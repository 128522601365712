import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";
import { comparer } from "../../components/Utils/General";

const slice = createSlice({
	name: "Message",
	initialState: {
		data: [
			{
				conversationId: "",
				messages: [],
				hasMore: true,
			},
		],
	},
	reducers: {
		messageListUpdated: (state, action) => {
			const messages = action.payload;

			if (messages.length === 0) {
				return state;
			} else {
				const conversationId = messages[0].conversationId;
				const index = state.data.findIndex(
					(data) => data.conversationId === conversationId
				);

				if (index >= 0) {
					const storeMessages = state.data[index].messages;

					const messagesToAppend = messages.filter(
						comparer(storeMessages)
					);

					state.data[index] = {
						...state.data[index],
						messages: [
							...state.data[index].messages,
							...messagesToAppend,
						],
						hasMore: messagesToAppend.length === 25,
					};
				} else {
					const dataItem = {
						conversationId: conversationId,
						// messages: messages.reverse(),
						messages: messages,
						hasMore: messages.length === 25,
					};

					return {
						...state,
						data: [...state.data, dataItem],
					};
				}
			}
		},

		messageAdded: (state, action) => {
			const conversationId = action.payload.conversationId;

			const index = state.data.findIndex(
				(data) => data.conversationId === conversationId
			);

			if (index >= 0) {
				const messageId = action.payload.messageId;
				const messageIndex = state.data[index].messages.findIndex(
					(msg) => msg.messageId === messageId
				);

				// does not exists // add it
				if (messageIndex === -1) {
					state.data[index] = {
						...state.data[index],
						messages: [
							action.payload,
							...state.data[index].messages,
						],
						hasMore: true,
					};
				}
				// exists // update it !!!
				else {
					state.data[index].messages[messageIndex] = action.payload;
				}
			} else {
				const arr = [];
				arr.unshift(action.payload);
				// arr.push(action.payload);

				const dataItem = {
					conversationId: conversationId,
					messages: arr,
					hasMore: true,
				};

				return {
					...state,
					data: [...state.data, dataItem],
				};
			}
		},

		messageUpdated: (state, action) => {
			const conversationId = action.payload.conversationId;

			const index = state.data.findIndex(
				(data) => data.conversationId === conversationId
			);

			if (index >= 0) {
				const messageId = action.payload.uuid;
				const messageIndex = state.data[index].messages.findIndex(
					(msg) => msg.uuid === messageId
				);

				// does not exists // add it
				if (messageIndex === -1) {
					state.data[index].messages.unshift(action.payload);
				}
				// exists // update it !!!
				else {
					state.data[index].messages[messageIndex] = action.payload;

					// console.log("updating the message !!!");
					// const incomingStatus = action.payload.messageStatus;
					// const savedStatus = state.data[index].messages[messageIndex].messageStatus;
					// if ((savedStatus === "PENDING" && incomingStatus === "SENT") || (savedStatus === "SENT" && incomingStatus === "RECEIVED") || (savedStatus === "RECEIVED" && incomingStatus === "READ"))
					//     state.data[index].messages[messageIndex] = action.payload;
					// else
					//     return state.data[index].messages[messageIndex];
				}
			} else {
				const arr = [];
				// arr.unshift(action.payload);
				arr.push(action.payload);

				const dataItem = {
					conversationId: conversationId,
					messages: arr,
					hasMore: false,
				};

				return {
					...state,
					data: [...state.data, dataItem],
				};
			}
		},

		ackUpdated: (state, action) => {
			const conversationId = action.payload.conversationId;

			const index = state.data.findIndex(
				(data) => data.conversationId === conversationId
			);

			if (index >= 0) {
				const messageId = action.payload.uuid;
				const messageIndex = state.data[index].messages.findIndex(
					(msg) => msg.uuid === messageId
				);

				// does not exists // add it
				if (messageIndex === -1) {
					state.data[index].messages.unshift(action.payload);
				}
				// exists // update it !!!
				else {
					// state.data[index].messages[messageIndex] = action.payload;

					const incomingStatus = action.payload.messageStatus;
					const savedStatus =
						state.data[index].messages[messageIndex].messageStatus;
					if (
						(savedStatus === "PENDING" &&
							incomingStatus === "SENT") ||
						(savedStatus === "SENT" &&
							incomingStatus === "RECEIVED") ||
						(savedStatus === "RECEIVED" &&
							incomingStatus === "READ")
					)
						state.data[index].messages[messageIndex] =
							action.payload;
				}
			} else {
				const arr = [];
				// arr.unshift(action.payload);
				arr.push(action.payload);

				const dataItem = {
					conversationId: conversationId,
					messages: arr,
					hasMore: false,
				};

				return {
					...state,
					data: [...state.data, dataItem],
				};
			}
		},
	},
});

export const { messageListUpdated, messageAdded, messageUpdated, ackUpdated } =
	slice.actions;
export default slice.reducer;

/**
 * get messages
 */

export const getMessages = (convId, page, size) =>
	apiActions.apiCallBegan({
		url: `conversation/messages/minified?conversationId=${convId}&page=${page}&size=${size}`,
		method: "GET",
		onSuccess: messageListUpdated.type,
	});

// export const getMessagesByUser = (profileId, page, size) =>
//     apiActions.apiCallBegan({
//         url: `conversation/messages/byUsers?profileId=${profileId}&page=${page}&size=${size}`,
//         method: "GET",
//         onSuccess: messageListUpdated.type,
//     });

/**
 * add message
 */

export const addMessage = (response) =>
	apiActions.socketBegan({
		response: response,
		onSuccess: messageAdded.type,
	});

/**
 * update message
 */

export const updateMessage = (response) =>
	apiActions.socketBegan({
		response: response,
		onSuccess: messageUpdated.type,
	});

export const updateAck = (response) =>
	apiActions.socketBegan({
		response: response,
		onSuccess: ackUpdated.type,
	});

/**
 * upload media
 */

export const uploadMessageMedia = (id, mediaData) =>
	apiActions.apiCallBegan({
		url: `conversation/messages/upload?messageId=${id}`,
		method: "POST",
		data: mediaData,
		showSuccessAlert: true,
		contentType: "multipart/form-data",
	});
