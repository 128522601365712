import React from "react";
import styles from "./ProfileEdit.module.css";

import Checkbox from "../../../../components/Utils/SubComs/Inputs/Checkbox/Checkbox";

function FeedShare({ checked, onChange, userImg }) {
    return (
        <div className={styles.share_canvas}>
            <Checkbox
                customBackgroundColor="gaawk"
                w={"14px"}
                h={"14px"}
                onChange={onChange}
                checked={checked}
            />
            <label>Share this update on my feed</label>
        </div>
    );
}

export default FeedShare;
