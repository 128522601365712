import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import useApi from "./useApi";
import postApi from "../api/post";

const usePostById = (isEditing = false) => {
	const [showModal, setShowModal] = useState(false);
	const [postId, setPostId] = useState("");

	const getPostByIdApi = useApi(postApi.getPostById, true, true);

	const fetchPost = async ({ queryKey }) => {
		const [_, postId] = queryKey;
		const response = await getPostByIdApi.request(postId);
		return response.data;
	};

	const post = useQuery({
		queryKey: ["post", postId],
		queryFn: fetchPost,
		enabled: !!postId,
	});

	useEffect(() => {
		if (post.data && isEditing) setShowModal(true);
	}, [postId, post.data, isEditing]);

	return { post, setPostId, showModal, setShowModal };
};

export default usePostById;
