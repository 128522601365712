import React, { useEffect, useMemo, useState } from "react";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
// import PromptMessage from "../../../../../Utils/Prompt/PromptMessage";
import Media from "../../SpecialProfileSections/Media";
import styles from "./MediaEntry.module.css";

import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// import deleteIcon from "images/icon-exit.svg";
// import playIcon from "images/play-icon-blue.svg";
import useApi from "../../../../../../hooks/useApi";
import specialProfileApi from "../../../../../../api/special-profile";
import LoadingBar from "../../../../../Utils/SubComs/LoadingBar/LoadingBar";
import FileThumbnail from "../../../../../Utils/SubComs/FileThumbnail/FileThumbnail";
import { getFileIcon } from "../../../../../Utils/General";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import { toast } from "react-toastify";

const MediaEntry = ({ profile }) => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { state } = useLocation();

	const { userUrl } = useParams();
	const addMediaApi = useApi(specialProfileApi.addMedia);

	const {
		audiosCount,
		videosCount,
		imagesCount,
		uuid: specialProfileId,
	} = profile;

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const [isMounted, setIsMounted] = useState(false);
	const [showProgress, setShowProgress] = useState(false);
	const [progress, setProgress] = useState(0);

	const schema = Joi.object({
		file: Joi.array().min(0),
	});

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		watch,
		setValue,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: {
			file: [],
		},
	});
	const fileWatcher = watch("file");
	// console.log("%c THE FILE WATCHER >>", "color: fuchsia", fileWatcher);

	const handleLoadMedia = (e) => {
		const files = Array.from(e.target.files);
		setValue("file", [...fileWatcher, ...files], { shouldDirty: true });
	};

	const handleRemoveImg = (index) => {
		const updatedArray = [...fileWatcher];
		updatedArray.splice(index, 1);
		setValue("file", updatedArray);
	};

	const loadedMedias = useMemo(() => {
		return fileWatcher.map((item, index) => {
			const { type, name } = item;
			return (
				<FileThumbnail
					key={index}
					thumbnail={getFileIcon(item, item.name).icon}
					isImage={type.startsWith("image")}
					onDelete={() => handleRemoveImg(index)}
					label={name}
				/>
			);
		});
	}, [fileWatcher]);

	const handleSave = (data) => {
		if (data.file.length > 0) {
			setShowProgress(true);
			const { file: fileArray } = data;
			const itemsProgress = {};

			fileArray.forEach((file, index) => {
				const formData = new FormData();

				formData.append("specialProfileId", specialProfileId);
				formData.append("multipartFile", file);

				addMediaApi.request(formData, (progress) => {
					itemsProgress[index] = progress * 100;

					let totalPercent = itemsProgress
						? Object.values(itemsProgress).reduce(
								(sum, num) => sum + num,
								0
						  )
						: 0;

					setProgress(Math.round(totalPercent / fileArray.length));
				});
			});
		}
	};

	useEffect(() => {
		let timer;
		if (isMounted) {
			if (!addMediaApi.loading && addMediaApi.responseCode === 200) {
				if (progress === 100) {
					timer = setTimeout(() => {
						setShouldBlockSave(false);
						queryClient.invalidateQueries(
							specialProfileKeys.detail(userUrl)
						);
					}, 2000);
				}
			} else {
				toast.error(addMediaApi.data.message);
			}
		}

		return () => {
			setIsMounted(true);
			clearTimeout(timer);
		};
	}, [addMediaApi.loading, addMediaApi.responseCode, progress]);

	useEffect(() => {
		if (!shouldBlockSave)
			navigate(
				routes.specialProfile(
					userUrl,
					state?.agencyId ? `?agency=${state?.agencyId}` : ""
				)
			);
	}, [navigate, shouldBlockSave]);

	return (
		<form
			className={styles.form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<div className={styles.subsection}>
				{showProgress && <LoadingBar progress={progress} />}

				<h3>Media</h3>
				<div className={styles.info_text}>
					You can add video, image and audio files to your media in
					your special profile. You can choose from your Vault, media
					from your Gallery, or you can upload new files.
				</div>

				<div className={styles.custom_upload_button}>
					{fileWatcher.length > 0 && (
						<div className={styles.thumbs_container}>
							{loadedMedias}
						</div>
					)}

					{/* <span className={styles.info_text}>You can upload image files only.</span> */}
					<div className={styles.img_option}>
						<button type="button">
							<Controller
								name="file"
								control={control}
								render={() => (
									<input
										type="file"
										accept="image/png, image/jpg, image/jpeg, audio/*, video/*"
										onChange={handleLoadMedia}
										tabIndex="-1"
										multiple
									/>
								)}
							/>
							+ ADD MEDIA
						</button>
					</div>
				</div>

				{(audiosCount > 0 || videosCount > 0 || imagesCount > 0) && (
					<div className={styles.subsection}>
						<h3>Your Media</h3>
						<Media
							specialProfileId={specialProfileId}
							editMode={true}
							userUrl={userUrl}
						/>
					</div>
				)}
			</div>

			<div className={styles.button_container}>
				<PrimaryButton className={styles.save_btn} text={"save"} />
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</form>
	);
};

export default MediaEntry;
