import StaticMap from "../StaticMap/StaticMap";
import styles from "./MapSelect.module.scss";

const MapSelect = ({ location, onClick, onRemove, customStyle }) => {
    return (
        <>
            {location && location.lat !== 0 && location.lng !== 0 ? (
                <>
                    <StaticMap
                        lat={location.lat}
                        lng={location.lng}
                        zoom="15"
                        customStyle={customStyle}
                    />
                    <button
                        className={styles.map_button}
                        onClick={onClick}
                        type="button"
                    >
                        Change location on map
                    </button>{" "}
                    |{" "}
                    <button
                        type="button"
                        className={styles.map_button}
                        onClick={onRemove}
                    >
                        Remove location
                    </button>
                </>
            ) : (
                <button
                    className={styles.map_button}
                    onClick={onClick}
                    type="button"
                >
                    Select location on map
                </button>
            )}
        </>
    );
};

export default MapSelect;
