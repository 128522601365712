import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	token: "",
};

const slice = createSlice({
	name: "fcmToken",
	initialState,
	reducers: {
		updateToken: (state, action) => {
			state.token = action.payload;
		},

		resetTokenState: () => {
			return initialState;
		},
	},
});

export const { updateToken, resetTokenState } = slice.actions;
export default slice.reducer;
