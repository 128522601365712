import Joi from "joi";

export const boardSchema = Joi.object({
	boardName: Joi.string().required().max(50).label("Board Name").messages({
		"string.empty": "Board Name can't be empty",
		"string.max": "Board Name can't be more than 50 characters",
	}),
	description: Joi.string()
		.required()
		.label("Description")
		.max(5000)
		.messages({
			"string.empty": "Description can't be empty",
			"string.max":
				"Product Description can't be more than 5000 characters",
		}),
	visibility: Joi.boolean().label("Board visibility"),
	city: Joi.object({
		label: Joi.string(),
		value: Joi.number(),
	})
		.required()
		.messages({
			"any.required": "Location can't be empty",
			"object.base": "Location can't be empty",
		})
		.label("Location"),
	image: Joi.array().min(1).label("Main Image").messages({
		"array.min": "An image is required",
	}),
	feedImage: Joi.array().min(1).label("In feed board image").messages({
		"array.min": "An image is required",
	}),
});

export const suggestedBoardSchema = Joi.object({
	boardName: Joi.string()
		.required()
		.label("Board Name")
		.messages({ "string.empty": "Board Name can't be empty" }),
	description: Joi.string()
		.required()
		.label("Description")
		.messages({ "string.empty": "Description can't be empty" }),
});
