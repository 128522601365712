import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";
import { toast } from "react-toastify";
import { comparer } from "../../components/Utils/General";

const slice = createSlice({
	name: "Vault",
	initialState: {
		info: null,
		sharedDetails: null,
		me: [],
		hasMoreMe: true,
		shared: [],
		hasMoreShared: true,
		favorites: [],
		hasMoreFav: true,
		gaawk: [],
		hasMoreGaawk: true,
		recent: [],
		folders: [],
		pending: [],
		foldersList: [],
		parentIdFolder: "",
		hasMoreFolders: true,
	},
	reducers: {
		infoUpdated: (state, action) => {
			// console.log("--->>>>>>", action.payload);
			state.info = action.payload;
		},

		meUpdated: (state, action) => {
			const itemsToDisplay = action.payload.passedData;
			const meItems = action.payload.response;

			if (meItems.length === 0) {
				// DO NOTHING
			} else {
				const storeMe = state.me;
				const itemsToAppend = meItems.filter(comparer(storeMe));

				state.me = [...state.me, ...itemsToAppend];
				state.hasMoreMe = itemsToAppend.length === itemsToDisplay;
			}
		},

		gaawkUpdated: (state, action) => {
			const itemsToDisplay = action.payload.passedData;
			const gaawkItems = action.payload.response;

			if (gaawkItems.length === 0) {
				// DO NOTHING
			} else {
				const storeGaawk = state.gaawk;
				const itemsToAppend = gaawkItems.filter(comparer(storeGaawk));

				state.gaawk = [...state.gaawk, ...itemsToAppend];
				state.hasMoreGaawk = itemsToAppend.length === itemsToDisplay;
			}
		},

		recentUpdated: (state, action) => {
			state.recent = action.payload;
		},

		sharedUpdated: (state, action) => {
			const itemsToDisplay = action.payload.passedData;
			const sharedItems = action.payload.response;

			if (sharedItems.length === 0) {
				// DO NOTHING
			} else {
				const storeShared = state.shared;
				const sharedToAppend = sharedItems.filter(
					comparer(storeShared)
				);

				state.shared = [...state.shared, ...sharedToAppend];
				state.hasMoreShared = sharedToAppend.length === itemsToDisplay;
			}
		},

		favoritesUpdated: (state, action) => {
			const itemsToDisplay = action.payload.passedData;
			const favItems = action.payload.response;

			if (favItems.length === 0) {
				// DO NOTHING
			} else {
				const storeFav = state.favorites;
				const favsToAppend = favItems.filter(comparer(storeFav));

				state.favorites = [...state.favorites, ...favsToAppend];
				state.hasMoreFav = favsToAppend.length === itemsToDisplay;
			}
		},

		favoriteUpdated: (state, action) => {
			const uuid = action.payload.uuid;
			const parentId = action.payload.parentId;

			// update me list
			const meIndex = state.me.findIndex((item) => item.uuid === uuid);
			if (meIndex > -1) {
				state.me[meIndex].favorite = action.payload.favorite;
			}

			// update recent list
			const recentIndex = state.recent.findIndex(
				(item) => item.uuid === uuid
			);
			if (recentIndex > -1) {
				state.recent[recentIndex].favorite = action.payload.favorite;
			}

			// update shared list
			const sharedIndex = state.shared.findIndex(
				(item) => item.uuid === uuid
			);
			if (sharedIndex > -1) {
				state.shared[sharedIndex].favorite = action.payload.favorite;
			}

			// update favorites list
			if (action.payload.favorite) {
				state.favorites = [...state.favorites, action.payload];
			} else {
				state.favorites = state.favorites.filter(
					(item) => item.uuid !== uuid
				);
			}
			// const favIndex = state.favorites.findIndex(
			//     (item) => item.uuid === uuid
			// );
			// if (favIndex > -1) {
			//     state.favorites[favIndex].favorite = action.payload.favorite;
			//     // if (!action.payload.favorite)
			//     //     state.favorites.filter(item => item !== uuid);
			// }

			// update gaawk list
			const gaawkIndex = state.gaawk.findIndex(
				(item) => item.uuid === uuid
			);
			if (gaawkIndex > -1) {
				state.gaawk[gaawkIndex].favorite = action.payload.favorite;
			}

			//update state.folders (STAR AN ITEM WHEN YOU'RE INSIDE A FOLDER)

			//* FINDING THE INDEX OF THE FOLDER
			const folderIndex = state.folders.findIndex(
				(folder) => folder.parentItem.uuid === parentId
			);

			if (folderIndex > -1) {
				//* FINDING THE INDEX OF THE ITEM, INSIDE OF THE FOLDER
				const itemIndex = state.folders[folderIndex].items.findIndex(
					(item) => item.uuid === uuid
				);

				if (itemIndex > -1) {
					//* STARRING THE FOUND ITEM INSIDE THE FOLDER
					state.folders[folderIndex].items[itemIndex].favorite =
						action.payload.favorite;
				}
			}
		},

		foldersUpdated: (state, action) => {
			state.folders.push(action.payload);
		},

		folderCreated: (state, action) => {
			const parentId = action.payload.parentId;
			const createdFolder = action.payload;

			if (parentId) {
				// CREATING IN FOLDER
				const index = state.folders.findIndex(
					(folder) => folder.parentItem.uuid === parentId
				);
				if (index > -1) {
					state.folders[index].items.unshift(action.payload);
				}
			} else {
				// CREATING IN ROOT
				state.me = [...state.me, createdFolder];
			}
		},

		pendingFilesUpdated: (state, action) => {
			if (action.payload.parentId) {
				//* means that you are adding files to a folder

				const index = state.folders.findIndex(
					(folder) =>
						folder.parentItem.uuid === action.payload.parentId
				);

				if (index > -1) {
					state.folders[index].items.unshift(action.payload);
				}
			} else {
				//* means that you are adding files to the root

				state.me = [...state.me, action.payload];
			}
		},

		itemDeleted: (state, action) => {
			// TODO: REMOVE ITEM FROM STORE :
			// TODO: me, shared, favorites, recent, gaawk, & folders

			const itemsToDelete = action.payload.passedData;

			const idsToDelete = itemsToDelete.map((item) => item.uuid);

			// if (itemsToDelete.length > 1) {
			//! MULTIPLE ITEMS TO DELETE

			state.me = state.me.filter(
				(i) => idsToDelete.indexOf(i.uuid) !== -1
			);

			state.recent = state.recent.filter(
				(i) => idsToDelete.indexOf(i.uuid) !== -1
			);

			state.shared = state.shared.filter(
				(i) => idsToDelete.indexOf(i.uuid) !== -1
			);

			state.favorites = state.favorites.filter(
				(i) => idsToDelete.indexOf(i.uuid) !== -1
			);

			// TODO: DELETE FROM INSIDE A FOLDER (PARENTID) => (same as favoriteUpdated)
			// }

			// else {
			//     //! SINGLE ITEM TO DELETE

			//     // update me list
			//     const meIndex = state.me.findIndex(
			//         (item) => item.uuid === itemsToDelete[0]
			//     );
			//     if (meIndex > -1) {
			//         state.me = state.me.filter(
			//             (item) => item.uuid !== itemsToDelete[0]
			//         );
			//     }

			//     // update recent list
			//     const recentIndex = state.recent.findIndex(
			//         (item) => item.uuid === itemsToDelete[0]
			//     );
			//     if (recentIndex > -1) {
			//         state.recent = state.recent.filter(
			//             (item) => item.uuid !== itemsToDelete[0]
			//         );
			//     }

			//     // update shared list
			//     const sharedIndex = state.shared.findIndex(
			//         (item) => item.uuid === itemsToDelete[0]
			//     );
			//     if (sharedIndex > -1) {
			//         state.shared = state.shared.filter(
			//             (item) => item.uuid !== itemsToDelete[0]
			//         );
			//     }

			//     // update favorites list
			//     const favIndex = state.favorites.findIndex(
			//         (item) => item.uuid === itemsToDelete[0]
			//     );
			//     if (favIndex > -1) {
			//         state.favorites = state.favorites.filter(
			//             (item) => item.uuid !== itemsToDelete[0]
			//         );
			//     }

			//     // TODO: DELETE FROM INSIDE A FOLDER (PARENTID) => (same as favoriteUpdated)
			// }
		},
		sharedInfoUpdated: (state, action) => {
			state.sharedDetails = action.payload;
		},

		foldersListed: (state, action) => {
			const myFolders = action.payload;

			let parentId = "";
			if (action.payload.length > 0 && action.payload[0].parentId) {
				parentId = action.payload[0].parentId;
			}
			// * IF THERE'S A PARENTID IN THE PAYLOAD >>> YOU ARE IN A SUBFOLDER

			if (myFolders.length === 0) {
				state.foldersList = [];
			} else {
				if (parentId) {
					// console.log("THERE IS A PARENT ID");
					if (state.parentIdFolder !== parentId) {
						// console.log("PARENT ID IS DIFFERENT THAN PREVIOUS ONE");
						state.foldersList = [...myFolders];
						state.parentIdFolder = parentId;
						state.hasMoreFolders = myFolders.length === 10;
					} else {
						// console.log("PARENT ID IS SAME");

						const foldersToAppend = myFolders.filter(
							comparer(state.foldersList)
						);
						state.foldersList = [
							...state.foldersList,
							...foldersToAppend,
						];
						state.hasMoreFolders = foldersToAppend.length === 10;
					}
				} else {
					// console.log("NO PARENT ID");
					if (state.parentIdFolder !== parentId) {
						state.foldersList = [...myFolders];
						state.hasMoreFolders = myFolders.length === 10;
						state.parentIdFolder = "";
						// console.log("IT'S A DIFFERENT FOLDER");
					} else {
						const foldersToAppend = myFolders.filter(
							comparer(state.foldersList)
						);
						state.foldersList = [
							...state.foldersList,
							...foldersToAppend,
						];
						state.hasMoreFolders = foldersToAppend.length === 10;
						// console.log("IT'S THE SAME FOLDER");
					}
				}
			}
			// }
		},

		itemRenamed: (state, action) => {
			let renamedUuid;
			let folderNewName;
			let fileNewName;
			let parentId;

			if (action.payload.response) {
				renamedUuid = action.payload.response.uuid;
				if (action.payload.response.folder) {
					folderNewName = action.payload.response.folder.name;
				}
				if (action.payload.response.file) {
					fileNewName = action.payload.response.file.originalName;
				}
				parentId = action.payload.passedData;
			} else {
				renamedUuid = action.payload.uuid;
				if (action.payload.file) {
					fileNewName = action.payload.file.originalName;
				}
				if (action.payload.folder) {
					folderNewName = action.payload.folder.name;
				}
			}

			const meIndex = state.me.findIndex(
				(item) => item.uuid === renamedUuid
			);
			if (meIndex > -1) {
				if (state.me[meIndex].type === "FILE") {
					state.me[meIndex].file.originalName = fileNewName;
				} else {
					state.me[meIndex].folder.name = folderNewName;
				}
			}

			const recentIndex = state.recent.findIndex(
				(item) => item.uuid === renamedUuid
			);
			if (recentIndex > -1) {
				if (state.recent[recentIndex].type === "FILE") {
					state.recent[recentIndex].file.originalName = fileNewName;
				} else {
					state.recent[recentIndex].folder.name = folderNewName;
				}
			}

			const sharedIndex = state.shared.findIndex(
				(item) => item.uuid === renamedUuid
			);
			if (sharedIndex > -1) {
				if (state.shared[sharedIndex].type === "FILE") {
					state.shared[sharedIndex].file.orginalName = fileNewName;
				} else {
					state.shared[sharedIndex].folder.name = folderNewName;
				}
			}

			// update favorites list
			const favIndex = state.favorites.findIndex(
				(item) => item.uuid === renamedUuid
			);
			if (favIndex > -1) {
				if (state.favorites[favIndex].type === "FILE") {
					state.favorites[favIndex].file.originalName = fileNewName;
				} else {
					state.favorites[favIndex].folder.name = folderNewName;
				}
			}

			// //* FINDING THE INDEX OF THE FOLDER
			const folderIndex = state.folders.findIndex(
				(folder) => folder.parentItem.uuid === parentId
			);

			if (folderIndex > -1) {
				//* FINDING THE INDEX OF THE ITEM, INSIDE OF THE FOLDER
				const itemIndex = state.folders[folderIndex].items.findIndex(
					(item) => item.uuid === renamedUuid
				);

				if (itemIndex > -1) {
					//* STARRING THE FOUND ITEM INSIDE THE FOLDER
					if (
						state.folders[folderIndex].items[itemIndex].type ===
						"FILE"
					) {
						state.folders[folderIndex].items[
							itemIndex
						].file.originalName = fileNewName;
					} else {
						state.folders[folderIndex].items[
							itemIndex
						].folder.name = folderNewName;
					}
				}
			}
		},
		itemMoved: (state, action) => {
			const itemsToMove = action.payload.passedData.items;
			const itemsIds = itemsToMove.map((item) => item.uuid);

			const parentId = action.payload.passedData.parentFolder;
			const originFolder = action.payload.passedData.originFolder;

			if (parentId) {
				//TODO CASE 1: WITH PARENT ID (TOWARDS A FOLDER)
				if (originFolder) {
					//* DONE: ETAPE 1, on retire du dossier d'origine pour move vers un autre dossier

					//? FINDING THE INDEX OF THE ORIGIN FOLDER

					const folderIndex = state.folders.findIndex(
						(folder) => folder.parentItem.uuid === originFolder
					);

					if (folderIndex > -1) {
						//? REMOVING ITEM(S) FROM INSIDE THE ORIGIN FOLDER

						state.folders[folderIndex].items = state.folders[
							folderIndex
						].items.filter((i) => itemsIds.indexOf(i.uuid) === -1);

						state.folders[folderIndex].parentItem.subCount -=
							itemsToMove.length;
					}

					//* DECREMENT FROM ORIGIN FOLDER
					const meFolderIndex = state.me.findIndex(
						(folder) => folder.uuid === originFolder
					);

					if (meFolderIndex > -1)
						state.me[meFolderIndex].subCount -= itemsToMove.length;

					const sharedFolderIndex = state.shared.findIndex(
						(folder) => folder.uuid === originFolder
					);

					if (sharedFolderIndex > -1)
						state.shared[sharedFolderIndex].subCount -=
							itemsToMove.length;

					const favoritesFolderIndex = state.favorites.findIndex(
						(folder) => folder.uuid === originFolder
					);

					if (favoritesFolderIndex > -1)
						state.favorites[favoritesFolderIndex].subCount -=
							itemsToMove.length;

					const recentFolderIndex = state.recent.findIndex(
						(folder) => folder.uuid === originFolder
					);
					if (recentFolderIndex > -1)
						state.recent[recentFolderIndex].subCount -=
							itemsToMove.length;
				} else {
					//* DONE: ETAPE 1BIS, on retire du root pour move vers un dossier
					state.me = state.me.filter(
						(i) => itemsIds.indexOf(i.uuid) === -1
					);
				}

				//* DONE: ETAPE 2: ADD THE MOVED FILE TO THE DESTINATION FOLDER ?
				const folderIndexDestination = state.folders.findIndex(
					(folder) => folder.parentItem.uuid === parentId
				);

				if (folderIndexDestination > -1) {
					state.folders[folderIndexDestination].items = [
						...state.folders[folderIndexDestination].items,
						...itemsToMove,
					];

					//* DONE ETAPE 3: SUBCOUNT OF DESTINATION TO INCREMENT (ME, SHARED, FAV, RECENT)

					state.folders[folderIndexDestination].parentItem.subCount +=
						itemsToMove.length;
				}

				//* INCREMENT SUBCOUNT IN OTHER STATES

				const meFolderIndex = state.me.findIndex(
					(folder) => folder.uuid === parentId
				);

				if (meFolderIndex > -1)
					state.me[meFolderIndex].subCount += itemsToMove.length;

				const sharedFolderIndex = state.shared.findIndex(
					(folder) => folder.uuid === parentId
				);

				if (sharedFolderIndex > -1)
					state.shared[sharedFolderIndex].subCount +=
						itemsToMove.length;

				const favoritesFolderIndex = state.favorites.findIndex(
					(folder) => folder.uuid === parentId
				);

				if (favoritesFolderIndex > -1)
					state.favorites[favoritesFolderIndex].subCount +=
						itemsToMove.length;

				const recentFolderIndex = state.recent.findIndex(
					(folder) => folder.uuid === parentId
				);
				if (recentFolderIndex > -1)
					state.recent[recentFolderIndex].subCount +=
						itemsToMove.length;
			} else {
				//TODO CASE 2: NO PARENT ID (TOWARDS ROOT VAULT)

				//? FINDING THE INDEX OF THE ORIGIN FOLDER
				const folderIndexOrigin = state.folders.findIndex(
					(folder) => folder.parentItem.uuid === originFolder
				);

				if (folderIndexOrigin > -1) {
					//? REMOVING ITEMS FROM INSIDE THE ORIGIN FOLDER
					state.folders[folderIndexOrigin].items = state.folders[
						folderIndexOrigin
					].items.filter((i) => itemsIds.indexOf(i.uuid) === -1);

					// ! ETAPE 3: SUBCOUNT A DECREMENT

					state.folders[folderIndexOrigin].parentItem.subCount -=
						itemsToMove.length;
				}

				//? ADD THE MOVED FILE TO THE ROOT ?
				state.me = [...state.me, ...itemsToMove];

				const meFolderIndex = state.me.findIndex(
					(folder) => folder.uuid === originFolder
				);

				if (meFolderIndex > -1)
					state.me[meFolderIndex].subCount -= itemsToMove.length;

				const sharedFolderIndex = state.shared.findIndex(
					(folder) => folder.uuid === originFolder
				);

				if (sharedFolderIndex > -1)
					state.shared[sharedFolderIndex].subCount -=
						itemsToMove.length;

				const favoritesFolderIndex = state.favorites.findIndex(
					(folder) => folder.uuid === originFolder
				);

				if (favoritesFolderIndex > -1)
					state.favorites[favoritesFolderIndex].subCount -=
						itemsToMove.length;

				const recentFolderIndex = state.recent.findIndex(
					(folder) => folder.uuid === originFolder
				);
				if (recentFolderIndex > -1)
					state.recent[recentFolderIndex].subCount -=
						itemsToMove.length;
			}
		},
		itemCopied: (state, action) => {
			const itemsToCopy = action.payload.passedData.items;
			// const itemsIds = itemsToCopy.map((item) => item.uuid);

			const parentId = action.payload.passedData.parentFolder;

			if (parentId) {
				//TODO CASE 1: WITH PARENT ID (TOWARDS A FOLDER)

				//* DONE: ETAPE 2: ADD THE MOVED FILE TO THE DESTINATION FOLDER ?
				const folderIndexDestination = state.folders.findIndex(
					(folder) => folder.parentItem.uuid === parentId
				);

				if (folderIndexDestination > -1) {
					state.folders[folderIndexDestination].items = [
						...state.folders[folderIndexDestination].items,
						...itemsToCopy,
					];

					//* DONE ETAPE 3: SUBCOUNT OF DESTINATION TO INCREMENT (ME, SHARED, FAV, RECENT)

					state.folders[folderIndexDestination].parentItem.subCount +=
						itemsToCopy.length;
				}

				//* INCREMENT SUBCOUNT IN OTHER STATES

				const meFolderIndex = state.me.findIndex(
					(folder) => folder.uuid === parentId
				);

				if (meFolderIndex > -1)
					state.me[meFolderIndex].subCount += itemsToCopy.length;

				const sharedFolderIndex = state.shared.findIndex(
					(folder) => folder.uuid === parentId
				);

				if (sharedFolderIndex > -1)
					state.shared[sharedFolderIndex].subCount +=
						itemsToCopy.length;

				const favoritesFolderIndex = state.favorites.findIndex(
					(folder) => folder.uuid === parentId
				);

				if (favoritesFolderIndex > -1)
					state.favorites[favoritesFolderIndex].subCount +=
						itemsToCopy.length;

				const recentFolderIndex = state.recent.findIndex(
					(folder) => folder.uuid === parentId
				);
				if (recentFolderIndex > -1)
					state.recent[recentFolderIndex].subCount +=
						itemsToCopy.length;
			} else {
				//TODO CASE 2: NO PARENT ID (TOWARDS ROOT VAULT)

				// //? FINDING THE INDEX OF THE ORIGIN FOLDER
				// const folderIndexOrigin = state.folders.findIndex(
				//     (folder) => folder.parentItem.uuid === originFolder
				// );

				// if (folderIndexOrigin > -1) {
				//     //? REMOVING ITEMS FROM INSIDE THE ORIGIN FOLDER
				//     state.folders[folderIndexOrigin].items = state.folders[
				//         folderIndexOrigin
				//     ].items.filter((i) => itemsIds.indexOf(i.uuid) === -1);

				//     // ! ETAPE 3: SUBCOUNT A DECREMENT

				//     state.folders[folderIndexOrigin].parentItem.subCount -=
				//         itemsToCopy.length;
				// }

				//? ADD THE COPIED FILE TO THE ROOT ?
				state.me = [...state.me, ...itemsToCopy];
			}
		},
	},
});

export const {
	infoUpdated,
	meUpdated,
	gaawkUpdated,
	recentUpdated,
	sharedUpdated,
	favoritesUpdated,
	favoriteUpdated,
	foldersUpdated,
	folderCreated,
	pendingFilesUpdated,
	itemDeleted,
	sharedInfoUpdated,
	foldersListed,
	itemRenamed,
	itemMoved,
	itemCopied,
} = slice.actions;
export default slice.reducer;

/**
 * Apis
 */

export const getInfo = () =>
	apiActions.apiCallBegan({
		url: `/vault/info`,
		method: "GET",
		onSuccess: infoUpdated.type,
	});

export const getMeItems = (parentFolderId, page, size, sort) =>
	apiActions.apiCallBegan({
		url: `/vault/items/me?page=${page}&size=${size}&sort=${sort}${
			parentFolderId ? `&parentFolderId=${parentFolderId}` : ""
		}`,
		passedData: size,
		method: "GET",
		onSuccess: meUpdated.type,
	});

export const getGaawkItems = (parentFolderId, page, size, sort) =>
	apiActions.apiCallBegan({
		url: `/vault/items/gaawk?page=${page}&size=${size}&sort=${sort}${
			parentFolderId ? `&parentFolderId=${parentFolderId}` : ""
		}`,
		passedData: size,
		method: "GET",
		onSuccess: gaawkUpdated.type,
	});

export const getRecents = () =>
	apiActions.apiCallBegan({
		url: `/vault/items/recent`,
		method: "GET",
		onSuccess: recentUpdated.type,
	});

export const getShared = (page, size, sort) =>
	apiActions.apiCallBegan({
		url: `/vault/items/me/shared?page=${page}&size=${size}&sort=${sort}`,
		method: "GET",
		passedData: size,
		onSuccess: sharedUpdated.type,
	});

export const getFavorites = (page, size, sort) =>
	apiActions.apiCallBegan({
		url: `/vault/items/favorites?page=${page}&size=${size}&sort=${sort}`,
		method: "GET",
		passedData: size,
		onSuccess: favoritesUpdated.type,
	});

export const favoriteItem = (itemId, state) =>
	apiActions.apiCallBegan({
		url: `/vault/items/favorites?itemId=${itemId}&status=${state}`,
		method: "PATCH",
		onSuccess: favoriteUpdated.type,
	});

export const share = (items, profileIds) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");
	const profileIdsString = profileIds
		.map((userId) => `sharedProfileIds=${userId}`)
		.join("&");
	return apiActions.apiCallBegan({
		url: `/vault/share?${itemIdsString}&${profileIdsString}`,
		method: "POST",
		showSuccessAlert: true,
	});
};

export const deleteItems = (items) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");
	return apiActions.apiCallBegan({
		url: `/vault/items?${itemIdsString}`,
		method: "DELETE",
		passedData: items,
		showSuccessAlert: true,
		onSuccess: itemDeleted.type,
	});
};

export const getFolders = (parentId, page, size, sort) =>
	apiActions.apiCallBegan({
		url: `/vault/items/folders?page=${page}${
			parentId && `&parentFolderId=${parentId}`
		}&size=${size}&sort=${sort}`,
		method: "GET",
		onSuccess: foldersListed.type,
	});

export const getFolderItems = (parentId, page, size, sort) =>
	apiActions.apiCallBegan({
		url: `/vault/items/byParentId?page=${page}&parentId=${parentId}&size=${size}&sort=${sort}`,
		method: "GET",
		onSuccess: foldersUpdated.type,
	});

export const createFolder = (name, onRoot, parentFolderId) =>
	apiActions.apiCallBegan({
		url: `/vault/folder?folderName=${name}&onRoot=${onRoot}&parentFolderId=${parentFolderId}`,
		method: "POST",
		showSuccessAlert: true,
		onSuccess: folderCreated.type,
	});

export const addPendingFiles = (formData) =>
	apiActions.apiCallBegan({
		url: `/vault/file`,
		method: "POST",
		data: formData,
		onSuccess: pendingFilesUpdated.type,
		// showSuccessAlert: true,
		contentType: "multipart/form-data",
	});

export const sharedInfo = (itemId) =>
	apiActions.apiCallBegan({
		url: `/vault/share/info?itemId=${itemId}`,
		method: "GET",
		// showSuccessAlert: true,
		onSuccess: sharedInfoUpdated.type,
	});

export const renameItem = (itemId, newName, parentId) =>
	apiActions.apiCallBegan({
		url: `/vault/items/rename?itemId=${itemId}&name=${newName}`,
		method: "PATCH",
		passedData: parentId,
		showSuccessAlert: true,
		onSuccess: itemRenamed.type,
	});

export const moveItems = (items, parentFolder, originFolder = null) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");
	return apiActions.apiCallBegan({
		url: parentFolder
			? `/vault/items/move?${itemIdsString}&toParentFolder=${parentFolder}`
			: `/vault/items/move?${itemIdsString}`,
		method: "PATCH",
		passedData: { items, parentFolder, originFolder },
		showSuccessAlert: true,
		onSuccess: itemMoved.type,
	});
};

export const copyItems = (items, parentFolder) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");
	return apiActions.apiCallBegan({
		url: parentFolder
			? `/vault/items/copy?${itemIdsString}&toParentFolder=${parentFolder}`
			: `/vault/items/copy?${itemIdsString}`,
		method: "POST",
		passedData: { items, parentFolder },
		showSuccessAlert: true,
		onSuccess: itemCopied.type,
	});
};
