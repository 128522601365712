import { useState, useEffect } from "react";

import styles from "./CompanyList.module.css";
import useApi from "../../hooks/useApi";
import profileApi from "../../api/profile";
import UserCompanies from "./UserCompanies";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";

const CompanyList = () => {
	const getUserCompanyApi = useApi(profileApi.getUserCompany);

	const [ownedCompany, setOwnedCompany] = useState([]);
	const [adminCompany, setAdminCompany] = useState([]);
	const [repCompany, setRepCompany] = useState([]);

	useEffect(() => {
		getUserCompanyApi.request();
	}, []);

	useEffect(() => {
		if (
			!getUserCompanyApi.loading &&
			getUserCompanyApi.data &&
			getUserCompanyApi.responseCode === 200
		) {
			const userCompany = getUserCompanyApi.data;

			if (userCompany.admins) setAdminCompany(userCompany.admins);
			if (userCompany.reps) setRepCompany(userCompany.reps);
			if (userCompany.owned) setOwnedCompany(userCompany.owned);
		}
	}, [getUserCompanyApi.data]);

	return (
		<>
			{getUserCompanyApi.loading ? (
				<div className={styles.loading_container}>
					<LoadingPage />
				</div>
			) : (
				<div className={styles.wrapper}>
					<div className={styles.container}>
						<UserCompanies
							title={
								ownedCompany.length > 0
									? "Companies you own"
									: "You don't have companies yet!"
							}
							text={
								ownedCompany.length > 0
									? `The followings are companies that you have created. You own these companies,
                                        but you can transfer the ownership to another person from Manage company
                                        menu.`
									: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro officia
                                        voluptatem reprehenderit voluptatibus. Facere delectus, fuga voluptatem ipsa
                                        quos eum accusamus alias possimus sint doloribus repudiandae eligendi
                                        quaerat numquam voluptatibus!`
							}
							items={ownedCompany}
							owned={true}
						/>
					</div>
					{adminCompany.length > 0 && repCompany.length > 0 && (
						// <div className={styles.other_companies_container}>
						<div className={styles.other_companies}>
							{adminCompany.length > 0 && (
								<UserCompanies
									title={"Companies you're Admin in"}
									text={
										"You are admin in the following companies"
									}
									items={adminCompany}
								/>
							)}
							{repCompany.length > 0 && (
								<UserCompanies
									title={"Companies you represent"}
									text={
										"You are a representative in the following companies"
									}
									items={repCompany}
								/>
							)}
						</div>
						// </div>
					)}
				</div>
			)}
		</>
	);
};

export default CompanyList;
