import client from "./client";

const endpoint = "/language";

const searchLanguage = (q = "", page, size) =>
    client.get(endpoint + `/search?q=${q}&page=${page}&size=${size}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    searchLanguage,
};
