import { useQuery } from "@tanstack/react-query";
import useApi from "./useApi";
import profileApi from "api/profile";
import { profileKeys } from "queryKeys/profile-key-factory";

//*HOOK TO GET THE USER'S COMPANIES

const useGetCompanies = (enabled = false) => {
	const getUserCompanyApi = useApi(profileApi.getUserCompany, true, true);

	const fetchCompanies = async () => {
		const response = await getUserCompanyApi.request();
		return response.data;
	};

	const query = useQuery({
		queryKey: profileKeys.myCompanies(),
		queryFn: fetchCompanies,
		enabled,
	});

	return query;
};

export default useGetCompanies;
