import profileApi from "api/profile";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { successfulChangeToast } from "components/Utils/General";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import useMutate from "hooks/useMutate";
import deleteIcon from "images/bin-icon.svg";
import downloadIcon from "images/download-icon-blue.svg";
import frameIcon from "images/frame_icon.svg";
import tempIcon from "images/temp_icon.svg";
import tempIconWhite from "images/temporary-white-35-x-35.svg";
import uploadIcon from "images/upload_icon.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WarningModal from "../../../../components/Utils/GaawkModal/WarningModal";
import {
	companyProfileImageUpdated,
	makeTempPermanentCompany,
	removeTempCompany,
} from "../../../../store/slices/company";
import {
	makeTempPermanent,
	profileImageUpdated,
	removeTemp,
} from "../../../../store/slices/user";
import CropModal from "../../../Utils/CropModal/CropModal";
import LazyImage from "../../../Utils/LazyImage/LazyImage";
import AddCaption from "./AddCaption";
import FeedShare from "./FeedShare";
import FrameModal from "./Frame/FrameModal";
import styles from "./ProfileEdit.module.css";
import PublicInfo from "./PublicInfo";
import TempModal from "./Temp/TempModal";

const Index = ({ editCompany }) => {
	const dispatch = useDispatch();

	const userData = useSelector((state) => {
		if (editCompany) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const userImage = userData.profileImage?.originalImageURL;
	const userFrame = userData.profileImage?.frame?.url;
	const userFrameId = userData.profileImage?.frame?.uuid || "";
	const userTempTime = userData.profileImage?.tempTime || 0;
	const userCaption = userData.profileImage?.postText || "";

	const [image, setImage] = useState(userImage);
	const [frame, setFrame] = useState(userFrame);
	const [frameId, setFrameId] = useState(userFrameId);
	const [caption, setCaption] = useState(userCaption);
	const [tempTime, setTempTime] = useState(userTempTime || 0);
	const [shareCheck, setShareCheck] = useState(false);
	const [deleteModalOpened, setDeleteModalOpened] = useState(false);
	const [cropModal, setCropModal] = useState(false);

	const {
		action: { mutate: deleteProfilePic },
	} = useMutate(profileApi.deleteProfilePic, (response) => {
		dispatch(
			editCompany
				? companyProfileImageUpdated(response.data)
				: profileImageUpdated(response.data)
		);
		setDeleteModalOpened(false);
		successfulChangeToast();
	});

	const handleDownload = () => {
		const timeStamp = new Date().getTime();
		saveAs(image, `gaawk_${timeStamp}.jpg`);
	};

	const handleImage = (e) => {
		setImage(e.target.files[0]);
		setCropModal(true);
	};

	const handleCaption = (text) => {
		setCaption(text);
	};

	const didMountRef = useRef(false);

	useEffect(() => {
		// * below to apply change after saving in states, and also not trigger unsavedChanges
		if (didMountRef.current) {
			setImage(userData.profileImage.originalImageURL);
			setTempTime(userData.profileImage?.tempTime || 0);
			setCaption(userData.profileImage?.postText || "");
			setFrame(userData.profileImage?.frame?.url || "");
			setFrameId(userData.profileImage?.frame?.uuid || "");
		}
		didMountRef.current = true;
	}, [userData.profileImage]);

	const checkUnsavedChanges = () => {
		if (
			image !== userImage ||
			frameId !== userFrameId ||
			tempTime !== userTempTime ||
			caption !== userCaption
		) {
			return true;
		} else return false;
	};

	// ! == temp modal ===

	const [showTempModal, setShowTempModal] = useState(false);

	// ! == frame modal ===

	const [showFrameModal, setShowFrameModal] = useState(false);

	//! navigation blocker

	const onBlock = (currentLocation, nextLocation) => {
		if (
			checkUnsavedChanges() &&
			nextLocation.pathname !== currentLocation.pathname
		) {
			return true;
		}
	};

	//! ==================

	const {
		action: { mutate: updatePicture, isLoading },
	} = useMutate(profileApi.updatePicture, (response) => {
		dispatch(
			editCompany
				? companyProfileImageUpdated(response.data)
				: profileImageUpdated(response.data)
		);

		successfulChangeToast();
	});

	const handleSave = () => {
		const formData = new FormData();

		if (image instanceof Blob) {
			formData.append("profileImageFile", image, image.fileName);
		}

		if (tempTime) {
			formData.append("tempTime", tempTime);
			formData.append("temp", true);
		}

		formData.append("share", shareCheck);

		if (caption) {
			formData.append("caption", encodeURIComponent(caption));
		}

		if (frameId) {
			formData.append("frameId", frameId);
		}

		if (editCompany) formData.append("companyId", userData.uuid);

		// // * to check the content of formData:
		// for (const pair of formData.entries()) {
		// 	console.log(pair[0] + ", " + pair[1]);
		// }

		updatePicture({
			formData,
			isCompany: editCompany ? true : false,
		});
	};

	const inputFile = useRef(null);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.image_options_container}>
					<div className={styles.img_canvas}>
						<LazyImage
							image={
								<img
									src={
										image instanceof Blob
											? URL.createObjectURL(image)
											: image
									}
									alt={"profile"}
								/>
							}
						/>

						{frame && (
							<LazyImage
								image={<img src={frame} alt={"frame"} />}
								customWrapper={styles.frame_wrapper}
							/>
						)}

						{tempTime !== 0 && (
							<div className={styles.temp_custom_date_wrapper}>
								<img src={tempIconWhite} alt={"temp"} />
								<label>
									{format(new Date(tempTime), "dd/MM/yyyy")}
								</label>
							</div>
						)}
					</div>
				</div>

				<PublicInfo subtext="profile picture" />

				<AddCaption
					onChange={handleCaption}
					disabled={!image}
					value={caption}
				/>

				<div className={styles.img_options}>
					<input
						type="file"
						accept="image/png, image/jpg, image/jpeg"
						onChange={(e) => {
							handleImage(e);
							e.target.value = "";
						}}
						ref={inputFile}
						style={{ display: "none" }}
					/>
					<button
						onClick={() => {
							inputFile.current.click();
						}}
					>
						<img
							src={uploadIcon}
							className={styles.icon}
							alt={"upload"}
						/>
						Upload Profile Picture
					</button>

					{/* //*  can only make an image temporary when it's just been uploaded  */}
					{image instanceof Blob && tempTime === 0 && (
						<button onClick={() => setShowTempModal(true)}>
							<img
								src={tempIcon}
								className={styles.icon}
								alt={"temporary"}
							/>
							Temporary Profile Picture
						</button>
					)}

					{tempTime !== 0 && (
						<button
							onClick={() => {
								//* when already having a temp time
								if (userTempTime !== 0) {
									dispatch(
										editCompany
											? removeTempCompany(userData.uuid)
											: removeTemp()
									);
								} else {
									//* when just set temp time without saving it
									setTempTime(0);
								}

								// dispatch(selectedTempTimeReq(0));
							}}
						>
							<img
								src={tempIcon}
								className={styles.icon}
								alt={"temporary"}
							/>
							Remove Temporary Profile Picture
						</button>
					)}

					{typeof image === "string" && tempTime !== 0 && (
						<button
							onClick={() =>
								dispatch(
									editCompany
										? makeTempPermanentCompany(
												userData.uuid
										  )
										: makeTempPermanent()
								)
							}
						>
							<img
								src={tempIcon}
								className={styles.icon}
								alt={"temporary"}
							/>
							Make Temporary Profile Picture permanent
						</button>
					)}

					{image && !frame && (
						<button onClick={() => setShowFrameModal(true)}>
							<img
								src={frameIcon}
								className={styles.icon}
								alt="add frame"
							/>
							Add Frame
						</button>
					)}

					{image && frame && (
						<button
							onClick={() => {
								setFrame(undefined);
								setFrameId(undefined);
							}}
						>
							<img
								src={frameIcon}
								className={styles.icon}
								alt="remove frame"
							/>
							Remove Frame
						</button>
					)}

					{image && (
						<button onClick={handleDownload}>
							<img
								src={downloadIcon}
								className={styles.icon}
								alt="download"
							/>
							Download
						</button>
					)}

					{image && (
						<button onClick={() => setDeleteModalOpened(true)}>
							<img
								src={deleteIcon}
								className={styles.icon}
								alt="Delete"
							/>
							Delete Profile Picture
						</button>
					)}
				</div>

				<FeedShare
					checked={shareCheck}
					onChange={() => setShareCheck((prevState) => !prevState)}
				/>

				<div className={styles.button_wrapper}>
					<PrimaryButton
						onClick={handleSave}
						isLoading={isLoading}
						type="button"
						text={"save"}
						width={130}
					/>
				</div>
			</div>

			<WarningModal
				show={deleteModalOpened}
				headerText="Are you sure you want to delete this image?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setDeleteModalOpened(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => {
					deleteProfilePic(editCompany && userData.uuid);
				}}
			/>

			<PromptModal when={onBlock} />

			<CropModal
				image={image instanceof Blob && URL.createObjectURL(image)}
				show={cropModal}
				onClose={() => setCropModal(false)}
				aspectMode={1}
				isCircle={true}
				onCrop={(cropped) => {
					cropped.fileName = image.name;
					setImage(cropped);
					setCropModal(false);
				}}
				returnBlob={true}
				canEscape={false}
			/>

			<FrameModal
				show={showFrameModal}
				onClose={() => setShowFrameModal(false)}
				imagePreview={
					image instanceof Blob ? URL.createObjectURL(image) : image
				}
				onSelect={(frameUrl, frameId) => {
					setFrame(frameUrl);
					setFrameId(frameId);
					setShowFrameModal(false);
				}}
			/>

			<TempModal
				show={showTempModal}
				onClose={() => setShowTempModal(false)}
				imagePreview={
					image instanceof Blob && URL.createObjectURL(image)
				}
				onSelect={(temp) => {
					setTempTime(temp);
					setShowTempModal(false);
				}}
			/>
		</>
	);
};

export default Index;
