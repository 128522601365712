import { urlRegex } from "components/Utils/General";
import Joi from "joi";

const specialProfileSchema = Joi.object({
	name: Joi.string()
		.required()
		.label("Profile Name")
		.messages({ "string.empty": "Profile Name can't be empty" }),
	url: Joi.string()
		.pattern(urlRegex)
		.required()
		.max(20)
		.label("Special Profile URL")
		.messages({
			"string.empty": "Special Profile URL can't be empty",
			"string.pattern.base": "Invalid characters",
			"string.max":
				"Special Profile URL can't be more than 20 characters",
		}),
	urlValidity: Joi.boolean()
		.required()
		.valid(true)
		.label("URL Availability")
		.messages({ "any.only": "URL is already taken" }),
	file: Joi.array()
		.min(1)
		.messages({ "array.min": "Picture is a mandatory field" }),
	type: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Profile Type can't be empty",
			"object.base": "Profile Type can't be empty",
		})
		.label("Profile Type"),
	privacy: Joi.string().valid("PUBLIC", "AGENCY", "PRIVATE").required(),
});

export default specialProfileSchema;
