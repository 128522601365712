import React from "react";
import styles from "./Tag.module.css";

import removeIcon from "images/cancel-icon-graywhite.svg";
import { useRef } from "react";

const Tag = ({ itemName, onRemove, customStyle, onClick }) => {
	const buttonRef = useRef();

	const handleKeyUp = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			buttonRef.current.click();
		}
	};

	return (
		<div
			className={`${styles.tag_wrapper} ${customStyle}`}
			onClick={onClick}
		>
			{itemName}
			{onRemove && (
				<button onClick={onRemove} type="button" onKeyUp={handleKeyUp}>
					<img src={removeIcon} alt="Remove" />
				</button>
			)}
		</div>
	);
};

export default Tag;
