import Footer from "components/Utils/Footer/Footer";
import styles from "./CenterContainer.module.scss";

const CenterContainer = ({ children, bgColor }) => {
	return (
		<div className={styles.container} style={{ backgroundColor: bgColor }}>
			<div className={styles.center_wrapper}>
				{children}

				<div className={styles.footer_container}>
					<Footer />
				</div>
			</div>
		</div>
	);
};

export default CenterContainer;
