import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../../Utils/SubComs/CustomSelect/CustomSelect";
import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { addPhone, updatePhone } from "../../../../../store/slices/user";
import {
	addCompanyPhone,
	updateCompanyPhone,
} from "../../../../../store/slices/company";

import styles from "./Phone.module.css";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import PrimaryButton from "../../../../Utils/Button/PrimaryButton";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PromptModal from "components/Utils/Prompt/PromptModal";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useFetchLocation from "hooks/useFetchLocation";
import { dialCodeFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import phoneSchema from "./phone-schema";
import { capitalize } from "components/Utils/General";

const itemsPerPage = 20;

const PhoneEntry = ({ editCompany }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state, pathname } = useLocation();

	const editMode = pathname === `/profile/contact/edit`;

	const companyId = useSelector((state) => state.company.companyInfo.uuid);

	const {
		uuid,
		description,
		mobile: phone,
		type,
		code,
		visibility: currentVisibility,
	} = state || {};

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(phoneSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					description,
					type: {
						label: capitalize(type), //type?.charAt(0) + type?.slice(1).toLowerCase(),
						value: type,
					},
					dialCode: { label: code, value: code },
					phone,
			  }
			: {
					description: "",
					type: null,
					dialCode: null,
					phone: "",
			  },
	});

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const [visibility, setVisibility] = useState(
		currentVisibility ?? "WORK_CIRCLE_FRIENDS"
	);

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	const handleSave = (data) => {
		const {
			description,
			dialCode: { value: code },
			phone: mobile,
			type: { value: type },
		} = data;

		const body = {
			...(editMode && { uuid }),
			description,
			type,
			code,
			mobile,
			visibility,
		};

		if (editCompany) {
			dispatch(
				editMode
					? updateCompanyPhone(body, companyId)
					: addCompanyPhone(body, companyId)
			);
		} else {
			dispatch(editMode ? updatePhone(body) : addPhone(body));
		}
		setShouldBlockSave(false);
	};

	const fetchCountries = useFetchLocation(false, itemsPerPage);

	useEffect(() => {
		if (!shouldBlockSave) navigate("/profile/contact");
	}, [navigate, shouldBlockSave]);

	if (editMode && !state) {
		return <Navigate to={"/profile/contact"} />;
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.contact_section}>
					<form
						className={styles.add_form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<div className={styles.form_input_wrapper}>
							<label>
								Description / Label
								<span className="required">*</span>
							</label>
							<TextInput
								{...register("description")}
								error={!!errors.description}
								placeholder="Description"
							/>
							{errors?.description?.message && (
								<p className={styles.error_message}>
									{errors?.description?.message}
								</p>
							)}
						</div>

						<div className={styles.react_select_wrapper}>
							<label>
								Contact type
								<span className="required">*</span>
							</label>

							<CustomSelect
								fontSize="14px"
								placeholder="Contact Type"
								height="35px"
								options={[
									{
										value: "MOBILE",
										label: "Mobile",
									},
									{
										value: "LANDLINE",
										label: "Landline",
									},
								]}
								isSearchable={false}
								error={!!errors.type}
								control={control}
								name="type"
							/>
							{errors?.type?.message && (
								<p className={styles.error_message}>
									{errors?.type?.message}
								</p>
							)}
						</div>

						<div className={styles.react_select_wrapper}>
							<label>
								Phone number
								<span className="required">*</span>
							</label>

							<div className={styles.phone_wrapper}>
								<div className={styles.phone_dial}>
									<InfiniteSearchInput
										queryName={"countries"}
										queryFn={fetchCountries}
										itemsPerPage={itemsPerPage}
										formatter={dialCodeFormatter}
										error={errors?.dialCode}
										control={control}
										name="dialCode"
										customStyle={styles.dialCode_input}
									/>
								</div>

								<div className={styles.form_input_wrapper}>
									<TextInput
										{...register("phone")}
										error={!!errors.phone}
										placeholder="Phone number"
									/>
									{errors?.phone?.message && (
										<p className={styles.error_message}>
											{errors?.phone?.message}
										</p>
									)}
								</div>
							</div>
						</div>

						<div className={styles.visibility_wrapper}>
							<label className={styles.visibility_label}>
								Visibility
								<span className="required">*</span>
							</label>

							<VisibilitySelector
								value={visibility}
								onValueChange={handleVisibilityChange}
							/>
						</div>

						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								text={"save"}
							/>
						</div>
					</form>
				</div>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default PhoneEntry;
