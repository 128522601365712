import React, {
	cloneElement,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import styles from "../Header.module.css";
import { useLocation } from "react-router-dom";
import Filter from "../../components/Utils/Filter/Filter";
import useWindowSize from "../../hooks/useWindowSize";
import useUserLocation from "../../hooks/useUserLocation";
import CityModal from "../../components/Boards/BoardsComponents/CityModal";
import { useDispatch } from "react-redux";
import { resetFilters, updateFilters } from "../../store/slices/search";
import Tag from "../../components/Utils/SubComs/Tags/Tag";
import PeopleFilter from "../../components/Search/FilterTabs/PeopleFilter";
import People from "../../components/More/PeoplePage/People";
import profileApi from "../../api/profile";
import InfiniteUsersPage from "../../components/More/MoreComponents/UsersComponents/InfiniteUsersPage";
import { peopleInitialFilters } from "../../components/Search/initialFilters";
import searchApi from "../../api/search";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import { featuresSection } from "components/Utils/General";

const PeopleView = () => {
	const { pathname } = useLocation();
	const leftSideRef = useRef();

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const [filterModal, setFilterModal] = useState();

	useEffect(() => {
		if (filterModal && !isMobile) setFilterModal(false);
	}, [isMobile, filterModal]);

	const [showModal, setShowModal] = useState(false);
	const [countryFlag, setCountryFlag] = useState();

	const { userCoordinates, handleSelectedCity } =
		useUserLocation(setCountryFlag);

	const dispatch = useDispatch();

	const [newInput, setNewInput] = useState("");
	const [selectedFilters, setSelectedFilters] = useState({});

	const handleFilters = (selectedFilters) => {
		setSelectedFilters(selectedFilters);

		let formattedFilters = {};

		for (const [key, values] of Object.entries(selectedFilters)) {
			if (!Array.isArray(values)) {
				formattedFilters[key] = values;
			} else if (values.length > 0) {
				formattedFilters[key] = values.map((item) => item.value);
			} else {
				formattedFilters[key] = [];
			}
		}

		dispatch(updateFilters(formattedFilters));
	};

	const tagsList = useMemo(() => {
		const valuesList = [];
		for (const [k, v] of Object.entries(selectedFilters)) {
			if (Array.isArray(v) && v?.length > 0) {
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.label}
							itemName={`${item.label}`}
							onRemove={() =>
								leftSideRef.current.updateDynamicFilters(
									k,
									item
								)
							}
							customStyle={styles.filter_tag}
						/>
					)
				);
			}
		}
		return valuesList;
	}, [selectedFilters]);

	let component;
	let headerTitle;

	switch (pathname) {
		case "/people":
			component = <People />;
			headerTitle = newInput ? "Search People" : "People";
			break;

		case "/people/network":
			component = (
				<InfiniteUsersPage
					queryKey={["infiniteNetworkPeople"]}
					endpoint={profileApi.getNetworkPeople}
				/>
			);
			headerTitle = "People you may know";
			break;

		case "/people/nearby":
			component = (
				<InfiniteUsersPage
					queryKey={["infiniteNearbyPeople", userCoordinates]}
					endpoint={profileApi.getPeopleNearby}
				/>
			);
			headerTitle = "People in your area";
			break;

		case "/people/my-company":
			component = (
				<InfiniteUsersPage
					queryKey={["infiniteMyCompanyPeople"]}
					endpoint={profileApi.getPeopleFromMyCompany}
				/>
			);
			headerTitle = "People who work in your company";
			break;

		case "/people/similar":
			component = (
				<InfiniteUsersPage
					queryKey={["infiniteInterestPeople", userCoordinates]}
					endpoint={profileApi.getPeopleInterests}
				/>
			);
			headerTitle = "People with similar interests";
			break;

		default:
			break;
	}

	//* >> dispatch(resetFilters()) whenever leaving the 'people' page because filters in the store are used in other components

	// useEffect(() => {
	//     const unlisten = history.listen((location) => {
	//         if (!location.pathname.startsWith("/people")) {
	//             dispatch(resetFilters());
	//         }
	//     });

	//     return unlisten;
	// }, [dispatch, history, currentPath.pathname]);

	useEffect(() => {
		if (!pathname.startsWith("/people")) dispatch(resetFilters());
	}, [dispatch, pathname]);

	return (
		<>
			<Filter
				title={headerTitle}
				isMobile={isMobile}
				onCloseModal={() => setFilterModal(false)}
				hiddenMode={!filterModal}
				headerButton={
					<button onClick={() => setShowModal(true)}>
						<img src={countryFlag} alt="" />
					</button>
				}
				leftSideChildren={
					newInput ? (
						<PeopleFilter
							ref={leftSideRef}
							inputValue={newInput}
							onChange={handleFilters}
							onResetFilters={handleFilters}
							selectedFilters={selectedFilters}
							userCoordinates={userCoordinates}
						/>
					) : (
						<FeaturesSidebar active={featuresSection.people} />
					)
				}
				rightSideChildren={cloneElement(component, {
					onInputChange: setNewInput,
					userCoordinates: userCoordinates,
					isMobile: isMobile,
					onShowModal: () => setFilterModal(true),
					tagsList: tagsList,
					initialFilters: peopleInitialFilters,
					searchQueryKey: ["searchUsers", userCoordinates.id],
					searchEndpoint: searchApi.searchUsers,
				})}
			/>

			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default PeopleView;
