import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { pluralize } from "../../Utils/General";
import styles from "./Jobs.module.scss";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import filterIcon from "images/filter-icon-white.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import NewJobCard from "components/Jobs/JobsComponents/NewJobCard/NewJobCard";

const Jobs = ({
	count,
	data,
	isPaginated = false,
	onLoad,
	hasNextPage,
	isFetching,
	isLoading,
	isMobile,
	onShowModal,
	onClick,
	tagsList,
	onToggleSave,
}) => {
	const { ref: viewRef, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			onLoad();
		}
	}, [inView, hasNextPage, onLoad, isFetching]);

	const itemsList = useMemo(
		() =>
			isPaginated
				? data?.pages?.map((page) =>
						page?.list?.map((job, i) => (
							<div
								ref={
									page.list.length === i + 1 ? viewRef : null
								}
								key={job.uuid}
							>
								<NewJobCard
									data={job}
									onToggleSave={() =>
										onToggleSave({
											jobId: job.uuid,
											state: !job.savedJob,
										})
									}
								/>
							</div>
						))
				  )
				: data.map((job) => (
						<NewJobCard
							key={job.uuid}
							data={job}
							onToggleSave={() =>
								onToggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
								})
							}
						/>
				  )),
		[data, isPaginated, viewRef]
	);

	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			<div className={styles.title_wrapper}>
				<h4>{`${count} ${pluralize(count, "Job")} found`}</h4>
				{isMobile && isPaginated && (
					<button
						className={styles.filter_button}
						onClick={onShowModal}
					>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList?.length > 0 && (
				<div className={styles.active_filters}>{tagsList}</div>
			)}

			<div className={styles.items_wrapper}>{itemsList}</div>
			{isFetching && <LoadingSpinner />}

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text="See more" />
				</div>
			)}
		</>
	);
};

export default Jobs;
