import React, { useEffect, useState, useRef, useMemo } from "react";
import styles from "./ChatLeftSide.module.css";
import Conversation from "./Conversation";
// import profileApi from "../../api/profile";
import chatApi from "../../api/chat";
import useApi from "../../hooks/useApi";
import ContactSearch from "./ContactSearch";
import GroupMember from "./GroupMember";
import useDebounce from "../../hooks/useDebounce";
import { useInView } from "react-intersection-observer";
import conversationIcon from "images/conversation.svg";
import { useInfiniteQuery } from "@tanstack/react-query";
import NewGroupModal from "./ChatComponents/GroupModal/NewGroupModal";
import LoadingSpinner from "../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "../Utils/SubComs/NoResults/NoResults";
import ConvoLeftHeader from "./ChatComponents/ConvoLeftHeader/ConvoLeftHeader";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import useProfileSearch from "hooks/useProfileSearch";
import useVaultStorage from "hooks/useVaultStorage";
import {
	getStorageWarningText,
	showStorageWarning,
} from "components/Utils/General";
import StorageCard from "components/Storage/StorageComponents/StorageCard";

const itemsPerPage = 20;

const ChatLeftSide = ({
	conversations,
	convRef,
	loading,
	conversation: selectedConversation,
	onConversationClicked,
	onNewConv,
	onCreateGroup,
	tempConv,
}) => {
	const { data: vaultData, usedSpaceInPercentage } = useVaultStorage();

	// const searchProfileApi = useApi(profileApi.search, true, true);
	const searchConversationsApi = useApi(
		chatApi.searchConversations,
		true,
		true
	);

	const [startConversation, setStartConversation] = useState(false);
	const [searchInput, setSearchInput] = useState("");
	const [newConvSearchInput, setNewConvSearchInput] = useState("");
	const searchRef = useRef(null);
	const newGroupModalRef = useRef(null);

	const handleNewConversation = (profile) => {
		onNewConv(profile);
		setNewConvSearchInput("");
		setStartConversation(false);
	};

	const handleNewMsg = () => {
		setNewConvSearchInput("");
		setStartConversation(true);
		searchRef.current.focus();
	};

	const handleNewGroup = () => {
		setStartConversation(false);
		setNewConvSearchInput("");
		newGroupModalRef.current.handleNewGroup();
	};

	// *===================== SEARCH PROFILE HANDLER =======================

	const debouncedSearch = useDebounce(searchInput);
	const debouncedSearchNewConv = useDebounce(newConvSearchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		if (startConversation) {
			setNewConvSearchInput(query);
		} else {
			setSearchInput(query);
		}
	};

	const { ref: viewRef, inView } = useInView();

	const { data, hasNextPage, fetchNextPage, isFetching } = useProfileSearch(
		debouncedSearchNewConv,
		startConversation ? true : false,
		itemsPerPage
	);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	const p2pProfileList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((profile, i) => (
					<div
						key={profile.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						className={styles.contact_item}
						onClick={() => {
							handleNewConversation(profile);
						}}
					>
						<div className={styles.contact_item_wrapper}>
							<GroupMember
								key={profile.uuid}
								data={profile}
								isChatLeftSide={true}
								imgSize={40}
								disableDecoration={true}
							/>
						</div>
					</div>
				))
			),
		[data]
	);

	// !=========== SEARCH CONVO ============

	const { ref: searchConvoViewRef, inView: searchConvoInView } = useInView();
	const fetchSearchConvo = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchQuery] = queryKey;

		const response = await searchConversationsApi.request(
			searchQuery,
			pageParam,
			itemsPerPage
		);
		return response.data;
	};

	const {
		data: searchedConversations,
		hasNextPage: hasNextPageConvo,
		fetchNextPage: fetchNextPageConvo,
		isFetching: isFetchingConvo,
	} = useInfiniteQuery({
		queryKey: ["searchConversations", debouncedSearch],
		queryFn: fetchSearchConvo,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		enabled: !startConversation && debouncedSearch ? true : false,
	});

	useEffect(() => {
		if (searchConvoInView && hasNextPageConvo) {
			fetchNextPageConvo();
		}
	}, [searchConvoInView, hasNextPageConvo]);

	const searchedConvoList = useMemo(
		() =>
			searchedConversations?.pages?.map((page) =>
				page.map((conversation, i) => (
					<div
						key={conversation.uuid}
						ref={page.length === i + 1 ? searchConvoViewRef : null}
					>
						<Conversation
							conversation={conversation}
							onclick={() => {
								onConversationClicked(conversation);
							}}
							active={
								selectedConversation &&
								conversation.uuid ===
									selectedConversation.uuid &&
								!tempConv
							}
						/>
					</div>
				))
			),
		[searchedConversations, selectedConversation]
	);

	const conversationsList = useMemo(
		() =>
			conversations?.pages?.map((page) =>
				page.map((conversation, i) => (
					<div
						key={conversation.uuid}
						ref={page.length === i + 1 ? convRef : null}
					>
						<Conversation
							conversation={conversation}
							onclick={() => {
								onConversationClicked(conversation);
							}}
							active={
								selectedConversation &&
								conversation.uuid ===
									selectedConversation.uuid &&
								!tempConv
							}
						/>
					</div>
				))
			),
		[
			convRef,
			conversations?.pages,
			onConversationClicked,
			selectedConversation,
			tempConv,
		]
	);

	const storageCard = showStorageWarning(usedSpaceInPercentage, data) && (
		<div className={styles.warning_wrapper}>
			<StorageCard
				text={getStorageWarningText(
					"default",
					usedSpaceInPercentage,
					vaultData
				)}
			/>
		</div>
	);

	// !=============================================================================

	return (
		<>
			<div className={styles.left_side_container}>
				<div className={styles.profile_container}>
					<ConvoLeftHeader
						onGroup={handleNewGroup}
						onMessage={handleNewMsg}
					/>
				</div>

				<div className={styles.search_container}>
					<ContactSearch
						searchRef={searchRef}
						showBackArrow={startConversation}
						searchInput={
							startConversation ? newConvSearchInput : searchInput
						}
						onSearchInputChange={handleSearchInputChange}
						onClear={() => {
							startConversation
								? setNewConvSearchInput("")
								: setSearchInput("");
						}}
						onBack={() => {
							setNewConvSearchInput("");
							setStartConversation(false);
						}}
					/>
				</div>

				{startConversation ? (
					<div className={styles.start_conversation_container}>
						{storageCard}
						{p2pProfileList}
						{<LoadingSpinner visible={isFetching} />}
						<NoResults
							isSearch={true}
							visible={
								p2pProfileList &&
								p2pProfileList[0].length === 0 &&
								!isFetching
							}
							text={"No profiles found related to your search"}
						/>
					</div>
				) : searchInput ? (
					<div className={styles.conversation_list_container}>
						{storageCard}

						{searchedConvoList}
						{<LoadingSpinner visible={isFetchingConvo} />}
						<NoResults
							isSearch={true}
							visible={
								searchedConvoList &&
								searchedConvoList[0].length === 0 &&
								!isFetchingConvo
							}
							text={"No conversations related to your search"}
						/>
					</div>
				) : (
					<div className={styles.conversation_list_container}>
						{storageCard}

						{conversationsList}
						{<LoadingSpinner visible={loading} />}
						<NoResults
							visible={conversations?.pages[0].length === 0}
							style={{ height: "100%" }}
							image={conversationIcon}
							imageStyle={styles.conversation_icon}
							text={
								"Ready to chat?\n Say hi to your friends here!"
							}
							button={
								<PrimaryButton
									onClick={handleNewMsg}
									type="button"
									text={"browse contact"}
								/>
							}
						/>
					</div>
				)}
			</div>

			<NewGroupModal
				ref={newGroupModalRef}
				onCreateGroup={(newGroup) => onCreateGroup(newGroup.data)}
			/>
		</>
	);
};

export default ChatLeftSide;
