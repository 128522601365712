import React from "react";
import Loader from "react-loader-spinner";
import styles from "./LoadingPage.module.css";

const LoadingPage = ({ customStyle, fullPage = false }) => {
	return (
		<div
			className={`${styles.loading_container} ${
				fullPage ? styles.fullPage : undefined
			} ${customStyle}`}
		>
			<Loader type="Oval" color="#6cc5d1" height={80} width={80} />
		</div>
	);
};

export default LoadingPage;
