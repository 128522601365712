import { Navigate } from "react-router-dom";
import styles from "./LandingPage.module.scss";
import routes from "components/Routing/routing-keys";
import LandingPageIntro from "components/Landing/LandingPageIntro";
import LandingPageDescription from "components/Landing/LandingPageDescription";
import LandingPageFooter from "components/Landing/LandingPageFooter";
import LandingPageHeader from "components/Landing/LandingPageHeader";
import LandingPageSlider from "components/Landing/LandingPageSlider";

const LandingPage = () => {
	const showLanding = localStorage.getItem("showLanding");

	if (showLanding === false) return <Navigate to={routes.login} />;

	return (
		<div className={styles.container}>
			<LandingPageHeader />
			<LandingPageIntro />
			<LandingPageSlider />
			<LandingPageDescription />
			<LandingPageFooter />
		</div>
	);
};

export default LandingPage;
