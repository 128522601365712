import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";

const GroupParticipants = ({
	title,
	data,
	isLoading,
	hasNextPage,
	onSeeMore,
}) => {
	return (
		<>
			<h3>{title}</h3>
			<div>{data}</div>
			<LoadingSpinner visible={isLoading} />
			{hasNextPage && (
				<SeeMore
					text="See more"
					onClick={onSeeMore}
					style={{ marginTop: "20px" }}
				/>
			)}
		</>
	);
};

export default GroupParticipants;
