import { useEffect } from "react";
import useDebounce from "../../../../../hooks/useDebounce";
import useApi from "../../../../../hooks/useApi";
import servicesApi from "../../../../../api/services";
import { toast } from "react-toastify";

export const useTag = (nameWatcher, setValue, step2, service) => {
	const getTagApi = useApi(servicesApi.getTag, true);
	const debouncedInput = useDebounce(nameWatcher);

	useEffect(() => {
		const fetchTag = async (title) => {
			const response = await getTagApi.request(title);
			if (response.status === 200) {
				setValue("tag", response.data.tag);
			} else {
				toast.error("Failed to fetch tag");
			}
		};

		if (!service) {
			if (debouncedInput === step2?.title) setValue("tag", step2?.tag);
			else if (debouncedInput) fetchTag(debouncedInput);
			else setValue("tag", "");
		}
	}, [debouncedInput]);

	return debouncedInput;
};
