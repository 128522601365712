import Tag from "components/Utils/SubComs/Tags/Tag";
import React, { useMemo } from "react";

export const useServiceTag = (setValue, tagsWatcher) => {
	const handleServicesChange = (serviceTags) => {
		setValue("serviceTags", serviceTags);
	};

	const handleDeleteService = (index) => {
		const updatedArray = tagsWatcher.filter((service, i) => i !== index);
		setValue("serviceTags", updatedArray);
	};

	const serviceTagsList = useMemo(
		() =>
			tagsWatcher?.map((service, index) => (
				<Tag
					key={service.uuid}
					itemName={service.name}
					onRemove={() => handleDeleteService(index)}
				/>
			)),
		[tagsWatcher]
	);
	return { serviceTagsList, handleServicesChange };
};
