import React, { forwardRef } from "react";
import styles from "./CustomRadioButton.module.css";

const CustomRadioButton = forwardRef(
	(
		{
			id,
			name,
			checked,
			onChange,
			readOnly = false,
			value,
			disabled = false,
			customStyle = undefined,
			onClick,
		},
		ref
	) => {
		return (
			<input
				ref={ref}
				className={`${styles.input} ${customStyle}`}
				type="radio"
				id={id}
				name={name}
				checked={checked}
				readOnly={readOnly}
				onChange={onChange}
				value={value}
				tabIndex={0}
				disabled={disabled}
				onClick={onClick ? onClick : (e) => e.stopPropagation()}
			/>
		);
	}
);

export default CustomRadioButton;
