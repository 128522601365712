import LandingPageFooter from "components/Landing/LandingPageFooter";
import TopBar from "../TopBar";
import styles from "./About.module.scss";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const About = () => {
	return (
		<div className={styles.container}>
			<TopBar />
			<div className={styles.body_container}>
				<NoResults visible={true} />
				Page under construction
			</div>
			<LandingPageFooter />
		</div>
	);
};

export default About;
