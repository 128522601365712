import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showModal: false,
	product: undefined,
	locationModal: false,
};

const slice = createSlice({
	name: "products",
	initialState,
	reducers: {
		setModalProduct: (state, action) => {
			state.showModal = action.payload;
		},

		setProduct: (state, action) => {
			state.product = action.payload;
		},

		setLocationModal: (state, action) => {
			state.locationModal = action.payload;
		},

		reset: () => {
			return initialState;
		},
	},
});

export const { setModalProduct, setProduct, reset, setLocationModal } =
	slice.actions;
export default slice.reducer;
