import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./Products.module.scss";
import ProductItem from "../../Profile/ServicesTab/ServicesComponent/ProductItem";
import { copyToClipboard, pluralize } from "../../Utils/General";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import filterIcon from "images/filter-icon-white.svg";
import SortingButtons from "../../Utils/SubComs/SortingButtons/SortingButtons";
import ProductItemList from "../../Profile/ServicesTab/ServicesComponent/ProductItemList";
import useCompanyId from "../../../hooks/useCompanyId";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useDirectMessage from "../../../hooks/useDirectMessage";
import productApi from "../../../api/product";
import WarningModal from "../../Utils/GaawkModal/WarningModal";
import useMutate from "../../../hooks/useMutate";
import { useNavigate } from "react-router-dom";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

const Products = ({
	count,
	data,
	isPaginated = false,
	onLoad,
	hasNextPage,
	isFetching,
	isLoading,
	isMobile,
	onShowModal,
	order,
	layout,
	onChangeOrder,
	onChangeLayout,
	onClick,
	tagsList,
	onRefresh,
}) => {
	const { handleDirectMessage } = useDirectMessage();
	const navigate = useNavigate();

	const companyId = useCompanyId();

	const { uuid: userId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const { ref: viewRef, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			onLoad();
		}
	}, [inView, hasNextPage, onLoad, isFetching]);

	const handleCopyLink = (productId) => {
		copyToClipboard(`${window.location.origin}/products/${productId}`);
		toast.success("Link copied !");
	};

	const handleEditProduct = (product) => {
		const {
			owner: { url },
			uuid: productId,
		} = product;

		// /profile/${userUrl}/product/${productId}

		navigate(`/profile/${url}/product/${productId}`, {
			state: { isEdit: true },
		});
		// navigate(`/products/${product.uuid}/edit`);
	};

	// ! ==== DELETE HANDLERS ======

	const {
		warningModal,
		setWarningModal,
		handleRequestDelete,
		handleConfirmDelete,
	} = useMutate(productApi.deleteProduct, onRefresh);

	// !===================================================================

	const itemsList = useMemo(
		() =>
			isPaginated
				? data?.pages?.map((page) =>
						page?.list?.map((product, i) => {
							return layout === "grid" ? (
								<div
									ref={
										page.list.length === i + 1
											? viewRef
											: null
									}
									key={product.uuid}
								>
									<ProductItem
										isOwner={userId === product.owner.uuid}
										item={product}
										onCopy={() =>
											handleCopyLink(product.uuid)
										}
										onMessage={() =>
											handleDirectMessage(product.owner)
										}
										onEdit={() =>
											handleEditProduct(product)
										}
										onDelete={() =>
											handleRequestDelete(product.uuid)
										}
									/>
								</div>
							) : (
								<div
									ref={
										page.list.length === i + 1
											? viewRef
											: null
									}
									key={product.uuid}
								>
									<ProductItemList
										isOwner={userId === product.owner.uuid}
										item={product}
										onCopy={() =>
											handleCopyLink(product.uuid)
										}
										onMessage={() =>
											handleDirectMessage(product.owner)
										}
										onEdit={() =>
											handleEditProduct(product)
										}
										onDelete={() =>
											handleRequestDelete(product.uuid)
										}
									/>
								</div>
							);
						})
				  )
				: data.map((product) => (
						<ProductItem
							key={product.uuid}
							isOwner={userId === product.owner.uuid}
							item={product}
							onCopy={() => handleCopyLink(product.uuid)}
							onMessage={() => handleDirectMessage(product.owner)}
							onEdit={() => handleEditProduct(product)}
							onDelete={() => handleRequestDelete(product.uuid)}
						/>
				  )),
		[data, layout]
	);

	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			<div className={styles.title_wrapper}>
				<h4>{`${count} ${pluralize(count, "Product")} found`}</h4>
				<div className={styles.controls}>
					{isPaginated && (
						<SortingButtons
							order={order}
							stateLayout={layout}
							onChangeOrder={onChangeOrder}
							onChangeLayout={onChangeLayout}
						/>
					)}
					{isMobile && isPaginated && (
						<button
							className={styles.filter_button}
							onClick={onShowModal}
						>
							<img src={filterIcon} alt="" />
						</button>
					)}
				</div>
			</div>

			{tagsList?.length > 0 && (
				<div className={styles.active_filters}>{tagsList}</div>
			)}

			<div
				className={`${styles.items_wrapper} ${
					layout === "list" ? styles.list : undefined
				}`}
			>
				{itemsList}
			</div>

			{isFetching && <LoadingSpinner />}

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text="See more" />
				</div>
			)}

			<WarningModal
				show={warningModal}
				modalOnTop={true}
				headerText="Are you sure you want to delete this product?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DISCARD"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Products;
