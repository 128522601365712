import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Profile",
    initialState: {
        list: [],
    },
    reducers: {
        profileListUpdated: (state, action) => {
            state.list = action.payload;
        },
        resetProfiles: (state) => {
            state.list = [];
        },
    },
});

export const { profileListUpdated, resetProfiles } = slice.actions;
export default slice.reducer;

/**
 * search profiles
 */

export const searchProfiles = (name) =>
    apiActions.apiCallBegan({
        url: `profile/search?q=${name}&page=0&size=10`,
        method: "GET",
        onSuccess: profileListUpdated.type,
    });
