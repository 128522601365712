import React from "react";
import styles from "./Chat.module.css";
import ProfilePic from "../Home/ProfilePic";
// import conversation from "../../store/slices/conversation";

const ShowChatProfile = ({
	data,
	typing = false,
	handleProfileClick,
	fontColor = undefined,
	// isGroup,
	// totalparticipants,
}) => {
	const { conversationType, minifiedProfile, groupDTO } = data;

	const convoName =
		conversationType === "Default"
			? minifiedProfile.deleted
				? "Deleted profile"
				: minifiedProfile.name
			: groupDTO.name;

	return (
		<div className={styles.profile}>
			<ProfilePic
				type={
					data.minifiedProfile?.type
						? data.minifiedProfile.type
						: conversationType === "Group"
						? "COMPANY"
						: "USER"
				}
				thumbnail={
					conversationType === "Group"
						? data.groupDTO?.image?.file.customName
						: data.minifiedProfile?.profileImage?.image
				}
				handleProfileClick={handleProfileClick}
				name={convoName}
				enableName={false}
			/>
			<div className={styles.profile_info} style={{ color: fontColor }}>
				<div className={styles.name} onClick={handleProfileClick}>
					{convoName}
				</div>
				{conversationType === "Group" && (
					<div className={styles.status}>
						{`${data.groupDTO.totalParticipants} Participants`}
						{/* {conversationType === "Group"
						? `${data.groupDTO.totalParticipants} Participants`
						: typing
						? `typing...`
						: `online`} */}
					</div>
				)}
			</div>
		</div>
	);
};

export default ShowChatProfile;
