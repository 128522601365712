import ThreeColumnPage from "components/Utils/PageArchs/ThreeColumnPage/ThreeColumnPage";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import HomeRightSidebar from "components/Home/Components/HomeRightSidebar/HomeRightSidebar";
import ErrorPage from "./ErrorPage";

const RootBoundary = () => {
	return (
		<ThreeColumnPage
			leftSideChildren={<HomeLeftSidebar />}
			centerChildren={<ErrorPage />}
			rightSideChildren={<HomeRightSidebar />}
			bgColor={"#E2F3F6"}
		/>
	);
};

export default RootBoundary;
