import { useState, useEffect, useMemo } from "react";
import GroupMember from "components/Chat/GroupMember";
import { getReactionImg } from "../General";
import styles from "./ReactionsModal.module.scss";
import GaawkModal from "../GaawkModal/GaawkModal";
import postApi from "api/post";
import useApi from "hooks/useApi";
import ScrollContainer from "react-indiana-drag-scroll";
import { useInView } from "react-intersection-observer";
import usePaginatedResource from "hooks/usePaginatedResource";
import { postsKeys } from "queryKeys/posts-key-factory";
import ContactLoader from "../SubComs/CustomLoader/ContactLoader";

const itemsPerPage = 20;

const ReactionsModal = ({
	show,
	onClose,
	title,
	itemId,
	reactionStats,
	type,
	activeTab = "All",
}) => {
	const { stats } = reactionStats || {};
	const [selectedTab, setSelectedTab] = useState(activeTab);

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getReactionApi = useApi(postApi.getReaction, true, true);

	const fetchReactions = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, reactionFrom, selectedTab, id] = queryKey;
		const response = await getReactionApi.request(
			reactionFrom,
			id,
			pageParam,
			itemsPerPage,
			selectedTab !== "All" ? selectedTab : ""
		);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			postsKeys.reactions(type, selectedTab, itemId),
			fetchReactions,
			itemsPerPage,
			show
		);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	const tabList = useMemo(
		() =>
			stats?.map((reaction) => (
				<div
					key={reaction.reaction}
					className={styles.tab_button_wrapper}
				>
					<button
						className={styles.button}
						onClick={() => setSelectedTab(reaction.reaction)}
					>
						<img
							src={getReactionImg(reaction.reaction)}
							alt={reaction.reaction}
						/>
						<h4>{reaction.reaction}</h4>
					</button>
					<span
						className={`${
							selectedTab === reaction.reaction
								? styles.selected_tab
								: undefined
						}`}
					/>
				</div>
			)),
		[selectedTab, stats]
	);

	const profileList = useMemo(
		() =>
			data?.pages.map((page) =>
				page.map((profile, i) => (
					<div
						key={profile.minifiedProfile.uuid}
						className={styles.contact_item}
						ref={page.length === i + 1 ? viewRef : null}
					>
						<div className={styles.contact_item_wrapper}>
							<GroupMember
								redirect={true}
								data={profile.minifiedProfile}
								icon={getReactionImg(profile.reaction)}
								customStyle={styles.user_item}
								iconSize={24}
							/>
						</div>
					</div>
				))
			),
		[data]
	);

	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign={"right"}
			modalOnTop={true}
			children={
				<>
					<div className={styles.tabs}>
						<ScrollContainer className={styles.tabs_container}>
							<div className={styles.tab_button_wrapper}>
								<button
									className={styles.button}
									onClick={() => setSelectedTab("All")}
								>
									<h4>All</h4>
								</button>
								<span
									className={`${
										selectedTab === "All"
											? styles.selected_tab
											: undefined
									}`}
								/>
							</div>
							{tabList}
						</ScrollContainer>
						<hr />
					</div>

					<div className={styles.container}>
						{profileList}
						{isFetching && <ContactLoader />}
					</div>
				</>
			}
		/>
	);
};

export default ReactionsModal;
