import { useQueryClient } from "@tanstack/react-query";
import { router } from "AppRoutes";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import FileSaver from "file-saver";
import useCurrentUser from "hooks/useCurrentUser";
import useVaultThumbCount from "hooks/useVaultThumbCount";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavigationType } from "react-router";
import { toast } from "react-toastify";
import vaultApi from "../../api/vault";
import useApi from "../../hooks/useApi";
import usePaginatedData from "../../hooks/usePaginatedData";
import FileViewer from "../Utils/FileViewer/FileViewer";
import ViewerHeader from "../Utils/FileViewer/ViewerHeader";
import FullScreenModal from "../Utils/GaawkModal/FullScreenModal";
import {
	removeItemIdFromArray,
	replaceItemInArray,
	viewerMapper,
} from "../Utils/General";
import UserVault from "./UserVault";
import VaultActions from "./VaultActions";
import VaultDeleteModal from "./VaultDeleteModal";
import VaultDetailsModal from "./VaultDetailsModal";
import VaultListItemsModal from "./VaultListItemsModal";
import VaultRenameModal from "./VaultRenameModal";
import styles from "./VaultRightSide.module.css";
import VaultShareModal from "./VaultShareModal";

const VaultRightSide = () => {
	/**
	 * Selectors
	 */

	const queryClient = useQueryClient();

	const { uuid: profileId } = useCurrentUser();

	// Refs
	const didMountRef = useRef(false);

	const userVaultRef = useRef();
	const fileviewerRef = useRef();

	// size
	// const { width } = useWindowSize();

	// const getRecentsApi = useApi(vaultApi.getRecents);

	const getMeItemsApi = useApi(vaultApi.getMeItems, true);
	const getSharedApi = useApi(vaultApi.getSharedItems, true);
	const getGaawkItemsApi = useApi(vaultApi.getGaawkItems, true);
	const getFavoriteApi = useApi(vaultApi.getFavoriteItems, true);

	// const getItemInfoApi = useApi(vaultApi.getItemInfo);
	// const shareItemsApi = useApi(vaultApi.shareItems);
	const removeItemsApi = useApi(vaultApi.removeItems);
	const deleteItemsApi = useApi(vaultApi.deleteItems);
	const createFolderApi = useApi(vaultApi.createFolder);
	const updateFavoriteApi = useApi(vaultApi.updateFavorite);
	const copyItemsApi = useApi(vaultApi.copyItems);
	const moveItemsApi = useApi(vaultApi.moveItems);
	const renameApi = useApi(vaultApi.renameItem);

	// STATES

	const displayCount = useVaultThumbCount();

	// const getDisplayCount = () => {
	// 	let count;

	// 	switch (true) {
	// 		case width >= 1608:
	// 			count = 18;
	// 			break;

	// 		case width >= 1400 && width < 1608:
	// 			count = 16;
	// 			break;

	// 		case width >= 1166 && width < 1400:
	// 			count = 12;
	// 			break;

	// 		case width >= 974 && width < 1166:
	// 			count = 8;
	// 			break;

	// 		case width >= 748 && width < 974:
	// 			count = 6;
	// 			break;

	// 		case width >= 640 && width < 748:
	// 			count = 10;
	// 			break;

	// 		case width < 640:
	// 			count = 6;
	// 			break;

	// 		default:
	// 			break;
	// 	}

	// 	return count;
	// };

	// const [displayCount] = useState(() => getDisplayCount());

	// info

	// recents
	// const [recents, setRecents] = useState([]);

	// ORDER

	const [meOrder, setMeOrder] = useState(
		() => localStorage.getItem("meSort") || "NEWEST"
	);

	// LAYOUT
	const [meLayout, setMeLayout] = useState(
		() => localStorage.getItem("meLayout") || "grid"
	);

	const [renameModal, setRenameModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [detailsModal, setDetailsModal] = useState(false);
	const [shareModal, setShareModal] = useState(false);
	const [copyModal, setCopyModal] = useState(false);
	const [moveModal, setMoveModal] = useState(false);
	const [targetFolderId, setTargetFolderId] = useState("");

	const [selectedItem, setSelectedItem] = useState("");
	const [selectedItems, setSelectedItems] = useState([]);

	// const [itemInfo, setItemInfo] = useState(null);

	// CUSTOM HOOKS

	const {
		data: meItems,
		loadMore: loadMoreMeItems,
		hasMore: hasMoreMeItems,
		loading: loadingMeItems,
		reload: reloadMeItems,
		setData: setMeItems,
	} = usePaginatedData(getMeItemsApi, displayCount, true);

	const {
		data: sharedItems,
		loadMore: loadMoreSharedItems,
		hasMore: hasMoreSharedItems,
		loading: loadingSharedItems,
		reload: reloadSharedItems,
		setData: setSharedItems,
	} = usePaginatedData(getSharedApi, displayCount);

	const {
		data: favoriteItems,
		loadMore: loadMoreFavoriteItems,
		hasMore: hasMoreFavoriteItems,
		loading: loadingFavoriteItems,
		reload: reloadFavoriteItems,
		setData: setFavoriteItems,
	} = usePaginatedData(getFavoriteApi, displayCount);

	const {
		data: gaawkItems,
		loadMore: loadMoreGaawkItems,
		hasMore: hasMoreGaawkItems,
		loading: loadingGaawkItems,
		reload: reloadGaawkItems,
		setData: setGaawkItems,
	} = usePaginatedData(getGaawkItemsApi, displayCount, true);

	// ======

	const getActiveTabItems = (fromStart = false) => {
		if (fromStart) {
			setMeItems([]);
			setSharedItems([]);
			setFavoriteItems([]);
			setGaawkItems([]);
		}
		switch (vaultTab) {
			case "files":
				loadMoreMeItems(undefined, fromStart ? 0 : undefined, meOrder);
				break;
			case "shared":
				loadMoreSharedItems(
					undefined,
					fromStart ? 0 : undefined,
					meOrder
				);
				break;
			case "favorites":
				loadMoreFavoriteItems(
					undefined,
					fromStart ? 0 : undefined,
					meOrder
				);
				break;
			case "gaawk":
				loadMoreGaawkItems(
					undefined,
					fromStart ? 0 : undefined,
					meOrder
				);
				break;
			default:
				break;
		}
	};

	const reloadActiveTabItems = () => {
		switch (vaultTab) {
			case "files":
				if (hasMoreMeItems) {
					reloadMeItems(undefined, undefined, meOrder);
				}
				break;
			case "shared":
				if (hasMoreSharedItems)
					reloadSharedItems(undefined, undefined, meOrder);
				break;
			case "favorites":
				if (hasMoreFavoriteItems)
					reloadFavoriteItems(undefined, undefined, meOrder);
				break;
			case "gaawk":
				if (hasMoreGaawkItems)
					reloadGaawkItems(undefined, undefined, meOrder);
				break;
			default:
				break;
		}
	};

	// !======== TAB CHANGE HANDLER ========

	const [vaultTab, setVaultTab] = useState(
		() => localStorage.getItem("meTab") || "files"
	);

	const handleTabChange = (tab) => {
		localStorage.setItem("meTab", tab);
		setVaultTab(tab);
	};

	useEffect(() => {
		//* TAB CHANGE
		if (didMountRef.current) {
			// setMeItems([]);
			// setSharedItems([]);
			// setFavoriteItems([]);
			// setGaawkItems([]);

			getActiveTabItems(true);
		}

		didMountRef.current = true;
	}, [vaultTab, meOrder]);

	// const getRecents = () => {
	//     getRecentsApi.request();
	// };

	useEffect(() => {
		// * FIRST LOAD
		getActiveTabItems(true);
	}, []);

	const changeUserItemsOrder = (sort) => {
		localStorage.setItem("meSort", sort);
		setMeOrder(sort);
	};

	// const [info, setInfo] = useState(null);

	//* Vault info
	// useEffect(() => {
	// 	if (!getInfoApi.loading && getInfoApi.data) setInfo(getInfoApi.data);
	// }, [getInfoApi.data]);

	// //* Recents items
	// useEffect(() => {
	// 	if (
	// 		!getRecentsApi.loading &&
	// 		getRecentsApi.data &&
	// 		getRecentsApi.data.length > 0
	// 	)
	// 		setRecents(getRecentsApi.data);
	// }, [getRecentsApi.data]);

	// created folder respose
	useEffect(() => {
		if (
			!createFolderApi.loading &&
			createFolderApi.data &&
			Object.keys(createFolderApi.data).length > 0
		) {
			// setMeItems((prevState) => [createFolderApi.data, ...prevState]);
			if (vaultTab === "files") getActiveTabItems(true);

			setSelectedItems([]);
		}
	}, [createFolderApi.data]);

	const replaceItem = (itemData) => {
		// setRecents((prevState) =>
		// 	replaceItemInArray(itemData, prevState, "uuid")
		// );

		switch (vaultTab) {
			case "files":
				setMeItems((prevState) =>
					replaceItemInArray(itemData, prevState, "uuid")
				);
				break;
			case "shared":
				setSharedItems((prevState) =>
					replaceItemInArray(itemData, prevState, "uuid")
				);
				break;
			case "favorites":
				setFavoriteItems((prevState) =>
					replaceItemInArray(itemData, prevState, "uuid", true)
				);
				break;
			case "gaawk":
				setGaawkItems((prevState) =>
					replaceItemInArray(itemData, prevState, "uuid")
				);
				break;
			default:
				break;
		}
	};

	// favorite api
	useEffect(() => {
		if (
			!updateFavoriteApi.loading &&
			updateFavoriteApi.data &&
			Object.keys(updateFavoriteApi.data).length > 0
		) {
			replaceItem(updateFavoriteApi.data);
			setSelectedItems([]);
		}
	}, [updateFavoriteApi.data]);

	// rename Item
	useEffect(() => {
		if (
			!renameApi.loading &&
			renameApi.data &&
			renameApi.responseCode === 200
		) {
			// replaceItem(renameApi.data);
			getActiveTabItems(true);
			setSelectedItems([]);
		}
	}, [renameApi.data]);

	// get item info
	// useEffect(() => {
	// 	if (
	// 		!getItemInfoApi.loading &&
	// 		getItemInfoApi.data &&
	// 		Object.keys(getItemInfoApi.data).length > 0
	// 	) {
	// 		setItemInfo(getItemInfoApi.data);
	// 	}
	// }, [getItemInfoApi.data]);

	// share item
	// useEffect(() => {
	// 	if (
	// 		!shareItemsApi.loading &&
	// 		!shareItemsApi.error &&
	// 		shareItemsApi.responseCode === 201
	// 	) {
	// 		toast.success("successflly shared!");
	// 	}
	// }, [shareItemsApi.data]);

	// delete item
	useEffect(() => {
		if (
			(!deleteItemsApi.loading &&
				!deleteItemsApi.error &&
				deleteItemsApi.responseCode === 200) ||
			(!removeItemsApi.loading &&
				!removeItemsApi.error &&
				removeItemsApi.responseCode === 200)
		) {
			toast.success("successfully deleted!");

			queryClient.invalidateQueries(vaultKeys.storage());

			// setRecents((prevState) =>
			// 	removeItemIdFromArray(selectedItems, prevState)
			// );

			// getRecents();

			switch (vaultTab) {
				case "files":
					setMeItems((prevState) =>
						removeItemIdFromArray(selectedItems, prevState)
					);
					break;
				case "shared":
					setSharedItems((prevState) =>
						removeItemIdFromArray(selectedItems, prevState)
					);
					break;
				case "favorites":
					setFavoriteItems((prevState) =>
						removeItemIdFromArray(selectedItems, prevState)
					);
					break;
				case "gaawk":
					setGaawkItems((prevState) =>
						removeItemIdFromArray(selectedItems, prevState)
					);
					break;
				default:
					break;
			}

			reloadActiveTabItems();

			// Deleting file from 'recents' should update subCount label of a root folder
			// Check if recent file to delete has a parentId

			if (selectedItems[0].parentId) {
				// find the folder object in meItems
				const updatedFolder = meItems.find(
					(folder) => folder.uuid === selectedItems[0].parentId
				);
				// if folder is found in root, update count and setMeItems with new folder subCount updated
				if (updatedFolder) {
					updatedFolder["subCount"] -= 1;
					setMeItems((prevState) =>
						replaceItemInArray(updatedFolder, prevState, "uuid")
					);
				}
			}

			setSelectedItems([]);
			userVaultRef.current.resetSelect();
		}
	}, [
		deleteItemsApi.loading,
		deleteItemsApi.error,
		deleteItemsApi.responseCode,
		removeItemsApi.loading,
		removeItemsApi.error,
		removeItemsApi.responseCode,
	]);

	// copy items
	useEffect(() => {
		if (
			!copyItemsApi.loading &&
			!copyItemsApi.error &&
			copyItemsApi.responseCode === 201
		) {
			toast.success("successfully copied!");

			// adding to recent container

			// setRecents((prevState) => {
			// 	const newArray = [...prevState];
			// 	newArray.splice(0, 0, ...copyItemsApi.data);
			// 	return newArray;
			// });

			if (!targetFolderId) {
				// case when copying in root vault
				// setMeItems((prevState) => [...prevState, ...copyItemsApi.data]);
				if (vaultTab === "files") getActiveTabItems(true);
			} else {
				// if copying in folder increase 'subCount' label of the target folder (if it's a root folder)
				const updatedFolder = meItems.find(
					(folder) => folder.uuid === targetFolderId
				);
				if (updatedFolder) {
					updatedFolder["subCount"] += copyItemsApi.data.length;
					setMeItems((prevState) =>
						replaceItemInArray(updatedFolder, prevState, "uuid")
					);
				}
			}

			setTargetFolderId("");
			setSelectedItems([]);
			userVaultRef.current.resetSelect();
			queryClient.invalidateQueries(vaultKeys.storage());
		} else if (copyItemsApi.error) {
			toast.error(copyItemsApi.data.message);
		}
	}, [copyItemsApi.data]);

	// move items
	useEffect(() => {
		if (!moveItemsApi.loading && moveItemsApi.responseCode === 200) {
			toast.success("successfully moved!");

			// update meFiles: remove the moved item from meFiles
			// const cleanedArray = removeItemIdFromArray(selectedItems, meItems);

			// increase 'subCount' label of the target folder (if it's a root folder)
			// const updatedFolder = meItems.find(
			//     (folder) => folder.uuid === targetFolderId
			// );
			// if (updatedFolder) {
			// updatedFolder["subCount"] += selectedItems.length;
			// setMeItems(
			//     replaceItemInArray(updatedFolder, cleanedArray, "uuid")
			// );
			// }
			//  else {
			//     setMeItems(cleanedArray);
			// }

			if (vaultTab === "files") getActiveTabItems(true);

			//TODO RELOAD RECENTS INSTEAD OF BELOW CODE?

			// update directory of recent item + update parentId (can't move it to the same place its just been moved to)
			// const updatedRecentItem = recents.find(
			// 	(item) => item.uuid === selectedItems[0].uuid
			// );
			// if (updatedRecentItem) {
			// 	updatedRecentItem["dirs"] = moveItemsApi.data[0].dirs;
			// 	updatedRecentItem["parentId"] = moveItemsApi.data[0].parentId;
			// 	setRecents((prevState) =>
			// 		replaceItemInArray(updatedRecentItem, prevState, "uuid")
			// 	);
			// }

			setTargetFolderId("");
			setSelectedItems([]);
			userVaultRef.current.resetSelect();
		}
	}, [moveItemsApi.data]);

	/**
	 * Handlers
	 */

	// const handleLoadMoreItems = () => {
	//     if (!recentSliderOpened) {
	//         getActiveTabItems();
	//     }
	// };

	const handleChangeMeLayout = (layout) => {
		localStorage.setItem("meLayout", layout);
		setMeLayout(layout);
	};

	const handleCreateFolder = (name, onRoot, parentId) => {
		createFolderApi.request(name, onRoot, parentId);
	};

	const handleFavoriteItem = (item) => {
		if (showSlider) {
			const activeFile = getActiveFile();
			updateFavoriteApi.request(activeFile.uuid, !activeFile.favorite);
		} else {
			updateFavoriteApi.request(item.uuid, !item.favorite);
		}
	};

	const handleRename = (item) => {
		setSelectedItem(item);
		setRenameModal(true);
	};

	const doRenameItem = (item, newTitle) => {
		renameApi.request(item.uuid, newTitle);
	};

	const handleDetails = (item) => {
		// setItemInfo(null);
		setSelectedItem(item);
		setDetailsModal(true);

		// getItemInfoApi.request(item.uuid);
	};

	const handleShare = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItems([item]);

		setShareModal(true);
	};

	// const [isMultiShare, setIsMultiShare] = useState(false);

	const handleMultiShare = (selectedItems) => {
		// setIsMultiShare(true);
		handleShare(selectedItems);
	};

	// const doShareItem = (profiles) => {
	// 	shareItemsApi.request(selectedItems, profiles);
	// };

	const handleDelete = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItems([item]);

		setDeleteModal(true);
	};

	const doDeleteItems = () => {
		let deleteItems = [];
		let removeItems = [];

		selectedItems.forEach((item) => {
			const mine = item.owner.uuid === profileId;

			if (mine) deleteItems = [...deleteItems, item];
			else removeItems = [...removeItems, item];
		});

		if (deleteItems.length > 0) deleteItemsApi.request(deleteItems);

		if (removeItems.length > 0) removeItemsApi.request(removeItems);
	};

	const handleCopy = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItems([item]);

		setCopyModal(true);
	};

	const doCopyItems = (toFolder) => {
		const items = selectedItems;

		if (items.length > 0) {
			let toFolderId = "";
			if (toFolder) {
				toFolderId = toFolder.uuid;
				setTargetFolderId(toFolder.uuid);
			}

			copyItemsApi.request(items, toFolderId);
		}
	};

	const handleMove = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItems([item]);
		setMoveModal(true);
	};

	const doMoveItems = (toFolder) => {
		const items = selectedItems;

		if (items.length > 0) {
			let toFolderId = "";
			if (toFolder) {
				toFolderId = toFolder.uuid;
				setTargetFolderId(toFolder.uuid);
			}

			moveItemsApi.request(items, toFolderId);
		}
	};

	// !======== SLIDER HANDLER ========

	const [showSlider, setShowSlider] = useState(false);
	const [filesArray, setFilesArray] = useState([]);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	useEffect(() => {
		if (showSlider) {
			window.history.replaceState(
				null,
				"",
				`/vault/item/${getActiveFile().uuid}`
			);
		}
	}, [activeSlideIndex]);

	// Filtering by files only

	const meFiles = useMemo(
		() => meItems.filter((item) => item.file),
		[meItems]
	);
	const meMappedFiles = useMemo(() => viewerMapper(meFiles), [meFiles]);

	const sharedFiles = useMemo(
		() => sharedItems.filter((item) => item.file),
		[sharedItems]
	);
	const sharedMappedFiles = useMemo(
		() => viewerMapper(sharedFiles),
		[sharedFiles]
	);

	const favFiles = useMemo(
		() => favoriteItems.filter((item) => item.file),
		[favoriteItems]
	);
	const favMappedFiles = useMemo(() => viewerMapper(favFiles), [favFiles]);

	useEffect(() => {
		switch (vaultTab) {
			case "files":
				setFilesArray(meMappedFiles);
				break;
			case "shared":
				setFilesArray(sharedMappedFiles);
				break;
			case "favorites":
				setFilesArray(favMappedFiles);
				break;
			default:
				break;
		}
	}, [favMappedFiles, meMappedFiles, sharedMappedFiles, vaultTab]);

	const handleShowSlider = (item) => {
		const fileIndex = filesArray.findIndex(
			(file) => file.uuid === item.file.uuid
		);
		setInitialSlideIndex(fileIndex);
		setShowSlider(true);
		window.history.pushState(null, "", `/vault/item/${item.uuid}`);
	};

	const handleCloseSlider = () => {
		setShowSlider(false);
		window.history.replaceState(null, "", "/vault");
	};

	useEffect(() => {
		let unlisten;
		if (showSlider) {
			unlisten = router.subscribe((state) => {
				if (state.historyAction === NavigationType.Pop) {
					handleCloseSlider();
				}
			});
		}

		return () => unlisten && unlisten();
	}, [showSlider]);

	const getActiveFile = () => {
		let fileArray;

		switch (vaultTab) {
			case "files":
				fileArray = meFiles;
				break;
			case "shared":
				fileArray = sharedFiles;
				break;
			case "favorites":
				fileArray = favFiles;
				break;
			default:
				break;
		}

		return fileArray[activeSlideIndex];
	};

	const currentTabHasMore = () => {
		switch (vaultTab) {
			case "files":
				return hasMoreMeItems;
			case "shared":
				return hasMoreSharedItems;
			case "favorites":
				return hasMoreFavoriteItems;
			default:
				break;
		}
	};

	const handleSliderDownload = () => {
		FileSaver.saveAs(
			filesArray[activeSlideIndex].url,
			filesArray[activeSlideIndex].originalName
		);
	};

	let loadingState;
	switch (vaultTab) {
		case "shared":
			loadingState = loadingSharedItems;
			break;
		case "favorites":
			loadingState = loadingFavoriteItems;
			break;
		case "gaawk":
			loadingState = loadingGaawkItems;
			break;
		case "files":
			loadingState = loadingMeItems;
			break;
		default:
			loadingState = false;
			break;
	}

	/**
	 * JSX
	 */

	return (
		<div className={styles.container}>
			<div className={styles.tab_menu_wrapper}>
				<TabMenu
					menuItems={{
						files: "Your files",
						favorites: "Favorites",
						shared: "Shared with you",
						gaawk: "Your Gaawk folders",
					}}
					selectedTab={vaultTab}
					onSelectedTabChanged={handleTabChange}
				/>
			</div>
			<hr className={styles.separator} />

			{/* {recents.length > 0 && (
    					<Recent
    						isLoading={getRecentsApi.loading}
    						perPageCount={displayCount}
    						items={recents}
    						onDelete={handleDelete}
    						onDetails={handleDetails}
    						onRename={handleRename}
    						onShare={handleShare}
    						onMove={handleMove}
    						onCopy={handleCopy}
    						onFavoriteItem={handleFavoriteItem}
    						onRecentClick={handleRecentClick}
    					/>
    				)} */}

			<UserVault
				ref={userVaultRef}
				fileItems={meItems}
				sharedItems={sharedItems}
				favoriteItems={favoriteItems}
				gaawkItems={gaawkItems}
				order={meOrder}
				layout={meLayout}
				// pageSize={width}
				isLoading={loadingState}
				perPageCount={displayCount}
				onCreateFolder={handleCreateFolder}
				onChangeOrderAction={changeUserItemsOrder}
				onChangeMeLayout={handleChangeMeLayout}
				onDelete={handleDelete}
				onDetails={handleDetails}
				onShare={handleShare}
				onMultiShare={handleMultiShare}
				onRename={handleRename}
				onMove={handleMove}
				onCopy={handleCopy}
				onFavoriteItem={handleFavoriteItem}
				onLoadMoreItems={() => getActiveTabItems()}
				onClick={handleShowSlider}
				vaultTab={vaultTab}
				onTabChange={handleTabChange}
			/>

			<VaultDeleteModal
				visible={deleteModal}
				onConfirm={doDeleteItems}
				onClose={() => setDeleteModal(false)}
			/>

			<VaultDetailsModal
				visible={detailsModal}
				item={selectedItem}
				// itemInfo={itemInfo}
				// loading={getItemInfoApi.loading}
				onClose={() => {
					setSelectedItem("");
					setDetailsModal(false);
				}}
			/>

			<VaultShareModal
				show={shareModal}
				onClose={() => {
					setShareModal(false);
					// setIsMultiShare(false);
				}}
				// items={isMultiShare ? selectedItems : selectedItem}
				items={selectedItems}
				onReset={() => {
					// setSelectedItem("");
					setSelectedItems([]);
					// setIsMultiShare(false);
					userVaultRef.current.resetSelect();
				}}
			/>

			{copyModal && (
				<VaultListItemsModal
					visible={copyModal}
					onConfirm={doCopyItems}
					onClose={() => setCopyModal(false)}
					title={
						selectedItems.length > 1
							? "Copy Items to"
							: "Copy item to"
					}
					buttonTitle={"COPY HERE"}
				/>
			)}

			{moveModal && (
				<VaultListItemsModal
					visible={moveModal}
					onConfirm={doMoveItems}
					onClose={() => setMoveModal(false)}
					title={"Move Item/s to"}
					isMove={true}
					selectedItems={selectedItems}
					buttonTitle={"MOVE HERE"}
				/>
			)}

			<VaultRenameModal
				visible={renameModal}
				item={selectedItem}
				onConfirm={doRenameItem}
				onClose={() => {
					setSelectedItem("");
					setRenameModal(false);
				}}
			/>

			{showSlider && (
				<FullScreenModal
					show={showSlider}
					onClose={handleCloseSlider}
					header={
						<ViewerHeader
							onClose={handleCloseSlider}
							leftSide={
								<div className={styles.parentFolderName}>
									Your Vault
								</div>
							}
							rightSide={
								<VaultActions
									isFavorite={
										filesArray[activeSlideIndex]?.favorite
									}
									onFavorite={handleFavoriteItem}
									onDownload={handleSliderDownload}
									onShare={() => handleShare(getActiveFile())}
									onFullscreen={() =>
										fileviewerRef.current.enterFullscreen()
									}
								/>
							}
						/>
					}
					children={
						<div className={styles.fileviewer_container}>
							<FileViewer
								ref={fileviewerRef}
								files={filesArray}
								initialSlide={initialSlideIndex}
								onLoadMore={() =>
									// !recentSliderOpened &&
									currentTabHasMore() && getActiveTabItems()
								}
								isLoading={
									loadingMeItems ||
									loadingSharedItems ||
									loadingFavoriteItems
								}
								onActiveSlide={(index) =>
									setActiveSlideIndex(index)
								}
								text={getActiveFile()?.file.originalName}
								// fsActions={
								//     <VaultFullscreenActions
								//         favorite={filesArray[activeSlideIndex]?.favorite}
								//         onFavorite={handleFavoriteItem}
								//         onDownload={handleSliderDownload}
								//         onShare={() => handleShare(getActiveFile())}
								//     />
								// }
							/>
						</div>
					}
				/>
			)}
		</div>
	);
};

export default VaultRightSide;
