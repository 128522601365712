import { useState, useEffect } from "react";
import {
	DayPicker,
	useActiveModifiers,
	isMatch,
	useNavigation,
} from "react-day-picker";
import {
	isSameDay,
	isWithinInterval,
	format,
	isWeekend,
	isToday,
} from "date-fns";
import "react-day-picker/dist/style.css";
import styles from "./RangesCalendar.module.css";
// import baseStyles from "react-day-picker/dist/style.module.css";
import navigateIcon from "images/icon-navigate-back.svg";

const Caption = (props) => {
	const { goToMonth, nextMonth, previousMonth } = useNavigation();
	return (
		<div className={styles.nav}>
			<button
				disabled={!previousMonth}
				onClick={() => previousMonth && goToMonth(previousMonth)}
			>
				<img src={navigateIcon} alt="previous month" />
			</button>
			<h3>{format(props.displayMonth, "MMMM yyyy")}</h3>
			<button
				disabled={!nextMonth}
				onClick={() => nextMonth && goToMonth(nextMonth)}
			>
				<img
					src={navigateIcon}
					style={{ transform: "scaleX(-1)" }}
					alt="next month"
				/>
			</button>
		</div>
	);
};

const DayRender = (props, selected, handleClick) => {
	const { date } = props;
	const activeModifiers = useActiveModifiers(props.date, props.displayMonth);
	const isSelected = isMatch(date, selected);
	const isFirstDay = selected.some((r) => isSameDay(date, r.from));
	const isLastDay = selected.some((r) => isSameDay(date, r.to));
	const isInRange = selected.some((r) =>
		isWithinInterval(date, { start: r.from, end: r.to })
	);

	let entryStatus = "";
	const selectedItem = selected.find((r) =>
		isWithinInterval(date, { start: r.from, end: r.to })
	);

	if (selectedItem) {
		entryStatus = selectedItem.type;
	}

	const handleDayClicked = () => {
		const selectedItem = selected.find((r) =>
			isWithinInterval(date, { start: r.from, end: r.to })
		);

		if (selectedItem) {
			handleClick(selectedItem);
		}
	};

	return (
		<>
			{!activeModifiers.outside && (
				<button
					className={`
                        ${styles.rdp_days}
                        ${isWeekend(date) ? styles.weekend : undefined}
                        ${isSelected ? styles.selected : undefined}
                        ${isFirstDay ? styles.is_first_day : undefined}
                        ${isLastDay ? styles.is_last_day : undefined}
                        ${isInRange ? styles.is_in_range : undefined}
                        ${styles[entryStatus]}
                    `}
					onClick={handleDayClicked}
				>
					<div
						className={`${
							isToday(date) ? styles.today : undefined
						}`}
					>
						{props.date.getDate()}
					</div>
				</button>
			)}
		</>
	);
};

const RangesCalendar = ({
	entries,
	onMonthChange,
	onClick,
	isSidebar = false,
	canEdit,
}) => {
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		const updatedEntries = entries.map((entry) => {
			const { startTime, endTime, ...otherProps } = entry;
			return {
				from: new Date(startTime),
				to: new Date(endTime),
				...otherProps,
			};
		});

		setSelected(updatedEntries);
	}, [entries]);

	const handleClick = (entry) => {
		onClick && onClick(entry);
	};

	const classNames = {
		// ...baseStyles,
		...styles,
	};

	return (
		<DayPicker
			selected={selected}
			onMonthChange={onMonthChange}
			style={isSidebar ? { width: "unset", margin: 0 } : undefined}
			weekStartsOn={1}
			classNames={classNames}
			formatters={{ formatWeekdayName: (date) => format(date, "E") }}
			components={{
				Day: (props) => DayRender(props, selected, handleClick),
				Caption: Caption,
			}}
		/>
	);
};

export default RangesCalendar;
