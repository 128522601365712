export const profileKeys = {
	all: ["profile"],
	myCompanies: () => [...profileKeys.all, "myCompanies"],
	pendingEmployees: (companyId) => [
		...profileKeys.all,
		companyId,
		"pendingEmployees",
	],
	employees: (companyId) => [...profileKeys.all, companyId, "employees"],
	employeesPaginated: (companyId, q = "") => [
		...profileKeys.all,
		companyId,
		"employeesPaginated",
		q,
	],
	myLocations: () => [...profileKeys.all, "profileLocationsList"],
	search: (query) => [...profileKeys.all, "search", query],
	commonConnections: (requestedProfileId) => [
		...profileKeys.all,
		"commonConnections",
		requestedProfileId,
	],
};
