import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    cloneElement,
} from "react";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import styles from "./FilterLeftSide.module.css";

const FilterLeftSide = forwardRef(
    ({ onResetFilter, leftSideChildren }, ref) => {
        const categoryRef = useRef();
        const filterRef = useRef();
        const dynamicFilterRef = useRef();

        const otherElement = leftSideChildren.find(
            (filter) => filter.props.id === "otherFilter"
        );
        const otherFilter =
            otherElement &&
            cloneElement(otherElement, {
                ref: categoryRef,
            });

        const staticElement = leftSideChildren.find(
            (filter) => filter.props.id === "staticFilter"
        );
        const staticFilter =
            staticElement &&
            cloneElement(staticElement, {
                ref: filterRef,
            });

        const dynamicElement = leftSideChildren.find(
            (filter) => filter.props.id === "dynamicFilter"
        );
        const dynamicFilter =
            dynamicElement &&
            cloneElement(dynamicElement, {
                ref: dynamicFilterRef,
            });

        const handleClearFields = () => {
            dynamicFilterRef.current?.clear();
            filterRef.current?.clear();
        };

        useImperativeHandle(ref, () => ({
            handleClearFields,
            updateDynamicFilters(key, tag) {
                dynamicFilterRef.current?.updateDynamicFilters(key, tag);
            },
        }));

        return (
            <div className={styles.leftside_container}>
                {otherFilter && (
                    <div
                        className={`${styles.type_container} ${
                            dynamicFilter ? styles.no_bottom_padding : undefined
                        }`}
                    >
                        {otherFilter}
                    </div>
                )}

                {dynamicFilter}

                {staticFilter}

                <div className={styles.button_container}>
                    <PrimaryButton
                        type="button"
                        text={"clear filters"}
                        className={`${styles.btn} ${styles.clear}`}
                        onClick={() => {
                            handleClearFields();
                            onResetFilter();
                        }}
                    />
                </div>
            </div>
        );
    }
);

export default FilterLeftSide;
