import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./CompanyEntry.module.css";

import PrimaryButton from "../Utils/Button/PrimaryButton";
import DatePicker from "components/Utils/SubComs/PrimeReactDatePicker/DatePicker";

// import DatePicker from "../Utils/SubComs/DatePicker/DatePicker";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import CustomSelect from "../Utils/SubComs/CustomSelect/CustomSelect";

import {
	defaultFormatter,
	locationFormatter,
} from "../Utils/SubComs/Inputs/SearchableInput/response-formatter";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { companySizeList } from "../Utils/General";

import useApi from "../../hooks/useApi";

import profileApi from "../../api/profile";
import industryApi from "../../api/industry";
import UrlCheckInput from "../Utils/SubComs/Inputs/UrlCheck/UrlCheckInput";
import companySchema from "./company-schema";
import InfiniteSearchInput from "../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useFetchLocation from "../../hooks/useFetchLocation";
import useMutate from "hooks/useMutate";
import { useNavigate } from "react-router";
import PromptModal from "components/Utils/Prompt/PromptModal";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import routes from "components/Routing/routing-keys";

const itemsPerPage = 20;
const itemsLimit = 8;

const CompanyEntry = ({ editMode = false }) => {
	const searchIndustryApi = useApi(industryApi.search, true, true);

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const {
		name,
		registrationName,
		customIndustry,
		industryDTO,
		tagLine,
		specialties,
		locationDTO: {
			id: locationId,
			name: cityName,
			countryDTO: { name: countryName } = {},
		} = {},
		establishmentTime,
		size,
		url,
		uuid,
	} = useSelector((state) => state.company.companyInfo);

	const specialtiesFormatter = (specialties) => {
		return specialties.map((item) => {
			if (item.customIndustry) {
				return {
					label: item.customIndustry,
					value: item.customIndustry,
					__isNew__: true,
				};
			} else {
				return { label: item.industry.name, value: item.industry.uuid };
			}
		});
	};

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		clearErrors,
	} = useForm({
		resolver: joiResolver(companySchema(editMode)),
		mode: "all",
		defaultValues: editMode
			? {
					companyName: name,
					registeredName: registrationName,
					url,
					urlValidity: true,
					industry: industryDTO
						? { label: industryDTO.name, value: industryDTO.uuid }
						: {
								label: customIndustry,
								value: customIndustry,
								__isNew__: true,
						  },
					headline: tagLine,
					specialty: specialtiesFormatter(specialties),
					city: {
						label: `${cityName}, ${countryName}`,
						value: locationId,
						//   countryCode: countryDTO.code,
					},
					size: companySizeList.find((item) => item.value === size),
					date: new Date(establishmentTime),
			  }
			: {
					companyName: "",
					registeredName: "",
					industry: null,
					headline: "",
					specialty: [],
					city: null,
					size: null,
					date: null,
			  },
	});

	const urlWatcher = watch("url");
	const urlValidityWatcher = watch("urlValidity");
	const specialtyWatcher = watch("specialty");

	// !== SPECIALTY HANDLER ====

	const handleSelectedSpecialty = (item) => {
		setValue("specialty", [...specialtyWatcher, item], {
			shouldDirty: true,
		});
	};

	const handleRemoveSpecialty = (index) => {
		const updatedSpecialty = specialtyWatcher.filter((_, i) => {
			return i !== index;
		});
		setValue("specialty", updatedSpecialty);
	};

	// !=== SAVE HANDLER ====
	const navigate = useNavigate();

	const {
		action: { mutate: createEditCompany, isLoading: isLoadingCreateEdit },
	} = useMutate(
		editMode ? profileApi.updateCompany : profileApi.createCompany,
		() =>
			editMode
				? navigate(routes.profile())
				: navigate(routes.myCompanies),
		undefined,
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		const {
			city: { value: cityId },
			companyName: name,
			registeredName: nameRegistered,
			industry: { value: industry },
			headline,
			specialty,
			size: { value: companySize },
			date,
			url,
		} = data;

		const formData = new FormData();

		formData.append("name", name);
		formData.append("registrationName", nameRegistered);
		formData.append("industry", industry);
		formData.append("establishmentDate", date.getTime());
		if (headline) formData.append("tagLine", headline);
		if (specialty.length > 0) {
			formData.append(
				"specialities",
				specialty.map((item) => item.value)
			);
		}

		formData.append("locationId", cityId);
		formData.append("companySize", companySize);
		if (editMode) {
			formData.append("url", url);
			formData.append("companyProfileId", uuid);
		}

		setShouldBlockSave(false);
		createEditCompany(formData);
	};

	const fetchIndustry = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await searchIndustryApi.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<form
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<div className={styles.form_input_wrapper}>
							<label>
								Company Name<span className="required">*</span>
							</label>
							<TextInput
								error={!!errors.companyName}
								{...register("companyName")}
								placeholder="Enter your Company Name"
							/>

							{errors?.companyName?.message && (
								<p className={styles.error_message}>
									{errors?.companyName?.message}
								</p>
							)}
						</div>
						<div className={styles.form_input_wrapper}>
							<label>
								Company Name as Registered
								<span className="required">*</span>
							</label>
							<TextInput
								error={!!errors.registeredName}
								{...register("registeredName")}
								placeholder="Enter your Company Name as registered"
							/>

							{errors?.registeredName?.message && (
								<p className={styles.error_message}>
									{errors?.registeredName?.message}
								</p>
							)}
						</div>

						<InfiniteSearchInput
							creatable={true}
							queryFn={fetchIndustry}
							queryName={"industries"}
							formatter={defaultFormatter}
							itemsPerPage={itemsPerPage}
							label={"Industry"}
							required={true}
							error={errors?.industry}
							control={control}
							name="industry"
						/>

						<div className={styles.form_input_wrapper}>
							<label>Headline</label>
							<TextInput
								{...register("headline")}
								placeholder="Company Headline"
							/>
						</div>

						<MultiSelectInput
							creatable={true}
							queryName="specialties"
							queryFn={fetchIndustry}
							data={specialtyWatcher}
							itemsPerPage={itemsPerPage}
							formatter={defaultFormatter}
							label="Specialty"
							limit={itemsLimit}
							onChange={handleSelectedSpecialty}
							onRemoveItem={handleRemoveSpecialty}
							infoText={`You can add up to ${itemsLimit} specialties.`}
						/>

						{editMode && (
							<UrlCheckInput
								label={"Company URL"}
								placeholder={"Enter your Company URL"}
								control={control}
								name={"url"}
								errors={errors}
								urlValidityWatcher={urlValidityWatcher}
								urlWatcher={urlWatcher}
								url={url}
								onSetValue={setValue}
								onSetError={setError}
								onClearErrors={clearErrors}
								urlPrefix={"profile/"}
							/>
						)}

						<InfiniteSearchInput
							queryName={"cities"}
							queryFn={fetchLocation}
							itemsPerPage={itemsPerPage}
							formatter={locationFormatter}
							label={"Headquarters"}
							required={true}
							error={errors?.city}
							control={control}
							name="city"
						/>

						<div className={styles.form_input_wrapper}>
							{/* //TODO >> replace calendar  */}

							{/* <DatePicker
								value={dateWatcher}
								control={control}
								placeholder={"Date of Establishment"}
								error={!!errors.date}
								name="date"
							/>
							{errors?.date?.message && (
								<p className={styles.error_message}>
									{errors?.date?.message}
								</p>
							)} */}
							<DatePicker control={control} name="date" />
						</div>
						<div className={styles.react_select_wrapper}>
							<label>
								Company Size
								<span className="required">*</span>
							</label>
							<CustomSelect
								options={companySizeList}
								placeholder="Select"
								height="35px"
								control={control}
								error={!!errors.size}
								name="size"
							/>
							{errors?.size?.message && (
								<p className={styles.error_message}>
									{errors?.size?.message}
								</p>
							)}
						</div>
						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								text={"save"}
								isLoading={isLoadingCreateEdit}
							/>
						</div>
					</form>
				</div>
			</div>
			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default CompanyEntry;
