import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import CompanyList from "../../components/Companies/CompanyList";
import CompanyEntry from "../../components/Companies/CompanyEntry";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import routes from "components/Routing/routing-keys";

const CompanyView = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const isCompanyAdd = pathname === routes.addCompany;

	return (
		<DefaultPage
			defaultHeader={false}
			headerRightContent={<BackButton onBack={() => navigate(-1)} />}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={
				isCompanyAdd ? <CompanyEntry /> : <CompanyList />
			}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default CompanyView;
