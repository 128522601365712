import { useRef } from "react";
import LazyImage from "../../../Utils/LazyImage/LazyImage";
import Popover from "../../../Utils/Popover/Popover";
import styles from "./ProductItemList.module.scss";
import optionIcon from "images/options-hor-dark-grey.svg";
import ProductPopoverActions from "./ProductPopoverActions";

const ProductItemList = ({
	item,
	isOwner,
	onCopy,
	onMessage,
	onEdit,
	onDelete,
}) => {
	const {
		name,
		mainImage: { file },
	} = item;

	const popoverRef = useRef();

	return (
		<div className={styles.item_list}>
			<div className={styles.left_side}>
				<div className={styles.icon_wrapper}>
					<div className={styles.media_wrapper}>
						<LazyImage
							image={
								<img
									src={file.customName}
									alt={file.originalName}
								/>
							}
						/>
					</div>
				</div>
				<div className={styles.item_details}>
					<h3>{name}</h3>
					<div className={styles.tags_container}>
						<i>should contain the tags</i>
					</div>
				</div>
			</div>

			<div className={styles.item_buttons}>
				<Popover
					ref={popoverRef}
					render={
						<ProductPopoverActions
							isOwner={isOwner}
							onCopy={onCopy}
							onMessage={onMessage}
							onEdit={onEdit}
							onDelete={onDelete}
							onClose={() => popoverRef.current.closePopover()}
						/>
					}
				>
					<button onClick={(e) => e.stopPropagation()}>
						<img
							src={optionIcon}
							className={styles.option_button}
							alt="options"
						/>
					</button>
				</Popover>
			</div>
		</div>
	);
};

export default ProductItemList;
