import BioEdit from "../../components/Profile/ProfileEdit/BioEdit";
import Certificate from "../../components/Profile/ProfileEdit/Certificate/Certificate";
import CertificateEntry from "../../components/Profile/ProfileEdit/Certificate/CertificateEntry";
import KeyPeople from "../../components/Profile/ProfileEdit/KeyPeople/KeyPeople";
import KeyPeopleEntry from "../../components/Profile/ProfileEdit/KeyPeople/KeyPeopleEntry";
import Software from "../../components/Profile/ProfileEdit/Software/index";
import SoftwareEntry from "../../components/Profile/ProfileEdit/Software/SoftwareEntry";
import Language from "../../components/Profile/ProfileEdit/Language/index";
import LanguageEntry from "../../components/Profile/ProfileEdit/Language/LanguageEntry";
import Experience from "../../components/Profile/ProfileEdit/Experience/index";
import ExperienceEntry from "../../components/Profile/ProfileEdit/Experience/ExperienceEntry";
import Education from "../../components/Profile/ProfileEdit/Education/Education";
import EducationEntry from "../../components/Profile/ProfileEdit/Education/EducationEntry";
import Skill from "../../components/Profile/ProfileEdit/Skills/index";
import Role from "../../components/Profile/ProfileEdit/Roles/index";
import ProfileLeftPanel from "../../components/Profile/ProfileLeftPanel/ProfileLeftPanel";
import Phone from "../../components/Profile/ProfileEdit/Contacts/Phone/Phone";
import PhoneEntry from "../../components/Profile/ProfileEdit/Contacts/Phone/PhoneEntry";
import Email from "../../components/Profile/ProfileEdit/Contacts/Email/Email";
import EmailEntry from "../../components/Profile/ProfileEdit/Contacts/Email/EmailEntry";
import Social from "../../components/Profile/ProfileEdit/Contacts/Social/Social";
import SocialEntry from "../../components/Profile/ProfileEdit/Contacts/Social/SocialEntry";
import Address from "../../components/Profile/ProfileEdit/Contacts/Address/Address";
import AddressEntry from "../../components/Profile/ProfileEdit/Contacts/Address/AddressEntry";
import Other from "../../components/Profile/ProfileEdit/Contacts/Other/Other";
import OtherEntry from "../../components/Profile/ProfileEdit/Contacts/Other/OtherEntry";
// import MakeTemp from "../../components/Profile/ProfileEdit/ProfilePicEdit/Temp/MakeTemp";
// import Frame from "components/Profile/ProfileEdit/ProfilePicEdit/Frame/Frame";
import ProfilePicEdit from "../../components/Profile/ProfileEdit/ProfilePicEdit/index";
import CoverEdit from "../../components/Profile/ProfileEdit/ProfileCoverEdit";
import EditInfo from "../../components/Profile/ProfileEdit/EditInfo/EditInfo";
import SpecialProfileEdit from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileEdit";
import SpecialBaseEntry from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileForms/SpecialBaseEntry/SpecialBaseEntry";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import BackButton from "../../components/Utils/Button/BackButton";

import styles from "../Header.module.css";
import { useNavigate } from "react-router-dom";

const EditProfileView = ({
	activeItem: selectedItem,
	activeKey,
	editCompany,
	headerTitle,
	...props
}) => {
	const navigate = useNavigate();

	let component;
	let activeItem;

	switch (selectedItem) {
		case "bio":
			component = <BioEdit editCompany={editCompany} {...props} />;
			activeItem = "bio";
			break;
		case "certificate":
			component = <Certificate editCompany={editCompany} {...props} />;
			activeItem = "certificate";
			break;
		case "certificate-add-edit":
			component = (
				<CertificateEntry editCompany={editCompany} {...props} />
			);
			activeItem = "certificate";
			break;
		case "keyPeople":
			component = <KeyPeople {...props} />;
			activeItem = "keyPeople";
			break;
		case "keyPeople-add":
			component = <KeyPeopleEntry {...props} />;
			activeItem = "keyPeople";
			break;
		case "language":
			component = <Language {...props} />;
			activeItem = "language";
			break;
		case "language-add-edit":
			component = <LanguageEntry {...props} />;
			activeItem = "language";
			break;
		case "software":
			component = <Software {...props} />;
			activeItem = "software";
			break;
		case "software-add-edit":
			component = <SoftwareEntry {...props} />;
			activeItem = "software";
			break;
		case "role":
			component = <Role {...props} />;
			activeItem = "roles";
			break;
		case "experience":
			component = <Experience {...props} />;
			activeItem = "experience";
			break;
		case "experience-add-edit":
			component = <ExperienceEntry {...props} />;
			activeItem = "experience";
			break;
		case "education":
			component = <Education {...props} />;
			activeItem = "education";
			break;
		case "education-add-edit":
			component = <EducationEntry {...props} />;
			activeItem = "education";
			break;
		case "skill":
			component = <Skill {...props} />;
			activeItem = "skills";
			break;
		case "specialProfile-edit":
			component = <SpecialProfileEdit {...props} />;
			activeItem = "specialProfile";
			break;
		case "specialProfile-add":
			component = <SpecialBaseEntry {...props} />;
			activeItem = "specialProfile";
			break;
		case "contact":
			component = <Phone editCompany={editCompany} {...props} />;
			activeItem = "mobile";
			break;
		case "contact-add-edit":
			component = <PhoneEntry editCompany={editCompany} {...props} />;
			activeItem = "mobile";
			break;
		case "email":
			component = <Email editCompany={editCompany} {...props} />;
			activeItem = "email";
			break;
		case "email-add-edit":
			component = <EmailEntry editCompany={editCompany} {...props} />;
			activeItem = "email";
			break;
		case "social":
			component = <Social editCompany={editCompany} {...props} />;
			activeItem = "social";
			break;
		case "social-add-edit":
			component = <SocialEntry editCompany={editCompany} {...props} />;
			activeItem = "social";
			break;
		case "address":
			component = <Address editCompany={editCompany} {...props} />;
			activeItem = "address";
			break;
		case "address-add-edit":
			component = <AddressEntry editCompany={editCompany} {...props} />;
			activeItem = "address";
			break;
		case "other":
			component = <Other editCompany={editCompany} {...props} />;
			activeItem = "other";
			break;
		case "other-add-edit":
			component = <OtherEntry editCompany={editCompany} {...props} />;
			activeItem = "other";
			break;
		// case "profilePic-temp":
		// 	component = <MakeTemp editCompany={editCompany} {...props} />;
		// 	activeItem = "profilePic";
		// 	break;
		// case "profilePic-frame":
		// 	component = <Frame editCompany={editCompany} {...props} />;
		// 	activeItem = "profilePic";
		// 	break;
		case "profilePic":
			component = <ProfilePicEdit editCompany={editCompany} {...props} />;
			activeItem = "profilePic";
			break;
		case "coverPic":
			component = <CoverEdit editCompany={editCompany} {...props} />;
			activeItem = "coverPic";
			break;
		case "edit-info":
			component = <EditInfo editCompany={editCompany} {...props} />;
			break;
		default:
			break;
	}

	return (
		<DefaultPage
			rightSideContainerColor={"#FFF"}
			leftSideChildren={
				<ProfileLeftPanel
					activeKey={activeKey}
					activeItem={activeItem}
					editCompany={editCompany}
				/>
			}
			rightSideBgColor={"#FFFFFF"}
			rightSideChildren={component}
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> {headerTitle}
				</div>
			}
			defaultHeader={false}
		/>
	);
};

export default EditProfileView;
