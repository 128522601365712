import ScrollContainer from "react-indiana-drag-scroll";
import styles from "./ProfileScrollableMenu.module.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import routes from "components/Routing/routing-keys";

const Index = ({
	onItemClicked,
	items,
	customStyle,
	disabled = false,
	children,
	customStyleSection,
	activeTab,
}) => {
	const { pathname } = useLocation();

	const isProfilePage = pathname.startsWith("/profile");
	const isChatPage = pathname === routes.chat;

	const profileActiveTab = useSelector((state) => state.activeTab.profileTab);

	const tabsList = items?.map((tab) => (
		<div key={tab} className={styles.col}>
			<button
				disabled={disabled}
				className={
					(isProfilePage || isChatPage
						? profileActiveTab
						: activeTab) === tab
						? styles.active
						: ""
				}
				onClick={onItemClicked}
			>
				{tab}
			</button>
		</div>
	));

	return (
		<ScrollContainer
			className={`${styles.scroll_container} ${customStyle}`}
		>
			<section className={`${styles.tiles} ${customStyleSection}`}>
				{children ? children : tabsList}

				{/* <div className={styles.col}>
                    <span className={activeTab === "portfolio" ? styles.active : ""} onClick={onItemClicked}>
                        portfolio
                    </span>
                </div>
                <div className={styles.col}>
                    <span className={activeTab === "collections" ? styles.active : ""} onClick={onItemClicked}>
                        collections
                    </span>
                </div>
                <div className={styles.col}>
                    <span className={activeTab === "boards" ? styles.active : ""} onClick={onItemClicked}>
                        boards
                    </span>
                </div>
                <div className={styles.col}>
                    <span className={activeTab === "events" ? styles.active : ""} onClick={onItemClicked}>
                        events
                    </span>
                </div>
                <div className={styles.col}>
                    <span className={activeTab === "AAA" ? styles.active : ""} onClick={onItemClicked}>
                        AAA
                    </span>
                </div> */}
			</section>
		</ScrollContainer>
	);
};

export default Index;
