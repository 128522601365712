import peopleIcon from "images/people.svg";
import companyIcon from "images/building.svg";
import vaultIcon from "images/folder.svg";
import boardIcon from "images/board.svg";
import productIcon from "images/product.svg";
import servicesIcon from "images/services.svg";
import jobIcon from "images/job.svg";

import GaawkTile from "components/More/MoreComponents/GaawkTile";
import { featuresSection } from "../General";
import styles from "./GaawkSection.module.scss";

const GaawkSection = ({
	tileStyle,
	labelStyle,
	inline = false,
	customStyle,
	active,
}) => {
	return (
		<>
			<GaawkTile
				// isSidebar={isSidebar}
				tileStyle={tileStyle}
				labelStyle={labelStyle}
				label="People"
				img={peopleIcon}
				path="/people"
				inline={inline}
				customStyle={`${customStyle} ${
					active === featuresSection.people
						? styles.active
						: undefined
				}`}
			/>
			<GaawkTile
				// isSidebar={isSidebar}
				tileStyle={tileStyle}
				labelStyle={labelStyle}
				label="Companies"
				path="/companies"
				img={companyIcon}
				inline={inline}
				customStyle={`${customStyle} ${
					active === featuresSection.companies
						? styles.active
						: undefined
				}`}
			/>
			<GaawkTile
				// isSidebar={isSidebar}
				tileStyle={tileStyle}
				labelStyle={labelStyle}
				label="Vault"
				path="/vault"
				img={vaultIcon}
				inline={inline}
				customStyle={`${customStyle} ${
					active === featuresSection.vault ? styles.active : undefined
				}`}
			/>
			<GaawkTile
				// isSidebar={isSidebar}
				tileStyle={tileStyle}
				labelStyle={labelStyle}
				label="Boards"
				path="/boards"
				img={boardIcon}
				inline={inline}
				customStyle={`${customStyle} ${
					active === featuresSection.boards
						? styles.active
						: undefined
				}`}
			/>
			<GaawkTile
				// isSidebar={isSidebar}
				tileStyle={tileStyle}
				labelStyle={labelStyle}
				label="Products"
				path="/products"
				img={productIcon}
				inline={inline}
				customStyle={`${customStyle} ${
					active === featuresSection.products
						? styles.active
						: undefined
				}`}
			/>
			<GaawkTile
				// isSidebar={isSidebar}
				tileStyle={tileStyle}
				labelStyle={labelStyle}
				label="Services"
				path="/services"
				img={servicesIcon}
				inline={inline}
				customStyle={`${customStyle} ${
					active === featuresSection.services
						? styles.active
						: undefined
				}`}
			/>
			{/* <GaawkTile label="Pro" img={""} path="" /> */}
			<GaawkTile
				// isSidebar={isSidebar}
				tileStyle={tileStyle}
				labelStyle={labelStyle}
				label="Jobs"
				path="/jobs"
				img={jobIcon}
				inline={inline}
				customStyle={`${customStyle} ${
					active === featuresSection.jobs ? styles.active : undefined
				}`}
			/>
			{/* <GaawkTile label="News" img={""} path="" /> */}
			{/* <GaawkTile label="AAA" img={""} path="" /> */}
			{/* <GaawkTile label="Events" img={""} path="" /> */}
			{/* <GaawkTile label="Chat" img={""} path="" /> */}
		</>
	);
};

export default GaawkSection;
