import ProfilePic from "../Home/ProfilePic";
import styles from "./CompanyItem.module.css";

const CompanyItem = ({
	item,
	subdata,
	subdiv,
	border = false,
	customStyle,
	onClick,
}) => {
	return (
		<div
			className={`${styles.company_item} ${
				border ? styles.border : undefined
			} ${customStyle} ${onClick ? styles.isClickable : undefined}`}
			onClick={onClick}
		>
			<ProfilePic
				fullWidth={true}
				type={"COMPANY"}
				w={48}
				h={48}
				// noMarginRight={true}
				thumbnail={
					item?.profileImage?.framedImageURL ||
					item?.profileImage?.originalImageURL ||
					item?.profileImage?.image
				}
				name={item.name}
				subdata={subdata}
				subdiv={subdiv}
			/>
		</div>
	);
};

export default CompanyItem;
