import { useEffect, useMemo } from "react";
import styles from "./UserSection.module.scss";
import { useInView } from "react-intersection-observer";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "../../../Utils/SubComs/NoResults/NoResults";
import GroupMember from "../../../Chat/GroupMember";
import useGlobalSearch from "../../../../hooks/useGlobalSearch";
import filterIcon from "images/filter-icon-white.svg";

const SearchUserSection = ({
	inputValue = "",
	userCoordinates,
	isMobile,
	onShowModal,
	tagsList,
	searchQueryKey,
	initialFilters,
	searchEndpoint,
}) => {
	const { data, hasNextPage, fetchNextPage, isLoading, fetchStatus } =
		useGlobalSearch(
			initialFilters,
			{ locationIdForSort: userCoordinates.id },
			// userCoordinates, //TODO >> replace this to {locationIdForSort: userCoordinates.id}
			searchQueryKey,
			inputValue,
			searchEndpoint
		);

	const { ref: viewRef, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage) fetchNextPage();
	}, [inView, hasNextPage, fetchNextPage]);

	const formattedUsers = useMemo(
		() =>
			data?.pages?.map((page) =>
				page?.list?.map((user, i) => (
					<div
						key={user.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						className={styles.contact_item}
					>
						<GroupMember data={user} redirect={true} />
					</div>
				))
			),
		[data]
	);

	return (
		<div className={styles.container}>
			<div className={styles.section_wrapper}>
				<div className={styles.title_wrapper}>
					<h3>Search Results</h3>
					{isMobile && (
						<button
							className={styles.filter_button}
							onClick={onShowModal}
						>
							<img src={filterIcon} alt="" />
						</button>
					)}
				</div>

				{tagsList?.length > 0 && (
					<div className={styles.active_filters}>{tagsList}</div>
				)}

				{fetchStatus !== "idle" && isLoading ? (
					<LoadingSpinner />
				) : (
					<div>{formattedUsers}</div>
				)}

				<NoResults
					text="No results"
					customStyle={styles.no_padding}
					visible={!isLoading && data?.count === 0}
				/>
			</div>
		</div>
	);
};

export default SearchUserSection;
