import { useSelector } from "react-redux";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "../Header.module.css";
import EditCalendar from "../../components/Profile/WorkStatusTab/EditCalendar";
import useCalendarEntries from "../../hooks/useCalendarEntries";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import { useLocation, useNavigate } from "react-router-dom";

const CalendarView = () => {
	const navigate = useNavigate();
	const { uuid: myProfileId } = useSelector(
		(state) => state.user.profileInfo
	);

	const { state } = useLocation();

	const { calendarEntries, handleMonthChange, handleDeleteEntry } =
		useCalendarEntries(myProfileId, state);

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Calendar
				</div>
			}
			defaultHeader={false}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideBgColor={"#FFFFFF"}
			rightSideChildren={
				<EditCalendar
					calendarEntries={calendarEntries}
					onMonthChange={handleMonthChange}
					onDeleteEntry={handleDeleteEntry}
				/>
			}
		/>
	);
};

export default CalendarView;
