import { useRef } from "react";
import styles from "./CreatePost.module.css";
import { ReactComponent as EmojiIcon } from "images/emoji-post.svg";
import { ReactComponent as ImageIcon } from "images/image.svg";
import { ReactComponent as VideoIcon } from "images/video.svg";
import { ReactComponent as PdfIcon } from "images/pdf.svg";
import { ReactComponent as PinIcon } from "images/location_icon.svg";
import { ReactComponent as TagIcon } from "images/tag-icon.svg";

const PostButtons = ({
	onChange,
	onChangePdf,
	onEmotion,
	onLocation,
	onTag,
}) => {
	const inputFile = useRef(null);
	const videoInputFile = useRef(null);
	const pdfInputFile = useRef(null);
	return (
		<div className={styles.post_buttons_wrapper}>
			<button onClick={onEmotion}>
				<EmojiIcon className={styles.icon} />
			</button>

			<button onClick={() => inputFile.current.click()}>
				<input
					type="file"
					id="file"
					ref={inputFile}
					multiple
					onChange={onChange}
					accept="image/png, image/jpg, image/jpeg"
					style={{ display: "none" }}
				/>

				<ImageIcon className={styles.icon} />
			</button>

			<button onClick={() => videoInputFile.current.click()}>
				<input
					type="file"
					ref={videoInputFile}
					multiple
					onChange={onChange}
					accept="video/*"
					style={{ display: "none" }}
				/>
				<VideoIcon className={styles.icon} />
			</button>

			<button onClick={() => pdfInputFile.current.click()}>
				<input
					type="file"
					ref={pdfInputFile}
					multiple
					onChange={onChangePdf}
					accept="application/pdf"
					style={{ display: "none" }}
				/>

				<PdfIcon className={styles.icon} />
			</button>

			<button onClick={onLocation}>
				<PinIcon className={styles.icon} />
			</button>

			<button onClick={onTag}>
				<TagIcon className={styles.icon} />
			</button>
		</div>
	);
};

export default PostButtons;
