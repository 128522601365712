import styles from "./DynamicLogo.module.scss";

import gaawkLogo from "images/gaawk-logos/gaawk-default.png";
import gaawkConnection from "images/gaawk-logos/gaawk-connection.png";
import gaawkChat from "images/gaawk-logos/gaawk-chat.png";
import gaawkVault from "images/gaawk-logos/gaawk-vault.png";
import gaawkPeople from "images/gaawk-logos/gaawk-people.png";
import gaawkCompany from "images/gaawk-logos/gaawk-company.png";
import gaawkJobs from "images/gaawk-logos/gaawk-jobs.png";
import gaawkProducts from "images/gaawk-logos/gaawk-product.png";
import gaawkServices from "images/gaawk-logos/gaawk-services.png";
import gaawkBoards from "images/gaawk-logos/gaawk-boards.png";
import gaawkPortfolio from "images/gaawk-logos/gaawk-portfolio.png";
import gaawkCollection from "images/gaawk-logos/gaawk-collection.png";
import { useNavigate } from "react-router-dom";

const DynamicLogo = () => {
	const navigate = useNavigate();

	let logo;

	const pathname = "";

	//TODO >> option 1: read the url and make dynamic ? (more dynamic but less control)
	//TODO >> option 1: pass a prop (less dynamic but more control)

	switch (pathname) {
		case "search":
			logo = gaawkLogo;
			break;

		case "connection":
			logo = gaawkConnection;
			break;

		case "chat":
			logo = gaawkChat;
			break;

		case "vault":
			logo = gaawkVault;
			break;

		case "people":
			logo = gaawkPeople;
			break;

		case "company":
			logo = gaawkCompany;
			break;

		case "jobs":
			logo = gaawkJobs;
			break;

		case "products":
			logo = gaawkProducts;
			break;

		case "services":
			logo = gaawkServices;
			break;

		case "boards":
			logo = gaawkBoards;
			break;

		case "portfolio":
			logo = gaawkPortfolio;
			break;

		case "collection":
			logo = gaawkCollection;
			break;

		default:
			logo = gaawkLogo;
			break;
	}

	return (
		<button onClick={() => navigate("/")}>
			<img src={logo} className={styles.logo} alt="" />
		</button>
	);
};

export default DynamicLogo;
