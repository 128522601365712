import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

//TODO >> TO BE REMOVED FROM SLICES

const slice = createSlice({
	name: "Frame",
	initialState: {
		list: [],
		suggestedList: [],
	},
	reducers: {
		listUpdated: (state, action) => {
			state.list = action.payload;
		},

		suggestedListUpdated: (state, action) => {
			state.suggestedList = action.payload;
		},
	},
});

const { listUpdated, suggestedListUpdated } = slice.actions;
export default slice.reducer;

/**
 * get the frame list form the server
 */

export const getFrames = () =>
	apiActions.apiCallBegan({
		url: "frame",
		method: "GET",
		onSuccess: listUpdated.type,
	});

export const getSuggestedFrames = () =>
	apiActions.apiCallBegan({
		url: "frame/suggested",
		method: "GET",
		onSuccess: suggestedListUpdated.type,
	});
