import { urlRegex } from "components/Utils/General";
import Joi from "joi";

const editUserSchema = Joi.object({
	firstName: Joi.string()
		.required()
		.label("First Name")
		.messages({ "string.empty": "First Name can't be empty" }),
	lastName: Joi.string()
		.required()
		.label("Last Name")
		.messages({ "string.empty": "Last Name can't be empty" }),
	url: Joi.string()
		.pattern(urlRegex)
		.required()
		.max(20)
		.label("Public URL")
		.messages({
			"string.empty": "Public URL can't be empty",
			"string.pattern.base": "Invalid characters",
			"string.max": "Public URL can't be more than 20 characters",
		}),
	urlValidity: Joi.boolean()
		.required()
		.valid(true)
		.label("URL Availability")
		.messages({ "any.only": "URL is already taken" }),
	headline: Joi.string().allow("").label("Headline"),
	location: Joi.object({
		value: Joi.number(),
		label: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Location can't be empty",
			"object.base": "Location can't be empty",
		})
		.label("Location"),
	nationality: Joi.object({
		value: Joi.number(),
		label: Joi.string(),
	})
		.label("Nationality")
		.allow(null),
	birthDate: Joi.date()
		.required()
		.messages({
			"any.required": "Date of birth can't be empty",
			"date.base": "Date of birth can't be empty",
		})
		.label("Date of Birth"),
	gender: Joi.string()
		.valid("m", "f", "c")
		.required()
		.messages({ "any.only": "Select at least one option" }),
	genderPronoun: Joi.when("gender", {
		is: Joi.string().valid("custom"),
		then: Joi.object({
			value: Joi.string(),
			label: Joi.string(),
		}).required(),
		otherwise: Joi.optional(),
	}),
	optionalGender: Joi.string().allow("").optional(),
});

export default editUserSchema;
