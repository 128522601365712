import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import WarningModal from "../../../Utils/GaawkModal/WarningModal";

import { deleteCertificate } from "../../../../store/slices/user";
import { deleteCompanyCertificate } from "../../../../store/slices/company";
import CertificateItem from "./CertificateItem";

import styles from "./Certificate.module.css";
import CtaButton from "components/Utils/Button/CtaButton";

const Certificate = ({ editCompany }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { certificates, uuid } = useSelector((state) => {
		if (editCompany) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const [certificateId, setCertificateId] = useState("");
	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const [formattedCertificates, setFormattedCertificates] = useState([]);

	const handleDelete = (certificateId) => {
		setCertificateId(certificateId);
		setWarningDeleteModal(true);
	};

	const handleConfirmDelete = () => {
		dispatch(
			editCompany
				? deleteCompanyCertificate(certificateId, uuid)
				: deleteCertificate(certificateId)
		);
		setWarningDeleteModal(false);
	};

	useEffect(() => {
		setFormattedCertificates(
			certificates.map((item) => (
				<CertificateItem
					key={item.uuid}
					item={item}
					onEditClicked={() =>
						navigate("/profile/certificate/edit", {
							state: item,
						})
					}
					onDelete={() => handleDelete(item.uuid)}
				/>
			))
		);
	}, [navigate, certificates]);

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW CERTIFICATE"
					onClick={() => navigate("/profile/certificate/add")}
				/>
			</div>

			{certificates.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>
						Your Certification & Courses
					</h4>

					<div className={styles.certificates_section}>
						{formattedCertificates}
					</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Certificate;
