import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ProficiencyGuide from "./ProficiencyGuide";

import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";

import {
	getLanguageProficiencies,
	getLanguageProficiency,
} from "./LanguageProficiency";

import styles from "./Language.module.css";
import { addLanguage, updateLanguage } from "../../../../store/slices/user";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { languageFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import { languagesKeys } from "queryKeys/languages-key-factory";
import useFetchLanguages from "hooks/useFetchLanguages";
import languageSchema from "./language-schema";
import PromptModal from "components/Utils/Prompt/PromptModal";

const itemsPerPage = 20;

const LanguageEntry = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { pathname, state } = useLocation();

	const editMode = pathname === "/profile/language/edit";

	const { uuid: userLanguageId, language, spoken, written } = state || {};

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const {
		formState: { errors, isDirty },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(languageSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					language: { label: language?.name, value: language?.code },
					written: getLanguageProficiency(written),
					spoken: getLanguageProficiency(spoken),
			  }
			: {
					language: null,
					written: null,
					spoken: null,
			  },
	});

	const handleSave = (data) => {
		const {
			language: { value: code },
			spoken: { value: spoken },
			written: { value: written },
		} = data;

		dispatch(
			editMode
				? updateLanguage({
						userLanguageId,
						code,
						spoken,
						written,
				  })
				: addLanguage({
						code,
						spoken,
						written,
				  })
		);
		setShouldBlockSave(false);
	};

	useEffect(() => {
		if (!shouldBlockSave) navigate("/profile/language");
	}, [navigate, shouldBlockSave]);

	const fetchLanguages = useFetchLanguages();

	if (editMode && !state) {
		return <Navigate to={"/profile/language"} />;
	}

	return (
		<>
			<div className={`${styles.container} ${styles.new}`}>
				<div className={styles.form_wrapper}>
					<form
						className={styles.form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<InfiniteSearchInput
							disabled={editMode}
							label={"Language"}
							required={true}
							queryName={languagesKeys.all}
							queryFn={fetchLanguages}
							itemsPerPage={itemsPerPage}
							formatter={languageFormatter}
							error={errors?.language}
							control={control}
							name="language"
							customStyle={styles.language_wrapper}
						/>

						<div className={styles.form_input_wrapper}>
							<label>
								Proficiency - Written
								<span className="required">*</span>
							</label>

							<CustomSelect
								placeholder="Select written proficiency"
								options={getLanguageProficiencies()}
								height="35px"
								error={!!errors.written}
								control={control}
								name="written"
							/>
							{errors?.written?.message && (
								<p className={styles.error_message}>
									{errors?.written?.message}
								</p>
							)}
						</div>

						<div className={styles.form_input_wrapper}>
							<label>
								Proficiency - Spoken
								<span className="required">*</span>
							</label>

							<CustomSelect
								placeholder="Select spoken proficiency"
								options={getLanguageProficiencies()}
								height="35px"
								error={!!errors.spoken}
								control={control}
								name="spoken"
							/>
							{errors?.spoken?.message && (
								<p className={styles.error_message}>
									{errors?.spoken?.message}
								</p>
							)}
						</div>

						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								text={"save"}
							/>
						</div>
					</form>

					<ProficiencyGuide />
				</div>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default LanguageEntry;
