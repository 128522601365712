import servicesApi from "api/services";
import styles from "./SuggestedServices.module.scss";
import useApi from "hooks/useApi";
import { servicesKeys } from "queryKeys/services-key-factory";
import useUserLocation from "hooks/useUserLocation";
import { useQuery } from "@tanstack/react-query";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import ProfilePic from "components/Home/ProfilePic";

const SuggestedServices = () => {
	const getSuggestedServicesApi = useApi(
		servicesApi.getSuggestedServices,
		true,
		true
	);

	const { userCoordinates } = useUserLocation();

	const fetchSuggested = async () => {
		const response = await getSuggestedServicesApi.request(
			0,
			5,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const { data } = useQuery({
		queryKey: servicesKeys.suggested(),
		queryFn: fetchSuggested,

		staleTime: 30000, // 5 minutes
	});

	const servicesList = data?.map((service) => (
		<GaawkLink
			key={service.uuid}
			customStyle={styles.service_wrapper}
			disableDecoration={true}
			to={`/services/${service.uuid}`}
		>
			<div className={styles.companyDTO}>
				<ProfilePic
					w={20}
					h={20}
					letterSize={12}
					name={service.minifiedProfile.name}
					enableName={false}
					noMarginRight={true}
					type={service.minifiedProfile.type}
					thumbnail={service.minifiedProfile?.profileImage?.image}
				/>

				<h5>{service.minifiedProfile.name}</h5>
			</div>
			<div className={styles.serviceDTO}>
				<div className={styles.service_image_wrapper}>
					<LazyImage
						image={
							<img
								src={service?.image?.file?.customName}
								alt=""
							/>
						}
					/>
				</div>
				<div>
					<h3>{service.name}</h3>
					<p>{service.description}</p>
				</div>
			</div>
		</GaawkLink>
	));

	if (!data || data?.length === 0) return null;

	return (
		<ProfileTabContainer customStyle={styles.section_container}>
			<h3>Services you might be interested in</h3>
			<div className={styles.services_container}>{servicesList}</div>
		</ProfileTabContainer>
	);
};

export default SuggestedServices;
