import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { isLogged } from "../Utils/General";
// import { useSelector } from "react-redux";
import useApi from "../../hooks/useApi";
import profileApi from "../../api/profile";
import useCompanyId from "../../hooks/useCompanyId";
import LoadingPage from "../Utils/SubComs/CustomLoader/LoadingPage";

import BaseView from "../../views/BaseView";

const CompanyManageRoute = ({
	// component: Component,
	children,
	headerText = "",
	// ...rest
}) => {
	const navigate = useNavigate();

	// const userData = useSelector((state) => state.user.profileInfo);
	const companyId = useCompanyId();
	const getUserByUrlApi = useApi(profileApi.getUserByUrl);
	const getCompanyProfileApi = useApi(profileApi.getCompanyProfile);

	// const {
	// 	computedMatch: {
	// 		params: { userUrl },
	// 	},
	// 	location: { pathname },
	// } = rest;

	const { pathname } = useLocation();
	const { userUrl } = useParams();

	const ownerProtectedRoutes =
		pathname.endsWith("/manage/transfer") ||
		pathname.endsWith("/manage/agency");

	const [companyDetails, setCompanyDetails] = useState("");

	useEffect(() => {
		getUserByUrlApi.request(userUrl);
	}, []);

	useEffect(() => {
		if (
			!getUserByUrlApi.loading &&
			getUserByUrlApi.responseCode === 200 &&
			getUserByUrlApi.data
		) {
			const response = getUserByUrlApi.data;
			if (response.type === "COMPANY")
				getCompanyProfileApi.request(response.uuid);
			else navigate(`/profile/${userUrl}`);
		} else if (!getUserByUrlApi.loading && getUserByUrlApi.error) {
			throw new Response("", {
				statusText: getUserByUrlApi.data.message,
				status: getUserByUrlApi.data.code,
			});
		}
	}, [getUserByUrlApi.data]);

	useEffect(() => {
		if (
			!getCompanyProfileApi.loading &&
			getCompanyProfileApi.responseCode === 200 &&
			getCompanyProfileApi.data
		) {
			const fullCompanyResponse = getCompanyProfileApi.data;
			const { uuid, companyUserType } = fullCompanyResponse;

			// * FIRST >> check if you're logged as the company you're trying to manage
			if (companyId && companyId === uuid) {
				// * >> if you're OWNER you can access: /manage, /agency, /transfer
				// * >> if you're ADMIN you can only access 'manage' page
				// * >> if you're REPRESENTATIVE you'll be redirected
				if (
					(ownerProtectedRoutes && companyUserType === "OWNER") ||
					(!ownerProtectedRoutes &&
						companyUserType !== "REPRESENTATIVE") //* Meaning you're either ADMIN or OWNER
				)
					setCompanyDetails(fullCompanyResponse);
				else navigate(`/profile/${userUrl}`);
			} else navigate(`/profile/${userUrl}`);
		}
	}, [getCompanyProfileApi.data]);

	if (companyDetails && Object.keys(companyDetails).length > 0) {
		return (
			<BaseView
				headerText={headerText}
				defaultHeader={false}
				component={children}
				state={companyDetails}
			/>
		);
	} else return <LoadingPage fullPage={true} />;

	// return (
	// 	<>
	// 		{!companyDetails && <LoadingPage />}
	// 		{companyDetails && Object.keys(companyDetails).length > 0 && (
	// 			<Route
	// 				{...rest}
	// 				render={({ location, ...props }) =>
	// 					isLogged(
	// 						userData && Object.keys(userData).length > 0
	// 					) ? (
	// 						<BaseView
	// 							headerText={headerText}
	// 							defaultHeader={false}
	// 							component={
	// 								<Component
	// 									{...props}
	// 									location={{
	// 										...location,
	// 										state: companyDetails,
	// 									}}
	// 								/>
	// 							}
	// 						/>
	// 					) : (
	// 						<Navigate to="/login" />
	// 					)
	// 				}
	// 			/>
	// 		)}
	// 	</>
	// );
};

export default CompanyManageRoute;
