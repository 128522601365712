import { QueryCache, QueryClient } from "@tanstack/react-query";

import { toast } from "react-toastify";

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		// onError: (error, query) => {
		// 	if (query.meta?.defaultMessage) {
		// 		toast.error(`${error.message}`);
		// 	} else if (query.meta?.errorMessage) {
		// 		toast.error(`${query.meta.errorMessage}`);
		// 	}
		// },
		onSuccess: (data, query) => {
			if (query.meta?.successMessage) {
				toast.success(query.meta.successMessage);
			}
		},
	}),
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			useErrorBoundary: (error, query) => {
				if (error.code >= 500 || error.code === 404) {
					throw new Response("", {
						statusText: error.message,
						status: error.code,
						// status: error.status,
					});
				} else {
					toast.error(error.message);
				}
			},
		},
	},
});
