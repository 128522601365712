import styles from "./Section.module.scss";

const Section = ({ title, icon, content }) => {
    return (
        <div className={styles.container}>
            <h3>
                <img src={icon} alt="" />
                {title}
            </h3>
            {content}
        </div>
    );
};

export default Section;
