import React from "react";
import styles from "./ConnectionModal.module.css";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import SecondaryButton from "../../Utils/Button/SecondaryButton";
import ProfilePic from "../../Home/ProfilePic";

const ConnectionModal = ({ show, action, onConfirm, onClose, name, profileImage }) => {
    let title = "";
    let text = "";
    let btnText = "";
    let btnText2 = "";
    let isWarning = false;

    switch (action) {
        case "ADD_WORK":
            title = "Add to Work Circle";
            btnText = "add";
            text = <div className={styles.text}>{`Are you sure you want to add ${name} to your Work Circle?`}</div>;
            break;
        case "REMOVE_WORK":
            title = "Remove from Work Circle";
            btnText = "remove";
            isWarning = true;
            text = <div className={styles.text}>{`Are you sure you want to remove ${name} to your Work Circle?`}</div>;
            break;
        case "ADD_FRIEND":
            title = "Add as friend";
            btnText = "add";
            text = <div className={styles.text}>{`Are you sure you want to add ${name} as a friend?`}</div>;
            break;
        case "REVOKE_FRIEND":
            title = "Revoke Friend request";
            btnText = "revoke";
            text = (
                <div className={styles.text}>{`Are you sure you want to revoke your friend request to ${name}?`}</div>
            );
            break;
        case "ACCEPT_FRIEND":
            title = "Friend request";
            btnText = "accept";
            btnText2 = "decline";
            text = <div className={styles.text}>{`${name} sent you a friend request.`}</div>;
            break;
        case "REMOVE_FRIEND":
            title = "Remove Friend";
            btnText = "remove";
            isWarning = true;
            text = <div className={styles.text}>{`Are you sure you want to remove ${name} from your friends?`}</div>;
            break;
        case "FOLLOW":
            title = "Follow";
            btnText = "follow";
            text = <div className={styles.text}>{`Are you sure you want to follow ${name}?`}</div>;
            break;
        case "UNFOLLOW":
            title = "Unfollow";
            btnText = "unfollow";
            isWarning = true;
            text = <div className={styles.text}>{`Are you sure you want to unfollow ${name}?`}</div>;
            break;
        case "BLOCK":
            title = "Block Connection";
            btnText = "block";
            isWarning = true;
            text = (
                <div
                    className={styles.text}
                >{`Are you sure you want to block ${name}? You will not receive messages or notifications, and you’ll not be connected.`}</div>
            );
            break;
        case "UNBLOCK":
            title = "Unblock Connection";
            btnText = "unblock";
            text = (
                <div
                    className={styles.text}
                >{`Are you sure you want to unblock ${name}? You will restart receiving messages and notifications.`}</div>
            );
            break;
        default:
            break;
    }

    return (
        <GaawkModal
            show={show}
            handleClose={onClose}
            showHeader={true}
            defaultModal={false}
            title={title}
            closeAlign={"right"}
            children={
                <div className={styles.modal_container}>
                    <div className={styles.content_wrapper}>
                        <ProfilePic type={"USER"} thumbnail={profileImage} w={"52px"} h={"52px"} noMarginRight={true} />
                        {text}
                    </div>
                    <div className={styles.btn_container}>
                        {btnText2 ? (
                            <SecondaryButton
                                text={btnText2}
                                className={styles.cancel_btn}
                                onClick={() => onConfirm("DECLINE_FRIEND")}
                            />
                        ) : (
                            <SecondaryButton text={"cancel"} className={styles.cancel_btn} onClick={onClose} />
                        )}
                        <PrimaryButton
                            text={btnText}
                            className={`${styles.modal_btn} ${isWarning ? styles.red : undefined}`}
                            onClick={() => onConfirm(action)}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default ConnectionModal;
