import React from "react";
import styles from "./ProfileSection.module.css";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import CtaButton from "components/Utils/Button/CtaButton";
import EditButton from "components/Utils/Button/EditButton";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";

const ProfileSection = ({
	show,
	loading,
	canEdit,
	label,
	data,
	onEdit,
	onAdd,
	btnText = "",
	infoText,
}) => {
	return (
		<>
			{loading && (
				// <div className={styles.container}>
				<ProfileTabContainer minHeight={false}>
					<h4 className={styles.title}>{label}</h4>
					<LoadingSpinner />
				</ProfileTabContainer>
				//</div>
			)}

			{show && !loading && (
				// <div className={styles.container}>
				<ProfileTabContainer minHeight={false}>
					<div className={styles.item_header}>
						<h4 className={styles.title}>{label}</h4>
						{canEdit && <EditButton onClick={onEdit} />}
					</div>

					{data}
				</ProfileTabContainer>
				// </div>
			)}

			{!show && canEdit && !loading && (
				// <div className={styles.emptyContainer}>
				<ProfileTabContainer minHeight={false}>
					<h4 className={styles.title}>{label}</h4>

					{infoText && (
						<div className={styles.emptyText}>{infoText}</div>
					)}
					<div className={styles.emptyBtn}>
						<CtaButton
							onClick={onAdd}
							text={`+ ADD ${
								btnText
									? btnText.toUpperCase()
									: label.toUpperCase()
							}`}
							className={styles.custom_btn}
						/>
					</div>
				</ProfileTabContainer>
				// </div>
			)}
		</>
	);
};

export default ProfileSection;
