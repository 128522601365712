import { create } from "apisauce";
import { differenceInHours, getTime } from "date-fns";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_USER = process.env.REACT_APP_AUTH_USER;
const AUTH_PASS = process.env.REACT_APP_AUTH_PASSWORD;
const expiryInHours = 12;

const apiClient = create({
	baseURL: BASE_URL,
});

const getToken = async () => {
	// todo:: secure this IMPORTANT
	// check the local storage
	const savedToken = localStorage.getItem("app_session_id");
	const savedTime = localStorage.getItem("app_session_time");

	if (savedToken && savedTime && !isExpired(savedTime))
		return `Bearer ${savedToken}`;

	// call api to get a new token
	const client = create({ baseURL: BASE_URL });

	const result = await client.post("/authenticate", {
		password: AUTH_PASS,
		username: AUTH_USER,
	});

	if (!result.ok) return alert("Error connecting to server");

	const jwtToken = result.data.jwttoken;

	localStorage.setItem("app_session_id", jwtToken);
	localStorage.setItem("app_session_time", getTime(new Date()));

	return `Bearer ${jwtToken}`;
};

apiClient.addAsyncRequestTransform((request) => async () => {
	const companyId = localStorage.getItem("companyId");

	if (!request.headers.skipAuth) {
		const jwtToken = await getToken();
		request.headers["Authorization"] = jwtToken;
	}

	const profileId =
		companyId && !request.headers.useUserId
			? companyId
			: localStorage.getItem("uuid");
	const userId = localStorage.getItem("uuid");

	request.headers["profileId"] = profileId;
	request.headers["userId"] = userId;
});

const isExpired = (savedTime) => {
	return (
		differenceInHours(getTime(new Date()), parseInt(savedTime)) >=
		expiryInHours
	);
};

export default apiClient;
