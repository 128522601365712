import { useEffect, useRef } from "react";
import styles from "./TagContainer.module.css";

const TagContainer = ({
	label,
	items,
	customStyle,
	containerClassName,
	border = false,
	scrollToBottomEnabled = false,
}) => {
	const bottomContainerRef = useRef(null);

	const scrollToBottom = () => {
		bottomContainerRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		if (scrollToBottomEnabled) scrollToBottom();
	}, [items, scrollToBottomEnabled]);

	return (
		<div className={`${styles.container} ${customStyle}`}>
			{label && <label className={styles.label}>{label}</label>}
			<div
				className={`${styles.tag_container} ${containerClassName} ${
					border ? styles.has_border : undefined
				}`}
			>
				{items}
				<div ref={bottomContainerRef} />
			</div>
		</div>
	);
};

export default TagContainer;
