import styles from "./VoiceNoteItem.module.scss";
import { useCallback, useEffect, useRef } from "react";
import { useWavesurfer } from "@wavesurfer/react";
import { format } from "date-fns";
import playIcon from "images/play-icon-blue.svg";
import pauseIcon from "images/pause-icon-blue.svg";
import micIcon from "images/mic-icon-blue.svg";

const formatTime = (seconds) =>
	[seconds / 60, seconds % 60]
		.map((v) => `0${Math.floor(v)}`.slice(-2))
		.join(":");

const VoiceNoteItem = ({ item, isReply }) => {
	const audioRef = useRef(null);

	const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
		container: audioRef,
		height: 35,
		barRadius: 5,
		barWidth: 3,
		cursorWidth: 3,
		cursorColor: "#6cc5d1",
		waveColor: "#dbdfe4",
		progressColor: "#6cc5d1",
		dragToSeek: true,
		url: item?.media?.file?.customName,
	});

	const onPlayPause = useCallback(() => {
		wavesurfer && wavesurfer.playPause();
	}, [wavesurfer]);

	useEffect(() => {
		wavesurfer &&
			wavesurfer.on("finish", () => {
				wavesurfer.setTime(0);
			});
	}, [wavesurfer]);

	const voiceNoteDuration = format(item.seconds * 1000, "mm:ss");

	return (
		<div className={styles.container}>
			<button onClick={onPlayPause}>
				{isPlaying ? (
					<img
						src={pauseIcon}
						className={styles.audio_control_icon}
						alt=""
					/>
				) : (
					<img
						src={playIcon}
						className={styles.audio_control_icon}
						alt=""
					/>
				)}
			</button>

			<div ref={audioRef} id={styles.waveform} />

			<div className={styles.right_side}>
				<img
					src={micIcon}
					className={styles.mic_icon}
					alt="voice note"
				/>
				<p>{isPlaying ? formatTime(currentTime) : voiceNoteDuration}</p>
			</div>
		</div>
	);
};

export default VoiceNoteItem;
