import Joi from "joi";
// import { passwordRegex } from "../../Utils/General";

const passwordSchema = Joi.object({
	password: Joi.string()
		// .required()
		// .min(8)

		.required()
		.messages({
			"string.empty": "Password is required",
		})
		.regex(/.{8,}/)
		.message("length")
		.regex(/[A-Z]/)
		.message("upper")
		.regex(/[a-z]/)
		.message("lower")
		.regex(/\d/)
		.message("digit")
		.regex(/[@$!%*?&]/)
		.message("special"),

	// .pattern(passwordRegex)
	// .required()
	// .label("Password")
	// .messages({
	//     "string.pattern.base":
	//         "Must be at least 8 characters, it should include at least 1 upper case character, 1 number and 1 special character.",
	//     "string.empty": "Password should not be empty",
	// }),
	confirmPassword: Joi.string()
		.required()
		.valid(Joi.ref("password"))
		.label("Confirm password")
		.messages({
			"any.only": "Does not match the password",
		}),
});

export default passwordSchema;
