import { useState } from "react";
import styles from "./All.module.scss";
import searchApi from "../../../api/search";
import ProductsComponent from "../SearchComponents/Products";
import { productsInitialFilters } from "../initialFilters";
import useGlobalSearch from "../../../hooks/useGlobalSearch";

const Products = ({
	searchInput,
	isMobile,
	onShowModal,
	tagsList,
	userCoordinates,
	onRefetch,
}) => {
	const [order, setOrder] = useState("NEWEST");
	const [layout, setLayout] = useState("grid");

	const {
		data,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		handleInvalidation,
	} = useGlobalSearch(
		productsInitialFilters,
		{ locationIds: [userCoordinates.id] },
		["searchProducts", order],
		searchInput,
		searchApi.searchProducts,
		order
	);

	return (
		<div className={styles.section}>
			<div className={styles.sub_section}>
				<ProductsComponent
					count={data?.pages[0].count}
					data={data}
					isPaginated={true}
					hasNextPage={hasNextPage}
					isFetching={isFetching}
					onLoad={fetchNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					order={order}
					layout={layout}
					onChangeOrder={setOrder}
					onChangeLayout={setLayout}
					tagsList={tagsList}
					onRefresh={() => {
						handleInvalidation();
						onRefetch();
					}}
				/>
			</div>
		</div>
	);
};

export default Products;
