import styles from "./Tabs.module.css";
import useApi from "../../../../hooks/useApi";
import useConnection from "../hooks/useConnection";
import connectionApi from "../../../../api/connection";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupMember from "../../../Chat/GroupMember";
// import addWorkIcon from "images/add-work-40-x-35.svg";
// import addedWorkIcon from "images/added-work-icon.svg";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";

const TheirWorkCircle = ({ profile, query, count, onUpdateCounter }) => {
	const getReceivedWorkCircleApi = useApi(
		connectionApi.getReceivedWorkCircle
	);
	// const addWorkCircleApi = useApi(connectionApi.addWorkCircle);
	// const removeWorkCircleApi = useApi(connectionApi.removeWorkCircle);

	const {
		data: otherWorkCircle,
		fetchData,
		// updateConnection,
	} = useConnection(getReceivedWorkCircleApi, query, profile.uuid);

	// const [profileId, setProfileId] = useState("");

	// const handleWorkCircle = (id, action) => {
	//     setProfileId(id);
	//     if (action === "ADD") {
	//         addWorkCircleApi.request(id);
	//     } else {
	//         removeWorkCircleApi.request(id);
	//     }
	// };

	const workCircleList = otherWorkCircle.map((item) => (
		// <div className={styles.contact_item} key={item.uuid}>
		<GroupMember
			key={item.uuid}
			data={item}
			redirect={true}
			// rightSideAction={
			//     item.connection.inMyWorkCircle ? (
			//         <img
			//             src={addedWorkIcon}
			//             className={styles.action_icon}
			//             onClick={() => handleWorkCircle(item.uuid, "REMOVE")}
			//             alt="remove from my work circle"
			//         />
			//     ) : (
			//         <img
			//             src={addWorkIcon}
			//             className={styles.action_icon}
			//             onClick={() => handleWorkCircle(item.uuid, "ADD")}
			//             alt="add to work circle"
			//         />
			//     )
			// }
		/>
		// </div>
	));

	// useEffect(() => {
	//     if (
	//         !addWorkCircleApi.loading &&
	//         addWorkCircleApi.responseCode === 201
	//     ) {
	//         updateConnection(profileId, "inMyWorkCircle");
	//         onUpdateCounter("inMyWorkCircle", "INCREMENT");
	//     }
	// }, [addWorkCircleApi.loading, addWorkCircleApi.responseCode]);

	// useEffect(() => {
	//     if (
	//         !removeWorkCircleApi.loading &&
	//         removeWorkCircleApi.responseCode === 200
	//     ) {
	//         updateConnection(profileId, "inMyWorkCircle");
	//         onUpdateCounter("inMyWorkCircle", "DECREMENT");
	//     }
	// }, [removeWorkCircleApi.loading, removeWorkCircleApi.responseCode]);

	return (
		<div id="scrollDiv" className={styles.container}>
			<InfiniteScroll
				dataLength={otherWorkCircle.length}
				next={fetchData}
				hasMore={
					!query &&
					otherWorkCircle.length > 0 &&
					otherWorkCircle.length < count
				}
				loader={<ContactLoader />}
				scrollableTarget="scrollDiv"
			>
				{getReceivedWorkCircleApi.loading && <ContactLoader />}
				{workCircleList.length > 0 &&
					!getReceivedWorkCircleApi.loading &&
					workCircleList}
			</InfiniteScroll>
		</div>
	);
};

export default TheirWorkCircle;
