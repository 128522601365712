import { useQuery } from "@tanstack/react-query";
import projectApi from "api/project";
import useApi from "hooks/useApi";
import { projectKeys } from "queryKeys/projects-key-factory";

const useProject = (projectId) => {
	const getProjectByIdApi = useApi(projectApi.getProject, true, true);

	const fetchProjectById = async ({ queryKey }) => {
		const [_, __, projectId] = queryKey;
		const response = await getProjectByIdApi.request(projectId);
		return response.data;
	};

	const query = useQuery({
		queryKey: projectKeys.detail(projectId),
		queryFn: fetchProjectById,
		enabled: !!projectId,
	});

	return query;
};

export default useProject;
