import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	step1: null,
	step2: null,
};

const slice = createSlice({
	name: "registration",
	initialState,
	reducers: {
		updateStep1: (state, action) => {
			state.step1 = action.payload;
		},
		updateStep2: (state, action) => {
			state.step2 = action.payload;
		},
		resetRegistration: () => {
			return initialState;
		},
	},
});

export const { updateStep1, updateStep2, resetRegistration } = slice.actions;
export default slice.reducer;
