import { useQuery, useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";
import BackButton from "components/Utils/Button/BackButton";
import CtaButton from "components/Utils/Button/CtaButton";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import { featuresSection } from "components/Utils/General";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import Tag from "components/Utils/SubComs/Tags/Tag";
import useCurrentUser from "hooks/useCurrentUser";
import useJob from "hooks/useJob";
import useMutate from "hooks/useMutate";
import { ReactComponent as DeleteIcon } from "images/deleteInMenu.svg";
import { classNames } from "primereact/utils";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { components } from "react-select";
import specialProfileApi from "../../../api/special-profile";
import vaultApi from "../../../api/vault";
import useApi from "../../../hooks/useApi";
import ProfilePic from "../../Home/ProfilePic";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import CustomRadioButton from "../../Utils/SubComs/CustomRadioButton/CustomRadioButton";
import CustomSelect from "../../Utils/SubComs/CustomSelect/CustomSelect";
import JobInfoItem from "../JobsComponents/JobInfoItem/JobInfoItem";
import styles from "./JobApply.module.css";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import routes from "components/Routing/routing-keys";

const itemsPerPage = 5;

const VaultFile = ({ item, onClick, checked, onDelete }) => {
	return (
		<button className={styles.file_item} onClick={onClick}>
			<div className={styles.file_item_info}>
				<CustomRadioButton readOnly={true} checked={checked} />
				<div>{item.name}</div>
			</div>
			<div
				className={styles.delete_button}
				role={"button"}
				tabIndex={0}
				onClick={onDelete}
			>
				<DeleteIcon fill={"#000"} />
			</div>
		</button>
	);
};

const JobApply = () => {
	// ! states ========================
	// const [isApplying, setIsApplying] = useState(true);
	const [showApplyModal, setShowApplyModal] = useState(false);
	const [itemToDelete, setItemToDelete] = useState(undefined);

	const [useLoadedFile, setUseLoadedFile] = useState(false);
	console.log("🚀 ~ useLoadedFile >>", useLoadedFile);
	const [useLoadedCoverFile, setUseLoadedCoverFile] = useState(false);
	console.log("🚀 ~ useLoadedCoverFile >>", useLoadedCoverFile);

	const [selectedPortfolio, setSelectedPortfolio] = useState();
	console.log("🚀 ~ selectedPortfolio >>", selectedPortfolio);
	const [selectedCover, setSelectedCover] = useState();
	console.log("🚀 ~ selectedCover >>", selectedCover);

	const [portfolioFile, setPortfolioFile] = useState();
	console.log("🚀 ~ portfolioFile >>", portfolioFile);
	const [portfolioError, setPortfolioError] = useState(false);

	const [coverFile, setCoverFile] = useState();
	console.log("🚀 ~ coverFile >>", coverFile);
	const [coverError, setCoverError] = useState(false);
	const [coverText, setCoverText] = useState("");
	console.log("🚀 ~ coverText >>", coverText);
	console.log("--------------");

	const [selectedProfile, setSelectedProfile] = useState();

	// ! ===============================

	const navigate = useNavigate();
	const { type, uuid } = useCurrentUser();

	if (type === "COMPANY") {
		//* not allowing a company to access the /apply page
		navigate(-1);
	}

	const portfolioInput = useRef(null);
	const coverLetterInput = useRef(null);

	// const user = useSelector((state) => state.user.profileInfo);

	//* >> fetching job to make sure the url is correct and user is not trying to a unknown jobId
	const { jobId } = useParams();
	const { data: job, isFetching } = useJob(jobId);

	// ! get portfolio =====
	const getPortfolioApi = useApi(vaultApi.getPortfolio, true, true);

	const fetchPortfolio = async () => {
		const response = await getPortfolioApi.request(0, itemsPerPage);
		return response.data;
	};

	const { data: portfolios, isFetching: isFetchingPortfolio } = useQuery({
		queryKey: vaultKeys.portfolio(),
		queryFn: fetchPortfolio,
	});

	const portfolioList = useMemo(
		() =>
			portfolios?.map((item) => (
				<VaultFile
					key={item.uuid}
					item={item}
					onClick={() => {
						setSelectedPortfolio({
							uuid: item.uuid,
							name: item.name,
						});
						setUseLoadedFile(false);
						// setPortfolioFile();
					}}
					onDelete={(e) => {
						e.stopPropagation();
						setDeleteModal(true);
						setItemToDelete({
							file: item,
							type: "portfolio",
						});
					}}
					checked={selectedPortfolio?.uuid === item.uuid}
				/>
			)),
		[portfolios, selectedPortfolio?.uuid]
	);

	// ! get cover letters ====
	const getCoverLetterApi = useApi(vaultApi.getCoverLetter, true, true);

	const fetchCoverLetters = async () => {
		const response = await getCoverLetterApi.request(0, itemsPerPage);
		return response.data;
	};

	const { data: coverLetters, isFetching: isFetchingCoverLetters } = useQuery(
		{
			queryKey: vaultKeys.coverLetters(),
			queryFn: fetchCoverLetters,
		}
	);

	const coverLettersList = useMemo(
		() =>
			coverLetters?.map((item) => (
				<VaultFile
					key={item.uuid}
					item={item}
					onClick={() => {
						setSelectedCover({ uuid: item.uuid, name: item.name });
						setCoverText("");
						setUseLoadedCoverFile(false);
						// setCoverFile();
					}}
					onDelete={(e) => {
						e.stopPropagation();
						setDeleteModal(true);
						setItemToDelete({
							file: item,
							type: "cover",
						});
					}}
					checked={selectedCover?.uuid === item.uuid}
				/>
			)),
		[coverLetters, selectedCover?.uuid]
	);

	// ! get special profiles ===
	const getAllSpecialApi = useApi(
		specialProfileApi.getAllSpecial,
		true,
		true
	);

	const fetchSpecialProfiles = async ({ queryKey }) => {
		const [_, __, userId] = queryKey;
		const response = await getAllSpecialApi.request(userId);
		return response.data;
	};

	const { data: specialProfiles, isFetching: isFetchingSpecialProfiles } =
		useQuery({
			queryKey: specialProfileKeys.lists(uuid),
			queryFn: fetchSpecialProfiles,
		});

	const specialProfileFormatted = useMemo(
		() =>
			specialProfiles?.map((profile) => ({
				value: profile.uuid,
				label: profile.name,
				profile,
			})),
		[specialProfiles]
	);

	// !=========DELETE FILE===========
	const queryClient = useQueryClient();

	const [deleteModal, setDeleteModal] = useState(false);

	const {
		action: { mutate: deleteFile },
	} = useMutate(vaultApi.deleteItems, () => {
		setDeleteModal(false);

		if (itemToDelete.type === "portfolio") {
			queryClient.invalidateQueries(vaultKeys.portfolio());
		} else {
			queryClient.invalidateQueries(vaultKeys.coverLetters());
		}
		setItemToDelete(undefined);
	});

	// !====================

	const fileSizeCheck = (fileSize) => {
		if ((fileSize / (1024 * 1024)).toFixed(2) < 2) return true;
		else return false;
	};

	const handlePortfolioChange = (val) => {
		setSelectedPortfolio();
		const file = val.target.files[0];
		if (file) {
			if (fileSizeCheck(file.size)) {
				setPortfolioFile(file);
				setPortfolioError(false);
				setUseLoadedFile(true);
			} else {
				setPortfolioFile();
				setPortfolioError(true);
				setUseLoadedFile(false);
			}
		}
		val.target.value = "";
	};

	const handleCoverChange = (val) => {
		setSelectedCover();
		setCoverText("");
		const file = val.target.files[0];
		if (file) {
			if (fileSizeCheck(file.size)) {
				setCoverFile(file);
				setCoverError(false);
				setUseLoadedCoverFile(true);
			} else {
				setCoverFile();
				setCoverError(true);
				setUseLoadedCoverFile(false);
			}
		}
		val.target.value = "";
	};

	const handleCoverText = ({ target }) => {
		setCoverError();
		setCoverFile();
		setSelectedCover();
		setCoverText(target.value);
		setUseLoadedCoverFile(false);
	};

	// 	//TODO >> no need to redirect to another page, should do a multi step modal to not loose file state
	// 	// navigate(`/jobs/${jobId}/review`, {
	// 	// 	state: {
	// 	// 		body,
	// 	// 		//  jobData: data //TODO >> make
	// 	// 	},
	// 	// });
	// };

	const {
		action: { mutate: apply, isLoading },
	} = useMutate(jobApi.apply, (response) => {
		navigate(routes.applicationConfirm(jobId), { state: response.data });
	});

	const handleApply = () => {
		const formData = new FormData();

		if (coverFile && useLoadedCoverFile)
			formData.append("coverLetterFile", coverFile);
		if (coverText) formData.append("coverLetterText", coverText);
		if (selectedCover)
			formData.append("coverLetterVaultItemId", selectedCover.uuid);

		if (portfolioFile && useLoadedFile)
			formData.append("portfolioFile", portfolioFile);
		if (selectedPortfolio)
			formData.append("portfolioVaultItemId", selectedPortfolio.uuid);
		if (selectedProfile)
			formData.append("specialProfileId", selectedProfile.uuid);

		apply({
			jobId,
			formData,
		});
	};

	return (
		<DefaultPage
			headerRightContent={
				<>
					<div className={styles.header}>
						<BackButton onBack={() => navigate(-1)} />
						Review application
					</div>
				</>
			}
			defaultHeader={false}
			leftSideChildren={<FeaturesSidebar active={featuresSection.jobs} />}
			rightSideChildren={
				<>
					<div className={styles.sub_container}>
						<div className={styles.input_wrapper}>
							<label>Attach Portfolio</label>
							{portfolios?.length > 0 && (
								<div className={styles.files_wrapper}>
									{portfolioList}
								</div>
							)}

							{!portfolioError && portfolioFile && (
								<div className={styles.loaded_item}>
									<VaultFile
										item={portfolioFile}
										onClick={() => {
											setSelectedPortfolio(undefined);
											setUseLoadedFile(true);
										}}
										checked={useLoadedFile}
										onDelete={(e) => {
											e.stopPropagation();
											setUseLoadedFile(false);
											setPortfolioFile(undefined);
										}}
									/>
								</div>
							)}

							<div className={styles.custom_upload_button}>
								<input
									ref={portfolioInput}
									type="file"
									accept=".pdf"
									key={portfolioFile?.lastModified}
									onChange={handlePortfolioChange}
									tabIndex="-1"
									style={{ display: "none" }}
								/>

								<CtaButton
									onClick={() => {
										portfolioInput.current.click();
									}}
									text={"+ UPLOAD NEW PORTFOLIO"}
								/>
								<div className={styles.text_info}>
									You can upload PDF files only. Max size 2MB.
								</div>
								{portfolioError && !selectedPortfolio && (
									<div
										className={classNames(
											styles.text_info,
											styles.error
										)}
									>
										Your file is bigger than 2MB
									</div>
								)}
							</div>
						</div>

						<div className={styles.input_wrapper}>
							<label>Add Cover Letter</label>
							<div className={styles.text_info}>
								Select / Upload Cover letter <span>OR</span>{" "}
								paste it in the box below.
								<br />
								PDF and MS Word files only. Max size 2MB.
							</div>
							{coverLetters?.length > 0 && (
								<div className={styles.files_wrapper}>
									{coverLettersList}
								</div>
							)}

							{!coverError && coverFile && (
								<div className={styles.loaded_item}>
									<VaultFile
										item={coverFile}
										onClick={() => {
											setSelectedCover(undefined);
											setUseLoadedCoverFile(true);
										}}
										checked={useLoadedCoverFile}
										onDelete={(e) => {
											e.stopPropagation();
											setUseLoadedCoverFile(false);
											setCoverFile(undefined);
										}}
									/>
								</div>
							)}

							<div className={styles.custom_upload_button}>
								<input
									type="file"
									key={coverFile?.lastModified}
									accept=".pdf, .doc, .docx"
									onChange={handleCoverChange}
									tabIndex="-1"
									ref={coverLetterInput}
									style={{ display: "none" }}
								/>

								<CtaButton
									onClick={() => {
										coverLetterInput.current.click();
									}}
									text={"+ UPLOAD COVER LETTER"}
								/>
							</div>

							{coverError && !selectedCover && (
								<div
									className={classNames(
										styles.text_info,
										styles.error
									)}
								>
									Your file is bigger than 2MB
								</div>
							)}

							<textarea
								onChange={handleCoverText}
								value={coverText}
								placeholder="Enter your text"
							/>
						</div>

						<div className={styles.react_select_wrapper}>
							<label className={styles.sub_title}>
								Link a Special Profile
							</label>

							<div>
								<CustomSelect
									options={specialProfileFormatted}
									placeholder="Enter a name"
									height="35px"
									maxMenuHeight={200}
									components={{
										Option: (props) => {
											const { profile } = props.data;

											return (
												<components.Option {...props}>
													<div
														className={
															styles.member_item
														}
													>
														<ProfilePic
															thumbnail={
																profile?.profilePic
																	? profile
																			.profilePic
																			.file
																			.customName
																	: ""
															}
															w={36}
															h={36}
															type={"USER"}
															name={profile.name}
															createMode={true}
														/>
													</div>
												</components.Option>
											);
										},
										Control: ({ children, ...props }) => {
											const profilePic =
												props.getValue()[0]?.profile
													?.profilePic.file
													.customName;

											return (
												<components.Control {...props}>
													{profilePic ? (
														<div
															className={
																styles.dropdown_item
															}
														>
															<img
																src={profilePic}
																alt=""
															/>
															{children}
														</div>
													) : (
														children
													)}
												</components.Control>
											);
										},
									}}
									onChange={(e) =>
										setSelectedProfile(e.profile)
									}
								/>
							</div>
						</div>

						<div className={styles.button_container}>
							<PrimaryButton
								text={"review your application"}
								className={styles.review_btn}
								onClick={() => setShowApplyModal(true)}
							/>
						</div>
					</div>

					<WarningModal
						show={deleteModal}
						headerText={`${itemToDelete?.file?.name} will be deleted for you and everyone you shared it with. Are you sure?`}
						warningText="This action cannot be undone."
						cancelButtonText={"Cancel"}
						onCancelButtonClicked={(e) => {
							e.stopPropagation();
							setDeleteModal(false);
						}}
						submitButtonText={"DELETE"}
						onSubmitButtonClicked={(e) => {
							e.stopPropagation();
							deleteFile([itemToDelete?.file]);
						}}
					/>

					<GaawkModal
						show={showApplyModal}
						title={"Review your application"}
						closeAlign={"right"}
						handleClose={() => setShowApplyModal(false)}
						defaultModal={false}
						showHeader={true}
						children={
							<div className={styles.review_container}>
								<JobInfoItem
									title="Portfolio"
									content={
										selectedPortfolio || portfolioFile ? (
											<Tag
												itemName={
													selectedPortfolio
														? selectedPortfolio.name
														: portfolioFile.name
												}
												customStyle={styles.tag}
											/>
										) : (
											<i>No portfolio attached</i>
										)
									}
								/>

								<JobInfoItem
									title="Cover Letter"
									content={
										selectedCover || coverFile ? (
											<Tag
												itemName={
													selectedCover
														? selectedCover.name
														: coverFile.name
												}
												customStyle={styles.tag}
											/>
										) : coverText ? (
											<p>{coverText}</p>
										) : (
											<i>No cover letter attached</i>
										)
									}
								/>

								<JobInfoItem
									title="Special Profile"
									content={
										selectedProfile ? (
											<ProfilePic
												type={"USER"}
												thumbnail={
													selectedProfile.profilePic
														.file.customName
												}
												name={selectedProfile.name}
											/>
										) : (
											<i>No special profile attached</i>
										)
									}
								/>
								<div className={styles.button_container}>
									<PrimaryButton
										text={"Apply"}
										className={styles.review_btn}
										onClick={handleApply}
										isLoading={isLoading}
									/>
								</div>
							</div>
						}
					/>
				</>
			}
			rightSideContainerColor={"#FFF"}
		/>
	);
};

export default JobApply;
