import LeftSide from "components/Utils/SubComs/Left-Side/LeftSide";
import styles from "./ThreeColumnPage.module.scss";
import RightSideColumn from "./RightSideColumn";
import BottomNavBar from "components/Utils/SubComs/bottomNavBar/BottomNavBar";
import HeaderBar from "components/Utils/SubComs/HeaderBar/HeaderBar";
import MainHeader from "components/Utils/SubComs/MainHeader/MainHeader";
import CenterContainer from "components/Utils/SubComs/CenterContainer/CenterContainer";

const ThreeColumnPage = ({
	leftSideChildren,
	centerChildren,
	rightSideChildren,
	headerRightContent,
	hasMobileHeader = true,
	defaultHeader = true,
	bgColor = undefined,
}) => {
	return (
		<>
			{defaultHeader ? (
				<MainHeader />
			) : (
				<HeaderBar
					headerRightContent={headerRightContent}
					hasMobileHeader={hasMobileHeader}
				/>
			)}

			<div className={styles.container}>
				<LeftSide children={leftSideChildren} />

				<CenterContainer children={centerChildren} bgColor={bgColor} />

				<RightSideColumn
					children={rightSideChildren}
					bgColor={bgColor}
				/>
			</div>

			{/* Show only in mobile mode (below 748px) because bottomBar in left pannel will be hidden */}
			<div className={styles.bottomNavBar}>
				<BottomNavBar />
			</div>
		</>
	);
};

export default ThreeColumnPage;
