import React, { useState, useEffect } from "react";
import styles from "./Tabs.module.css";
import useApi from "../../../../hooks/useApi";
import useConnection from "../hooks/useConnection";
import connectionApi from "../../../../api/connection";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupMember from "../../../Chat/GroupMember";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";

const Following = ({ profile, query, count, onUpdateCounter }) => {
	const getFollowingApi = useApi(connectionApi.getFollowing);
	// const followApi = useApi(connectionApi.follow);
	// const unfollowApi = useApi(connectionApi.unfollow);

	const {
		data: following,
		fetchData,
		// updateConnection,
	} = useConnection(getFollowingApi, query, profile.uuid);

	// const [profileId, setProfileId] = useState("");

	// const handleFollowStatus = (id, action) => {
	//     setProfileId(id);
	//     if (action === "FOLLOW") {
	//         followApi.request(id);
	//     } else {
	//         unfollowApi.request(id);
	//     }
	// };

	const followingList = following.map((item) => (
		// <div className={styles.contact_item} key={item.uuid}>
		<GroupMember
			key={item.uuid}
			data={item}
			redirect={true}
			// rightSideAction={
			//     item.connection.following ? (
			//         <span
			//             className={styles.rightaction_text}
			//             onClick={() =>
			//                 handleFollowStatus(item.uuid, "UNFOLLOW")
			//             }
			//         >
			//             Unfollow
			//         </span>
			//     ) : (
			//         <span
			//             className={styles.rightaction_text}
			//             onClick={() => handleFollowStatus(item.uuid, "FOLLOW")}
			//         >
			//             Follow
			//         </span>
			//     )
			// }
		/>
		// </div>
	));

	// useEffect(() => {
	//     if (!followApi.loading && followApi.responseCode === 201) {
	//         updateConnection(profileId, "following");
	//         onUpdateCounter("following", "INCREMENT");
	//     }
	// }, [followApi.loading, followApi.responseCode]);

	// useEffect(() => {
	//     if (!unfollowApi.loading && unfollowApi.responseCode === 200) {
	//         updateConnection(profileId, "following");
	//         onUpdateCounter("following", "DECREMENT");
	//     }
	// }, [unfollowApi.loading, unfollowApi.responseCode]);

	return (
		<div id="scrollDiv" className={styles.container}>
			<InfiniteScroll
				dataLength={following.length}
				next={fetchData}
				hasMore={
					!query && following.length > 0 && following.length < count
				}
				loader={<ContactLoader />}
				scrollableTarget="scrollDiv"
			>
				{getFollowingApi.loading && <ContactLoader />}
				{followingList.length > 0 &&
					!getFollowingApi.loading &&
					followingList}
			</InfiniteScroll>
		</div>
	);
};

export default Following;
