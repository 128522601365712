import React, { useMemo } from "react";
import styles from "./SpecialProfileEdit.module.css";
import { useNavigate } from "react-router-dom";
import useSpecialProfiles from "hooks/useSpecialProfiles";
import { useDispatch, useSelector } from "react-redux";
import SpecialProfileItem from "./SpecialProfileItem";
import Loader from "react-loader-spinner";
import { useQueryClient } from "@tanstack/react-query";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import useMutate from "hooks/useMutate";
import specialProfileApi from "api/special-profile";
import { updateSpecialProfileCounts } from "store/slices/user";
import CtaButton from "components/Utils/Button/CtaButton";
import routes from "components/Routing/routing-keys";

const SpecialProfileEdit = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const { uuid, specialProfileCounts } = useSelector(
		(state) => state.user.profileInfo
	);

	const { data, isLoading } = useSpecialProfiles(uuid);

	const {
		action: { mutate: deleteSpecialProfile },
	} = useMutate(specialProfileApi.deleteSpecialProfile, () => {
		queryClient.invalidateQueries(specialProfileKeys.lists(uuid));
		dispatch(updateSpecialProfileCounts(specialProfileCounts - 1));
	});

	const formattedSpecial = useMemo(
		() =>
			data?.map((specialProfile) => (
				<SpecialProfileItem
					key={specialProfile.uuid}
					canEdit={true}
					item={specialProfile}
					onDelete={() => {
						deleteSpecialProfile(specialProfile.uuid);
					}}
				/>
			)),
		[data]
	);

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD SPECIAL PROFILE"
					onClick={() => navigate(routes.specialProfilesList("/add"))}
				/>
			</div>

			<div className={styles.container}>
				{/* <button
					onClick={() => navigate(routes.specialProfilesList("/add"))}
				>
					+ ADD SPECIAL PROFILE
				</button> */}

				{isLoading && (
					<div className={styles.loading_container}>
						<Loader
							type="Oval"
							color="#6cc5d1"
							height={40}
							width={40}
						/>
					</div>
				)}
				{data?.length > 0 && !isLoading && (
					<>
						{/* <div className={styles.header}>
							<label>Special Profiles you have created</label>
						</div> */}
						<h4 className={styles.title}>
							Special Profiles you have created
						</h4>

						<div
							className={`${styles.data_section} ${
								data?.length > 2 ? styles.align_left : undefined
							}`}
						>
							{formattedSpecial}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default SpecialProfileEdit;
