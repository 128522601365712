import styles from "./ForUsers.module.scss";
import ForUserSection from "components/Landing/ForUserSection";

const artists = [
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/artist1.png",
        title: "Abstract Painter",
        details: "Sarah - 32",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/artist2.png",
        title: "Realistic Painter",
        details: "Sam - 42",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/artist3.png",
        title: "Ballerina",
        details: "Amal - 25",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/artist4.png",
        title: "Singer",
        details: "Jennifer - 28",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/artist5.png",
        title: "Photographer",
        details: "Anna - 23",
    }
];
const technicians = [
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/techician1.png",
        title: "Electricity Technicians",
        details: "OZ - 32",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/techician2.png",
        title: "Lift Technicians",
        details: "Rony - 44",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/techician3.png",
        title: "Mechanic",
        details: "Jonny - 21",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/techician4.png",
        title: "HVAC Technicians",
        details: "Rob - 28",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/techician5.png",
        title: "IT Support",
        details: "David - 22",
    }
];
const companies = [
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/company1.png",
        title: "ZARA HOME",
        details: "Home Appliances",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/company2.png",
        title: "Evolution",
        details: "Live Events & more",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/company3.png",
        title: "Urban Company",
        details: "Home Services",
    },
    {
        img: "https://gigshack.s3.ap-southeast-1.amazonaws.com/system/company4.png",
        title: "Yalla Dubai",
        details: "Services",
    }
];

const ForUsers = () => {

    return <div className={styles.container}>
            <img className={styles.img} src={require("images/placeholderImage.svg").default} alt={"demo"} />
            <div className={styles.forCreators}>
                <ForUserSection title={"For Artists"} items={artists} />
                <ForUserSection title={"For Technician"} items={technicians} />
                <ForUserSection title={"Even for Companies"} items={companies} />
            </div>
    </div>;
};

export default ForUsers;