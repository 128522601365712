import { useInView } from "react-intersection-observer";
import styles from "./ServiceSection.module.scss";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "../../../Utils/SubComs/NoResults/NoResults";
import Masonry from "react-masonry-css";
import ServiceItemAlt from "./ServiceItemAlt";

const SearchServiceSection = ({
	title,
	queryFn,
	// errorMessage,
	debouncedSearch = "",
	itemsPerPage,
}) => {
	const { ref: viewRef, inView } = useInView();

	const {
		data,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		fetchStatus,
	} = useInfiniteQuery({
		queryKey: ["searchServices", debouncedSearch],
		queryFn,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		// ...(errorMessage && {
		// 	meta: {
		// 		errorMessage,
		// 	},
		// }),
		enabled: !!debouncedSearch,
		cacheTime: 0, // won't store search queries in cache
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) fetchNextPage();
	}, [inView, hasNextPage, fetchNextPage, isFetching]);

	const formattedSearchServices = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((service, i) => (
					<Link
						key={service.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						className={styles.service_item}
						to={`/services/${service.uuid}`}
					>
						<ServiceItemAlt item={service} />
					</Link>
				))
			),
		[data]
	);

	return (
		<div className={styles.container}>
			<div className={styles.section_wrapper}>
				<h3>{title}</h3>

				{fetchStatus !== "idle" && isLoading ? (
					<LoadingSpinner />
				) : (
					<Masonry
						breakpointCols={{
							default: 2,
							1399: 1,
						}}
						className={styles.services_container}
						columnClassName={styles.services_container_grid_columns}
					>
						{formattedSearchServices}
					</Masonry>
				)}

				<NoResults
					text="No results related to your search"
					customStyle={styles.no_padding}
					isSearch={true}
					visible={
						!isLoading &&
						!data?.pages.some((pageArray) => pageArray.length > 0)
					}
				/>
			</div>
		</div>
	);
};

export default SearchServiceSection;
