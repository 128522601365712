import client from "./client";

const endpoint = "/roles";

const getRoles = (page, size, q = "") =>
    client.get(endpoint + `/get?page=${page}&size=${size}&title=${q}`);

const searchRoles = (title) => client.get(endpoint + `/search?title=${title}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getRoles,
    searchRoles,
};
