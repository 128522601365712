import Joi from "joi";
import styles from "./EmailForm.module.scss";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDispatch } from "react-redux";
import useApi from "hooks/useApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import profileApi from "api/profile";
import { toast } from "react-toastify";
import { resetTimer, updateNewEmail } from "store/slices/otp";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Utils/Button/PrimaryButton";

const EmailForm = ({ onNext, onRegisterMobile }) => {
	const schema = Joi.object({
		email: Joi.string()
			.email({ tlds: { allow: false } })
			.required()
			.label("Email")
			.messages({
				"string.empty": "Email should not be empty",
				"string.email": "Email should be valid",
			}),
	});

	const {
		register,
		watch,
		formState: { errors },
		handleSubmit,
	} = useForm({ resolver: joiResolver(schema), mode: "onBlur" });

	const dispatch = useDispatch();

	const checkEmailApi = useApi(profileApi.checkEmail, true);
	const watchEmail = watch("email", "");
	const [emailExists, setEmailExists] = useState(false);

	const handleCheckEmail = useCallback(
		async (email) => {
			const response = await checkEmailApi.request(email);
			if (!response.ok) toast.error("Something wrong happened");
			if (response.status === 200) setEmailExists(response.data);
		},
		[checkEmailApi]
	);

	useEffect(() => {
		let timer;
		if (!errors?.email && watchEmail) {
			timer = setTimeout(() => {
				handleCheckEmail(watchEmail);
			}, 100);
		} else {
			setEmailExists(false);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [watchEmail, errors.email]);

	const handleNext = ({ email }) => {
		if (email && !emailExists) {
			dispatch(resetTimer());
			dispatch(updateNewEmail(email));
			onNext();
		}
	};

	const customEmailError = useMemo(() => {
		if (errors?.email) {
			return (
				<p className={styles.error_message}>{errors?.email?.message}</p>
			);
		} else if (emailExists && !errors?.email) {
			return (
				<p className={styles.error_message}>
					This email is already linked to an account
				</p>
			);
		}
	}, [emailExists, errors?.email]);

	return (
		<form
			className={styles.form_wrapper}
			onSubmit={handleSubmit((data) => handleNext(data))}
		>
			<h3>Enter your email address</h3>
			<div className={styles.info}>
				Add a new email address to your account. This email will not be
				published on your profile unless you choose to make it visible.
			</div>

			<InputWrapper
				label="Email"
				required={true}
				error={errors?.email || emailExists}
				customError={customEmailError}
				component={
					<TextInput
						{...register("email")}
						placeholder="Enter your email"
						error={errors?.email || emailExists}
					/>
				}
			/>

			<PrimaryButton text={"next"} className={styles.next_btn} />

			<button className={styles.gray_btn} onClick={onRegisterMobile}>
				Add another mobile number instead
			</button>
		</form>
	);
};

export default EmailForm;
