import React, { forwardRef } from "react";

import styles from "./RectangleCheckbox.module.css";

const RectangleCheckbox = forwardRef(
    (
        { checked, value, name, onChange, readOnly = false, customStyle },
        ref
    ) => {
        return (
            <div className={`${styles.container} ${customStyle}`}>
                <input
                    ref={ref}
                    readOnly={readOnly}
                    className={styles.input}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    value={value}
                    name={name}
                />
                <span className={`${styles.checkmark}`} />
            </div>
        );
    }
);

export default RectangleCheckbox;
