import profileApi from "api/profile";
import GroupMember from "components/Chat/GroupMember";
import routes from "components/Routing/routing-keys";
import DeleteItemModal from "components/Utils/DeleteItemModal/DeleteItemModal";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import Popover from "components/Utils/Popover/Popover";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useApi from "hooks/useApi";
import useDebounce from "hooks/useDebounce";
import useDirectMessage from "hooks/useDirectMessage";
import usePaginatedResource from "hooks/usePaginatedResource";
import moreIcon from "images/3dot-grey.svg";
import chatIcon from "images/comment-black.svg";
import contactIcon from "images/contact-icon.svg";
import deleteIcon from "images/delete-black.svg";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import styles from "./EmployeeModal.module.scss";
import useMutate from "hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";

const itemsPerPage = 20;

const EmployeeModal = ({ show, onClose, userInfo, canEdit, onUpdateCount }) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState();

	const queryClient = useQueryClient();

	const popoverRef = useRef(null);

	const navigate = useNavigate();
	const { handleDirectMessage } = useDirectMessage();

	const {
		uuid,
		// type
	} = userInfo;

	const [searchInput, setSearchInput] = useState("");

	const debouncedQuery = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const handleClearSearch = () => {
		setSearchInput("");
	};

	const getVerifiedEmployeesApi = useApi(
		profileApi.getVerifiedEmployees,
		true,
		true
	);

	const fetchEmployees = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, companyId, __, query] = queryKey;

		const response = await getVerifiedEmployeesApi.request(
			pageParam,
			itemsPerPage,
			companyId,
			query
		);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			profileKeys.employeesPaginated(uuid, debouncedQuery),
			fetchEmployees,
			itemsPerPage,
			show //&& type === "COMPANY"
		);

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const employeesList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((employee, i) => (
					<GroupMember
						ref={page.length === i + 1 ? viewRef : null}
						key={employee.uuid}
						redirect={true}
						data={employee}
						// customStyle={styles.employee_item} //! to be removed?
						rightSideAction={
							<Popover
								ref={popoverRef}
								render={
									<div className="popover_container">
										<button
											className="popover_item"
											onClick={() => {
												onClose();
												navigate(
													routes.profile(employee.url)
												);
											}}
										>
											<img src={contactIcon} alt="" />
											<span>View Profile</span>
										</button>

										<button
											className="popover_item"
											onClick={(e) => {
												e.preventDefault();
												handleDirectMessage(employee);
											}}
										>
											<img src={chatIcon} alt="" />
											<span>Message this person</span>
										</button>

										{canEdit && (
											<button
												className="popover_item"
												onClick={(e) => {
													e.preventDefault();
													setSelectedEmployee(
														employee
													);
													setDeleteModal(true);
												}}
											>
												<img src={deleteIcon} alt="" />
												<span>Remove Employee</span>
											</button>
										)}
									</div>
								}
							>
								<button onClick={(e) => e.preventDefault()}>
									<img src={moreIcon} alt="options" />
								</button>
							</Popover>
						}
					/>
				))
			),
		[data]
	);

	const {
		action: { mutate: removeEmployee, isLoading },
	} = useMutate(profileApi.deleteEmployee, () => {
		queryClient.invalidateQueries(
			profileKeys.employeesPaginated(uuid, debouncedQuery)
		); //* invalidating for this modal
		queryClient.invalidateQueries(profileKeys.employees(uuid)); //* invalidating for the profileTab
		setSelectedEmployee(undefined);
		setDeleteModal(false);
		onUpdateCount();
	});

	const handleRemoveEmployee = () => {
		removeEmployee(selectedEmployee.uuid);
	};

	return (
		<>
			<GaawkModal
				show={show && !deleteModal}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={"Employees"}
				closeAlign={"right"}
				children={
					<div className={styles.container}>
						<SearchInput
							onChange={handleSearchInputChange}
							value={searchInput}
							showIcons={true}
							onClearClicked={handleClearSearch}
							border={false}
						/>

						<div className={styles.employees_container}>
							<div className={styles.mr_10}>{employeesList}</div>
							{isFetching && <LoadingSpinner />}
						</div>
					</div>
				}
			/>

			<DeleteItemModal
				show={deleteModal}
				onClose={() => setDeleteModal(false)}
				title="Remove Employee"
				text={`Are you sure you want to remove ${selectedEmployee?.name} from your list of employees? This action can NOT be undone.`}
				onConfirm={handleRemoveEmployee}
				isLoading={isLoading}
			/>
		</>
	);
};

export default EmployeeModal;
