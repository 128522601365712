import { useState } from "react";
import styles from "./PhysicalDetailsEntry.module.css";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
	buildTypes,
	eyeHairColor,
	hairStyle as hairStyleType,
	hairLength as hairLengthType,
} from "../../special-types";
import TextInput from "../../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import CustomSelect from "../../../../../Utils/SubComs/CustomSelect/CustomSelect";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import specialProfileApi from "../../../../../../api/special-profile";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useQueryClient } from "@tanstack/react-query";
import physicalDetailsSchema from "./physical-details-schema";
import useMutate from "hooks/useMutate";
import PromptModal from "components/Utils/Prompt/PromptModal";
import routes from "components/Routing/routing-keys";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";

const PhysicalDetailsEntry = ({ profile }) => {
	const navigate = useNavigate();

	const { state } = useLocation();

	const queryClient = useQueryClient();
	const { userUrl } = useParams();
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const {
		buildType,
		chest,
		eyeColor,
		hairColor,
		hairLength,
		hairStyle,
		height,
		shoe,
		waist,
		weight,
	} = profile.physicalDetails || {};

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(physicalDetailsSchema),
		mode: "onSubmit",
		defaultValues: {
			height: height ? height : "",
			weight: weight ? weight : "",
			waist: waist ? waist : "",
			chest: chest ? chest : "",
			shoe: shoe ? shoe : "",
			build: buildTypes.find((item) => item.value === buildType),
			eyeColor: eyeHairColor.find((item) => item.value === eyeColor),
			hairType: hairStyleType.find((item) => item.value === hairStyle),
			hairColor: eyeHairColor.find((item) => item.value === hairColor),
			hairLength: hairLengthType.find(
				(item) => item.value === hairLength
			),
		},
	});
	console.log("🚀 ~ errors >>", errors);

	const {
		action: { mutate: updateDetails, isLoading },
	} = useMutate(
		specialProfileApi.updatePhysicalDetails,
		() => {
			queryClient.invalidateQueries(specialProfileKeys.detail(userUrl));
			navigate(
				routes.specialProfile(
					userUrl,
					state?.agencyId ? `?agency=${state?.agencyId}` : ""
				)
			);
		},
		undefined,
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		setShouldBlockSave(false);

		const {
			hairLength,
			hairColor,
			eyeColor,
			hairType,
			build,
			height,
			weight,
			waist,
			chest,
			shoe,
		} = data;

		const body = {
			...(hairLength && { hairLength: hairLength.value }),
			...(hairColor && { hairColor: hairColor.value }),
			...(eyeColor && { eyeColor: eyeColor.value }),
			...(hairType && { hairStyle: hairType.value }),
			...(build && { buildType: build.value }),
			...(height > 0 && { height }),
			...(weight > 0 && { weight }),
			...(waist > 0 && { waist }),
			...(chest > 0 && { chest }),
			...(shoe > 0 && { shoe }),
		};

		updateDetails({ uuid: profile.uuid, body });
	};

	return (
		<form
			className={styles.physical_form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<h3>Physical Details</h3>

			<div className={styles.section}>
				{/* <div className={styles.form_input_wrapper}>
					<label>Height (in Centimeters)</label>
					<TextInput
						{...register("height")}
						placeholder="Height"
						error={!!errors.height}
					/>

					{errors?.height?.message && (
						<p className={styles.error_message}>
							{errors?.height?.message}
						</p>
					)}
				</div> */}

				<div className={styles.form_input_wrapper}>
					<InputWrapper
						className={styles.noMargin}
						label="Height (in Centimeters)"
						required={false}
						error={errors?.height}
						component={
							<TextInput
								{...register("height")}
								error={errors.height}
								placeholder="Height"
							/>
						}
					/>
				</div>

				{/* <div className={styles.form_input_wrapper}>
					<label>Weight (in Kilograms)</label>
					<TextInput {...register("weight")} placeholder="Weight" />
				</div> */}

				<div className={styles.form_input_wrapper}>
					<InputWrapper
						className={styles.noMargin}
						label="Weight (in Kilograms)"
						required={false}
						error={errors?.weight}
						component={
							<TextInput
								{...register("weight")}
								error={errors.weight}
								placeholder="Weight"
							/>
						}
					/>
				</div>

				<div className={styles.form_input_wrapper}>
					<label>Build</label>
					<CustomSelect
						isClearable={true}
						options={buildTypes}
						height="35px"
						control={control}
						name={"build"}
					/>
				</div>
				<div className={styles.form_input_wrapper}>
					<label>Eye Color</label>
					<CustomSelect
						isClearable={true}
						options={eyeHairColor}
						height="35px"
						control={control}
						name={"eyeColor"}
					/>
				</div>
			</div>

			<div className={styles.section}>
				<div className={styles.form_input_wrapper}>
					<label>Hair Type</label>
					<CustomSelect
						isClearable={true}
						options={hairStyleType}
						height="35px"
						control={control}
						name={"hairType"}
					/>
				</div>
				<div className={styles.form_input_wrapper}>
					<label>Hair Color</label>
					<CustomSelect
						isClearable={true}
						options={eyeHairColor}
						height="35px"
						control={control}
						name={"hairColor"}
					/>
				</div>
				<div className={styles.form_input_wrapper}>
					<label>Hair Length</label>
					<CustomSelect
						isClearable={true}
						options={hairLengthType}
						height="35px"
						control={control}
						name={"hairLength"}
					/>
				</div>
			</div>

			<div className={styles.section}>
				{/* <div className={styles.form_input_wrapper}>
					<label>Waist (in Centimeters)</label>
					<TextInput {...register("waist")} placeholder="Waist" />
				</div> */}

				<div className={styles.form_input_wrapper}>
					<InputWrapper
						className={styles.noMargin}
						label="Waist (in Centimeters)"
						required={false}
						error={errors?.waist}
						component={
							<TextInput
								{...register("waist")}
								error={errors.waist}
								placeholder="Waist"
							/>
						}
					/>
				</div>

				{/* <div className={styles.form_input_wrapper}>
					<label>Chest (in Centimeters)</label>
					<TextInput {...register("chest")} placeholder="Chest" />
				</div> */}

				<div className={styles.form_input_wrapper}>
					<InputWrapper
						className={styles.noMargin}
						label="Chest (in Centimeters)"
						required={false}
						error={errors?.chest}
						component={
							<TextInput
								{...register("chest")}
								error={errors.chest}
								placeholder="Chest"
							/>
						}
					/>
				</div>

				{/* <div className={styles.form_input_wrapper}>
					<label>Shoe Size (Euro Size)</label>
					<TextInput {...register("shoe")} placeholder="Shoe size" />
				</div> */}

				<div className={styles.form_input_wrapper}>
					<InputWrapper
						className={styles.noMargin}
						label="Shoe Size (Euro Size)"
						required={false}
						error={errors?.shoe}
						component={
							<TextInput
								{...register("shoe")}
								error={errors.shoe}
								placeholder="Shoe Size"
							/>
						}
					/>
				</div>
			</div>

			<div className={styles.button_container}>
				<PrimaryButton
					className={styles.save_btn}
					text={"save"}
					isLoading={isLoading}
				/>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</form>
	);
};

export default PhysicalDetailsEntry;
