import Clamp from "../Clamp/Clamp";

const ReadMore = ({ children, lines = 10, customStyle }) => {
    return (
        <Clamp lines={lines} withToggle customStyle={customStyle}>
            {children}
        </Clamp>
    );
};

export default ReadMore;
