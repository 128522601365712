import client from "./client";

const endpoint = "/service";

const getServices = (
	requestedProfileId,
	page,
	size,
	numberOfProducts = 8,
	parentId = ""
) =>
	client.get(
		endpoint +
			`?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}${
				parentId ? `&parentId=${parentId}` : ""
			}${numberOfProducts ? `&numberOfProducts=${numberOfProducts}` : ""}`
	);

const getServiceByTag = (tag, numberOfProducts = "") =>
	client.get(endpoint + `/tag/${tag}?numberOfProducts=${numberOfProducts}`);

const getServicesWithProducts = (
	requestedProfileId,
	page,
	size,
	numberOfProducts,
	parentId = "",
	level = ""
) =>
	client.get(
		endpoint +
			`/hasProducts?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}${
				level ? `&level=${level}` : ""
			}${
				numberOfProducts ? `&numberOfProducts=${numberOfProducts}` : ""
			}${parentId ? `&parentId=${parentId}` : ""}`
	);

const getTag = (title) => client.get(endpoint + `/tag?title=${title}`);

const getServiceById = (id, numberOfProducts = 12) =>
	client.get(
		endpoint +
			`/${id}${
				numberOfProducts ? `?numberOfProducts=${numberOfProducts}` : ""
			}`
	);

const addService = (formData) => client.post(endpoint, formData);

const updateService = (formData) => client.put(endpoint, formData);

const deleteService = (serviceId) => client.delete(endpoint + `/${serviceId}`);

const getIntro = (requestedProfileId) =>
	client.get(endpoint + `/intro?requestedProfileId=${requestedProfileId}`);

const updateIntro = (formData) => client.post(endpoint + "/intro", formData);

const deleteIntro = () => client.delete(endpoint + "/intro");

const getNearbyServices = (page, size, lat = "", lng = "") =>
	client.get(
		endpoint + `/nearby?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getConnectionServices = (page, size) =>
	client.get(endpoint + `/connection?page=${page}&size=${size}`);

const getSuggestedServices = (
	page,
	size,
	lat = "",
	lng = "",
	q = "",
	hasProducts = false,
	numberOfProducts = 0,
	serviceIds = [],
	excludedServiceIds = []
) =>
	client.get(
		endpoint +
			`/suggested?page=${page}&size=${size}&lat=${lat}&lng=${lng}&q=${q}&hasProducts=${hasProducts}&numberOfProducts=${numberOfProducts}`
	);

const searchServices = (page, size, q = "", requestedProfileId = "") =>
	client.get(
		endpoint +
			`/all?page=${page}&size=${size}&q=${q}&requestedProfileId=${requestedProfileId}`
	);

const getAllServices = (
	page,
	size,
	q = "",
	requestedProfileId = "",
	numberOfProducts = 8
) =>
	client.get(
		endpoint +
			`/all?page=${page}&size=${size}&q=${q}&requestedProfileId=${requestedProfileId}&numberOfProducts=${numberOfProducts}`
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getIntro,
	getServices,
	getServiceByTag,
	getServiceById,
	updateIntro,
	deleteIntro,
	getTag,
	addService,
	updateService,
	deleteService,
	getNearbyServices,
	getConnectionServices,
	searchServices,
	getServicesWithProducts,
	getAllServices,
	getSuggestedServices,
};
