import React from "react";
import BackButton from "../Button/BackButton";
import styles from "./ViewerHeader.module.css";

const ViewerHeader = ({ onClose, leftSide, rightSide }) => {
    return (
        <div className={styles.header_container}>
            <div className={styles.leftSide}>
                <BackButton onBack={onClose} />
                {leftSide}
            </div>
            {rightSide}
        </div>
    );
};

export default ViewerHeader;
