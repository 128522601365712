import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import Album from "../../components/Profile/GalleryTab/Album";
import EditAlbum from "../../components/Profile/GalleryTab/EditAlbum";
import styles from "../Header.module.css";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";

const AlbumView = (props) => {
	const navigate = useNavigate();
	const { pathname, state } = useLocation();
	const { albumId } = useParams();

	const isAlbumEdit = pathname === `/profile/album/${albumId}/edit`;
	// const albumProp = props.location.state;

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} />
					{isAlbumEdit ? "Edit Album" : "Album"}
				</div>
			}
			defaultHeader={false}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={
				isAlbumEdit ? <EditAlbum album={state} /> : <Album />
			}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default AlbumView;
