import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	loadedCover: "",
	loadedCoverPreview: "",
	croppedCover: "",
	caption: "",
	unsavedChanges: false,
};

const slice = createSlice({
	name: "cover",
	initialState,
	reducers: {
		uploadCover: (state, action) => {
			state.loadedCover = action.payload;
		},
		uploadCoverPreview: (state, action) => {
			state.loadedCoverPreview = action.payload;
		},
		cropCover: (state, action) => {
			state.croppedCover = action.payload;
		},
		updateCaptionCover: (state, action) => {
			state.caption = action.payload;
		},
		updateCoverUnsavedChanges: (state, action) => {
			state.unsavedChanges = action.payload;
		},
		resetCover: () => initialState,
	},
});

export const {
	uploadCover,
	uploadCoverPreview,
	cropCover,
	updateCaptionCover,
	updateCoverUnsavedChanges,
	resetCover,
} = slice.actions;
export default slice.reducer;
