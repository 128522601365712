import styles from "./CollapsibleJobSection.module.scss";
import usePaginatedResource from "hooks/usePaginatedResource";
import useCurrentUser from "hooks/useCurrentUser";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import useApi from "hooks/useApi";
import jobApi from "api/job";
import CompanyJobItem from "components/Jobs/JobsComponents/CompanyJobItem/CompanyJobItem";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import CollapsibleContainer from "components/Utils/CollapsibleContainer/CollapsibleContainer";

const itemsPerPage = 20;

const sectionHeaders = {
	long_term: {
		title: "Jobs",
		jobType: "Long_Term",
	},
	freelance: {
		title: "Freelance Jobs",
		jobType: "Freelance",
	},
	casting: {
		title: "Casting Calls",
		jobType: "Casting_Call",
	},
};

const CollapsibleJobSection = ({ activeTab, open, count, projectId }) => {
	const { uuid: companyId } = useCurrentUser();

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getJobsApi = useApi(jobApi.getJobByCompanyId, true, true);

	const fetchJobs = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, companyId, jobType, closed, excludeId, projectId] =
			queryKey;

		const response = await getJobsApi.request(
			companyId,
			pageParam,
			itemsPerPage,
			jobType,
			closed,
			excludeId,
			projectId
		);
		return response.data;
	};
	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			jobsKeys.jobsByCompany(
				companyId,
				sectionHeaders[activeTab].jobType,
				!open,
				undefined,
				projectId
			),
			fetchJobs,
			itemsPerPage
		);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const jobsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((job, i) => (
					<div
						key={job.uuid}
						ref={page.length === i + 1 ? viewRef : null}
					>
						<CompanyJobItem job={job} />
					</div>
				))
			),
		[data]
	);

	return (
		<CollapsibleContainer
			title={`${open ? "Open" : "Closed"} ${
				sectionHeaders[activeTab].title
			} (${count})`}
		>
			<div className={styles.jobs_wrapper}>{jobsList}</div>
			{isFetching && <LoadingSpinner />}
			<NoResults
				text={`No ${open ? "open" : "closed"} ${
					sectionHeaders[activeTab].title
				} to show here.`}
				visible={count === 0}
			/>
		</CollapsibleContainer>
	);
};

export default CollapsibleJobSection;
