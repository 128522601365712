import { useState } from "react";
import styles from "./JobsBanner.module.scss";
// import gaawkJobsLogo from "images/gaawk-jobs.svg";
// import searchIcon from "images/magnifyingGrey.svg";
// import crossIcon from "images/cross-grey-14-x-14@3x.png";
// import { locationFormatter } from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
// import PrimaryButton from "../../Utils/Button/PrimaryButton";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
// import InfiniteSearchInput from "../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
// import useFetchLocation from "../../../hooks/useFetchLocation";
import { useNavigate } from "react-router-dom";
import ProfileSwitch from "components/Utils/SubComs/BurgerMenu/ProfileSwitch";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import useUserLocation from "hooks/useUserLocation";
import CityModal from "components/Boards/BoardsComponents/CityModal";
import useJobInitialFilters from "./hooks/useJobInitialFilters";

// const itemsPerPage = 20;

const JobsBanner = ({ onResizeWindow }) => {
	// const initialState = {
	// 	type: ["All"],
	// 	postedDate: ["Any Time"],
	// 	jobType: [],
	// 	workLocation: [],
	// 	freelanceWorkLocation: [],
	// 	compensation: "",
	// 	currency: null,
	// 	frequency: null,
	// 	positionStatus: false,
	// 	calendarAvailability: false,
	// 	startDate: null,
	// 	endDate: null,
	// 	experienceLevel: "",
	// 	freelanceExperienceLevel: "",
	// 	gender: [],
	// 	minAge: "",
	// 	maxAge: "",
	// 	castingDate: null,
	// 	ethnicity: null,
	// 	languages: [],
	// 	skills: [],
	// 	location: null,
	// };

	const initialState = useJobInitialFilters();
	// console.log("🚀 ~ initialState >>", initialState);

	// const searchCitiesApi = useApi(countryApi.searchCities);

	const navigate = useNavigate();

	const [searchInput, setSearchInput] = useState("");
	const [searchProps, setSearchProps] = useState(initialState);
	// console.log("🚀 ~ searchProps >>", searchProps);
	// console.log("===========");
	const [postJobModal, setPostJobModal] = useState(false);

	const onSearch = () => {
		const body = {
			locationId: searchProps.location.value,
			...(searchInput && { q: searchInput }),
		};

		navigate("/jobs/results", {
			state: { body, searchProps },
		});
	};

	const handleKeyUp = (e) => {
		const key = e.key || e.keyCode;
		if (key === "Enter" || key === 13) {
			onSearch();
		}
	};

	const handleLocationChange = (city) => {
		handleSelectedCity(city);

		setSearchProps((prevState) => ({
			...prevState,
			location: {
				label: `${city.name}, ${city.countryDTO.name}`,
				value: `${city.id}`,
			},
		}));
	};

	const [showLocationModal, setShowLocationModal] = useState(false);
	const { userCoordinates, handleSelectedCity } = useUserLocation();

	// const fetchLocation = useFetchLocation(true, itemsPerPage);

	return (
		<>
			<div
				ref={(node) => onResizeWindow(node?.clientHeight)}
				className={styles.container}
			>
				<div className={styles.inner_wrapper}>
					<h1>FIND YOUR DREAM JOB</h1>

					<SearchInput
						onKeyUp={handleKeyUp}
						onChange={(e) => setSearchInput(e.target.value)}
						value={searchInput}
						placeholder={"Search by job title"}
						showIcons={true}
						onClearClicked={() => setSearchInput("")}
					/>

					<div className={styles.advanced_container}>
						<button onClick={() => navigate("/jobs/advanced")}>
							Advanced Search
						</button>

						<button
							className={styles.location_button}
							onClick={() => setShowLocationModal(true)}
						>
							{userCoordinates.name}
							<img
								src={userCoordinates.countryDTO.flagUrl}
								alt=""
							/>
						</button>
					</div>

					<div className={styles.find_section}>
						<p>
							<span onClick={() => navigate("/jobs/talents")}>
								FIND TALENT
							</span>
						</p>
						<p>
							<span onClick={() => setPostJobModal(true)}>
								POST A JOB
							</span>
						</p>
						<p>
							<span onClick={() => navigate("/jobs/agency")}>
								FIND AN AGENCY
							</span>
						</p>
					</div>
				</div>
			</div>

			{/* <div className={styles.banner}>
				<div className={styles.search_wrapper}>
					<div className={styles.header}>
						<img src={gaawkJobsLogo} alt="Gaawk Jobs" />
						<span onClick={() => navigate("/jobs/advanced")}>
							Advanced Search
						</span>
					</div>
					<div className={styles.input_wrapper}>
						<img
							src={searchIcon}
							className={styles.search_icon}
							alt="search"
						/>
						<input
							onChange={(e) => setSearchInput(e.target.value)}
							value={searchInput}
							type="text"
							placeholder={"Search by job title"}
						/>
						<div className={styles.separator}></div>
						<button
							type="button"
							onClick={() => setSearchInput("")}
						>
							<img
								src={crossIcon}
								className={styles.close_icon}
								alt="close"
							/>
						</button>
					</div>
					<div className={styles.location_input}>
						<InfiniteSearchInput
							queryName={"cities"}
							queryFn={fetchLocation}
							itemsPerPage={itemsPerPage}
							formatter={locationFormatter}
							placeholder={"Select Location..."}
							onChange={(e) =>
								setSearchProps((prevState) => ({
									...prevState,
									location: e,
								}))
							}
							value={searchProps.location}
						/>

						<PrimaryButton
							onClick={onSearch}
							type="button"
							text={"Search"}
							className={styles.search_button}
						/>
					</div>
					<div className={styles.find_section}>
						<span onClick={() => navigate("/jobs/talents")}>
							FIND TALENT
						</span>
						<span onClick={() => setPostJobModal(true)}>
							POST A JOB
						</span>
						<span onClick={() => navigate("/jobs/agency")}>
							FIND AN AGENCY
						</span>
					</div>
				</div>
			</div> */}

			<GaawkModal
				show={postJobModal}
				handleClose={() => setPostJobModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Post a Job"}
				closeAlign={"right"}
				children={
					<>
						<div className={styles.job_modal_container}>
							In order to post a Job opening, you need to have
							company profile. If you already have one, please
							switch to your company profile and post a job.
							Otherwise, create a company profile.
						</div>

						<div className={styles.job_modal_container}>
							<ProfileSwitch
								isOpen={postJobModal}
								jobsModal={true}
							/>
						</div>
					</>
				}
			/>

			{showLocationModal && (
				<CityModal
					show={showLocationModal}
					onClose={() => setShowLocationModal(false)}
					onSelectCity={handleLocationChange}
				/>
			)}
		</>
	);
};

export default JobsBanner;
