import { forwardRef, useRef, useState } from "react";
import useAutosizeTextArea from "hooks/useAutosizeTextArea";
import styles from "./HelpTextArea.module.scss";
import { classNames } from "primereact/utils";

const HelpTextArea = forwardRef(
	(
		{
			className,
			inputClassName,
			placeholder,
			value,
			onChange,
			error,
			name,
		},
		ref
	) => {
		const textAreaRef = useRef(null);
		useAutosizeTextArea(textAreaRef.current, value);

		return (
			<div className={`${styles.container} ${className}`}>
				<textarea
					name={name}
					ref={(e) => {
						ref(e);
						textAreaRef.current = e;
					}}
					rows={1}
					value={value}
					onChange={onChange}
					className={classNames(styles.input, inputClassName, {
						[styles.error]: !!error,
					})}
					placeholder={placeholder}
				/>

				{error && (
					<span className={styles.errorMessage}>{error.message}</span>
				)}
			</div>
		);
	}
);

export default HelpTextArea;
