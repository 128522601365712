import { classNames } from "primereact/utils";
import styles from "./HelpUploadFile.module.scss";
import { useRef } from "react";

const HelpUploadFile = ({
	className,
	text,
	onFileUploaded,
	accept = "application/pdf,image/*",
	error,
	file,
}) => {
	// refs
	const inputFile = useRef(null);

	// functions
	const onChangeFile = (event) => {
		event.stopPropagation();
		event.preventDefault();
		const file = event.target.files[0];
		onFileUploaded(file);
	};

	// JSX
	return (
		<>
			<input
				type="file"
				id="file"
				ref={inputFile}
				style={{ display: "none" }}
				accept={accept}
				onChange={onChangeFile}
			/>
			<button
				className={classNames(styles.container, className, {
					[styles.hidden]: file,
				})}
				onClick={() => inputFile.current.click()}
				type="button"
			>
				<img
					alt={"upload"}
					src={require("images/upload.svg").default}
					className={styles.icon}
				/>
				<span className={styles.text}>{text}</span>
			</button>

			{error ? (
				<span className={styles.errorMessage}>{error.message}</span>
			) : (
				<span className={styles.uploadNote}>
					Attach file. File size of your documents should not exceed
					10MB
				</span>
			)}
		</>
	);
};

export default HelpUploadFile;
