import React from "react";
import styles from "./button.module.css";
import { ReactComponent as BackIcon } from "images/back-arrow-white-20-x-17.svg";

const BackButton = ({ onBack, color = "#FFF" }) => {
	return (
		<button className={styles.back_button} type="button" onClick={onBack}>
			<BackIcon fill={color} />
		</button>
	);
};

export default BackButton;
