// import { useResizeListener } from 'primereact/hooks'; //TODO >> use this when implementing prime react for mentions
import { useEffect, useState } from "react";

export default function useWindowSize(target = null) {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: target ? target.innerWidth : window.innerWidth,
        height: target ? target.innerHeight : window.innerHeight,
        scrollHeight: target ? target.scrollHeight : window.scrollHeight,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: target ? target.innerWidth : window.innerWidth,
                height: target ? target.innerHeight : window.innerHeight,
                scrollHeight: target
                    ? target.scrollHeight
                    : window.scrollHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, [target]); // Empty array ensures that effect is only run on mount

    return windowSize;
}
