import { useQueryClient } from "@tanstack/react-query";
import projectApi from "api/project";
import routes from "components/Routing/routing-keys";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import Popover from "components/Utils/Popover/Popover";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { ReactComponent as AddIcon } from "images/add-icon.svg";
import archiveIcon from "images/archive.svg";
import deleteIcon from "images/delete.svg";
import editIcon from "images/edit.svg";
import { ReactComponent as MoreIcon } from "images/three-dots-black-5-x-20.svg";
import { classNames } from "primereact/utils";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { projectKeys } from "queryKeys/projects-key-factory";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ProjectActions.module.scss";

const ProjectActions = ({ project, horizontal = true, btnColor = "#000" }) => {
	const { uuid, active } = project;

	const { pathname } = useLocation();

	const isProjectDetailPage = pathname === routes.project(uuid);

	const navigate = useNavigate();
	const popoverRef = useRef(null);
	const { uuid: profileId } = useCurrentUser();

	const queryClient = useQueryClient();

	const handleAddProjectJob = (jobType) => {
		navigate(routes.jobCreate, {
			state: { jobType, project },
		});
	};

	// * toggle project status =========

	const {
		action: { mutate: toggleStatus },
	} = useMutate(projectApi.updateProjectStatus, () => {
		if (isProjectDetailPage) {
			queryClient.invalidateQueries(projectKeys.detail(uuid));
		} else {
			//TODO >> improvement change the projectKeys to invalidate 1 query for both statuses true and false
			queryClient.invalidateQueries(projectKeys.lists(profileId, true));
			queryClient.invalidateQueries(projectKeys.lists(profileId, false));
			queryClient.invalidateQueries(jobsKeys.landing());
		}
	});

	// * Delete project ================

	const [deleteProjectModal, setDeleteProjectModal] = useState(false);

	const {
		action: { mutate: deleteProject },
	} = useMutate(projectApi.deleteProject, () => {
		setDeleteProjectModal(false);

		if (isProjectDetailPage) {
			navigate(-1);
		} else {
			queryClient.invalidateQueries(projectKeys.lists(profileId, active));
			queryClient.invalidateQueries(jobsKeys.landing());
		}
	});

	return (
		<>
			<Popover
				ref={popoverRef}
				render={
					<div className="popover_container">
						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								navigate(routes.projectEdit(uuid));
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={editIcon} alt="edit" />
							</span>
							<span>Edit Project</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								toggleStatus({
									uuid,
									status: !active,
								});
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img
									src={archiveIcon}
									alt={`mark as ${
										active ? "Past" : "Active"
									}`}
								/>
							</span>
							<span>
								Mark Project as {active ? `"Past"` : `"Active"`}
							</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								handleAddProjectJob("Long_Term");
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<AddIcon width={14} height={14} fill={"#000"} />
							</span>
							<span>Add Job to Project</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								handleAddProjectJob("Casting_Call");
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<AddIcon width={14} height={14} fill={"#000"} />
							</span>
							<span>Add Casting Call to Project</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								handleAddProjectJob("Freelance");
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<AddIcon width={14} height={14} fill={"#000"} />
							</span>
							<span>Add Freelance Job to Project</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								setDeleteProjectModal(true);
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={deleteIcon} alt="delete" />
							</span>
							<span>Delete Project</span>
						</button>
					</div>
				}
			>
				<button
					onClick={(e) => e.stopPropagation()}
					className={classNames(styles.option_btn, {
						[styles.vertical]: !horizontal,
					})}
				>
					<MoreIcon fill={btnColor} />
				</button>
			</Popover>

			<WarningModal
				show={deleteProjectModal}
				headerText="Are you sure you want to delete this project?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={(e) => {
					e.stopPropagation();
					setDeleteProjectModal(false);
				}}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={(e) => {
					e.stopPropagation();
					deleteProject(uuid);
				}}
			/>
		</>
	);
};

export default ProjectActions;
