import styles from "./MessageInfoUser.module.css";
import ProfilePic from "../Home/ProfilePic";
import CustomCheckbox from "../Utils/SubComs/Inputs/CustomCheckbox/CustomCheckbox";
import { format } from "date-fns";

const MessageInfoUser = ({
	thumbnail,
	id,
	name,
	type,
	time = 0,
	canSelect = false,
	onSelect,
	isSelected,
}) => {
	return (
		<div
			className={`${styles.user_item} ${
				canSelect ? styles.canSelect : undefined
			}`}
			onClick={() => onSelect(id)}
		>
			{canSelect && (
				<CustomCheckbox
					isCircle={true}
					customBackgroundColor={"gaawk"}
					checked={isSelected}
					readOnly={true}
				/>
			)}

			<ProfilePic
				thumbnail={thumbnail}
				w={24}
				h={24}
				type={type}
				createMode={true}
				enableName={false}
				name={name}
				letterSize={"12px"}
			/>
			<div className={styles.user_details}>
				<div className={styles.user_name}>{name}</div>
				{time !== 0 && (
					<div className={styles.messageInfo_status_time}>
						{format(new Date(time), "do MMMM yyyy, h:mm aaa")}
					</div>
				)}
			</div>
		</div>
	);
};

export default MessageInfoUser;
