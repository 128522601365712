import useApi from "./useApi";
import servicesApi from "../api/services";
import { useQuery } from "@tanstack/react-query";
import { servicesKeys } from "queryKeys/services-key-factory";

export const useGetServiceById = (
	serviceId,
	numberOfProducts = 12,
	enabled = true
) => {
	const getServiceByIdApi = useApi(servicesApi.getServiceById, true, true);

	const fetchService = async ({ queryKey }) => {
		const [, , serviceUuid] = queryKey;
		const response = await getServiceByIdApi.request(
			serviceUuid,
			numberOfProducts
		); //second argument for number of products
		return response.data;
	};

	const serviceQuery = useQuery({
		queryKey: servicesKeys.detail(serviceId),
		queryFn: fetchService,
		refetchOnWindowFocus: false,
		enabled: !!serviceId || enabled,
	});

	return serviceQuery;
};
