import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import { deleteSite } from "../../../../../store/slices/user";
import styles from "./Email.module.css";
import EmailItem from "./EmailItem";
import { deleteCompanySite } from "../../../../../store/slices/company";
import CtaButton from "components/Utils/Button/CtaButton";

const Email = ({ editCompany }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { sites, uuid: companyId } = useSelector((state) => {
		if (editCompany) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const [warningDeleteModal, setWarningDeleteModal] = useState(false);
	const [siteId, setSiteId] = useState("");

	const handleSiteDelete = (siteId) => {
		setWarningDeleteModal(true);
		setSiteId(siteId);
	};

	const handleConfirmDelete = () => {
		dispatch(
			editCompany
				? deleteCompanySite(siteId, companyId)
				: deleteSite(siteId)
		);
		setWarningDeleteModal(false);
	};

	const sitesList = sites.map((site) => (
		<EmailItem
			key={site.uuid}
			item={site}
			onEditClicked={() =>
				navigate("/profile/email/edit", {
					state: site,
				})
			}
			onDelete={() => handleSiteDelete(site.uuid)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW WEB ADDRESS"
					onClick={() => navigate("/profile/email/add")}
				/>
			</div>

			{sites.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Email / Website</h4>
					<div className={styles.contact_section}>{sitesList}</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Email;
