import { getVideoFrame } from "components/Utils/General";
import { useCallback, useEffect, useState } from "react";

const useGetFirstFrame = (fileOrUrl, name, condition) => {
	const [videoThumb, setVideoThumb] = useState();

	const getVideoThumb = useCallback(async () => {
		const firstFrame = await getVideoFrame(fileOrUrl, name);
		setVideoThumb(firstFrame);
		return firstFrame;
	}, [fileOrUrl, name]);

	useEffect(() => {
		if (condition) {
			getVideoThumb();
		}
	}, [condition, getVideoThumb]);

	return videoThumb;
};

export default useGetFirstFrame;
