import {
    GoogleMap,
    useJsApiLoader,
    Autocomplete,
    Marker,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import { useEffect, useState, useRef } from "react";
import styles from "./MapItem.module.css";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import LoadingSpinner from "../../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import {
    getCity,
    getCountry,
    // getCountryCode,
    getArea,
    getStreet,
    getBuilding,
    getState,
    getZipCode,
} from "./map-helpers";
import { toast } from "react-toastify";
import useBrowserLocation from "../../../../../hooks/useBrowserLocation";
import { dubaiDefaultLocation } from "../../../../Utils/General";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_KEY);

const MapItem = ({ onLocation, locationData }) => {
    const didMountRef = useRef(false);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_PLACES_KEY}`,
        nonce: "map",
        libraries: ["places"],
    });

    const [loadingGPS, setLoadingGPS] = useState(!locationData ? true : false);
    const [location, setLocation] = useState();

    const [markerPosition, setMarkerPosition] = useState();
    const [searchResult, setSearchResult] = useState("");

    const onLoad = (autocomplete) => {
        setSearchResult(autocomplete);
    };

    const onPlaceChanged = () => {
        if (searchResult != null) {
            const {
                geometry: { location },
                formatted_address,
                address_components,
            } = searchResult.getPlace();

            handleLocation(
                location.lat(),
                location.lng(),
                formatted_address,
                address_components
            );
        } else {
            alert("Please enter text");
        }
    };

    const handleLocation = (lat, lng, formattedAddress, addressComponents) => {
        setMarkerPosition({ lat, lng });

        const address = formattedAddress,
            addressArray = addressComponents,
            city = getCity(addressArray),
            country = getCountry(addressArray),
            // countryCode = getCountryCode(addressArray),
            area = getArea(addressArray),
            street = getStreet(addressArray),
            building = getBuilding(addressArray),
            state = getState(addressArray),
            zipCode = getZipCode(addressArray);

        setLocation({
            address: address ? address : "",
            area: area ? area : "",
            street: street ? street : "",
            building: building ? building : "",
            city: city ? city : "",
            country: country ? country : "",
            // countryCode: countryCode ? countryCode : "",
            state: state ? state : "",
            zipCode: zipCode ? zipCode : "",
            lat,
            lng,
        });
    };

    const geocodeAddress = (lat, lng) => {
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                handleLocation(
                    lat,
                    lng,
                    response.results[0].formatted_address,
                    response.results[0].address_components
                );
            },
            (error) => {
                toast.error(error);
            }
        );
    };

    const grantedCallback = (position) => {
        geocodeAddress(position.coords.latitude, position.coords.longitude);
        setLoadingGPS(false);
    };

    const deniedCallback = (error) => {
        geocodeAddress(dubaiDefaultLocation.lat, dubaiDefaultLocation.lng);
        setLoadingGPS(false);
    };

    const getBowserLocation = useBrowserLocation(
        grantedCallback,
        deniedCallback
    );

    // console.log("THE LOCATION DATA >>", locationData);
    // console.log("THE LOCATION >>", location);

    useEffect(() => {
        if (locationData && locationData.lat !== 0 && locationData.lng !== 0) {
            setLocation(locationData);
            setMarkerPosition({
                lat: locationData.lat,
                lng: locationData.lng,
            });
        } else {
            getBowserLocation();
        }
    }, [locationData]);

    useEffect(() => {
        if (didMountRef.current) onLocation(location);

        didMountRef.current = true;
    }, [location]);

    const renderMap = () => {
        return (
            <>
                <Autocomplete
                    onPlaceChanged={onPlaceChanged}
                    onLoad={onLoad}
                    className={styles.autocomplete_input}
                >
                    <TextInput placeholder="Search" />
                </Autocomplete>

                <GoogleMap
                    mapContainerStyle={{
                        height: "400px",
                        width: "100%",
                    }}
                    zoom={12}
                    defaultCenter={{
                        lat: dubaiDefaultLocation.lat,
                        lng: dubaiDefaultLocation.lng,
                    }}
                    center={markerPosition}
                >
                    <Marker
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={(e) =>
                            geocodeAddress(e.latLng.lat(), e.latLng.lng())
                        }
                    />
                </GoogleMap>
            </>
        );
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    if (loadingGPS) return <LoadingSpinner />;

    if (isLoaded && !loadingGPS) return renderMap();
};

export default MapItem;
