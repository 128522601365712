import useApi from "hooks/useApi";
import productApi from "api/product";
import { useInfiniteQuery } from "@tanstack/react-query";
import { productsKeys } from "queryKeys/products-key-factory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export const useGetProductMedia = (productId, enabled, itemsPerPage = 10) => {
	const getProductMediaApi = useApi(productApi.getProductMedia, true, true);

	const fetchMedia = async ({ pageParam = 0, queryKey }) => {
		const [, , , productId] = queryKey;
		const response = await getProductMediaApi.request(
			pageParam,
			itemsPerPage,
			productId
		);
		return response.data;
	};

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
		queryKey: productsKeys.productMedia(productId),
		queryFn: fetchMedia,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		staleTime: Infinity,
		enabled,
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	// const {
	// 	data: productMedia,
	// 	isFetched: isMediaFetched,
	// 	isFetching: isProductMediaFetching,
	// 	isLoading: isProductMediaLoading,

	// } = useQuery({
	// 	queryKey: productsKeys.productMedia(productId),
	// 	queryFn: fetchMedia,
	// 	meta: {
	// 		errorMessage: "Failed to get product media",
	// 	},
	// 	enabled: enabled,
	// });

	return {
		data,
		isFetching,
		fetchNextPage,
		viewRef,
	};
};
