import { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "./PeopleFilter.module.scss";
import { companiesInitialFilters } from "../initialFilters";
import searchApi from "../../../api/search";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import {
	defaultFormatter,
	specialtyFormatter,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import ConnectionFilter from "./ConnectionFilter";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useSearchFilters from "../../../hooks/useSearchFilters";
import Checkbox from "components/Utils/SubComs/Inputs/Checkbox/Checkbox";
import usePaginatedSearchFilters from "hooks/usePaginatedSearchFilters";
import useFilterAndSort from "./useFilterAndSort";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

const CompaniesFilter = forwardRef(
	(
		{
			inputValue,
			onChange,
			onResetFilters,
			selectedFilters: selectedDynamicFilters,
			userCoordinates,
		},
		ref
	) => {
		const {
			initialFilters,
			data,
			isFetching,
			hasFilters,
			selectedFilters,
			setSelectedFilters,
		} = useSearchFilters(
			companiesInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"companiesFilters",
			inputValue,
			searchApi.companiesFilters,
			selectedDynamicFilters,
			onChange
		);

		const {
			hasNextPage: hasNextPageIndustries,
			fetchNextPage: fetchNextPageIndustries,
			isFetched: isFetchedIndustries,
			refetch: refetchIndustries,
			data: dynamicIndustries,
		} = usePaginatedSearchFilters(
			companiesInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"companiesIndustries",
			inputValue,
			searchApi.companiesIndustries,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			hasNextPage: hasNextPageSpecialties,
			fetchNextPage: fetchNextPageSpecialties,
			isFetched: isFetchedSpecialties,
			refetch: refetchSpecialties,
			data: dynamicSpecialties,
		} = usePaginatedSearchFilters(
			companiesInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"companiesSpecialties",
			inputValue,
			searchApi.companiesSpecialties,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			industries: industriesList = [],
			specialties: specialtiesList = [],
		} = data || {};

		const {
			industryIds = [],
			specialties = [],
			agency,
			inMyCircle = false,
			inTheirCircle = false,
		} = selectedFilters || {};

		const filteredIndustries = useFilterAndSort(
			industryIds,
			dynamicIndustries,
			industriesList,
			isFetchedIndustries
		);

		const filteredSpecialties = useFilterAndSort(
			specialties,
			dynamicSpecialties,
			specialtiesList,
			isFetchedSpecialties,
			"value",
			false,
			true
		);

		const industriesRef = useRef();
		const specialtiesRef = useRef();

		const handleClearFields = () => {
			if (hasFilters) {
				setSelectedFilters(initialFilters);
				// industriesRef.current?.clear();
				// specialtiesRef.current?.clear();
				onResetFilters(initialFilters);
			}
		};

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "industryIds":
						industriesRef.current.removeItem(tag);
						break;
					case "specialties":
						specialtiesRef.current.removeItem(tag);
						break;
					default:
						break;
				}
			},
		}));

		if (isFetching)
			return <LoadingSpinner customStyle={styles.loading_spinner} />;

		return (
			<div className={styles.container}>
				{industriesList?.length > 0 && (
					<div className={styles.field_wrapper}>
						<MultiCheckbox
							ref={industriesRef}
							title={"Industries"}
							options={filteredIndustries.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(industries) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									industryIds: industries,
								}));
							}}
							selected={industryIds}
							perRow="2, 150px"
							// customStyle={styles.multicheckbox_container}
						/>

						{((!isFetchedIndustries &&
							industriesList.length === 4) ||
							(isFetchedIndustries && hasNextPageIndustries)) && (
							<SeeMore
								onClick={
									!isFetchedIndustries
										? refetchIndustries
										: fetchNextPageIndustries
								}
								className={styles.margin_top}
							/>
						)}
					</div>
				)}

				{specialtiesList?.length > 0 && (
					<div className={styles.field_wrapper}>
						<MultiCheckbox
							ref={specialtiesRef}
							title={"Specialties"}
							options={filteredSpecialties.map((item) =>
								specialtyFormatter(item)
							)}
							onSelect={(specialties) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									specialties: specialties,
								}));
							}}
							selected={specialties}
							perRow="2, 150px"
							// customStyle={styles.multicheckbox_container}
						/>

						{((!isFetchedSpecialties &&
							specialtiesList.length === 4) ||
							(isFetchedSpecialties &&
								hasNextPageSpecialties)) && (
							<SeeMore
								onClick={
									!isFetchedSpecialties
										? refetchSpecialties
										: fetchNextPageSpecialties
								}
								className={styles.margin_top}
							/>
						)}
					</div>
				)}

				<div className={styles.agency_wrapper}>
					<h3>Talent Agencies</h3>
					<div className={styles.agency_checkbox}>
						<Checkbox
							className={styles.agency_checkbox}
							w="14px"
							h="14px"
							checked={agency}
							onChange={() =>
								setSelectedFilters((prevState) => ({
									...prevState,
									agency: !prevState.agency,
								}))
							}
							name={"agency"}
							customBackgroundColor={"gaawk"}
						/>
						<span
							onClick={() =>
								setSelectedFilters((prevState) => ({
									...prevState,
									agency: !prevState.agency,
								}))
							}
						>
							Show only Talent Agencies
						</span>
					</div>
				</div>

				<ConnectionFilter
					inMyCircle={inMyCircle}
					inTheirCircle={inTheirCircle}
					onMyWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inMyCircle: value,
						}))
					}
					onTheirWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inTheirCircle: value,
						}))
					}
				/>

				<div className={styles.button_container}>
					<PrimaryButton
						type="button"
						text={"clear filters"}
						className={`${styles.btn} ${styles.clear}`}
						onClick={handleClearFields}
					/>
				</div>
			</div>
		);
	}
);

export default CompaniesFilter;
