import Joi from "joi";

export const callbackSchema = Joi.object({
	fullName: Joi.string().required().max(50).label("Full Name").messages({
		"string.empty": "Full name can't be empty",
		"string.max": "Full name can't be more than 50 characters",
	}),

	position: Joi.string().required().label("Position").max(100).messages({
		"string.empty": "Position can't be empty",
		"string.max": "Position can't be more than 100 characters",
	}),

	contact: Joi.string()
		.required()
		.regex(/^\d+$/)
		.messages({
			"string.empty": "Mobile number can't be empty",
			"string.pattern.base":
				"Mobile number needs to contain numbers only",
		})
		.label("Mobile number"),

	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.label("Email")
		.messages({
			"string.empty": "Email can't be empty",
			"string.email": "Email should be valid",
		}),

	description: Joi.string()
		.allow("")
		.label("Description")
		.max(5000)
		.messages({
			"string.max": "Description can't be more than 5000 characters",
		}),
});
