import styles from "./ServicesPage.module.scss";
import InfiniteServices from "../../Profile/ServicesTab/ServicesComponent/InfiniteServices";
import useApi from "../../../hooks/useApi";
import servicesApi from "../../../api/services";
import useUserLocation from "../../../hooks/useUserLocation";

const itemsPerPage = 24;

const ServicesNearby = () => {
	const { userCoordinates } = useUserLocation();

	const nearbyServicesApi = useApi(servicesApi.getNearbyServices, true, true);

	const fetchNearbyServices = async ({ pageParam = 0, signal }) => {
		const response = await nearbyServicesApi.request(
			pageParam,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	return (
		<div className={styles.container}>
			<InfiniteServices
				title={"Services in your area"}
				queryKey={["infiniteNearbyServices", userCoordinates]}
				queryFn={fetchNearbyServices}
				// errorMessage={"Failed to get nearby services"}
				itemsPerPage={itemsPerPage}
			/>
		</div>
	);
};

export default ServicesNearby;
