import React, { useMemo } from "react";
import useApi from "hooks/useApi";
import productApi from "api/product";
import Tag from "components/Utils/SubComs/Tags/Tag";

export const useMaterials = (colorsPerPage, materialsWatcher, setValue) => {
	const searchMaterialsApi = useApi(productApi.searchProductMaterials, true);

	const searchMaterials = async ({ pageParam = 0, queryKey, signal }) => {
		const [, debouncedSearch] = queryKey;
		const response = await searchMaterialsApi.request(
			pageParam,
			colorsPerPage,
			debouncedSearch
		);
		return response.data;
	};

	const handleDeleteMaterial = (index) => {
		const updatedArray = materialsWatcher.filter(
			(material, i) => i !== index
		);
		setValue("materials", updatedArray);
	};

	const materialsList = useMemo(
		() =>
			materialsWatcher?.map((material, index) => (
				<Tag
					key={material.value}
					itemName={material.label}
					onRemove={() => handleDeleteMaterial(index)}
				/>
			)),
		[materialsWatcher]
	);

	return { materialsList, searchMaterials };
};
