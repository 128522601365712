import { useSearchParams } from "react-router-dom";

const useGetQueryParam = (paramName) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const paramValue = searchParams.get(paramName);

	const setParamValue = (newValue) => {
		setSearchParams((prevParams) => {
			const newParams = new URLSearchParams(prevParams);
			newParams.set(paramName, newValue);
			return newParams;
		});
	};

	return { paramValue, setParamValue };
};

export default useGetQueryParam;
