import MultiCheckbox from "components/Utils/MultiCheckbox/MultiCheckbox";
import styles from "../PeopleFilter.module.scss";
import {
	employementTypes,
	workLocation as workLocationTypes,
} from "components/Utils/General";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";

const LongTermFilters = ({
	onEmployementSelect,
	employement,
	onWorkLocation,
	workLocation,
	onExpLvl,
	expLvl,
}) => {
	const excludedEmployement = [
		"SELF_EMPLOYED",
		"FREELANCE",
		"APPRENTICESHIP",
		"CASTING_CALLS",
	];

	const filteredEmployement = employementTypes.filter(
		(item) => !excludedEmployement.includes(item.value)
	);

	return (
		<div className={styles.separator_container}>
			<div className={styles.container}>
				<MultiCheckbox
					title={"Job Type"}
					options={filteredEmployement}
					onSelect={onEmployementSelect}
					selected={employement}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/>

				<MultiCheckbox
					title={"Working Location"}
					type="radio"
					options={workLocationTypes}
					onSelect={onWorkLocation}
					selected={workLocation}
					perRow="3, 100px"
					customStyle={styles.multicheckbox_container}
				/>

				<InputWrapper
					label="Experience Level"
					component={
						<TextInput
							value={expLvl}
							placeholder="Enter number of years"
							onChange={onExpLvl}
						/>
					}
				/>
			</div>
		</div>
	);
};

export default LongTermFilters;
