import useApi from "hooks/useApi";
import searchApi from "api/search";
import { useQuery } from "@tanstack/react-query";
import { productsKeys } from "queryKeys/products-key-factory";

const itemsPerPage = 14;

export const useGetUncategoirzedProducts = (profileId, enabled = true) => {
	const getProductsApi = useApi(searchApi.searchProducts, true, true);

	const fetchProducts = async ({ pageParam = 0, queryKey }) => {
		const [, , , profileId] = queryKey;
		const response = await getProductsApi.request(
			pageParam,
			itemsPerPage,
			{ onlyUncategorizedProducts: true, ownerIds: [profileId] },
			"NEWEST"
		);
		return response.data;
	};

	const {
		data: uncategorizedProducts,
		isLoading: isLoadingUncategorized,
		isFetching: isFetchingUncategorized,
		isInitialLoading: isInitialLoadingUncategorized,
	} = useQuery({
		queryKey: productsKeys.unCategorized(profileId),
		queryFn: fetchProducts,
		refetchOnWindowFocus: false,
		enabled: enabled,
	});

	return {
		uncategorizedProducts,
		isLoadingUncategorized,
		isFetchingUncategorized,
		isInitialLoadingUncategorized,
	};
};
