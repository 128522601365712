import {createSlice} from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Country",
    initialState: {
        list: [],
    },
    reducers: {
        "listUpdated": (countries, action) => {
            countries.list = action.payload;
        },
    },
});

export const {listUpdated} = slice.actions;
export default slice.reducer;

/**
 * get countries
 */

export const getCountries = () => apiActions.apiCallBegan({
    url: "country",
    method: "GET",
    onSuccess: listUpdated.type,
});