import { useState, Fragment, useEffect } from "react";
import isCssEllipsisApplied from "./isCssEllipsisApplied";
import TruncatedElement from "./TruncatedElement";
import styles from "./Clamp.module.css";

const defaultShowMoreElement = ({ toggle }) => (
	<button type="button" className={styles.toggle_button} onClick={toggle}>
		Show More
	</button>
);

const defaultShowLessElement = ({ toggle }) => (
	<button type="button" className={styles.toggle_button} onClick={toggle}>
		Show Less
	</button>
);

const Clamp = ({
	children,
	lines = 2,
	maxLines = undefined,
	withTooltip = false,
	withToggle = false,
	showMoreElement = defaultShowMoreElement,
	showLessElement = defaultShowLessElement,
	onShowMore = () => {},
	customStyle,
}) => {
	const [sLines, setLines] = useState(lines);
	const [isExpanded, setIsExpanded] = useState(false);
	const [showMore, setShowMore] = useState(false);

	const handleToggleShowMore = (show) => {
		const newLines = show ? maxLines : lines;

		setShowMore((showMore) => !showMore);
		setIsExpanded((isExpanded) => !isExpanded);
		setLines(newLines);

		onShowMore(show);
	};

	const handleConfigElement = (elem) => {
		if (!elem) return;

		if (isCssEllipsisApplied(elem)) {
			if (withTooltip) {
				const title = elem.textContent;
				elem.setAttribute("title", title);
			}

			if (withToggle && !showMore && !isExpanded) {
				setShowMore(true);
			}
		} else {
			elem.removeAttribute("title");
			setShowMore(false);
		}
	};

	useEffect(() => {
		if (lines) {
			setLines(lines);
		}
	}, [lines]);

	return (
		<Fragment>
			<TruncatedElement
				customStyle={customStyle}
				lines={sLines}
				getRef={handleConfigElement}
			>
				{children}
			</TruncatedElement>

			{showMore &&
				!isExpanded &&
				showMoreElement({
					toggle: (e) => {
						e.preventDefault();
						handleToggleShowMore(true);
					},
				})}

			{isExpanded &&
				showLessElement({
					toggle: (e) => {
						e.preventDefault();
						handleToggleShowMore(false);
					},
				})}
		</Fragment>
	);
};

export default Clamp;
