import React from "react";
import CreateAlbum from "../../components/Profile/GalleryTab/CreateAlbum";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "../Header.module.css";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import { useNavigate } from "react-router-dom";

const CreateAlbumView = () => {
	const navigate = useNavigate();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Create Album
				</div>
			}
			defaultHeader={false}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={<CreateAlbum />}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default CreateAlbumView;
