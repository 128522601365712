import Joi from "joi";

const addressSchema = Joi.object({
    label: Joi.string()
        .required()
        .messages({ "string.empty": "Label can't be empty" })
        .label("Label"),
    city: Joi.object({
        value: Joi.number(),
        label: Joi.string(),
    })
        .required()
        .messages({
            "any.required": "City/Country can't be empty",
            "object.base": "City/Country can't be empty",
        })
        .label("City/Country"),
    building: Joi.string().allow("").label("Building"),
    street: Joi.string().allow("").label("Street"),
    zip: Joi.string().allow("").label("Zip Code"),
    url: Joi.string().allow("").label("Address Url"),
});

export default addressSchema;
