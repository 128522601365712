import Joi from "joi";

const firstStep = Joi.object({
	category: Joi.string().valid("NEW", "SUB").label("Category"),
	subCategory: Joi.when("category", {
		is: "SUB",
		then: Joi.string().required().label("Sub Category").messages({
			"string.empty": "Please choose one of the categories",
		}),
	}),
	level: Joi.number(),
});

const secondStep = Joi.object({
	name: Joi.string().required().max(30).label("Name").messages({
		"string.empty": "Service Name can't be empty",
		"string.max": "Service Name can't be more than 30 characters",
	}),
	worldWide: Joi.boolean(),
	locationIds: Joi.when("worldWide", {
		is: Joi.boolean().valid(true),
		then: Joi.array(),
		otherwise: Joi.array().required().min(1).messages({
			"array.min": "Location can't be empty",
		}),
	}),
	tag: Joi.when("name", {
		is: true,
		then: Joi.string().required().label("Service Tag").messages({
			"string.empty": "Service Tag can't be empty",
		}),
	}),
	description: Joi.string()
		.required()
		.label("Description")
		.messages({ "string.empty": "Description can't be empty" }),
	gaawkLvl1: Joi.object({
		label: Joi.string(),
		value: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Level One Services can't be empty",
			"object.base": "Level One Services can't be empty",
		})
		.label("Level One Services"),
	gaawkLvl2: Joi.object({
		label: Joi.string(),
		value: Joi.string(),
	})
		.allow(null)
		.label("Level Two Services"),
	gaawkLvl3: Joi.object({
		label: Joi.string(),
		value: Joi.string(),
	})
		.allow(null)
		.label("Level Three Services"),
	tagAlbumMedia: Joi.boolean(),
	tagProducts: Joi.boolean(),
});

const thirdStep = Joi.object({
	headerStyle: Joi.string()
		.valid("STACKED", "HORIZONTAL", "TEXT")
		.label("Header Display Style"),
	imageSize: Joi.string()
		.valid("TALL", "STANDARD", "SHORT", "N_A")
		.label("Header Image Size"),
	image: Joi.when("headerStyle", {
		is: "TEXT",
		then: Joi.array().label("Hearder Image"),
		otherwise: Joi.array()
			.min(1)
			.required()
			.label("Hearder Image")
			.messages({
				"any.required": "An image is required",
				"array.min": "An image is required",
			}),
	}),
	sameImageMobile: Joi.boolean(),
	mobileImage: Joi.when("headerStyle", {
		is: "TEXT",
		then: Joi.optional(),
		otherwise: Joi.when("sameImageMobile", {
			is: false,
			then: Joi.array().min(1).required().label("Mobile Image").messages({
				"any.required": "A mobile image is required",
				"array.min": "A mobile image is required",
			}),
		}),
	}),
});

export { firstStep, secondStep, thirdStep };
