import Joi from "joi";
import styles from "./MobileForm.module.scss";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDispatch } from "react-redux";
import useFetchLocation from "hooks/useFetchLocation";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { dialCodeFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import { resetTimer, updateNewMobile } from "store/slices/otp";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Utils/Button/PrimaryButton";

const itemsPerPage = 20;

const MobileForm = ({ onNext }) => {
	const dispatch = useDispatch();

	const schema = Joi.object({
		mobile: Joi.string()
			.pattern(/^[0-9]+$/)
			.required()
			.label("Mobile")
			.messages({
				"string.empty": "Mobile can't be empty",
				"string.pattern.base": "Mobile can only contain numbers",
			}),
		countryCode: Joi.required().messages({
			"any.required": "Dial code is required",
		}),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
	} = useForm({ resolver: joiResolver(schema), mode: "onBlur" });

	const fetchCountries = useFetchLocation(false, itemsPerPage);

	const handleNext = (data) => {
		const { mobile, countryCode } = data;
		dispatch(resetTimer());
		dispatch(updateNewMobile({ countryCode: countryCode.value, mobile }));
		onNext();
	};

	return (
		<form
			className={styles.form_wrapper}
			onSubmit={handleSubmit((data) => handleNext(data))}
		>
			<h3>Enter your mobile number</h3>
			<div className={styles.info}>
				Enter a mobile number which will be linked to your account. This
				number will not be published on your profile unless you choose
				to make it visible.
			</div>

			<div className={styles.input_wrapper}>
				<div className={styles.mobile_container}>
					<InfiniteSearchInput
						queryName={"countries"}
						queryFn={fetchCountries}
						itemsPerPage={itemsPerPage}
						formatter={dialCodeFormatter}
						error={errors?.countryCode}
						control={control}
						name="countryCode"
						customStyle={styles.dialCode_input}
						errorStyle={styles.hide}
					/>

					<TextInput
						{...register("mobile")}
						placeholder="Enter your mobile number"
						error={!!errors.mobile}
					/>
				</div>

				{errors?.countryCode && (
					<p
						className={`${styles.error_message} ${styles.countryCode}`}
					>
						{errors?.countryCode?.message}
					</p>
				)}
				{errors?.mobile && (
					<p className={`${styles.error_message} ${styles.mobile}`}>
						{errors?.mobile?.message}
					</p>
				)}
			</div>

			<PrimaryButton text="next" className={styles.next_btn} />
		</form>
	);
};

export default MobileForm;
