import { useQuery } from "@tanstack/react-query";
import { paymentKeys } from "queryKeys/payment-key-factory";
import useApi from "./useApi";
import paymentApi from "api/payment";

const usePackages = () => {
	const getPackagesApi = useApi(paymentApi.getPackages, true, true);

	const fetchPackages = async () => {
		const response = await getPackagesApi.request();
		return response.data;
	};

	const query = useQuery({
		queryKey: paymentKeys.packages(),
		queryFn: fetchPackages,
	});

	return query;
};

export default usePackages;
