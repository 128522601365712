import React, {
	forwardRef,
	useEffect,
	useRef,
	useState,
	useImperativeHandle,
} from "react";

import styles from "./Replies.module.css";
import { useSelector } from "react-redux";

import { replaceItemInArray } from "../Utils/General";

import CommentInput from "./CommentInput";
import postApi from "../../api/post";
import useApi from "../../hooks/useApi";
import ReplyItem from "./ReplyItem";
import useCompanyId from "../../hooks/useCompanyId";

const repliesSize = 5;

const Replies = forwardRef(
	({ commentId, repliesCount, onUpdateRepliesCount }, ref) => {
		const getRepliesApi = useApi(postApi.getCommentReplies);
		const addReplyApi = useApi(postApi.addReply);
		const deleteReplyApi = useApi(postApi.deleteReply);

		const companyId = useCompanyId();

		const { profileImage, type, url } = useSelector((state) => {
			if (companyId) {
				return state.company.companyInfo;
			} else {
				return state.user.profileInfo;
			}
		});

		useImperativeHandle(ref, () => ({
			replyComment(commentUrl) {
				handleReplyClick(commentUrl);
			},
		}));

		const [replies, setReplies] = useState([]);
		const [hasMore, setHasMore] = useState(false);
		const [reply, setReply] = useState("");

		const inputRef = useRef(null);

		const [page, setPage] = useState(0);

		useEffect(() => {
			loadMore();
		}, []);

		const loadMore = () => {
			getRepliesApi.request(commentId, page, repliesSize);
			setPage(page + 1);
		};

		useEffect(() => {
			if (
				!getRepliesApi.loading &&
				getRepliesApi.responseCode === 200 &&
				getRepliesApi.data
			) {
				if (replies.length + getRepliesApi.data.length < repliesCount)
					setHasMore(true);
				else setHasMore(false);
				if (replies.length === 0) setReplies(getRepliesApi.data);
				else
					setReplies((prevState) => [
						...prevState,
						...getRepliesApi.data,
					]);
			}
		}, [getRepliesApi.data]);

		// console.log("%c THE REPLIES >>", "color: red; font-weight: bolder", replies);

		const handleTextChange = (text) => {
			setReply(text);
		};

		const handleReplyClick = (url) => {
			setReply(`@${url}  `);
			inputRef.current.focus();
		};

		const handleEmojiClick = (_, emojiObject) => {
			setReply((prevState) => prevState + emojiObject.emoji);
		};

		const handleConfirmReply = () => {
			addReplyApi.request({ commentId, reply });
			setReply("");
		};

		useEffect(() => {
			if (
				!addReplyApi.loading &&
				addReplyApi.responseCode === 201 &&
				addReplyApi.data
			) {
				setReplies((prevState) => [addReplyApi.data, ...prevState]);
				onUpdateRepliesCount(commentId, "INCREMENT");
			}
		}, [addReplyApi.data]);

		const handleReplyUpdate = (updatedReply) => {
			setReplies((prevState) =>
				replaceItemInArray(updatedReply, prevState, "uuid")
			);
		};

		// ! ========= REPLY EDIT =========

		const [newText, setNewText] = useState("");

		const handleReplyEdit = (text) => {
			setNewText(text);
		};

		// ! ========= REPLY DELETE =========
		const [replyIdToDelete, setReplyIdToDelete] = useState("");

		const handleDeleteReply = (replyId) => {
			setReplyIdToDelete(replyId);
			deleteReplyApi.request(replyId);
		};

		useEffect(() => {
			if (
				!deleteReplyApi.loading &&
				deleteReplyApi.responseCode === 200
			) {
				setReplies((prevState) =>
					prevState.filter((reply) => reply.uuid !== replyIdToDelete)
				);
				onUpdateRepliesCount(commentId, "DECREMENT");
			}
		}, [deleteReplyApi.loading, deleteReplyApi.responseCode]);

		const replyList =
			replies.length > 0 &&
			replies.map((reply) => {
				return (
					<ReplyItem
						key={reply.uuid}
						profileImg={profileImage?.originalImageURL}
						type={type}
						url={url}
						commentId={commentId}
						reply={reply}
						onReplyUpdate={handleReplyUpdate}
						onTextChange={handleReplyEdit}
						editedText={newText}
						onDelete={handleDeleteReply}
						onReply={handleReplyClick}
					/>
				);
			});

		return (
			<div className={styles.container}>
				<CommentInput
					ref={inputRef}
					profileImg={profileImage?.originalImageURL}
					type={type}
					url={url}
					onTextChange={handleTextChange}
					text={reply}
					onEmojiClick={handleEmojiClick}
					isEditing={false}
					onConfirm={handleConfirmReply}
				/>

				<>{replyList}</>

				{replies.length > 0 && hasMore && (
					<div className={styles.load_more_button}>
						<button onClick={loadMore}>
							{repliesCount - replies.length} more replies
						</button>
					</div>
				)}
			</div>
		);
	}
);

export default Replies;
