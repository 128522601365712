import { classNames } from "primereact/utils";
import styles from "./HelpInput.module.scss";
import { forwardRef } from "react";

const HelpInput = forwardRef(
	(
		{
			className,
			inputClassName,
			placeholder,
			value,
			onChange,
			type = "text",
			name,
			error,
		},
		ref
	) => {
		return (
			<>
				<div className={`${styles.container} ${className}`}>
					<input
						ref={ref}
						name={name}
						type={type}
						className={classNames(styles.input, inputClassName, {
							[styles.error]: !!error,
						})}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
					/>
					{error && (
						<span className={styles.errorMessage}>
							{error.message}
						</span>
					)}
				</div>
			</>
		);
	}
);

export default HelpInput;
