import { Navigate, useSearchParams } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import HomeLeftSidebar from "./Components/HomeLeftSidebar/HomeLeftSidebar";
import Feeds from "./Feeds";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";

const FeedByHashtag = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const hashtagValue = searchParams.get("hashtag");
	const hasParam = searchParams.has("hashtag");

	if (!hasParam || !hashtagValue) return <Navigate to={routes.root} />;

	return (
		<DefaultPage
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={
				<FeedContainer>
					<h4>results for #{hashtagValue}</h4>
					<Feeds hashtagValue={hashtagValue} />
				</FeedContainer>
			}
		/>
	);
};

export default FeedByHashtag;
