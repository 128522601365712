import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	conversationIdToExport: "",
	replyId: "",
};

const slice = createSlice({
	name: "chat",
	initialState,
	reducers: {
		updateReplyId: (state, action) => {
			state.replyId = action.payload;
		},
		updateConversationIdExport: (state, action) => {
			state.conversationIdToExport = action.payload;
		},
		reset: () => initialState,
	},
});

export const { updateReplyId, updateConversationIdExport, reset } =
	slice.actions;
export default slice.reducer;
