import styles from "./All.module.scss";
import Jobs from "../SearchComponents/Jobs";
import PeopleAndCompanies from "../SearchComponents/PeopleAndCompanies";
import Products from "../SearchComponents/Products";
import Posts from "../SearchComponents/Posts";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useApi from "../../../hooks/useApi";
import searchApi from "../../../api/search";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useDebounce from "../../../hooks/useDebounce";
import useMutate from "hooks/useMutate";
import jobApi from "api/job";

const All = ({ searchInput, onViewMore, userCoordinates }) => {
	const debouncedSearch = useDebounce(searchInput);
	const queryClient = useQueryClient();

	const searchAllApi = useApi(searchApi.searchAll, true, true);
	const searchAll = async ({ queryKey }) => {
		const [_, searchInput, locationId] = queryKey;

		const response = await searchAllApi.request(searchInput, locationId);
		return response.data;
	};

	const { data = {}, isLoading } = useQuery({
		queryKey: ["search", debouncedSearch, userCoordinates.id],
		queryFn: searchAll,
		enabled: !!userCoordinates.id,
	});

	const handleInvalidateQuery = () => {
		queryClient.invalidateQueries([
			"search",
			debouncedSearch,
			userCoordinates.id,
		]);
	};

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, handleInvalidateQuery);

	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			{data.users?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<PeopleAndCompanies
							count={data.users?.count}
							data={data.users?.list}
							onClick={() => onViewMore("people")}
						/>
					</div>
				</div>
			)}

			{data.companies?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<PeopleAndCompanies
							count={data.companies?.count}
							data={data.companies?.list}
							isCompany={true}
							onClick={() => onViewMore("companies")}
						/>
					</div>
				</div>
			)}

			{data.products?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<Products
							count={data.products?.count}
							data={data.products?.list}
							onRefresh={handleInvalidateQuery}
							onClick={() => onViewMore("products")}
						/>
					</div>
				</div>
			)}

			{data.jobs?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<Jobs
							count={data.jobs?.count}
							data={data.jobs?.list}
							onClick={() => onViewMore("jobs")}
							onToggleSave={toggleSave}
						/>
					</div>
				</div>
			)}

			{data.posts?.count > 0 && (
				<div className={styles.section}>
					<div
						className={`${styles.sub_section} ${styles.posts_container}`}
					>
						<Posts
							count={data.posts?.count}
							data={data.posts?.list}
							onRefresh={handleInvalidateQuery}
							searchInput={searchInput}
							onClick={() => onViewMore("posts")}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default All;
