import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "./useApi";
import { useSelector } from "react-redux";
import useDebounce from "./useDebounce";

const useGlobalSearch = (
	initialFilters,
	userCoordinates,
	queryName,
	inputValue,
	filterApi,
	orderOrNoOfProducts = undefined,
	size = 20
) => {
	const queryClient = useQueryClient();

	const filtersList = useSelector((state) => state.search.filters);

	const debouncedSearch = useDebounce(inputValue);

	const searchApi = useApi(filterApi, true, true);

	const searchFn = async ({ pageParam = 0, queryKey, signal }) => {
		const [searchInput, filtersList] = queryKey;
		const hasFilters = Object.keys(filtersList).length > 0;

		const response = await searchApi.request(
			pageParam,
			size,
			hasFilters
				? {
						...filtersList,
						...userCoordinates,
						q: searchInput,
						//   latitude: userCoordinates.lat,
						//   longitude: userCoordinates.lng,
				  }
				: {
						...initialFilters,
						...userCoordinates,
						q: searchInput,

						//   latitude: userCoordinates.lat,
						//   longitude: userCoordinates.lng,
				  },
			orderOrNoOfProducts ?? ""
		);
		return response.data;
	};

	const queryKey = [
		debouncedSearch,
		filtersList,
		userCoordinates,
		...queryName,
	];

	const {
		data,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		fetchStatus,
	} = useInfiniteQuery({
		queryKey: queryKey,
		queryFn: searchFn,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.list?.length === size ? pages.length : undefined;
			return nextPage;
		},
		enabled: !!Object.values(userCoordinates)[0],
		// staleTime: 300000, // 5 minutes
	});

	const handleInvalidation = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return {
		data,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		fetchStatus,
		handleInvalidation,
	};
};

export default useGlobalSearch;
