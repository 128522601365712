import React, { useMemo } from "react";
import styles from "./Tabs.module.css";
import useApi from "../../../../hooks/useApi";
import useConnection from "../hooks/useConnection";
import connectionApi from "../../../../api/connection";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupMember from "../../../Chat/GroupMember";
// import msgIcon from "images/message-icon-black.svg";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";
// import ChatContext from "../../../Chat/chat-context";

const Friends = ({ profile, query, count }) => {
	const getFriendsApi = useApi(connectionApi.getFriends);
	const { data: friends, fetchData } = useConnection(
		getFriendsApi,
		query,
		profile.uuid
	);

	// const handleDirectMsg = (contact) => {
	//     history.push({ pathname: "/chat", state: contact });
	// };

	const friendsList = useMemo(
		() =>
			friends.map((item) => (
				// <div className={styles.contact_item} key={item.uuid}>
				<GroupMember
					key={item.uuid}
					data={item}
					redirect={true}
					// rightSideAction={
					//     <img
					//         src={msgIcon}
					//         className={styles.action_icon}
					//         onClick={() => handleDirectMsg(item)}
					//         alt="message"
					//     />
					// }
				/>
				// </div>
			)),
		[friends]
	);

	return (
		<div id="scrollDiv" className={styles.container}>
			<InfiniteScroll
				dataLength={friends.length}
				next={fetchData}
				hasMore={!query && friends.length > 0 && friends.length < count}
				loader={<ContactLoader />}
				scrollableTarget="scrollDiv"
			>
				{getFriendsApi.loading && <ContactLoader />}
				{friendsList.length > 0 &&
					!getFriendsApi.loading &&
					friendsList}
			</InfiniteScroll>
		</div>
	);
};

export default Friends;
