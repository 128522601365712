import {
	// forwardRef,
	// useImperativeHandle,
	useCallback,
	useEffect,
	useState,
	useMemo,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./GalleryFileUploader.module.css";
import SimpleDropZone from "../../Utils/SubComs/CustomDropZone/SimpleDropZone";

import LoadingBar from "../../Utils/SubComs/LoadingBar/LoadingBar";
import useApi from "../../../hooks/useApi";
import galleryApi from "../../../api/gallery";
import GalleryFileItem from "./GalleryFileItem";
import EditMediaModal from "../../Home/EditMediaModal";
import PrimaryButton from "components/Utils/Button/PrimaryButton";

const GalleryFileUploader = () => {
	const navigate = useNavigate();

	const { albumId } = useParams();

	const addAlbumFilesApi = useApi(galleryApi.addAlbumFiles);

	/**
	 * States
	 */
	const [droppedFiles, setDroppedFiles] = useState([]);

	// console.log("%c dropped files >>", "color: fuchsia; font-weight: bolder", droppedFiles);

	const [showProgress, setShowProgress] = useState(false);
	const [progress, setProgress] = useState(0);

	/**
	 * Effects
	 */

	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		let timer;
		if (isMounted) {
			if (addAlbumFilesApi.loading) console.log("uploading..........");
			else {
				if (addAlbumFilesApi.responseCode === 201) {
					if (progress === 100) {
						timer = setTimeout(() => {
							navigate(`/profile/album/${albumId}`, {
								replace: true,
							});
						}, 2000);
					}
				}
			}
		}
		return () => {
			setIsMounted(true);
			clearTimeout(timer);
		};
	}, [addAlbumFilesApi.loading, addAlbumFilesApi.responseCode, progress]);

	/**
	 * Callbacks
	 */
	const onDropFiles = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length > 0) {
				const formattedFiles = [];
				for (let i = 0; i < acceptedFiles.length; i++) {
					formattedFiles.push({
						caption: "",
						multipartFile: acceptedFiles[i],
						type: acceptedFiles[i].type.includes("video")
							? "VIDEO"
							: acceptedFiles[i].type.includes("image")
							? "IMAGE"
							: "PDF",
						taggedIds: [],
						newFile: true,
					});
				}
				setDroppedFiles([...droppedFiles, ...formattedFiles]);
			}
		},
		[droppedFiles]
	);

	// useEffect(() => {
	// 	onDrop(droppedFiles);
	// }, [droppedFiles]);

	/**
	 * functions
	 */

	const removeFile = (index) => () => {
		setDroppedFiles(droppedFiles.filter((_, i) => i !== index));
	};

	// useImperativeHandle(ref, () => ({
	// 	uploadFiles,
	// }));

	const handleUploadFiles = () => {
		setShowProgress(true);
		if (droppedFiles.length > 0) {
			const itemsProgress = {};

			droppedFiles.forEach((file) => {
				const formData = new FormData();

				formData.append("albumId", albumId);
				formData.append(
					"multipartFile",
					file.multipartFile,
					file.multipartFile instanceof Blob
						? file.multipartFile.fileName
						: undefined
				);
				formData.append("caption", file.caption);
				if (file.date) formData.append("date", file.date);
				if (file.location) {
					formData.append(
						"location.latitude",
						file.location.latitude
					);
					formData.append(
						"location.longitude",
						file.location.longitude
					);
					formData.append("location.type", file.location.type);
					formData.append("location.title", file.location.title);
				}
				if (file.taggedIds) {
					formData.append(
						"taggedIds",
						file.taggedIds.map((profile) => profile.uuid)
					);
				}

				addAlbumFilesApi.request(formData, (progress) => {
					// todo: save the item progress based on a unique ID (otherwise what happens if 2 files have the same name ?)
					itemsProgress[file.multipartFile.name] = progress * 100;

					let totalPercent = itemsProgress
						? Object.values(itemsProgress).reduce(
								(sum, num) => sum + num,
								0
						  )
						: 0;

					setProgress(Math.round(totalPercent / droppedFiles.length));
				});
			});
		}
	};

	// !=========== EDIT FILE =============
	const [showEditModal, setShowEditModal] = useState(false);
	const [editItem, setEditItem] = useState("");
	const [editItemIndex, setEditItemIndex] = useState("");

	const handleShowEditModal = (file, index) => {
		setEditItem(file);
		setEditItemIndex(index);
		setShowEditModal(true);
	};

	const handleHideEditModal = () => {
		setShowEditModal(false);
	};

	const handleConfirmEdit = (newItem) => {
		const droppedFilesCopy = [...droppedFiles];
		droppedFilesCopy[editItemIndex] = newItem;
		setDroppedFiles(droppedFilesCopy);
	};

	/**
	 * Mappers
	 */

	const mappedFiles = useMemo(
		() =>
			droppedFiles.map((file, index) => {
				return (
					<GalleryFileItem
						key={index}
						file={file}
						onRemove={removeFile(index)}
						onEdit={() => handleShowEditModal(file, index)}
					/>
				);
			}),
		[droppedFiles]
	);

	return (
		<div className={styles.container}>
			<div className={styles.upload_header}>
				<div>
					<h4>Upload Files</h4>
					<p>
						You need to add all of your media here, then press
						“Upload all files” to upload all your files into the
						album. Only *.jpeg *.jpg *.png *.pdf and video files
						will be accepted.
					</p>
				</div>
				{droppedFiles.length > 0 && (
					<PrimaryButton
						onClick={handleUploadFiles}
						type="button"
						text={"upload"}
					/>
				)}
			</div>

			<SimpleDropZone
				onDrop={onDropFiles}
				acceptedFiles={[
					"image/png, image/jpg, image/jpeg, application/pdf, video/*",
				]}
			/>

			{showProgress && droppedFiles.length > 0 && (
				<LoadingBar progress={progress} />
			)}

			{mappedFiles.length > 0 && (
				<div className={styles.items_container}>
					<div className={styles.items_wrapper}>{mappedFiles}</div>
				</div>
			)}

			<EditMediaModal
				show={showEditModal}
				onClose={handleHideEditModal}
				onReset={() => setEditItem("")}
				item={editItem}
				onConfirm={handleConfirmEdit}
				onToggle={(bool) => setShowEditModal(bool)}
			/>
		</div>
	);
};

export default GalleryFileUploader;
