import usePrompt from "hooks/usePrompt";
import WarningModal from "../GaawkModal/WarningModal";

const PromptModal = ({
	when,
	onProceed = undefined,
	onCancel = undefined,
	headerText = "Are you sure you want to discard unsaved changes?",
	warningText = "This action cannot be undone.",
}) => {
	const { blocker, handleProceed, handleCancel } = usePrompt(
		when,
		onProceed,
		onCancel
	);

	return (
		<WarningModal
			show={blocker.state === "blocked"}
			headerText={headerText}
			warningText={warningText}
			cancelButtonText={"Cancel"}
			onCancelButtonClicked={handleCancel}
			submitButtonText={"DISCARD"}
			onSubmitButtonClicked={handleProceed}
		/>
	);
};

export default PromptModal;
