// import React from "react";
import ContentLoader from "react-content-loader";
// import useWindowSize from "../../../../hooks/useWindowSize";
import styles from "./VaultLoader.module.css";

const VaultLoader = ({ isList, ...props }) => {
	// const { width } = useWindowSize();

	// let loader;

	// switch (true) {
	//     case width >= 1400 && width < 1608:
	//         loader = (
	//             <>
	//                 <rect x="0" y="0" rx="12" ry="12" width="98" height="98" />
	//                 <rect x="0" y="108" rx="3" ry="3" width="98" height="7" />
	//                 <rect x="0" y="120" rx="3" ry="3" width="60" height="5" />
	//                 <rect x="0" y="130" rx="3" ry="3" width="30" height="5" />
	//             </>
	//         );
	//         break;
	//     case (width >= 640 && width < 748) || (width >= 974 && width < 1166) || (width >= 1166 && width < 1400):
	//         loader = (
	//             <>
	//                 <rect x="0" y="0" rx="12" ry="12" width="72" height="72" />
	//                 <rect x="0" y="82" rx="3" ry="3" width="72" height="7" />
	//                 <rect x="0" y="94" rx="3" ry="3" width="60" height="5" />
	//                 <rect x="0" y="104" rx="3" ry="3" width="30" height="5" />
	//             </>
	//         );
	//         break;
	//     case width < 640 || (width >= 748 && width < 974):
	//         loader = (
	//             <>
	//                 <rect x="0" y="0" rx="12" ry="12" width="96" height="96" />
	//                 <rect x="0" y="106" rx="3" ry="3" width="96" height="7" />
	//                 <rect x="0" y="118" rx="3" ry="3" width="60" height="5" />
	//                 <rect x="0" y="128" rx="3" ry="3" width="30" height="5" />
	//             </>
	//         );
	//         break;
	//     default:
	//         loader = (
	//             <>
	//                 <rect x="0" y="0" rx="12" ry="12" width="120" height="120" />
	//                 <rect x="0" y="130" rx="3" ry="3" width="120" height="7" />
	//                 <rect x="0" y="142" rx="3" ry="3" width="60" height="5" />
	//                 <rect x="0" y="152" rx="3" ry="3" width="30" height="5" />
	//             </>
	//         );
	//         break;
	// }

	return (
		<ContentLoader
			className={`${styles.vault_loader} ${
				isList ? styles.list : undefined
			}`}
			backgroundColor="#f2f3f5"
			foregroundColor="#c3c4c8"
			{...props}
		>
			{/* {loader} */}
			{isList ? (
				<rect x="0" y="0" rx="6" ry="6" width="100%" height="56" />
			) : (
				<>
					<rect x="0" y="0" rx="12" ry="12" width="96" height="96" />
					<rect x="0" y="106" rx="3" ry="3" width="96" height="7" />
					<rect x="0" y="118" rx="3" ry="3" width="60" height="5" />
					<rect x="0" y="128" rx="3" ry="3" width="30" height="5" />
				</>
			)}
		</ContentLoader>
	);
};

export default VaultLoader;
