import { useMemo } from "react";
import styles from "./VocalDetails.module.css";
import recordIcon from "images/record-icon.svg";
import micIcon from "images/microphone-icon.svg";
import cakeIcon from "images/cake-icon.svg";
import pitchIcon from "images/pitch-icon.svg";
import rangeIcon from "images/range-icon.svg";
import Tag from "../../../../Utils/SubComs/Tags/Tag";
import {
	pitch as pitchTypes,
	vocalAge as vocalAgeTypes,
	vocalRange as vocalRangeTypes,
} from "../special-types";
import SpecItem from "../../../../Utils/SubComs/SpecItem/SpecItem";

const VocalDetails = ({ data }) => {
	const {
		recordingTypes,
		vocalAge,
		vocalRange,
		voiceCharacteristics,
		pitch,
	} = data;

	const recordingTypesList = useMemo(
		() =>
			recordingTypes.map((record) => (
				<Tag
					key={record.uuid}
					customStyle={styles.tag}
					itemName={record.name}
				/>
			)),
		[recordingTypes]
	);

	const voiceCharacteristicsList = useMemo(
		() =>
			voiceCharacteristics.map((item) => (
				<Tag
					key={item.uuid}
					customStyle={styles.tag}
					itemName={item.name}
				/>
			)),
		[voiceCharacteristics]
	);

	return (
		<>
			{recordingTypes.length > 0 && (
				<SpecItem
					icon={recordIcon}
					title={"Recording Types"}
					content={recordingTypesList}
					hasTags={true}
				/>
			)}
			{voiceCharacteristics.length > 0 && (
				<SpecItem
					icon={micIcon}
					title={"Voice Characteristics"}
					content={voiceCharacteristicsList}
					hasTags={true}
				/>
			)}
			{vocalAge !== "N_A" && (
				<SpecItem
					icon={cakeIcon}
					title={"Vocal Age"}
					content={
						vocalAgeTypes.find((item) => item.value === vocalAge)
							.label
					}
				/>
			)}
			{pitch !== "N_A" && (
				<SpecItem
					icon={pitchIcon}
					title={"Pitch"}
					content={
						pitchTypes.find((item) => item.value === pitch).label
					}
				/>
			)}
			{vocalRange !== "N_A" && (
				<SpecItem
					icon={rangeIcon}
					title={"Vocal Range"}
					content={
						vocalRangeTypes.find(
							(item) => item.value === vocalRange
						).label
					}
				/>
			)}
		</>
	);
};

export default VocalDetails;
