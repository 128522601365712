import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import useApi from "./useApi";
import { useSelector } from "react-redux";

const useSearchFilters = (
	filters,
	locationId,
	queryName,
	inputValue,
	filterApi,
	selectedDynamicFilters,
	onChange,
	enabled = true,
	isJobs = false
) => {
	const queryClient = useQueryClient();

	const initialFilters = useMemo(
		() => ({
			...filters,
			...locationId,
		}),
		[locationId, filters]
	);

	const [selectedFilters, setSelectedFilters] = useState(
		Object.keys(selectedDynamicFilters).length > 0
			? selectedDynamicFilters
			: initialFilters
	);

	const filtersList = useSelector((state) => state.search.filters);

	const hasFilters = Object.keys(filtersList).length > 0;

	const searchFilterApi = useApi(filterApi, true, true);

	const fetchFilters = async ({ queryKey, signal }) => {
		const [_, searchInput, filtersList, locationId] = queryKey;

		//* only /job/search/filters has a endpoint that is paginated (not a bug)

		const response = isJobs
			? await searchFilterApi.request(
					0, //PAGE
					4, //SIZE
					hasFilters
						? {
								...filtersList,
								...locationId,
								q: searchInput,
						  }
						: { ...initialFilters, q: searchInput }
			  )
			: await searchFilterApi.request(
					hasFilters
						? {
								...filtersList,
								...locationId,
								q: searchInput,
						  }
						: { ...initialFilters, q: searchInput }
			  );
		return response.data;
	};

	const queryKey = [queryName, inputValue, filtersList, locationId];

	const { isFetching, data } = useQuery({
		queryKey: queryKey,
		queryFn: fetchFilters,
		enabled,
		staleTime: 0,
	});

	const didMountRef = useRef(false);

	useEffect(() => {
		let timer;
		if (didMountRef.current && Object.keys(selectedFilters).length > 0) {
			timer = setTimeout(() => {
				onChange({
					...selectedFilters,
					...locationId,
				});
			}, 500);
		}
		didMountRef.current = true;
		return () => clearTimeout(timer);
	}, [selectedFilters]);

	const handleRefetch = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return {
		initialFilters,
		data,
		isFetching,
		hasFilters,
		selectedFilters,
		setSelectedFilters,
		handleRefetch,
	};
};

export default useSearchFilters;
