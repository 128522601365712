import { forwardRef } from "react";
import GalleryItem from "components/Profile/GalleryTab/GalleryItem";
import useCurrentUser from "hooks/useCurrentUser";

const ServiceProductMediaItem = forwardRef(
	(
		{
			media,
			onClick,
			onEdit,
			onDownload,
			onCopy,
			onShare,
			onDelete,
			onReport,
		},
		ref
	) => {
		const { owner, postVisibility } = media;
		const { uuid } = useCurrentUser();

		return (
			<GalleryItem
				ref={ref}
				key={media.uuid}
				item={media}
				onClick={onClick}
				showPopover={true}
				isOwner={uuid === owner.uuid}
				isPublic={postVisibility === "PUBLIC"}
				onShare={onShare}
				onEdit={onEdit}
				onDelete={onDelete}
				onCopy={onCopy}
				onReport={onReport}
			/>
		);
	}
);

export default ServiceProductMediaItem;
