import React from "react";

import OTPInput, { ResendOTP } from "otp-input-react";

import "./CustomOTPInput.css";

function CustomOTPInput({ value, onChange }) {
    return (
        <OTPInput
            value={value}
            onChange={onChange}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            className="otp_container"
            inputClassName="otp_input"
        />
    );
}

export default CustomOTPInput;
