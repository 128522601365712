import { passwordRegex } from "components/Utils/General";
import Joi from "joi";

const loginSchema = Joi.object({
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label("Email")
        .messages({
            "string.empty": "Email should not be empty",
            "string.email": "Email should be valid",
        }),
    password: Joi.string()
        .pattern(passwordRegex)
        .required()
        .label("Password")
        .messages({
            "string.pattern.base": "Invalid password",
            "string.empty": "Password can't be empty",
        }),
});

export default loginSchema;
