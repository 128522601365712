import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";

import styles from "./Other.module.css";
import { addOther, updateOther } from "../../../../../store/slices/user";
import {
	addCompanyOther,
	updateCompanyOther,
} from "../../../../../store/slices/company";
import PrimaryButton from "../../../../Utils/Button/PrimaryButton";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PromptModal from "components/Utils/Prompt/PromptModal";

const OtherEntry = ({ editCompany }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isFocused, setIsFocused] = useState(false);
	const { pathname, state } = useLocation();

	const editMode = pathname === `/profile/other/edit`;

	const companyId = useSelector((state) => state.company.companyInfo.uuid);

	const {
		uuid,
		information: info,
		title,
		visibility: currentVisibility,
	} = state || {};

	const schema = Joi.object({
		title: Joi.string()
			.required()
			.label("Title")
			.messages({ "string.empty": "Title can't be empty" }),
		info: Joi.string()
			.required()
			.label("Info")
			.messages({ "string.empty": "Info can't be empty" }),
	});

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		watch,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					title,
					info,
			  }
			: {
					title: "",
					info: "",
			  },
	});

	const infoWatcher = watch("info");
	const [visibility, setVisibility] = useState(
		currentVisibility ?? "WORK_CIRCLE_FRIENDS"
	);
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const maxCount = 5000;
	const validCount =
		infoWatcher?.length > 0 && infoWatcher?.length <= maxCount;

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	const handleSave = (data) => {
		const { info: information, title } = data;

		if (editCompany) {
			dispatch(
				editMode
					? updateCompanyOther(
							{
								...(editMode && { uuid }),
								title,
								information,
								visibility,
							},
							companyId
					  )
					: addCompanyOther(
							{ title, information, visibility },
							companyId
					  )
			);
		} else {
			dispatch(
				editMode
					? updateOther({
							...(editMode && { uuid }),
							title,
							information,
							visibility,
					  })
					: addOther({ title, information, visibility })
			);
		}

		setShouldBlockSave(false);
	};

	useEffect(() => {
		if (!shouldBlockSave) navigate("/profile/other");
	}, [navigate, shouldBlockSave]);

	if (editMode && !state) {
		return <Navigate to={`/profile/other`} />;
	}

	return (
		<>
			{/* <div className={styles.container}> */}
			{/* <div className={styles.contact_section}> */}
			<form
				className={styles.add_form}
				onSubmit={handleSubmit((data) => handleSave(data))}
				noValidate
			>
				<div className={styles.form_input_wrapper}>
					<label>
						Title
						<span className="required">*</span>
					</label>

					<TextInput
						{...register("title")}
						error={!!errors.title}
						placeholder="Title"
					/>
					{errors?.title?.message && (
						<p className={styles.error_message}>
							{errors?.title?.message}
						</p>
					)}
				</div>

				<div className={styles.textarea_container}>
					<label>
						Info
						<span className="required">*</span>
					</label>
					<div
						className={`${styles.textarea_wrapper} ${
							!!errors.info ? styles.error : undefined
						} ${isFocused ? styles.focused : undefined}`}
					>
						<textarea
							placeholder="Other info"
							maxLength={maxCount}
							{...register("info")}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
						/>

						<div className={styles.word_count_wrapper}>
							<span
								className={
									validCount
										? styles.text_count
										: styles.text_count +
										  " " +
										  styles.danger
								}
							>
								{`${infoWatcher.length}/${maxCount}`}
							</span>
						</div>
					</div>
					{errors?.info?.message && (
						<p className={styles.error_message}>
							{errors?.info?.message}
						</p>
					)}
				</div>

				<div className={styles.visibility_wrapper}>
					<label className={styles.visibility_label}>
						Visibility
						<span className="required">*</span>
					</label>

					<VisibilitySelector
						value={visibility}
						onValueChange={handleVisibilityChange}
					/>
				</div>
				<div className={styles.button_container}>
					<PrimaryButton className={styles.save_btn} text={"save"} />
				</div>
			</form>
			{/* </div> */}
			{/*  </div> */}

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default OtherEntry;
