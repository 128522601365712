export const reportKeys = {
	all: ["report"],

	reasonsList: (category) => [...reportKeys.all, "reasons", category],

	// list: (category, coordinates) =>
	//     [...boardsKeys.lists(), { category }, coordinates].filter(
	//         (item) => item !== undefined
	//     ),

	// searchList: (type) =>
	//     [...boardsKeys.all, "search", type].filter(
	//         (item) => item !== undefined
	//     ),

	// search: (query, coordinates) => [
	//     ...boardsKeys.searchList(),
	//     query,
	//     coordinates,
	// ],

	// details: () => [...boardsKeys.all, "details"],

	// detail: (boardId) => [...boardsKeys.details(), boardId],

	// posts: (boardId) => [...boardsKeys.detail(boardId), "posts"],

	// searchPosts: (boardId, searchQuery) => [
	//     ...boardsKeys.searchList("posts"),
	//     boardId,
	//     searchQuery,
	// ],
};
