import client from "./client";

const endpoint = "/institution";

const searchCertificateInstitute = (page, size, q) =>
	client.get(
		endpoint + `/certificate/search?page=${page}&size=${size}&name=${q}`
	);

const searchEducationInstitute = (page, size, q) =>
	client.get(
		endpoint + `/education/search?page=${page}&size=${size}&name=${q}`
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	searchCertificateInstitute,
	searchEducationInstitute,
};
