import styles from "./ReplyWidget.module.css";
import StaticMap from "../Utils/StaticMap/StaticMap";
import { getFileIcon, removeFileExtension } from "../Utils/General";
import locationIcon from "images/location_icon.svg";
import photoIcon from "images/photo-icon.svg";
import videoIcon from "images/video-icon.svg";
import attachIcon from "images/icon-attach.svg";
import micIcon from "images/mic-grey.svg";
import ContactCard from "./ContactCard";
import { format } from "date-fns";

const ReplyWidget = ({ item, isReplying = false }) => {
	const { messageType, media, location, attachedProfile } = item;

	let widgetContent;
	if (media) {
		if (media.file.previewType === "IMAGE") {
			widgetContent = (
				<>
					<div className={styles.reply_image}>
						<img src={media.file.customName} alt="media" />
					</div>
					<div className={styles.reply_details_container}>
						<div className={styles.reply_title}>
							{media.file.originalName}
						</div>
						<div className={styles.reply_subtitle}>
							<img src={photoIcon} alt="media" />
							Photo
						</div>
					</div>
				</>
			);
		} else if (media.file.previewType === "VIDEO") {
			widgetContent = (
				<>
					<div className={styles.reply_image}>
						<img src={media.file.customName} alt="media" />
					</div>
					<div className={styles.reply_details_container}>
						<div className={styles.reply_title}>
							{media.file.originalName}
						</div>
						<div className={styles.reply_subtitle}>
							<img src={videoIcon} alt="media" />
							Video
						</div>
					</div>
				</>
			);
		} else if (messageType === "VOICE") {
			widgetContent = (
				<>
					<div className={styles.reply_details_container}>
						<div className={styles.reply_title}>
							Voice Message (
							{format(item.seconds * 1000, "mm:ss")})
						</div>
						<div className={styles.reply_subtitle}>
							<img src={micIcon} alt="mic icon" />
							{getFileIcon(media.file, media.name).ext} File
						</div>
					</div>
				</>
			);
		} else {
			widgetContent = (
				<>
					<div className={styles.reply_image_file}>
						<img
							src={getFileIcon(media.file, media.name).icon}
							alt="media"
						/>
					</div>
					<div className={styles.reply_details_container}>
						<div className={styles.reply_title}>
							{removeFileExtension(media.file.originalName)}
						</div>
						<div className={styles.reply_subtitle}>
							<img src={attachIcon} alt="media" />
							{getFileIcon(media.file, media.name).ext} File
						</div>
					</div>
				</>
			);
		}
	} else if (location) {
		widgetContent = (
			<>
				<div className={styles.reply_image}>
					<StaticMap
						lat={location.latitude}
						lng={location.longitude}
						title={location.title}
					/>
				</div>
				<div className={styles.reply_details_container}>
					<div className={styles.reply_title}>{location.title}</div>
					<div className={styles.reply_subtitle}>
						<img src={locationIcon} alt="location" />
						Location
					</div>
				</div>
			</>
		);
	} else if (attachedProfile) {
		widgetContent = (
			<div className={styles.contact_container}>
				<ContactCard profile={attachedProfile} isReply={true} />
			</div>
		);
	}

	return (
		<>
			{widgetContent && (
				<div
					className={`${styles.reply_container} ${
						isReplying ? styles.is_replying : undefined
					}`}
				>
					{widgetContent}
				</div>
			)}
		</>
	);
};

export default ReplyWidget;
