import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ConnectionTab.module.css";
import useApi from "../../../hooks/useApi";
import connectionApi from "../../../api/connection";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import workIcon from "images/connection-icons/work.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import { pluralize } from "components/Utils/General";

const Work = ({ profile, canEdit, connectionCount }) => {
	const getWorkCircleApi = useApi(connectionApi.getWorkCircle);
	const [workCircle, setWorkCircle] = useState([]);

	const { type, firstName, lastName, name } = profile;
	const fullName = type === "USER" ? `${firstName} ${lastName}` : `${name}`;

	const { connection } = profile;

	const navigate = useNavigate();

	const { userUrl } = useParams();

	const fetchWorkCircle = () => {
		getWorkCircleApi.request(profile.uuid, 0, 6);
	};

	useEffect(() => {
		// if (profile.uuid) {

		fetchWorkCircle();
		// }
	}, [
		profile.uuid,
		connection.inMyWorkCircle,
		connection.inTheirWorkCircles,
	]);

	useEffect(() => {
		if (
			!getWorkCircleApi.loading &&
			getWorkCircleApi.responseCode === 200 &&
			getWorkCircleApi.data &&
			getWorkCircleApi.data.length > 0
		) {
			setWorkCircle(getWorkCircleApi.data);
		}
	}, [getWorkCircleApi.data]);

	const handleViewAll = (action) => () => {
		sessionStorage.setItem("activeConnectionTab", action);
		navigate(
			userUrl
				? `/profile/${userUrl}/connections`
				: "/profile/connections",
			{
				state: {
					profile,
					canEdit,
				},
			}
		);
	};

	const workCircleList = workCircle.map((contact) => (
		<div className={styles.contact_item} key={contact.uuid}>
			<GroupMember data={contact} redirect={true} />
		</div>
	));

	if (
		connectionCount.inMyWorkCircle === 0 &&
		connectionCount.inTheirWorkCircles === 0
	)
		return null;

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img src={workIcon} alt="Work" />
					Work circle
				</div>
				<SeeMore
					text="See All"
					onClick={handleViewAll("Your work circle")}
				/>
			</div>

			{getWorkCircleApi.loading && <ContactLoader />}

			{connectionCount.inMyWorkCircle > 0 &&
				!getWorkCircleApi.loading && (
					<>
						<div className={styles.subtitle}>
							{canEdit
								? "You have "
								: profile.type === "USER"
								? `${profile.firstName} ${profile.lastName} has `
								: `${profile.name} has `}
							<span
								onClick={handleViewAll("Your work circle")}
							>{`${connectionCount.inMyWorkCircle}`}</span>
							{canEdit
								? ` ${pluralize(
										connectionCount.inMyWorkCircle,
										"connection"
								  )} in your work circle.`
								: ` ${pluralize(
										connectionCount.inMyWorkCircle,
										"connection"
								  )} in his/her work circle.`}
						</div>

						<div>{workCircleList}</div>
					</>
				)}

			{/* {connectionCount.inMyWorkCircle === 0 && !getWorkCircleApi.loading && (
                        <div className={`${styles.more_section} ${styles.counter}`}>
                            <div className={`${styles.more_info} ${styles.counter}`}>
                                You don't have any contact in your work circle yet
                            </div>
                        </div>
                    )} */}

			{connectionCount.inTheirWorkCircles > 0 && (
				<div className={styles.more_section}>
					<div className={styles.more_info}>
						<span
							onClick={handleViewAll("Work circles you're in")}
						>{`${connectionCount.inTheirWorkCircles}`}</span>{" "}
						{pluralize(
							connectionCount.inTheirWorkCircles,
							"Connection"
						)}{" "}
						added {canEdit ? "you" : `${fullName}`} to their work
						circle.
					</div>
					<div
						className={styles.view_all}
						onClick={handleViewAll("Work circles you're in")}
					>
						View all connections
					</div>
				</div>
			)}
		</ProfileTabContainer>
	);
};

export default Work;
