import DefaultPage from "../Utils/PageArchs/DefaultPage/DefaultPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useCompanyId from "../../hooks/useCompanyId";
import useFetchProfile from "../../hooks/useFetchProfile";
import { updateProfileTab } from "store/slices/activeTab";
import { useMemo } from "react";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import ServicesTree from "./ServicesTab/ServicesComponent/ServicesTree";
import useCurrentUser from "hooks/useCurrentUser";

const Index = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const activeTab = useSelector((state) => state.activeTab.profileTab);

	const companyId = useCompanyId();
	const currentUser = useCurrentUser();

	// TODO >> REFACTOR useFetchProfile TO USE REACT-QUERY !!!!
	const {
		fetchProfile,
		profile,
		canEdit,
		handleUpdateFriendStatus,
		handleUpdateWorkCircle,
		handleUpdateFollow,
		handleUpdateBlockStatus,
		handleUnblock,
		handleUpdateFavorite,
	} = useFetchProfile(currentUser, companyId);

	const tabClicked = (e) => {
		dispatch(updateProfileTab(e.target?.innerHTML || e));
	};

	const leftSidePanel = useMemo(() => {
		if (activeTab === "services" && profile.hasServices) {
			return (
				<div style={{ padding: "20px" }}>
					<ServicesTree userId={profile.uuid} enabled={true} />
				</div>
			);
		} else if (activeTab === "products" && profile.productsHasServices) {
			return (
				<div style={{ padding: "20px" }}>
					<ServicesTree
						showServicesWithProducts={true}
						userId={profile.uuid}
						enabled={true}
					/>
				</div>
			);
		} else {
			return <HomeLeftSidebar />;
		}
	}, [
		activeTab,
		profile.hasServices,
		profile.productsHasServices,
		profile.uuid,
	]);

	return (
		<DefaultPage
			forceShowLeftScroll={true}
			leftSideChildren={leftSidePanel}
			rightSideChildren={
				profile && (
					<ProfilePage
						companyId={companyId}
						userInfo={profile}
						onItemClicked={tabClicked}
						canEdit={canEdit}
						onClickEditProfilePic={() =>
							navigate("/profile/profilePic")
						}
						onClickSetFrame={() =>
							navigate("/profile/profilePic/frame")
						}
						onClickEditCoverPic={() =>
							navigate("/profile/coverPic")
						}
						onClickEdit={() => navigate("/profile/edit")}
						onUpdateWork={handleUpdateWorkCircle}
						onUpdateFollow={handleUpdateFollow}
						onUpdateFriendStatus={handleUpdateFriendStatus}
						onUpdateBlock={handleUpdateBlockStatus}
						onUnblock={handleUnblock}
						onUpdateFav={handleUpdateFavorite}
						onReloadProfile={fetchProfile}
					/>
				)
			}
		/>
	);
};

export default Index;
