const LanguageProficiency = {
    NO_PROFICIENCY: {
        id: 0,
        label: "0. No Proficiency",
        value: "NO_PROFICIENCY",
    },
    ELEMENTARY_PROFICIENCY: {
        id: 1,
        label: "1. Elementary Proficiency",
        value: "ELEMENTARY_PROFICIENCY",
    },
    LIMITED_WORKING_PROFICIENCY: {
        id: 2,
        label: "2. Limited Working Proficiency",
        value: "LIMITED_WORKING_PROFICIENCY",
    },
    PROFESSIONAL_WORKING_PROFICIENCY: {
        id: 3,
        label: "3. Professional Working Proficiency",
        value: "PROFESSIONAL_WORKING_PROFICIENCY",
    },
    FULL_PROFESSIONAL_PROFICIENCY: {
        id: 4,
        label: "4. Full Professional Proficiency",
        value: "FULL_PROFESSIONAL_PROFICIENCY",
    },
    NATIVE_BILINGUAL_PROFICIENCY: {
        id: 5,
        label: "5. Native / Bilingual Proficiency",
        value: "NATIVE_BILINGUAL_PROFICIENCY",
    },
};

export const getLanguageProficiencies = () => {
    return [
        {
            id: LanguageProficiency.ELEMENTARY_PROFICIENCY.id,
            label: LanguageProficiency.ELEMENTARY_PROFICIENCY.label,
            value: LanguageProficiency.ELEMENTARY_PROFICIENCY.value,
        },
        {
            id: LanguageProficiency.LIMITED_WORKING_PROFICIENCY.id,
            label: LanguageProficiency.LIMITED_WORKING_PROFICIENCY.label,
            value: LanguageProficiency.LIMITED_WORKING_PROFICIENCY.value,
        },
        {
            id: LanguageProficiency.PROFESSIONAL_WORKING_PROFICIENCY.id,
            label: LanguageProficiency.PROFESSIONAL_WORKING_PROFICIENCY.label,
            value: LanguageProficiency.PROFESSIONAL_WORKING_PROFICIENCY.value,
        },
        {
            id: LanguageProficiency.FULL_PROFESSIONAL_PROFICIENCY.id,
            label: LanguageProficiency.FULL_PROFESSIONAL_PROFICIENCY.label,
            value: LanguageProficiency.FULL_PROFESSIONAL_PROFICIENCY.value,
        },
        {
            id: LanguageProficiency.NATIVE_BILINGUAL_PROFICIENCY.id,
            label: LanguageProficiency.NATIVE_BILINGUAL_PROFICIENCY.label,
            value: LanguageProficiency.NATIVE_BILINGUAL_PROFICIENCY.value,
        },
    ];
};

export const getLanguageProficiency = (type) => {
    switch (type) {
        case LanguageProficiency.ELEMENTARY_PROFICIENCY.value:
            return LanguageProficiency.ELEMENTARY_PROFICIENCY;

        case LanguageProficiency.LIMITED_WORKING_PROFICIENCY.value:
            return LanguageProficiency.LIMITED_WORKING_PROFICIENCY;

        case LanguageProficiency.PROFESSIONAL_WORKING_PROFICIENCY.value:
            return LanguageProficiency.PROFESSIONAL_WORKING_PROFICIENCY;

        case LanguageProficiency.FULL_PROFESSIONAL_PROFICIENCY.value:
            return LanguageProficiency.FULL_PROFESSIONAL_PROFICIENCY;

        case LanguageProficiency.NATIVE_BILINGUAL_PROFICIENCY.value:
            return LanguageProficiency.NATIVE_BILINGUAL_PROFICIENCY;

        default:
            return LanguageProficiency.NO_PROFICIENCY;
    }
};
