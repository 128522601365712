import styles from "./SpecialSection.module.css";
import CtaButton from "components/Utils/Button/CtaButton";
import EditButton from "components/Utils/Button/EditButton";

const SpecialSection = ({
	label,
	content,
	canEdit,
	onEdit,
	noBorder = false,
	hasContent,
	btnText = "",
	disableEdit = false,
	disabled = false,
	warningContent,
}) => {
	return (
		<div
			className={`${styles.section} ${
				noBorder ? styles.noBorder : undefined
			} ${!hasContent && !canEdit ? styles.hide : undefined}`}
		>
			{warningContent}

			<div
				className={`${styles.container} ${
					disabled ? styles.disabled : undefined
				}`}
			>
				{hasContent && (
					<>
						<div className={styles.item_header}>
							<h3 className={styles.header_title}>{label}</h3>
							{canEdit && !disableEdit && (
								<EditButton onClick={onEdit} />
							)}
						</div>
						{content}
					</>
				)}

				{!hasContent && canEdit && (
					<div className={styles.emptyContainer}>
						<h2 className={styles.header_title}>{label}</h2>
						<div className={styles.emptyText}>
							Lorem ipsum dolor sit amet consectetur, adipisicing
							elit. Quia exercitationem, fuga dolorem nulla
							consequuntur enim obcaecati doloribus doloremque
							mollitia aspernatur. Ratione repellendus corrupti
							hic quo cumque dicta ducimus. Cupiditate,
							necessitatibus!
						</div>
						<div className={styles.emptyBtn}>
							<CtaButton
								className={styles.cta_button}
								onClick={onEdit}
								disabled={disabled}
								text={`${
									btnText
										? btnText.toUpperCase()
										: `+ ADD ${label.toUpperCase()}`
								}`}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SpecialSection;
