import { useMemo, useRef, useState } from "react";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./AdvancedSearch.module.css";

import SearchType from "./FormSections/SearchType";
import JobsFilter from "./FormSections/JobsFilter";
import FreelanceFilter from "./FormSections/FreelanceFilter";
import CastingFilter from "./FormSections/CastingFilter";
import CompensationFilter from "./FormSections/CompensationFilter";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import useCompanyId from "../../../hooks/useCompanyId";
import { useNavigate } from "react-router-dom";
import useJobInitialFilters from "../Landing/hooks/useJobInitialFilters";

const AdvancedSearch = ({ data, filterMode = false }) => {
	const navigate = useNavigate();
	const companyId = useCompanyId();
	const searchTypeRef = useRef();

	useMemo(() => {
		if (companyId) navigate("/jobs");
	}, [companyId, navigate]);

	// const initialState = {
	// 	type: [{ label: "All", value: 0 }],
	// 	postedDate: [{ label: "Any Time", value: "NONE" }],
	// 	jobType: [],
	// 	workLocation: [],
	// 	freelanceWorkLocation: [],
	// 	compensation: "",
	// 	currency: null,
	// 	frequency: null,
	// 	positionStatus: false,
	// 	calendarAvailability: false,
	// 	startDate: null,
	// 	endDate: null,
	// 	experienceLevel: "",
	// 	freelanceExperienceLevel: "",
	// 	gender: [],
	// 	minAge: "",
	// 	maxAge: "",
	// 	castingDate: null,
	// 	ethnicity: null,
	// 	languages: [],
	// 	skills: [],
	// 	location: null,
	// };

	const initialState = useJobInitialFilters();

	const [searchInput, setSearchInput] = useState("");
	const [searchProps, setSearchProps] = useState(
		data && Object.keys(data).length > 0 ? data : initialState
	);

	const {
		type,
		postedDate,
		jobType,
		workLocation,
		freelanceWorkLocation,
		compensation,
		currency,
		frequency,
		positionStatus,
		calendarAvailability,
		startDate,
		endDate,
		experienceLevel,
		freelanceExperienceLevel,
		gender,
		minAge,
		maxAge,
		castingDate,
		ethnicity,
		languages,
		skills,
		location,
	} = searchProps;

	const handleSubmit = (e) => {
		e.preventDefault();

		const body = {
			q: searchInput,
			time: postedDate[0].value,
			jobType: type[0].value, //! >>> NEEDED TO FILTER BY JOB TYPE BUT NOT WORKING

			...(location && {
				locationId: location.value,
			}),
			...(type[0].label === "Jobs" && {
				jobRequest: {
					...(Number(experienceLevel) > 0 && {
						experience: Number(experienceLevel),
					}),
					...(jobType.length > 0 && {
						longTermJobTypeId: jobType.map((type) => type.value),
					}),
					...(workLocation.length > 0 && {
						workingLocation: workLocation[0].value,
					}),
				},
			}),
			...(type[0].label === "Casting Calls" && {
				castingCallRequest: {
					...(gender.length > 0 &&
						gender.length < 2 && {
							gender: gender[0].value,
						}), //! THE PASSED VALUE SHOULD BE "MALE" OR "FEMALE" not INTEGER?
					...(Number(minAge) > 0 && { minAge: Number(minAge) }),
					...(Number(maxAge) > 0 && { maxAge: Number(maxAge) }),
					...(ethnicity && { ethnicityId: ethnicity.value }),
					...(languages.length > 0 && {
						languageIds: languages.map(
							(language) => language.value
						),
					}),
					...(castingDate && { date: castingDate.getTime() }),
				},
			}),
			...(type[0].label === "Freelance" && {
				freelanceRequest: {
					showBasedOnCalendar: calendarAvailability,
					...(startDate && { startDate: startDate.getTime() }),
					...(endDate && { endDate: endDate.getTime() }),
					...(freelanceWorkLocation.length > 0 && {
						workingLocation: freelanceWorkLocation[0].value,
					}),
					...(Number(freelanceExperienceLevel) > 0 && {
						experience: Number(freelanceExperienceLevel),
					}),
				},
			}),
			...(skills.length > 0 && {
				skillIds: skills.map((skill) => skill.value),
			}),
			...(Number(compensation) > 0 && {
				compensation: Number(compensation),
			}),
			...(currency && { currencyId: currency.value }),
			...(frequency && { frequency: frequency.value }),
		};

		navigate("/jobs/results", {
			state: { body, searchProps },
		});
	};

	return (
		<form className={styles.form} onSubmit={handleSubmit} noValidate>
			{!filterMode && (
				<div className={styles.search_container}>
					<SearchInput
						onChange={(e) => setSearchInput(e.target.value)}
						value={searchInput}
						showIcons={true}
						onClearClicked={() => setSearchInput("")}
						border={false}
						placeholder={"Search Jobs"}
					/>
				</div>
			)}
			<SearchType
				ref={searchTypeRef}
				onLocationChange={(e) =>
					setSearchProps((prevState) => ({
						...prevState,
						location: e,
					}))
				}
				onTypeChange={(e) =>
					setSearchProps((prevState) => ({ ...prevState, type: e }))
				}
				onDateChange={(e) =>
					setSearchProps((prevState) => ({
						...prevState,
						postedDate: e,
					}))
				}
				type={type}
				postedDate={postedDate}
				location={location}
			/>
			{type[0].label === "Jobs" && (
				<JobsFilter
					onJobTypeChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							jobType: e,
						}))
					}
					jobType={jobType}
					onWorkLocationChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							workLocation: e,
						}))
					}
					workLocation={workLocation}
					onExperienceChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							experienceLevel: e.target.value,
						}))
					}
					experienceLevel={experienceLevel}
				/>
			)}
			{type[0].label === "Casting Calls" && (
				<CastingFilter
					minAge={minAge}
					maxAge={maxAge}
					ethnicity={ethnicity}
					onGenderChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							gender: e,
						}))
					}
					gender={gender}
					onMaxAgeChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							maxAge: e.target.value,
						}))
					}
					onMinAgeChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							minAge: e.target.value,
						}))
					}
					onCastingDateChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							castingDate: e,
						}))
					}
					castingDate={castingDate}
					onEthnicityChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							ethnicity: e,
						}))
					}
					languages={languages}
					onAddLanguage={(language) =>
						setSearchProps((prevState) => ({
							...prevState,
							languages: [...prevState.languages, language],
						}))
					}
					onRemoveLanguage={(languageIndex) =>
						setSearchProps((prevState) => ({
							...prevState,
							languages: prevState.languages.filter(
								(_, index) => index !== languageIndex
							),
						}))
					}
				/>
			)}

			{type[0].label === "Freelance" && (
				<FreelanceFilter
					onCalendarChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							calendarAvailability: e,
						}))
					}
					calendarAvailability={calendarAvailability}
					onStartDateChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							startDate: e,
						}))
					}
					onEndDateChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							endDate: e,
						}))
					}
					startDate={startDate}
					endDate={endDate}
					onWorkLocationChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							freelanceWorkLocation: e,
						}))
					}
					workLocation={freelanceWorkLocation}
					onExperienceChange={(e) =>
						setSearchProps((prevState) => ({
							...prevState,
							freelanceExperienceLevel: e.target.value,
						}))
					}
					experienceLevel={freelanceExperienceLevel}
				/>
			)}
			<CompensationFilter
				compensation={compensation}
				currency={currency}
				frequency={frequency}
				onCompensationChange={(e) =>
					setSearchProps((prevState) => ({
						...prevState,
						compensation: e.target.value,
					}))
				}
				onCurrencyChange={(e) =>
					setSearchProps((prevState) => ({
						...prevState,
						currency: e,
					}))
				}
				onFrequencyChange={(e) =>
					setSearchProps((prevState) => ({
						...prevState,
						frequency: e,
					}))
				}
				onStatusChange={(e) =>
					setSearchProps((prevState) => ({
						...prevState,
						positionStatus: e,
					}))
				}
				positionStatus={positionStatus}
				skills={skills}
				onAddSkill={(skill) =>
					setSearchProps((prevState) => ({
						...prevState,
						skills: [...prevState.skills, skill],
					}))
				}
				onRemoveSkill={(skillIndex) =>
					setSearchProps((prevState) => ({
						...prevState,
						skills: prevState.skills.filter(
							(_, index) => index !== skillIndex
						),
					}))
				}
			/>

			<div className={styles.button_container}>
				{filterMode && (
					<PrimaryButton
						type="button"
						onClick={() => {
							searchTypeRef.current.clear();
							setSearchProps(initialState);
						}}
						className={styles.clear_btn}
						text={"clear filters"}
					/>
				)}

				<PrimaryButton
					className={styles.search_btn}
					text={filterMode ? "apply filters" : "search"}
				/>
			</div>
		</form>
	);
};

export default AdvancedSearch;
