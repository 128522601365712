import React, { useState, useEffect } from "react";
import UpdateWorkStatus from "../../components/Profile/WorkStatusTab/UpdateWorkStatus";

import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import useApi from "../../hooks/useApi";
import workStatusApi from "../../api/workStatus";
import styles from "../Header.module.css";
import { useSelector } from "react-redux";
import { locationFormatter } from "../../components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import { useNavigate } from "react-router-dom";

const UpdateWorkStatusView = () => {
	const navigate = useNavigate();
	const getWorkStatusApi = useApi(workStatusApi.getWorkStatus);
	const { uuid: profileId } = useSelector((state) => state.user.profileInfo);

	const [workStatus, setWorkStatus] = useState("");

	useEffect(() => {
		getWorkStatusApi.request(profileId);
	}, []);

	useEffect(() => {
		if (
			!getWorkStatusApi.loading &&
			Object.keys(getWorkStatusApi.data).length > 0 &&
			getWorkStatusApi.responseCode === 200
		) {
			const { roles, locations, legalCountries, ...rest } =
				getWorkStatusApi.data;

			const formattedRoles = roles?.map((role) => ({
				label: role.roleDTO ? role.roleDTO.name : role.roleName,
				value: role.roleDTO ? role.roleDTO.uuid : role.roleName,
			}));

			setWorkStatus({
				...rest,
				locations: locations.map((item) => locationFormatter(item)),
				legalCountries: legalCountries.map((item) =>
					locationFormatter(item)
				),
				roles: roles ? formattedRoles : [],
			});
		}
	}, [getWorkStatusApi.data]);

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Update Work
					Status
				</div>
			}
			defaultHeader={false}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={
				workStatus && <UpdateWorkStatus workStatus={workStatus} />
			}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default UpdateWorkStatusView;
