import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./PostLoader.module.css";

const PostLoader = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.options}>
                <ContentLoader backgroundColor="#f3f3f3" foregroundColor="#ecebeb" width={26} height={73} {...props}>
                    <ellipse cx="3" cy="30" rx="3" ry="3" />
                    <ellipse cx="3" cy="40" rx="3" ry="3" />
                    <ellipse cx="3" cy="50" rx="3" ry="3" />
                </ContentLoader>
            </div>
            <ContentLoader backgroundColor="#f3f3f3" foregroundColor="#ecebeb" height={420} width={"100%"} {...props}>
                <ellipse cx="20" cy="20" rx="20" ry="20" />
                <rect x="50" y="0" width="165" height="16" rx="2" />
                <rect x="50" y="25" width="85" height="16" rx="2" />
                <rect x="0" y="60" width="100%" height="300" />
                <rect x="0" y="370" width="100%" height="2" />
                <rect x="0" y="380" width="32%" height="40" rx="2" />
                <rect x="35%" y="380" width="32%" height="40" rx="2" />
                <rect x="70%" y="380" width="32%" height="40" rx="2" />
            </ContentLoader>
        </div>
    );
};

export default PostLoader;
