import Joi from "joi";

const projectSchema = Joi.object({
    title: Joi.string().label("Title").required().messages({
        "string.empty": "Title can't be empty",
    }),
    startDate: Joi.date().label("Start date").allow(null),
    endDate: Joi.date()
        .min(Joi.ref("startDate"))
        .label("End Date")
        .allow(null)
        .messages({
            "date.min": "End date must be equal or greater than the Start date",
        }),
    image: Joi.array().min(0),
    tags: Joi.array().min(0).label("Project Tags"),
    status: Joi.boolean(),
    description: Joi.string().label("Project Description").allow(""),
    address: Joi.string().label("Address").allow(""),
    city: Joi.object({
        value: Joi.number(),
        label: Joi.string(),
    })
        .label("City/Country")
        .allow(null),
});

export default projectSchema;
