import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "service",
	initialState: {
		step1: undefined,
		step2: undefined,
		step: 1,
		modal: false,
		service: undefined,
		suggModal: false,
		locationModal: false,
		// editMode: false,
	},

	reducers: {
		updateStep1: (state, action) => {
			state.step1 = action.payload;
		},
		updateStep2: (state, action) => {
			state.step2 = action.payload;
		},
		setStep: (state, action) => {
			state.step = action.payload;
		},
		clear: (state) => {
			state.step1 = undefined;
			state.step2 = undefined;
		},
		setModal: (state, action) => {
			state.modal = action.payload;
		},
		setSuggModal: (state, action) => {
			state.suggModal = action.payload;
		},
		setLocationModal: (state, action) => {
			state.locationModal = action.payload;
		},
		setService: (state, action) => {
			state.service = action.payload;
		},

		// setEditMode: (state, action) => {
		// 	state.editMode = action.payload;
		// },
	},
});

export const {
	updateStep1,
	updateStep2,
	setSuggModal,
	setLocationModal,
	// setEditMode,
	clear,
	setStep,
	setService,
	setModal,
} = slice.actions;
export default slice.reducer;
