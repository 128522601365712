import { useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "hooks/useApi";
import { useEffect, useMemo, useRef, useState } from "react";
// import useApi from "./useApi";
import { useSelector } from "react-redux";

const useAgencyFilters = (
	filters,
	userCoordinates,
	queryName,
	filterApi,
	selectedDynamicFilters,
	onChange,
	enabled = true
) => {
	const queryClient = useQueryClient();

	const initialFilters = useMemo(
		() => ({
			...filters,
			locationIds: [userCoordinates.id],
		}),
		[userCoordinates, filters]
	);

	const [selectedFilters, setSelectedFilters] = useState(
		Object.keys(selectedDynamicFilters).length > 0
			? selectedDynamicFilters
			: initialFilters
	);

	const filtersList = useSelector((state) => state.search.filters);

	const hasFilters = Object.keys(filtersList).length > 0;

	const searchFilterApi = useApi(filterApi, true, true);

	const fetchFilters = async ({ queryKey, signal }) => {
		const [_, filtersList, userCoordinates] = queryKey;

		//* only /job/search/filters has a endpoint that is paginated (not a bug)

		const response = await searchFilterApi.request(
			hasFilters
				? {
						...filtersList,
						locationIds: [userCoordinates.id],
				  }
				: { ...initialFilters },
			userCoordinates.lat,
			userCoordinates.lng
		);

		return response.data;
	};

	const queryKey = [queryName, filtersList, userCoordinates];

	const { isFetching, data } = useQuery({
		queryKey: queryKey,
		queryFn: fetchFilters,

		enabled,
		staleTime: 0,
	});

	const didMountRef = useRef(false);

	useEffect(() => {
		let timer;
		if (didMountRef.current && Object.keys(selectedFilters).length > 0) {
			timer = setTimeout(() => {
				console.log({
					...selectedFilters,
					locationIds: [userCoordinates.id],
				});

				onChange({
					...selectedFilters,
					locationIds: [userCoordinates.id],
				});
			}, 500);
		}
		didMountRef.current = true;
		return () => clearTimeout(timer);
	}, [selectedFilters]);

	const handleRefetch = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return {
		initialFilters,
		data,
		isFetching,
		hasFilters,
		selectedFilters,
		setSelectedFilters,
		handleRefetch,
	};
};

export default useAgencyFilters;
