import styles from "../ScrollableMenu/ProfileScrollableMenu.module.css";
import ScrollContainer from "react-indiana-drag-scroll";

const ConnectionScrollableMenu = ({
	activeTab,
	onItemClicked,
	canEdit,
	profile,
	// onFocus,
}) => {
	const { type, firstName, name } = profile;

	const shortName = type === "USER" ? `${firstName}` : `${name}`;

	return (
		<ScrollContainer className={styles.scroll_container}>
			<section className={styles.tiles}>
				{/*onFocus={onFocus}*/}

				<div className={styles.col}>
					<span
						className={activeTab === "Friends" ? styles.active : ""}
						onClick={onItemClicked}
					>
						Friends
					</span>
				</div>
				{canEdit && (
					<>
						<div className={styles.col}>
							<span
								className={
									activeTab === "Sent Requests"
										? styles.active
										: ""
								}
								onClick={onItemClicked}
							>
								Sent Requests
							</span>
						</div>
						<div className={styles.col}>
							<span
								className={
									activeTab === "Pending Requests"
										? styles.active
										: ""
								}
								onClick={onItemClicked}
							>
								Pending Requests
							</span>
						</div>
					</>
				)}
				<div className={styles.col}>
					<span
						className={
							activeTab === "Your work circle"
								? styles.active
								: ""
						}
						onClick={onItemClicked}
					>
						{canEdit ? "Your work circle" : "Their work circle"}
					</span>
				</div>

				<div className={styles.col}>
					<span
						className={
							activeTab === "Work circles you're in"
								? styles.active
								: ""
						}
						onClick={onItemClicked}
					>
						{canEdit
							? "Work circles you're in"
							: `Work circles ${shortName} is in`}
					</span>
				</div>

				<div className={styles.col}>
					<span
						className={
							activeTab === "People you follow"
								? styles.active
								: ""
						}
						onClick={onItemClicked}
					>
						{canEdit ? "People you follow" : "Following"}
					</span>
				</div>
				<div className={styles.col}>
					<span
						className={
							activeTab === "People who follow you"
								? styles.active
								: ""
						}
						onClick={onItemClicked}
					>
						{canEdit ? "People who follow you" : "Followers"}
					</span>
				</div>
				{canEdit && (
					<>
						<div className={styles.col}>
							<span
								className={
									activeTab === "Favorites"
										? styles.active
										: ""
								}
								onClick={onItemClicked}
							>
								Favorites
							</span>
						</div>
						<div className={styles.col}>
							<span
								className={
									activeTab === "Blocked" ? styles.active : ""
								}
								onClick={onItemClicked}
							>
								Blocked
							</span>
						</div>
					</>
				)}
			</section>
		</ScrollContainer>
	);
};

export default ConnectionScrollableMenu;
