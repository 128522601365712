export const videoToImage = (videoFile, args) => {
	const defaults = {
		frameTimeInSeconds: 0.5,
		extension: "png",
		filename: "",
	};
	const options = { ...defaults, ...args };

	return new Promise((resolve) => {
		const canvas = document.createElement("canvas");
		const video = document.createElement("video");
		const source = document.createElement("source");
		const context = canvas.getContext("2d");

		let urlRef;

		if (typeof videoFile !== "string") {
			urlRef = URL.createObjectURL(videoFile);
		} else {
			urlRef = videoFile;
		}

		video.style.display = "none";
		canvas.style.display = "none";

		source.setAttribute("src", urlRef);
		video.setAttribute("crossorigin", "anonymous");
		video.setAttribute("preload", "metadata");

		video.appendChild(source);
		document.body.appendChild(canvas);
		document.body.appendChild(video);

		if (!context) {
			return;
		}

		video.currentTime = options.frameTimeInSeconds;
		video.load();

		video.addEventListener("loadedmetadata", function () {
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
		});

		video.addEventListener("loadeddata", function () {
			setTimeout(() => {
				context.drawImage(
					video,
					0,
					0,
					video.videoWidth,
					video.videoHeight
				);

				canvas.toBlob((blob) => {
					if (!blob) return;
					resolve(
						new File(
							[blob],
							(options.filename || videoFile.name) +
								"_preview." +
								options.extension,
							{
								type: "image/" + options.extension,
							}
						)
					);
					URL.revokeObjectURL(urlRef);

					video.remove();
					canvas.remove();
				}, "image/" + options.extension);
			}, 2000);
		});
	});
};
