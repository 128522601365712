import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import styles from "./ErrorPage.module.scss";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import routes from "components/Routing/routing-keys";
import { Link, useRouteError } from "react-router-dom";

const ErrorPage = () => {
	const error = useRouteError();

	const isErrorInstance = error instanceof Error;

	return (
		<div className={styles.container}>
			{!isErrorInstance && (
				<div className={styles.background}>
					<p>{error.status}</p>
				</div>
			)}

			<div className={styles.content}>
				{isErrorInstance ? (
					<>
						<h1>Something went wrong!</h1>
						<p>
							Oops! It seems we're experiencing technical
							difficulties. Hang tight with Gaawk!
						</p>
					</>
				) : (
					<>
						<h1>{error.statusText}</h1>
					</>
				)}

				<GaawkLink to={routes.root}>
					<PrimaryButton
						text={"back to gaawk"}
						className={styles.back_btn}
					/>
				</GaawkLink>

				<Link to={routes.help} className={styles.link}>
					Help & Support
				</Link>
			</div>
		</div>
	);
};

export default ErrorPage;
