import { specialtyFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import { useMemo } from "react";

const useFilterAndSort = (
	selectedData,
	dynamicData,
	staticData,
	isFetched,
	identifier = "uuid",
	isGaawkServices = false,
	isSpecialty = false
) => {
	return useMemo(() => {
		if (isFetched) {
			let filtered;

			if (isGaawkServices) {
				filtered = dynamicData?.pages
					.flat()
					.map(
						(item) =>
							item.serviceL3 || item.serviceL2 || item.serviceL1
					)
					.filter(
						(item) =>
							!selectedData
								.map((item) => item.value)
								.includes(item[identifier])
					);
			} else if (isSpecialty) {
				filtered = dynamicData?.pages
					.flat()
					.map((item) => specialtyFormatter(item))
					.filter(
						(item) =>
							!selectedData
								.map((item) => item.value)
								.includes(item[identifier])
					);
			} else {
				filtered = dynamicData?.pages
					.flat()
					.filter(
						(item) =>
							!selectedData
								.map((item) => item.value)
								.includes(item[identifier])
					);
			}

			if (filtered) return [...selectedData, ...filtered];
			return [];
		} else {
			let filtered;

			if (isGaawkServices) {
				filtered = staticData
					.map(
						(item) =>
							item.serviceL3 || item.serviceL2 || item.serviceL1
					)
					.filter(
						(item) =>
							!selectedData
								.map((item) => item.value)
								.includes(item[identifier])
					);
			} else if (isSpecialty) {
				filtered = staticData
					.map((item) => specialtyFormatter(item))
					.filter(
						(item) =>
							!selectedData
								.map((item) => item.value)
								.includes(item[identifier])
					);
			} else {
				filtered = staticData.filter(
					(item) =>
						!selectedData
							.map((item) => item.value)
							.includes(item[identifier])
				);
			}

			return [...selectedData, ...filtered];
		}
	}, [dynamicData, isFetched, selectedData, staticData]);
};

export default useFilterAndSort;
