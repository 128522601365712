import React, { useState, useEffect } from "react";
import styles from "./ProfileTagModal.module.css";
import useApi from "../../hooks/useApi";
import profileApi from "../../api/profile";

import { List } from "react-content-loader";

import GroupMember from "../Chat/GroupMember";

import closeIcon from "images/cross-white-14-x-14.svg";

import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";

const ProfileTagModal = ({ show, tagsArray, onClose, onConfirm }) => {
	const getProfileApi = useApi(profileApi.search);

	const [tagSearchText, setTagSearchText] = useState("");
	const [searchProfile, setSearchProfile] = useState([]);
	const [postTags, setPostTags] = useState([]);

	useEffect(() => {
		if (tagsArray) {
			setPostTags(tagsArray);
		}
	}, [tagsArray, show]);

	const handleTagSearchChange = ({ target }) => {
		setTagSearchText(target.value);
		if (target.value) {
			getProfileApi.request(undefined, undefined, target.value);
		}
	};

	useEffect(() => {
		if (
			!getProfileApi.loading &&
			getProfileApi.data &&
			getProfileApi.responseCode === 200
		) {
			setSearchProfile(getProfileApi.data);
		}
	}, [getProfileApi.data]);

	const LoadingView = () => {
		return (
			<div className={styles.loader_wrapper}>
				<List />
			</div>
		);
	};

	const handleProfileSelected = (profile) => {
		setPostTags((prevState) => [...prevState, profile]);
	};

	const handleRemoveTaggedProfile = (uuid) => {
		setPostTags(postTags.filter((profile) => profile.uuid !== uuid));
	};

	// TODO: USEMEMO !
	const taggedProfiles = postTags.map((profile) => {
		return (
			<div className={styles.item} key={profile.uuid}>
				<label>{profile.name}</label>
				<img
					src={closeIcon}
					alt={"close"}
					onClick={() => handleRemoveTaggedProfile(profile.uuid)}
				/>
			</div>
		);
	});

	const searchProfileCopy = [...searchProfile];

	const filteredProfileList = searchProfileCopy.filter(
		(profile) => !postTags.map((x) => x.uuid).includes(profile.uuid)
	);
	const profileList = filteredProfileList.map((profile) => {
		return (
			<div
				key={profile.uuid}
				className={styles.contact_item}
				onClick={() => handleProfileSelected(profile)}
			>
				<div className={styles.contact_item_wrapper}>
					<GroupMember key={profile.uuid} data={profile} />
				</div>
			</div>
		);
	});

	const handleClose = () => {
		setTagSearchText("");
		setSearchProfile([]);
		setPostTags([]);
		onClose();
	};

	const handleConfirm = () => {
		onConfirm(postTags);
		handleClose();
	};

	return (
		<GaawkModal
			show={show}
			handleClose={handleClose}
			defaultModal={false}
			showHeader={true}
			title={"Tag People / Companies"}
			closeAlign={"left"}
			modalOnTop={true}
			children={
				<div className={styles.create_post_modal_container}>
					<div className={styles.tag_search_input}>
						<TextInput
							placeholder={"Search..."}
							value={tagSearchText}
							onChange={handleTagSearchChange}
						/>
					</div>

					{postTags.length > 0 && (
						<div className={styles.selected_profiles_wrapper}>
							<label>tagged</label>
							<div className={styles.selected_profiles}>
								{taggedProfiles}
							</div>
						</div>
					)}

					<div className={styles.lables}>
						<label>SUGGESTIONS</label>
						<button onClick={handleConfirm}>Done</button>
					</div>

					{searchProfile.length === 0 && getProfileApi.loading && (
						<LoadingView />
					)}
					{searchProfile.length > 0 && (
						<div className={styles.profile_list_wrapper}>
							{profileList}
						</div>
					)}
				</div>
			}
		/>
	);
};

export default ProfileTagModal;
