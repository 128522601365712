import ProfilePic from "../../../Home/ProfilePic";
import deleteIcon from "images/cancel-btn-gray.svg";
import styles from "./KeyPeopleItem.module.css";

const KeyPeopleItem = ({
	title,
	subtitle,
	image,
	onDelete,
	showControls = false,
	onClick,
	cursor = "default",
}) => {
	return (
		<div
			className={styles.keyPerson_item}
			style={{ cursor }}
			onClick={onClick}
		>
			<div className={styles.img_wrapper}>
				{showControls && (
					<button className={styles.delete_button} onClick={onDelete}>
						<img
							src={deleteIcon}
							className={styles.delete_icon}
							alt="delete"
						/>
					</button>
				)}
				<div className={styles.img_container}>
					<ProfilePic
						thumbnail={image}
						w={"100%"}
						h={"100%"}
						type={"USER"}
						name={title}
						letterSize={"30px"}
						enableName={false}
					/>
				</div>
			</div>
			<div className={styles.info_container}>
				<div>{title}</div>
				{subtitle && <div className={styles.position}>{subtitle}</div>}
			</div>
		</div>
	);
};

export default KeyPeopleItem;
