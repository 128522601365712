import client from "./client";

const endpoint = "/hashtags";

const getHashtags = (q) => client.get(endpoint + `?q=${q}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getHashtags,
};
