import styles from "./DeleteButton.module.scss";
import SecondaryButton from "./SecondaryButton";
import { ReactComponent as TrashIcon } from "images/trash-icon.svg";

const DeleteButton = ({ onClick }) => {
	return (
		<SecondaryButton
			text={
				<>
					<TrashIcon />
					Delete
				</>
			}
			className={styles.btn}
			onClick={onClick}
		/>
	);
};

export default DeleteButton;
