import client from "./client";

const endpoint = "/notifications/v2";

const getNotifications = (page, size, category = "ALL") =>
	client.get(endpoint + `?page=${page}&size=${size}&category=${category}`);

const markAsRead = (notificationId) =>
	client.patch(endpoint + `/markRead?notificationId=${notificationId}`);

const markAllAsRead = (category = "ALL") =>
	client.patch(endpoint + `/markAllRead?category=${category}`);

const deleteNotification = (notificationId) =>
	client.delete(endpoint + `?notificationId=${notificationId}`);

const deleteAllNotification = (category = "ALL") =>
	client.delete(endpoint + `/all?category=${category}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getNotifications,
	markAsRead,
	markAllAsRead,
	deleteNotification,
	deleteAllNotification,
};
