import Joi from "joi";

const registrationSchema = Joi.object({
	firstName: Joi.string().required().label("First name").messages({
		"string.empty": "First name should not be empty",
		"string.pattern.base": "First name should not contain numbers",
	}),
	lastName: Joi.string().required().label("Last name").messages({
		"string.empty": "Last name should not be empty",
		"string.pattern.base": "Last name should not contain numbers",
	}),
	city: Joi.object({
		value: Joi.number(),
		label: Joi.string(),
	})
		.required()
		.messages({ "any.required": "Location can't be empty" })
		.label("Location"),
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.label("Email")
		.messages({
			"string.empty": "Email should not be empty",
			"string.email": "Email should be valid",
		}),
	// confirmEmail: Joi.string()
	//     .required()
	//     .valid(Joi.ref("email"))
	//     .label("Confirm email")
	//     .messages({
	//         "any.only": "Does not match the email address",
	//     }),
	day: Joi.object({
		value: Joi.number(),
		label: Joi.string(),
	})
		.required()
		.label("Day"),
	month: Joi.object({
		value: Joi.number(),
		label: Joi.string(),
	})
		.required()
		.label("Month"),
	year: Joi.object({
		value: Joi.number(),
		label: Joi.string(),
	})
		.required()
		.label("Year"),
});

export default registrationSchema;
