import { useState, useEffect, useMemo, useRef } from "react";
import useApi from "../../../hooks/useApi";
import galleryApi from "../../../api/gallery";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import styles from "./GalleryTab.module.css";
import AlbumItem from "./AlbumItem";
import { useNavigate } from "react-router-dom";
import useGalleryCount from "../../../hooks/useGalleryCount";
import {
	getStorageWarningText,
	removeItemsFromArray,
	showStorageWarning,
} from "../../Utils/General";
import CreatePost from "../../Home/CreatePost";
import { toast } from "react-toastify";
import GalleryLoader from "../../Utils/SubComs/CustomLoader/GalleryLoader";
import WarningModal from "../../Utils/GaawkModal/WarningModal";
import CtaButton from "components/Utils/Button/CtaButton";
import GalleryContainer from "components/Utils/SubComs/GalleryContainer/GalleryContainer";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import StorageCard from "components/Storage/StorageComponents/StorageCard";
import useVaultStorage from "hooks/useVaultStorage";

// TODO >> replace fetch logic with react-query

const GalleryTab = ({ canEdit, userInfo }) => {
	const navigate = useNavigate();

	const postRef = useRef(null);

	const getAlbumsApi = useApi(galleryApi.getAlbums);
	const deleteAlbumApi = useApi(galleryApi.deleteAlbum);

	const {
		uuid: profileId,
		firstName,
		lastName,
		profileImage,
		type,
		// url,
	} = userInfo;

	const { data, usedSpaceInPercentage } = useVaultStorage();

	const itemsPerView = useGalleryCount();

	const [albums, setAlbums] = useState([]);
	// console.log("🚀 ~ albums >>", albums);

	const [page, setPage] = useState(0);

	// ! =========================

	const [noResults, setNoResults] = useState(true);

	const loadAlbums = (pageNumber = page) => {
		getAlbumsApi.request(profileId, pageNumber, itemsPerView);
	};

	useEffect(() => {
		// if (albums.length === 0) {
		loadAlbums(0);
		// }
		return () => {
			setPage(0);
			setAlbums([]);
		};
	}, [profileId]);

	useEffect(() => {
		if (
			!getAlbumsApi.loading &&
			getAlbumsApi.data &&
			getAlbumsApi.responseCode === 200
		) {
			const response = getAlbumsApi.data;

			if (response.length === 0) {
				setNoResults(true);
			} else {
				setNoResults(false);
			}

			if (albums.length === 0) {
				setAlbums(response);
			} else {
				setAlbums((prevState) => [...prevState, ...response]);
			}
			setPage((prevState) => prevState + 1);
		}
	}, [getAlbumsApi.data]);

	// ! ========= SHARE ALBUM HANDLER =========
	// * KEEP FOR LATER
	// const handleShareAlbum = (post) => {
	//     if (post.sharedPost) {
	//         postRef.current.sharePost(post.sharedPost);
	//     } else {
	//         postRef.current.sharePost(post);
	//     }
	// };

	// ! ========= DELETE ALBUM HANDLER =========
	const [showWarning, setShowWarning] = useState(false);
	const [albumToDelete, setAlbumToDelete] = useState("");

	const handleDeleteAlbum = (album) => {
		setAlbumToDelete(album);
		setShowWarning(true);
	};

	const handleSubmitDelete = () => {
		deleteAlbumApi.request(albumToDelete.uuid);
		setShowWarning(false);
	};

	useEffect(() => {
		if (!deleteAlbumApi.loading && deleteAlbumApi.responseCode === 200) {
			setAlbums((prevState) =>
				removeItemsFromArray([albumToDelete], prevState)
			);
		}
	}, [deleteAlbumApi.loading, deleteAlbumApi.responseCode]);

	// ! ========================================

	const albumsList = useMemo(
		() =>
			albums.map((album) => (
				<AlbumItem
					key={album.uuid}
					onEdit={() =>
						navigate(`/profile/album/${album.uuid}/edit`, {
							state: album,
						})
					}
					// onDownload={() => alert("DOWNLOAD ALBUM")}
					// onShare={() => handleShareAlbum(album.post)}
					onClick={() => navigate(`/profile/album/${album.uuid}`)}
					onDelete={() => handleDeleteAlbum(album)}
					item={album}
				/>
			)),
		[albums]
	);

	const loadingItems = [...Array(itemsPerView / 2)].map((_, index) => (
		<GalleryLoader key={index} />
	));

	return (
		<ProfileTabContainer
			minHeight={false}
			customStyle={styles.tab_container}
		>
			<div className={styles.button_container}>
				{canEdit && (
					<>
						<CtaButton
							text={"+ ADD A NEW ALBUM"}
							onClick={() => navigate("/profile/album/add")}
							className={styles.add_button}
						/>

						{showStorageWarning(usedSpaceInPercentage, data) && (
							<StorageCard
								text={getStorageWarningText(
									"default",
									usedSpaceInPercentage,
									data
								)}
							/>
						)}
					</>
				)}
			</div>

			<GalleryContainer>
				{albumsList}
				{getAlbumsApi.loading && loadingItems}
			</GalleryContainer>

			{!noResults &&
				albums.length % itemsPerView === 0 &&
				albums.length > 0 && (
					<div className={styles.btn_container}>
						<PrimaryButton
							className={styles.more_btn}
							text={"VIEW MORE"}
							onClick={() => loadAlbums()}
						/>
					</div>
				)}

			<NoResults
				visible={noResults && !getAlbumsApi.loading}
				text={"no results"}
				noPadding={true}
			/>

			<CreatePost
				ref={postRef}
				name={`${firstName} ${lastName}`}
				profileImg={profileImage}
				type={type}
				onCreate={() => toast.success("Post successfully shared!")}
			/>

			<WarningModal
				show={showWarning}
				modalOnTop={true}
				headerText="Are you sure you want to delete this album?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarning(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleSubmitDelete}
			/>
		</ProfileTabContainer>
	);
};

export default GalleryTab;
