import profileApi from "api/profile";
import useApi from "./useApi";
import { useQuery } from "@tanstack/react-query";
import { vaultKeys } from "queryKeys/vault-key-factory";

const useVaultStorage = () => {
	const getVaultInfoApi = useApi(profileApi.getVaultInfo, true, true);

	const fetchVaultInfo = async () => {
		const response = await getVaultInfoApi.request();
		return response.data;
	};

	const {
		isFetching,
		data = {},
		isError,
	} = useQuery({
		queryKey: vaultKeys.storage(),
		queryFn: fetchVaultInfo,
		useErrorBoundary: false,
		staleTime: 0,
	});

	const {
		galleryImage,
		galleryPDF,
		galleryVideo,

		vaultAudio,
		vaultFiles,
		vaultImage,
		vaultPDF,
		vaultVideo,

		vaultSystemAudio,
		vaultSystemFiles,
		vaultSystemImage,
		vaultSystemPDF,
		vaultSystemVideo,

		totalStorage,
	} = data;

	const systemFolderSpace =
		vaultSystemAudio +
		vaultSystemFiles +
		vaultSystemImage +
		vaultSystemPDF +
		vaultSystemVideo;

	const gallerySpace = galleryImage + galleryPDF + galleryVideo;

	const vaultSpace =
		vaultAudio + vaultFiles + vaultImage + vaultPDF + vaultVideo;

	const usedSpace = systemFolderSpace + gallerySpace + vaultSpace;

	const usedSpaceInPercentage = ((usedSpace * 100) / totalStorage).toFixed(2);

	return {
		data,
		isFetching,
		isError,
		totalStorage,
		systemFolderSpace,
		gallerySpace,
		vaultSpace,
		usedSpaceInPercentage,
	};
};

export default useVaultStorage;
