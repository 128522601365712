import styles from "./FileItem.module.css";
// import deleteIcon from "images/icon-exit.svg";
import LazyImage from "../Utils/LazyImage/LazyImage";
import { getFileIcon } from "components/Utils/General";
import { useMemo } from "react";
import useGetFirstFrame from "hooks/useGetFirstFrame";
import { ReactComponent as PlayButton } from "images/play-button.svg";
import { ReactComponent as CrossIcon } from "images/cross-icon.svg";

const FileItem = ({ item, onRemove, isChat = false, p2p }) => {
	const isFile = item instanceof File;

	const name = isFile ? item.name : item.file.originalName;

	const { icon, isPhoto, ext } = useMemo(() => {
		if (isFile) {
			return getFileIcon(item, item.name);
		} else {
			return getFileIcon(item.file, item.file.originalName);
		}
	}, [item, isFile]);

	const isVideo = isFile
		? item.type.startsWith("video")
		: item.file.previewType === "VIDEO";

	const videoThumb = useGetFirstFrame(
		isFile ? item : item.file.customName,
		name,
		isVideo
	);
	// const videoThumb = useGetFirstFrame(item, item.name, isVideo);

	return (
		<div
			className={`${styles.dropped_file_item} ${
				isChat ? styles.isChat : undefined
			}
            ${p2p ? styles.p2p : undefined}
            `}
		>
			{!isChat && (
				// <button className={styles.close} onClick={onRemove}>
				// 	<img src={deleteIcon} alt={"delete"} />
				// </button>
				<button className={styles.close} onClick={onRemove}>
					<CrossIcon width={12} />
				</button>
			)}

			{isVideo ? (
				<div
					className={`${styles.icon}
                ${isChat ? styles.isChat : undefined}
                ${p2p ? styles.p2p : undefined}
                ${styles.video_container}`}
				>
					<PlayButton className={styles.play_button} />
					<LazyImage image={<img src={videoThumb} alt={"icon"} />} />
				</div>
			) : (
				<div
					className={`${styles.icon}
                    ${p2p ? styles.p2p : undefined}
                    ${isChat ? styles.isChat : undefined}
                    ${isPhoto ? undefined : `${styles.full_size}`}`}
				>
					{icon && (
						<LazyImage image={<img src={icon} alt={"icon"} />} />
					)}
				</div>
			)}

			{!isChat && (
				<>
					<p className={styles.name}>{name}</p>
					<p className={styles.type}>{`${ext.toUpperCase()} FILE`}</p>
				</>
			)}
		</div>
	);
};

export default FileItem;
