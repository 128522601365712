import client from "./client";

const endpoint = "/settings";

const getSettings = () => client.get(endpoint);

const updateMessagesSettings = (q) =>
    client.put(endpoint + `/messages?allowFrom=${q}`);

const updateMentionsSettings = (q) =>
    client.put(endpoint + `/mentions?allowFrom=${q}`);

const updateGroupSettings = (q) =>
    client.put(endpoint + `/groups?allowFrom=${q}`);

const updateTagsSettings = (q) => client.put(endpoint + `/tags?allowFrom=${q}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getSettings,
    updateMessagesSettings,
    updateMentionsSettings,
    updateGroupSettings,
    updateTagsSettings,
};
