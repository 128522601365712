import client from "./client";

const endpoint = "/conversation";

/**
 * Conversations
 */

const getConversations = (page, size) =>
	client.get(endpoint + `/conversation?page=${page}&size=${size}`);

const getConversation = (convId) =>
	client.get(endpoint + `/conversation/get?conversationId=${convId}`);

const getFreqConversations = () => client.get(endpoint + "/conversation/freq");

const forwardMessage = (convId, fwdMsgId, msgId) =>
	client.post(
		endpoint +
			`/message/forward?conversationId=${convId}&forwardMessageId=${fwdMsgId}&messageId=${msgId}`
	);

const getMessageDetails = (msgId) =>
	client.get(endpoint + `/message/details?messageId=${msgId}`);

const muteConv = (convId, duration) =>
	client.put(endpoint + `/mute?conversationId=${convId}&hours=${duration}`);

const unmuteConv = (convId) =>
	client.delete(endpoint + `/mute?conversationId=${convId}`);

const deleteMessages = (selectedConvId, msgItems) => {
	const messagesIdsString = msgItems
		.map((msg) => `messageIds=${msg.messageId}`)
		.join("&");
	return client.delete(
		endpoint +
			`/message?conversation=${selectedConvId}&${messagesIdsString}`
	);
};

const deleteMessagesForAll = (selectedConvId, msgItems) => {
	const messagesIdsString = msgItems
		.map((msg) => `messageIds=${msg.messageId}`)
		.join("&");
	return client.delete(
		endpoint +
			`/message/forAll?conversation=${selectedConvId}&${messagesIdsString}`
	);
};

const clearConversation = (selectedConvId) =>
	client.delete(endpoint + `/clear?conversationId=${selectedConvId}`);

const deleteConversation = (convId) =>
	client.delete(endpoint + `?conversationId=${convId}`);

const exportConversation = (conversationId, includeMedia = false) =>
	client.get(
		endpoint +
			`/export?conversationId=${conversationId}&includeMedia=${includeMedia}`
	);

const deleteExportedConversation = (fileId) =>
	client.delete(endpoint + `/export?fileId=${fileId}`);

/**
 * Media
 */

const getMedia = (convId, page, size) =>
	client.get(
		endpoint +
			`/messages/minified/media?conversationId=${convId}&page=${page}&size=${size}`
	);

/**
 * Messages
 */
const getMessages = (conversationId, page, size) =>
	client.get(
		endpoint +
			`/messages/minified?conversationId=${conversationId}&page=${page}&size=${size}`
	);

const sendMessage = (message, onUploadProgress) => {
	const data = new FormData();

	data.append("messageId", message.id);
	data.append("messageText", message.text);
	// if (message.text) data.append("messageText", message.text);

	if (message.newConversationId)
		data.append("newConversationId", message.newConversationId);
	if (message.parentMessageId)
		data.append("parentMessageId", message.parentMessageId);
	if (message.toGroup) data.append("toGroup", message.toGroup);
	if (message.toId) data.append("toId", message.toId);
	if (message.attachedProfileId)
		data.append("attachedProfileId", message.attachedProfileId);
	if (message.latitude) data.append("location.latitude", message.latitude);
	if (message.longitude) data.append("location.longitude", message.longitude);
	if (message.locationTitle)
		data.append("location.title", message.locationTitle);
	if (message.locationType)
		data.append("location.type", message.locationType);

	if (message.file) data.append("multipartFile", message.file);

	return client.post(endpoint + "/messages/send", data, {
		onUploadProgress: (progress) =>
			onUploadProgress(progress.loaded / progress.total),
	});
};

/**
 * Groups
 */

const createGroup = (
	groupName,
	groupDescription,
	groupImg,
	groupImgName,
	groupParticipants
) => {
	const data = new FormData();

	if (groupName !== "") {
		data.append("name", groupName);
	}
	if (groupDescription !== "") {
		data.append("description", groupDescription);
	}
	if (groupImg) {
		data.append("imageFile", groupImg, groupImgName);
	}

	for (let i = 0; i < groupParticipants.length; i++) {
		data.append(`profileIds[${i}]`, groupParticipants[i].uuid);
	}

	return client.post(endpoint + "/group", data);
};

const editGroup = (groupId, groupName, description, groupImg, imgName) => {
	// const { groupName, description, groupImg, groupId } = group;
	const data = new FormData();

	if (groupName !== "") {
		data.append("name", groupName);
	}
	if (description !== "") {
		data.append("description", description);
	}
	if (groupImg) {
		data.append("imageFile", groupImg, imgName);
	}

	return client.put(endpoint + `/group?groupId=${groupId}`, data);
};

const searchParticipants = (groupId, q) =>
	client.get(
		endpoint + `/group/participants/search?groupId=${groupId}&q=${q}`
	);

const getParticipants = (groupId, page, size, type) =>
	client.get(
		endpoint +
			`/group/participants?groupId=${groupId}&page=${page}&size=${size}&type=${type}`
	);

const addParticipants = (groupId, profileIds) => {
	const profileIdsString = profileIds
		.map((userId) => `profileIds=${userId}`)
		.join("&");
	return client.post(
		endpoint + `/group/participants?groupId=${groupId}&${profileIdsString}`
	);
};

const deleteParticipants = (groupId, profileIds) => {
	const profileIdsString = profileIds
		.map((userId) => `profileIds=${userId}`)
		.join("&");
	return client.delete(
		endpoint + `/group/participants?groupId=${groupId}&${profileIdsString}`
	);
};

const assignAdmin = (assigneeId, groupId) =>
	client.patch(
		endpoint +
			`/group/assignAdmin?assigneeId=${assigneeId}&groupId=${groupId}`
	);

const revokeAdmin = (assigneeId, groupId) =>
	client.patch(
		endpoint +
			`/group/revokeAdmin?assigneeId=${assigneeId}&groupId=${groupId}`
	);

const exitGroup = (groupId) =>
	client.delete(endpoint + `/group/exit?groupId=${groupId}`);

/**
 * Search
 */

const searchConversations = (query, page, size) =>
	client.get(endpoint + `/search?q=${query}&page=${page}&size=${size}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getConversations,
	getConversation,
	getFreqConversations,
	searchConversations,
	forwardMessage,
	getMessages,
	sendMessage,
	getMessageDetails,
	muteConv,
	unmuteConv,
	deleteMessages,
	deleteMessagesForAll,
	clearConversation,
	deleteConversation,
	exportConversation,
	deleteExportedConversation,
	createGroup,
	editGroup,
	searchParticipants,
	getParticipants,
	addParticipants,
	deleteParticipants,
	assignAdmin,
	revokeAdmin,
	getMedia,
	exitGroup,
};
