import heightIcon from "images/height-icon.png";
import weightIcon from "images/weight-icon.png";
import buildIcon from "images/build-icon.svg";
import eyecolorIcon from "images/eyecolor-icon.svg";
import hairtypeIcon from "images/hairtype-icon.svg";
import haircolorIcon from "images/haircolor-icon.svg";
import hairlengthIcon from "images/hairlength-icon.svg";
import waistIcon from "images/waist-icon.svg";
import chestIcon from "images/chest-icon.svg";
import shoeIcon from "images/shoe-icon.svg";
import {
	buildTypes,
	eyeHairColor,
	hairStyle as hairType,
	hairLength as hairLengthType,
} from "../special-types";
import SpecItem from "../../../../Utils/SubComs/SpecItem/SpecItem";

const PhysicalDetails = ({ data }) => {
	const {
		buildType,
		chest,
		eyeColor,
		hairColor,
		hairLength,
		hairStyle,
		height,
		shoe,
		waist,
		weight,
	} = data;

	return (
		<>
			{height > 0 && (
				<SpecItem
					icon={heightIcon}
					title={"Height"}
					content={`${height} cm`}
				/>
			)}

			{weight > 0 && (
				<SpecItem
					icon={weightIcon}
					title={"Weight"}
					content={`${weight} kg`}
				/>
			)}

			{buildType && (
				<SpecItem
					icon={buildIcon}
					title={"Build"}
					content={
						buildTypes.find((item) => item.value === buildType)
							?.label
					}
				/>
			)}

			{eyeColor && (
				<SpecItem
					icon={eyecolorIcon}
					title={"Eye Color"}
					content={
						eyeHairColor.find((item) => item.value === eyeColor)
							?.label
					}
				/>
			)}

			{hairStyle && (
				<SpecItem
					icon={hairtypeIcon}
					title={"Hair Type"}
					content={
						hairType.find((item) => item.value === hairStyle)?.label
					}
				/>
			)}

			{hairColor && (
				<SpecItem
					icon={haircolorIcon}
					title={"Hair Color"}
					content={
						eyeHairColor.find((item) => item.value === hairColor)
							?.label
					}
				/>
			)}

			{hairLength && (
				<SpecItem
					icon={hairlengthIcon}
					title={"Hair Length"}
					content={
						hairLengthType.find((item) => item.value === hairLength)
							?.label
					}
				/>
			)}

			{waist > 0 && (
				<SpecItem
					icon={waistIcon}
					title={"Waist"}
					content={`${waist} cm`}
				/>
			)}

			{chest > 0 && (
				<SpecItem
					icon={chestIcon}
					title={"Chest"}
					content={`${chest} cm`}
				/>
			)}

			{shoe > 0 && (
				<SpecItem icon={shoeIcon} title={"Shoe Size"} content={shoe} />
			)}
		</>
	);
};

export default PhysicalDetails;
