import { useCollapse } from "react-collapsed";
import styles from "./AccordionItem.module.css";

import arrowIcon from "images/dropdown_arrow_white_14_x_10.svg";
import arrowIconGray from "images/dropdown-arrow-gray.svg";
import CustomRadioButton from "../SubComs/CustomRadioButton/CustomRadioButton";
import { useRef } from "react";
import { Link } from "react-router-dom";

const AccordionItem = ({
	title,
	content,
	isOpen,
	onToggle,
	itemKey,
	className,
	showArrow = true,
	level = undefined,
	radioMode,
	onRadioSelect,
	selectedItem,
	button = undefined,
	showServicesWithProducts,
	disableNav = false,
	ownerKey,
}) => {
	const accordionRef = useRef(null);
	const radioRef = useRef(null);

	const config = {
		isExpanded: isOpen,
		duration: 0,
		// easing: "cubic-bezier(.93,.12,.42,.25)",
	};

	// console.log(accordionRef);

	const { getCollapseProps, getToggleProps, isExpanded } =
		useCollapse(config);

	const isSelected = selectedItem === itemKey;

	return (
		<>
			<div
				role="button"
				className={`${styles.header} ${className} ${
					isSelected ? styles.active : undefined
				} ${disableNav ? styles.disabled : undefined}`}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						accordionRef.current.click();
					}
				}}
				{...getToggleProps({
					onClick: () => onToggle(itemKey),
					ref: accordionRef,
				})}
			>
				{!radioMode ? (
					<Link
						to={
							showServicesWithProducts
								? `/services/${itemKey}/products`
								: `/services/${itemKey}`
						}
						// to={{
						//   pathname: showServicesWithProducts
						//     ? `/services/${itemKey}/products`
						//     : `/services/${itemKey}`,
						//   state: { ownerId: ownerKey },
						// }}
						onClick={(e) => {
							if (disableNav) {
								e.preventDefault();
								onToggle(itemKey);
							}
							e.stopPropagation();
						}}
					>
						{title}
					</Link>
				) : (
					title
				)}
				<div className={styles.right_side}>
					{radioMode && (
						<button
							type="button"
							onClick={() => radioRef.current.click()}
						>
							<CustomRadioButton
								ref={radioRef}
								onChange={() => onRadioSelect(itemKey)}
								name="subCategory"
								customStyle={
									level === 1
										? styles.radio_level_1
										: undefined
								}
								checked={isSelected}

								// {...register("category")}
								// value="SUB"
								// id="SUB"
								// readOnly={true}
								// disabled={!hasServices}
							/>
						</button>
					)}
					{showArrow && (
						<img
							src={
								level === 2 || isSelected
									? arrowIconGray
									: arrowIcon
							}
							className={`${styles.arrow} ${
								isExpanded ? styles.rotate : undefined
							}`}
							alt=""
						/>
					)}
					{button && button}
				</div>
			</div>
			{isExpanded && <div {...getCollapseProps()}>{content}</div>}
		</>
	);
};

export default AccordionItem;
