import { useState } from "react";
import { removeItemIdFromArray } from "../components/Utils/General";

const usePaginatedData = (api, itemsPerPage = 10, optionalId = false) => {
    const { request, loading } = api;

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);

    const [hasMore, setHasMore] = useState(false);

    const loadMore = async (id, pageNumber = page, ...rest) => {
        let args;
        if (id || optionalId) {
            args = [id, pageNumber, itemsPerPage, ...rest];
        } else {
            args = [pageNumber, itemsPerPage, ...rest];
        }

        const response = await request(...args);

        if (response.status === 200) {
            if (
                response.data.length > 0 &&
                response.data.length % itemsPerPage === 0
            ) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            setPage(pageNumber + 1);
            setData((prevState) => [...prevState, ...response.data]);
        }
    };

    const reload = async (id, pageNumber = page, ...rest) => {
        let args;
        if (id || optionalId) {
            args = [id, pageNumber - 1, itemsPerPage, ...rest];
        } else {
            args = [pageNumber - 1, itemsPerPage, ...rest];
        }

        const response = await request(...args);

        if (response.status === 200) {
            if (
                response.data.length > 0 &&
                response.data.length % itemsPerPage === 0
            )
                setHasMore(true);
            else setHasMore(false);

            setData((prevState) => [
                ...prevState,
                ...removeItemIdFromArray(prevState, response.data),
            ]);
        }
    };

    return {
        data,
        hasMore,
        setPage,
        page,
        setData,
        loadMore,
        reload,
        loading,
    };
};

export default usePaginatedData;
