import styles from "./NotificationBubble.module.scss";
import LazyImage from "../LazyImage/LazyImage";

const NotificationBubble = ({ notification, body, title, onClick }) => {
	const { from } = notification || {};

	return (
		<div className={styles.container} onClick={onClick}>
			<div
				className={`${styles.profile_pic}
                ${from?.type === "USER" ? styles.user : styles.company}
                `}
			>
				{from?.profileImage?.image && (
					<LazyImage
						image={<img src={from.profileImage.image} alt="" />}
					/>
				)}
			</div>

			<div className={styles.notification_body}>
				<h4>{title}</h4>
				<div>{body}</div>
			</div>
		</div>
	);
};

export default NotificationBubble;
