import useApi from "./useApi";
import locationApi from "../api/location";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";
import useUserLocation from "./useUserLocation";

const PAGE = 0;
const PAGE_SIZE = 50;
export const useGetLocationsList = () => {
	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const [searchInput, setSearchInput] = useState("");
	const { userCoordinates } = useUserLocation();
	const query = useDebounce(searchInput);
	const getLocationsApi = useApi(locationApi.locationSearch, true, true);

	const fetchLocations = async ({ pageParam = PAGE, queryKey }) => {
		const [, lat, lng, query] = queryKey;
		const response = await getLocationsApi.request(
			pageParam,
			PAGE_SIZE,
			lat,
			lng,
			query
		);
		return response.data;
	};

	const {
		data: locations,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"LocationsList",
			userCoordinates?.lat,
			userCoordinates?.lng,
			query,
		],
		queryFn: fetchLocations,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage?.length === PAGE_SIZE ? pages.length : undefined;
			return nextPage;
		},
		refetchOnWindowFocus: false,
	});

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};
	const handleClearSearch = () => {
		setSearchInput("");
	};

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, inView]);

	return {
		viewRef,
		handleSearchInputChange,
		handleClearSearch,
		locations,
		isFetching,
		isLoading,
		searchInput,
	};
};
