import { formatBytes } from "../General";
import styles from "./StorageBar.module.scss";
import { ReactComponent as FolderIcon } from "images/vault-storage-folder.svg";

const StorageBar = ({ total, system, gallery, vault }) => {
	const usedSpace = formatBytes(system + gallery + vault);

	const systemPercentage = (system / total) * 100;
	const galleryPercentage = ((gallery + system) / total) * 100;
	const vaultPercentage = ((vault + gallery + system) / total) * 100;

	return (
		<div className={styles.storageBar}>
			<p>
				<FolderIcon />
				{`${usedSpace} / ${formatBytes(total)}`}
			</p>

			<div className={styles.barContainer}>
				<div
					className={`${styles.barSegment} ${styles.system}`}
					style={{ width: `${systemPercentage}%` }}
				/>
				<div
					className={`${styles.barSegment} ${styles.gallery}`}
					style={{ width: `${galleryPercentage}%` }}
				/>
				<div
					className={`${styles.barSegment} ${styles.vault}`}
					style={{ width: `${vaultPercentage}%` }}
				/>
			</div>

			<div className={styles.legend}>
				<span className={styles.systemLegend}>System folder</span>
				<span className={styles.galleryLegend}>Gallery</span>
				<span className={styles.vaultLegend}>Vault folder</span>
			</div>
		</div>
	);
};

export default StorageBar;
