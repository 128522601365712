import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "activeTab",
	initialState: {
		profileTab: "posts",
		experienceModal: false,
		talentModal: false,
		connectionTab: "Friends",
	},

	reducers: {
		updateProfileTab: (state, action) => {
			state.profileTab = action.payload;
		},
		updateConnectionTab: (state, action) => {
			state.connectionTab = action.payload;
		},
		updateExperienceModal: (state, action) => {
			state.experienceModal = action.payload;
		},
		updateTalentModal: (state, action) => {
			state.talentModal = action.payload;
		},
	},
});

export const {
	updateProfileTab,
	updateConnectionTab,
	updateExperienceModal,
	updateTalentModal,
} = slice.actions;
export default slice.reducer;
