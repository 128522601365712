import styles from "./UserVault.module.css";

import newFolder from "images/new-folder-icon.svg";
import newFile from "images/upload-file-icon.svg";
import { useNavigate, useParams } from "react-router-dom";

const VaultUserButtons = ({ onCreate, vaultTab }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	if (vaultTab !== "files" && !id) return null;

	return (
		<div className={styles.right_side_buttons}>
			<button onClick={onCreate}>
				<img src={newFolder} alt="create a folder" />
			</button>

			<button
				onClick={() => navigate(`/vault/uploader${id ? `/${id}` : ""}`)}
			>
				<img src={newFile} alt="upload a file" />
			</button>
		</div>
	);
};

export default VaultUserButtons;
