import React, { useEffect, useMemo, useRef, useState } from "react";
import profileApi from "../../../api/profile";
// import useApi from "../../../hooks/useApi";
// import useFilteredData from "../../../hooks/useFilteredData";
import Filter from "../../Utils/Filter/Filter";
import Tag from "../../Utils/SubComs/Tags/Tag";
import AgencyDynamicFilters from "./AgencyDynamicFilters";
import styles from "../../Utils/Filter/Filter.module.css";
import CompanyItem from "../../Companies/CompanyItem";
import { Link, useLocation } from "react-router-dom";
import FilterLeftSide from "../../Profile/TalentTab/FilterLeftSide";
import FilterRightSide from "../../Profile/TalentTab/FilterRightSide";
import useWindowSize from "../../../hooks/useWindowSize";
import { isPlainObject } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { resetFilters, updateFilters } from "store/slices/search";
import useGlobalSearch from "hooks/useGlobalSearch";
import { agenciesInitialFilters } from "components/Search/initialFilters";
// import useSearchFilters from "hooks/useSearchFilters";
import useUserLocation from "hooks/useUserLocation";
import useAgencyFilters from "./useAgencyFilters";
import { useInView } from "react-intersection-observer";

const AgencyFilterView = () => {
	const leftSideRef = useRef();

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const [filterModal, setFilterModal] = useState();

	const { userCoordinates } = useUserLocation();

	const { ref: viewRef, inView } = useInView();

	useEffect(() => {
		// closes the filter modal if opened in mobile,
		// and then switching to a desktop breakpoint
		if (filterModal && !isMobile) setFilterModal(false);
	}, [isMobile, filterModal]);

	// const searchAgencyApi = useApi(profileApi.searchAgency, true);
	// const getAgencyFiltersApi = useApi(profileApi.getAgencyFilters);
	const [selectedDynamicFilters, setSelectedDynamicFilters] = useState({});

	const handleDynamicFilters = (selectedFilters) => {
		setSelectedDynamicFilters(selectedFilters);
		const formatted = formatValues(selectedFilters);
		dispatch(updateFilters(formatted));
	};

	// const {
	// 	initialFilters,
	// 	data,
	// 	isFetching,
	// 	hasFilters,
	// 	selectedFilters,
	// 	setSelectedFilters,
	// } = useSearchFilters(
	// 	agenciesInitialFilters,
	// 	{ locationIds: [userCoordinates.id] },
	// 	"agenciesFilters",
	// 	"",
	// 	profileApi.getAgencyFilters,
	// 	selectedDynamicFilters,
	// 	handleDynamicFilters
	// );

	const {
		initialFilters,
		data,
		// isFetching,
		hasFilters,
		// selectedFilters,
		setSelectedFilters,
	} = useAgencyFilters(
		agenciesInitialFilters,
		userCoordinates,
		"agenciesFilters",
		profileApi.getAgencyFilters,
		selectedDynamicFilters,
		handleDynamicFilters
	);

	const {
		data: agencies,
		hasNextPage,
		fetchNextPage,
		// isLoading,
		// isFetching: isFetchingAgencies,
	} = useGlobalSearch(
		agenciesInitialFilters,
		// userCoordinates,
		{ locationIds: [userCoordinates.id] },
		["searchAgencies"],
		"",
		profileApi.searchAgency
	);

	const handleClearFields = () => {
		if (hasFilters) {
			setSelectedFilters(initialFilters);
			// roleRef.current?.clear();
			// skillRef.current?.clear();
			// softwareRef.current?.clear();
			// languageRef.current?.clear();
			handleDynamicFilters(initialFilters);
		}
	};

	// const {
	// filters,
	//     handleLoadData,
	//     handleLoadFilters,
	//     data: agencies,
	//     selectedDynamicFilters,
	//     hasMore,
	//     loading,
	//     dynamicFilters,
	//     handleResetFilters,
	//     handleDynamicFilters,
	// } = useFilteredData(searchAgencyApi, getAgencyFiltersApi);

	// useEffect(() => {
	//     handleLoadData(undefined, 0);
	//     handleLoadFilters();
	// }, [filters]);

	const dispatch = useDispatch();

	const formatValues = (toBeFormatted) => {
		const formattedValues = {};

		for (const [key, values] of Object.entries(toBeFormatted)) {
			if (isPlainObject(values)) {
				formattedValues[key] = formatValues(values);
			} else if (!Array.isArray(values)) {
				formattedValues[key] = values;
			} else if (values.length > 0) {
				formattedValues[key] = values.map((item) => item.value);
			} else {
				formattedValues[key] = [];
			}
		}
		return formattedValues;
	};

	const { pathname } = useLocation();

	useEffect(() => {
		if (!pathname.startsWith("/search")) dispatch(resetFilters());
	}, [dispatch, pathname]);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	const agenciesList = useMemo(
		() =>
			agencies?.pages?.map((page) =>
				page?.map((agency, i) => (
					<Link
						to={`/profile/${agency.url}`}
						className={styles.item_link}
						key={agency.uuid}
						ref={page.length === i + 1 ? viewRef : null}
					>
						<CompanyItem
							customStyle={styles.clickable}
							item={agency}
							subdiv={
								<>
									<div className={styles.item_tagLine}>
										{agency.tagLine}
									</div>
									<div className={styles.item_location}>
										{`${agency.locationDTO.name}, ${agency.locationDTO.countryDTO.name}`}
									</div>
								</>
							}
						/>
					</Link>
				))
			),
		[agencies]
	);

	const handleDeleteTag = (key, tag) => {
		leftSideRef.current.updateDynamicFilters(key, tag);
	};

	const tagsList = useMemo(() => {
		const valuesList = [];
		for (const [k, v] of Object.entries(selectedDynamicFilters)) {
			if (Array.isArray(v) && v?.length > 0 && k !== "locationIds") {
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.label}
							itemName={`${item.label}`}
							onRemove={() => handleDeleteTag(k, item)}
							customStyle={styles.filter_tag}
						/>
					)
				);
			}
		}
		return valuesList;
	}, [selectedDynamicFilters]);

	return (
		<Filter
			title="Agencies"
			isMobile={isMobile}
			onCloseModal={() => setFilterModal(false)}
			hiddenMode={!filterModal}
			leftSideChildren={
				<FilterLeftSide
					ref={leftSideRef}
					onResetFilter={handleClearFields}
					leftSideChildren={[
						<AgencyDynamicFilters
							id="dynamicFilter"
							filters={data}
							// filters={dynamicFilters}
							onChange={handleDynamicFilters}
							selectedDynamicFilters={selectedDynamicFilters}
						/>,
					]}
				/>
			}
			rightSideChildren={
				<FilterRightSide
					data={agenciesList}
					title="Agencies"
					tagsList={tagsList}
					isMobile={isMobile}
					onShowModal={() => setFilterModal(true)}
					// hasMore={hasMore}
					// loading={loading}
					// onLoad={() =>
					// 	handleLoadData(undefined, undefined, undefined, true)
					// }
				/>
			}
		/>
	);
};

export default AgencyFilterView;
