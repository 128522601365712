import { useNavigate } from "react-router-dom";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import VaultFileUploader from "../../components/Vault/VaultFileUploader";
import BackButton from "../../components/Utils/Button/BackButton";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import { featuresSection } from "components/Utils/General";

const VaultUpload = () => {
	const navigate = useNavigate();

	return (
		<DefaultPage
			defaultHeader={false}
			headerRightContent={<BackButton onBack={() => navigate(-1)} />}
			leftSideChildren={
				<FeaturesSidebar active={featuresSection.vault} />
			}
			rightSideChildren={<VaultFileUploader />}
		/>
	);
};

export default VaultUpload;
