import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styles from "./ProductsPage.module.scss";
import useUserLocation from "hooks/useUserLocation";
import CityModal from "components/Boards/BoardsComponents/CityModal";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { productsKeys } from "queryKeys/products-key-factory";
import useApi from "hooks/useApi";

import productApi from "api/product";
import ProductItem from "components/Profile/ServicesTab/ServicesComponent/ProductItem";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import { useProductsSearch } from "components/Profile/ProductsTab/hooks";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const itemsPerPage = 2;

const ProductsPage = forwardRef(({ onFlagChange }, ref) => {
	//! ========= GETTING USER COORDINATES ============

	const [showModal, setShowModal] = useState(false);

	useImperativeHandle(ref, () => ({
		showModal() {
			setShowModal(true);
		},
	}));

	const { userCoordinates, handleSelectedCity } =
		useUserLocation(onFlagChange);

	// ! ======== SEARCH =============
	const {
		_handleClearSearch,
		handleSearchInputChange,
		isFetching: isFetchingSearchProducts,
		products: searchedProducts,
		ref: searchRef,
		searchInput,
	} = useProductsSearch({}, true);

	const nearbyProductsApi = useApi(productApi.getProductNearby, true, true);

	const fetchNearbyProducts = async ({ pageParam = 0, signal }) => {
		const response = await nearbyProductsApi.request(
			pageParam,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
		queryKey: productsKeys.nearby(),
		queryFn: fetchNearbyProducts,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	const productsList = (searchInput ? searchedProducts : data)?.pages?.map(
		(page) =>
			(searchInput ? page.list : page)?.map((product) => {
				return (
					<div
						ref={searchInput ? searchRef : viewRef}
						key={product.uuid}
					>
						<ProductItem optionsIcon={false} item={product} />
					</div>
				);
			})
	);

	return (
		<>
			<div className={styles.search_input_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={_handleClearSearch}
					border={false}
					placeholder={"Search Products"}
				/>
			</div>
			<div className={styles.container}>
				<h3>{searchInput ? "Search results" : "Products Nearby"}</h3>

				<NoResults
					visible={productsList && productsList[0]?.length === 0}
					isSearch={searchInput}
					text={
						searchInput
							? "No results related to your search"
							: "No results"
					}
					noPadding={true}
				/>

				<div className={styles.items_wrapper}>{productsList}</div>
				<LoadingSpinner
					visible={isFetching || isFetchingSearchProducts}
				/>
				{showModal && (
					<CityModal
						show={showModal}
						onClose={() => setShowModal(false)}
						onSelectCity={handleSelectedCity}
					/>
				)}
			</div>
		</>
	);
});

export default ProductsPage;
