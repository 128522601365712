import Joi from "joi";

const productSchema = Joi.object({
	name: Joi.string().required().max(30).label("Name").messages({
		"string.empty": "Product Name can't be empty",
		"string.max": "Product Name can't be more than 30 characters",
	}),
	worldWide: Joi.boolean(),
	locationIds: Joi.when("worldWide", {
		is: Joi.boolean().valid(true),
		then: Joi.array(),
		otherwise: Joi.array().required().min(1).messages({
			"array.min": "Location can't be empty",
		}),
	}),
	description: Joi.string()
		.required()
		.label("Description")
		.max(5000)
		.messages({
			"string.empty": "Description can't be empty",
			"string.max":
				"Product Description can't be more than 5000 characters",
		}),
	image: Joi.array().min(1).label("Main Image").messages({
		"array.min": "An image is required",
	}),
	otherImages: Joi.array()
		.optional()
		.max(5)
		.label("Additional Images")
		.messages({
			"array.max": "Can't add more than 5 additional images",
		}),
	brand: Joi.string().allow("").label("Brand"),
	model: Joi.string().allow("").label("Model"),
	colors: Joi.array().label("Colors"),
	materials: Joi.array().label("Materials"),
	length: Joi.number()
		.allow("")
		.label("Length")
		.messages({ "number.base": "Length must be a number" }),
	width: Joi.number()
		.allow("")
		.label("Width")
		.messages({ "number.base": "Width must be a number" }),
	height: Joi.number()
		.allow("")
		.label("Height")
		.messages({ "number.base": "Height must be a number" }),
	weight: Joi.number()
		.allow("")
		.label("Weight")
		.messages({ "number.base": "Weight must be a number" }),
	serviceTags: Joi.array().label("Service Tags"),
	lengthUnit: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	}),
	widthUnit: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	}),
	heightUnit: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	}),
	weightUnit: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	}),
});

export default productSchema;
