import Feeds from "./Feeds";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import HomeRightSidebar from "components/Home/Components/HomeRightSidebar/HomeRightSidebar";
import ThreeColumnPage from "components/Utils/PageArchs/ThreeColumnPage/ThreeColumnPage";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";

const Index = () => {
	return (
		<ThreeColumnPage
			leftSideChildren={<HomeLeftSidebar />}
			centerChildren={
				<FeedContainer>
					<Feeds />
				</FeedContainer>
			}
			rightSideChildren={<HomeRightSidebar />}
		/>
	);
};

export default Index;
