import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ContactCard.module.css";
import contactIcon from "images/contact-icon.svg";
import ProfilePic from "../Home/ProfilePic";

const ContactCard = ({ isReply = false, profile }) => {
	const { name, type, tagLine, url } = profile;
	const navigate = useNavigate();

	return (
		<div className={styles.contact_container}>
			<div className={styles.profile_pic_container}>
				<ProfilePic
					thumbnail={
						profile.profileImage
							? profile.profileImage.image
							: undefined
					}
					w={40}
					h={40}
					type={type}
					name={name}
					createMode={true}
					subdata={
						isReply ? (
							<div className={styles.sublabel}>
								<img src={contactIcon} alt="contact" />
								Profile
							</div>
						) : (
							tagLine
						)
					}
				/>
			</div>
			{!isReply && (
				<>
					<hr />
					<span
						className={styles.link}
						onClick={() => navigate(`/profile/${url}`)}
					>
						View profile
					</span>
				</>
			)}
		</div>
	);
};

export default ContactCard;
