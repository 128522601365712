import { useState, useRef } from "react";
import styles from "./AgencyEntry.module.css";

import GroupMember from "../../../../../Chat/GroupMember";
import Popover from "../../../../../Utils/Popover/Popover";
import useDirectMessage from "../../../../../../hooks/useDirectMessage";
import optionIcon from "images/three-dots-black-5-x-20.svg";
import chatIcon from "images/message-icon-black.svg";
import deleteIcon from "images/delete-black.svg";
import GaawkModal from "../../../../../Utils/GaawkModal/GaawkModal";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";

const AgencyEntry = ({
	data,
	canEdit,
	onClick,
	onDelete,
	disabled = false,
}) => {
	const popoverRef = useRef();

	const { handleDirectMessage } = useDirectMessage();

	const [selectedAgency, setSelectedAgency] = useState();
	const [deleteModal, setDeleteModal] = useState(false);

	const agenciesList = data.map((item) => (
		<GroupMember
			key={item.uuid}
			redirect={true}
			data={item}
			rightSideAction={
				canEdit && (
					<Popover
						ref={popoverRef}
						render={
							<div className="popover_container">
								<div
									className="popover_item"
									onClick={() => handleDirectMessage(item)}
								>
									<img src={chatIcon} alt="chat" />
									<span>Message Agency</span>
								</div>

								<div
									className="popover_item"
									onClick={() => {
										popoverRef.current.closePopover();
										setSelectedAgency(item);
										setDeleteModal(true);
									}}
								>
									<img src={deleteIcon} alt="delete" />
									<span>De-Link Agency</span>
								</div>
							</div>
						}
					>
						<button onClick={(e) => e.stopPropagation()}>
							<img src={optionIcon} alt="options" />
						</button>
					</Popover>
				)
			}
		/>
	));

	return (
		<>
			<div className={styles.container}>
				{canEdit && (
					<>
						<div>
							Lorem ipsum dolor sit amet, consetetur sadipscing
							elitr, sed diam nonumy eirmod tempor invidunt ut
							labore et dolore magna aliquyam erat, sed diam
							voluptua. At vero eoset justo duo dolores et ea
							rebum. consetetur sadipscing elitr, sed diam nonumy
							eirmod tempor invidunt ut labore et dolore magna
							aliquyam erat, sed diam voluptua. At vero eoset
							justo duo dolores et ea rebum.
						</div>
						<div className={styles.emptyBtn}>
							<button disabled={disabled} onClick={onClick}>
								LINK WITH AN AGENCY
							</button>
						</div>
					</>
				)}
				<h3>Agencies linked with:</h3>
				<div>{agenciesList}</div>
			</div>

			<GaawkModal
				show={deleteModal}
				handleClose={() => setDeleteModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"De-Link Agency"}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.info_text}>
							Are you sure you want to de-link yourself from{" "}
							<b>{selectedAgency?.name}</b>. This action can NOT
							be undone.
						</div>

						<div className={styles.btn_container}>
							<PrimaryButton
								text="delete"
								type="button"
								className={styles.btn}
								onClick={() => {
									setDeleteModal(false);
									onDelete(selectedAgency);
								}}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default AgencyEntry;
