import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "./Notifications.module.scss";
import BackButton from "components/Utils/Button/BackButton";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import { useNavigate } from "react-router-dom";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import { useMemo, useRef, useState } from "react";
import Popover from "components/Utils/Popover/Popover";
import optionIcon from "images/three-dots-white-5-x-20.svg";
import { ReactComponent as ClearIcon } from "images/clear-icon-gray.svg";
import { ReactComponent as MarkAsRead } from "images/mark-as-read.svg";
import { notificationCategory } from "components/Utils/General";
import useFetchNotifications from "hooks/useFetchNotifications";
import NotificationItem from "components/Utils/Notifications/NotificationItem";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
// import emptyIcon from "images/empty-box.svg";

const Notifications = () => {
	const popoverRef = useRef(null);
	const navigate = useNavigate();
	const [tab, setTab] = useState("ALL");

	const {
		query: { data, isFetching },
		viewRef,
		markAllAsRed,
		deleteAllNotification,
		markAsRead,
		deleteNotification,
	} = useFetchNotifications(true, tab);

	console.log("🚀 ~ NOTIFICATION LIST >>", data);

	const notificationsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page?.map((item, i) => (
					<div
						ref={page.length === i + 1 ? viewRef : null}
						key={item.uuid}
					>
						<NotificationItem
							item={item}
							onRead={() => markAsRead(item.uuid)}
							onDelete={() => deleteNotification(item.uuid)}
						/>
					</div>
				))
			),
		[data]
	);

	return (
		<DefaultPage
			defaultHeader={false}
			headerRightContent={
				<>
					<BackButton onBack={() => navigate(-1)} />

					<Popover
						ref={popoverRef}
						render={
							<div className="popover_container">
								<h4 className={styles.menu_title}>
									{notificationCategory[tab]} notifications
								</h4>

								<button
									tabIndex={0}
									className={`popover_item ${styles.menu_item}`}
									onClick={(e) => {
										e.stopPropagation();
										markAllAsRed();
										popoverRef.current.closePopover();
									}}
								>
									<div className={styles.icon_wrapper}>
										<MarkAsRead width={16} />
									</div>
									<span>Mark all as read</span>
								</button>

								<button
									tabIndex={0}
									className={`popover_item ${styles.menu_item}`}
									onClick={(e) => {
										e.stopPropagation();
										deleteAllNotification();
										popoverRef.current.closePopover();
									}}
								>
									<div className={styles.icon_wrapper}>
										<ClearIcon fill="#000" width={16} />
									</div>

									<span>Clear all notifications</span>
								</button>
							</div>
						}
					>
						<button className={styles.option_icon}>
							<img src={optionIcon} alt="options" />
						</button>
					</Popover>
				</>
			}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={
				<div className={styles.container}>
					<div className={styles.tab_menu}>
						<TabMenu
							menuItems={notificationCategory}
							selectedTab={tab}
							onSelectedTabChanged={setTab}
							// customStyle={styles.menu_button}
							// isLoading={loading}
						/>
					</div>

					<div>
						{data?.pages[0].length > 0 && notificationsList}
						<LoadingSpinner visible={isFetching} />
						<NoResults
							// image={emptyIcon}
							// imageStyle={styles.empty_icon}
							// customStyle={styles.no_results}
							text={"You don't have any notifications"}
							visible={data?.pages[0].length === 0}
						/>
					</div>
				</div>
			}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default Notifications;
