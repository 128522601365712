import styles from "./GroupMember.module.css";
import friendIcon from "images/friend-icon-gray.svg";
import workIcon from "images/work-circle-gray.svg";
import ProfilePic from "../Home/ProfilePic";
import GaawkLink from "../Utils/SubComs/GaawkLink/GaawkLink";
import ConnectionStatus from "components/Utils/SubComs/ConnectionStatus/ConnectionStatus";
import { forwardRef } from "react";
import { classNames } from "primereact/utils";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";

const GroupMember = forwardRef(
	(
		{
			data,
			rightSideAction,
			redirect = false,
			blocked = false,
			icon = "",
			iconSize = "",
			isChatLeftSide = false,
			customStyle,
			disableDecoration = false,
			onClick,
			disabled = false,
			forceShow = false,
			imgSize = 48,
			showConnection = true,
		},
		ref
	) => {
		const {
			uuid,
			name,
			type,
			url,
			profileImage,
			connection: {
				mutualFriends,
				mutualWorkCircle,
				inMyWorkCircle,
				friendStatus,
			} = {},
			tagLine,
		} = data;

		const { uuid: profileId } = useCurrentUser();

		const userInfo = (
			<div className={styles.userInfo_container}>
				{tagLine && !blocked && (
					<div
						className={`${styles.tagline} ${
							isChatLeftSide ? styles.chatLeftSide : undefined
						}`}
					>
						{tagLine}
					</div>
				)}
				{uuid !== localStorage.getItem("uuid") && !blocked && (
					<div className={styles.connection_container}>
						{mutualFriends !== 0 && (
							<div className={styles.connection_wrapper}>
								<img src={friendIcon} alt="Mutual friends" />
								<p>{mutualFriends} Mutual</p>
							</div>
						)}
						{mutualWorkCircle !== 0 && (
							<div className={styles.connection_wrapper}>
								<img
									src={workIcon}
									alt="Mutual work connections"
								/>
								<p>{mutualWorkCircle} Common</p>
							</div>
						)}
					</div>
				)}
			</div>
		);

		return (
			//TODO >> replace the "onclick" by the <Link> component from react-router

			// <Link
			//     onClick={(e) => !redirect && e.preventDefault()}
			//     to={`/profile/${url}`}
			//     className={`${styles.member_wrapper} ${customStyle}`}
			// >
			<div
				ref={ref}
				className={classNames(styles.member_wrapper, customStyle, {
					[styles.disabled]: disabled,
				})}
				onClick={() => {
					!redirect && onClick && !disabled && onClick();
				}}
			>
				<GaawkLink
					to={routes.profile(url)}
					onClick={(e) => {
						!redirect && e.preventDefault();
					}}
					customStyle={styles.link_item}
					disableDecoration={disableDecoration || disabled}
				>
					<ProfilePic
						thumbnail={profileImage && profileImage.image}
						w={imgSize}
						h={imgSize}
						type={type}
						name={name}
						createMode={true}
						subdiv={userInfo}
						icon={icon}
						iconSize={iconSize}
						className={styles.item}
					/>

					{!rightSideAction &&
						showConnection &&
						profileId !== uuid && (
							<ConnectionStatus
								friendStatus={friendStatus}
								inMyWorkCircle={inMyWorkCircle}
							/>
						)}
				</GaawkLink>
				{rightSideAction && (profileId !== uuid || forceShow) && (
					<>{rightSideAction}</>
				)}
			</div>
			// </Link>
		);
	}
);

export default GroupMember;
