import Joi from "joi";

const phoneSchema = Joi.object({
	description: Joi.string()
		.required()
		.messages({ "string.empty": "Description / Label can't be empty" })
		.label("Description / Label"),

	type: Joi.object({
		label: Joi.string(),
		value: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Contact Type can't be empty",
			"object.base": "Contact Type can't be empty",
		})
		.label("Contact Type"),
	dialCode: Joi.object({
		label: Joi.string(),
		value: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Dial code can't be empty",
			"object.base": "Dial code can't be empty",
		})
		.label("Dial code"),

	phone: Joi.string()
		.required()
		.regex(/^\d+$/)
		.messages({
			"string.empty": "Phone number can't be empty",
			"string.pattern.base": "Phone number needs to contain numbers only",
		})
		.label("Phone number"),
});

export default phoneSchema;
