import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import { deletePhone } from "../../../../../store/slices/user";
import { deleteCompanyPhone } from "../../../../../store/slices/company";
import styles from "./Phone.module.css";
import PhoneItem from "./PhoneItem";
import CtaButton from "components/Utils/Button/CtaButton";

const Phone = ({ editCompany }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { contacts: contactList, uuid: companyId } = useSelector((state) => {
		if (editCompany) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const [warningDeleteModal, setWarningDeleteModal] = useState(false);
	const [contactId, setContactId] = useState("");

	const handlePhoneDelete = (phoneId) => {
		setWarningDeleteModal(true);
		setContactId(phoneId);
	};

	const handleConfirmDelete = () => {
		dispatch(
			editCompany
				? deleteCompanyPhone(contactId, companyId)
				: deletePhone(contactId)
		);
		setWarningDeleteModal(false);
	};

	const contacts = contactList.map((contact) => (
		<PhoneItem
			key={contact.uuid}
			item={contact}
			onEditClicked={() =>
				navigate("/profile/contact/edit", {
					state: contact,
				})
			}
			onDelete={() => handlePhoneDelete(contact.uuid)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW PHONE NUMBER"
					onClick={() => navigate("/profile/contact/add")}
				/>
			</div>

			{contactList.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Mobile / Landline</h4>
					<div className={styles.contact_section}>{contacts}</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Phone;
