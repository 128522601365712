import LandingPageFooter from "components/Landing/LandingPageFooter";
import TopBar from "../TopBar";
import styles from "./Terms.module.scss";

const Terms = () => {
	return (
		<div className={styles.container}>
			<TopBar />
			<div className={styles.titleContainer}>
				<div className={styles.title}>
					<span className={styles.titleText1}>Terms &</span>
					<span className={styles.titleText2}>Conditions</span>
				</div>
				<span className={styles.subTitle}>
					Our privacy policy outlines how we handle your personal
					information, ensuring its security and confidentiality. We
					are committed to safeguarding your privacy and providing
					transparency in our data practices.
				</span>
			</div>
			<div className={styles.contentWrapper}>
				<span className={styles.contentTitle}>Terms & Conditions</span>
				<div className={styles.content}>
					<p>
						Our privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices. Our
						privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices.
					</p>
					<p>
						Our privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices. Our
						privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices.
					</p>
					<p>
						Our privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices. Our
						privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices.
					</p>
					<p>
						Our privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices. Our
						privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices.
					</p>
					<p>
						Our privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices. Our
						privacy policy outlines how we handle your personal
						information, ensuring its security and confidentiality.
						We are committed to safeguarding your privacy and
						providing transparency in our data practices.
					</p>
				</div>
			</div>
			<LandingPageFooter />
		</div>
	);
};

export default Terms;
