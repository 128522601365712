import { useRef } from "react";

import Options from "images/three-dots-black-5-x-20.svg";
import OptionsWhite from "images/three-dots-white-5-x-20.svg";

// import bookmark from "images/bookmark-outline.svg";
import deleteIcon from "images/delete-icon-black-20-x-20.svg";
import downloadIcon from "images/download-black.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import linkIcon from "images/link.svg";
import shareIcon from "images/share-option.svg";
// import invisibleIcon from "images/invisible.svg";
// import infoIcon from "images/information.svg";
// import warningIcon from "images/exclamation-mark.svg";
import reportIcon from "images/report.svg";

// import followIcon from "images/follow-icon.svg";
import Popover from "../Utils/Popover/Popover";
import styles from "./PostActions.module.scss";
// import "react-day-picker/dist/style.css";

const PostActions = ({
	whiteButton = false,
	isOwner,
	isPublic,
	onEdit,
	onDelete,
	onCopy,
	onShare,
	onReport,
	postType,
	onDownload,
	isFullScreen = false,
}) => {
	const popoverRef = useRef(null);

	return (
		<Popover
			allow={["left"]}
			// crossAxis={15}
			ref={popoverRef}
			render={
				<div className="popover_container">
					{/* <button
                        tabIndex={0}
                        className="popover_item"
                        onClick={() => {
                            alert("save to collection");
                            popoverRef.current.closePopover();
                        }}
                    >
                        <span>
                            <img src={bookmark} alt={"bookmark"} />
                        </span>
                        <span>Save to Collection</span>
                    </button> */}

					{/* //! DOWNLOAD SHOULD ONLY BE AVAILABLE if : slider is open or if grid view items */}
					{onDownload && (
						<button
							tabIndex={0}
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								onDownload();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={downloadIcon} alt={"download"} />
							</span>
							<span>Download</span>
						</button>
					)}

					{isOwner && (
						<>
							{postType !== "PROFILE_PIC" &&
								postType !== "COVER_PIC" && (
									<>
										<button
											className="popover_item"
											tabIndex={0}
											onClick={(e) => {
												e.stopPropagation();
												onEdit();
												popoverRef.current.closePopover();
											}}
										>
											<span>
												<img
													src={editIcon}
													alt={"edit"}
												/>
											</span>
											<span>Edit post</span>
										</button>

										<button
											className="popover_item"
											tabIndex={0}
											onClick={(e) => {
												e.stopPropagation();
												onDelete();
												popoverRef.current.closePopover();
											}}
										>
											<>
												<span>
													<img
														src={deleteIcon}
														alt={"delete"}
													/>
												</span>
												<span>Delete</span>
											</>
										</button>
									</>
								)}

							{(postType === "PROFILE_PIC" ||
								postType === "COVER_PIC") &&
								!isFullScreen && (
									<button
										className="popover_item"
										tabIndex={0}
										onClick={(e) => {
											e.stopPropagation();
											onDelete();
											popoverRef.current.closePopover();
										}}
									>
										<>
											<span>
												<img
													src={deleteIcon}
													alt={"delete"}
												/>
											</span>
											<span>
												Remove post from feed & gallery
											</span>
										</>
									</button>
								)}
						</>
					)}

					{/* {postType === "PROFILE_PIC" &&
									postType === "COVER_PIC" &&
									!isFullScreen && (
										<>
											<span >
												<img
													src={deleteIcon}
													alt={"delete"}
												/>
											</span>
											<span>
												Remove post from feed & gallery
											</span>
										</>
									)} */}

					<button
						className="popover_item"
						tabIndex={0}
						onClick={(e) => {
							e.stopPropagation();
							onCopy();
							popoverRef.current.closePopover();
						}}
					>
						<span>
							<img src={linkIcon} alt={"link"} />
						</span>
						<span>Copy link</span>
					</button>

					{isPublic && (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								onShare();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={shareIcon} alt={"share"} />
							</span>
							<span>Share post</span>
						</button>
					)}

					{!isOwner && (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								onReport();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={reportIcon} alt={"report"} />
							</span>
							<span>Report post</span>
						</button>
					)}

					{/* {!isOwner && (
                        <>
                            <button
                                className="popover_item"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    alert("hide post");
                                    popoverRef.current.closePopover();
                                }}
                            >
                                <span >
                                    <img src={invisibleIcon} alt={"hide"} />
                                </span>
                                <span>Hide this post</span>
                            </button>

                            <button
                                className="popover_item"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    alert("Report this post");
                                    popoverRef.current.closePopover();
                                }}
                            >
                                <span >
                                    <img src={warningIcon} alt={"report"} />
                                </span>
                                <span>Report this post</span>
                            </button>

                            <button
                                className="popover_item"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    alert(`Unfollow ${postOwner}`);
                                    popoverRef.current.closePopover();
                                }}
                            >
                                <span >
                                    <img src={followIcon} alt={"Unfollow"} />
                                </span>
                                <span>Unfollow {postOwner}</span>
                            </button>
                        </>
                    )} */}

					{/* {isOwner && (
                        <button
                            className="popover_item"
                            tabIndex={0}
                            onClick={() => {
                                alert("Who can see this post?");
                                popoverRef.current.closePopover();
                            }}
                        >
                            <span >
                                <img src={infoIcon} alt={"info"} />
                            </span>
                            <span>Who can see this post?</span>
                        </button>
                    )} */}
				</div>
			}
		>
			<button
				className={styles.option_btn}
				onClick={(e) => e.stopPropagation()}
			>
				<img src={whiteButton ? OptionsWhite : Options} alt="options" />
			</button>
		</Popover>
	);
};

export default PostActions;
