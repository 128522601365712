import reactStringReplace from "react-string-replace";
import styles from "./FormatSpecialText.module.css";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import useMutate from "hooks/useMutate";
import servicesApi from "api/services";
import { getClickableLink } from "components/Utils/General";

const FormatSpecialText = ({ text, customStyle }) => {
	// * IMPROVEMENT >> also detect and replace websites / email addresses ?

	const navigate = useNavigate();

	const {
		action: { mutate: getServiceByTag },
	} = useMutate(servicesApi.getServiceByTag, (response) => {
		navigate(routes.serviceDetails(response.data.uuid));
	});

	let formattedPostText = text;

	formattedPostText = reactStringReplace(
		formattedPostText,
		/#(\w+)/g,
		(match, i) => (
			<span
				onClick={() => navigate(routes.postsByHashtag(match))}
				key={match + i}
				className={styles.special_text}
			>
				#{match}
			</span>
		)
	);

	formattedPostText = reactStringReplace(
		formattedPostText,
		/\B@(\w+)/g,
		(match, i) => (
			<span
				onClick={() => navigate(routes.profile(match))}
				key={match + i}
				className={styles.special_text}
			>
				@{match}
			</span>
		)
	);

	formattedPostText = reactStringReplace(
		formattedPostText,
		/\B\+(\w+)/g,
		(match, i) => (
			<span
				onClick={() => getServiceByTag(match)}
				key={match + i}
				className={styles.special_text}
			>
				+{match}
			</span>
		)
	);

	formattedPostText = reactStringReplace(
		formattedPostText,
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
		(match, i) => (
			<a
				key={match + i}
				href={getClickableLink(match)}
				className={styles.special_text}
				target="_blank"
				rel="noreferrer"
			>
				{match}
			</a>
		)
	);

	return (
		<div className={`${styles.container} ${customStyle}`}>
			{formattedPostText}
		</div>
	);
};

export default FormatSpecialText;
