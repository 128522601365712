import { classNames } from "primereact/utils";
import styles from "./JobStatus.module.scss";
import PrimaryButton from "components/Utils/Button/PrimaryButton";

const JobStatus = ({ active, canEdit, onToggleStatus, isProject = false }) => {
	const btnLabel = isProject ? "Project" : "Job";

	return (
		<div className={styles.header}>
			<div className={styles.status_wrapper}>
				<div
					className={classNames(styles.status_indicator, {
						[styles.active]: active,
					})}
				></div>
				<p>
					Status: <span>{active ? "Active" : "Closed"}</span>
				</p>
			</div>
			{canEdit && (
				<PrimaryButton
					text={active ? `Close ${btnLabel}` : `Open ${btnLabel}`}
					className={styles.toggleStatusBtn}
					onClick={onToggleStatus}
				/>
			)}
		</div>
	);
};

export default JobStatus;
