import client from "./client";

const endpoint = "/report";

const getReportReasons = (category) =>
	client.get(endpoint + `/reasons/${category}`);

const report = (category, itemId, reasonId, formData) => {
	let itemIdLabel = "";
	switch (category) {
		case "board":
			itemIdLabel = "boardId"; //~ done
			break;
		case "group":
			itemIdLabel = "groupId"; //~ done
			break;
		case "job":
			itemIdLabel = "jobId"; //~ done
			break;
		case "post":
			itemIdLabel = "postId"; //~ done
			break;
		case "product":
			itemIdLabel = "productId"; //TODO LATER
			break;
		case "profile":
			itemIdLabel = "reportedProfileId"; //~ done
			break;
		case "service":
			itemIdLabel = "serviceId"; //TODO LATER
			break;
		case "specialProfile":
			itemIdLabel = "specialProfileId"; //~ done
			break;
		default:
			break;
	}

	//TODO >> reasonText, message go through formData
	// &reasonText=${reasonText}&message=${message}

	return client.post(
		endpoint + `/${category}?${itemIdLabel}=${itemId}&reasonId=${reasonId}`,
		formData
	);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getReportReasons,
	report,
};
