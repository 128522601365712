import { useState } from "react";
import useWindowSize from "./useWindowSize";

export default function useThumbsCount() {
    const { width } = useWindowSize();

    // lazy initialization pasing a function as initial state (won't compute expression every render)
    const [thumbsPerView] = useState(
        () =>
            width >= 1950
                ? 16
                : width < 1950 && width >= 1900
                ? 15
                : width < 1900 && width >= 1800
                ? 14
                : width < 1800 && width >= 1700
                ? 13
                : width < 1608 && width >= 1400
                ? 12
                : width < 1400 && width >= 1166
                ? 10
                : (width < 1166 && width >= 974) || width < 640
                ? 8
                : 7
        // width >= 1608
        //     ? 16
        //     : width < 1608 && width >= 1400
        //     ? 12
        //     : width < 1400 && width >= 1166
        //     ? 10
        //     : (width < 1166 && width >= 974) || width < 640
        //     ? 8
        //     : 7
    );
    return thumbsPerView;
}
