import client from "./client";

const endpoint = "/gaawk/category";

const getGaawkService = (
  page,
  size,
  q = "",
  l1ServiceId = "",
  l2ServiceId = ""
) =>
  client.get(
    endpoint +
      `?page=${page}&size=${size}&q=${q}&l1ServiceId=${l1ServiceId}&l2ServiceId=${l2ServiceId}`
  );

const addGaawkServiceSuggestion = (name, description, parentId) =>
  client.post(
    endpoint +
      `/suggested?name=${name}&description=${description}&parentId=${parentId}`
  );

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getGaawkService,
  addGaawkServiceSuggestion,
};
