import { useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import "../../../bootstrap-style.css";
// import "bootstrap/css/bootstrap.css";

import styles from "./GaawkModal.module.css";

import close from "images/cross-white-14-x-14.svg";
import back from "images/back-arrow-white-20-x-17.svg";

const GaawkModal = ({
	show,
	handleClose,
	title,
	children,
	defaultModal = true,
	escKeyboard = true,
	color = "#000000",
	showHeader = true,
	size,
	closeAlign = "right",
	modalOnTop = false,
	rightAction = false,
	hiddenMode = false,
	fullWidth = false,
	backPress,
}) => {
	const modalDiv = document.querySelector(".modal");

	useEffect(() => {
		if (modalDiv) modalDiv.removeAttribute("tabindex");
	}, [modalDiv]);

	return (
		<Modal
			style={{ zIndex: modalOnTop ? "2000" : "1050" }}
			animation={false}
			show={show}
			onHide={handleClose}
			backdrop="static"
			backdropClassName={`${styles.gaawk_modal_backdrop} ${
				modalOnTop && styles.modal_on_top
			} ${hiddenMode ? styles.hide : undefined}`}
			dialogClassName={`${styles.gaawk_modal_dialog} ${
				defaultModal
					? size
						? `${styles.default} ${styles}`
						: styles.default
					: fullWidth
					? styles.gaawk_modal_dialog_fullwidth
					: styles.black
			}`}
			className={hiddenMode ? styles.hide : undefined}
			contentClassName={styles.gaawk_modal_content}
			keyboard={escKeyboard}
		>
			{showHeader && (
				<Modal.Header
					className={`${styles.gaawk_modal_header} ${
						defaultModal ? styles.default : styles.black
					}`}
					style={
						defaultModal
							? { backgroundColor: color }
							: { backgroundColor: "#000000" }
					}
				>
					{closeAlign === "both" ? (
						<>
							<button
								type="button"
								className={styles.close}
								onClick={backPress}
							>
								<img
									style={{
										width: "20px",
										height: "17px",
									}}
									src={back}
									alt="close"
								/>
							</button>

							<Modal.Title
								className={styles.gaawk_modal_header_title}
							>
								{title}
							</Modal.Title>

							<button
								type="button"
								className={styles.close}
								onClick={handleClose}
							>
								<img src={close} alt="close" />
							</button>
						</>
					) : (
						<>
							{closeAlign === "left" && closeAlign !== "none" && (
								<button
									type="button"
									className={styles.close}
									onClick={handleClose}
								>
									<img
										style={{
											width: "20px",
											height: "17px",
										}}
										src={back}
										alt="close"
									/>
								</button>
							)}
							<Modal.Title
								className={styles.gaawk_modal_header_title}
							>
								{title}
							</Modal.Title>
							{closeAlign === "right" &&
								closeAlign !== "none" && (
									<button
										type="button"
										className={styles.close}
										onClick={handleClose}
									>
										<img src={close} alt="close" />
									</button>
								)}
						</>
					)}

					{rightAction && rightAction}
				</Modal.Header>
			)}

			<Modal.Body className={styles.gaawk_modal_body}>
				{children}
			</Modal.Body>
		</Modal>
	);
};

export default GaawkModal;
