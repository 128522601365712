import Joi from "joi";

const helpSchema = Joi.object({
	name: Joi.string()
		.required()
		.max(30)
		.messages({
			"string.empty": "Name can't be empty",
			"string.max": "Name can't be more than 30 characters",
		})
		.label("Name"),

	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.label("Email")
		.messages({
			"string.empty": "Email can't be empty",
			"string.email": "Email should be valid",
		}),

	phone: Joi.string()
		.required()
		.pattern(/\+?[\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d/)
		.messages({
			"string.empty": "Phone number can't be empty",
			"string.pattern.base": "Please enter a valid phone number",
		})
		.label("Phone number"),

	description: Joi.string()
		.required()
		.messages({ "string.empty": "Description can't be empty" })
		.label("Description"),

	// file: Joi.object({
	// 	name: Joi.string().required(),
	// 	size: Joi.number()
	// 		.max(10 * 1024 * 1024)
	// 		.messages({
	// 			"number.max": "File size should be less than 10MB",
	// 		}),
	// }),
	file: Joi.any().custom((value, helpers) => {
		// if (!(value instanceof File)) {
		//   return helpers.message('Must be a file');
		// }

		const { size } = value;

		// const allowedTypes = ['image/jpeg', 'image/png'];
		const maxSize = 10 * 1024 * 1024; // 10MB

		// if (!allowedTypes.includes(type)) {
		//   return helpers.message('File type must be JPEG or PNG');
		// }

		if (size > maxSize) {
			return helpers.message("File size should be less than 10MB");
		}

		return value;
	}),
});

export default helpSchema;
