import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import styles from "./VaultListItemsModal.module.css";
import backArrow from "images/back-arrow-black.svg";
import vaultIcon from "images/vault-icon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import useApi from "../../hooks/useApi";
import vaultApi from "../../api/vault";
import folderIcon from "images/icon-awesome-folder-open.svg";
import { List } from "react-content-loader";
// import { removeItemsFromArray } from "../Utils/General";

const ITEMS_PER_PAGE = 10;

function VaultListItemsModal({
	visible,
	onClose,
	// onReset,
	onConfirm,
	title,
	buttonTitle,
	isMove = false,
	selectedItems,
}) {
	/**
	 * Hooks
	 */
	const getFoldersApi = useApi(vaultApi.getFolders);

	/**
	 * States
	 */

	const [selectedFolder, setSelectedFolder] = useState(undefined);
	const [previousFolders, setPreviousFolders] = useState([]);
	const [foldersList, setFoldersList] = useState([]);
	const [page, setPage] = useState(0);
	const [disableButton, setDisableButton] = useState(false);
	// no :id params means you're in the vault root
	// const { id: currentFolderId } = useParams();

	/**
	 * effects
	 */

	useEffect(() => {
		fetchFolders("", 0);
	}, []);

	useEffect(() => {
		if (!getFoldersApi.loading && getFoldersApi.responseCode === 200) {
			if (page === 0)
				setFoldersList(
					getFoldersApi.data.length === 0 ? [] : getFoldersApi.data
				);
			else setFoldersList([...foldersList, ...getFoldersApi.data]);

			setPage(page + 1);
		}
	}, [getFoldersApi.data]);

	useEffect(() => {
		if (isMove) {
			if (selectedItems[0].parentId) {
				// HAVE PARENT ID (NESTED)
				if (selectedFolder) {
					selectedItems[0].parentId === selectedFolder.uuid
						? setDisableButton(true)
						: setDisableButton(false);
				} else {
					setDisableButton(false);
				}
			} else {
				// DON'T HAVE PARENT ID (ROOT)
				if (selectedFolder) {
					setDisableButton(false);
				} else {
					setDisableButton(true);
				}
			}
		}
	}, [isMove, selectedFolder, selectedItems]);

	useEffect(() => {
		if (selectedFolder !== undefined) {
			// fetch data
			setPage(0);
			fetchFolders(selectedFolder ? selectedFolder.uuid : "", 0);
		}
	}, [selectedFolder]);

	// console.log("foldersList >>> ", foldersList);
	// console.log("selectedFolder >>> ", selectedFolder);
	// console.log("Selected items >> ", selectedItems);

	/**
	 * Handlers
	 */

	const fetchFolders = (parentId, page) => {
		getFoldersApi.request(parentId, page, ITEMS_PER_PAGE);
	};

	const handleSelectedFolder = (folder) => {
		setFoldersList([]);
		setSelectedFolder(folder);
		setPreviousFolders(folder.dirs);
	};

	const handleGoBackFolder = () => {
		setFoldersList([]);

		let previousFolder = null;

		if (previousFolders && previousFolders.length > 0)
			previousFolder = previousFolders[0];

		setSelectedFolder(previousFolder);
		setPreviousFolders(
			previousFolders.filter((fol) => fol.uuid !== previousFolder.uuid)
		);
	};

	const loadNextFolders = () => {
		const parentId = selectedFolder ? selectedFolder.uuid : "";
		fetchFolders(parentId, page);
	};

	const handleConfirm = () => {
		onConfirm(selectedFolder);
		onClose();
		// onReset();
	};

	/**
	 * JSX
	 */
	const LoadingView = () => (
		<div>
			<List />
		</div>
	);
	const EmptyView = () => (
		<span className={styles.info_text}>You don't have any folders</span>
	);

	return (
		<GaawkModal
			show={visible}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign={"right"}
			children={
				<div className={styles.modal_container}>
					<div className={styles.movecopy_body}>
						<h3>Select destination folder</h3>
						<div className={styles.movecopy_folder_container}>
							<div className={styles.current_folder}>
								{selectedFolder ? (
									<>
										<img
											src={backArrow}
											onClick={handleGoBackFolder}
											alt="vault"
										/>
										<span>{selectedFolder.name}</span>
									</>
								) : (
									<>
										<img src={vaultIcon} alt="vault" />
										<span>Your Vault</span>
									</>
								)}
							</div>
							<div className={styles.separator} />
							<div className={styles.folders_list}>
								<InfiniteScroll
									dataLength={foldersList.length}
									next={loadNextFolders}
									hasMore={
										foldersList.length > 0 &&
										foldersList.length % 10 === 0
									}
									loader={<h4>Loading...</h4>}
									height={200}
								>
									{!getFoldersApi.loading &&
										foldersList.length === 0 && (
											<EmptyView />
										)}
									{getFoldersApi.loading &&
										foldersList.length === 0 && (
											<LoadingView />
										)}
									{foldersList.length > 0 &&
										foldersList.map((folder) => {
											return (
												<div
													key={folder.uuid}
													className={
														styles.folder_item
													}
													onClick={() =>
														handleSelectedFolder(
															folder
														)
													}
												>
													<img
														src={folderIcon}
														alt="Folder"
													/>
													<span>{folder.name}</span>
												</div>
											);
										})}
								</InfiniteScroll>
							</div>
						</div>
					</div>

					<div className={styles.movecopy_modal_buttons}>
						<SecondaryButton
							className={styles.cancel_button}
							text={"CANCEL"}
							onClick={onClose}
						/>

						<PrimaryButton
							className={styles.movecopy_button}
							text={buttonTitle}
							disabled={disableButton}
							onClick={handleConfirm}
						/>
					</div>
				</div>
			}
		/>
	);
}

export default VaultListItemsModal;
