import styles from "./ForUserSection.module.scss";
import ForUserSectionItem from "components/Landing/ForUserSectionItem";

const ForUserSection = ({ title, items }) => {
    return (
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            <div className={styles.items}>
                {items.map((item, i) => {
                    return <ForUserSectionItem key={i} item={item} />;
                })}
            </div>
        </div>
    );
};

export default ForUserSection;
