import styles from "./PasswordValidationItem.module.scss";
import { ReactComponent as BlueTick } from "images/blue-tick-35-x-35.svg";
import { ReactComponent as RedCross } from "images/cancel-btn-red.svg";

const PasswordValidationItem = ({ errors, validate, label, isDirty }) => {
    return (
        <p className={styles.filter_item}>
            {errors.password?.types["string.pattern.base"]?.includes(
                validate
            ) ||
            errors?.password?.type === "string.empty" ||
            !isDirty ? (
                <RedCross className={styles.icon} />
            ) : (
                <BlueTick className={styles.icon} />
            )}{" "}
            {label}
        </p>
    );
};

export default PasswordValidationItem;
