import React from "react";
import styles from "./ContactSearch.module.css";
import backArrow from "images/back-arrow-black.svg";
import SearchInput from "../Utils/SubComs/Inputs/SearchInput/SearchInput";

const ContactSearch = ({
	showBackArrow = false,
	searchInput,
	onSearchInputChange,
	onClear,
	onBack,
	searchRef,
}) => {
	return (
		<div className={styles.search_wrapper}>
			{showBackArrow && (
				<img
					src={backArrow}
					className={styles.backArrow}
					onClick={onBack}
					alt="Back"
				/>
			)}
			<SearchInput
				searchRef={searchRef}
				onChange={onSearchInputChange}
				classNameInput={styles.search_input}
				value={searchInput}
				showIcons={true}
				onClearClicked={onClear}
				border={false}
				placeholder={
					showBackArrow ? "Start a new conversation" : undefined
				}
			/>
		</div>
	);
};

export default ContactSearch;
