import client from "./client";

const endpoint = "/boards";

const getBoard = (boardId) => client.get(endpoint + `/${boardId}`);

const addBoard = (formData) => client.post(endpoint, formData);

const deleteBoard = (boardId) =>
	client.delete(endpoint + `?boardId=${boardId}`);

const editBoard = (formData) => client.put(endpoint, formData);

const getBoardPosts = (boardId, page, size) =>
	client.get(`/posts/board/${boardId}?page=${page}&size=${size}`);

const getBoardPinnedPosts = (boardId, page, size) =>
	client.get(`/posts/board/${boardId}/pinned?page=${page}&size=${size}`);

const searchBoards = (page, size, q, lat = undefined, lng = undefined) =>
	client.get(
		endpoint +
			`/search?page=${page}&size=${size}&q=${q}${
				lat && lng ? `&lat=${lat}&lng=${lng}` : ""
			}`
	);

const searchPostsBoard = (boardId, page, size, q) =>
	client.get(
		endpoint + `/${boardId}/search?page=${page}&size=${size}&q=${q}`
	);

const pinPost = (boardId, postId) =>
	client.put(endpoint + `/${boardId}/pin?postId=${postId}`);

const unpinPost = (boardId, postId) =>
	client.put(endpoint + `/${boardId}/unpin?postId=${postId}`);

const getMyBoards = (page, size) =>
	client.get(endpoint + `/mine?page=${page}&size=${size}`);

const getBoardModerators = (boardId, page, size) =>
	client.get(
		endpoint + `/moderators?boardId=${boardId}&page=${page}&size=${size}`
	);

const deleteBoardModerator = (boardId, requestedProfileId) =>
	client.delete(
		endpoint +
			`/moderators/moderator?boardId=${boardId}&requestedProfileId=${requestedProfileId}`
	);

const getFollowingBoards = (requestedProfileId, page, size) =>
	client.get(
		endpoint +
			`/following?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}`
	);

const getSuggestedBoards = (page, size, lat = undefined, lng = undefined) =>
	client.get(
		endpoint +
			`/suggested?page=${page}&size=${size}${
				lat && lng ? `&lat=${lat}&lng=${lng}` : ""
			}`
	);

const getBoardFollowers = (boardId, page, size, q = "", usersOnly = false) =>
	client.get(
		endpoint +
			`/followers/full?boardId=${boardId}&page=${page}&size=${size}&q=${q}&usersOnly=${usersOnly}`
	);

const addModerator = (boardId, requestedProfileId) =>
	client.post(
		endpoint +
			`/moderators/moderator?boardId=${boardId}&requestedProfileId=${requestedProfileId}`
	);

const addFollower = (boardId, requestedProfileId) =>
	client.post(
		endpoint +
			`/followers/follower?boardId=${boardId}&requestedProfileId=${requestedProfileId}`
	);

const deleteFollower = (boardId, requestedProfileId) =>
	client.delete(
		endpoint +
			`/followers/follower?boardId=${boardId}&requestedProfileId=${requestedProfileId}`
	);

const suggestBoard = (title, description) =>
	client.post(
		endpoint + `/suggest?title=${title}&description=${description}`
	);

const getPendingFollowers = (boardId, page, size) =>
	client.get(
		endpoint +
			`/followers/pending?boardId=${boardId}&page=${page}&size=${size}`
	);

const acceptBoardFollower = (boardId, requestedProfileId) =>
	client.put(
		endpoint +
			`/followers/follower/accept?boardId=${boardId}&requestedProfileId=${requestedProfileId}`
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getBoard,
	getBoardPosts,
	getBoardPinnedPosts,
	searchBoards,
	searchPostsBoard,
	pinPost,
	unpinPost,
	getMyBoards,
	getBoardModerators,
	deleteBoardModerator,
	getFollowingBoards,
	getSuggestedBoards,
	addFollower,
	deleteFollower,
	addBoard,
	editBoard,
	deleteBoard,
	suggestBoard,
	getBoardFollowers,
	addModerator,
	getPendingFollowers,
	acceptBoardFollower,
};
