import { useQuery } from "@tanstack/react-query";
import useApi from "./useApi";

const useGetPeople = (
	api,
	queryKey,
	coords = undefined,
	itemsPerPage = 5,
	staleTime = 30000
) => {
	const peopleApi = useApi(api, true, true);

	const fetchPeople = async () => {
		const response = await peopleApi.request(
			0,
			itemsPerPage,
			coords && coords.lat,
			coords && coords.lng
		);
		return response.data;
	};

	const query = useQuery({
		queryKey,
		queryFn: fetchPeople,
		staleTime, // 5 minutes
	});

	return query;
};

export default useGetPeople;
