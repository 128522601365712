import styles from "./ProductsFilter.module.scss";
import searchApi from "api/search";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { defaultFormatter } from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { productsInitialFilters } from "../initialFilters";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import CustomSelect from "../../Utils/SubComs/CustomSelect/CustomSelect";
import { measurementUnits, weightUnits } from "../../Utils/General";
import ConnectionFilter from "./ConnectionFilter";
import useSearchFilters from "hooks/useSearchFilters";
import usePaginatedSearchFilters from "hooks/usePaginatedSearchFilters";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useFilterAndSort from "./useFilterAndSort";

const ProductsFilter = forwardRef(
	(
		{
			inputValue,
			onChange,
			onResetFilters,
			selectedFilters: selectedDynamicFilters,
			userCoordinates,
			companyId,
		},
		ref
	) => {
		const {
			initialFilters,
			data,
			isFetching,
			hasFilters,
			selectedFilters,
			setSelectedFilters,
			handleRefetch,
		} = useSearchFilters(
			productsInitialFilters,
			{ locationIds: [userCoordinates.id] },
			"productsFilters",
			inputValue,
			searchApi.productsFilters,
			selectedDynamicFilters,
			onChange
		);

		const {
			hasNextPage: hasNextPageColors,
			fetchNextPage: fetchNextPageColors,
			isFetched: isFetchedColors,
			refetch: refetchColors,
			data: dynamicColors,
		} = usePaginatedSearchFilters(
			productsInitialFilters,
			{ locationIds: [userCoordinates.id] },
			"productsColorsFilters",
			inputValue,
			searchApi.productsColors,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			hasNextPage: hasNextPageMaterials,
			fetchNextPage: fetchNextPageMaterials,
			isFetched: isFetchedMaterials,
			refetch: refetchMaterials,
			data: dynamicMaterials,
		} = usePaginatedSearchFilters(
			productsInitialFilters,
			{ locationIds: [userCoordinates.id] },
			"productsMaterialsFilters",
			inputValue,
			searchApi.productsMaterials,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			hasNextPage: hasNextPageOwners,
			fetchNextPage: fetchNextPageOwners,
			isFetched: isFetchedOwners,
			refetch: refetchOwners,
			data: dynamicOwners,
		} = usePaginatedSearchFilters(
			productsInitialFilters,
			{ locationIds: [userCoordinates.id] },
			"productsOwnersFilters",
			inputValue,
			searchApi.productsOwners,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			hasNextPage: hasNextPageServices,
			fetchNextPage: fetchNextPageServices,
			isFetched: isFetchedServices,
			refetch: refetchServices,
			data: dynamicServices,
		} = usePaginatedSearchFilters(
			productsInitialFilters,
			{ locationIds: [userCoordinates.id] },
			"productsServicesFilters",
			inputValue,
			searchApi.productsServices,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			colors: colorsList = [],
			materials: materialsList = [],
			services = [],
			owners = [],
		} = data || {};

		const colorRef = useRef();
		const ownersRef = useRef();
		const materialRef = useRef();
		const serviceRef = useRef();

		const {
			colors = [],
			materials = [],
			serviceTags = [],
			ownerIds = [],
			minLength = "",
			maxLength = "",
			lengthUnit,
			minWidth = "",
			maxWidth = "",
			widthUnit,
			minHeight = "",
			maxHeight = "",
			heightUnit,
			minWeight = "",
			maxWeight = "",
			weightUnit,
			friends,
			inMyCircle,
			inTheirCircle,
		} = selectedFilters || {};

		const filteredColors = useFilterAndSort(
			colors,
			dynamicColors,
			colorsList,
			isFetchedColors
		);

		const filteredMaterials = useFilterAndSort(
			materials,
			dynamicMaterials,
			materialsList,
			isFetchedMaterials
		);

		const filteredOwners = useFilterAndSort(
			ownerIds,
			dynamicOwners,
			owners,
			isFetchedOwners
		);

		const filteredServices = useFilterAndSort(
			serviceTags,
			dynamicServices,
			services,
			isFetchedServices
		);

		const handleClearFields = () => {
			if (hasFilters) {
				setSelectedFilters(initialFilters);

				// colorRef.current?.clear();
				// materialRef.current?.clear();
				// serviceRef.current?.clear();

				onResetFilters(initialFilters);
			}
		};

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "colors":
						colorRef.current.removeItem(tag);
						break;
					case "materials":
						materialRef.current.removeItem(tag);
						break;
					case "serviceTags":
						serviceRef.current.removeItem(tag);
						break;
					case "ownerIds":
						ownersRef.current.removeItem(tag);
						break;
					default:
						break;
				}
			},
			refetch() {
				handleRefetch();
			},
		}));

		if (isFetching)
			return <LoadingSpinner customStyle={styles.loading_spinner} />;

		return (
			<div className={styles.container}>
				{owners?.length > 0 && (
					<MultiCheckbox
						ref={ownersRef}
						title={"Listed by"}
						options={filteredOwners.map((item) =>
							defaultFormatter(item)
						)}
						onSelect={(owners) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								ownerIds: owners,
							}));
						}}
						selected={ownerIds}
						perRow="2, 150px"
						customStyle={styles.multicheckbox_container}
					/>
				)}

				{((!isFetchedOwners && owners.length === 4) ||
					(isFetchedOwners && hasNextPageOwners)) && (
					<SeeMore
						onClick={
							!isFetchedOwners
								? refetchOwners
								: fetchNextPageOwners
						}
						className={styles.margin_top}
					/>
				)}

				{colorsList?.length > 0 && (
					<MultiCheckbox
						ref={colorRef}
						title={"Colors"}
						options={filteredColors.map((item) =>
							defaultFormatter(item)
						)}
						onSelect={(colors) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								colors: colors,
							}));
						}}
						selected={colors}
						perRow="2, 150px"
						customStyle={styles.multicheckbox_container}
					/>
				)}

				{((!isFetchedColors && colorsList.length === 4) ||
					(isFetchedColors && hasNextPageColors)) && (
					<SeeMore
						onClick={
							!isFetchedColors
								? refetchColors
								: fetchNextPageColors
						}
						className={styles.margin_top}
					/>
				)}

				{materialsList?.length > 0 && (
					<MultiCheckbox
						ref={materialRef}
						title={"Materials"}
						options={filteredMaterials.map((item) =>
							defaultFormatter(item)
						)}
						onSelect={(materials) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								materials: materials,
							}));
						}}
						selected={materials}
						perRow="2, 150px"
						customStyle={styles.multicheckbox_container}
					/>
				)}

				{((!isFetchedMaterials && materialsList.length === 4) ||
					(isFetchedMaterials && hasNextPageMaterials)) && (
					<SeeMore
						onClick={
							!isFetchedMaterials
								? refetchMaterials
								: fetchNextPageMaterials
						}
						className={styles.margin_top}
					/>
				)}

				{services?.length > 0 && (
					<MultiCheckbox
						ref={serviceRef}
						title={"Service Tags"}
						options={filteredServices.map((item) =>
							defaultFormatter(item)
						)}
						onSelect={(serviceTags) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								serviceTags: serviceTags,
							}));
						}}
						selected={serviceTags}
						perRow="2, 150px"
						customStyle={styles.multicheckbox_container}
					/>
				)}

				{((!isFetchedServices && services.length === 4) ||
					(isFetchedServices && hasNextPageServices)) && (
					<SeeMore
						onClick={
							!isFetchedServices
								? refetchServices
								: fetchNextPageServices
						}
						className={styles.margin_top}
					/>
				)}

				<div className={styles.multicheckbox_container}>
					<label>Length</label>
					<div className={styles.input_wrapper}>
						<TextInput
							onChange={({ target }) =>
								setSelectedFilters((prevState) => ({
									...prevState,
									minLength: parseInt(target.value),
								}))
							}
							value={minLength}
							placeholder="min"
							cursor="text"
							type="number"
						/>
						<TextInput
							onChange={({ target }) =>
								setSelectedFilters((prevState) => ({
									...prevState,
									maxLength: parseInt(target.value),
								}))
							}
							value={maxLength}
							placeholder="max"
							cursor="text"
							type="number"
						/>
						<div className={styles.dropdown_container}>
							<CustomSelect
								onChange={(e) =>
									setSelectedFilters((prevState) => ({
										...prevState,
										lengthUnit: e.value,
									}))
								}
								value={measurementUnits.find(
									(item) => item.value === lengthUnit
								)}
								fontSize="14px"
								placeholder="Unit"
								options={measurementUnits}
								isSearchable={false}
								defaultValue={{
									label: "Meters",
									value: "METER",
								}}
							/>
						</div>
					</div>
				</div>

				<div className={styles.multicheckbox_container}>
					<label>Width</label>
					<div className={styles.input_wrapper}>
						<TextInput
							onChange={({ target }) =>
								setSelectedFilters((prevState) => ({
									...prevState,
									minWidth: parseInt(target.value),
								}))
							}
							value={minWidth}
							placeholder="min"
							cursor="text"
							type="number"
						/>
						<TextInput
							onChange={({ target }) =>
								setSelectedFilters((prevState) => ({
									...prevState,
									maxWidth: parseInt(target.value),
								}))
							}
							value={maxWidth}
							placeholder="max"
							cursor="text"
							type="number"
						/>
						<div className={styles.dropdown_container}>
							<CustomSelect
								onChange={(e) =>
									setSelectedFilters((prevState) => ({
										...prevState,
										widthUnit: e.value,
									}))
								}
								value={measurementUnits.find(
									(item) => item.value === widthUnit
								)}
								fontSize="14px"
								placeholder="Unit"
								options={measurementUnits}
								isSearchable={false}
								defaultValue={{
									label: "Meters",
									value: "METER",
								}}
							/>
						</div>
					</div>
				</div>

				<div className={styles.multicheckbox_container}>
					<label>Height</label>
					<div className={styles.input_wrapper}>
						<TextInput
							onChange={({ target }) =>
								setSelectedFilters((prevState) => ({
									...prevState,
									minHeight: parseInt(target.value),
								}))
							}
							value={minHeight}
							placeholder="min"
							cursor="text"
							type="number"
						/>
						<TextInput
							onChange={({ target }) =>
								setSelectedFilters((prevState) => ({
									...prevState,
									maxHeight: parseInt(target.value),
								}))
							}
							value={maxHeight}
							placeholder="max"
							cursor="text"
							type="number"
						/>
						<div className={styles.dropdown_container}>
							<CustomSelect
								onChange={(e) =>
									setSelectedFilters((prevState) => ({
										...prevState,
										heightUnit: e.value,
									}))
								}
								value={measurementUnits.find(
									(item) => item.value === heightUnit
								)}
								fontSize="14px"
								placeholder="Unit"
								options={measurementUnits}
								isSearchable={false}
								defaultValue={{
									label: "Meters",
									value: "METER",
								}}
							/>
						</div>
					</div>
				</div>

				<div className={styles.multicheckbox_container}>
					<label>Weight</label>
					<div className={styles.input_wrapper}>
						<TextInput
							onChange={({ target }) =>
								setSelectedFilters((prevState) => ({
									...prevState,
									minWeight: parseInt(target.value),
								}))
							}
							value={minWeight}
							placeholder="min"
							cursor="text"
							type="number"
						/>
						<TextInput
							onChange={({ target }) =>
								setSelectedFilters((prevState) => ({
									...prevState,
									maxWeight: parseInt(target.value),
								}))
							}
							value={maxWeight}
							placeholder="max"
							cursor="text"
							type="number"
						/>
						<div className={styles.dropdown_container}>
							<CustomSelect
								onChange={(e) =>
									setSelectedFilters((prevState) => ({
										...prevState,
										weightUnit: e.value,
									}))
								}
								value={weightUnits.find(
									(item) => item.value === weightUnit
								)}
								fontSize="14px"
								placeholder="Unit"
								options={weightUnits}
								isSearchable={false}
								defaultValue={{
									label: "Grams",
									value: "GRAM",
								}}
							/>
						</div>
					</div>
				</div>

				<ConnectionFilter
					friends={friends}
					friendLabel="From my friends"
					onFriendChange={
						!companyId &&
						((value) =>
							setSelectedFilters((prevState) => ({
								...prevState,
								friends: value,
							})))
					}
					inMyCircle={inMyCircle}
					myCircleLabel="From my work circle"
					onMyWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inMyCircle: value,
						}))
					}
					inTheirCircle={inTheirCircle}
					theirCircleLabel="From accounts who added me to their work circle"
					onTheirWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inTheirCircle: value,
						}))
					}
				/>

				<div className={styles.button_container}>
					<PrimaryButton
						type="button"
						text={"clear filters"}
						className={`${styles.btn} ${styles.clear}`}
						onClick={handleClearFields}
					/>
				</div>
			</div>
		);
	}
);

export default ProductsFilter;
