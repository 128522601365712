import styles from "./PlanDetails.module.scss";
import { ReactComponent as BasicPlan } from "images/basic-plan.svg";
import { ReactComponent as PremiumPlan } from "images/premium-plan.svg";
import { ReactComponent as GiftIcon } from "images/gift.svg";
import { capitalize } from "components/Utils/General";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";

const PlanDetails = ({ data, isFetching, onChangeTab, onNavigateToPlan }) => {
	const navigate = useNavigate();
	const { currentPlan, nextPlan } = data;

	const { paymentPackageType: currentPackage, price } = currentPlan || {};
	const { paymentPackageType: nextPackage } = nextPlan || {};

	const currentPackageLabel =
		currentPackage === "AAA" ? currentPackage : capitalize(currentPackage);

	const nextPackageLabel =
		nextPackage === "AAA" ? nextPackage : capitalize(nextPackage);

	return (
		<div className={styles.container}>
			{isFetching ? (
				<LoadingSpinner customStyle={styles.loading_container} />
			) : (
				<>
					<div className={styles.header}>
						<div>
							{currentPackage === "FREE" ? (
								<BasicPlan />
							) : (
								<PremiumPlan />
							)}

							<h1>
								{currentPackage === "FREE"
									? "Basic Plan"
									: `${currentPackageLabel} Account`}
							</h1>
						</div>

						<p className={styles.description}>
							{currentPackage === "FREE"
								? "Free plan"
								: `You currently enjoy a complimentary 6-months trial of Gaawk,
    					giving you full access to all our exceptional features. Chat
    					with us for your custom tailored plan if you got more needs.
    					Cancel anytime.`}
						</p>

						{currentPackage === "FREE" && (
							<div className={styles.trial_offer}>
								<GiftIcon />
								<p className={styles.description}>
									Enjoy a complimentary Free trial for first 6
									months from different plans as per your
									needs.
								</p>
							</div>
						)}
					</div>
					<div className={styles.main}>
						<div className={styles.plan_section}>
							<h6>Plan</h6>
							<p>{currentPackageLabel}</p>
						</div>
						<div className={styles.plan_section}>
							<h6>{currentPackageLabel} member since</h6>
							<p>--</p>
						</div>
						<div className={styles.plan_section}>
							<h6>{currentPackageLabel} subscription</h6>
							<p>
								{currentPackage !== "FREE"
									? `Billed Monthly: ${price} AED (After free trial ends)`
									: "--"}
							</p>
						</div>

						{currentPackage !== "FREE" && (
							<button
								type={"button"}
								onClick={() =>
									navigate(routes.subscribe("?unsub=true"))
								}
							>
								Cancel Subscription
							</button>
						)}
					</div>

					<div className={styles.button_container}>
						{!!nextPlan && (
							<PrimaryButton
								onClick={() => onNavigateToPlan(nextPackage)}
								type={"button"}
								text={`Upgrade to ${nextPackageLabel}`}
								className={styles.upgrade_button}
							/>
						)}
						<button
							onClick={onChangeTab}
							type="button"
							className={styles.compare_button}
						>
							See all plans & Compare
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default PlanDetails;
