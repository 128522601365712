import useReportPost from "hooks/useReportPost";
import GaawkModal from "../GaawkModal/GaawkModal";
import styles from "./ReportModal.module.scss";
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from "react";
import GaawkRadioButton from "../SubComs/GaawkRadioButton/GaawkRadioButton";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PrimaryButton from "../Button/PrimaryButton";
import reportSchema from "./report-schema";
import InputWrapper from "../SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "../SubComs/Inputs/TextInput/TextInput";
import SimpleDropZone from "../SubComs/CustomDropZone/SimpleDropZone";
import GalleryFileItem from "components/Profile/GalleryTab/GalleryFileItem";
import useMutate from "hooks/useMutate";
import reportApi from "api/report";
import reportThumbIcon from "images/report-thumb.svg";

const ReportModal = forwardRef(({ category }, ref) => {
	const { showReportModal, setShowReportModal, reasons } =
		useReportPost(category);

	const [step, setStep] = useState(1);
	const [itemId, setItemId] = useState("");

	useImperativeHandle(ref, () => ({
		showReportModal(itemId) {
			setItemId(itemId);
			setShowReportModal(true);
		},
	}));

	const {
		register,
		formState: {
			errors,
			// isDirty,
			// dirtyFields
		},
		// control,
		// setValue,
		// clearErrors,
		handleSubmit,
		watch,
		setError,
		reset,
	} = useForm({
		resolver: joiResolver(reportSchema),
		mode: "onChange",
		// defaultValues: {
		// 	name: "",
		// 	url: "",
		// 	urlValidity: undefined,
		// 	file: [],
		// 	type: null,
		// 	privacy: "public",
		// },
	});

	const reasonId = watch("reason");
	const reasonText = watch("reasonText");

	useEffect(() => {
		if (reasons) {
			reset({
				reason: reasons[0].id.toString(),
			});
		}
	}, [reasons]);

	const reasonsList = useMemo(
		() =>
			reasons?.map((reason) => {
				return (
					<GaawkRadioButton
						customStyle={styles.radio_item}
						{...register("reason")}
						label={
							<>
								<h4 className={styles.label_title}>
									{reason.title}
								</h4>
								<p className={styles.label_text}>
									{reason.subTitle}
								</p>
							</>
						}
						id={reason.id}
						value={reason.id}
						key={reason.id}
						name="reason"
					/>
				);
			}),
		[reasons, reasonId]
	);

	const handleNext = () => {
		const { error } = reportSchema.validate({
			reason: reasonId,
			reasonText: reasonText,
		});

		if (error) {
			setError("reasonText", {
				message: "Please provide a reason",
			});
		} else {
			setStep(2);
		}
	};

	const [droppedFiles, setDroppedFiles] = useState([]);

	const onDropFiles = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length > 0) {
				const formattedFiles = [];
				for (let i = 0; i < acceptedFiles.length; i++) {
					formattedFiles.push({
						caption: "",
						multipartFile: acceptedFiles[i],
						type: acceptedFiles[i].type.includes("video")
							? "VIDEO"
							: "IMAGE",
						taggedIds: [],
						newFile: true,
					});
				}
				setDroppedFiles(formattedFiles);
			}
		},
		[droppedFiles]
	);

	const mappedFiles = useMemo(
		() =>
			droppedFiles.map((file, index) => {
				return (
					<GalleryFileItem
						key={index}
						file={file}
						onRemove={() => setDroppedFiles([])}
						className={styles.file_wrapper}
						// onEdit={() => handleShowEditModal(file, index)}
					/>
				);
			}),
		[droppedFiles]
	);

	const {
		action: { mutate: report, isLoading },
	} = useMutate(reportApi.report, () => {
		// queryClient.invalidateQueries(boardsKeys.detail(boardId));
		// navigate(`/boards/${board.uuid}`);
		setStep(3);
	});

	const handleSave = (data) => {
		const { reason: reasonId, reasonText, message } = data;

		const formData = new FormData();

		// formData.append("uuid", uuid);
		// formData.append("name", boardName);
		if (message) formData.append("message", message);
		if (reasonText) formData.append("reasonText", reasonText);
		if (droppedFiles.length > 0)
			formData.append(
				"file",
				droppedFiles[0].multipartFile,
				droppedFiles[0].multipartFile.name
			);

		// if (boardImage[0] instanceof Blob)
		// 	formData.append("image", boardImage[0], boardImageName);
		// if (feedImage[0] instanceof Blob)
		// 	formData.append("feedImage", feedImage[0], feedImageName);

		// setShouldBlockSave(false);
		// editBoard(formData);

		report({
			category,
			itemId,
			reasonId: reasonId === "other" ? "" : reasonId,
			formData,
		});
	};

	const handleClose = () => {
		setStep(1);
		reset();
		setDroppedFiles([]);
		setShowReportModal(false);
		setItemId("");
	};

	return (
		<GaawkModal
			show={showReportModal}
			handleClose={handleClose}
			defaultModal={false}
			showHeader={true}
			title={`Report ${
				category === "specialProfile" ? "special profile" : category
			}`}
			closeAlign={step === 2 ? "both" : "right"}
			backPress={() => setStep(1)}
			children={
				<form
					className={styles.container}
					onSubmit={handleSubmit((data) => handleSave(data))}
				>
					{step === 1 ? (
						<>
							<p>
								If you come across any of the following issues,
								please don’t hesitate to report them to us:
							</p>

							<div className={styles.reasons_wrapper}>
								{reasonsList}

								<GaawkRadioButton
									customStyle={styles.radio_item}
									{...register("reason")}
									label={
										<>
											<h4 className={styles.label_title}>
												Other
											</h4>
											<p className={styles.label_text}>
												Choose this if you didn't find
												your reason listed above.
											</p>
										</>
									}
									id={"other"}
									value={"other"}
									name="reason"
								/>

								{reasonId === "other" && (
									<InputWrapper
										className={styles.reason_input}
										error={errors?.reasonText}
										component={
											<TextInput
												{...register("reasonText")}
												error={errors.reasonText}
												placeholder="Explain here please..."
											/>
										}
									/>
								)}
							</div>

							<PrimaryButton
								type="button"
								onClick={handleNext}
								text={"next"}
								className={styles.button}
							/>
						</>
					) : step === 2 ? (
						<>
							<p>
								Could you please provide more information or
								consider sharing a screenshot or a screen
								recording to facilitate better understanding? We
								value your feedback and would like to assist you
								effectively.
							</p>

							<InputWrapper
								className={styles.textarea_container}
								error={errors?.message}
								component={
									<div className={styles.textarea_wrapper}>
										<textarea
											{...register("message")}
											placeholder="Explain here please..."
										/>
									</div>
								}
							/>

							<SimpleDropZone
								onDrop={onDropFiles}
								acceptedFiles={[
									"image/png, image/jpg, image/jpeg, application/pdf, video/*",
								]}
								multiple={false}
							/>

							{mappedFiles.length > 0 && mappedFiles}

							<PrimaryButton
								text={"submit"}
								className={styles.button}
								isLoading={isLoading}
							/>
						</>
					) : (
						<div className={styles.success_container}>
							<img
								src={reportThumbIcon}
								className={styles.success_icon}
								alt="report"
							/>

							<h3>Thank you for your feedback!</h3>

							<p>
								Our team has received it and will use your
								valuable input to enhance your app experience.
							</p>

							<PrimaryButton
								type="button"
								onClick={handleClose}
								text={"close"}
								className={styles.button}
							/>
						</div>
					)}
				</form>
			}
		/>
	);
});

export default ReportModal;
