import { useQueryClient } from "@tanstack/react-query";
import { replaceItemInArray } from "components/Utils/General";
import { produce } from "immer";

const useUpdateReaction = () => {
	const queryClient = useQueryClient();

	const updatePostReaction = (queryKey, reaction) => {
		const previousState = queryClient.getQueryData(queryKey);

		const updatedData = produce(previousState, (draftData) => {
			if (previousState.uuid) {
				//~ >> IF HAS UUID MEANS ITS A SINGLE POST
				draftData["reactionStats"] = reaction.reactionStats;
				draftData["profileReactionType"] = reaction.profileReactionType;
			} else {
				//~ >> IF ARRAY (MULTIPLE POSTS, LIKE THE FEED)
				const { pages } = draftData;
				const updatedPost = pages.map((page) =>
					page.find((post) => post.uuid === reaction.postId)
				);

				updatedPost[0]["reactionStats"] = reaction.reactionStats;
				updatedPost[0]["profileReactionType"] =
					reaction.profileReactionType;

				const newPagesArray = pages.map((page) =>
					replaceItemInArray(updatedPost[0], page, "uuid")
				);

				draftData.pages = newPagesArray;
			}
		});

		queryClient.setQueryData(queryKey, updatedData);
		// return { previousState };
	};

	return updatePostReaction;
};

export default useUpdateReaction;
