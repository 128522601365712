import styles from "./StorageCard.module.scss";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import { classNames } from "primereact/utils";

const StorageCard = ({ text, showCleanBtn = true, className }) => {
	const navigate = useNavigate();

	return (
		<div className={classNames(styles.offer_container, className)}>
			<div className={styles.info}>{text}</div>

			<div className={styles.button_container}>
				{showCleanBtn && (
					<button
						type={"button"}
						onClick={() => navigate(routes.storage)}
					>
						Clean up Space
					</button>
				)}

				<button
					type={"button"}
					className={styles.offer_btn}
					onClick={() => navigate(routes.plans)}
				>
					Get Offer
				</button>
			</div>
		</div>
	);
};

export default StorageCard;
