import React from "react";
import styles from "./ProfileEdit.module.css";

function AddCaption(props) {
	const handleOnChange = (e) => {
		props.onChange(e.target.value);
	};

	return (
		<div className={styles.caption_canvas}>
			<textarea
				onChange={handleOnChange}
				value={props.value}
				placeholder="Add a caption..."
				disabled={props.disabled}
			></textarea>
		</div>
	);
}

export default AddCaption;
