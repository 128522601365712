import React, { useState, useRef, useEffect } from "react";
import photoIcon from "images/photo-icon.svg";
import docIcon from "images/document-icon.svg";
import contactIcon from "images/contact-icon.svg";
import pinIcon from "images/location_icon.svg";

const FileAttachOptions = ({
	onUploadFileChange,
	onLocationModal,
	onContactModal,
}) => {
	const inputFile = useRef(null);

	const [fileType, setFileType] = useState({
		type: "",
		timestamp: 0, //adding time stamp to retrigger the useEffect if user presses multiple times on the same button
	});

	const handleFileAccept = (type) => {
		if (type === "DOC") {
			setFileType({
				type: ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.rtf,.odt,.ods,.odp",
				timestamp: Date.now(),
			});
		} else if (type === "MEDIA") {
			setFileType({
				type: "image/*,video/*,.jpg,.jpeg,.png,.gif,.mp4,.mov,.avi,.webm",
				timestamp: Date.now(),
			});
		}
	};

	useEffect(() => {
		if (fileType.type) inputFile.current.click();
	}, [fileType]);

	return (
		<div className="popover_container">
			<input
				type="file"
				id="file"
				ref={inputFile}
				multiple
				onChange={onUploadFileChange}
				accept={fileType.type}
				style={{ display: "none" }}
			/>

			<button
				className="popover_item"
				onClick={() => handleFileAccept("MEDIA")}
			>
				<img src={photoIcon} alt="photo and video" />
				<span>Photo &amp; Video</span>
			</button>

			<button
				className="popover_item"
				onClick={() => handleFileAccept("DOC")}
			>
				<img src={docIcon} alt="document" />
				<span>Document</span>
			</button>

			<button
				className="popover_item"
				onClick={() => {
					onLocationModal();
				}}
			>
				<img src={pinIcon} alt="location" />
				<span>Location</span>
			</button>

			<button
				className="popover_item"
				onClick={() => {
					onContactModal();
				}}
			>
				<img src={contactIcon} alt="contact" />
				<span>Contact</span>
			</button>
		</div>
	);
};

export default FileAttachOptions;
