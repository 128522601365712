import { router } from "AppRoutes";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useLocationChange = (callback) => {
	const { pathname } = useLocation();

	useEffect(() => {
		const unlisten = router.subscribe((state) => {
			if (state.location.pathname !== pathname) callback();
		});
		return unlisten;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useLocationChange;
