import usePackages from "hooks/usePackages";
import useVaultStorage from "hooks/useVaultStorage";
import styles from "./Subscribe.module.scss";
import useGetQueryParam from "hooks/useGetQueryParam";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import CustomCheckbox from "components/Utils/SubComs/Inputs/CustomCheckbox/CustomCheckbox";
import { useState } from "react";
import { ReactComponent as ProductIcon } from "images/product.svg";
import { ReactComponent as ServiceIcon } from "images/services.svg";
import { ReactComponent as GalleryIcon } from "images/image.svg";
import { packageTypes, pluralize } from "components/Utils/General";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useMutate from "hooks/useMutate";
import paymentApi from "api/payment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import routes from "components/Routing/routing-keys";

const Subscribe = () => {
	const navigate = useNavigate();
	const { data: packages, isFetching: isFetchingPackages } = usePackages();

	const { data, isFetching } = useVaultStorage();

	const { currentPlan } = data;
	const { products, services, storage } = currentPlan || {};

	const { paramValue: unsub } = useGetQueryParam("unsub");
	const { paramValue: plan } = useGetQueryParam("plan");

	const isUnsub = unsub === "true";

	const downgradingPackage = packages?.find(
		(item) =>
			item.paymentPackageType === (isUnsub ? packageTypes.free : plan)
	);

	const {
		products: downgradeProducts,
		services: downgradeServices,
		storage: downgradeStorage,
		paymentPackageType: downgradePlan,
	} = downgradingPackage || {};

	const [termsCheckbox, setTermsCheckbox] = useState(false);

	const {
		action: { mutate: subscribe },
	} = useMutate(paymentApi.subscribe, () => {
		toast.success("We're sorry to see you go");
		navigate(routes.plans);
	});

	return (
		<div className={styles.container}>
			{isFetching || isFetchingPackages ? (
				<LoadingSpinner visible={true} customStyle={styles.loading} />
			) : (
				<>
					<div>
						<div className={styles.info}>
							<p>
								Before {isUnsub ? "cancelling" : "downgrading"},
								please review your stored data on Gaawk. Note
								that all data will be permanently lost upon{" "}
								{isUnsub ? "cancelling" : "downgrading"}.
							</p>
						</div>

						<div className={styles.main_container}>
							<div className={styles.feature_item}>
								<div className={styles.icon_wrapper}>
									<ProductIcon fill={"#FFF"} />
								</div>
								<div>
									<p>
										You have{" "}
										<span className={styles.bold}>
											{products}
										</span>{" "}
										{pluralize(products, "Product")}
									</p>
									<p className={styles.warning}>
										Will be downgraded to{" "}
										{`${downgradeProducts} ${pluralize(
											downgradeProducts,
											"product"
										)}`}
									</p>
								</div>
							</div>
							<div className={styles.feature_item}>
								<div className={styles.icon_wrapper}>
									<ServiceIcon fill={"#FFF"} />
								</div>
								<div>
									<p>
										You have{" "}
										<span className={styles.bold}>
											{services}
										</span>{" "}
										{pluralize(services, "Service")}
									</p>
									<p className={styles.warning}>
										Will be downgraded to{" "}
										{`${downgradeServices} ${pluralize(
											downgradeServices,
											"service"
										)}`}
									</p>
								</div>
							</div>
							<div className={styles.feature_item}>
								<div className={styles.icon_wrapper}>
									<GalleryIcon fill={"#FFF"} />
								</div>
								<div>
									<p>
										You have{" "}
										<span className={styles.bold}>
											{storage} GB
										</span>{" "}
										of Storage
									</p>
									<p className={styles.warning}>
										Will be downgraded to{" "}
										{`${downgradeStorage} GB`}
									</p>
								</div>
							</div>
						</div>

						<div className={styles.info}>
							<p>
								Additionally,{" "}
								{isUnsub ? "cancelling" : "downgrading"} your
								subscription will cause your circles to lose
								access to all of your data, products and
								services.
							</p>
						</div>
					</div>

					<div>
						<div className={styles.disclaimer}>
							<CustomCheckbox
								checked={termsCheckbox}
								onChange={({ target }) => {
									setTermsCheckbox(target.checked);
								}}
								id="videoGallery"
								customBackgroundColor={"gaawk"}
								marginRight="0px"
								isBorder={true}
							/>

							<label htmlFor="videoGallery">
								I understand that{" "}
								{isUnsub ? "cancelling" : "downgrading"} my
								subscription will result in the loss of all my
								data, and I will no longer have access to it.
							</label>
						</div>

						<div className={styles.button_container}>
							<PrimaryButton
								type={"button"}
								text={"CONFIRM"}
								onClick={() => subscribe(downgradePlan)}
								disabled={!termsCheckbox}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Subscribe;
