import BottomNavBar from "../../SubComs/bottomNavBar/BottomNavBar";

import styles from "./FullPage.module.css";

const FullPage = ({ content }) => {
	return (
		<>
			<div className={styles.container}>{content}</div>

			<BottomNavBar fluid={true} />
		</>
	);
};

export default FullPage;
