import { useMemo } from "react";
import Tag from "../../../Utils/SubComs/Tags/Tag";
import styles from "./WorkStatusItem.module.css";

const WorkStatusItem = ({ title, items }) => {
	const tags = useMemo(
		() => items.map((item, index) => <Tag key={index} itemName={item} />),
		[items]
	);

	return (
		<div className={styles.container}>
			<h4>{title}</h4>
			<div className={styles.items_container}>{tags}</div>
		</div>
	);
};

export default WorkStatusItem;
