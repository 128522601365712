import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useApi from "hooks/useApi";
import useMutate from "hooks/useMutate";
import useUserLocation from "hooks/useUserLocation";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./Jobs.module.css";
import NewJobCard from "../JobsComponents/NewJobCard/NewJobCard";

const itemsPerPage = 20;

const Jobs = () => {
	const queryClient = useQueryClient();

	// const [tab, setTab] = useState("all");

	const { userCoordinates } = useUserLocation();

	// ! ========================== RECOMMENDED JOBS LOGIC ==========================
	const getRecommendedJobsApi = useApi(jobApi.getRecommendedJobs, true, true);

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const fetchRecommendedJobs = async ({
		pageParam = 0,
		queryKey,
		signal,
	}) => {
		const [_, __, ___, userCoordinates] = queryKey;

		const response = await getRecommendedJobsApi.request(
			pageParam,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const {
		data: recommendedJobs,
		hasNextPage,
		fetchNextPage,
		isFetching,
	} = useInfiniteQuery({
		queryKey: jobsKeys.recommended(userCoordinates),
		queryFn: fetchRecommendedJobs,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		enabled: Object.keys(userCoordinates).length > 0,
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	const recommendedJobList = useMemo(
		() =>
			recommendedJobs?.pages?.map((page) =>
				page?.map((item, i) => (
					<div
						ref={page.length === i + 1 ? viewRef : null}
						key={item.uuid}
					>
						<NewJobCard
							data={item}
							onToggleSave={() =>
								toggleSave({
									jobId: item.uuid,
									state: !item.savedJob,
								})
							}
						/>
					</div>
				))
			),
		[recommendedJobs]
	);

	// ! ========================== NEARBY JOBS LOGIC ==========================

	const getNearbyJobsApi = useApi(jobApi.getNearbyJobs, true, true);

	const { ref: nearbyViewRef, inView: nearbyInView } = useInView({
		triggerOnce: true,
	});

	const fetchNearbyJobs = async ({ pageParam = 0, queryKey, signal }) => {
		const [_, __, ___, userCoordinates] = queryKey;

		const response = await getNearbyJobsApi.request(
			pageParam,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const {
		data: nearbyJobs,
		hasNextPage: hasNextPageNearby,
		fetchNextPage: fetchNextPageNearby,
		isFetching: isFetchingNearby,
	} = useInfiniteQuery({
		queryKey: jobsKeys.nearby(userCoordinates),
		queryFn: fetchNearbyJobs,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},

		enabled: !isFetching && recommendedJobs?.pages?.[0]?.length === 0,
	});

	useEffect(() => {
		if (nearbyInView && hasNextPageNearby) {
			fetchNextPageNearby();
		}
	}, [nearbyInView, hasNextPageNearby, fetchNextPageNearby]);

	const nearbyJobList = useMemo(
		() =>
			nearbyJobs?.pages?.map((page) =>
				page?.map((item, i) => (
					<div
						ref={page.length === i + 1 ? nearbyViewRef : null}
						key={item.uuid}
					>
						<NewJobCard
							data={item}
							onToggleSave={() =>
								toggleSave({
									jobId: item.uuid,
									state: !item.savedJob,
								})
							}
						/>
					</div>
				))
			),
		[nearbyJobs]
	);

	// ! =======================================================================

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, () =>
		queryClient.invalidateQueries(jobsKeys.recommended(userCoordinates))
	);

	return (
		<>
			<div className={styles.recommended_jobs}>
				{recommendedJobs?.pages?.[0]?.length > 0 && (
					<>
						<h3 className={styles.title}>Suggested Jobs</h3>
						<div className={styles.jobs_wrapper}>
							{recommendedJobList}
						</div>
					</>
				)}

				{nearbyJobs?.pages?.[0]?.length > 0 && (
					<>
						<h3 className={styles.title}>Jobs Nearby</h3>
						<div className={styles.jobs_wrapper}>
							{nearbyJobList}
						</div>
					</>
				)}

				<LoadingSpinner visible={isFetching || isFetchingNearby} />

				<NoResults
					text={"No jobs for the moment"}
					visible={
						recommendedJobs?.pages?.[0]?.length === 0 &&
						nearbyJobs?.pages?.[0]?.length === 0
					}
				/>
			</div>
		</>
	);
};

export default Jobs;
