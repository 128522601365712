import Joi from "joi";

const physicalDetailsSchema = Joi.object({
	height: Joi.number().allow("").max(300).label("Height").messages({
		"number.base": "Height must be a number",
		"number.max": "Height can't be more than 300",
	}),
	weight: Joi.number().allow("").max(999).label("Weight").messages({
		"number.base": "Weight must be a number",
		"number.max": "Weight can't be more than 999",
	}),
	waist: Joi.number().allow("").max(999).label("Waist").messages({
		"number.base": "Waist must be a number",
		"number.max": "Waist can't be more than 999",
	}),
	chest: Joi.number().allow("").max(999).label("Chest").messages({
		"number.base": "Chest must be a number",
		"number.max": "Chest can't be more than 999",
	}),
	shoe: Joi.number().allow("").max(100).label("Shoe Size").messages({
		"number.base": "Shoe size must be a number",
		"number.max": "Shoe size can't be more than 100",
	}),
	build: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Build")
		.allow(null),
	eyeColor: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Eye Color")
		.allow(null),
	hairType: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Hair Type")
		.allow(null),
	hairColor: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Hair Color")
		.allow(null),
	hairLength: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Hair Length")
		.allow(null),
});

export default physicalDetailsSchema;
