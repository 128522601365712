import TagContainer from "../Tags/TagContainer";
import styles from "./SpecItem.module.scss";

const SpecItem = ({
    icon,
    title,
    content,
    hasTags = false,
    custom_title_style = false,
    hasGap = false,
}) => {
    return (
        <div
            className={`${styles.spec_item} ${
                hasTags ? styles.has_tags : undefined
            } ${hasGap ? styles.has_gap : undefined}`}
        >
            <span
                className={`${
                    custom_title_style ? styles.spec_title_style : styles.title
                }`}
            >
                <img src={icon} alt="" />
                {title}
            </span>
            {hasTags ? (
                <TagContainer items={content} customStyle={styles.margin} />
            ) : (
                <span
                    className={`${
                        custom_title_style ? custom_title_style : undefined
                    }`}
                >
                    {content}
                </span>
            )}
        </div>
    );
};

export default SpecItem;
