import SpecItem from "components/Utils/SubComs/SpecItem/SpecItem";
import React from "react";
import ScrollableMenu from "components/Profile/ScrollableMenu";
import styles from "../ProductDetails/ProductDetails.module.scss";
import TagContainer from "components/Utils/SubComs/Tags/TagContainer";

export const ProductSpec = ({
	locations,
	specs,
	taggedServices,
	servicesList,
	isWorldWide,
}) => {
	const locationNames = locations
		?.map((location) =>
			location.name ? location?.name : location?.countryDTO?.name
		)
		.join(", ");

	const validSpecs = specs.filter(
		(spec) => spec?.content && spec?.content.length > 0
	);
	return (
		<>
			<div className={styles.specs_wrapper}>
				{isWorldWide && (
					<span className={styles.locations_wrapper}>
						Available Worldwide
					</span>
				)}

				{locations?.length > 0 && (
					<span className={styles.locations_wrapper}>
						Available in: {locationNames}
					</span>
				)}

				{validSpecs.length > 0 && (
					<span className={styles.spec_txt}>Specs</span>
				)}

				{specs.map((spec) =>
					spec.condition !== false && spec?.content ? (
						<SpecItem
							key={spec.title}
							custom_title_style={
								spec?.title === "Size (LxWxH)"
									? styles.content_span_size
									: styles.content_span
							}
							icon={spec.icon}
							title={spec.title}
							hasGap={spec?.hasTags ? true : false}
							content={
								spec?.hasTags ? (
									<ScrollableMenu
										customStyleSection={
											styles.scroll_container
										}
										children={spec.content}
										customStyle={styles.scroll_wrapper}
									/>
								) : (
									spec.content
								)
							}
							hasTags={false}
						/>
					) : null
				)}
			</div>
			{taggedServices?.length > 0 && (
				<>
					<span className={styles.spec_txt}>Service Tags</span>
					<TagContainer
						customStyle={styles.services_tag_wrapper}
						items={servicesList}
					/>
				</>
			)}
		</>
	);
};
