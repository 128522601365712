import styles from "./Settings.module.scss";
import DefaultFullPage from "../Utils/PageArchs/DefaultFullPage/DefaultFullPage";
import Section from "./SettingsComponents/Section";
import contactIcon from "images/contact-icon.svg";
import privacyIcon from "images/privacy-icon.svg";
import GaawkLink from "../Utils/SubComs/GaawkLink/GaawkLink";
import MultiCheckbox from "../Utils/MultiCheckbox/MultiCheckbox";
import { logout, privacyOptions } from "../Utils/General";
import settingsApi from "../../api/settings";
import profileApi from "../../api/profile";
import useApi from "../../hooks/useApi";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import LoadingPage from "../Utils/SubComs/CustomLoader/LoadingPage";
import useMutate from "../../hooks/useMutate";
import { toast } from "react-toastify";
import connectionApi from "../../api/connection";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import LoadingSpinner from "../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import ProfilePic from "../Home/ProfilePic";
import useCompanyId from "../../hooks/useCompanyId";
import WarningModal from "../Utils/GaawkModal/WarningModal";

const itemsPerPage = 30;

const Settings = () => {
	const getSettingsApi = useApi(settingsApi.getSettings, true, true);

	const companyId = useCompanyId();

	const fetchSettings = async () => {
		const response = await getSettingsApi.request();
		return response.data;
	};

	const { isLoading, data: settings } = useQuery({
		queryKey: ["settings"],
		queryFn: fetchSettings,
	});

	const { messages, mentions, tags, groups } = settings || {};

	//! ====== MESSAGES ======

	const {
		action: { mutate: mutateMessages },
	} = useMutate(settingsApi.updateMessagesSettings, () =>
		toast.success("Messages settings saved!")
	);

	//! ====== GROUPS ========

	const {
		action: { mutate: mutateGroups },
	} = useMutate(settingsApi.updateGroupSettings, () =>
		toast.success("Groups settings saved!")
	);

	//! ====== TAGS ==========

	const {
		action: { mutate: mutateTags },
	} = useMutate(settingsApi.updateTagsSettings, () =>
		toast.success("Tags settings saved!")
	);

	//! ====== MENTIONS ======

	const {
		action: { mutate: mutateMentions },
	} = useMutate(settingsApi.updateMentionsSettings, () =>
		toast.success("Mentions settings saved!")
	);

	//! ========== BLOCKED PEOPLE ============

	const { ref: viewRef, inView } = useInView();

	const getBlockedApi = useApi(connectionApi.getBlocked, true, true);

	const fetchBlockedUsers = async ({ pageParam = 0 }) => {
		const response = await getBlockedApi.request(pageParam, itemsPerPage);
		return response.data;
	};

	const {
		data: blockedUsers,
		hasNextPage,
		fetchNextPage,
		isFetching,
	} = useInfiniteQuery({
		queryKey: ["connection", "blocked"],
		queryFn: fetchBlockedUsers,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) fetchNextPage();
	}, [inView, hasNextPage, isFetching, fetchNextPage]);

	const blockedUsersList = blockedUsers?.pages.map((page) =>
		page.map((user, i) => (
			<div
				className={styles.contact_item}
				key={user.uuid}
				ref={page.length === i + 1 ? viewRef : null}
			>
				<ProfilePic
					name={user.name}
					type={user.type}
					thumbnail={user?.profileImage?.image}
					subdata={user.tagLine}
					url={user.url}
					fullWidth={true}
				/>
			</div>
		))
	);

	const {
		warningModal,
		setWarningModal,
		handleRequestDelete,
		handleConfirmDelete,
	} = useMutate(profileApi.deleteAccount, logout);

	if (isLoading) return <LoadingPage fullPage={true} />;

	return (
		<DefaultFullPage
			padding={false}
			content={
				<>
					{!companyId && (
						<Section
							title={"Account Information"}
							icon={contactIcon}
							content={
								<div className={styles.account_container}>
									<GaawkLink
										to="/settings/change-phone"
										customStyle={styles.account_info_item}
									>
										Change Phone Number
									</GaawkLink>
									<GaawkLink
										to="/settings/change-password"
										customStyle={styles.account_info_item}
									>
										Change Password
									</GaawkLink>
									<button
										className={styles.account_info_item}
										onClick={handleRequestDelete}
									>
										Delete Account
									</button>
								</div>
							}
						/>
					)}
					<Section
						title={"Privacy"}
						icon={privacyIcon}
						content={
							<>
								<MultiCheckbox
									title={"Who can send you messages"}
									options={privacyOptions}
									onSelect={(option) =>
										mutateMessages(option[0].value)
									}
									selected={[
										privacyOptions.find(
											(item) => item.value === messages
										),
									]}
									type={"radio"}
									perRow="1, auto"
									customStyle={styles.privacy_item}
									name={"messages"}
								/>
								<MultiCheckbox
									title={"Who can add you to the group"}
									options={privacyOptions}
									onSelect={(option) =>
										mutateGroups(option[0].value)
									}
									selected={[
										privacyOptions.find(
											(item) => item.value === groups
										),
									]}
									type={"radio"}
									perRow="1, auto"
									customStyle={styles.privacy_item}
									name={"groups"}
								/>
								<MultiCheckbox
									title={"Who can tag you in posts"}
									options={privacyOptions}
									onSelect={(option) =>
										mutateTags(option[0].value)
									}
									selected={[
										privacyOptions.find(
											(item) => item.value === tags
										),
									]}
									type={"radio"}
									perRow="1, auto"
									customStyle={styles.privacy_item}
									name={"tags"}
								/>
								<MultiCheckbox
									title={"Who can mention you in posts"}
									options={privacyOptions}
									onSelect={(option) =>
										mutateMentions(option[0].value)
									}
									selected={[
										privacyOptions.find(
											(item) => item.value === mentions
										),
									]}
									type={"radio"}
									perRow="1, auto"
									customStyle={styles.privacy_item}
									name={"mentions"}
								/>
								<div className={styles.privacy_item}>
									<h3>Profiles you have blocked</h3>
									<div>
										{blockedUsersList}
										<LoadingSpinner visible={isFetching} />
									</div>
								</div>
							</>
						}
					/>

					<WarningModal
						show={warningModal}
						modalOnTop={false}
						headerText="Are you sure you want to delete your account?"
						warningText="This action cannot be undone."
						cancelButtonText={"Cancel"}
						onCancelButtonClicked={() => setWarningModal(false)}
						submitButtonText={"DELETE"}
						onSubmitButtonClicked={handleConfirmDelete}
					/>
				</>
			}
		/>
	);
};

export default Settings;
