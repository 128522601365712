import FingerprintJS from "@fingerprintjs/fingerprintjs";

const getFingerprint = async (retries = 3) => {
	try {
		const fp = await FingerprintJS.load();
		const result = await fp.get();
		return result.visitorId;
	} catch (error) {
		if (retries > 0) {
			console.log(
				`Retrying fingerprint generation... (${retries} attempts left)`
			);
			await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before retrying
			return getFingerprint(retries - 1);
		}
		console.error("Failed to generate fingerprint after multiple attempts");
		// throw new Error('Failed to generate fingerprint after multiple attempts');
	}
};

export default getFingerprint;
