import {createSlice} from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "City",
    initialState: {
        list: [],
    },
    reducers: {
        listUpdated: (state, action) => {
            state.list = action.payload;
        },
    },
});

export const {listUpdated} = slice.actions;
export default slice.reducer;

// action creators

/**
 * get country?city by city name
 */
export const loadCities = (city) => apiActions.apiCallBegan({
    url: `country/cities/search?cityName=${city}`,
    method: "GET",
    onSuccess: listUpdated.type,
});
