import React, { forwardRef } from "react";
import styles from "./BasicCheckbox.module.css";

const BasicCheckbox = forwardRef(
    ({ onChange, isChecked, text, clickableLabel = true }, ref) => {
        //TODO >> improve by adding custom style to make checkbox circle

        return (
            <>
                <label className={styles.container}>
                    <input
                        ref={ref}
                        type="checkbox"
                        onChange={onChange}
                        checked={isChecked}
                        tabIndex={0}
                    />
                    <span className={styles.checkmark}></span>
                    {clickableLabel && text}
                </label>
                {!clickableLabel && (
                    <label className={styles.unclickable_label}>{text}</label>
                )}
            </>
        );
    }
);

export default BasicCheckbox;
