import { urlRegex } from "components/Utils/General";
import Joi from "joi";

const companySchema = (editMode) => {
	return Joi.object({
		companyName: Joi.string()
			.required()
			.label("Company Name")
			.messages({ "string.empty": "Company Name can't be empty" }),
		registeredName: Joi.string()
			.required()
			.label("Registered Name")
			.messages({
				"string.empty": "Registered Company Name can't be empty",
			}),
		...(editMode && {
			url: Joi.string()
				.pattern(urlRegex)
				.required()
				.max(20)
				.label("Company URL")
				.messages({
					"string.empty": "Company URL can't be empty",
					"string.pattern.base": "Invalid characters",
					"string.max":
						"Company URL should not be more than 20 characters",
				}),
			urlValidity: Joi.boolean()
				.required()
				.valid(true)
				.label("URL Availability")
				.messages({ "any.only": "URL is already taken" }),
		}),

		industry: Joi.object({
			label: Joi.string(),
			value: Joi.string(),
			__isNew__: Joi.boolean(),
		})
			.required()
			.messages({
				"any.required": "Industry can't be empty",
				"object.base": "Industry can't be empty",
			})
			.label("Industry"),
		headline: Joi.string().allow("").label("Headline"),
		specialty: Joi.array().min(0),
		file: Joi.array().min(0),
		city: Joi.object({
			label: Joi.string(),
			value: Joi.number(),
			// countryCode: Joi.string(),
		})
			.required()
			.messages({
				"any.required": "Headquarters location can't be empty",
				"object.base": "Headquarters location can't be empty",
			})
			.label("Headquarters"),
		size: Joi.object({
			value: Joi.string(),
			label: Joi.string(),
		})
			.required()
			.messages({
				"any.required": "Company Size can't be empty",
				"object.base": "Company Size can't be empty",
			})
			.label("Company Size"),
		date: Joi.date()
			.required()
			.messages({
				"any.required": "Date of Establishment can't be empty",
				"date.base": "Date of Establishment can't be empty",
			})
			.label("Date of Establishment"),
	});
};

export default companySchema;
