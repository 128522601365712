import { useState, useCallback, useLayoutEffect, cloneElement } from "react";
import useResizeObserver from "../../../../hooks/useResizeObserver";
import { useDispatch } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";

import styles from "./RightSide.module.css";
import { updateChatFooterHeight } from "../../../../store/slices/ui";

const RightSide = ({
	children,
	onScroll,
	chat = false,
	rightProiority = false,
	footerContent,
	rightSideBgColor,
	rightSideFullWidth = false,
	showConvoList,
	isInfoOpened,
	disableScrollbar = false,
	bannerComponent,
	defaultBannerHeight = undefined,
}) => {
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const isMobile = width < 748;

	const [footerHeight, setFooterHeight] = useState(undefined);

	const onResize = useCallback((target, entry) => {
		setFooterHeight(entry.contentRect.height);
	}, []);

	const chatFooterRef = useResizeObserver(onResize);

	useLayoutEffect(() => {
		dispatch(updateChatFooterHeight(footerHeight));
	}, [dispatch, footerHeight]);

	const [bannerHeight, setBannerHeight] = useState(defaultBannerHeight);

	const clonedBanner =
		bannerComponent &&
		cloneElement(bannerComponent, {
			onResizeWindow: (height) => setBannerHeight(height + 1), //* adding 1px fixes some scrollbar issues sometimes
		});

	const dynamicHeight = {
		height: `calc(100dvh - 55px ${
			footerHeight ? `- ${footerHeight}px` : width < 748 ? "- 56px" : ""
		} ${bannerComponent ? `- ${bannerHeight}px` : ""})`,
	};

	return (
		<>
			<div
				className={`${styles.right_side_wrapper} ${
					chat ? styles.chat : undefined
				} ${
					chat && rightProiority ? styles.right_proiority : undefined
				} ${isMobile && showConvoList ? styles.hide : styles.show}`}
			>
				{/* <ClonedBanner /> */}
				{/* {bannerComponent} */}
				{clonedBanner}
				<div
					id="scrollableDiv"
					onScroll={onScroll}
					className={`${styles.section_wrapper} ${
						chat ? styles.chat : undefined
					} ${isInfoOpened ? styles.infoOpened : undefined} ${
						disableScrollbar ? styles.disableScrollbar : undefined
					}`}
					style={dynamicHeight}
				>
					<div
						className={`${styles.container}
                        ${rightSideFullWidth ? styles.fullWidth : undefined} ${
							isInfoOpened ? styles.infoOpened : undefined
						}`}
						style={dynamicHeight}
					>
						<div
							className={`${styles.content_wrapper} ${
								rightSideFullWidth
									? styles.fullWidth
									: undefined
							} ${isInfoOpened ? styles.infoOpened : undefined}`}
							style={{
								...dynamicHeight,
								...(rightSideBgColor && {
									backgroundColor: rightSideBgColor,
								}),
							}}
						>
							{children}
						</div>
					</div>
				</div>

				{chat && (
					<div
						ref={chatFooterRef}
						className={`${styles.footer} ${
							isInfoOpened ? styles.infoOpened : undefined
						}`}
					>
						{footerContent}
					</div>
				)}
			</div>
		</>
	);
};

export default RightSide;
