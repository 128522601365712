import styles from "./Footer.module.scss";

const Footer = () => {
	return (
		<div className={styles.container}>
			<p>Copyright all right reserved gaawk {new Date().getFullYear()}</p>
		</div>
	);
};

export default Footer;
