import Joi from "joi";

const jobSchema = (jobType) =>
    Joi.object({
        title: Joi.string().label("Title").required().messages({
            "string.empty": "Title can't be empty",
        }),
        ...(jobType !== "Casting_Call" && {
            location: Joi.object({
                value: Joi.string(),
                label: Joi.string(),
            })
                .required()
                .messages({
                    "any.required": "Working Location can't be empty",
                    "object.base": "Working Location can't be empty",
                })
                .label("Working Location"),
            experienceNeeded: Joi.number()
                .label("Experience Needed")
                .allow("")
                .messages({
                    "number.base": "Experience needed must be a number",
                }),
        }),
        ...(jobType === "Casting_Call" && {
            gender: Joi.string()
                .valid("male", "female")
                .required()
                .messages({
                    "any.only": "Select at least one option",
                })
                .label("Gender"),
            minAge: Joi.number().label("Minimum Age").allow(""),
            maxAge: Joi.number().label("Maximum Age").allow(""),
            ethnicity: Joi.object({
                value: Joi.string(),
                label: Joi.string(),
            })

                .label("Ethnicity")
                .allow(null),
            languages: Joi.array().min(0).label("Languages"),
            date: Joi.date().label("Date").allow(null),
            time: Joi.string()
                .pattern(/^[0-9]{2}:[0-9]{2}$/)
                .allow(null),
        }),
        ...(jobType === "Freelance" && {
            startDate: Joi.date().label("Start date").allow(null),
            endDate: Joi.date()
                .min(Joi.ref("startDate"))
                .label("End Date")
                .allow(null)
                .messages({
                    "date.min":
                        "End date must be equal or greater than the Start date",
                }),
        }),
        ...(jobType === "Long_Term" && {
            employementType: Joi.object({
                value: Joi.string(),
                label: Joi.string(),
            })
                .required()
                .messages({
                    "any.required": "Employement type can't be empty",
                    "object.base": "Employement type can't be empty",
                })
                .label("Employement Type"),
        }),
        currency: Joi.object({
            value: Joi.string(),
            label: Joi.string(),
        })
            .label("Currency")
            .allow(null),
        frequency: Joi.object({
            value: Joi.string(),
            label: Joi.string(),
        })
            .label("Frequency")
            .allow(null),
        status: Joi.boolean(),
        description: Joi.string().label("Job description").allow(""),
        requirements: Joi.string().label("Job requirements").allow(""),
        skills: Joi.array().min(0).label("Skills"),
        compensation: Joi.number().label("Compensation").allow(""),
        address: Joi.string().label("Address").allow(""),
        city: Joi.object({
            value: Joi.number(),
            label: Joi.string(),
        })
            .label("City/Country")
            .allow(null),
    });

export default jobSchema;
