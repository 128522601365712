import { forwardRef } from "react";
import styles from "./GaawkRadioButton.module.scss";
import { classNames } from "primereact/utils";

const GaawkRadioButton = forwardRef(
	(
		{
			label,
			id,
			name,
			value,
			onChange,
			checked,
			customStyle,
			disabled = false,
			centered = false,
			readOnly = false,
			standaloneMode = false,
		},
		ref
	) => {
		return (
			<label
				htmlFor={id}
				className={`${styles.radio} ${customStyle} ${
					disabled ? styles.disabled : undefined
				}`}
			>
				<input
					ref={ref}
					id={id}
					value={value}
					name={name}
					onChange={onChange}
					checked={checked}
					type="radio"
					disabled={disabled}
					readOnly={readOnly}
					tabIndex={readOnly ? -1 : undefined}
				/>
				<span
					className={classNames({
						[styles.centered]: centered,
						[styles.standalone]: standaloneMode,
					})}
				>
					{label}
				</span>
			</label>
		);
	}
);

export default GaawkRadioButton;
