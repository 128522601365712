import React, { useEffect, useMemo } from "react";

import { useSelector } from "react-redux";
import useApi from "../../../../hooks/useApi";
import profileApi from "../../../../api/profile";
import styles from "./ProfileSwitch.module.css";

import Loader from "react-loader-spinner";

import useCompanyId from "../../../../hooks/useCompanyId";

import useProfileSwitch from "hooks/useProfileSwitch";
import ProfileSwitchItem from "./ProfileSwitchItem";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";

const ProfileSwitch = ({ isOpen, jobsModal = false }) => {
	const navigate = useNavigate();

	const getUserCompanyApi = useApi(profileApi.getUserCompany);

	const user = useSelector((state) => state.user.profileInfo);

	const companyId = useCompanyId();

	const {
		switchToProfile,
		switchToCompany,
		userCompanies,
		isLoading,
		refetch,
	} = useProfileSwitch(false);

	useEffect(() => {
		if (isOpen) refetch();
	}, [isOpen]);

	const companies = useMemo(
		() =>
			userCompanies.map((company) => (
				<ProfileSwitchItem
					key={company.uuid}
					data={company}
					isActive={company.uuid === companyId}
					onClick={() => switchToCompany(company.uuid)}
					fontColorWhite={!jobsModal}
				/>
			)),
		[userCompanies, companyId]
	);

	return (
		<>
			{
				<div className={styles.info_text}>
					<h3>{`Select ${!jobsModal ? "Profile" : "Company"}`}</h3>
					{!jobsModal && (
						<p>
							You can post and interact as your personal profile
							or as a company or venue account that you manage.
						</p>
					)}
				</div>
			}

			{isLoading && (
				<div className={styles.loading_container}>
					<Loader
						type="Oval"
						color="#6cc5d1"
						height={25}
						width={25}
					/>
				</div>
			)}

			{!getUserCompanyApi.loading && (
				<div className={styles.companies_container}>
					{!jobsModal && (
						<ProfileSwitchItem
							data={user}
							isActive={!companyId}
							onClick={() => switchToProfile(user.uuid)}
							fontColorWhite={!jobsModal}
						/>
					)}

					{companies}
				</div>
			)}

			<button
				className={styles.manage_companies}
				onClick={() => navigate(routes.myCompanies)}
			>
				+ Create/Manage Companies
			</button>
		</>
	);
};

export default ProfileSwitch;
