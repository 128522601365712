import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	response: {},
};

const slice = createSlice({
	name: "socket",
	initialState,
	reducers: {
		updateResponse: (state, action) => {
			state.response = action.payload;
		},
		resetResponse: () => {
			return initialState;
		},
	},
});

export const { updateResponse, resetResponse } = slice.actions;
export default slice.reducer;
