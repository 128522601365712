import React from "react";
import { Handles, Slider, Tracks } from "react-compound-slider";

import blackTickIcon from "images/black-tick-circle-20-x-20.svg";

const SliderBar = ({
	value,
	trackColor,
	barColor,
	maxDomain,
	tickImg,
	tickWidth,
	tickHeight,
	margin,
	tickPadding,
	labelOnTick,
	width = "100%",
}) => {
	const sliderStyle = {
		position: "relative",
		width: width,
	};

	const railStyle = {
		position: "absolute",
		width: "100%",
		height: 10,
		backgroundColor: trackColor ? trackColor : "#d4f3f7",
		borderRadius: "4px",
	};

	function Handle({ handle: { id, value, percent }, getHandleProps }) {
		return (
			<div
				style={{
					left: `${percent}%`,
					position: "absolute",
					marginLeft: -10,
					marginTop: margin ? margin : -5,
					zIndex: 2,
					width: tickWidth ? tickWidth : 20,
					height: tickHeight ? tickHeight : 20,
					cursor: "pointer",
				}}
				{...getHandleProps(id)}
			>
				<div style={{ position: "relative" }}>
					<img src={tickImg ? tickImg : blackTickIcon} alt="tick" />
					{labelOnTick && (
						<span
							style={{
								width: "100%",
								height: "100%",
								position: "absolute",
								padding: tickPadding,
								top: 0,
								right: 0,
								fontFamily: "OpenSans-bold",
								fontSize: "14px",
								textAlign: "center",
								color: "#ffffff",
							}}
						>
							{value}
						</span>
					)}
				</div>
			</div>
		);
	}

	function Track({ source, target, getTrackProps }) {
		return (
			<div
				style={{
					position: "absolute",
					height: 10,
					zIndex: 1,
					// marginTop: 35,
					backgroundColor: barColor ? barColor : "#6cc5d1",
					// borderRadius: 5,
					cursor: "pointer",
					left: `${source.percent}%`,
					width: `${target.percent - source.percent}%`,
					borderRadius: "4px 0 0 4px",
				}}
				{
					...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
				}
			/>
		);
	}

	return (
		<Slider
			disabled={true}
			rootStyle={sliderStyle}
			domain={[0, maxDomain ? maxDomain : 10]}
			step={1}
			mode={2}
			values={value}
		>
			<div style={railStyle} />
			<Handles>
				{({ handles, getHandleProps }) => (
					<div className="slider-handles">
						{handles.map((handle) => (
							<Handle
								key={handle.id}
								handle={handle}
								getHandleProps={getHandleProps}
							/>
						))}
					</div>
				)}
			</Handles>
			<Tracks right={false}>
				{({ tracks, getTrackProps }) => (
					<div className="slider-tracks">
						{tracks.map(({ id, source, target }) => (
							<Track
								key={id}
								source={source}
								target={target}
								getTrackProps={getTrackProps}
							/>
						))}
					</div>
				)}
			</Tracks>
		</Slider>
	);
};

export default SliderBar;
