import LazyImage from "../../../Utils/LazyImage/LazyImage";
import OptionsBlack from "images/three-dots-black-5-x-20.svg";
import styles from "./ServiceItem.module.css";
import ServicePopover from "./ServicePopover";
import ScrollableMenu from "../../ScrollableMenu";
import ForwardIcon from "images/icon-navigate-forward@3x.png";

const ServiceItem = ({ item, onDelete, isServiceIntro = false, canEdit }) => {
	const {
		image: { file: { customName } = {} } = {},
		description,
		level,
		uuid: serviceId,
		products,
		productsCount,
		tagProducts,
		name,
	} = item || {};

	const serviceName = name;

	const productsImages = products?.map((product, index) => {
		const imgSrc = product?.mainImage?.file?.customName;
		return (
			<div key={product.uuid}>
				<LazyImage
					inlineStyle={styles.products_image}
					image={<img src={imgSrc} alt={imgSrc} />}
				/>
			</div>
		);
	});

	return (
		<div className={styles.item_container}>
			<div className={styles.item_content_container}>
				{customName && (
					<div className={styles.image_container}>
						<LazyImage
							inlineStyle={styles.item_image}
							image={<img src={customName} alt={customName} />}
						/>
					</div>
				)}
				<div className={styles.content_wrapper}>
					{canEdit && (
						<div className={styles.flex_row_between}>
							<span className={styles.item_service_name}>
								{serviceName}
							</span>
							<ServicePopover
								level={level}
								service={item}
								threeDotsIcon={OptionsBlack}
								isTitlePopover={!isServiceIntro}
								serviceId={serviceId}
								onDelete={onDelete}
							/>
						</div>
					)}

					<p className={styles.description}>{description}</p>

					<div className={styles.products_wrapper}>
						{productsCount > 0 && tagProducts && (
							<div className={styles.products_container}>
								<span className={styles.products_text}>
									Products
								</span>
								<ScrollableMenu
									customStyleSection={styles.scroll_container}
									children={productsImages}
									customStyle={styles.scroll_wrapper}
								/>
							</div>
						)}

						<div className={styles.learn_more_container}>
							<span className={styles.explore_text}>
								Explore More
							</span>

							<img
								src={ForwardIcon}
								className={styles.forward_icon}
								alt="Go"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceItem;
