import React from "react";
import styles from "./ParticipantItem.module.css";
import greyClose from "images/cancel-btn-gray.svg";

const ParticipantItem = ({ name, onRemove }) => {
	return (
		<div className={styles.participant}>
			<span>{name}</span>
			<img onClick={onRemove} src={greyClose} alt="close" />
		</div>
	);
};

export default ParticipantItem;
