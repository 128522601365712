import { Controller } from "react-hook-form";
import styles from "./DatePicker.module.scss";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";

const DatePicker = ({
	control,
	name,
	onChange,
	date,
	disabled = false,
	minDate = null,
	maxDate = null,
}) => {
	return (
		<>
			{control ? (
				<Controller
					name={name}
					control={control}
					render={({ field, fieldState }) => {
						return (
							<>
								<label htmlFor={field.name}>
									Date of Establishment
									<span className="required">*</span>
								</label>

								<Calendar
									inputId={field.name}
									value={field.value}
									onChange={field.onChange}
									dateFormat="dd/mm/yy"
									inputClassName={styles.input_wrapper}
									panelClassName={styles.panel_wrapper}
									showButtonBar={true}
									disabled={disabled}
									placeholder="Select date"
									className={classNames(styles.date_wrapper, {
										[styles.invalid]: fieldState.error,
									})}
									// panelStyle={{
									// 	width: "320px !important",
									// 	minWidth: "320px !important",
									// 	left: "0",
									// }}
									// pt={{
									// 	panel: {
									// 		style: {
									// 			minWidth: "unset !important",
									// 			maxWidth: "320px",
									// 			width: "100%",
									// 		},
									// 	},
									// }}
								/>

								{fieldState.error && (
									<p className={styles.error_wrapper}>
										{fieldState.error.message}
									</p>
								)}
							</>
						);
					}}
				/>
			) : (
				<Calendar
					value={date}
					onChange={onChange}
					dateFormat="dd/mm/yy"
				/>
			)}
		</>
	);
};

export default DatePicker;
