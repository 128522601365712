import { useRef } from "react";
import { useSelector } from "react-redux";

import styles from "./AlbumItem.module.css";
import galleryIcon from "images/imgUpload.svg";
import optionIcon from "images/options-hor-dark-grey.svg";
import editIcon from "images/edit-black.svg";
// import shareIcon from "images/share-option.svg";
// import downloadIcon from "images/download-black.svg";
import deleteIcon from "images/delete-black.svg";

import LazyImage from "../../Utils/LazyImage/LazyImage";
import AlbumItemCounter from "./AlbumItemCounter";
import Popover from "../../Utils/Popover/Popover";
import useCompanyId from "../../../hooks/useCompanyId";

const AlbumItem = ({
	item,
	onClick,
	onDelete,
	onEdit,
	onDownload,
	onShare,
}) => {
	// console.log("THE ALBUM POST >>", item.post);
	// console.log("-------");

	const {
		audiosCount,
		imagesCount,
		videosCount,
		pdfsCount,
		title,
		thumbnail,
		gaawkAlbum,
		post: { owner: { uuid: ownerId } = {} } = {},
	} = item;

	const { uuid: profileId } = useSelector((state) => state.user.profileInfo);
	const companyId = useCompanyId();
	const isOwner = ownerId === (companyId ?? profileId);

	const emptyAlbum =
		imagesCount === 0 && videosCount === 0 && pdfsCount === 0;

	const popoverRef = useRef(null);

	return (
		<div className={styles.album_item_wrapper}>
			<button onClick={onClick}>
				<div className={styles.item_cover}>
					{thumbnail ? (
						<LazyImage
							image={<img src={thumbnail} alt={title} />}
						/>
					) : (
						<img
							src={galleryIcon}
							className={styles.gallery_icon}
							alt="gallery icon"
						/>
					)}

					{!emptyAlbum && (
						<AlbumItemCounter
							audiosCount={audiosCount}
							imagesCount={imagesCount}
							videosCount={videosCount}
							pdfsCount={pdfsCount}
						/>
					)}
				</div>
				<p className={styles.title}>{title}</p>
			</button>

			<div className={styles.option_wrapper}>
				{!gaawkAlbum && isOwner && (
					// * popover is removed for gaawkAlbum for now, as there's only edit / delete
					<Popover
						ref={popoverRef}
						render={
							<div className="popover_container">
								<button
									className="popover_item"
									onClick={(e) => {
										e.stopPropagation();
										onEdit();
										popoverRef.current.closePopover();
									}}
								>
									<img src={editIcon} alt="Edit" />
									<span>Edit details</span>
								</button>

								<button
									className="popover_item"
									onClick={(e) => {
										e.stopPropagation();
										onDelete();
										popoverRef.current.closePopover();
									}}
								>
									<img src={deleteIcon} alt="Delete" />
									<span>Delete album</span>
								</button>
							</div>
						}
					>
						<button onClick={(e) => e.stopPropagation()}>
							<img src={optionIcon} alt="options" />
						</button>
					</Popover>
				)}
			</div>
		</div>
	);
};

export default AlbumItem;
