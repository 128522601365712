import useUserLocation from "hooks/useUserLocation";

const useJobInitialFilters = () => {
	const { userCoordinates } = useUserLocation();

	const initialState = {
		type: [{ label: "All", value: 0 }],
		postedDate: [{ label: "Any Time", value: "NONE" }],
		jobType: [],
		workLocation: [],
		freelanceWorkLocation: [],
		compensation: "",
		currency: null,
		frequency: null,
		positionStatus: false,
		calendarAvailability: false,
		startDate: null,
		endDate: null,
		experienceLevel: "",
		freelanceExperienceLevel: "",
		gender: [],
		minAge: "",
		maxAge: "",
		castingDate: null,
		ethnicity: null,
		languages: [],
		skills: [],
		location: {
			label: `${userCoordinates.name}, ${userCoordinates.countryDTO.name}`,
			value: `${userCoordinates.id}`,
		},
	};

	return initialState;
};

export default useJobInitialFilters;
