import { useEffect, useMemo, useRef, useState } from "react";
import Filter from "../../components/Utils/Filter/Filter";
import useWindowSize from "../../hooks/useWindowSize";
import SearchFilterRightSide from "../../components/Search/SearchFilterRightSide";
import SearchFilterLeftSide from "../../components/Search/SearchFilterLeftSide";
import { useDispatch } from "react-redux";
import { resetFilters, updateFilters } from "../../store/slices/search";
import Tag from "../../components/Utils/SubComs/Tags/Tag";
import styles from "./SearchFilterView.module.scss";
import CityModal from "../../components/Boards/BoardsComponents/CityModal";
import useUserLocation from "../../hooks/useUserLocation";
import { useLocation } from "react-router-dom";
// import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import { isPlainObject } from "@reduxjs/toolkit";
import useCompanyId from "hooks/useCompanyId";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";

const SearchFilterView = ({ userCoordinates: coordinates, flag }) => {
	const { width } = useWindowSize();
	const isMobile = width < 748;

	const leftSideRef = useRef();
	const didMountRef = useRef(false);

	const companyId = useCompanyId();

	const [showModal, setShowModal] = useState(false);
	const [userCoordinates, setUserCoordinates] = useState(coordinates);
	const [countryFlag, setCountryFlag] = useState(flag);

	const { userCoordinates: coord, handleSelectedCity } =
		useUserLocation(setCountryFlag);

	useEffect(() => {
		if (didMountRef.current) {
			setUserCoordinates(coord);
		}
		didMountRef.current = true;
	}, [coord]);

	const [filterModal, setFilterModal] = useState();

	useEffect(() => {
		if (filterModal && !isMobile) setFilterModal(false);
	}, [isMobile, filterModal]);

	const [activeTab, setActiveTab] = useState("all");

	const [newInput, setNewInput] = useState("");

	const dispatch = useDispatch();

	const [selectedFilters, setSelectedFilters] = useState({});

	const formatValues = (toBeFormatted) => {
		const formattedValues = {};

		for (const [key, values] of Object.entries(toBeFormatted)) {
			if (isPlainObject(values)) {
				formattedValues[key] = formatValues(values);
			} else if (!Array.isArray(values)) {
				formattedValues[key] = values;
			} else if (values.length > 0) {
				formattedValues[key] = values.map((item) => item.value);
			} else {
				formattedValues[key] = [];
			}
		}
		return formattedValues;
	};

	const handleFilters = (selectedFilters) => {
		setSelectedFilters(selectedFilters);
		const formatted = formatValues(selectedFilters);
		dispatch(updateFilters(formatted));
	};

	const tagsList = useMemo(() => {
		const valuesList = [];
		for (const [k, v] of Object.entries(selectedFilters)) {
			// ignoring the tag creation when the key is 'locationIds'
			if (Array.isArray(v) && v?.length > 0 && k !== "locationIds") {
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.label}
							itemName={`${item.label}`}
							onRemove={() =>
								leftSideRef.current.updateDynamicFilters(
									k,
									item
								)
							}
							customStyle={styles.filter_tag}
						/>
					)
				);
			}
		}
		return valuesList;
	}, [selectedFilters]);

	const handleTabClick = (e) => {
		dispatch(resetFilters());
		setSelectedFilters({});
		setActiveTab(e.target ? e.target?.innerHTML : e);
	};

	const { pathname } = useLocation();

	useEffect(() => {
		if (!pathname.startsWith("/search")) dispatch(resetFilters());
	}, [dispatch, pathname]);

	// useEffect(() => {
	//     const unlisten = history.listen((location) => {
	//         if (!location.pathname.startsWith("/search")) {
	//             dispatch(resetFilters());
	//         }
	//     });

	//     return unlisten;
	// }, [dispatch, history, currentPath.pathname]);

	return (
		<>
			<Filter
				isMobile={isMobile}
				onCloseModal={() => setFilterModal(false)}
				hiddenMode={!filterModal}
				headerButton={
					<button onClick={() => setShowModal(true)}>
						<img src={countryFlag} alt="" />
					</button>
				}
				leftSideChildren={
					activeTab === "all" ? (
						// <FeaturesSidebar />
						<HomeLeftSidebar />
					) : (
						<SearchFilterLeftSide
							ref={leftSideRef}
							inputValue={newInput}
							activeTab={activeTab}
							onChange={handleFilters}
							onResetFilters={handleFilters}
							selectedFilters={selectedFilters}
							userCoordinates={userCoordinates}
							companyId={companyId}
						/>
					)
				}
				rightSideChildren={
					<SearchFilterRightSide
						onInputChange={setNewInput}
						onTabClick={handleTabClick}
						activeTab={activeTab}
						isMobile={isMobile}
						onShowModal={() => setFilterModal(true)}
						tagsList={tagsList}
						userCoordinates={userCoordinates}
						onRefetch={() => {
							leftSideRef.current.refetch();
						}}
					/>
				}
			/>

			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default SearchFilterView;
