import { useCallback, useEffect, useRef, useState } from "react";
import ChatPage from "../Utils/PageArchs/ChatPage/ChatPage";
import { useDispatch, useSelector } from "react-redux";
import ChatRightSide from "./ChatRightSide";
import ChatLeftSide from "./ChatLeftSide";
import ForwardModal from "./ForwardModal";

import RightSideHeader from "./RightSideHeader";
import RightSideFooter from "./RightSideFooter";
import useApi from "../../hooks/useApi";
import chatApi from "../../api/chat";
import useOnScreen from "../../hooks/useOnScreen";
import useWindowSize from "../../hooks/useWindowSize";
import {
	replaceItemInArray,
	viewerMapper,
	movePageItems,
} from "../Utils/General";

import GaawkModal from "../Utils/GaawkModal/GaawkModal";

import CustomRadioButton from "../Utils/SubComs/CustomRadioButton/CustomRadioButton";
import muteIcon from "images/mute-icon.svg";
import fullscreenIcon from "images/fullscreen-icon-white.svg";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import WarningChatModal from "./WarningChatModal";
import styles from "./index.module.css";
import { nanoid } from "nanoid";

import FullScreenModal from "../Utils/GaawkModal/FullScreenModal";
import FileViewer from "../Utils/FileViewer/FileViewer";
import ViewerHeader from "../Utils/FileViewer/ViewerHeader";
import ShowChatProfile from "./ShowChatProfile";
import useProfileById from "../../hooks/useProfileById";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import useMutate from "../../hooks/useMutate";
import SelectAdminModal from "./ChatComponents/AdminModal/SelectAdminModal";
import { useLocation } from "react-router-dom";
import { updateProfileTab } from "store/slices/activeTab";
import { useStompClient } from "react-stomp-hooks";
import { deliveryStatus } from "./SocketSubscription";
import useCompanyId from "hooks/useCompanyId";
import useWindowFocus from "hooks/useWindowFocus";
import { updateChatTotalCount, updateIsInfoOpened } from "store/slices/ui";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import { toast } from "react-toastify";
import { updateConversationIdExport } from "store/slices/chat";
import useLocationChange from "hooks/useLocationChange";
import { router } from "AppRoutes";

/**
 * Constants
 */
const messagesPerPage = 100;
// const mediaPerPage = 10;
const mediaPerPage = 25;
const conversationsPerPage = 50;
const storedConvKey = "selectedConvId";

const Index = () => {
	const location = useLocation();
	const newChat = location.state;

	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const isInfoOpened = useSelector((state) => state.ui.isInfoOpened);

	//* dispatch to reset the redux state
	useLocationChange(() => dispatch(updateIsInfoOpened(false)));

	const [showConvoList, setShowConvoList] = useState(false);
	const [inConvo, setInConvo] = useState(false);

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const fileviewerRef = useRef();

	const handleInfoOpened = () => {
		dispatch(updateIsInfoOpened(true));
		// setIsInfoOpened(true);
		// window.history.pushState(null, "", "/chat");
	};

	const handleBack = useCallback(() => {
		if (isInfoOpened) {
			// setIsInfoOpened(false);
			dispatch(updateIsInfoOpened(false));
		}

		if (isMobile && !isInfoOpened) {
			setShowConvoList(true);
			setInConvo(false);
		}
	}, [isInfoOpened, isMobile, dispatch]);

	// * below useEffect is supposed to go back to conversation when pression browser 'back' button if 'isInfoOpened' is true
	// * however you cannot prevent the 'pop' action from the browser, and also there's no window.history.pushState that adds entry to the history stack
	// * when opening info page like done in other component like <PostFullScreen />
	// useEffect(() => {
	// 	let unlisten;
	// 	if (isInfoOpened) {
	// 		unlisten = router.subscribe((state) => {
	// 			if (state.historyAction === NavigationType.Pop) {
	// 				handleBack();
	// 			}
	// 		});
	// 	}

	// 	return () => unlisten && unlisten();
	// }, [isInfoOpened]);

	useEffect(() => {
		if (newChat) {
			handleNewConversation(newChat);
		}
	}, []);

	useEffect(() => {
		if (isMobile && newChat) {
			setShowConvoList(false);
			setInConvo(true);
		} else {
			setShowConvoList(true);
			setInConvo(false);
		}
	}, [dispatch, isMobile, newChat]);

	const companyId = useCompanyId();

	const { uuid: userId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	/**
	 * Hooks
	 */

	const chatRef = useRef();
	const isVisible = useOnScreen(chatRef);

	const deleteMessagesApi = useApi(chatApi.deleteMessages);
	const deleteMessagesForAllApi = useApi(chatApi.deleteMessagesForAll);
	const clearConvApi = useApi(chatApi.clearConversation);

	// const [messageArrivedSound] = useSound(pingSound, {volume: 1});
	// const stompClient = useStompClient();

	/**
	 * states
	 */
	// conversations
	const [selectedConveration, setSelectedConveration] = useState(null);
	// console.log("🚀 ~ selectedConveration >>", selectedConveration);

	// messages
	// const [messages, setMessages] = useState([]);
	// const [messagePage, setMessagePage] = useState(0);

	const [progress, setProgress] = useState(0);

	//! ==== Show slider ========================

	const [messagesWithMedias, setMessagesWithMedias] = useState([]);

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const getMediaApi = useApi(chatApi.getMedia, true, true);

	const fetchMedia = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, conversationId] = queryKey;

		const response = await getMediaApi.request(
			conversationId,
			pageParam,
			mediaPerPage
		);
		return response.data;
	};

	const {
		data: media,
		hasNextPage: hasNextPageMedia,
		fetchNextPage: fetchNextPageMedia,
		isFetching: isFetchingMedia,
	} = useInfiniteQuery({
		queryKey: ["chatMedia", selectedConveration?.uuid],
		queryFn: fetchMedia,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === mediaPerPage ? pages.length : undefined;
			return nextPage;
		},

		// enabled: !!selectedConveration?.uuid,
		enabled:
			showSlider &&
			messagesWithMedias.length < selectedConveration?.filesCount
				? true
				: false,
		initialData: { pageParams: [undefined], pages: [[]] },
	});

	// console.log("MESSAGES WITH MEDIAS >>>", messagesWithMedias?.length);
	// console.log("THE SELECTED COUNT >>>", selectedConveration?.filesCount);
	// console.log("====================");

	const filterDeleted = (array) => {
		// Fn to only keep media messages that are not deleted
		if (messages.pages[0].length > 0) {
			const flattenArray = array.pages.flat();
			return flattenArray.filter((msg) => msg.media && !msg.deleted);
		}
	};

	useEffect(() => {
		const unduplicatedFilteredMedia = viewerMapper(
			media.pages.flat()
		).filter(
			(item) =>
				!messagesWithMedias.map(({ uuid }) => uuid).includes(item.uuid)
		);

		setMessagesWithMedias((prevState) => [
			...prevState,
			...unduplicatedFilteredMedia,
		]);
	}, [media.pages]);

	const handleShowSlider = (msg) => {
		if (!selectActive) {
			setShowSlider(true);
			const index = messagesWithMedias.findIndex(
				({ uuid }) => uuid === msg.media.file.uuid
			);

			setInitialSlideIndex(index);
		}
	};

	const handleLoadMore = () => {
		if (
			hasNextPageMedia &&
			messagesWithMedias.length < selectedConveration.filesCount
		) {
			fetchNextPageMedia();
		}
	};

	//! ===================

	/**
	 * Functions
	 */

	const {
		refetch,
		data: profile,
		fetchStatus,
		isLoading: isProfileLoading,
	} = useProfileById(
		selectedConveration?.minifiedProfile?.uuid,
		selectedConveration?.minifiedProfile?.type
	);

	const handleClick = () => {
		//TODO >> opening the convo detail should fetch convo again to get latest filecount / convo details

		getConvo(selectedConveration.uuid);

		dispatch(updateProfileTab("info"));

		if (selectedConveration.conversationType === "Default") {
			refetch();
		}
		handleInfoOpened();

		// if (selectedConveration.conversationType === "Default") {
		// 	refetch();
		// 	handleInfoOpened();
		// } else {
		// 	handleInfoOpened();
		// }
	};

	const [restOfMedias, setRestOfMedias] = useState([]);

	const handleMessageSent = (response) => {
		const { message, uuid: convId } = response.data;

		if (!selectedConveration?.isTemp) {
			queryClient.setQueryData(
				// ["messages", selectedConveration?.uuid],
				["messages", convId],
				(prevData) => {
					const pagesCopy = [...prevData.pages];

					const pageIndex = pagesCopy.findIndex((page) =>
						page.find(
							({ conversationId }) => conversationId === convId
						)
					);

					pagesCopy[pageIndex] = replaceItemInArray(
						message,
						pagesCopy[pageIndex],
						"messageId"
					);

					return {
						...prevData,
						pages: pagesCopy,
					};
				}
			);
		}

		// set the state
		// handleCloseReply();
		if (restOfMedias.length > 0) setSendRestOfMedias(true);

		if (selectedConveration?.isTemp) {
			handleClickConversation(response.data);
		}

		queryClient.setQueryData(["conversations"], (prevData) => {
			const pagesCopy = [...prevData.pages];

			const pageIndex = pagesCopy.findIndex((page) =>
				page.find((conv) => conv.uuid === convId)
			);

			if (pageIndex > -1) {
				const convIndex = pagesCopy[pageIndex].findIndex(
					(conv) => conv.uuid === convId
				);

				pagesCopy[pageIndex][convIndex] = response.data;

				//* commented because if sending message and switching to another convo quickly, the below line would go back to the 1st convo
				// setSelectedConveration(response.data);

				return {
					...prevData,
					pages: pagesCopy,
				};
			}
		});
	};

	const {
		action: { mutate: sendMessage },
	} = useMutate(chatApi.sendMessage, handleMessageSent);

	const handleSendMessageMutation = (messageObject) => {
		sendMessage(messageObject);
		replyItem && handleCloseReply();
	};

	const handleSendingMessage = (
		messageRequest,
		pendingMessage,
		pendingConv,
		medias
	) => {
		const formattedMedia = [];
		const msgIdArray = [];

		if (medias) {
			medias.forEach((media) => {
				const msgId = nanoid(21);
				msgIdArray.push(msgId);

				const mediaObject = {
					messageId: msgId,
					message: media.text,
					...pendingMessage,
				};
				formattedMedia.push(mediaObject);
			});

			queryClient.setQueryData(
				["messages", selectedConveration?.uuid],
				(prevData) => {
					const pagesCopy = [...prevData.pages];

					pagesCopy[0] = [...formattedMedia, ...pagesCopy[0]];

					const updatedPages = movePageItems(
						pagesCopy,
						messagesPerPage
					);

					return {
						...prevData,
						pages: updatedPages,
					};
				}
			);

			medias.forEach((media, i) => {
				if (pendingConv) {
					if (i === 0) {
						const mediaMessageRequest = {
							id: msgIdArray[i],
							text: media.text,
							file: media.file,
							...messageRequest,
						};

						handleSendMessageMutation({
							request: mediaMessageRequest,
							progressFn: (progress) => setProgress(progress),
						});
					} else {
						const messageRequestCopy = { ...messageRequest };
						messageRequestCopy["newConversationId"] = null;

						const mediaObject = {
							id: msgIdArray[i],
							text: media.text,
							file: media.file,
							...messageRequestCopy,
						};

						setRestOfMedias((prevState) => [
							mediaObject,
							...prevState,
						]);
					}
				} else {
					const messageRequestCopy = { ...messageRequest };
					const mediaObject = {
						id: msgIdArray[i],
						text: media.text,
						file: media.file,
						...messageRequestCopy,
					};

					handleSendMessageMutation({
						request: mediaObject,
						progressFn: (progress) => setProgress(progress),
					});
				}
			});
		} else {
			queryClient.setQueryData(
				["messages", selectedConveration?.uuid],
				(prevData) => {
					const pagesCopy = [...prevData.pages];

					pagesCopy[0] = [pendingMessage, ...pagesCopy[0]];

					const updatedPages = movePageItems(
						pagesCopy,
						messagesPerPage
					);

					return {
						...prevData,
						pages: updatedPages,
					};
				}
			);

			handleSendMessageMutation({
				request: messageRequest,
				progressFn: (progress) => setProgress(progress),
			});
		}

		queryClient.setQueryData(["conversations"], (prevData) => {
			const pagesCopy = [...prevData.pages];

			const pageIndex = pagesCopy.findIndex((page) =>
				page.find((conv) => conv.uuid === selectedConveration?.uuid)
			);

			if (selectedConveration.isTemp) {
				pagesCopy[0] = [pendingConv, ...pagesCopy[0]];
			} else if (pageIndex > -1) {
				pagesCopy[pageIndex] = pagesCopy[pageIndex].filter(
					(conv) => conv.uuid !== selectedConveration.uuid
				);

				const updatedConv = { ...selectedConveration };

				updatedConv["message"] =
					formattedMedia.length > 0
						? formattedMedia[formattedMedia.length - 1]
						: pendingMessage;
				// TODO: FIX LEFT SIDE LAST MESSAGE WHEN IT'S A MEDIA ( + text if any )

				pagesCopy[0] = [updatedConv, ...pagesCopy[0]];
			} else {
				pagesCopy[0] = [selectedConveration, ...pagesCopy[0]];
			}

			const updatedPages = movePageItems(pagesCopy, conversationsPerPage);

			return {
				...prevData,
				pages: updatedPages,
			};
		});
	};

	const isWindowFocus = useWindowFocus();

	const stompClient = useStompClient();

	const [pendingAcknowledge, setPendingAcknowledge] = useState(undefined);

	const markAsRead = (messageObject) => {
		if (messageObject?.unreadCount > 0) {
			dispatch(
				updateChatTotalCount(chatTotalCount - messageObject.unreadCount)
			);
		}

		if (stompClient) {
			stompClient.publish({
				destination: "/app/mark.public",
				body: JSON.stringify(messageObject),
			});
		}
	};

	useEffect(() => {
		//* ONCE WINDOW IS FOCUSED AND THAT ACTIVE CONVO IS THE SAME AS THE CONVO FROM THE SAVED MESSAGE

		if (
			pendingAcknowledge?.conversationId === selectedConveration?.uuid &&
			isWindowFocus
		) {
			markAsRead(pendingAcknowledge);
		} else {
			setPendingAcknowledge(undefined);
		}
	}, [isWindowFocus]);

	const handleReceivedMessageViaSocket = (response) => {
		const { uuid: conversationId, message, unreadCount } = response;

		queryClient.setQueryData(["conversations"], (prevData) => {
			const pagesCopy = [...prevData.pages];

			const pageIndex = pagesCopy.findIndex((page) =>
				page.find((conv) => conv.uuid === conversationId)
			);

			if (pageIndex > -1) {
				pagesCopy[pageIndex] = pagesCopy[pageIndex].filter(
					(conv) => conv.uuid !== conversationId
				);

				if (
					selectedConveration &&
					selectedConveration.uuid === conversationId
				) {
					setSelectedConveration(response);

					const messageObject = {
						from: response.message.sendBy
							? response.message.sendBy.uuid
							: null,
						participantId: userId,
						conversationId: response.message.conversationId,
						messageId: response.message.messageId,
						messageStatus: deliveryStatus.read,
						unreadCount,
					};

					if (isWindowFocus) {
						//*  IF WINDOW IS FOCUSED: MARK AS "READ"
						markAsRead(messageObject);
					} else {
						//* IF WINDOW IS BLURRED >> SAVE THE OBJECT
						setPendingAcknowledge(messageObject);
					}

					queryClient.setQueryData(
						["messages", conversationId],
						(prevData) => {
							const pagesCopy = [...prevData.pages];

							pagesCopy[0] = [message, ...pagesCopy[0]];

							const updatedPages = movePageItems(
								pagesCopy,
								messagesPerPage
							);

							return {
								...prevData,
								pages: updatedPages,
							};
						}
					);
				}
			}

			pagesCopy[0] = [response, ...pagesCopy[0]];

			const updatedPages = movePageItems(pagesCopy, conversationsPerPage);

			return {
				...prevData,
				pages: updatedPages,
			};
		});
	};

	const handleAcknowledge = (response) => {
		const {
			uuid: conversationId,
			message: { messageId, messageStatus },
		} = response;

		queryClient.setQueryData(["conversations"], (prevData) => {
			const pagesCopy = [...prevData.pages];

			const pageIndex = pagesCopy.findIndex((page) =>
				page.find((conv) => conv.uuid === conversationId)
			);

			if (pageIndex > -1) {
				const convIndex = pagesCopy[pageIndex].findIndex(
					(conv) => conv.uuid === conversationId
				);

				pagesCopy[pageIndex][convIndex]["message"]["messageStatus"] =
					messageStatus;

				queryClient.setQueryData(
					["messages", conversationId],
					(prevData) => {
						if (prevData === undefined) {
							return;
						} else {
							const pagesCopy = [...prevData.pages];

							const updatedMessageIndex = pagesCopy[0].findIndex(
								(msg) => msg.messageId === messageId
							);

							if (updatedMessageIndex > -1) {
								pagesCopy[0][updatedMessageIndex][
									"messageStatus"
								] = messageStatus;
							}

							return {
								...prevData,
								pages: pagesCopy,
							};
						}
					}
				);

				return {
					...prevData,
					pages: pagesCopy,
				};
			} else {
				return prevData;
			}
		});
	};

	//! =============== EXPORT CONVERSATION HANDLER ==============

	const {
		action: { mutate: exportConversation },
	} = useMutate(chatApi.exportConversation, () => {
		toast.success(
			"Your file is getting prepared. You'll be notified once it's ready"
		);
	});

	const handleExport = () => {
		exportConversation(selectedConveration.uuid);
		dispatch(updateConversationIdExport(selectedConveration.uuid));
	};

	//! =============== REPLY MESSAGE HANDLER ====================

	const [replyItem, setReplyItem] = useState("");

	const [replyHeight, setReplyHeight] = useState("");

	const timerRef = useRef(null);

	function startTimer() {
		// Clear any existing timer before setting a new one
		clearTimer();

		timerRef.current = setTimeout(() => {
			// console.log("Timer executed");
			footerRef.current?.focusInput();
		}, 1);
	}

	function clearTimer() {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
			// console.log("Timer cleared");
			timerRef.current = null; // Reset the timer ref
		}
	}

	const handleReply = (messageInfo) => {
		setReplyItem(messageInfo);
		startTimer();
	};

	useEffect(() => {
		// This effect will clean up the timer when the component unmounts
		return () => clearTimer();
	}, []); // Empty dependency array ensures this runs only on unmount

	const handleReplyHeight = (height) => {
		setReplyHeight(height);
	};

	const handleCloseReply = () => {
		setReplyItem("");
		setReplyHeight("");
	};

	//! =============== SELECT MESSAGE(S) HANDLER ================

	const [selectActive, setSelectActive] = useState(false);
	const [selectedMessages, setSelectedMessages] = useState([]);

	const handleSelect = () => {
		setSelectActive(true);
	};

	const handleSelected = (messageItem) => {
		if (
			selectedMessages
				.map((item) => item.messageId)
				.includes(messageItem.messageId)
		) {
			setSelectedMessages((prevState) =>
				prevState.filter(
					(msg) => msg.messageId !== messageItem.messageId
				)
			);
		} else {
			setSelectedMessages((prevState) => [...prevState, messageItem]);
		}
	};

	const handleCloseSelect = () => {
		setSelectActive(false);
		setSelectedMessages([]);
	};

	//! =============== FORWARD MESSAGE(S) HANDLER ===============

	const [forwardModalOpened, setForwardModalOpened] = useState(false);
	const [forwardConvIds, setForwardConvIds] = useState([]);

	const handleCloseForward = () => {
		setForwardConvIds([]);
		setForwardModalOpened(false);
		if (!selectActive) {
			setSelectedMessages([]);
		}
	};

	const handleSelectedConv = (selectedConvId) => {
		if (forwardConvIds.includes(selectedConvId)) {
			setForwardConvIds((prevState) =>
				prevState.filter((convId) => convId !== selectedConvId)
			);
		} else {
			setForwardConvIds((prevState) => [...prevState, selectedConvId]);
		}
	};

	//TODO - Forwarding messages won’t keep the same order of messages

	const handleForward = () => {
		forwardConvIds.forEach((convId) => {
			selectedMessages
				.sort((a, b) => a.messageTime - b.messageTime)
				.forEach((message) => {
					const generatedId = nanoid(21);
					forwardMessage({
						convId,
						fwdMsgId: message.messageId,
						msgId: generatedId,
					});
				});
		});

		handleCloseForward();
		handleCloseSelect();
	};

	const handleSingleForward = (messageItem) => {
		setSelectedMessages([messageItem]);
		setForwardModalOpened(true);
	};

	const handleForwardMessage = (response) => {
		const { conversationId } = response.data;

		queryClient.setQueryData(["conversations"], (prevData) => {
			const pagesCopy = [...prevData.pages];

			const pageIndex = pagesCopy.findIndex((page) =>
				page.find((conv) => conv.uuid === conversationId)
			);

			if (pageIndex > -1) {
				const convIndex = pagesCopy[pageIndex].findIndex(
					(conv) => conv.uuid === conversationId
				);

				const updatedConv = { ...pagesCopy[pageIndex][convIndex] };
				updatedConv["message"] = response.data;

				pagesCopy[pageIndex] = pagesCopy[pageIndex].filter(
					(conv) => conv.uuid !== conversationId
				);

				// TODO: FIX LEFT SIDE LAST MESSAGE WHEN IT'S A MEDIA ( + text if any )

				pagesCopy[0] = [updatedConv, ...pagesCopy[0]];
				const updatedPages = movePageItems(
					pagesCopy,
					conversationsPerPage
				);
				return {
					...prevData,
					pages: updatedPages,
				};
			}
		});
	};

	const {
		action: { mutate: forwardMessage },
	} = useMutate(chatApi.forwardMessage, handleForwardMessage);

	//! =============== WARNING MODAL HANDLERS ===================

	const [showAdminModal, setShowAdminModal] = useState(false);

	// const handleAdminSuccess = () => {
	//     setShowAdminModal(false);
	//     leaveGroup(selectedConveration.groupDTO.uuid);
	// };

	const {
		action: { mutate: assignAdmin },
	} = useMutate(chatApi.assignAdmin, () => {
		setShowAdminModal(false);
		leaveGroup(selectedConveration.groupDTO.uuid);
	});

	// const handleSelectedAdmin = (profile) => {
	//     assignAdmin({
	//         assigneeId: profile.uuid,
	//         groupId: selectedConveration.groupDTO.uuid,
	//     });
	// };

	const [warningChatModalAction, setWarningChatModalAction] = useState("");
	const [warningChatModalActionAlt, setWarningChatModalActionAlt] =
		useState("");
	const [showWarningChatModal, setShowWarningChatModal] = useState(false);

	const handleConfirmAction = (action) => {
		// TODO handle the api calls based on the action (action list in <WarningChatModal />)
		switch (action) {
			// case "BLOCK":
			//     blockProfileApi.request(
			//         selectedConveration.minifiedProfile.uuid
			//     );
			//     break;
			case "REPORT":
				alert("CALL API TO REPORT CONNECTION");
				break;
			case "CLEAR":
				clearConvApi.request(selectedConveration.uuid);
				break;
			case "DELETE_MSG":
				deleteMessagesApi.request(
					selectedConveration.uuid,
					selectedMessages
				);
				if (selectActive) setSelectActive(false);
				break;
			case "DELETE_MSG_4ALL":
				deleteMessagesForAllApi.request(
					selectedConveration.uuid,
					selectedMessages
				);
				if (selectActive) setSelectActive(false);
				break;
			// case "DELETE_MSGS":
			//     deleteMessagesApi.request(selectedConveration.uuid, selectedMessages);
			//     setSelectActive(false);
			// break;
			case "DELETE_CONV":
				deleteConv(selectedConveration.uuid);
				break;
			case "LEAVE_GROUP":
				const {
					groupDTO: { admin, totalAdmins, totalMembers },
				} = selectedConveration;

				if (admin && totalAdmins === 1 && totalMembers > 0) {
					setShowAdminModal(true);
				} else {
					leaveGroup(selectedConveration.groupDTO.uuid);
				}

				break;
			// case "REPORT_GROUP":
			//     alert("CALL API TO REPORT GROUP");
			//     break;
			// case "DELETE_GROUP":
			//     alert("CALL API TO DELETE GROUP");
			//     break;
			// case "DELETE_GROUP_IMG":
			//     alert("CALL API TO DELETE GROUP IMAGE");
			//     break;
			default:
				break;
		}
		setShowWarningChatModal(false);
	};

	// console.log(
	//     "%c SELECTED CONVERSATION >>",
	//     "color: orange; font-weight: bolder;",
	//     selectedConveration
	// );

	const handleCloseWarningModal = () => {
		setSelectedMessages([]);
		setShowWarningChatModal(false);
	};

	//! =============== CLEAR CONVERSATION HANDLER ===============

	const handleClearChat = () => {
		setShowWarningChatModal(true);
		setWarningChatModalAction("CLEAR");
		// TODO: NEED TO CLEAR THE LEFT SIDE CONVERSATION MESSAGE AS WELL !!!
	};

	useEffect(() => {
		if (!clearConvApi.loading && clearConvApi.responseCode === 200) {
			queryClient.invalidateQueries([
				"messages",
				selectedConveration?.uuid,
			]);
		}
	}, [clearConvApi.loading, clearConvApi.responseCode]);

	//! =============== DELETE MESSAGE(S) HANDLER ================

	const canDeleteForAll = (messages) => {
		const currentUserId = localStorage.getItem("uuid");
		// TODO: ADD ALSO CHECK FOR "DELETED: true" flag (when message is showing "this message has been deleted")
		const canDeleteForAll = messages.some(
			(msg) =>
				msg.messageTime + 300000 < new Date().getTime() ||
				msg.sendBy.uuid !== currentUserId
		);
		return canDeleteForAll;
	};

	const handleSingleDelete = (messageItem) => {
		setSelectedMessages([messageItem]);
		setShowWarningChatModal(true);
		setWarningChatModalAction("DELETE_MSG");

		if (!canDeleteForAll([messageItem]))
			setWarningChatModalActionAlt("DELETE_MSG_4ALL");
		else setWarningChatModalActionAlt("");
	};

	const handleDeleteMessage = () => {
		setShowWarningChatModal(true);
		setWarningChatModalAction("DELETE_MSG");

		if (!canDeleteForAll(selectedMessages))
			setWarningChatModalActionAlt("DELETE_MSG_4ALL");
		else setWarningChatModalActionAlt("");
	};

	useEffect(() => {
		if (
			!deleteMessagesApi.loading &&
			deleteMessagesApi.responseCode === 200
		) {
			queryClient.invalidateQueries([
				"messages",
				selectedConveration?.uuid,
			]);

			setSelectedMessages([]);
		}
	}, [deleteMessagesApi.loading, deleteMessagesApi.responseCode]);

	useEffect(() => {
		if (
			!deleteMessagesForAllApi.loading &&
			deleteMessagesForAllApi.responseCode === 200
		) {
			queryClient.invalidateQueries([
				"messages",
				selectedConveration?.uuid,
			]);

			setSelectedMessages([]);
		}
	}, [deleteMessagesForAllApi.loading, deleteMessagesForAllApi.responseCode]);

	//! =============== DELETE CONVERSATION HANDLER ==============

	const removeConvFromUi = () => {
		// if (isInfoOpened) setIsInfoOpened(false);
		if (isInfoOpened) dispatch(updateIsInfoOpened(false));
		queryClient.invalidateQueries(["conversations"]);
		setSelectedConveration(null);
		localStorage.removeItem(storedConvKey);
	};

	const {
		action: { mutate: deleteConv },
	} = useMutate(chatApi.deleteConversation, removeConvFromUi);

	const handleDeleteConv = () => {
		setShowWarningChatModal(true);
		setWarningChatModalAction("DELETE_CONV");
	};

	//! =============== LEAVE GROUP HANDLER ======================

	const handleLeaveGroup = () => {
		setShowWarningChatModal(true);
		setWarningChatModalAction("LEAVE_GROUP");
	};

	const {
		action: { mutate: leaveGroup },
	} = useMutate(chatApi.exitGroup, removeConvFromUi);

	//! =============== REPORT CONNECTION HANDLER ================

	const handleReport = () => {
		setShowWarningChatModal(true);
		setWarningChatModalAction("REPORT");
	};

	const reportRef = useRef();

	const handleReportGroup = () => {
		reportRef.current.showReportModal(selectedConveration?.groupDTO?.uuid);
		// console.log(selectedConveration);
		// setShowWarningChatModal(true);
		// setWarningChatModalAction("REPORT_GROUP");
	};

	//! =============== MUTE/UNMUTE HANDLER ======================

	const [muteModal, setMuteModal] = useState(false);
	const [muteDuration, setMuteDuration] = useState("");

	const closeMuteModal = () => {
		setMuteDuration("");
		setMuteModal(false);
	};

	const {
		action: { mutate: muteConv },
	} = useMutate(chatApi.muteConv, () => updateMuteStatus(true));

	const {
		action: { mutate: unmuteConv },
	} = useMutate(chatApi.unmuteConv, () => updateMuteStatus(false));

	const handleConfirmMute = () => {
		muteConv({
			convId: selectedConveration.uuid,
			muteDuration,
		});
		closeMuteModal();
	};

	const handleUnmute = () => {
		unmuteConv(selectedConveration.uuid);
	};

	const updateMuteStatus = (status) => {
		setSelectedConveration((prevState) => ({
			...prevState,
			muted: status,
		}));

		queryClient.setQueryData(["conversations"], (prevData) => {
			const pagesCopy = [...prevData.pages];

			const pageIndex = pagesCopy.findIndex((page) =>
				page.find((conv) => conv.uuid === selectedConveration?.uuid)
			);

			const convIndex = pagesCopy[pageIndex].findIndex(
				(conv) => conv.uuid === selectedConveration?.uuid
			);

			pagesCopy[pageIndex][convIndex]["muted"] = status;

			return {
				...prevData,
				pages: pagesCopy,
			};
		});
	};

	//! ========================== TEMP CONV ================================
	const [shouldRedirectOnConvo, setShouldRedirectOnConvo] = useState(false); //for mobile view

	const {
		action: { mutate: getConvo },
	} = useMutate(chatApi.getConversation, ({ data }) => {
		if (shouldRedirectOnConvo) {
			handleClickConversation(data);
		} else {
			setSelectedConveration(data);
		}
		setShouldRedirectOnConvo(false);
	});

	// const getConversationApi = useApi(chatApi.getConversation);

	const handleNewConversation = (profile) => {
		if (profile.conversationId) {
			// getConversationApi.request(profile.conversationId);
			setShouldRedirectOnConvo(true);
			getConvo(profile.conversationId);
		} else {
			handleClickConversation(
				{
					conversationType: "Default",
					minifiedProfile: profile,
					isTemp: true,
				},
				true
			);

			// setMessages([]); //! setQueryData impacted ==> nothing todo
		}
	};

	// useEffect(() => {
	// 	if (
	// 		!getConversationApi.loading &&
	// 		getConversationApi.data &&
	// 		getConversationApi.responseCode === 200
	// 	) {
	// 		console.log("CONVO FETCHED >>", getConversationApi.data);
	// 		handleClickConversation(getConversationApi.data);
	// 	}
	// }, [getConversationApi.data]);

	//! ========================== NEW GROUP ================================

	const handleNewGroup = (newConv) => {
		const isGroup = true;
		handleClickConversation(newConv, isGroup);

		queryClient.setQueryData(["conversations"], (prevData) => {
			const pagesCopy = [...prevData.pages];

			pagesCopy[0] = [newConv, ...pagesCopy[0]];

			const updatedPages = movePageItems(pagesCopy, conversationsPerPage);

			return {
				...prevData,
				pages: updatedPages,
			};
		});
	};

	//! ========================== UPDATE PARTICIPANT COUNT ================================
	const handleUpdateCount = (type, newCount, isAdmin) => {
		if (type === "ADD") {
			setSelectedConveration((prevState) => ({
				...prevState,
				groupDTO: {
					...prevState.groupDTO,
					totalMembers: prevState.groupDTO.totalMembers + newCount,
					totalParticipants:
						prevState.groupDTO.totalParticipants + newCount,
				},
			}));
		} else if (type === "DELETE") {
			// UPDATE COUNT DEPENDING ON IF YOU'RE REMOVING AN ADMIN OR A PARTICIPANT
			setSelectedConveration((prevState) => ({
				...prevState,
				groupDTO: {
					...prevState.groupDTO,
					...(isAdmin
						? {
								totalAdmins:
									prevState.groupDTO.totalAdmins - newCount,
						  }
						: {
								totalMembers:
									prevState.groupDTO.totalMembers - newCount,
						  }),
					totalParticipants:
						prevState.groupDTO.totalParticipants - newCount,
				},
			}));
		} else {
			// SWAP FROM ADMIN TO PARTICIPANT OR THE OPPOSITE
			setSelectedConveration((prevState) => ({
				...prevState,
				groupDTO: {
					...prevState.groupDTO,
					...(isAdmin
						? {
								totalAdmins:
									prevState.groupDTO.totalAdmins + newCount,
								totalMembers:
									prevState.groupDTO.totalMembers - newCount,
						  }
						: {
								totalAdmins:
									prevState.groupDTO.totalAdmins - newCount,
								totalMembers:
									prevState.groupDTO.totalMembers + newCount,
						  }),
				},
			}));
		}
	};

	//! ========================== UPDATE GROUP INFO ================================

	const handleUpdateGroupInfo = (newGroupInfo) => {
		setSelectedConveration(newGroupInfo);
	};

	/**
	 * When detecting socket response and the user on the chat page
	 * =>
	 */

	const socketResponse = useSelector((state) => state.socket.response);

	useEffect(() => {
		if (socketResponse && isVisible) {
			// console.log("Socket response detected....");
			// console.log("THE SOCKET RESPONSE >>", socketResponse);

			const { type, response } = socketResponse;

			switch (type) {
				case "MESSAGE_OBJ":
					response.message.deleted
						? queryClient.invalidateQueries([
								"messages",
								selectedConveration?.uuid,
						  ])
						: handleReceivedMessageViaSocket(response);
					break;
				case "ACK":
					handleAcknowledge(response);

					break;
				default:
					break;
			}
		}
	}, [socketResponse]);
	/**
	 * Effects
	 */

	// ! ========= LOADING CONVERSATIONS ============

	const getMessagesApi = useApi(chatApi.getMessages, true, true);

	const { ref: msgViewRef, inView: msgInView } = useInView();

	const fetchMessages = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, conversationId] = queryKey;

		const response = await getMessagesApi.request(
			conversationId,
			pageParam,
			messagesPerPage
		);
		return response.data;
	};

	const {
		data: messages,
		hasNextPage: hasNextPageMsg,
		fetchNextPage: fetchNextPageMsg,
		isFetching: isFetchingMsg,
	} = useInfiniteQuery({
		queryKey: ["messages", selectedConveration?.uuid],
		queryFn: fetchMessages,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === messagesPerPage ? pages.length : undefined;
			return nextPage;
		},
		enabled: !!selectedConveration?.uuid,
		initialData: { pageParams: [undefined], pages: [[]] },
	});

	useEffect(() => {
		if (msgInView && hasNextPageMsg) {
			fetchNextPageMsg();
		}
	}, [msgInView, hasNextPageMsg]);

	useEffect(() => {
		if (messages.pages[0].length > 0) {
			setMessagesWithMedias(viewerMapper(filterDeleted(messages)));
		}
	}, [messages.pages]);

	// !======

	/*  When conversation changed call api to get messages */

	const footerRef = useRef(null);

	const chatTotalCount = useSelector((state) => state.ui.chatTotalCount);

	const handleClickConversation = (
		clickedConversation,
		isNewConvo = false //only used in one place, should be replaced with clickConversation.temp
	) => {
		// console.log(
		// 	"%c 🚀 ~ CLICKED CONVO >>",
		// 	"color: cyan; font-weight: bolder;",
		// 	clickedConversation
		// );
		footerRef.current?.clearInput();

		localStorage.setItem(storedConvKey, clickedConversation.uuid);
		setSelectedConveration(clickedConversation);
		// if (isInfoOpened) setIsInfoOpened(false);
		if (isInfoOpened) dispatch(updateIsInfoOpened(false));

		if (isMobile) {
			setShowConvoList(false);
			setInConvo(true);
		}

		handleCloseReply();
		handleCloseSelect();

		//* marking convo as read

		if (
			clickedConversation?.message &&
			clickedConversation?.message?.messageStatus !==
				deliveryStatus.read &&
			!isNewConvo
		) {
			const sendBy = clickedConversation?.message?.sendBy;

			markAsRead({
				from: sendBy ? sendBy.uuid : null,
				participantId: userId,
				conversationId: clickedConversation.message.conversationId,
				messageId: clickedConversation.message.messageId,
				messageStatus: deliveryStatus.read,
				unreadCount: clickedConversation.unreadCount,
			});
		}
	};

	useEffect(() => {
		//* removing the "unread" count
		if (selectedConveration?.uuid && selectedConveration?.unreadCount > 0) {
			queryClient.setQueryData(["conversations"], (prevData) => {
				const pagesCopy = [...prevData.pages];
				const pageIndex = pagesCopy.findIndex((page) =>
					page.find((conv) => conv.uuid === selectedConveration.uuid)
				);
				if (pageIndex > -1) {
					const convIndex = pagesCopy[pageIndex].findIndex(
						(conv) => conv.uuid === selectedConveration.uuid
					);

					pagesCopy[pageIndex][convIndex]["unreadCount"] = 0;

					return {
						...prevData,
						pages: pagesCopy,
					};
				}
			});
		}
	}, [selectedConveration]);

	//! ====== LOADING MESSAGES  ======

	const getConversationsApi = useApi(chatApi.getConversations, true, true);

	const { ref: viewRef, inView } = useInView();

	const fetchConversations = async ({ pageParam = 0, signal }) => {
		const response = await getConversationsApi.request(
			pageParam,
			conversationsPerPage
		);
		return response.data;
	};

	const {
		data: conversations,
		hasNextPage,
		fetchNextPage,
		isFetching,
	} = useInfiniteQuery({
		queryKey: ["conversations"],
		queryFn: fetchConversations,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === conversationsPerPage
					? pages.length
					: undefined;
			return nextPage;
		},
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	//* effect to get the active conversation when loading chat
	useEffect(() => {
		if (
			conversations?.pages[0].length > 0 &&
			selectedConveration === null &&
			newChat === null
		) {
			// check the stored conversation
			const storedConvId = localStorage.getItem(storedConvKey);
			if (storedConvId) {
				const pageIndex = conversations.pages.findIndex((page) =>
					page.find((conv) => conv.uuid === storedConvId)
				);

				if (pageIndex > -1) {
					const convIndex = conversations.pages[pageIndex].findIndex(
						(conv) => conv.uuid === storedConvId
					);

					setSelectedConveration(
						conversations.pages[pageIndex][convIndex]
					);
				}
			}
		}
	}, [conversations]);

	/**
	 * When getting message response after send via api
	 * => add message to message state
	 */

	const [sendRestOfMedias, setSendRestOfMedias] = useState(false);

	useEffect(() => {
		if (restOfMedias.length > 0 && sendRestOfMedias) {
			restOfMedias.forEach((media) => {
				handleSendMessageMutation({
					request: media,
					progressFn: (progress) => setProgress(progress),
				});
			});

			setRestOfMedias([]);
			setSendRestOfMedias(false);
		}
	}, [sendRestOfMedias]);

	/**
	 * JSX
	 */
	return (
		<div ref={chatRef}>
			<ChatPage
				isInfoOpened={isInfoOpened}
				inConvo={inConvo}
				showConvoList={showConvoList}
				// hasMobileHeader={!showConvoList} //condition based on if showConvo
				leftSideChildren={
					<ChatLeftSide
						// newChat={newChat}
						// onCreateTempConv={handleTempConv}
						conversation={selectedConveration}
						onConversationClicked={handleClickConversation}
						onNewConv={handleNewConversation}
						onCreateGroup={handleNewGroup}
						loading={isFetching}
						convRef={viewRef}
						conversations={conversations}
						tempConv={selectedConveration?.isTemp}
					/>
				}
				rightSideChildren={
					<ChatRightSide
						convoHeader={
							<RightSideHeader
								onProfileClick={handleClick}
								handleBack={handleBack}
								conversation={selectedConveration}
								onClear={handleClearChat}
								onExport={handleExport}
								onSelect={handleSelect}
								onDelete={
									selectedConveration?.conversationType ===
									"Default"
										? handleDeleteConv
										: handleLeaveGroup
								}
								onMute={() => setMuteModal(true)}
								onUnmute={handleUnmute}
								isInfoOpened={isInfoOpened}
							/>
						}
						isInfoOpened={isInfoOpened}
						messagesPerPage={messagesPerPage}
						userProfile={profile}
						loadingProfile={
							isProfileLoading && fetchStatus !== "idle"
						}
						messages={messages}
						msgRef={msgViewRef}
						hasNextPageMsg={hasNextPageMsg}
						conversation={selectedConveration}
						loading={isFetchingMsg}
						// onLoadMore={loadMoreMessages}
						onReply={handleReply}
						replyHeight={replyHeight}
						selectActive={selectActive}
						onSelect={handleSelected}
						onForward={handleSingleForward}
						onDelete={handleSingleDelete}
						selectedMessages={selectedMessages}
						onRefetch={refetch}
						onReport={handleReport}
						onClear={handleClearChat}
						onExport={handleExport}
						onReportGrp={handleReportGroup}
						onExit={handleLeaveGroup}
						onMute={() => setMuteModal(true)}
						onUnmute={handleUnmute}
						onUpdateCount={handleUpdateCount}
						onUpdateGroupInfo={handleUpdateGroupInfo}
						onDirectMsg={handleNewConversation}
						onShowSlider={handleShowSlider}
					/>
				}
				footerContent={
					<RightSideFooter
						ref={footerRef}
						conversation={selectedConveration}
						onSendMessage={handleSendingMessage}
						replyItem={replyItem}
						onHeight={handleReplyHeight}
						onCloseReply={handleCloseReply}
						onCloseSelect={handleCloseSelect}
						selectActive={selectActive}
						selectedCounter={selectedMessages.length}
						onForwardMessage={() => setForwardModalOpened(true)}
						onDeleteMessage={handleDeleteMessage}
					/>
				}
				rightProiority={localStorage.getItem("convId")}
			/>

			<FullScreenModal
				show={showSlider}
				fullWidth={true}
				onClose={() => setShowSlider(false)}
				header={
					<ViewerHeader
						onClose={() => setShowSlider(false)}
						leftSide={
							<ShowChatProfile
								data={selectedConveration}
								// typing={selectedConveration?.typing}
								// isGroup={conversation.groupDTO}
								// totalparticipants={totalParticipants}
								handleProfileClick={() => {
									setShowSlider(false);
									handleClick();
								}}
								fontColor={"#FFF"}
							/>
						}
						rightSide={
							<button>
								<img
									src={fullscreenIcon}
									className={styles.fullscreen_icon}
									alt="Fullscreen"
									onClick={() =>
										fileviewerRef.current.enterFullscreen()
									}
								/>
							</button>
						} //TODO ADD 3 DOTS 'MORE OPTIONS'
					/>
				}
				children={
					<div className={styles.fileviewer_container}>
						<FileViewer
							ref={fileviewerRef}
							// disableScrollToEnd={true}
							files={messagesWithMedias}
							initialSlide={initialSlideIndex}
							onLoadMore={handleLoadMore}
							isLoading={isFetchingMedia}
							onActiveSlide={(index) =>
								setActiveSlideIndex(index)
							}
							text={
								//TODO - setQueryData impacted
								messagesWithMedias[activeSlideIndex]?.message
							}
						/>
					</div>
				}
			/>

			<GaawkModal
				show={muteModal}
				handleClose={closeMuteModal}
				defaultModal={false}
				showHeader={true}
				title={"Mute"}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.info_text}>
							<img
								src={muteIcon}
								className={styles.muteIcon}
								alt="Mute notifications"
							/>
							Please select how long you want this conversation to
							be muted:
						</div>
						<div className={styles.radio_container}>
							<div
								className={styles.radio_wrapper}
								onClick={() => setMuteDuration(1)}
							>
								<CustomRadioButton
									checked={muteDuration === 1}
									readOnly={true}
								/>
								<p>1 hour</p>
							</div>
							<div
								className={styles.radio_wrapper}
								onClick={() => setMuteDuration(8)}
							>
								<CustomRadioButton
									checked={muteDuration === 8}
									readOnly={true}
								/>
								<p>8 hours</p>
							</div>
							<div
								className={styles.radio_wrapper}
								onClick={() => setMuteDuration(0)}
							>
								<CustomRadioButton
									checked={muteDuration === 0}
									readOnly={true}
								/>
								<p>Until I unmute</p>
							</div>
						</div>
						<div className={styles.btn_container}>
							<PrimaryButton
								text={"mute"}
								onClick={handleConfirmMute}
							/>
						</div>
					</div>
				}
			/>

			{forwardModalOpened && (
				<ForwardModal
					isModalOpened={forwardModalOpened}
					onClose={handleCloseForward}
					onSelect={handleSelectedConv}
					selectedConvs={forwardConvIds}
					onForward={handleForward}
				/>
			)}

			<SelectAdminModal
				show={showAdminModal}
				onClose={() => setShowAdminModal(false)}
				groupId={selectedConveration?.groupDTO?.uuid}
				onClick={(profile) =>
					assignAdmin({
						assigneeId: profile.uuid,
						groupId: selectedConveration.groupDTO.uuid,
					})
				}
			/>

			{selectedConveration && (
				<WarningChatModal
					show={showWarningChatModal}
					action={warningChatModalAction}
					actionAlt={warningChatModalActionAlt}
					onConfirm={handleConfirmAction}
					onClose={handleCloseWarningModal}
					conversationItem={selectedConveration}
				/>
			)}

			<ReportModal ref={reportRef} category="group" />
		</div>
	);
};

export default Index;
