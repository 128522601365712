import React from "react";
import ContentLoader from "react-content-loader";

const GalleryLoader = (props) => {
    return (
        <ContentLoader
            style={{ height: "167px", width: "150px" }}
            backgroundColor="#f2f3f5"
            foregroundColor="#c3c4c8"
            {...props}
        >
            <rect x="0" y="0" rx="12" ry="12" width="150" height="150" />
            <rect x="15" y="160" rx="3" ry="3" width="120" height="7" />
        </ContentLoader>
    );
};

export default GalleryLoader;
