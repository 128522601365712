import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useApi from "./useApi";
import profileApi from "../api/profile";
import { useDispatch } from "react-redux";
import { profileInfoUpdated } from "../store/slices/user";
import { companyInfoUpdated } from "../store/slices/company";

const useFetchProfile = (currentUser, companyId) => {
	const { userUrl } = useParams();
	const dispatch = useDispatch();

	const getUserByUrlApi = useApi(profileApi.getUserByUrl);
	const getUserProfileApi = useApi(profileApi.getUserProfile);
	const getCompanyProfileApi = useApi(profileApi.getCompanyProfile);

	const [profile, setProfile] = useState("");

	const [canEdit, setCanEdit] = useState(false);

	const fetchProfile = () => {
		if (userUrl === currentUser.url || !userUrl) {
			if (companyId) {
				getCompanyProfileApi.request(companyId);
			} else {
				getUserProfileApi.request(currentUser.uuid);
			}
		} else {
			getUserByUrlApi.request(userUrl);
		}
	};

	useEffect(() => {
		fetchProfile();
	}, [userUrl, companyId]);

	useEffect(() => {
		if (
			!getUserByUrlApi.loading &&
			getUserByUrlApi.data &&
			Object.keys(getUserByUrlApi.data).length > 0 &&
			getUserByUrlApi.responseCode === 200
		) {
			const reqUrl = getUserByUrlApi.data;
			if (reqUrl.type === "USER") {
				getUserProfileApi.request(reqUrl.uuid);
			} else if (reqUrl.type === "COMPANY") {
				getCompanyProfileApi.request(reqUrl.uuid);
			}
		} else if (
			!getUserByUrlApi.loading &&
			getUserByUrlApi.responseCode !== 200 &&
			getUserByUrlApi.responseCode !== 0
		) {
			throw new Response("", {
				statusText: `${getUserByUrlApi.data.message} url: ${userUrl}`,
				status: getUserByUrlApi.data.code,
			});
		}
	}, [getUserByUrlApi.data]);

	useEffect(() => {
		if (
			!getUserProfileApi.loading &&
			getUserProfileApi.responseCode === 200 &&
			getUserProfileApi.data &&
			Object.keys(getUserProfileApi.data).length > 0
		) {
			const user = getUserProfileApi.data;
			setProfile(user);
			if (currentUser.uuid === user.uuid)
				dispatch(profileInfoUpdated(user));
			setCanEdit(currentUser.uuid === user.uuid);
		}
	}, [getUserProfileApi.data]);

	useEffect(() => {
		if (
			!getCompanyProfileApi.loading &&
			getCompanyProfileApi.responseCode === 200 &&
			getCompanyProfileApi.data &&
			Object.keys(getCompanyProfileApi.data).length > 0
		) {
			const company = getCompanyProfileApi.data;
			setProfile(company);
			if (currentUser.uuid === company.uuid)
				dispatch(companyInfoUpdated(company));
			setCanEdit(currentUser.uuid === company.uuid);
		}
	}, [getCompanyProfileApi.data]);

	const handleUpdateFriendStatus = (status) => {
		setProfile((prevState) => ({
			...prevState,
			connection: { ...prevState.connection, friendStatus: status },
		}));
	};

	const handleUpdateWorkCircle = () => {
		setProfile((prevState) => ({
			...prevState,
			connection: {
				...prevState.connection,
				inMyWorkCircle: !prevState.connection.inMyWorkCircle,
			},
		}));
	};

	const handleUpdateFollow = () => {
		setProfile((prevState) => ({
			...prevState,
			connection: {
				...prevState.connection,
				following: !prevState.connection.following,
			},
		}));
	};

	const handleUpdateBlockStatus = () => {
		setProfile((prevState) => ({
			...prevState,
			connection: {
				...prevState.connection,
				blocking: true,
			},
		}));
	};

	const handleUnblock = (profile) => {
		if (profile.type === "USER") {
			getUserProfileApi.request(profile.uuid);
		} else {
			getCompanyProfileApi.request(profile.uuid);
		}
	};

	const handleUpdateFavorite = () => {
		setProfile((prevState) => ({
			...prevState,
			connection: {
				...prevState.connection,
				favorite: !prevState.connection.favorite,
			},
		}));
	};

	return {
		fetchProfile,
		profile,
		canEdit,
		handleUpdateFriendStatus,
		handleUpdateWorkCircle,
		handleUpdateFollow,
		handleUpdateBlockStatus,
		handleUpdateFavorite,
		handleUnblock,
	};
};

export default useFetchProfile;
