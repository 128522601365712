import { useForm } from "react-hook-form";
import styles from "./GenderForm.module.scss";
import { joiResolver } from "@hookform/resolvers/joi";
import genderSchema from "components/Registeration/Schema/gender-schema";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { customGenderValues } from "components/Utils/General";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import GaawkRadioButton from "components/Utils/SubComs/GaawkRadioButton/GaawkRadioButton";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { useDispatch, useSelector } from "react-redux";
import { updateStep2 } from "store/slices/registration";

const GenderForm = ({ onBack, onNext }) => {
	const dispatch = useDispatch();
	const { step2 } = useSelector((state) => state.registration);

	const {
		register,
		watch,
		formState: { errors },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(genderSchema),
		mode: "all",
		defaultValues: step2,
	});

	const watchAll = watch();

	const handleNext = (data) => {
		const { gender, customGender, optionalGender } = data;

		onNext({
			gender: gender.charAt(),
			genderPronoun:
				gender === "male"
					? "he"
					: gender === "female"
					? "she"
					: data.customGender.value,
			customGender:
				gender === "custom" && data.optionalGender
					? data.optionalGender
					: "",
		});

		dispatch(
			updateStep2({
				gender,
				customGender,
				optionalGender,
			})
		);
	};

	return (
		<form
			className={styles.form}
			onSubmit={handleSubmit((data) => handleNext(data))}
		>
			<button className={styles.arrow_btn} type="button" onClick={onBack}>
				<img
					src={require("images/back-arrow-black.svg").default}
					alt="back"
				/>
			</button>

			<h3>What's your gender?</h3>

			<p className={styles.description}>
				You can decide who can see your gender on your profile by
				applying visibility settings.
			</p>

			<div className={styles.radio_container}>
				<GaawkRadioButton
					{...register("gender", { required: true })}
					label={"Female"}
					id="female"
					value="female"
					name="gender"
				/>

				<GaawkRadioButton
					{...register("gender", { required: true })}
					label={"Male"}
					id="male"
					value="male"
					name="gender"
				/>

				<GaawkRadioButton
					{...register("gender", { required: true })}
					label={"Custom"}
					id="custom"
					value="custom"
					name="gender"
				/>
			</div>

			<InputWrapper
				className={
					watchAll.gender === "custom" ? undefined : styles.hide
				}
				label="Select your pronoun"
				required={true}
				error={errors?.customGender}
				customError={
					<p className={styles.error_message}>
						Please select an option
					</p>
				}
				component={
					<CustomSelect
						control={control}
						name="customGender"
						error={errors?.customGender}
						options={customGenderValues}
					/>
				}
			/>

			<InputWrapper
				className={
					watchAll.gender === "custom" ? undefined : styles.hide
				}
				label="Enter your gender"
				component={
					<TextInput
						{...register("optionalGender")}
						placeholder="Type your gender"
					/>
				}
			/>

			{errors?.gender?.message && (
				<p className={styles.error_message}>
					Please select your gender
				</p>
			)}

			<PrimaryButton text="next" className={styles.next_btn} />
		</form>
	);
};

export default GenderForm;
