import axios from "axios";
import * as actions from "../actionCreators/api";
import { JWTUpdated } from "../slices/jwt";
// import { updateLoading } from "../slices/ui";

import { toast } from "react-toastify";

let counter = 0;

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_USER = process.env.REACT_APP_AUTH_USER;
const AUTH_PASS = process.env.REACT_APP_AUTH_PASSWORD;

const api =
	({ dispatch, getState }) =>
	(next) =>
	async (action) => {
		// check if the type is not call api request
		if (action.type !== actions.apiCallBegan.type) return next(action);

		// get the trace in the log
		next(action);

		// this is the body of api middleware

		const savedToken = getState().jwt.token;

		if (!savedToken && !action.payload.skipAuth) {
			await genToken(dispatch, action);
		} else {
			let headerId = "";
			if (localStorage.getItem("uuid"))
				headerId = localStorage.getItem("uuid");
			else if (sessionStorage.getItem("uuid"))
				headerId = sessionStorage.getItem("uuid");

			// get the vars from the payload
			const {
				url,
				method,
				data,
				onSuccess,
				onError,
				showSuccessAlert = false,
				contentType = "application/json",
				passedData = false,
				skipAuth = false,
			} = action.payload;

			// call the api using axios
			try {
				// dispatch(updateLoading(true));

				const response = await axios.request({
					baseURL: BASE_URL,
					headers: {
						...(!skipAuth && {
							Authorization: `Bearer ${savedToken}`,
						}),
						userId: headerId,
						profileId: headerId,
						"Content-Type": contentType,
					},
					url,
					method,
					data,
				});

				// General success dispatching...
				dispatch(actions.apiCallSuccess(response.data));

				if (showSuccessAlert) toast.success("Success 🙌🏼");

				// Specific success dispatching...
				if (onSuccess) {
					if (passedData)
						dispatch({
							type: onSuccess,
							payload: {
								passedData: passedData,
								response: response.data,
							},
						});
					else dispatch({ type: onSuccess, payload: response.data });
				}
			} catch (error) {
				if (error.response) {
					const { status } = error.response.data;

					// recreate the token
					if (status === 401) {
						await genToken(dispatch, action);
					} else {
						// alert(error.response.data.message);
						toast.error(`Error ! ${error.response.data.message}`, {
							position: toast.POSITION.TOP_CENTER,
						});

						// general error dispatching...
						dispatch(actions.apiCallFailed(error.message));

						// specific error dispatching
						if (onError)
							dispatch({
								type: onError,
								payload: error.response,
							});
					}
				} else {
					console.log(error.response);
				}
			}
			// finally {
			//     dispatch(updateLoading(false));
			// }
		}
	};

const genToken = async (dispatch, action) => {
	if (counter >= 10) alert("can't get the token, !!!!");
	else {
		counter++;
		try {
			const response = await axios.request({
				baseURL: BASE_URL,
				url: "authenticate",
				method: "POST",
				data: {
					password: AUTH_PASS,
					username: AUTH_USER,
				},
			});

			// General success dispatching...
			dispatch(actions.apiCallSuccess(response.data));

			dispatch({ type: JWTUpdated.type, payload: response.data });
			dispatch(action);
		} catch (error) {
			console.error(error);

			// general error dispatching...
			dispatch(actions.apiCallFailed(error.message));
			alert(error.message);
		}
	}
};

export default api;
