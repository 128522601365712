import styles from "./ForUserSectionItem.module.scss";
import LazyImage from "components/Utils/LazyImage/LazyImage";

const ForUserSectionItem = ({item}) => {

    return <div className={styles.container}>
        <LazyImage customWrapper={styles.imgContainer} inlineStyle={styles.img} image={<img  src={item.img} alt={item.title}/>} />
        <span className={styles.title}>{item.title}</span>
        <span className={styles.details}>{item.details}</span>
    </div>;
};

export default ForUserSectionItem;