import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
// import LazyImage from "../../LazyImage/LazyImage";
import styles from "./ProfileTag.module.css";
import removeIcon from "images/cancel-btn-gray.svg";
import ProfilePic from "../../../Home/ProfilePic";
import Tooltip from "../../Tooltip/Tooltip";

const ProfileTag = ({ name, image, onRemove, type, size, url }) => {
	const containerRef = useRef(null);
	const navigate = useNavigate();

	return (
		<Tooltip label={name}>
			<div
				ref={containerRef}
				onClick={() => url && navigate(`/profile/${url}`)}
				className={`${styles.container} ${
					url ? styles.clickable : undefined
				}`}
			>
				{onRemove && (
					<img
						src={removeIcon}
						className={styles.remove_icon}
						onClick={onRemove}
						alt="remove"
					/>
				)}
				<ProfilePic
					thumbnail={image}
					enableName={false}
					name={name}
					w={size}
					h={size}
					type={type}
					noMarginRight={true}
				/>
			</div>
		</Tooltip>
	);
};

export default ProfileTag;
