import React, { useEffect, useState } from "react";
import GridView from "./GridView";
import ListView from "./ListView";

const VaultItem = ({
	item,
	layout = "grid",
	// isRecent,
	isActive,
	selectedItems,
	onSelect,

	// openedPopoverId,
	// onPopover,
	onCopy,
	onFavorite,
	onDelete,
	onDetails,
	onShare,
	onMove,
	onRename,
	onClick,
}) => {
	// const {uuid} = item;

	/**
	 * States
	 */
	const [showTickSelect, setShowTickSelect] = useState(isActive);
	const [isSelected, setIsSelected] = useState(false);

	/**
	 * Effects
	 */

	useEffect(() => {
		if (selectedItems) {
			const selectedIds = selectedItems.map((item) => item.uuid);

			if (selectedIds.includes(item.uuid)) {
				setIsSelected(true);
			} else {
				setIsSelected(false);
			}
		}
	}, [selectedItems]);

	useEffect(() => {
		setShowTickSelect(isActive);
	}, [isActive]);

	/**
	 * Handlers
	 */
	const handleSelected = (e) => {
		if (e && e.stopPropagation) e.stopPropagation();

		setIsSelected((prevState) => !prevState);
		onSelect(item);
	};

	const handleFavorite = (e) => {
		if (e && e.stopPropagation) e.stopPropagation();
		onFavorite(item);
	};

	const handleShare = (e) => {
		if (e && e.stopPropagation) e.stopPropagation();
		onShare(item);
	};

	const handleCopy = (e) => {
		if (e && e.stopPropagation) e.stopPropagation();
		onCopy(item);
	};

	const handleMove = (e) => {
		if (e && e.stopPropagation) e.stopPropagation();
		onMove(item);
	};

	const handleDelete = (e) => {
		if (e && e.stopPropagation) e.stopPropagation();
		onDelete(item);
	};

	const handleDetails = (e) => {
		if (e && e.stopPropagation) e.stopPropagation();
		onDetails(item);
	};

	const handleRename = (e) => {
		if (e && e.stopPropagation) e.stopPropagation();
		onRename(item);
	};

	const handleMouseOver = () => {
		setShowTickSelect(true);
	};

	const handleMouseLeave = () => {
		if (!isActive) {
			setShowTickSelect(false);
		}
	};

	return (
		<>
			{layout === "grid" ? (
				<GridView
					item={item}
					// isRecent={isRecent}
					isSelected={isSelected}
					isActive={isActive}
					showTickSelect={showTickSelect}
					handleSelected={handleSelected}
					onMouseOver={handleMouseOver}
					onMouseLeave={handleMouseLeave}
					onShare={handleShare}
					onCopy={handleCopy}
					onMove={handleMove}
					onDetails={handleDetails}
					onFavorite={handleFavorite}
					onDelete={handleDelete}
					onRename={handleRename}
					// onPopover={onPopover}
					// openedPopoverId={openedPopoverId}
					onClick={onClick}
				/>
			) : (
				<ListView
					item={item}
					// isRecent={isRecent}
					isSelected={isSelected}
					isActive={isActive}
					showTickSelect={showTickSelect}
					handleSelected={handleSelected}
					onMouseOver={handleMouseOver}
					onMouseLeave={handleMouseLeave}
					onShare={handleShare}
					onCopy={handleCopy}
					onMove={handleMove}
					onDetails={handleDetails}
					onFavorite={handleFavorite}
					onDelete={handleDelete}
					onRename={handleRename}
					// onPopover={onPopover}
					// openedPopoverId={openedPopoverId}
					onClick={onClick}
				/>
			)}
		</>
	);
};

export default VaultItem;
