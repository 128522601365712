import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";
import { comparer } from "../../components/Utils/General";

const slice = createSlice({
    name: "Group",
    initialState: {
        data: [
            {
                groupId: "",
                admins: [],
                members: [],
                hasMoreAdmins: true,
                hasMoreMembers: true,
            },
        ],
    },
    reducers: {
        membersUpdated: (state, action) => {
            const members = action.payload.response;

            if (members.length === 0) {
                // DO NOTHING
            } else {
                const groupId = action.payload.passedData;
                const index = state.data.findIndex(
                    (data) => data.groupId === groupId
                );

                if (index >= 0) {
                    const storeMembers = state.data[index].members;

                    const membersToAppend = members.filter(
                        comparer(storeMembers)
                    );

                    state.data[index] = {
                        ...state.data[index],
                        members: [
                            ...state.data[index].members,
                            ...membersToAppend,
                        ],
                        hasMoreMembers: membersToAppend.length === 10,
                    };
                } else {
                    const dataItem = {
                        groupId: groupId,
                        members: members,
                        hasMoreMembers: members.length === 10,
                        admins: [],
                        hasMoreAdmins: true,
                    };

                    return {
                        data: [...state.data, dataItem],
                    };
                }
            }
        },
        adminsUpdated: (state, action) => {
            const admins = action.payload.response;

            if (admins.length === 0) {
                // DO NOTHING
            } else {
                const groupId = action.payload.passedData;
                const index = state.data.findIndex(
                    (data) => data.groupId === groupId
                );

                if (index >= 0) {
                    const storeAdmins = state.data[index].admins;

                    const adminsToAppend = admins.filter(comparer(storeAdmins));

                    state.data[index] = {
                        ...state.data[index],
                        admins: [
                            ...state.data[index].admins,
                            ...adminsToAppend,
                        ],
                        hasMoreAdmins: adminsToAppend.length === 10,
                    };
                } else {
                    const dataItem = {
                        groupId: groupId,
                        admins: admins,
                        hasMoreAdmins: admins.length === 10,
                        members: [],
                        hasMoreMembers: true,
                    };

                    return {
                        ...state,
                        data: [...state.data, dataItem],
                    };
                }
            }
        },
        participantDeleted: (state, action) => {
            const { groupId, profileIds } = action.payload.passedData;

            // 1 - FIND THE GROUP USING THE INDEX
            const index = state.data.findIndex(
                (data) => data.groupId === groupId
            );

            if (index >= 0) {
                let groupAdmins = state.data[index].admins;
                let groupMembers = state.data[index].members;

                if (profileIds.length > 1) {
                    // REMOVAL OF MULTIPLE PROFILES FROM THE GROUP
                } else {
                    // REMOVAL 1 PERSON

                    const adminIndex = groupAdmins.findIndex(
                        (admin) => admin.uuid === profileIds[0]
                    );

                    // Is user an admin ?
                    if (adminIndex >= 0) {
                        // Yes user is admin

                        groupAdmins = groupAdmins.filter(
                            (admin) => admin.uuid !== profileIds[0]
                        );

                        state.data[index] = {
                            ...state.data[index],
                            admins: groupAdmins,
                        };
                    } else {
                        // User is not admin
                        const memberIndex = groupMembers.findIndex(
                            (member) => member.uuid === profileIds[0]
                        );
                        if (memberIndex >= 0) {
                            groupMembers = groupMembers.filter(
                                (member) => member.uuid !== profileIds[0]
                            );

                            state.data[index] = {
                                ...state.data[index],
                                members: groupMembers,
                            };
                        }
                    }
                }
            }
        },
        adminAssigned: (state, action) => {
            const { groupId, assigneeId } = action.payload.passedData;

            // FIND THE GROUP INDEX
            const groupIndex = state.data.findIndex(
                (data) => data.groupId === groupId
            );

            if (groupIndex >= 0) {
                let groupAdmins = state.data[groupIndex].admins;
                let groupMembers = state.data[groupIndex].members;

                // FIND MEMBER INDEX
                const memberIndex = groupMembers.findIndex(
                    (member) => member.uuid === assigneeId
                );

                if (memberIndex >= 0) {
                    // ADD USER TO ADMINS
                    groupAdmins.push(groupMembers[memberIndex]);

                    // REMOVE USER FROM MEMBERS
                    groupMembers = groupMembers.filter(
                        (member) => member.uuid !== assigneeId
                    );

                    // RETURN STATE
                    state.data[groupIndex] = {
                        ...state.data[groupIndex],
                        members: groupMembers,
                        admins: groupAdmins,
                    };
                }
            }
        },
        adminRevoked: (state, action) => {
            const { groupId, assigneeId } = action.payload.passedData;

            // FIND THE GROUP INDEX
            const groupIndex = state.data.findIndex(
                (data) => data.groupId === groupId
            );

            if (groupIndex >= 0) {
                let groupAdmins = state.data[groupIndex].admins;
                let groupMembers = state.data[groupIndex].members;

                // FIND ADMIN INDEX
                const adminIndex = groupAdmins.findIndex(
                    (admin) => admin.uuid === assigneeId
                );

                if (adminIndex >= 0) {
                    // ADD USER TO MEMBERS
                    groupMembers.push(groupAdmins[adminIndex]);

                    // REMOVE USER FROM ADMINS
                    groupAdmins = groupAdmins.filter(
                        (admin) => admin.uuid !== assigneeId
                    );

                    // RETURN STATE
                    state.data[groupIndex] = {
                        ...state.data[groupIndex],
                        members: groupMembers,
                        admins: groupAdmins,
                    };
                }
            }
        },
    },
});

export const {
    membersUpdated,
    adminsUpdated,
    participantDeleted,
    adminAssigned,
    adminRevoked,
} = slice.actions;
export default slice.reducer;

/**
 * get participants
 */

export const getParticipants = (groupId, page, size) =>
    apiActions.apiCallBegan({
        url: `/conversation/group/participants?groupId=${groupId}&page=${page}&size=${size}&type=MEMBER`,
        passedData: groupId,
        method: "GET",
        onSuccess: membersUpdated.type,
    });

export const getAdmins = (groupId, page, size) =>
    apiActions.apiCallBegan({
        url: `/conversation/group/participants?groupId=${groupId}&page=${page}&size=${size}&type=ADMIN`,
        passedData: groupId,
        method: "GET",
        onSuccess: adminsUpdated.type,
    });

/**
 * Add Participants
 */

/**
 * Remove Participants
 */

export const deleteParticipant = (groupId, profileIds) => {
    const profileIdsString = profileIds
        .map((userId) => `&profileIds=${userId}`)
        .join("");
    return apiActions.apiCallBegan({
        url: `/conversation/group/participants?groupId=${groupId}${profileIdsString}`,
        method: "DELETE",
        passedData: { groupId, profileIds },
        onSuccess: participantDeleted.type,
    });
};

/**
 * Make/Revoke Admins
 */

export const assignAdmin = (assigneeId, groupId) =>
    apiActions.apiCallBegan({
        url: `/conversation/group/assignAdmin?assigneeId=${assigneeId}&groupId=${groupId}`,
        passedData: { groupId, assigneeId },
        method: "PATCH",
        onSuccess: adminAssigned.type,
    });

export const revokeAdmin = (assigneeId, groupId) =>
    apiActions.apiCallBegan({
        url: `/conversation/group/revokeAdmin?assigneeId=${assigneeId}&groupId=${groupId}`,
        passedData: { groupId, assigneeId },
        method: "PATCH",
        onSuccess: adminRevoked.type,
    });
