import React from "react";
import { useMemo } from "react";
import styles from "./EditCalendar.module.css";
import EventItem from "./EventItem";
import MyCalendar from "./MyCalendar/MyCalendar";
import { useNavigate } from "react-router-dom";

const EditCalendar = ({ calendarEntries, onMonthChange, onDeleteEntry }) => {
	const navigate = useNavigate();

	const handleEdit = (event) => {
		navigate("/profile/calendar/edit", { state: event });
	};

	const handleDelete = (event) => {
		onDeleteEntry(event);
	};

	const eventList = useMemo(
		() =>
			calendarEntries.map((event) => (
				<EventItem
					key={event.uuid}
					onEdit={handleEdit}
					onDelete={handleDelete}
					event={event}
				/>
			)),
		[calendarEntries]
	);

	return (
		<div className={styles.container}>
			<div className={styles.sub_container}>
				<MyCalendar
					canEdit={true}
					calendarEntries={calendarEntries}
					onMonthChange={onMonthChange}
					onDeleteEntry={handleDelete}
				/>
			</div>

			{eventList.length > 0 && (
				<>
					<span className={styles.separator}></span>
					<div className={styles.sub_container}>{eventList}</div>
				</>
			)}
		</div>
	);
};

export default EditCalendar;
