import * as actions from "../actionCreators/api";

const store = ({dispatch}) => (next) => (action) => {
    // check if the type is not call api request
    if (action.type !== actions.storeBegan.type)
        return next(action);

    // get the trace in the log
    next(action);

    // get the vars from the payload
    const {onSuccess, data} = action.payload;

    dispatch({type: onSuccess, payload: data});

};

export default store;
