import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "search",
	initialState: {
		filters: {},
	},

	reducers: {
		updateFilters: (state, action) => {
			state.filters = action.payload;
		},
		resetFilters: (state) => {
			state.filters = {};
		},
	},
});

export const { updateFilters, resetFilters } = slice.actions;
export default slice.reducer;
