import React, { cloneElement } from "react";
import BackButton from "../components/Utils/Button/BackButton";
import DefaultPage from "../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "./BaseView.module.css";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import { useNavigate } from "react-router-dom";

const BaseView = ({
	component,
	headerText = "",
	defaultHeader = true,
	rightSideBgColor = "#f2f3f5",
	...rest
}) => {
	const navigate = useNavigate();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> {headerText}
				</div>
			}
			defaultHeader={defaultHeader}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={cloneElement(component, { ...rest })}
			rightSideBgColor={rightSideBgColor}
		/>
	);
};

export default BaseView;
