import { useInfiniteQuery } from "@tanstack/react-query";

const usePaginatedResource = (
	queryKey,
	queryFn,
	itemsPerPage = 20,
	enabled = true,
	queryOptions,
	getNextPageParamFn = undefined
) => {
	const queryData = useInfiniteQuery({
		queryKey: queryKey,
		queryFn: queryFn,
		getNextPageParam: (lastPage, pages) => {
			if (getNextPageParamFn) {
				getNextPageParamFn(lastPage, pages);
			} else {
				const nextPage =
					lastPage.length === itemsPerPage ? pages.length : undefined;
				return nextPage;
			}
		},
		enabled,
		...queryOptions,
	});

	return queryData;
};

export default usePaginatedResource;
