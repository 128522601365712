import React, { useState, useEffect } from "react";
import styles from "./Tabs.module.css";
import useApi from "../../../../hooks/useApi";
import useConnection from "../hooks/useConnection";
import profileApi from "../../../../api/profile";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupMember from "../../../Chat/GroupMember";
// import fullStar from "images/icon-metro-star-full-color.svg";
// import emptyStar from "images/icon-metro-star-full.svg";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";

const Favorites = ({ profile, query, count, onUpdateCounter }) => {
	const getFavoritesApi = useApi(profileApi.getFavorites);
	// const addFavoriteApi = useApi(profileApi.addFavorite);
	// const removeFavoriteApi = useApi(profileApi.removeFavorite);

	const {
		data: favorites,
		fetchData,
		// updateConnection,
	} = useConnection(getFavoritesApi, query, profile.uuid);

	// const [profileId, setProfileId] = useState("");

	// const handleFavStatus = (id, action) => {
	//     setProfileId(id);
	//     if (action === "ADD") {
	//         addFavoriteApi.request(id);
	//     } else {
	//         removeFavoriteApi.request(id);
	//     }
	// };

	const favoritesList = favorites.map((item) => (
		// <div className={styles.contact_item} key={item.uuid}>
		<GroupMember
			key={item.uuid}
			data={item}
			redirect={true}
			// rightSideAction={
			//     item.connection.favorite ? (
			//         <img
			//             src={fullStar}
			//             className={styles.action_icon}
			//             onClick={() => handleFavStatus(item.uuid, "REMOVE")}
			//             alt="remove from favorites"
			//         />
			//     ) : (
			//         <img
			//             src={emptyStar}
			//             className={styles.action_icon}
			//             onClick={() => handleFavStatus(item.uuid, "ADD")}
			//             alt="add to favorites"
			//         />
			//     )
			// }
		/>
		// </div>
	));

	// useEffect(() => {
	//     if (!addFavoriteApi.loading && addFavoriteApi.responseCode === 200) {
	//         updateConnection(profileId, "favorite");
	//         onUpdateCounter("favorites", "INCREMENT");
	//     }
	// }, [addFavoriteApi.loading, addFavoriteApi.responseCode]);

	// useEffect(() => {
	//     if (
	//         !removeFavoriteApi.loading &&
	//         removeFavoriteApi.responseCode === 200
	//     ) {
	//         updateConnection(profileId, "favorite");
	//         onUpdateCounter("favorites", "DECREMENT");
	//     }
	// }, [removeFavoriteApi.loading, removeFavoriteApi.responseCode]);

	return (
		<div id="scrollDiv" className={styles.container}>
			<InfiniteScroll
				dataLength={favorites.length}
				next={fetchData}
				hasMore={
					!query && favorites.length > 0 && favorites.length < count
				}
				loader={<ContactLoader />}
				scrollableTarget="scrollDiv"
			>
				{getFavoritesApi.loading && <ContactLoader />}
				{favoritesList.length > 0 &&
					!getFavoritesApi.loading &&
					favoritesList}
			</InfiniteScroll>
		</div>
	);
};

export default Favorites;
