import React from "react";
import styles from "./FileThumbnail.module.css";
import deleteIcon from "images/icon-exit.svg";
import LazyImage from "../../LazyImage/LazyImage";

const FileThumbnail = ({
	thumbnail,
	isImage,
	label,
	onDelete,
	customIconStyle,
	customStyle,
	labelStyle,
	containerStyle,
}) => {
	return (
		<div className={`${styles.container} ${containerStyle}`}>
			<div className={`${styles.thumb_wrapper} ${customStyle}`}>
				{onDelete && (
					<button
						type="button"
						className={styles.close}
						onClick={onDelete}
					>
						<img src={deleteIcon} alt={"delete"} />
					</button>
				)}

				<LazyImage
					image={<img src={thumbnail} alt="" />}
					inlineStyle={`${!isImage ? styles.icon : undefined} ${
						!isImage && customIconStyle
							? customIconStyle
							: undefined
					}`}
				/>
			</div>

			{label && <label className={labelStyle}>{label}</label>}
		</div>
	);
};

export default FileThumbnail;
