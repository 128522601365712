import { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "./PeopleFilter.module.scss";
import ConnectionFilter from "./ConnectionFilter";
import searchApi from "../../../api/search";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import {
	defaultFormatter,
	languageFormatter,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { peopleInitialFilters } from "../initialFilters";
import useSearchFilters from "../../../hooks/useSearchFilters";
import usePaginatedSearchFilters from "hooks/usePaginatedSearchFilters";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useFilterAndSort from "./useFilterAndSort";

const PeopleFilter = forwardRef(
	(
		{
			inputValue,
			onChange,
			onResetFilters,
			selectedFilters: selectedDynamicFilters,
			userCoordinates,
			companyId,
		},
		ref
	) => {
		const {
			initialFilters,
			data,
			isFetching,
			hasFilters,
			selectedFilters,
			setSelectedFilters,
		} = useSearchFilters(
			peopleInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"usersFilters",
			inputValue,
			searchApi.usersFilters,
			selectedDynamicFilters,
			onChange
		);

		const {
			hasNextPage: hasNextPageRoles,
			fetchNextPage: fetchNextPageRoles,
			isFetched: isFetchedRoles,
			refetch: refetchRoles,
			data: dynamicRoles,
		} = usePaginatedSearchFilters(
			peopleInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"usersRolesFilters",
			inputValue,
			searchApi.rolesFilters,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			hasNextPage: hasNextPageLanguages,
			fetchNextPage: fetchNextPageLanguages,
			isFetched: isFetchedLanguages,
			refetch: refetchLanguages,
			data: dynamicLanguages,
		} = usePaginatedSearchFilters(
			peopleInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"usersLanguagesFilters",
			inputValue,
			searchApi.languageFilters,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			hasNextPage: hasNextPageSkills,
			fetchNextPage: fetchNextPageSkills,
			isFetched: isFetchedSkills,
			refetch: refetchSkills,
			data: dynamicSkills,
		} = usePaginatedSearchFilters(
			peopleInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"usersSkillsFilters",
			inputValue,
			searchApi.skillsFilters,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			hasNextPage: hasNextPageSoftware,
			fetchNextPage: fetchNextPageSoftware,
			isFetched: isFetchedSoftware,
			refetch: refetchSoftware,
			data: dynamicSoftware,
		} = usePaginatedSearchFilters(
			peopleInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"usersSoftwareFilters",
			inputValue,
			searchApi.softwareFilters,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			roles: rolesList = [],
			software: softwareList = [],
			languages: languagesList = [],
			skills: skillsList = [],
		} = data || {};

		const roleRef = useRef();
		const softwareRef = useRef();
		const languageRef = useRef();
		const skillRef = useRef();

		const {
			roles = [],
			software = [],
			languages = [],
			skills = [],
			friends = false,
			inMyCircle = false,
			inTheirCircle = false,
		} = selectedFilters || {};

		const filteredLanguages = useFilterAndSort(
			languages,
			dynamicLanguages,
			languagesList,
			isFetchedLanguages,
			"code"
		);

		const filteredRoles = useFilterAndSort(
			roles,
			dynamicRoles,
			rolesList,
			isFetchedRoles
		);

		const filteredSkills = useFilterAndSort(
			skills,
			dynamicSkills,
			skillsList,
			isFetchedSkills
		);

		const filteredSoftware = useFilterAndSort(
			software,
			dynamicSoftware,
			softwareList,
			isFetchedSoftware
		);

		const handleClearFields = () => {
			if (hasFilters) {
				setSelectedFilters(initialFilters);
				// roleRef.current?.clear();
				// skillRef.current?.clear();
				// softwareRef.current?.clear();
				// languageRef.current?.clear();
				onResetFilters(initialFilters);
			}
		};

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "roles":
						roleRef.current.removeItem(tag);
						break;
					case "software":
						softwareRef.current.removeItem(tag);
						break;
					case "languages":
						languageRef.current.removeItem(tag);
						break;
					case "skills":
						skillRef.current.removeItem(tag);
						break;
					default:
						break;
				}
			},
		}));

		if (isFetching)
			return <LoadingSpinner customStyle={styles.loading_spinner} />;

		return (
			<div className={styles.container}>
				{rolesList?.length > 0 && (
					<div className={styles.field_wrapper}>
						<MultiCheckbox
							ref={roleRef}
							title={"Roles"}
							options={filteredRoles.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(roles) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									roles: roles,
								}));
							}}
							selected={roles}
							perRow="2, 150px"
							// customStyle={styles.multicheckbox_container}
						/>

						{((!isFetchedRoles && rolesList.length === 4) ||
							(isFetchedRoles && hasNextPageRoles)) && (
							<SeeMore
								onClick={
									!isFetchedRoles
										? refetchRoles
										: fetchNextPageRoles
								}
								className={styles.margin_top}
							/>
						)}
					</div>
				)}

				{softwareList?.length > 0 && (
					<div className={styles.field_wrapper}>
						<MultiCheckbox
							ref={softwareRef}
							title={"Software"}
							options={filteredSoftware.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(software) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									software: software,
								}));
							}}
							selected={software}
							perRow="2, 150px"
							// customStyle={styles.multicheckbox_container}
						/>

						{((!isFetchedSoftware && softwareList.length === 4) ||
							(isFetchedSoftware && hasNextPageSoftware)) && (
							<SeeMore
								onClick={
									!isFetchedSoftware
										? refetchSoftware
										: fetchNextPageSoftware
								}
								className={styles.margin_top}
							/>
						)}
					</div>
				)}

				{languagesList?.length > 0 && (
					<div className={styles.field_wrapper}>
						<MultiCheckbox
							ref={languageRef}
							title={"Languages"}
							options={filteredLanguages.map((item) =>
								languageFormatter(item)
							)}
							onSelect={(languages) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									languages: languages,
								}));
							}}
							selected={languages}
							perRow="2, 150px"
							// customStyle={styles.multicheckbox_container}
						/>

						{((!isFetchedLanguages && languagesList.length === 4) ||
							(isFetchedLanguages && hasNextPageLanguages)) && (
							<SeeMore
								onClick={
									!isFetchedLanguages
										? refetchLanguages
										: fetchNextPageLanguages
								}
								className={styles.margin_top}
							/>
						)}
					</div>
				)}

				{skillsList?.length > 0 && (
					<div className={styles.field_wrapper}>
						<MultiCheckbox
							ref={skillRef}
							title={"Skills"}
							options={filteredSkills.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(skills) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									skills: skills,
								}));
							}}
							selected={skills}
							perRow="2, 150px"
							// customStyle={styles.multicheckbox_container}
						/>

						{((!isFetchedSkills && skillsList.length === 4) ||
							(isFetchedSkills && hasNextPageSkills)) && (
							<SeeMore
								onClick={
									!isFetchedSkills
										? refetchSkills
										: fetchNextPageSkills
								}
								className={styles.margin_top}
							/>
						)}
					</div>
				)}

				<ConnectionFilter
					friends={friends}
					inMyCircle={inMyCircle}
					inTheirCircle={inTheirCircle}
					onFriendChange={
						!companyId &&
						((value) =>
							setSelectedFilters((prevState) => ({
								...prevState,
								friends: value,
							})))
					}
					onMyWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inMyCircle: value,
						}))
					}
					onTheirWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inTheirCircle: value,
						}))
					}
				/>

				<div className={styles.button_container}>
					<PrimaryButton
						type="button"
						text={"clear filters"}
						className={`${styles.btn} ${styles.clear}`}
						onClick={handleClearFields}
					/>
				</div>
			</div>
		);
	}
);

export default PeopleFilter;
