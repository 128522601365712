import Joi from "joi";

const emailSchema = Joi.object({
	description: Joi.string()
		.required()
		.messages({ "string.empty": "Description can't be empty" })
		.label("Description"),

	type: Joi.object({
		label: Joi.string(),
		value: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Type can't be empty",
			"object.base": "Type can't be empty",
		})
		.label("Type"),

	site: Joi.when("type.value", {
		is: "EMAIL",
		then: Joi.string()
			.email({ tlds: { allow: false } })
			.required()
			.label("Email")
			.messages({
				"string.empty": "Email can't be empty",
				"string.email": "Email should be valid",
			}),
		otherwise: Joi.string()
			.required()
			.messages({ "string.empty": "Field can't be empty" })
			.label("Website"),
	}),
});

export default emailSchema;
