import { useState } from "react";
import useWindowSize from "./useWindowSize";

export default function useGalleryCount() {
    const { width } = useWindowSize();

    // lazy initialization pasing a function as initial state (won't compute expression every render)
    const [itemsPerView] = useState(() =>
        width >= 1608
            ? 12
            : width < 1608 && width >= 1400
            ? 10
            : width < 1400 && width >= 1166
            ? 8
            : width < 1166 && width >= 640
            ? 6
            : 4
    );

    return itemsPerView;
}
