import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import RectangleCheckbox from "../../../Utils/SubComs/Inputs/Checkbox/RectangleCheckbox";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { addSoftware, updateSoftware } from "../../../../store/slices/user";

import styles from "./SoftwareEntry.module.css";
import { getSoftwareList } from "../../../../store/slices/software";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PromptModal from "components/Utils/Prompt/PromptModal";
import softwareSchema from "./software-schema";

const SoftwareEntry = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname, state } = useLocation();

	const editMode = pathname === "/profile/software/edit";

	const {
		uuid,
		activelyGaining,
		months: expMonths,
		years: expYears,
		proficiency: expProficiency,
		software,
	} = state || {};

	const {
		register,
		setValue,
		formState: { errors, isDirty },
		watch,
		handleSubmit,
		control,
		setError,
		clearErrors,
	} = useForm({
		resolver: joiResolver(softwareSchema),
		mode: "onChange",
		defaultValues: editMode
			? {
					proficiency: expProficiency,
					software: { label: software?.name, value: software?.uuid },
					expAmount: {
						years:
							expYears > 0
								? { label: expYears, value: expYears }
								: null,
						months:
							expMonths > 0
								? { label: expMonths, value: expMonths }
								: null,
					},
					activelyGaining,
			  }
			: {
					proficiency: null,
					software: null,
					expAmount: { years: null, months: null },
					activelyGaining: false,
			  },
	});

	const expWatcher = watch("expAmount");
	const proficiency = watch("proficiency");

	// console.log("%c ERRORS >>", "color: red;", errors);
	// console.log("========================");

	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			if (expWatcher.years || expWatcher.months) {
				clearErrors("expAmount");
			} else {
				setError("expAmount", {
					message: "Enter at least one: years or months",
				});
			}
		}
		didMountRef.current = true;
	}, [expWatcher.years, expWatcher.months]);

	const didMountRef2 = useRef(false);

	useEffect(() => {
		if (didMountRef2.current) {
			if (proficiency) {
				clearErrors("proficiency");
			} else {
				setError("proficiency", {
					message: "Proficiency is required",
				});
			}
		}
		didMountRef2.current = true;
	}, [proficiency]);

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	//TODO REPLACE BY CALLING THE API INSTEAD OF USING THE STORE

	const softwareList = useSelector((state) => state.software.list);

	const formattedSoftware = softwareList.map((item) => ({
		label: item.name,
		value: item.uuid,
	}));

	const handleSave = (data) => {
		const {
			activelyGaining,
			expAmount: { years, months },
			proficiency,
			software: { value: softwareId },
		} = data;

		dispatch(
			editMode
				? updateSoftware({
						uuid,
						activelyGaining,
						months: months ? months.value : 0,
						years: years ? years.value : 0,
						proficiency,
						shareAsPost: true,
						softwareId,
				  })
				: addSoftware({
						activelyGaining,
						months: months ? months.value : 0,
						years: years ? years.value : 0,
						proficiency,
						shareAsPost: true,
						softwareId,
				  })
		);

		setShouldBlockSave(false);
	};

	useEffect(() => {
		if (softwareList.length === 0) dispatch(getSoftwareList());
	}, [dispatch, softwareList]);

	useEffect(() => {
		if (!shouldBlockSave) navigate("/profile/software");
	}, [navigate, shouldBlockSave]);

	if (editMode && !state) {
		return <Navigate to="/profile/software" />;
	}

	return (
		<>
			<div className={styles.container}>
				<form
					className={styles.add_form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<div className={styles.form_input_wrapper}>
						<label>
							Software name
							<span className="required">*</span>
						</label>

						<CustomSelect
							options={formattedSoftware}
							placeholder="Search for a software..."
							height="35px"
							error={!!errors.software}
							control={control}
							name="software"
						/>

						{errors?.software?.message && (
							<p className={styles.error_message}>
								{errors?.software?.message}
							</p>
						)}
					</div>

					<div className={styles.form_input_wrapper}>
						<div
							className={`${styles.proficiency_wrapper} ${
								errors?.proficiency ? styles.error : undefined
							}`}
						>
							<button
								type="button"
								className={
									proficiency >= 1 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 1, {
										shouldDirty: true,
									})
								}
							>
								1
							</button>
							<button
								type="button"
								className={
									proficiency >= 2 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 2, {
										shouldDirty: true,
									})
								}
							>
								2
							</button>
							<button
								type="button"
								className={
									proficiency >= 3 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 3, {
										shouldDirty: true,
									})
								}
							>
								3
							</button>
							<button
								type="button"
								className={
									proficiency >= 4 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 4, {
										shouldDirty: true,
									})
								}
							>
								4
							</button>
							<button
								type="button"
								className={
									proficiency >= 5 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 5, {
										shouldDirty: true,
									})
								}
							>
								5
							</button>
							<button
								type="button"
								className={
									proficiency >= 6 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 6, {
										shouldDirty: true,
									})
								}
							>
								6
							</button>
							<button
								type="button"
								className={
									proficiency >= 7 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 7, {
										shouldDirty: true,
									})
								}
							>
								7
							</button>
							<button
								type="button"
								className={
									proficiency >= 8 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 8, {
										shouldDirty: true,
									})
								}
							>
								8
							</button>
							<button
								type="button"
								className={
									proficiency >= 9 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 9, {
										shouldDirty: true,
									})
								}
							>
								9
							</button>
							<button
								type="button"
								className={
									proficiency >= 10 ? styles.selected : ""
								}
								{...register("proficiency")}
								onClick={() =>
									setValue("proficiency", 10, {
										shouldDirty: true,
									})
								}
							>
								10
							</button>
						</div>
						{errors?.proficiency?.message && (
							<p className={styles.error_message}>
								{errors?.proficiency?.message}
							</p>
						)}
					</div>

					<div className={styles.form_input_wrapper}>
						<label>
							Amount of experience
							<span className="required">*</span>
						</label>

						<div className={styles.dropdown_container}>
							<div className={styles.dropdown_item}>
								<CustomSelect
									fontSize="14px"
									placeholder="Years"
									options={Array.from(
										{ length: 99 },
										(_, i) => i + 1
									).map((item) => ({
										label: item,
										value: item,
									}))}
									isClearable={true}
									error={errors?.expAmount?.message}
									control={control}
									name="expAmount.years"
									isSearchable={false}
								/>
							</div>

							<div className={styles.dropdown_item}>
								<CustomSelect
									fontSize="14px"
									placeholder="Months"
									options={Array.from(
										{ length: 11 },
										(_, i) => i + 1
									).map((item) => ({
										label: item,
										value: item,
									}))}
									isClearable={true}
									error={errors?.expAmount?.message}
									control={control}
									name="expAmount.months"
									isSearchable={false}
								/>
							</div>
						</div>
						{errors?.expAmount?.message && (
							<p
								className={styles.error_message}
								style={{ textAlign: "left" }}
							>
								{errors?.expAmount?.message}
							</p>
						)}
						<div className={styles.actively_looking_wrapper}>
							<RectangleCheckbox
								{...register("activelyGaining")}
								name="activelyGaining"
							/>

							<span>
								I am still actively gaining experience in this
								software
							</span>
						</div>
					</div>

					<div className={styles.button_container}>
						<PrimaryButton
							className={styles.save_btn}
							text={"save"}
						/>
					</div>
				</form>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default SoftwareEntry;
