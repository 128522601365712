import styles from "./RightSideColumn.module.scss";

const RightSideColumn = ({ children, bgColor }) => {
	return (
		<div
			className={styles.right_column_wrapper}
			style={{ backgroundColor: bgColor }}
		>
			<div>{children}</div>
		</div>
	);
};

export default RightSideColumn;
