import React from "react";
import styles from "./Tabs.module.css";
import useApi from "../../../../hooks/useApi";
import useConnection from "../hooks/useConnection";
import connectionApi from "../../../../api/connection";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupMember from "../../../Chat/GroupMember";
// import msgIcon from "images/message-icon-black.svg";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";

const YourWorkCircle = ({ profile, query, count }) => {
	const getWorkCircleApi = useApi(connectionApi.getWorkCircle);
	const { data: workCircle, fetchData } = useConnection(
		getWorkCircleApi,
		query,
		profile.uuid
	);

	// const handleDirectMsg = (contact) => {
	//     history.push({ pathname: "/chat", state: contact });
	// };

	// console.log("MY WORK CIRCLE >>", workCircle);

	const workCircleList = workCircle.map((item) => (
		// <div className={styles.contact_item} key={item.uuid}>
		<GroupMember
			key={item.uuid}
			data={item}
			redirect={true}
			// rightSideAction={
			//     <img
			//         src={msgIcon}
			//         className={styles.action_icon}
			//         onClick={() => handleDirectMsg(item)}
			//         alt="message"
			//     />
			// }
		/>
		// </div>
	));

	return (
		<div id="scrollDiv" className={styles.container}>
			<InfiniteScroll
				dataLength={workCircle.length}
				next={fetchData}
				hasMore={
					!query && workCircle.length > 0 && workCircle.length < count
				}
				loader={<ContactLoader />}
				scrollableTarget="scrollDiv"
			>
				{getWorkCircleApi.loading && <ContactLoader />}
				{workCircleList.length > 0 &&
					!getWorkCircleApi.loading &&
					workCircleList}
			</InfiniteScroll>
		</div>
	);
};

export default YourWorkCircle;
