import { useBlocker } from "react-router-dom";

const usePrompt = (when, onProceed = undefined, onCancel = undefined) => {
	let blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (when instanceof Function) {
			//* PASSING CUSTOM CONDITION TO TRIGGER THE BLOCKER
			return when(currentLocation, nextLocation);
		} else {
			//* JUST PASSING A BOOLEAN TO TRIGGER THE BLOCKER
			return when && currentLocation.pathname !== nextLocation.pathname;
		}
	});

	const handleProceed = () => {
		onProceed && onProceed();
		blocker.proceed();
	};
	const handleCancel = () => {
		onCancel && onCancel();
		blocker.reset();
	};

	return { blocker, handleProceed, handleCancel };
};

export default usePrompt;
