import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import styles from "./CollapsibleProjectSection.module.scss";
import CollapsibleContainer from "components/Utils/CollapsibleContainer/CollapsibleContainer";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { useInView } from "react-intersection-observer";
import useApi from "hooks/useApi";
import projectApi from "api/project";
import useCurrentUser from "hooks/useCurrentUser";
import usePaginatedResource from "hooks/usePaginatedResource";
import { useEffect, useMemo } from "react";
import { projectKeys } from "queryKeys/projects-key-factory";
import ProjectCard from "components/Jobs/JobsComponents/ProjectCard/ProjectCard";

const itemsPerPage = 20;

const CollapsibleProjectSection = ({ open, count }) => {
	const { uuid: companyId } = useCurrentUser();

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getProjectsApi = useApi(projectApi.getAllProjects, true, true);

	const fetchProjects = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, ___, status] = queryKey;

		const response = await getProjectsApi.request(
			pageParam,
			itemsPerPage,
			status
		);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			projectKeys.lists(companyId, open),
			fetchProjects,
			itemsPerPage
		);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const projectsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((project, i) => (
					<div
						key={project.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						className={styles.project_wrapper}
					>
						<ProjectCard project={project} />
					</div>
				))
			),
		[data]
	);

	return (
		<CollapsibleContainer
			title={`${open ? "Open" : "Closed"} Projects (${count})`}
		>
			<div className={styles.projects_wrapper}>{projectsList}</div>
			{isFetching && <LoadingSpinner />}
			<NoResults
				text={`No ${open ? "open" : "closed"} Projects to show here.`}
				visible={count === 0}
			/>
		</CollapsibleContainer>
	);
};

export default CollapsibleProjectSection;
