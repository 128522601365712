import React from "react";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import styles from "./DeleteItemModal.module.css";

const DeleteItemModal = ({
	show,
	onClose,
	text,
	title,
	onConfirm,
	isLoading,
}) => {
	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign={"right"}
			children={
				<div className={styles.container}>
					<div className={styles.warning_container}>{text}</div>

					<div className={styles.button_container}>
						<PrimaryButton
							text={"cancel"}
							className={`${styles.btn} ${styles.cancel}`}
							type="button"
							onClick={onClose}
						/>
						<PrimaryButton
							text={"remove"}
							className={`${styles.btn} ${styles.decline}`}
							type="button"
							onClick={onConfirm}
							isLoading={isLoading}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default DeleteItemModal;
