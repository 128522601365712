import Joi from "joi";

const certificateSchema = Joi.object({
    title: Joi.string()
        .required()
        .label("Title")
        .messages({ "string.empty": "Title can't be empty" }),

    certificateType: Joi.string()
        .valid("AWARD", "CERTIFICATE")
        .required()
        .label("Type")
        .messages({ "any.only": "Select at least 1 option" }),

    institution: Joi.object({
        value: Joi.string(),
        label: Joi.string(),
        __isNew__: Joi.boolean(),
    })
        .required()
        .messages({
            "any.required": "Institution can't be empty",
            "object.base": "Institution can't be empty",
        })
        .label("Institution"),

    city: Joi.object({
        value: Joi.number(),
        label: Joi.string(),
        // countryCode: Joi.string(),
    })
        .required()
        .messages({
            "any.required": "Location can't be empty",
            "object.base": "Location can't be empty",
        })
        .label("Location"),
    website: Joi.string().allow("").label("Website"),
    switch: Joi.boolean(),
    startDate: Joi.date().label("Start date").allow(null),
    endDate: Joi.date().allow(null).label("End date"),
    validity: Joi.date().allow(null).label("Validity date"),
    comments: Joi.string().allow("").label("Comments"),
    score: Joi.string().allow("").label("Score"),
    outOf: Joi.string().allow("").label("Out of"),
    files: Joi.array().min(0),
});

export default certificateSchema;
