import styles from "./ProductsItems.module.scss";

export const BackdropLoading = ({ isVisible }) => {
	if (!isVisible) return null;

	return (
		<div className={styles.backdrop}>
			<div className={styles.loader} />
		</div>
	);
};
