import { useEffect, useMemo } from "react";
import styles from "./Services.module.scss";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import ServiceItemAlt from "components/Profile/ServicesTab/ServicesComponent/ServiceItemAlt";
import { pluralize } from "components/Utils/General";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import Masonry from "react-masonry-css";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import { useInView } from "react-intersection-observer";
import filterIcon from "images/filter-icon-white.svg";

const Services = ({
	count,
	data,
	isPaginated = false,
	hasNextPage,
	isFetching,
	onLoad,
	isLoading,
	isMobile,
	onShowModal,
	tagsList,
	onClick,
}) => {
	const { ref: viewRef, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			onLoad();
		}
	}, [inView, hasNextPage, onLoad, isFetching]);

	const formattedServices = useMemo(
		() =>
			data?.pages?.map((page) =>
				page?.list?.map((service, i) => (
					<GaawkLink
						disableDecoration={true}
						key={service.uuid}
						className={styles.service_item}
						to={`/services/${service.uuid}`}
						ref={page.list.length === i + 1 ? viewRef : null}
					>
						<ServiceItemAlt item={service} />
					</GaawkLink>
				))
			),
		[data]
	);

	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			<div className={styles.title_wrapper}>
				<h4>{`${count} ${pluralize(count, "Service")} found`}</h4>
				{isMobile && isPaginated && (
					<button
						className={styles.filter_button}
						onClick={onShowModal}
					>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList?.length > 0 && (
				<div className={styles.active_filters}>{tagsList}</div>
			)}

			<Masonry
				breakpointCols={{
					default: 2,
					1399: 1,
				}}
				className={styles.services_container}
				columnClassName={styles.services_container_grid_columns}
			>
				{formattedServices}
			</Masonry>

			{isFetching && <LoadingSpinner />}

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text={"view more"} />
				</div>
			)}
		</>
	);
};

export default Services;
