import { Link } from "react-router-dom";
import styles from "./GaawkLink.module.scss";

const GaawkLink = ({
	children,
	to,
	customStyle,
	disableDecoration = false,
	tabIndex,
	...props
}) => {
	return (
		<Link
			to={to}
			tabIndex={tabIndex}
			className={`${styles.link} ${
				disableDecoration ? styles.no_decoration : undefined
			} ${customStyle}`}
			{...props}
		>
			{children}
		</Link>
	);
};

export default GaawkLink;
