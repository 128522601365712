import { useEffect, useState } from "react";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import { removeFileExtension } from "../Utils/General";
import styles from "./VaultRenameModal.module.css";

const VaultRenameModal = ({ visible, item, onClose, onConfirm }) => {
	const [newItemName, setNewItemName] = useState("");
	const [extension, setExtension] = useState("");

	useEffect(() => {
		if (item) {
			if (item.type === "FILE") {
				setNewItemName(removeFileExtension(item.name));
				setExtension(item.file.extension);
			} else {
				setNewItemName(item.name);
			}
		}
	}, [item]);

	if (!visible) return null;

	const handleConfirmRename = () => {
		onConfirm(
			item,
			item.type === "FILE" ? `${newItemName}.${extension}` : newItemName
		);
		setNewItemName("");
		setExtension("");
		onClose();
	};

	return (
		<GaawkModal
			show={visible}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={item.name}
			closeAlign={"right"}
			children={
				<div className={styles.modal_container}>
					<div className={styles.rename_input_wrapper}>
						<label>Title</label>
						<TextInput
							value={newItemName}
							onChange={(e) => setNewItemName(e.target.value)}
						/>
					</div>

					<div className={styles.rename_modal_buttons}>
						<SecondaryButton
							className={styles.cancel_button}
							text={"CANCEL"}
							onClick={onClose}
						/>

						<PrimaryButton
							className={styles.rename_button}
							text={"DONE"}
							onClick={handleConfirmRename}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default VaultRenameModal;
