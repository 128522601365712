import React from "react";
import styles from "./SystemMessage.module.css";

import { format } from "date-fns";

const SystemMessage = ({ message, isMessageInfo = false }) => {
    return (
        <div className={styles.system_message}>
            {isMessageInfo && <div className={styles.system_message_line} />}

            <div className={styles.system_message_text}>
                <span>{typeof message === "number" ? format(new Date(message), "eeee do MMMM yyyy") : message}</span>
            </div>
        </div>
    );
};

export default SystemMessage;
