import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import { deleteLanguage } from "../../../../store/slices/user";

import LanguageItem from "./LanguageItem";
import styles from "./Language.module.css";
import CtaButton from "components/Utils/Button/CtaButton";

const Index = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { languages: userLanguageList } = useSelector(
		(state) => state.user.profileInfo
	);

	const [languageId, setLanguageId] = useState("");
	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const handleDelete = (languageId) => {
		setLanguageId(languageId);
		setWarningDeleteModal(true);
	};

	const handleConfirmDelete = () => {
		dispatch(deleteLanguage(languageId));
		setWarningDeleteModal(false);
	};

	const userLanguage = userLanguageList.map((item) => (
		<LanguageItem
			key={item.uuid}
			item={item}
			onEditClicked={() =>
				navigate("/profile/language/edit", {
					state: item,
				})
			}
			onDelete={() => handleDelete(item.uuid)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW LANGUAGE"
					onClick={() => navigate("/profile/language/add")}
				/>
			</div>

			{userLanguageList.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Languages you have added</h4>

					<div className={styles.languages_section}>
						{userLanguage}
					</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Index;
