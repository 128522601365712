import { useQuery } from "@tanstack/react-query";
import jobApi from "api/job";
import useApi from "hooks/useApi";
import { jobsKeys } from "queryKeys/jobs-key-factory";

const useJobsLanding = (projectId) => {
	const getJobsLandingApi = useApi(jobApi.getJobsLanding, true, true);

	const fetchJobsLanding = async () => {
		const response = await getJobsLandingApi.request();
		return response.data;
	};

	const query = useQuery({
		queryKey: jobsKeys.landing(),
		queryFn: fetchJobsLanding,
		enabled: !projectId,
	});

	return query;
};

export default useJobsLanding;
