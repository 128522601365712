import { useMemo } from "react";
import FileThumbnail from "../FileThumbnail/FileThumbnail";
import styles from "./ThumbnailContainer.module.scss";
import { getFileIcon } from "components/Utils/General";

const ThumbnailContainer = ({ items, onDelete, className }) => {
	const thumbnailList = useMemo(
		() =>
			items.map((item, index) => {
				const isFile = item instanceof File;
				const isBlob = item instanceof Blob;

				return (
					<FileThumbnail
						key={index}
						thumbnail={
							isBlob
								? URL.createObjectURL(item)
								: getFileIcon(
										isFile ? item : item?.file,
										isFile
											? item?.name
											: item?.file?.originalName
								  ).icon
						}
						isImage={
							item?.type.startsWith("image") ||
							item?.file?.previewType === "IMAGE"
						}
						onDelete={() => onDelete(item, index)}
					/>
				);
			}),
		[items]
	);

	return (
		<div className={`${styles.container} ${className}`}>
			{thumbnailList}
		</div>
	);
};

export default ThumbnailContainer;
