import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import CreateSelect from "../Select/CreatableSelect";
import styles from "./CreatableInput.module.css";

const CreatableInput = ({
    label,
    placeholder = "Search",
    required = false,
    error,
    control,
    api,
    formatter,
    disabled,
    onChange,
    onChangeAlt,
    name,
    multiSelect = false,
    cursor = "default",
    unDuplicateFn,
    value,
    customStyle,
}) => {
    const [items, setItems] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    const handleKeyUp = (e) => {
        if (e.key !== "ArrowUp" && e.key !== "ArrowDown") {
            setSearchInput(e.target.value);
        }
    };

    const resetField = () => {
        setSearchInput("");
    };

    useEffect(() => {
        let timer;
        if (searchInput) {
            timer = setTimeout(() => {
                api.request(searchInput);
            }, 500);
        } else {
            setItems([]);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [searchInput]);

    useEffect(() => {
        if (!api.loading && api.data?.length > 0 && api.responseCode === 200) {
            const response = unDuplicateFn ? unDuplicateFn(api.data) : api.data;
            setItems(response.map((item) => formatter(item)));
        }
    }, [api.data]);

    return (
        <div className={`${styles.container} ${customStyle}`}>
            {label && (
                <label>
                    {label}
                    {required && <span className="required">*</span>}
                </label>
            )}
            <div onKeyUp={handleKeyUp}>
                {required ? (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <CreateSelect
                                options={items}
                                placeholder={placeholder}
                                height="35px"
                                value={multiSelect ? "" : value}
                                isLoading={api.loading}
                                onChange={(value) => {
                                    !multiSelect && onChange(value);
                                    multiSelect && onChangeAlt(value);
                                    resetField();
                                }}
                                disabled={disabled}
                                error={error}
                                cursor={cursor}
                                onBlur={resetField}
                            />
                        )}
                    />
                ) : (
                    <CreateSelect
                        options={items}
                        placeholder={placeholder}
                        height="35px"
                        value={multiSelect ? "" : value}
                        isLoading={api.loading}
                        onChange={(value) => {
                            onChange(value);
                            resetField();
                        }}
                        disabled={disabled}
                        cursor={cursor}
                        onBlur={resetField}
                    />
                )}
            </div>
            {error && <p className={styles.error_message}>{error.message}</p>}
        </div>
    );
};

export default CreatableInput;
