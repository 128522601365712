import { forwardRef, useImperativeHandle, useRef } from "react";
// import styles from "./ServicesFilter.module.scss";
import styles from "./PeopleFilter.module.scss";

import { servicesInitialFilters } from "../initialFilters";
import searchApi from "api/search";
import useSearchFilters from "hooks/useSearchFilters";
import usePaginatedSearchFilters from "hooks/usePaginatedSearchFilters";
import MultiCheckbox from "components/Utils/MultiCheckbox/MultiCheckbox";
import useFilterAndSort from "./useFilterAndSort";
import { defaultFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import Checkbox from "components/Utils/SubComs/Inputs/Checkbox/Checkbox";
import ConnectionFilter from "./ConnectionFilter";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";

const ServicesFilter = forwardRef(
	(
		{
			inputValue,
			onChange,
			onResetFilters,
			selectedFilters: selectedDynamicFilters,
			userCoordinates,
			companyId,
		},
		ref
	) => {
		const {
			initialFilters,
			data,
			isFetching,
			hasFilters,
			selectedFilters,
			setSelectedFilters,
		} = useSearchFilters(
			servicesInitialFilters,
			{ locationIds: [userCoordinates.id] },
			"servicesFilters",
			inputValue,
			searchApi.servicesFilters,
			selectedDynamicFilters,
			onChange
		);

		const {
			hasNextPage: hasNextPageCategories,
			fetchNextPage: fetchNextPageCategories,
			isFetched: isFetchedCategories,
			refetch: refetchCategories,
			data: dynamicCategories,
		} = usePaginatedSearchFilters(
			servicesInitialFilters,
			{ locationIds: [userCoordinates.id] },
			"servicesCategoriesFilters",
			inputValue,
			searchApi.servicesCategories,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			gaawkCategories = [],
			includeMedia,
			includeProducts,
			friends,
			inMyCircle,
			inTheirCircle,
		} = selectedFilters || {};

		const { gaawkCategories: gaawkList = [] } = data || {};

		const categoriesRef = useRef();

		const filteredCategories = useFilterAndSort(
			gaawkCategories,
			dynamicCategories,
			gaawkList,
			isFetchedCategories,
			"uuid",
			true
		);

		const handleClearFields = () => {
			if (hasFilters) {
				setSelectedFilters(initialFilters);

				// colorRef.current?.clear();
				// materialRef.current?.clear();
				// serviceRef.current?.clear();

				onResetFilters(initialFilters);
			}
		};

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "gaawkCategories":
						categoriesRef.current?.removeItem(tag);
						break;

					default:
						break;
				}
			},
		}));

		if (isFetching)
			return <LoadingSpinner customStyle={styles.loading_spinner} />;

		return (
			<div className={styles.container}>
				{gaawkList?.length > 0 && (
					<MultiCheckbox
						ref={categoriesRef}
						title={"Gaawk Categories"}
						options={filteredCategories.map((item) =>
							defaultFormatter(item)
						)}
						onSelect={(gaawkCategories) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								gaawkCategories: gaawkCategories,
							}));
						}}
						selected={gaawkCategories}
						perRow="2, 150px"
						customStyle={styles.multicheckbox_container}
					/>
				)}

				{((!isFetchedCategories && gaawkList.length === 4) ||
					(isFetchedCategories && hasNextPageCategories)) && (
					<SeeMore
						onClick={
							!isFetchedCategories
								? refetchCategories
								: fetchNextPageCategories
						}
						className={styles.margin_top}
					/>
				)}

				<div className={styles.agency_wrapper}>
					<div className={styles.agency_checkbox}>
						<Checkbox
							className={styles.agency_checkbox}
							w="14px"
							h="14px"
							checked={includeProducts}
							onChange={() =>
								setSelectedFilters((prevState) => ({
									...prevState,
									includeProducts: !prevState.includeProducts,
								}))
							}
							name={"media"}
							customBackgroundColor={"gaawk"}
						/>
						<span
							onClick={() =>
								setSelectedFilters((prevState) => ({
									...prevState,
									includeProducts: !prevState.includeProducts,
								}))
							}
						>
							Only services that have products in it
						</span>
					</div>
				</div>

				<div className={styles.agency_wrapper}>
					<div className={styles.agency_checkbox}>
						<Checkbox
							className={styles.agency_checkbox}
							w="14px"
							h="14px"
							checked={includeMedia}
							onChange={() =>
								setSelectedFilters((prevState) => ({
									...prevState,
									includeMedia: !prevState.includeMedia,
								}))
							}
							name={"media"}
							customBackgroundColor={"gaawk"}
						/>
						<span
							onClick={() =>
								setSelectedFilters((prevState) => ({
									...prevState,
									includeMedia: !prevState.includeMedia,
								}))
							}
						>
							Only services that have media tagged in it
						</span>
					</div>
				</div>

				<ConnectionFilter
					friends={friends}
					friendLabel="From my friends"
					onFriendChange={
						!companyId &&
						((value) =>
							setSelectedFilters((prevState) => ({
								...prevState,
								friends: value,
							})))
					}
					inMyCircle={inMyCircle}
					myCircleLabel="From my work circle"
					onMyWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inMyCircle: value,
						}))
					}
					inTheirCircle={inTheirCircle}
					theirCircleLabel="From accounts who added me to their work circle"
					onTheirWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inTheirCircle: value,
						}))
					}
				/>

				<div className={styles.button_container}>
					<PrimaryButton
						type="button"
						text={"clear filters"}
						className={`${styles.btn} ${styles.clear}`}
						onClick={handleClearFields}
					/>
				</div>
			</div>
		);
	}
);

export default ServicesFilter;
