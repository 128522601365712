import { useMutation } from "@tanstack/react-query";
import useApi from "./useApi";
import { toast } from "react-toastify";
import { isPlainObject } from "@reduxjs/toolkit";
import { useState } from "react";

const useMutate = (
	endpoint,
	successFn,
	errorMessage = true,
	errorFn,
	options = {}
) => {
	const api = useApi(endpoint, true, true);

	const action = useMutation({
		mutationFn: (args) => {
			if (isPlainObject(args)) {
				return api.request(...Object.values(args));
			} else {
				return api.request(args);
			}
		},
		onSuccess: (data) => {
			successFn && successFn(data);
		},

		onError: (error) => {
			errorMessage !== false &&
				toast.error(
					typeof errorMessage === "string"
						? errorMessage
						: error.message
				);
			if (options.useErrorBoundary) {
				throw new Response("", {
					statusText: error.message,
					status: error.code,
				});
			}
			errorFn && errorFn(error);
		},

		...options,
	});

	const [warningModal, setWarningModal] = useState(false);
	const [activeId, setActiveId] = useState("");

	const handleRequestDelete = (id) => {
		setWarningModal(true);
		setActiveId(id);
	};

	const handleConfirmDelete = () => {
		action.mutate(activeId);
		setWarningModal(false);
	};

	return {
		action,
		warningModal,
		setWarningModal,
		handleRequestDelete,
		handleConfirmDelete,
		activeId,
	};
};

export default useMutate;
