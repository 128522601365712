import { useState, useEffect } from "react";
import Friends from "./Friends";
import Work from "./Work";
import Follow from "./Follow";
import Favorites from "./Favorites";
import Blocked from "./Blocked";
import profileApi from "../../../api/profile";
import useApi from "../../../hooks/useApi";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import NoData from "../ProfilePage/NoData";
import useDirectMessage from "../../../hooks/useDirectMessage";
import NoResults from "../../Utils/SubComs/NoResults/NoResults";
import styles from "./ConnectionTab.module.css";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateIsInfoOpened } from "store/slices/ui";

const ConnectionTab = ({ userInfo, canEdit }) => {
	const [connectionCount, setConnectionCount] = useState("");

	const getConnectionsCountApi = useApi(profileApi.getConnectionsCount);

	const {
		followers,
		following,
		friends,
		inMyWorkCircle,
		inTheirWorkCircles,
		// friendPendingSent,
		// blocking,
		// favorites,
	} = connectionCount;

	const fetchConnectionCount = () => {
		getConnectionsCountApi.request(userInfo.uuid);
	};

	useEffect(() => {
		if (userInfo.uuid) {
			fetchConnectionCount();
		}
	}, [userInfo.uuid, userInfo.connection]);

	useEffect(() => {
		if (
			!getConnectionsCountApi.loading &&
			getConnectionsCountApi.responseCode === 200 &&
			getConnectionsCountApi.data &&
			Object.keys(getConnectionsCountApi.data).length > 0
		) {
			setConnectionCount(getConnectionsCountApi.data);
		}
	}, [getConnectionsCountApi.data]);

	const { handleDirectMessage } = useDirectMessage();

	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const handleSendMessage = () => {
		if (pathname === "/chat") {
			dispatch(updateIsInfoOpened(false));
		} else {
			handleDirectMessage(userInfo);
		}
	};

	return (
		<div className={styles.container}>
			{userInfo && (
				<>
					{/* //TODO: ADD LOADING SPINNER */}
					{!canEdit &&
						followers === 0 &&
						following === 0 &&
						friends === 0 &&
						inTheirWorkCircles === 0 &&
						inMyWorkCircle === 0 && (
							<ProfileTabContainer>
								<NoData
									text={`${
										userInfo.type === "USER"
											? `${userInfo.firstName} ${userInfo.lastName}`
											: userInfo.name
									}  did not add any information to his contact details, or he has visibility limits for friends / work circles.`}
									onClick={handleSendMessage}
								/>
							</ProfileTabContainer>
						)}
					<NoResults
						visible={
							canEdit &&
							followers === 0 &&
							following === 0 &&
							friends === 0 &&
							inMyWorkCircle === 0
						}
						text="You don't have any connections yet"
					/>

					<Friends
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>

					<Work
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>

					<Follow
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>

					<Favorites
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>

					<Blocked
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>
				</>
			)}
		</div>
	);
};

export default ConnectionTab;
