// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import {
	getMessaging,
	// getToken,
	// onMessage
} from "firebase/messaging/sw";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
	apiKey: "AIzaSyA_c5XnUD5JQfbP9bm-gKH_nnX7usxPAsI",
	authDomain: "gigshack-evolution.firebaseapp.com",
	databaseURL: "https://gigshack-evolution.firebaseio.com",
	projectId: "gigshack-evolution",
	storageBucket: "gigshack-evolution.appspot.com",
	messagingSenderId: "50336686121",
	appId: "1:50336686121:web:bae2122dceee6066b5bc38",
	measurementId: "G-J2EETMZLRP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// export const fetchToken = async (setTokenFound) => {
// 	return getToken(messaging, {
// 		vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
// 	})
// 		.then((currentToken) => {
// 			if (currentToken) {
// 				setTokenFound({
// 					token: currentToken,
// 					isFound: true,
// 				});

// 				// Track the token -> client mapping, by sending to backend server
// 				// show on the UI that permission is secured
// 			} else {
// 				console.log(
// 					"No registration token available. Request permission to generate one."
// 				);

// 				console.log("THE NOTIFICATION >>>", Notification);

// 				if (!("Notification" in window)) {
// 					// Check if the browser supports notifications
// 					alert("This browser does not support desktop notification");
// 				} else if (Notification.permission === "granted") {
// 					// Check whether notification permissions have already been granted;
// 					console.log("Notification permission already granted.");
// 				} else if (Notification.permission !== "denied") {
// 					// We need to ask the user for permission
// 					Notification.requestPermission().then((permission) => {
// 						// If the user accepts, log it
// 						if (permission === "granted") {
// 							console.log("Notification permission granted.");
// 						}
// 					});
// 				}
// 			}
// 		})
// 		.catch((err) => {
// 			console.log("An error occurred while retrieving token.", err);

// 			setTokenFound({
// 				token: undefined,
// 				isFound: false,
// 			});
// 			// catch error while creating client token
// 		});
// };

// const onMessageListener = () =>
// 	new Promise((resolve) => {
// 		onMessage(messaging, (payload) => {
// 			resolve(payload);
// 		});
// 	});

// if ("serviceWorker" in navigator) {
// 	navigator.serviceWorker
// 		.register("/firebase-messaging-sw.js")
// 		.then((registration) => {
// 			console.log(
// 				"Service Worker registered with scope:",
// 				registration.scope
// 			);
// 		})
// 		.catch((err) => {
// 			console.error("Service Worker registration failed:", err);
// 		});
// }

// export {
// 	// app,
// 	messaging,
// 	// onMessageListener,
// };
