import styles from "./ServiceAccordionItem.module.scss";
import arrowIcon from "images/dropdown_arrow_white_14_x_10.svg";
import arrowIconGray from "images/dropdown-arrow-gray.svg";
import CustomRadioButton from "components/Utils/SubComs/CustomRadioButton/CustomRadioButton";
import { useEffect, useRef, useState } from "react";

const ServiceAccordionItem = ({
	content,
	isOpen,
	onToggle,
	itemKey,
	className,
	// showArrow = true,
	level = undefined,
	radioMode,
	onRadioSelect,
	selectedItem,
	// button = undefined,
	// showServicesWithProducts,
	isActiveService,
	productsCount,
	// product_number,
	LinkComponent,
	productCountStyle,
	// name,
	// idToOpen,
}) => {
	const accordionRef = useRef(null);
	const radioRef = useRef(null);

	const isSelected = selectedItem === itemKey;
	// console.log("🚀 ~ selectedItem >>", selectedItem);
	// console.log("🚀 ~ isSelected >>", isSelected);

	const [open, setOpen] = useState(false);

	// console.log("🚀 ~ isOpen prop >>", name, ">>", isOpen);
	// console.log("open state >> ", open);
	// console.log("🚀 ~ service name >>", name);
	// console.log("=====");

	// useEffect(() => {
	// 	if (isOpen && selectedItem) {
	// 		setOpen(true);
	// 	}
	// }, [selectedItem, isOpen]);

	useEffect(() => {
		if (isOpen) {
			setOpen(true);
		}
	}, [isOpen]);

	//* removed...
	// ${
	//     isSelected ? styles.active : undefined
	// }
	return (
		<>
			<div
				role="button"
				ref={accordionRef}
				className={`${styles.header} ${className}`} //* ...from here
				// onClick={() => onToggle(itemKey)}
				onClick={() => {
					if (level !== 3) {
						setOpen((prevState) => !prevState);
						!open && onToggle && onToggle();
					}
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter" || e.key === " ") {
						accordionRef.current.click();
					}
				}}
				tabIndex={0}
			>
				{LinkComponent}

				<div className={styles.right_side}>
					{radioMode && level !== 3 && (
						<button
							type="button"
							onClick={() => radioRef.current.click()}
						>
							<CustomRadioButton
								ref={radioRef}
								onChange={() => onRadioSelect(itemKey)}
								name="subCategory"
								customStyle={
									level === 1 || isSelected
										? styles.white_radio
										: undefined
								}
								checked={isSelected}
							/>
						</button>
					)}

					{productsCount && (
						<span className={productCountStyle}>
							{productsCount}
						</span>
					)}
					{/* {showServicesWithProducts && (
						<span className={productCountStyle}>
							{product_number}
						</span>
					)} */}

					{(level === 1 || level === 2) && (
						<img
							src={
								isActiveService
									? arrowIcon
									: level === 2
									? arrowIconGray
									: arrowIcon
							}
							className={`${styles.arrow} ${
								open ? styles.rotate : undefined
							}`}
							alt=""
						/>
					)}
					{/* {button && button} */}
				</div>
			</div>
			{open && <div>{content}</div>}
		</>
	);
};

export default ServiceAccordionItem;

// import styles from "./AccordionItem.module.css";
// import arrowIcon from "images/dropdown_arrow_white_14_x_10.svg";
// import arrowIconGray from "images/dropdown-arrow-gray.svg";
// import CustomRadioButton from "../SubComs/CustomRadioButton/CustomRadioButton";
// import { useEffect, useRef, useState } from "react";

// const TestAccordionItem = ({

// }) => {

// };

// export default TestAccordionItem;
