import { postsInitialFilters } from "../initialFilters";
import styles from "./PeopleFilter.module.scss";
import { forwardRef, useImperativeHandle } from "react";
import searchApi from "../../../api/search";
import ConnectionFilter from "./ConnectionFilter";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import useSearchFilters from "../../../hooks/useSearchFilters";
import Checkbox from "components/Utils/SubComs/Inputs/Checkbox/Checkbox";

const PostsFilter = forwardRef(
	(
		{
			inputValue,
			onChange,
			onResetFilters,
			selectedFilters: selectedDynamicFilters,
			userCoordinates,
			companyId,
		},
		ref
	) => {
		const {
			initialFilters,
			hasFilters,
			selectedFilters,
			setSelectedFilters,
			handleRefetch,
		} = useSearchFilters(
			postsInitialFilters,
			{ locationIdForSort: userCoordinates.id },
			"postsFilters",
			inputValue,
			searchApi.postsFilters,
			selectedDynamicFilters,
			onChange,
			false // >> disabling the query because there are no dynamic filters for jobs (static only)
		);

		const {
			includeMedia,
			friends = false,
			inMyCircle = false,
			inTheirCircle = false,
			fromDate = null,
			toDate = null,
		} = selectedFilters || {};

		const handleClearFields = () => {
			if (hasFilters) {
				setSelectedFilters(initialFilters);
				onResetFilters(initialFilters);
			}
		};

		useImperativeHandle(ref, () => ({
			refetch() {
				handleRefetch();
			},
		}));

		return (
			<div className={styles.container}>
				<div className={styles.datePickerContainer}>
					{/* <label>Date</label> */}
					<div className={styles.datePickerWrapper}>
						<div className={styles.datePickerItem}>
							<label>From</label>
							<DayPicker
								className={`form-start-date`}
								onChange={(startDate) =>
									setSelectedFilters((prevState) => ({
										...prevState,
										fromDate: startDate?.getTime(),
									}))
								}
								value={fromDate && new Date(fromDate)}
								isClearable={true}
							/>
						</div>
						<div className={styles.datePickerItem}>
							<label>To</label>
							<DayPicker
								className={`form-end-date`}
								onChange={(endDate) =>
									setSelectedFilters((prevState) => ({
										...prevState,
										toDate: endDate?.getTime(),
									}))
								}
								value={toDate && new Date(toDate)}
								isClearable={true}
							/>
						</div>
					</div>
				</div>

				<div className={styles.agency_wrapper}>
					<h3>Media</h3>
					<div className={styles.agency_checkbox}>
						<Checkbox
							className={styles.agency_checkbox}
							w="14px"
							h="14px"
							checked={includeMedia}
							onChange={() =>
								setSelectedFilters((prevState) => ({
									...prevState,
									includeMedia: !prevState.includeMedia,
								}))
							}
							name={"media"}
							customBackgroundColor={"gaawk"}
						/>
						<span
							onClick={() =>
								setSelectedFilters((prevState) => ({
									...prevState,
									includeMedia: !prevState.includeMedia,
								}))
							}
						>
							Media Posts Only
						</span>
					</div>
				</div>

				<ConnectionFilter
					friends={friends}
					friendLabel="From my friends"
					onFriendChange={
						!companyId &&
						((value) =>
							setSelectedFilters((prevState) => ({
								...prevState,
								friends: value,
							})))
					}
					inMyCircle={inMyCircle}
					myCircleLabel="From my work circle"
					onMyWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inMyCircle: value,
						}))
					}
					inTheirCircle={inTheirCircle}
					theirCircleLabel="From accounts who added me to their work circle"
					onTheirWorkChange={(value) =>
						setSelectedFilters((prevState) => ({
							...prevState,
							inTheirCircle: value,
						}))
					}
				/>

				<div className={styles.button_container}>
					<PrimaryButton
						type="button"
						text={"clear filters"}
						className={`${styles.btn} ${styles.clear}`}
						onClick={handleClearFields}
					/>
				</div>
			</div>
		);
	}
);

export default PostsFilter;
