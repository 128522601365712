import React, { useState } from "react";
import styles from "./KeyPeopleEntry.module.css";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import profileApi from "../../../../api/profile";
import {
	defaultFormatter,
	peopleFormatter,
	unduplicateIds,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import { components } from "react-select";
import ProfilePic from "../../../Home/ProfilePic";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { addKeyPerson } from "../../../../store/slices/company";
import keyPeopleSchema from "./keyPeople-schema";
import useMutate from "hooks/useMutate";
import { useNavigate } from "react-router";
import PromptModal from "components/Utils/Prompt/PromptModal";
import useFetchRoles from "hooks/useFetchRoles";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { rolesKeys } from "queryKeys/roles-key-factory";
import { profileKeys } from "queryKeys/profile-key-factory";
import useFetchUsers from "hooks/useFetchUsers";
import routes from "components/Routing/routing-keys";

const itemsPerPage = 20;

const KeyPeopleEntry = () => {
	const [shouldBlockSave, setShouldBlockSave] = useState(true);
	const dispatch = useDispatch();

	const { keyPeople, uuid: companyId } = useSelector(
		(state) => state.company.companyInfo
	);
	const keyPeopleIds = useMemo(
		() => keyPeople.map((person) => person.profile.uuid),
		[keyPeople]
	);

	const {
		formState: { errors, isDirty },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(keyPeopleSchema),
		mode: "onSubmit",
		defaultValues: {
			title: "",
			person: null,
		},
	});

	const navigate = useNavigate();

	const {
		action: { mutate: addKeyPeople, isLoading },
	} = useMutate(
		profileApi.addKeyPeople,
		(response) => {
			dispatch(addKeyPerson(response.data.keyPeople));
			navigate(routes.keyPeople);
		},
		undefined,
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		setShouldBlockSave(false);

		const {
			person: { value: profileId },
			title: { value: role },
		} = data;

		addKeyPeople({ companyId, profileId, role });
	};

	const fetchRoles = useFetchRoles();
	const fetchUsers = useFetchUsers();

	return (
		<>
			<form
				className={styles.form}
				onSubmit={handleSubmit((data) => handleSave(data))}
				noValidate
			>
				<InfiniteSearchInput
					label={"Person's name"}
					queryName={profileKeys.all}
					// unDuplicateFn={(passedData) =>
					// 	unduplicateIds(keyPeopleIds, passedData, "value")
					// }
					required={true}
					error={errors?.person}
					control={control}
					name="person"
					queryFn={fetchUsers}
					itemsPerPage={10}
					formatter={peopleFormatter}
					option={{
						Option: (props) => {
							const user = props.data;
							return (
								<components.Option {...props}>
									<div className={styles.member_item}>
										<ProfilePic
											thumbnail={
												user?.profileImage?.image
											}
											w={36}
											h={36}
											type={user.type}
											name={user.label}
											createMode={true}
											subdata={user.tagLine}
										/>
									</div>
								</components.Option>
							);
						},
					}}
				/>

				<InfiniteSearchInput
					label={"Title / Designation"}
					required={true}
					queryName={rolesKeys.all}
					queryFn={fetchRoles}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					error={errors?.title}
					control={control}
					name="title"
					creatable={true}
				/>

				<div className={styles.button_container}>
					<PrimaryButton
						className={styles.save_btn}
						text={"save"}
						isLoading={isLoading}
					/>
				</div>
			</form>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default KeyPeopleEntry;
