import client from "./client";

const endpoint = "/ethnicity";

const getEthnicities = () => client.get(endpoint);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getEthnicities,
};
