import React from "react";
import CompanyEntry from "../../../Companies/CompanyEntry";

import EditUserInfo from "./EditUserInfo";

const EditInfo = ({ editCompany }) => {
    return <>{editCompany ? <CompanyEntry editMode={true} /> : <EditUserInfo />}</>;
};

export default EditInfo;
