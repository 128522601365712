import { cloneElement } from "react";
// import { isLogged } from "../Utils/General";
// import { useSelector } from "react-redux";
import useCompanyId from "../../hooks/useCompanyId";
// import useFetchProfile from "../../hooks/useFetchProfile";
// import LoadingPage from "../Utils/SubComs/CustomLoader/LoadingPage";

const EditProfileRoute = ({
	// userOnly = false,
	// companyOnly = false,
	children,
	// component: Component,
	// activeItem,
	// activeKey,
	// headerTitle,
	// ...rest
}) => {
	const companyId = useCompanyId();

	// const currentUser = useSelector((state) => {
	// 	if (companyId) {
	// 		return state.company.companyInfo;
	// 	} else {
	// 		return state.user.profileInfo;
	// 	}
	// });

	// const { profile } = useFetchProfile(currentUser, companyId);

	// if ((companyId && !userOnly) || (!companyId && !companyOnly)) {
	return cloneElement(children, { editCompany: companyId });
	// } else {
	// // return <Navigate to="/profile" />;
	// }

	// return (
	// 	<Route
	// 		{...rest}
	// 		render={(props) =>
	// 			isLogged(currentUser && Object.keys(currentUser).length > 0) ? (
	// 				profile ? (
	// 					(companyId && !userOnly) ||
	// 					(!companyId && !companyOnly) ? (
	// 						<Component
	// 							{...props}
	// 							editCompany={companyId}
	// 							activeItem={activeItem}
	// 							activeKey={activeKey}
	// 							headerTitle={headerTitle}
	// 						/>
	// 					) : (
	// 						<Navigate to="/profile" />
	// 					)
	// 				) : (
	// 					<LoadingPage />
	// 				)
	// 			) : (
	// 				<Navigate to="/login" />
	// 			)
	// 		}
	// 	/>
	// );
};

export default EditProfileRoute;
