import React, { useMemo } from "react";
import styles from "./Certificate.module.css";

import { format } from "date-fns";
import FileThumbnail from "../../../Utils/SubComs/FileThumbnail/FileThumbnail";
import { capitalize, getFileIcon } from "../../../Utils/General";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { ReactComponent as AwardIcon } from "images/award.svg";
import { ReactComponent as CertificateIcon } from "images/certificate.svg";
import EditButton from "components/Utils/Button/EditButton";
import DeleteButton from "components/Utils/Button/DeleteButton";

const CertificateItem = ({
	showControls = true,
	onEditClicked,
	onDelete,
	item,
}) => {
	const startDate =
		item.startDate > 0
			? format(new Date(item.startDate), "dd/MM/yyyy")
			: "";

	const endDate = item.currentlyWorking
		? "Currently pursuing"
		: item.completionDate > 0
		? format(new Date(item.completionDate), "dd/MM/yyyy")
		: "";

	const mediaFiles = item.files.map((file) => {
		return (
			<FileThumbnail
				key={file.file.uuid}
				thumbnail={getFileIcon(file.file, file.file.originalName).icon}
				isImage={file.file.previewType === "IMAGE"}
				customStyle={styles.file_item}
			/>
		);
	});

	const certificateIcon = useMemo(() => {
		if (item.certificateType === "CERTIFICATE") {
			return <CertificateIcon />;
		} else if (item.certificateType === "AWARD") {
			return <AwardIcon />;
		}
	}, [item.certificateType]);

	return (
		<div key={item.uuid} className={styles.certificate_item}>
			<Tag
				customStyle={styles.certificate_name}
				itemName={
					<div className={styles.certificate_wrapper}>
						{certificateIcon}
						<span>|</span>
						{capitalize(item.certificateType)}
						{/* {item.certificateType.charAt(0) +
							item.certificateType.slice(1).toLowerCase()} */}
					</div>
				}
			/>

			<div className={styles.certificate_title}>
				<span>{item.title}</span>
			</div>
			<div className={styles.institute_name}>
				{item.instituteDTO
					? item.instituteDTO.name
					: item.instituteName}
			</div>
			<div className={styles.institute_website}>{item.website}</div>

			<div className={styles.institute_location}>
				{item.locationDTO?.countryDTO.name}
			</div>

			{item.comments && (
				<div className={styles.certificate_description}>
					<p>{item.comments}</p>
				</div>
			)}
			{mediaFiles.length > 0 && (
				<div className={styles.files_container}>{mediaFiles}</div>
			)}

			{item.score && (
				<span className={styles.certificate_score}>{`${item.score} ${
					item.scoreOutOf ? `/ ${item.scoreOutOf}` : ""
				}`}</span>
			)}
			<div className={styles.certificate_dates}>
				{`${startDate}
                    ${startDate && endDate ? " - " : ""}
                 ${endDate}`}
			</div>

			{item.validity !== 0 && (
				<div
					className={styles.certificate_validity}
				>{`Valid till ${format(
					new Date(item.validity),
					"dd/MM/yyyy"
				)}`}</div>
			)}

			{showControls && (
				<div className={styles.controls}>
					<EditButton onClick={onEditClicked} />
					<DeleteButton onClick={onDelete} />
				</div>
			)}
		</div>
	);
};

export default CertificateItem;
