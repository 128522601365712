import styles from "./ProfileCarousel.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import useCompanyId from "hooks/useCompanyId";
import { useSelector } from "react-redux";
import CarouselItem from "./CarouselItem";
import useProfileSwitch from "hooks/useProfileSwitch";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";

const ProfileCarousel = () => {
	const companyId = useCompanyId();

	const user = useSelector((state) => state.user.profileInfo);

	const { switchToProfile, switchToCompany, userCompanies, isLoading } =
		useProfileSwitch();

	const profileList = userCompanies?.map((company) => (
		<SwiperSlide key={company.uuid} className={styles.profile_wrapper}>
			<CarouselItem
				data={company}
				isActive={companyId === company.uuid}
				onClick={() => switchToCompany(company.uuid)}
			/>
		</SwiperSlide>
	));

	if (isLoading)
		return (
			<div className={styles.container}>
				<LoadingSpinner
					customStyle={styles.spinner_wrapper}
					size={30}
				/>
			</div>
		);

	return (
		<Swiper
			slidesPerView={"auto"}
			spaceBetween={20}
			className={styles.container}
			centeredSlidesBounds={true}
		>
			<SwiperSlide className={styles.profile_wrapper}>
				<CarouselItem
					data={user}
					isActive={!companyId}
					onClick={() => switchToProfile(user.uuid)}
				/>
			</SwiperSlide>

			{profileList}
		</Swiper>
	);
};

export default ProfileCarousel;
