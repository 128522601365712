import { useState, useEffect } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../../hooks/useApi";
import servicesApi from "../../../../api/services";
import useDebounce from "../../../../hooks/useDebounce";
import { servicesKeys } from "queryKeys/services-key-factory";

const feedSize = 50;

export const useGetServices = (userId, inView) => {
	const [searchInput, setSearchInput] = useState("");

	const query = useDebounce(searchInput);

	const getServicesApi = useApi(servicesApi.getAllServices, true, true);

	const fetchServices = async ({ pageParam = 0, queryKey }) => {
		const [, , , id, query] = queryKey;
		const response = await getServicesApi.request(
			pageParam,
			feedSize,
			query,
			id
		);

		return response.data;
	};

	const {
		data: services,
		hasNextPage: hasNextPageLevel1,
		fetchNextPage: fetchNextPageLevel1,
		isLoading: isLoadingLevel1,
		isFetching: isFetchingLevel1,
	} = useInfiniteQuery({
		queryKey: servicesKeys.search(userId, query),
		queryFn: fetchServices,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === feedSize ? pages.length : undefined;
			return nextPage;
		},
		refetchOnWindowFocus: false,
	});

	// const queryClient = useQueryClient();

	// const invalidateServices = () => {
	// 	queryClient.invalidateQueries(servicesKeys.search(userId, query));
	// };

	const updateExpanded = (service, currentExpanded1, currentExpanded2) => {
		let newExpanded1 = [...currentExpanded1];
		let newExpanded2 = [...currentExpanded2];

		if (service.parents) {
			if (service.parents.p3) {
				newExpanded1.push(service.parents.p3.uuid);
				newExpanded2.push(service.uuid);
			}
			if (service.parents.p3 && service.parents.p2) {
				newExpanded2.push(service.parents.p2.uuid);
			}
			if (!service.parents.p3) {
				newExpanded1.push(service.uuid);
			}
		}

		return [newExpanded1, newExpanded2];
	};

	useEffect(() => {
		if (inView && hasNextPageLevel1 && !isFetchingLevel1) {
			fetchNextPageLevel1();
		}
	}, [fetchNextPageLevel1, hasNextPageLevel1, inView, isFetchingLevel1]);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};
	const _handleClearSearch = () => {
		setSearchInput("");
	};

	return {
		searchInput,
		handleSearchInputChange,
		isStale: searchInput !== query,
		isLoadingLevel1,
		isFetchingLevel1,
		_handleClearSearch,
		updateExpanded,
		services,
	};
};
