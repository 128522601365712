import client from "./client";

const endpoint = "/search";

const searchAll = (q = "", locationId) =>
	client.get(endpoint + `/all?q=${q}&locationId=${locationId}`);

//* USERS ===========
const searchUsers = (page, size, request) =>
	client.post(endpoint + `/users?page=${page}&size=${size}`, request);

const usersFilters = (request) =>
	client.post(endpoint + `/users/filter`, request);

const languageFilters = (page, size, request) =>
	client.post(
		endpoint + `/users/filter/languages?page=${page}&size=${size}`,
		request
	);

const rolesFilters = (page, size, request) =>
	client.post(
		endpoint + `/users/filter/roles?page=${page}&size=${size}`,
		request
	);

const skillsFilters = (page, size, request) =>
	client.post(
		endpoint + `/users/filter/skills?page=${page}&size=${size}`,
		request
	);

const softwareFilters = (page, size, request) =>
	client.post(
		endpoint + `/users/filter/software?page=${page}&size=${size}`,
		request
	);

//* COMPANIES =======
const searchCompanies = (page, size, request) =>
	client.post(endpoint + `/companies?page=${page}&size=${size}`, request);

const companiesFilters = (request) =>
	client.post(endpoint + `/companies/filter`, request);

const companiesIndustries = (page, size, request) =>
	client.post(
		endpoint + `/companies/filter/industries?page=${page}&size=${size}`,
		request
	);

const companiesSpecialties = (page, size, request) =>
	client.post(
		endpoint + `/companies/filter/specialties?page=${page}&size=${size}`,
		request
	);

//* POSTS ===========
const searchPosts = (page, size, request) =>
	client.post(endpoint + `/posts?page=${page}&size=${size}`, request);

const postsFilters = (request) =>
	client.post(endpoint + `/posts/filter`, request);

//* PRODUCTS ========
const searchProducts = (page, size, filter, sort = "NEWEST") =>
	client.post(
		endpoint + `/products?page=${page}&size=${size}&sort=${sort}`,
		filter
	);
const productsFilters = (request) =>
	client.post(endpoint + `/products/filters`, request);

const productsColors = (page, size, request) =>
	client.post(
		endpoint + `/products/filters/colors?page=${page}&size=${size}`,
		request
	);

const productsMaterials = (page, size, request) =>
	client.post(
		endpoint + `/products/filters/materials?page=${page}&size=${size}`,
		request
	);

const productsOwners = (page, size, request) =>
	client.post(
		endpoint + `/products/filters/owners?page=${page}&size=${size}`,
		request
	);

const productsServices = (page, size, request) =>
	client.post(
		endpoint + `/products/filters/services?page=${page}&size=${size}`,
		request
	);

//* SERVICES ========
const searchServices = (page, size, filter, numberOfProducts = 0) =>
	client.post(
		endpoint +
			`/services?page=${page}&size=${size}&numberOfProducts=${numberOfProducts}`,
		filter
	);

const servicesFilters = (request) =>
	client.post(endpoint + `/services/filters`, request);

const servicesCategories = (page, size, request) =>
	client.post(
		endpoint + `/services/filters/categories?page=${page}&size=${size}`,
		request
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	searchAll,
	searchUsers,
	usersFilters,
	languageFilters,
	rolesFilters,
	skillsFilters,
	softwareFilters,
	searchCompanies,
	companiesFilters,
	companiesIndustries,
	companiesSpecialties,
	searchPosts,
	postsFilters,
	searchProducts,
	productsFilters,
	productsColors,
	productsMaterials,
	productsOwners,
	productsServices,
	searchServices,
	servicesFilters,
	servicesCategories,
};
