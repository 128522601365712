import { useState } from "react";
import useApi from "./useApi";
import reportApi from "api/report";
import {
	useQuery,
	// useQueryClient
} from "@tanstack/react-query";
import { reportKeys } from "queryKeys/report-key-factory";

const useReportPost = (category) => {
	const [showReportModal, setShowReportModal] = useState(false);

	const getReportReasonsApi = useApi(reportApi.getReportReasons, true, true);

	const fetchReasons = async ({ queryKey }) => {
		const [_, __, category] = queryKey;
		const response = await getReportReasonsApi.request(category);
		return response.data;
	};

	const {
		isLoading,
		// isError,
		data: reasons,
	} = useQuery({
		queryKey: reportKeys.reasonsList(
			category === "group" ? "chat" : category
		),
		queryFn: fetchReasons,
		useErrorBoundary: false,
		enabled: showReportModal,
		// staleTime: 300000, // 5 minutes
	});

	return {
		showReportModal,
		setShowReportModal,
		reasons,
		isLoading,
	};
};

export default useReportPost;
