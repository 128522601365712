import React from "react";

import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";

import styles from "../Header.module.css";

import CalendarEntry from "../../components/Profile/WorkStatusTab/CalendarEntry";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import { useLocation, useNavigate } from "react-router-dom";

const CalendarEntryView = () => {
	const navigate = useNavigate();

	const { pathname, state } = useLocation();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} />
					{pathname === "/profile/calendar/add"
						? "Add to Calendar"
						: "Edit Calendar"}
				</div>
			}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={<CalendarEntry state={state} />}
			rightSideBgColor={"#FFF"}
			defaultHeader={false}
		/>
	);
};

export default CalendarEntryView;
