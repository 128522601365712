import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import specialProfileApi from "../../../api/special-profile";
import useApi from "../../../hooks/useApi";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import SecondaryButton from "../../Utils/Button/SecondaryButton";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import styles from "./PendingTalentModal.module.css";

const AliasModal = ({ show, data, onClose, onUpdate }) => {
	const updateAliasApi = useApi(specialProfileApi.updateAlias, true);

	const aliasRef = useRef(data?.alias || data?.name);
	const [talent, setTalent] = useState(data);

	const handleAlias = ({ target }) => {
		setTalent((prevState) => ({ ...prevState, alias: target.value }));
	};

	const handleConfirm = async () => {
		const response = await updateAliasApi.request(
			talent.uuid,
			talent.alias
		);
		if (response.status === 200) onUpdate(talent);
		else toast.error("Something Wrong Happened");

		onClose();
	};

	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={"Rename Alias"}
			closeAlign={"right"}
			children={
				<div className={styles.container}>
					<div className={styles.warning_container}>
						{`You are about to rename the talent ${aliasRef.current} in your Agency’s talent’s list.`}
					</div>

					<div className={styles.input_wrapper}>
						<label>Alias name</label>

						<TextInput
							onChange={handleAlias}
							placeholder="Enter Alias Name"
							value={talent.alias}
						/>
						<p>
							If you choose to enter an alias name for this
							talent, your chosen alias name will be displayed
							instead of the talent’s stage name.
						</p>
					</div>

					<div className={styles.button_container}>
						<SecondaryButton
							text={"Cancel"}
							className={`${styles.btn} ${styles.cancel}`}
							onClick={onClose}
						/>

						<PrimaryButton
							text={"confirm"}
							className={styles.btn}
							type="button"
							onClick={handleConfirm}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default AliasModal;
