import React from "react";
import "../Utils/Popover/Popover.css";
import { useNavigate } from "react-router-dom";

import msgIconBlue from "images/message-icon.svg";
import accountIcon from "images/account-icon.svg";
import binIcon from "images/bin-icon.svg";
import addFriend from "images/add-friend-icon.svg";
import addWork from "images/add-work-icon.svg";
import adminIcon from "images/admin-icon.svg";

const GroupMemberOptions = ({
	url,
	friendStatus,
	userIsAdmin,
	amIAdmin,
	inMyWorkCircle,
	onUpdateConnection,
	onDirectMsg,
	onMakeAdmin,
	onRevokeAdmin,
	onDelete,
	onClose,
}) => {
	const navigate = useNavigate();

	return (
		<div className="popover_container">
			<button
				className="popover_item"
				onClick={() => navigate(`/profile/${url}`)}
			>
				<img src={accountIcon} alt="profile" />
				<span>View info</span>
			</button>

			{friendStatus === "N_A" && (
				<button
					className="popover_item"
					onClick={() => {
						onUpdateConnection("ADD_FRIEND");
						onClose && onClose();
					}}
				>
					<img src={addFriend} alt="friend" />
					<span>Add Friend</span>
				</button>
			)}

			{friendStatus === "APPROVED" && (
				<button
					className="popover_item"
					onClick={() => {
						onUpdateConnection("REMOVE_FRIEND");
						onClose && onClose();
					}}
				>
					<img src={addFriend} alt="friend" />
					<span>Remove Friend</span>
				</button>
			)}

			{friendStatus === "PENDING" && (
				<button
					className="popover_item"
					onClick={() => {
						onUpdateConnection("ACCEPT_FRIEND");
						onClose && onClose();
					}}
				>
					<img src={addFriend} alt="friend" />
					<span>Accept / Decline Friend</span>
				</button>
			)}

			{friendStatus === "SENT" && (
				<button
					className="popover_item"
					onClick={() => {
						onUpdateConnection("REVOKE_FRIEND");
						onClose && onClose();
					}}
				>
					<img src={addFriend} alt="friend" />
					<span>Revoke Friend</span>
				</button>
			)}

			{inMyWorkCircle ? (
				<button
					className="popover_item"
					onClick={() => {
						onUpdateConnection("REMOVE_WORK");
						onClose && onClose();
					}}
				>
					<img src={addWork} alt="work circle" />
					<span>Remove from work circle</span>
				</button>
			) : (
				<button
					className="popover_item"
					onClick={() => {
						onUpdateConnection("ADD_WORK");
						onClose && onClose();
					}}
				>
					<img src={addWork} alt="work circle" />
					<span>Add to work circle</span>
				</button>
			)}

			<button
				className="popover_item"
				onClick={() => {
					onDirectMsg();
					onClose && onClose();
				}}
			>
				<img src={msgIconBlue} alt="message" />
				<span>Message this person</span>
			</button>
			{amIAdmin && (
				<>
					{!userIsAdmin ? (
						<button
							className="popover_item"
							onClick={() => {
								onMakeAdmin();
								onClose && onClose();
							}}
						>
							<img src={adminIcon} alt="admin" />
							<span>Make Admin</span>
						</button>
					) : (
						<button
							className="popover_item"
							onClick={() => {
								onRevokeAdmin();
								onClose && onClose();
							}}
						>
							<img src={adminIcon} alt="admin" />
							<span>Dismiss Admin</span>
						</button>
					)}

					<button
						className="popover_item"
						onClick={() => {
							onDelete();
							onClose && onClose();
						}}
					>
						<img src={binIcon} alt="delete" />
						<span>Remove from group</span>
					</button>
				</>
			)}
		</div>
	);
};

export default GroupMemberOptions;
