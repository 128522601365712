import "./CustomCheckbox.css";

function CustomCheckbox({
	id,
	name,
	checked,
	onChange,
	customBackgroundColor,
	marginRight = "10px",
	isCircle = false,
	readOnly = false,
	isBorder = false,
	disabled = false,
	small = false,
}) {
	return (
		<div
			className={`checkbox-container ${small ? "small" : undefined} ${
				disabled ? "disabled" : undefined
			}`}
			style={{
				marginRight: marginRight,
			}}
		>
			<input
				id={id}
				name={name}
				type="checkbox"
				checked={checked || false}
				onChange={onChange}
				readOnly={readOnly}
				disabled={disabled}
			/>
			<span
				className={`checkmark ${
					customBackgroundColor ? customBackgroundColor : `black`
				} ${isCircle ? "circle" : undefined} ${
					isBorder ? "box" : undefined
				} ${disabled ? "disabled" : undefined}`}
			/>
		</div>
	);
}

export default CustomCheckbox;
