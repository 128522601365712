import styles from "./ProfileEditCollapsableMenu.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// import eyeIcon from "images/view-icon-grey-18-x-12.svg";
import editIcon from "images/edit-icon-dgrey-15-x-17.svg";
import addGreyIcon from "images/plus-icon-grey-16-x-16.svg";
import AccordionItem from "../../Utils/Accordion/AccordionItem";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";

const ProfileEditCollapsableMenu = ({
	activeKeyProp,
	activeItemProp,
	editCompany,
}) => {
	const navigate = useNavigate();

	const {
		profileImage,
		profileCover,
		softwareList = [],
		languages = [],
		bio,
		certificates,
		contacts,
		sites,
		socials,
		addresses,
		others,
		roles = [],
		skills = [],
		experiences = [],
		educations = [],
		keyPeople,
		specialProfileCounts,
	} = useSelector((state) => {
		if (editCompany) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const reducer = (previousValue, currentValue) =>
		previousValue + currentValue;
	const exp = experiences.map((exp) => exp.roleList.length);
	const expCount = exp.length > 0 ? exp.reduce(reducer) : 0;

	const [activeKey, setActiveKey] = useState(activeKeyProp || "profile");
	const [activeItem, setActiveItem] = useState("");

	useEffect(() => {
		setActiveItem(activeItemProp);
	}, [activeItemProp]);

	const onChange = (newKey) => {
		if (newKey === activeKey) setActiveKey("");
		else setActiveKey(newKey);
	};

	return (
		<>
			<AccordionItem
				disableNav={true}
				className={styles.accordion_item}
				onToggle={onChange}
				isOpen={activeKey === "profile"}
				title="profile"
				itemKey="profile"
				content={
					<ul className={styles.custom_list}>
						{/* <button>
							<li
								className={
									activeItem === "view" ? styles.active : ""
								}
							>
								<span>
									<img src={eyeIcon} alt="view" />
								</span>
								<label>View My Profile</label>
							</li>
						</button> */}
						<button onClick={() => navigate(`/profile/profilePic`)}>
							<li
								className={
									activeItem === "profilePic"
										? styles.active
										: ""
								}
							>
								<span>
									<img
										src={
											profileImage
												? editIcon
												: addGreyIcon
										}
										alt="edit"
									/>
								</span>
								<label>Profile Picture</label>
							</li>
						</button>
						<button onClick={() => navigate(`/profile/coverPic`)}>
							<li
								className={
									activeItem === "coverPic"
										? styles.active
										: ""
								}
							>
								<span>
									<img
										src={
											profileCover
												? editIcon
												: addGreyIcon
										}
										alt="edit"
									/>
								</span>
								<label>Cover Picture</label>
							</li>
						</button>
						{!editCompany && (
							<button onClick={() => navigate("/profile/role")}>
								<li
									className={
										activeItem === "roles"
											? styles.active
											: ""
									}
								>
									<span>
										<img
											src={
												roles && roles.length > 0
													? editIcon
													: addGreyIcon
											}
											alt="edit"
										/>
									</span>
									<label>{`Roles ${
										roles && roles.length > 0
											? `(${roles.length})`
											: ``
									}`}</label>
								</li>
							</button>
						)}
						<button onClick={() => navigate(`/profile/edit/bio`)}>
							<li
								className={
									activeItem === "bio" ? styles.active : ""
								}
							>
								<span>
									<img
										src={bio ? editIcon : addGreyIcon}
										alt="edit"
									/>
								</span>
								<label>Bio</label>
							</li>
						</button>
						{!editCompany && (
							<button
								onClick={() => navigate("/profile/experience")}
							>
								<li
									className={
										activeItem === "experience"
											? styles.active
											: ""
									}
								>
									<span>
										<img
											src={
												expCount > 0
													? editIcon
													: addGreyIcon
											}
											alt="edit"
										/>
									</span>
									<label>{`Experience ${
										expCount > 0 ? `(${expCount})` : ""
									}`}</label>
								</li>
							</button>
						)}
						{!editCompany && (
							<>
								<button
									onClick={() =>
										navigate("/profile/software")
									}
								>
									<li
										className={
											activeItem === "software"
												? styles.active
												: ""
										}
									>
										<span>
											<img
												src={
													softwareList &&
													softwareList.length > 0
														? editIcon
														: addGreyIcon
												}
												alt="edit"
											/>
										</span>
										<label>{`Software ${
											softwareList &&
											softwareList.length > 0
												? `(${softwareList.length})`
												: ``
										}`}</label>
									</li>
								</button>
								<button
									onClick={() =>
										navigate("/profile/education")
									}
								>
									<li
										className={
											activeItem === "education"
												? styles.active
												: ""
										}
									>
										<span>
											<img
												src={
													educations &&
													educations.length > 0
														? editIcon
														: addGreyIcon
												}
												alt="edit"
											/>
										</span>
										<label>{`Education ${
											educations && educations.length > 0
												? `(${educations.length})`
												: ``
										}`}</label>
									</li>
								</button>
							</>
						)}
						<button
							onClick={() => navigate(`/profile/certificate`)}
						>
							<li
								className={
									activeItem === "certificate"
										? styles.active
										: ""
								}
							>
								<span>
									<img
										src={
											certificates &&
											certificates.length > 0
												? editIcon
												: addGreyIcon
										}
										alt="edit"
									/>
								</span>
								<label>{`Courses & Certification ${
									certificates && certificates.length > 0
										? `(${certificates.length})`
										: ""
								}`}</label>
							</li>
						</button>
						{!editCompany && (
							<>
								<button
									onClick={() =>
										navigate("/profile/language")
									}
								>
									<li
										className={
											activeItem === "language"
												? styles.active
												: ""
										}
									>
										<span>
											<img
												src={
													languages &&
													languages.length > 0
														? editIcon
														: addGreyIcon
												}
												alt="edit"
											/>
										</span>
										<label>{`Languages ${
											languages && languages.length > 0
												? `(${languages.length})`
												: ``
										}`}</label>
									</li>
								</button>

								<button
									onClick={() => navigate("/profile/skill")}
								>
									<li
										className={
											activeItem === "skills"
												? styles.active
												: ""
										}
									>
										<span>
											<img
												src={
													skills && skills.length > 0
														? editIcon
														: addGreyIcon
												}
												alt="edit"
											/>
										</span>
										<label>{`Skills ${
											skills && skills.length > 0
												? `(${skills.length})`
												: ``
										}`}</label>
									</li>
								</button>
							</>
						)}

						{editCompany && (
							<button
								onClick={() => navigate(`/profile/key-people`)}
							>
								<li
									className={
										activeItem === "keyPeople"
											? styles.active
											: ""
									}
								>
									<span>
										<img
											src={
												keyPeople &&
												keyPeople.length > 0
													? editIcon
													: addGreyIcon
											}
											alt="edit"
										/>
									</span>
									<label>{`Key People ${
										keyPeople && keyPeople.length > 0
											? `(${keyPeople.length})`
											: ``
									}`}</label>
								</li>
							</button>
						)}
					</ul>
				}
			/>

			{!editCompany && (
				<AccordionItem
					disableNav={true}
					className={styles.accordion_item}
					onToggle={onChange}
					isOpen={activeKey === "special"}
					title="Special profile(s)"
					itemKey="special"
					content={
						<ul className={styles.custom_list}>
							<button
								onClick={() =>
									navigate(routes.specialProfilesList())
								}
							>
								<li
									className={
										activeItem === "specialProfile"
											? styles.active
											: ""
									}
								>
									<span>
										<img
											src={
												specialProfileCounts > 0
													? editIcon
													: addGreyIcon
											}
											alt="edit"
										/>
									</span>
									<label>{`Add a special profile ${
										specialProfileCounts > 0
											? `(${specialProfileCounts})`
											: ""
									}`}</label>
								</li>
							</button>
						</ul>
					}
				/>
			)}

			<AccordionItem
				disableNav={true}
				className={styles.accordion_item}
				onToggle={onChange}
				isOpen={activeKey === "contacts"}
				title="contacts"
				itemKey="contacts"
				content={
					<ul className={styles.custom_list}>
						<button onClick={() => navigate(`/profile/contact`)}>
							<li
								className={
									activeItem === "mobile" ? styles.active : ""
								}
							>
								<span>
									<img
										src={
											contacts && contacts.length > 0
												? editIcon
												: addGreyIcon
										}
										alt="edit"
									/>
								</span>
								<label>{`Mobile / Landline ${
									contacts && contacts.length > 0
										? `(${contacts.length})`
										: ""
								}`}</label>
							</li>
						</button>

						<button onClick={() => navigate(`/profile/email`)}>
							<li
								className={
									activeItem === "email" ? styles.active : ""
								}
							>
								<span>
									<img
										src={
											sites && sites.length > 0
												? editIcon
												: addGreyIcon
										}
										alt="edit"
									/>
								</span>
								<label>{`Email / Website ${
									sites && sites.length > 0
										? `(${sites.length})`
										: ""
								}`}</label>
							</li>
						</button>

						<button onClick={() => navigate(`/profile/social`)}>
							<li
								className={
									activeItem === "social" ? styles.active : ""
								}
							>
								<span>
									<img
										src={
											socials && socials.length > 0
												? editIcon
												: addGreyIcon
										}
										alt="edit"
									/>
								</span>
								<label>{`Social Media ${
									socials && socials.length > 0
										? `(${socials.length})`
										: ""
								}`}</label>
							</li>
						</button>
						<button onClick={() => navigate(`/profile/address`)}>
							<li
								className={
									activeItem === "address"
										? styles.active
										: ""
								}
							>
								<span>
									<img
										src={
											addresses && addresses.length > 0
												? editIcon
												: addGreyIcon
										}
										alt="edit"
									/>
								</span>
								<label>{`Addresses ${
									addresses && addresses.length > 0
										? `(${addresses.length})`
										: ""
								}`}</label>
							</li>
						</button>

						<button onClick={() => navigate(`/profile/other`)}>
							<li
								className={
									activeItem === "other" ? styles.active : ""
								}
							>
								<span>
									<img
										src={
											others && others.length > 0
												? editIcon
												: addGreyIcon
										}
										alt="edit"
									/>
								</span>
								<label>{`Other Information ${
									others && others.length > 0
										? `(${others.length})`
										: ""
								}`}</label>
							</li>
						</button>
					</ul>
				}
			/>
		</>
	);
};

export default ProfileEditCollapsableMenu;
