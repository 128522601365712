import styles from "./LandingPageSlider.module.scss";

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import LazyImage from "components/Utils/LazyImage/LazyImage";
import {Autoplay} from "swiper";


const LandingPageSlider = () => {

    return <div className={styles.container}>
        <Swiper
            allowTouchMove={false}
            autoplay={{delay: 2000}}
            speed={1200}
            // spaceBetween={20}
            className={styles.swiper}
            modules={[Autoplay]}
            slidesPerView={"auto"}
            loop={true}
            breakpoints={
                {
                    320: {spaceBetween: 4},
                    744: {spaceBetween: 10},
                    1280: {spaceBetween: 20},
                }
            }
        >
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider1.png"} alt={"slider1"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider2.png"} alt={"slider2"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider3.png"} alt={"slider3"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider4.png"} alt={"slider4"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider5.png"} alt={"slider5"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider1.png"} alt={"slider1"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider2.png"} alt={"slider2"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider3.png"} alt={"slider3"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider4.png"} alt={"slider4"}/>}/>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <LazyImage inlineStyle={styles.sliderImg}
                           image={<img src={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/slider5.png"} alt={"slider5"}/>}/>
            </SwiperSlide>
        </Swiper>
    </div>;
};

export default LandingPageSlider;