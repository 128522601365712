const useBrowserLocation = (grantedCallback, deniedCallback) => {
    const showCurrentLocation = () => {
        // navigator.geolocation.getCurrentPosition // >> not accurate enough / sometimes doesn't get location at all
        navigator.geolocation.watchPosition(
            (position) => {
                grantedCallback(position);
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    deniedCallback && deniedCallback(error);
                }
            },
            { enableHighAccuracy: false, timeout: 5000, maximumAge: Infinity }
        );
    };

    const getBrowserLocation = () => {
        navigator.permissions.query({ name: "geolocation" }).then((_result) => {
            // if (_result.state === "granted") {
            //     console.log("LOCATION IS GRANTED !!!!");
            // } else if (_result.state === "prompt") {
            // }
            showCurrentLocation();
        });
    };

    return getBrowserLocation;
};

export default useBrowserLocation;
