import React from "react";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import binIcon from "images/bin-icon-gray.svg";

import styles from "./VaultDeleteModal.module.css";

const VaultDeleteModal = ({ visible, onConfirm, onClose, onReset }) => {
	const handleConfirmDelete = () => {
		onConfirm();
		onClose();
	};

	return (
		<>
			<GaawkModal
				show={visible}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={"Delete selected"}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.delete_icon_modal}>
							<img src={binIcon} alt="delete icon" />
						</div>
						<div className={styles.delete_msg_modal}>
							Are you sure you want to <span>Delete</span> the
							selected item ?
							<br />
							This action <strong>CAN NOT</strong> be undone!
						</div>
						<div className={styles.delete_modal_buttons}>
							<SecondaryButton
								className={styles.cancel_button}
								text={"CANCEL"}
								onClick={onClose}
							/>

							<PrimaryButton
								className={styles.delete_button}
								text={"DELETE"}
								onClick={handleConfirmDelete}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default VaultDeleteModal;
