import * as actions from "../actionCreators/api";

const socket = ({dispatch}) => (next) => (action) => {
    // check if the type is not call api request
    if (action.type !== actions.socketBegan.type)
        return next(action);

    // get the trace in the log
    next(action);

    // get the vars from the payload
    const {onSuccess, response} = action.payload;

    dispatch({type: onSuccess, payload: response});

};

export default socket;
