import styles from "./All.module.scss";
import searchApi from "api/search";
import ServicesComponent from "../SearchComponents/Services";
import { servicesInitialFilters } from "../initialFilters";
import useGlobalSearch from "hooks/useGlobalSearch";

const Services = ({
	searchInput,
	isMobile,
	onShowModal,
	tagsList,
	userCoordinates,
	// onRefetch,
}) => {
	const {
		data,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		// handleInvalidation,
	} = useGlobalSearch(
		servicesInitialFilters,
		{ locationIds: [userCoordinates.id] },
		["searchServices"],
		searchInput,
		searchApi.searchServices,
		0
	);

	return (
		<div className={styles.section}>
			<div className={styles.sub_section}>
				<ServicesComponent
					count={data?.pages[0].count}
					data={data}
					hasNextPage={hasNextPage}
					isFetching={isFetching}
					onLoad={fetchNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
					isPaginated={true}
					// onRefresh={() => {
					// 	handleInvalidation();
					// 	onRefetch();
					// }}
				/>
			</div>
		</div>
	);
};

export default Services;
