import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import styles from "./PendingRequests.module.scss";
import arrowIcon from "images/icon-navigate-forward@2x.png";
import usePendingConnections from "hooks/usePendingConnections";
import { useMemo } from "react";
import useMutate from "hooks/useMutate";
import connectionApi from "api/connection";
import ConnectionItem from "./ConnectionItem";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";

const PendingRequests = () => {
	const {
		infiniteQuery: { data: pendingRequests },
		invalidate,
	} = usePendingConnections();

	const {
		action: { mutate: acceptRequest },
	} = useMutate(connectionApi.acceptFriend, invalidate);

	const {
		action: { mutate: declineRequest },
	} = useMutate(connectionApi.declineFriend, invalidate);

	const pendingList = useMemo(
		() =>
			pendingRequests?.pages?.map((page) =>
				page.map((data) => (
					<ConnectionItem
						key={data.uuid}
						data={data}
						onDecline={() => declineRequest(data.uuid)}
						onAccept={() => acceptRequest(data.uuid)}
						showButtons={true}
					/>
				))
			),
		[pendingRequests]
	);

	if (!pendingRequests || pendingRequests?.pages[0].length === 0) return null;

	return (
		<ProfileTabContainer customStyle={styles.section_container}>
			<div className={styles.header}>
				<h3>Pending Connection Requests</h3>

				<GaawkLink
					to={"/profile/connections"}
					customStyle={styles.overflow_hidden}
					onClick={() =>
						sessionStorage.setItem(
							"activeConnectionTab",
							"Pending Requests"
						)
					}
				>
					<span>More</span> <img src={arrowIcon} alt="" />
				</GaawkLink>
			</div>
			<div className={styles.items_container}>{pendingList}</div>
		</ProfileTabContainer>
	);
};

export default PendingRequests;
