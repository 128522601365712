import { forwardRef } from "react";
// import ForwardIcon from "images/icon-navigate-forward@3x.png";
import styles from "../ProductsComponents/ProductsItems.module.scss";
import { Link, useNavigate } from "react-router-dom";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import ScrollContainer from "react-indiana-drag-scroll";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

export const ProductItems = forwardRef(
	({ productsServicesLevel1 = [] }, ref) => {
		const navigate = useNavigate();

		// if (!visible) return null;
		return (
			<>
				{productsServicesLevel1?.pages?.map((page, index) =>
					page?.map((service) => {
						return (
							<div
								className={`${styles.service_products_container}
                        ${index === 0 ? "" : styles.notFirst}`}
								key={service.uuid}
								ref={ref}
							>
								<div className={styles.name_image_container}>
									<span className={styles.service_name}>
										{service.name}
									</span>

									<SeeMore
										text="See All"
										onClick={() => {
											navigate(
												`/services/${
													service.uuid
												}${"/products"}`
											);
										}}
									/>
								</div>
								<ScrollContainer
									className={styles.scroll_container}
								>
									{service?.products?.map((item) => {
										const {
											mainImage,
											name,
											uuid: productId,
										} = item;

										return (
											<Link
												key={productId}
												to={`/profile/${item?.owner?.url}/product/${productId}`}
												className={
													styles.container_product_image
												}
											>
												<div>
													<LazyImage
														image={
															<img
																src={
																	mainImage
																		?.file
																		?.customName
																}
																alt={name}
															/>
														}
														inlineStyle={
															styles.product_img
														}
													/>
												</div>
												<span
													className={
														styles.product_name
													}
												>
													{name}
												</span>
											</Link>
										);
									})}
								</ScrollContainer>
								{/* <div className={styles.scroll}>
									<div className={styles.list}>
										{service?.products?.map((item) => {
											const {
												mainImage,
												name,
												uuid: productId,
											} = item;
											return (
												<Link
													key={productId}
													to={`/profile/${item?.owner?.url}/product/${productId}`}
													className={
														styles.container_product_image
													}
												>
													<div>
														<LazyImage
															image={
																<img
																	src={
																		mainImage
																			.file
																			.customName
																	}
																	alt={name}
																/>
															}
															inlineStyle={
																styles.product_img
															}
														/>
													</div>
													<span
														className={
															styles.product_name
														}
													>
														{name}
													</span>
												</Link>
											);
										})}
									</div>
								</div> */}
							</div>
						);
					})
				)}
			</>
		);
	}
);
