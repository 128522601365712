import locationApi from "../api/location";
import useApi from "./useApi";
import useUserLocation from "./useUserLocation";

const useFetchLocation = (isCity, itemsPerPage, isAllLocations = false) => {
	const searchLocation = useApi(
		isAllLocations
			? locationApi.locationSearch
			: isCity
			? locationApi.searchCities
			: locationApi.searchCountries,
		true,
		true
	);

	const { userCoordinates } = useUserLocation();

	const fetchLocation = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await searchLocation.request(
			pageParam,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng,
			searchInput
		);
		return response.data;
	};

	return fetchLocation;
};

export default useFetchLocation;
