import React, { useEffect, useState } from "react";
import styles from "./EmotionModal.module.css";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import useApi from "../../hooks/useApi";
import emotionApi from "../../api/emotion";
import { List } from "react-content-loader";

import removeIcon from "images/cancel-icon-graywhite.svg";

const EmotionModal = ({
	show,
	onClose,
	onSelect,
	emotionId,
	onRemoveEmotion,
}) => {
	const getEmotionsApi = useApi(emotionApi.getEmotions);
	const searchEmotionsApi = useApi(emotionApi.searchEmotions);

	const [emotionsList, setEmotionsList] = useState([]);
	const [emotionSearchText, setEmotionSearchText] = useState("");

	const handleEmotionSearchChange = ({ target }) => {
		const query = target.value;
		setEmotionSearchText(query);
		if (query) {
			searchEmotionsApi.request(query);
		} else {
			getEmotionsApi.request();
		}
	};

	const handleSelect = (emotion) => {
		onSelect(emotion);
		handleClose();
	};

	const handleClose = () => {
		setEmotionSearchText("");
		setEmotionsList([]);
		onClose();
	};

	useEffect(() => {
		if (show) {
			getEmotionsApi.request();
		}
	}, [show]);

	useEffect(() => {
		if (
			!getEmotionsApi.loading &&
			getEmotionsApi.data &&
			getEmotionsApi.responseCode === 200
		) {
			setEmotionsList(getEmotionsApi.data);
		}
	}, [getEmotionsApi.data]);

	useEffect(() => {
		if (
			!searchEmotionsApi.loading &&
			searchEmotionsApi.data &&
			searchEmotionsApi.responseCode === 200
		) {
			setEmotionsList(searchEmotionsApi.data);
		}
	}, [searchEmotionsApi.data]);

	const emotionList = emotionsList.map((emotion) => {
		return (
			<div
				className={`${styles.emotion_item} ${
					emotion.uuid === emotionId ? styles.active : undefined
				}`}
				key={emotion.uuid}
				onClick={() => handleSelect(emotion)}
			>
				<div>
					<img src={emotion.icon} alt={emotion.name} />
					<label>{emotion.name}</label>
				</div>
				{emotion.uuid === emotionId && (
					<img
						src={removeIcon}
						className={styles.remove_icon}
						onClick={onRemoveEmotion}
						alt="remove"
					/>
				)}
			</div>
		);
	});

	return (
		<GaawkModal
			show={show}
			handleClose={handleClose}
			defaultModal={false}
			showHeader={true}
			title={"How are you feeling today?"}
			closeAlign={"left"}
			children={
				<div className={styles.create_post_modal_container}>
					<div className={styles.search_input}>
						<TextInput
							placeholder={"Search..."}
							value={emotionSearchText}
							onChange={handleEmotionSearchChange}
						/>
					</div>

					<div className={styles.emotion_list_wrapper}>
						{emotionsList.length === 0 &&
							getEmotionsApi.loading && <List />}
						{emotionsList.length === 0 &&
							!getEmotionsApi.loading &&
							emotionSearchText &&
							"No results found"}
						{emotionsList.length > 0 && emotionList}
					</div>
				</div>
			}
		/>
	);
};

export default EmotionModal;
