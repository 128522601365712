import React from "react";
import styles from "./MessageReply.module.css";
import ReplyWidget from "./ReplyWidget";
import cancelIcon from "images/cancel-icon-graywhite.svg";

const MessageReply = ({ item, onCloseReply }) => {
	// console.log("THE REPLY ITEM >>", item);

	const { sendBy, message } = item;

	// console.log("%c THE REPLY ITEM >>", "font-weight: bolder; color: fuchsia", item);

	return (
		<div className={styles.reply_bubble_wrapper}>
			<div className={styles.left_side}>
				<div className={styles.reply_sent_by}>
					{sendBy.uuid === localStorage.getItem("uuid")
						? "You"
						: sendBy.name}
				</div>
				<ReplyWidget item={item} isReplying={true} />

				<div className={styles.message}>{message}</div>
			</div>
			<div className={styles.right_side}>
				<img
					src={cancelIcon}
					onClick={onCloseReply}
					className={styles.cancel_icon}
					alt="close"
				/>
			</div>
		</div>
	);
};

export default MessageReply;
