import styles from "./FrameItem.module.scss";

const FrameItem = ({ item, userImg, onSelect }) => {
	const { uuid, url, name, description } = item;

	return (
		<button
			type="button"
			onClick={() => onSelect(url, uuid)}
			className={styles.frame_item}
		>
			{userImg !== "" ? (
				<img className={styles.frame_img} src={userImg} alt="profile" />
			) : (
				<div className={styles.frame_img}></div>
			)}

			<img className={styles.out_frame} src={url} alt="frame" />

			<div className={styles.frame_details_wrapper}>
				<p className={styles.title}>{name}</p>
				<p className={styles.description}>{description}</p>
			</div>
		</button>
	);
};

export default FrameItem;
