import BoardSection from "components/Boards/BoardsComponents/BoardSection";
import styles from "./ManageBoards.module.scss";
import BoardEntry from "./BoardEntry";
import { useState } from "react";
import useCompanyId from "hooks/useCompanyId";
import { useSelector } from "react-redux";
import useApi from "hooks/useApi";
import boardsApi from "api/boards";
import useGetBoards from "./useGetBoards";
import { boardsKeys } from "queryKeys/boards-key-factory";
import useCurrentUser from "hooks/useCurrentUser";

const itemsPerPage = 20;

const ManageBoards = () => {
	const [showBoardModal, setShowBoardModal] = useState(false);

	const { uuid, type } = useCurrentUser();

	// ! =========== my boards (owned + moderating) =============

	const getMyBoardsApi = useApi(boardsApi.getMyBoards, true, true);

	const fetchMyBoards = async ({ pageParam = 0, signal }) => {
		const response = await getMyBoardsApi.request(pageParam, itemsPerPage);
		return response.data;
	};

	const myBoards = useGetBoards(fetchMyBoards, boardsKeys.list("myBoards"));

	// ! =========== followed boards =============

	const getFollowingBoardsApi = useApi(
		boardsApi.getFollowingBoards,
		true,
		true
	);

	//* just adding "signal" as parameter allows to cancel the request if component unmounts
	const fetchFollowing = async ({ pageParam = 0, signal }) => {
		const response = await getFollowingBoardsApi.request(
			uuid,
			pageParam,
			itemsPerPage
		);
		return response.data;
	};

	const followingBoards = useGetBoards(
		fetchFollowing,
		boardsKeys.list("following")
	);

	const handleNewBoard = () => {
		setShowBoardModal(true);
	};

	// !============================================
	return (
		<div className={styles.container}>
			<div className={styles.boards_wrapper}>
				<BoardSection
					title="Your boards"
					showEmpty={type !== "COMPANY"}
					onNewBoard={type !== "COMPANY" ? handleNewBoard : undefined}
					infiniteData={myBoards}
				/>

				<BoardSection
					title="Boards you follow"
					infiniteData={followingBoards}
				/>
			</div>

			<BoardEntry
				show={showBoardModal}
				onClose={() => setShowBoardModal(false)}
			/>
		</div>
	);
};

export default ManageBoards;
