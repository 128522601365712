import { useInfiniteQuery } from "@tanstack/react-query";
import useApi from "../../../hooks/useApi";
import useDebounce from "../../../hooks/useDebounce";
import profileApi from "../../../api/profile";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const useSearchNewGroupMember = (
	searchInput,
	enabled,
	groupId = "",
	itemsPerPage = 50
) => {
	const debouncedSearchGroup = useDebounce(searchInput);

	const searchGroupProfileApi = useApi(
		profileApi.searchGroupProfile,
		true,
		true
	);

	const { ref, inView } = useInView({ triggerOnce: true });

	const fetchSearch = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, groupId, searchInput] = queryKey;

		const response = await searchGroupProfileApi.request(
			pageParam,
			itemsPerPage,
			groupId,
			searchInput
		);
		return response.data;
	};

	const infiniteQuery = useInfiniteQuery({
		queryKey: ["searchProfile", groupId, debouncedSearchGroup],
		queryFn: fetchSearch,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		enabled,
		// initialData: { pageParams: [undefined], pages: [[]] },
	});

	const { hasNextPage, fetchNextPage } = infiniteQuery;

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	return { ref, infiniteQuery };
};

export default useSearchNewGroupMember;
