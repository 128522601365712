import { useState, useEffect } from "react";
import styles from "./ConnectionDetails.module.css";
import ConnectionScrollableMenu from "./ConnectionScrollableMenu";
import profileApi from "../../../api/profile";
import useApi from "../../../hooks/useApi";
import { pluralize } from "../../Utils/General";
import Friends from "./Tabs/Friends";
import Sent from "./Tabs/Sent";
import Pending from "./Tabs/Pending";
import YourWorkCircle from "./Tabs/YourWorkCircle";
import TheirWorkCircle from "./Tabs/TheirWorkCircle";
import Following from "./Tabs/Following";
import Followers from "./Tabs/Followers";
import Favorites from "./Tabs/Favorites";
import Blocked from "./Tabs/Blocked";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";

const ConnectionDetails = ({ canEdit, profile }) => {
	const { firstName, lastName, name, type } = profile;

	const shortName = type === "USER" ? `${firstName}` : `${name}`;

	const fullName = type === "USER" ? `${firstName} ${lastName}` : `${name}`;
	const [activeTab, setActiveTab] = useState(
		() => sessionStorage.getItem("activeConnectionTab") || "Friends"
	);

	const getConnectionsCountApi = useApi(profileApi.getConnectionsCount);

	const [connectionCount, setConnectionCount] = useState("");

	const {
		friends,
		friendPendingSent,
		friendPendingReceived,
		inMyWorkCircle,
		inTheirWorkCircles,
		following,
		followers,
		favorites,
		blocking,
	} = connectionCount;

	const handleTabClicked = ({ target }) => {
		if (target.innerHTML === "Following") {
			setActiveTab("People you follow");
			sessionStorage.setItem("activeConnectionTab", "People you follow");
		} else if (target.innerHTML === "Followers") {
			setActiveTab("People who follow you");
			sessionStorage.setItem(
				"activeConnectionTab",
				"People who follow you"
			);
		} else if (target.innerHTML === "Their work circle") {
			setActiveTab("Your work circle");
			sessionStorage.setItem("activeConnectionTab", "Your work circle");
		} else if (target.innerHTML === `Work circles ${shortName} is in`) {
			setActiveTab("Work circles you're in");
			sessionStorage.setItem(
				"activeConnectionTab",
				"Work circles you're in"
			);
		} else {
			setActiveTab(target.innerHTML);
			sessionStorage.setItem("activeConnectionTab", target.innerHTML);
		}
		setSearchInput("");
	};

	useEffect(() => {
		getConnectionsCountApi.request(profile.uuid);
	}, [
		profile.uuid,
		friendPendingSent,
		friendPendingReceived,
		inMyWorkCircle,
		inTheirWorkCircles,
		following,
		favorites,
		blocking,
	]);

	useEffect(() => {
		if (
			!getConnectionsCountApi.loading &&
			getConnectionsCountApi.responseCode === 200 &&
			getConnectionsCountApi.data &&
			Object.keys(getConnectionsCountApi.data).length > 0
		) {
			setConnectionCount(getConnectionsCountApi.data);
		}
	}, [getConnectionsCountApi.data]);

	const getCounter = (activeTab) => {
		switch (activeTab) {
			case "Friends":
				if (canEdit)
					return `You have ${friends} ${pluralize(
						friends,
						"friend"
					)}`;
				return `${fullName} has ${friends} ${pluralize(
					friends,
					"friend"
				)}`;
			case "Sent Requests":
				return `You have sent ${friendPendingSent} friend ${pluralize(
					friendPendingSent,
					"request"
				)}`;
			case "Pending Requests":
				return `You have received ${friendPendingReceived} friend ${pluralize(
					friendPendingReceived,
					"request"
				)}`;
			case "Your work circle":
				if (canEdit)
					return `You have ${inMyWorkCircle} ${pluralize(
						inMyWorkCircle,
						"connection"
					)} in your work circle`;
				return `${fullName} has ${inMyWorkCircle} ${pluralize(
					inMyWorkCircle,
					"connection"
				)} in their work circle`;
			case "Work circles you're in":
				if (canEdit)
					return `${inTheirWorkCircles} ${pluralize(
						inTheirWorkCircles,
						"connection"
					)} added you to their work circle`;
				return `${inTheirWorkCircles} ${pluralize(
					inTheirWorkCircles,
					"connection"
				)} added ${fullName} to their work circle`;
			case "People you follow":
				if (canEdit)
					return `You follow ${following} ${pluralize(
						following,
						"connection"
					)}`;
				return `${fullName} follows ${following} ${pluralize(
					following,
					"connection"
				)}`;
			case "People who follow you":
				if (canEdit)
					return `${followers} ${pluralize(
						followers,
						"connection"
					)} follow you`;
				return `${followers} ${pluralize(
					followers,
					"connection"
				)} follow ${fullName} `;
			case "Favorites":
				return `You have favorited ${favorites} ${pluralize(
					favorites,
					"connection"
				)}`;
			case "Blocked":
				return `You have blocked ${blocking} ${pluralize(
					blocking,
					"connection"
				)}`;
			default:
				return;
		}
	};

	const handleUpdateCounter = (category, action) => {
		switch (category) {
			case "friendPendingSent":
				return setConnectionCount((prevState) => ({
					...prevState,
					friendPendingSent: prevState.friendPendingSent - 1,
				}));
			case "friendPendingReceived":
				return setConnectionCount((prevState) => ({
					...prevState,
					friendPendingReceived: prevState.friendPendingReceived - 1,
				}));
			case "inMyWorkCircle":
				return setConnectionCount((prevState) => ({
					...prevState,
					inMyWorkCircle:
						action === "INCREMENT"
							? prevState.inMyWorkCircle + 1
							: prevState.inMyWorkCircle - 1,
				}));
			case "following":
				return setConnectionCount((prevState) => ({
					...prevState,
					following:
						action === "INCREMENT"
							? prevState.following + 1
							: prevState.following - 1,
				}));
			case "favorites":
				return setConnectionCount((prevState) => ({
					...prevState,
					favorites:
						action === "INCREMENT"
							? prevState.favorites + 1
							: prevState.favorites - 1,
				}));
			case "blocking":
				return setConnectionCount((prevState) => ({
					...prevState,
					blocking:
						action === "INCREMENT"
							? prevState.blocking + 1
							: prevState.blocking - 1,
				}));
			default:
				return;
		}
	};

	// ! ========= Search handlers =========
	const [searchInput, setSearchInput] = useState("");
	const handleClear = () => {
		setSearchInput("");
	};

	const handleChangeInput = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	return (
		<div className={styles.container}>
			<div className={styles.scrollableMenu_wrapper}>
				<ConnectionScrollableMenu
					onItemClicked={handleTabClicked}
					canEdit={canEdit}
					activeTab={activeTab}
					profile={profile}
				/>
			</div>
			{connectionCount && (
				<div className={styles.counter}>{getCounter(activeTab)}</div>
			)}
			<div className={styles.search_container}>
				<SearchInput
					onChange={handleChangeInput}
					value={searchInput}
					showIcons={true}
					onClearClicked={handleClear}
					border={false}
				/>
			</div>
			<div>
				{activeTab === "Friends" && (
					<Friends
						profile={profile}
						query={searchInput}
						count={friends}
					/>
				)}
				{activeTab === "Sent Requests" && (
					<Sent
						query={searchInput}
						count={friendPendingSent}
						onUpdateCounter={handleUpdateCounter}
					/>
				)}
				{activeTab === "Pending Requests" && (
					<Pending
						query={searchInput}
						count={friendPendingReceived}
						onUpdateCounter={handleUpdateCounter}
					/>
				)}
				{activeTab === "Your work circle" && (
					<YourWorkCircle
						profile={profile}
						query={searchInput}
						count={inMyWorkCircle}
					/>
				)}
				{activeTab === "Work circles you're in" && (
					<TheirWorkCircle
						profile={profile}
						query={searchInput}
						count={inTheirWorkCircles}
						onUpdateCounter={handleUpdateCounter}
					/>
				)}
				{activeTab === "People you follow" && (
					<Following
						profile={profile}
						query={searchInput}
						count={following}
						onUpdateCounter={handleUpdateCounter}
					/>
				)}
				{activeTab === "People who follow you" && (
					<Followers
						profile={profile}
						query={searchInput}
						count={followers}
						onUpdateCounter={handleUpdateCounter}
					/>
				)}
				{activeTab === "Favorites" && (
					<Favorites
						profile={profile}
						query={searchInput}
						count={favorites}
						onUpdateCounter={handleUpdateCounter}
					/>
				)}
				{activeTab === "Blocked" && (
					<Blocked
						profile={profile}
						query={searchInput}
						count={blocking}
						onUpdateCounter={handleUpdateCounter}
					/>
				)}
			</div>
		</div>
	);
};

export default ConnectionDetails;
