import useCurrentUser from "hooks/useCurrentUser";
import styles from "./JobActions.module.scss";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popover from "components/Utils/Popover/Popover";
import routes from "components/Routing/routing-keys";
import { ReactComponent as MoreIcon } from "images/three-dots-black-5-x-20.svg";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import archiveIcon from "images/archive.svg";
import deleteIcon from "images/delete.svg";
import editIcon from "images/edit.svg";
import reportIcon from "images/report-black-icon.svg";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import { useQueryClient } from "@tanstack/react-query";
import useMutate from "hooks/useMutate";
import jobApi from "api/job";
import { jobsKeys } from "queryKeys/jobs-key-factory";

const JobActions = ({ job }) => {
	const {
		uuid,
		closed,
		jobType,
		minifiedCompany: { uuid: companyId } = {},
	} = job;

	const navigate = useNavigate();
	const popoverRef = useRef(null);
	const { uuid: profileId } = useCurrentUser();

	const isJobOwner = profileId === companyId;

	const reportRef = useRef();

	const handleReport = () => reportRef.current.showReportModal(uuid);

	const queryClient = useQueryClient();

	//! Delete job ==

	const [deleteModal, setDeleteModal] = useState(false);

	const {
		action: { mutate: deleteJob },
	} = useMutate(jobApi.deleteJob, () => {
		setDeleteModal(false);
		navigate(-1);
	});

	//! toggle status ==

	const {
		action: { mutate: toggleStatus },
	} = useMutate(jobApi.updateJobStatus, () => {
		queryClient.invalidateQueries(jobsKeys.detail(uuid));
	});

	const jobLabel = jobType === "Casting_Call" ? "Casting Call" : "Job";

	return (
		<>
			<Popover
				ref={popoverRef}
				render={
					<div className="popover_container">
						{isJobOwner ? (
							<>
								<button
									className="popover_item"
									onClick={(e) => {
										e.stopPropagation();
										navigate(routes.jobEdit(uuid));
										popoverRef.current.closePopover();
									}}
								>
									<span>
										<img src={editIcon} alt="edit" />
									</span>
									<span>Edit {jobLabel}</span>
								</button>

								<button
									className="popover_item"
									onClick={(e) => {
										e.stopPropagation();

										toggleStatus({
											uuid,
											status: closed,
										});
										popoverRef.current.closePopover();
									}}
								>
									<span>
										<img
											src={archiveIcon}
											alt={`mark as ${
												closed ? "open" : "closed"
											}`}
										/>
									</span>
									<span>
										Mark {jobLabel} as{" "}
										{closed ? "open" : "closed"}
									</span>
								</button>

								<button
									className="popover_item"
									onClick={(e) => {
										e.stopPropagation();
										setDeleteModal(true);
										popoverRef.current.closePopover();
									}}
								>
									<span>
										<img src={deleteIcon} alt="delete" />
									</span>
									<span>Delete {jobLabel}</span>
								</button>
							</>
						) : (
							<button
								className="popover_item"
								onClick={(e) => {
									e.stopPropagation();
									handleReport();
									popoverRef.current.closePopover();
								}}
							>
								<span>
									<img src={reportIcon} alt="report" />
								</span>
								<span>Report</span>
							</button>
						)}
					</div>
				}
			>
				<button
					onClick={(e) => e.stopPropagation()}
					className={styles.option_btn}
				>
					<MoreIcon fill={"#FFF"} />
				</button>
			</Popover>

			<WarningModal
				show={deleteModal}
				headerText={`Are you sure you want to delete this ${jobLabel}?`}
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={(e) => {
					e.stopPropagation();
					setDeleteModal(false);
				}}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={(e) => {
					e.stopPropagation();
					deleteJob(uuid);
				}}
			/>

			<ReportModal ref={reportRef} category="job" />
		</>
	);
};

export default JobActions;
