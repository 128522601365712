import styles from "./All.module.scss";
import searchApi from "../../../api/search";
import PostsComponent from "../SearchComponents/Posts";
import { postsInitialFilters } from "../initialFilters";
import useGlobalSearch from "../../../hooks/useGlobalSearch";

const Posts = ({
	searchInput,
	isMobile,
	onShowModal,
	tagsList,
	userCoordinates,
	onRefetch,
}) => {
	const {
		data,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		handleInvalidation,
	} = useGlobalSearch(
		postsInitialFilters,
		{ locationIdForSort: userCoordinates.id },
		["searchPosts"],
		searchInput,
		searchApi.searchPosts
	);

	return (
		<div className={styles.section}>
			<div className={`${styles.sub_section} ${styles.posts_container}`}>
				<PostsComponent
					count={data?.pages[0].count}
					data={data}
					isPaginated={true}
					hasNextPage={hasNextPage}
					isFetching={isFetching}
					onLoad={fetchNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					searchInput={searchInput}
					tagsList={tagsList}
					onRefresh={() => {
						handleInvalidation();
						onRefetch();
					}}
				/>
			</div>
		</div>
	);
};

export default Posts;
