import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Hashtags",
    initialState: {
        list: [],
    },
    reducers: {
        listUpdated: (state, action) => {
            state.list = action.payload;
        },
        resetHashtags: (state) => {
            state.list = [];
        },
    },
});

export const { listUpdated, resetHashtags } = slice.actions;
export default slice.reducer;

// action creators

/**
 * get country?city by city name
 */
export const getHashtags = (q) =>
    apiActions.apiCallBegan({
        url: `hashtags?q=${q}`,
        method: "GET",
        onSuccess: listUpdated.type,
    });
