import { useParams } from "react-router-dom";
import styles from "./ServiceProducts.module.scss";
import { AllProductsComponent } from "components/Profile/ProductsTab/ProductsComponents/AllProducts";
import { useProductsSearch } from "components/Profile/ProductsTab/hooks";
import useCompanyId from "hooks/useCompanyId";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import ProductsEmpty from "images/box@3x.png";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { setModalProduct } from "store/slices/products";

// import useWindowSize from "hooks/useWindowSize";

import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import CtaButton from "components/Utils/Button/CtaButton";
import ProductEntry from "components/Profile/ProductsTab/ProductEntry/ProductEntry";

const ServiceProducts = ({ ownerId, serviceName }) => {
	// const { width } = useWindowSize();
	// const isMobile = width < 748;
	const dispatch = useDispatch();
	const { serviceId } = useParams();
	const companyId = useCompanyId();
	const { uuid: userId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});
	const {
		_handleClearSearch,
		handleSearchInputChange,
		isFetching,
		products,
		ref,
		searchInput,
	} = useProductsSearch({
		ownerIds: [ownerId],
		serviceTags: [serviceId],
	});
	const canEdit = userId === ownerId;

	return (
		<div className={styles.container}>
			<div className={styles.search_container}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					classNameInput={styles.search_input}
					onClearClicked={_handleClearSearch}
					border={false}
					placeholder={"Search in Products"}
				/>
				<div className={styles.row}>
					<span className={styles.service_name}>{serviceName}</span>
					{canEdit && (
						<div className={styles.btn_wrapper}>
							<PrimaryButton
								text={"+ add product"}
								onClick={() => dispatch(setModalProduct(true))}
							/>
						</div>
					)}
				</div>
			</div>

			<AllProductsComponent
				visible={true}
				products={products}
				ref={ref}
				Customitems_wrapper={styles.items_wrapper}
				isViewAll
				product_img_style={styles.product_img}
				canEdit={canEdit}
			/>
			<LoadingSpinner visible={isFetching} />
			<NoResults
				visible={products?.pages[0]?.list?.length === 0}
				text={
					canEdit
						? "Feel free to drop your product right here!"
						: "No products related to your search"
				}
				isSearch={!canEdit}
				image={canEdit && ProductsEmpty}
				button={
					canEdit && (
						<CtaButton
							text={"+ Add Product"}
							onClick={() => dispatch(setModalProduct(true))}
						/>
					)
				}
				customStyle={styles.no_result_container}
				imageStyle={styles.empty_image}
			/>

			<ProductEntry />
		</div>
	);
};

export default ServiceProducts;
