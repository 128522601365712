import Joi from "joi";

const languageSchema = Joi.object({
	language: Joi.object({
		label: Joi.string(),
		value: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Language can't be empty",
			"object.base": "Language can't be empty",
		})
		.label("Language"),
	written: Joi.object({
		id: Joi.number(),
		label: Joi.string(),
		value: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Proficiency - Written can't be empty",
			"object.base": "Proficiency - Written can't be empty",
		})
		.label("Proficiency - Written"),
	spoken: Joi.object({
		id: Joi.number(),
		label: Joi.string(),
		value: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Proficiency - Spoken can't be empty",
			"object.base": "Proficiency - Spoken can't be empty",
		})
		.label("Proficiency - Spoken"),
});

export default languageSchema;
