import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SpecialBaseEntry.module.css";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
import TextInput from "../../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import CustomSelect from "../../../../../Utils/SubComs/CustomSelect/CustomSelect";
import UrlCheckInput from "../../../../../Utils/SubComs/Inputs/UrlCheck/UrlCheckInput";
import CropModal from "../../../../../Utils/CropModal/CropModal";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import specialProfileSchema from "./special-profile-schema";
import { specialProfileTypes } from "../../special-types";
import specialProfile from "../../../../../../api/special-profile";
import { differenceInYears } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import useMutate from "hooks/useMutate";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { updateSpecialProfileCounts } from "store/slices/user";
import GaawkRadioButton from "components/Utils/SubComs/GaawkRadioButton/GaawkRadioButton";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import routes from "components/Routing/routing-keys";

const SpecialBaseEntry = ({ editMode = false, profile = "" }) => {
	const {
		name,
		url: currentUrl,
		type,
		profilePic: { file } = {},
		uuid: specialProfileId,
		privacy,
	} = profile;

	const { dob, gender, customGender, specialProfileCounts } = useSelector(
		(state) => state.user.profileInfo
	);

	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { userUrl } = useParams();

	const fileInputRef = useRef(null);

	const navigate = useNavigate();
	const { state } = useLocation();

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const [cropModal, setCropModal] = useState(false);

	const [imageName, setImageName] = useState("");

	const {
		register,
		formState: { errors, isDirty, dirtyFields },
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		clearErrors,
	} = useForm({
		resolver: joiResolver(specialProfileSchema),
		mode: "all",
		defaultValues: editMode
			? {
					name: name,
					url: currentUrl,
					urlValidity: true,
					file: [file],
					type: specialProfileTypes.find(
						(profileType) => profileType.value === type
					),
					privacy,
			  }
			: {
					name: "",
					url: "",
					urlValidity: undefined,
					file: [],
					type: null,
					privacy: "public",
			  },
	});

	const urlWatcher = watch("url");
	const fileWatcher = watch("file");
	const privacyWatcher = watch("privacy");
	const urlValidityWatcher = watch("urlValidity");

	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState("");
	const [warningText, setWarningText] = useState("");

	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			if (privacyWatcher === "AGENCY" && profile.agencies?.length === 0) {
				setWarningModal(true);
				setWarningTitle("Not linked with any agency");
				setWarningText(
					"You are not linked with any agencies. If you choose this visibility, your profile will not be visible anywhere. Either link with an agency or choose some other visibility. You can link with agency in the last section of your special profile page."
				);
			}
			if (privacyWatcher === "PRIVATE") {
				setWarningModal(true);
				setWarningTitle("Dismissing pending links");
				setWarningText(
					"All pending requests with agencies will be dismissed if you change your privacy to private."
				);
			}
		}
		didMountRef.current = true;
	}, [privacyWatcher, profile.agencies?.length]);

	const { url: isUrlDirty } = dirtyFields;

	useEffect(() => {
		if (fileWatcher.length > 0) {
			clearErrors("file");
		}
	}, [fileWatcher]);

	const {
		action: { mutate: addUpdateDetails, isLoading },
	} = useMutate(
		editMode
			? specialProfile.updateSpecialProfile
			: specialProfile.addSpecialProfile,
		() => {
			editMode &&
				isUrlDirty &&
				queryClient.removeQueries(specialProfileKeys.detail(userUrl));

			editMode &&
				!isUrlDirty &&
				queryClient.invalidateQueries(
					specialProfileKeys.detail(urlWatcher)
				);

			dispatch(updateSpecialProfileCounts(specialProfileCounts + 1));
			navigate(
				routes.specialProfile(
					urlWatcher,
					state?.agencyId ? `?agency=${state?.agencyId}` : ""
				)
			);
		},
		undefined,
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		setShouldBlockSave(false);
		const {
			name,
			type: { value: type },
			url,
			file: profilePicFile,
			privacy,
		} = data;

		const formData = new FormData();
		formData.append("url", url);
		formData.append("name", name);
		formData.append("type", type);
		formData.append("privacy", privacy);

		if (profilePicFile[0] instanceof Blob)
			formData.append("profilePicFile", profilePicFile[0], imageName);

		if (editMode) {
			formData.append("specialProfileId", specialProfileId);
		}

		addUpdateDetails(formData);
	};

	return (
		<>
			<div className={styles.wrapper}>
				<form
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<div className={styles.container}>
						<div className={styles.form_input_wrapper}>
							<label>
								Special Profile Name
								<span className="required">*</span>
							</label>
							<TextInput
								error={!!errors.name}
								{...register("name")}
								placeholder="Enter your Special Profile Name"
							/>

							{errors?.name?.message && (
								<p className={styles.error_message}>
									{errors?.name?.message}
								</p>
							)}
						</div>

						<UrlCheckInput
							label={"Special Profile URL"}
							placeholder={"Enter your Special Profile URL"}
							control={control}
							name={"url"}
							errors={errors}
							urlValidityWatcher={urlValidityWatcher}
							urlWatcher={urlWatcher}
							url={currentUrl}
							onSetValue={setValue}
							onSetError={setError}
							onClearErrors={clearErrors}
							urlPrefix={"sp/"}
						/>

						<div className={styles.react_select_wrapper}>
							<label>
								Special Profile Type
								<span className="required">*</span>
							</label>
							<CustomSelect
								options={specialProfileTypes}
								placeholder="Select"
								height="35px"
								disabled={editMode}
								control={control}
								error={!!errors.type}
								name="type"
							/>
							{errors?.type?.message && (
								<p className={styles.error_message}>
									{errors?.type?.message}
								</p>
							)}
						</div>

						<div className={styles.custom_upload_wrapper}>
							<label>
								Special Profile Picture
								<span className="required">*</span>
							</label>

							{fileWatcher.length > 0 && (
								<div className={styles.thumbs_container}>
									<div className={styles.thumb}>
										<div className={styles.thumb_inner}>
											<img
												src={
													fileWatcher[0] instanceof
														File ||
													fileWatcher[0] instanceof
														Blob
														? URL.createObjectURL(
																fileWatcher[0]
														  )
														: fileWatcher[0]
																.customName
												}
												alt=""
											/>
										</div>
									</div>
								</div>
							)}

							<span className={styles.info_text}>
								You can upload image files only.
							</span>
							<div className={styles.img_option}>
								<button
									type="button"
									className={
										errors?.file ? styles.error : undefined
									}
									onClick={() => {
										fileInputRef.current.click();
									}}
								>
									<Controller
										name="file"
										control={control}
										render={() => (
											<input
												ref={fileInputRef}
												type="file"
												accept="image/png, image/jpg, image/jpeg"
												onClick={(e) =>
													e.stopPropagation()
												}
												onChange={(val) => {
													val.target.files[0] &&
														setValue(
															"file",
															[
																val.target
																	.files[0],
															],
															{
																shouldDirty: true,
															}
														);
													setImageName(
														val.target.files[0].name
													);
													setCropModal(true);
													val.target.value = "";
												}}
												tabIndex="-1"
											/>
										)}
									/>
									{editMode ? "REPLACE IMAGE" : "+ ADD IMAGE"}
								</button>
							</div>

							{errors?.file?.message && (
								<p className={styles.error_message}>
									{errors?.file?.message}
								</p>
							)}
						</div>
					</div>

					<div className={styles.separator} />

					<div className={styles.container}>
						<div className={styles.form_input_wrapper}>
							<label>Gender</label>
							<TextInput
								value={
									customGender
										? customGender[0].toUpperCase() +
										  customGender.slice(1).toLowerCase()
										: gender[0].toUpperCase() +
										  gender.slice(1).toLowerCase()
								}
								disabled
							/>
						</div>

						<div className={styles.form_input_wrapper}>
							<label>Age</label>
							<TextInput
								value={differenceInYears(
									new Date(),
									new Date(dob)
								)}
								disabled
							/>
						</div>

						<span className={styles.info_text}>
							Gender and age will be extracted from your profile.
							If you wish to change them,{" "}
							<span
								className={styles.link}
								onClick={() => navigate(routes.editProfile)}
							>
								edit
							</span>{" "}
							your main profile.
						</span>
					</div>

					<div className={styles.separator} />

					<div className={styles.container}>
						<h4 className={styles.label_title}>
							Privacy of Special Profile
						</h4>

						<GaawkRadioButton
							{...register("privacy", { required: true })}
							label={
								<>
									<h4 className={styles.label_title}>
										Public Profile
									</h4>
									<p className={styles.label_text}>
										Everyone can access your special profile
										from everywhere
									</p>
								</>
							}
							id="public"
							value="PUBLIC"
							name="privacy"
							customStyle={styles.radio_item}
						/>

						<GaawkRadioButton
							{...register("privacy", { required: true })}
							label={
								<>
									<h4 className={styles.label_title}>
										Only via Agencies
									</h4>
									<p className={styles.label_text}>
										People only find you through agencies
										you are linked with.
									</p>
								</>
							}
							id="agency"
							value="AGENCY"
							name="privacy"
							customStyle={styles.radio_item}
						/>

						<GaawkRadioButton
							{...register("privacy", { required: true })}
							label={
								<>
									<h4 className={styles.label_title}>
										Private Profile
									</h4>
									<p className={styles.label_text}>
										No one can access your special profile.
									</p>
								</>
							}
							id="private"
							value="PRIVATE"
							name="privacy"
							customStyle={styles.radio_item}
						/>

						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								text={"save"}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</form>
			</div>

			<GaawkModal
				show={warningModal}
				handleClose={() => setWarningModal(false)}
				defaultModal={false}
				showHeader={true}
				title={warningTitle}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<p>{warningText}</p>
						<div className={styles.button_container}>
							<PrimaryButton
								onClick={() => setWarningModal(false)}
								className={styles.save_btn}
								text={"ok"}
							/>
						</div>
					</div>
				}
			/>

			<PromptModal when={isDirty && shouldBlockSave} />

			<CropModal
				image={
					fileWatcher[0] instanceof File &&
					URL.createObjectURL(fileWatcher[0])
				}
				show={cropModal}
				onClose={() => setCropModal(false)}
				aspectMode={1}
				isCircle={true}
				onCrop={(cropped) => {
					setValue("file", [cropped]);
					setCropModal(false);
				}}
				returnBlob={true}
			/>
		</>
	);
};

export default SpecialBaseEntry;
