import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Album.module.css";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import galleryApi from "../../../api/gallery";
import {
	copyToClipboard,
	getVisibility,
	replaceItemInArray,
} from "../../Utils/General";
import GalleryLoader from "../../Utils/SubComs/CustomLoader/GalleryLoader";
import imgIcon from "images/image.svg";
import videoIcon from "images/video.svg";
import pdfIcon from "images/pdf.svg";
import emptyFolderIcon from "images/empty-folder-icon.svg";
import locationIcon from "images/custom-pin.svg";
import calendarIcon from "images/calendar-icon-blue.svg";
import ProfileTag from "../../Utils/SubComs/Tags/ProfileTag";
import useGalleryCount from "../../../hooks/useGalleryCount";
import GalleryItem from "./GalleryItem";
import { List } from "react-content-loader";
import PostItem from "../../Home/PostItem";
import CreatePost from "../../Home/CreatePost";
import { toast } from "react-toastify";
import WarningModal from "../../Utils/GaawkModal/WarningModal";
import EditMediaModal from "../../Home/EditMediaModal";
import useWindowSize from "../../../hooks/useWindowSize";
import TaggedModal from "./TaggedModal";
import { format } from "date-fns";
import useCompanyId from "../../../hooks/useCompanyId";
import PostViewer from "./PostViewer";
import LoadMoreButton from "../../Utils/Button/LoadMoreButton";
import useEditSubPost from "../../../hooks/useEditSubPost";
import FileSaver from "file-saver";
import CtaButton from "components/Utils/Button/CtaButton";
import EditButton from "components/Utils/Button/EditButton";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import routes from "components/Routing/routing-keys";
import GalleryContainer from "components/Utils/SubComs/GalleryContainer/GalleryContainer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { galleryKeys } from "queryKeys/gallery-key-factory";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import usePaginatedResource from "hooks/usePaginatedResource";
import { produce } from "immer";
import useUpdateReaction from "hooks/useUpdateReaction";
import useMutate from "hooks/useMutate";

const itemsPerPage = 20;

const Album = () => {
	const { albumId } = useParams();

	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const companyId = useCompanyId();

	const profile = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const profileName =
		profile.type === "COMPANY"
			? profile.name
			: `${profile.firstName} ${profile.lastName}`;

	const postRef = useRef(null);
	// const fileviewerRef = useRef(null);

	const updateAlbumCoverApi = useApi(galleryApi.updateAlbumCover);
	const updateAlbumItemApi = useApi(galleryApi.updateAlbumItem, true);

	const { width } = useWindowSize();

	const [tagsCount] = useState(() =>
		width >= 1608
			? 19
			: width < 1608 && width >= 1400
			? 15
			: width < 1400 && width >= 1166
			? 11
			: width < 1166 && width >= 974
			? 8
			: 9
	);

	// ! -- get album by id ----

	const getAlbumIdApi = useApi(galleryApi.getAlbumById, true, true);

	const fetchAlbumId = async ({ signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, albumId] = queryKey;

		const response = await getAlbumIdApi.request(albumId);
		return response.data;
	};

	const { isLoading, data: album } = useQuery({
		queryKey: galleryKeys.albumId(albumId),
		queryFn: fetchAlbumId,
	});

	const {
		imagesCount,
		videosCount,
		pdfsCount,
		audiosCount,
		title,
		date,
		visibility,
		description,
		contributes,
		tagged,
		uuid,
		post,
		gaawkAlbum,
		location,
	} = album || {};

	// !------------------

	const systemAlbum = ["Timeline", "Cover_Pic", "Profile_Pic"];
	const isSystemAlbum = systemAlbum.includes(album?.albumType);

	const isOwner = useMemo(
		() => profile.uuid === post?.owner?.uuid,
		[post, profile]
	);

	const itemsPerView = useGalleryCount();

	// ! ======== CONTRIBUTORS / TAGGED PEOPLE =========

	const isContributor = useMemo(() => {
		const contributorsIds = contributes?.map((item) => item.uuid);
		return contributorsIds?.includes(profile.uuid);
	}, [contributes]);

	const contributorsList = useMemo(
		() =>
			contributes
				?.slice(0, tagsCount)
				.map((contributor) => (
					<ProfileTag
						key={contributor.uuid}
						name={contributor.name}
						type={contributor.type}
						image={contributor.profileImage?.image}
						url={contributor.url}
						size={24}
					/>
				)),
		[contributes]
	);

	const taggedList = useMemo(
		() =>
			tagged
				?.slice(0, tagsCount)
				.map((tag) => (
					<ProfileTag
						key={tag.uuid}
						url={tag.url}
						name={tag.name}
						type={tag.type}
						image={tag.profileImage?.image}
						size={24}
					/>
				)),
		[tagged]
	);

	const [showContributorsModal, setShowContributorsModal] = useState(false);
	const [showTaggedModal, setShowTaggedModal] = useState(false);

	// !============== DELTE HANDLER ===================

	// const deleteFileApi = useApi(galleryApi.deleteFile);

	const {
		action: { mutate: deleteFile },
	} = useMutate(galleryApi.deleteFile, () => {
		if (showSlider && albumItems.length === activeSlideIndex + 1) {
			//* decrement activeSlideIndex by 1 when you're deleting the last subPost, otherwise error
			setActiveSlideIndex((prevState) => prevState - 1);
		}
		queryClient.invalidateQueries(galleryKeys.albumItems(albumId));
		queryClient.invalidateQueries(galleryKeys.albumId(albumId));
	});

	const [showWarning, setShowWarning] = useState(false);
	const [itemToDelete, setItemToDelete] = useState("");

	const handleDelete = (item) => {
		setItemToDelete(item);
		setShowWarning(true);
	};

	const handleSubmitDelete = () => {
		deleteFile({ albumId, idToDelete: itemToDelete.uuid });
		setShowWarning(false);
	};

	// ! ============ EDIT GALLERY ITEM  ===============

	const [showEditModal, setShowEditModal] = useState(false);
	const [editItem, setEditItem] = useState("");

	const handleShowEditModal = (post) => {
		setEditItem(post);
		setShowEditModal(true);
	};

	const handleUpdateAlbumItems = (updatedAlbumItem) => {
		const previousState = queryClient.getQueryData(
			galleryKeys.albumItems(albumId)
		);

		const updatedData = produce(previousState, (draftData) => {
			const { pages } = draftData;

			const newPagesArray = pages.map((page) =>
				replaceItemInArray(updatedAlbumItem, page, "uuid")
			);

			draftData.pages = newPagesArray;
		});

		queryClient.setQueryData(galleryKeys.albumItems(albumId), updatedData);

		toast.success("Post edited!");
	};

	const { handleEdit } = useEditSubPost(
		handleUpdateAlbumItems,
		updateAlbumItemApi,
		true,
		[albumId]
	);

	// ! ============ UPDATE ALBUM COVER  ==============

	const handleAlbumCover = (item) => {
		updateAlbumCoverApi.request(albumId, item.uuid);
	};

	// ! ============ UPDATE POST ======================

	const handleUpdateAlbumPost = (albumReaction) => {
		//* update the album's post reaction

		const { profileReactionType, reactionStats } = albumReaction;
		const queryKey = galleryKeys.albumId(albumId);

		const previousState = queryClient.getQueryData(queryKey);

		const updatedData = produce(previousState, (draftData) => {
			const { post } = draftData;
			post["reactionStats"] = reactionStats;
			post["profileReactionType"] = profileReactionType;
		});

		queryClient.setQueryData(queryKey, updatedData);
	};

	// ! ============ SHARE ALBUM ======================

	const handleShare = (item = null) => {
		if (item) {
			if (item.sharedPost) {
				postRef.current.sharePost(item.sharedPost);
			} else {
				postRef.current.sharePost(item);
			}
		} else {
			if (post.sharedPost) {
				postRef.current.sharePost(post.sharedPost);
			} else {
				postRef.current.sharePost(post);
			}
		}
	};

	// ! ===================== GET ALBUM AND ALBUM ITEMS ========================

	const getAlbumItemsApi = useApi(galleryApi.getAlbumItems, true, true);

	const fetchAlbumItems = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, albumId] = queryKey;

		const response = await getAlbumItemsApi.request(
			albumId,
			pageParam,
			itemsPerPage
		);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			galleryKeys.albumItems(albumId),
			fetchAlbumItems,
			itemsPerPage,
			!!album
		);

	const albumItems = data?.pages.flat();

	const loadingItems = [...Array(itemsPerView / 2)].map((_, index) => (
		<GalleryLoader key={index} />
	));

	// ! ============= ALBUM ITEMS =====================
	const handleCopyLink = (id) => {
		copyToClipboard(`${window.location.origin}/post/${id}`);
		toast.success("Link copied !");
	};

	const albumItemsList = useMemo(
		() =>
			albumItems?.map((item) => (
				<GalleryItem
					option_white
					key={item.uuid}
					item={item}
					onClick={() => handleShowSlider(item)}
					onDelete={() => handleDelete(item)}
					onEdit={() => handleShowEditModal(item)}
					onShare={() => handleShare(item)}
					// onProfile={() => alert("make profile picture")} //TODO LATER
					// onProfileCover={() => alert("make cover photo")} //TODO LATER
					onCopy={() => handleCopyLink(item.uuid)}
					onAlbumCover={() => handleAlbumCover(item)}
					isOwner={isOwner}
					showPopover={true}
					isPublic={item.postVisibility === "PUBLIC"}
				/>
			)),
		[albumItems, isOwner]
	);

	// !======== SLIDER HANDLER ========================

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const handleShowSlider = (item) => {
		const fileIndex = filesArray.findIndex((file) => file === item.file);
		setInitialSlideIndex(fileIndex);
		setShowSlider(true);
		window.history.pushState(null, "", `/post/${item.uuid}`);
	};

	const handleCloseSlider = () => {
		setShowSlider(false);
		window.history.replaceState(null, "", `/profile/album/${album.uuid}`);
	};

	const updatePostReaction = useUpdateReaction();

	useEffect(() => {
		//* code to change the url based on the slide index

		if (showSlider) {
			window.history.replaceState(
				null,
				"",
				`/post/${albumItems[activeSlideIndex].uuid}`
			);
		}
	}, [activeSlideIndex, albumItems]);

	const filesArray = useMemo(
		() => albumItems?.map((item) => item.file),
		[albumItems]
	);

	const handleDownload = () => {
		const downloadable = filesArray[activeSlideIndex];
		FileSaver.saveAs(`${downloadable.url}`, `${downloadable.originalName}`);
	};

	if (isLoading) return <LoadingPage />;

	return (
		<>
			<div className={styles.album_header}>
				{getAlbumIdApi.loading ? (
					<List />
				) : (
					<>
						<div className={styles.title_wrapper}>
							<div>
								<div className={styles.title}>
									{title}{" "}
									<div className={styles.visibility_wrapper}>
										{getVisibility(visibility)}
									</div>
								</div>
								<span className={styles.album_owner}>
									by{" "}
									<GaawkLink
										to={routes.profile(post?.owner.url)}
										customStyle={styles.owner_url}
									>
										{post?.owner.name}
									</GaawkLink>
								</span>
							</div>
							{!gaawkAlbum && isOwner && (
								<EditButton
									onClick={() =>
										navigate(
											`/profile/album/${uuid}/edit`,
											{
												state: album,
											}
										)
									}
								/>
							)}
						</div>

						<div className={styles.counters_wrapper}>
							<div className={styles.counter_item}>
								<img src={imgIcon} alt="counter" />
								{imagesCount}
							</div>
							<div className={styles.counter_item}>
								<img src={videoIcon} alt="video counter" />
								{videosCount}
							</div>
							<div className={styles.counter_item}>
								<img src={pdfIcon} alt="pdf counter" />
								{pdfsCount}
							</div>
						</div>

						<div className={styles.location_date_wrapper}>
							{location && (
								<div className={styles.location_wrapper}>
									<img src={locationIcon} alt="location" />
									{`${location.title}${
										location.countryDTO
											? `, ${location.countryDTO.name}`
											: ""
									}`}
								</div>
							)}

							{date > 0 && (
								<div className={styles.date_wrapper}>
									<img src={calendarIcon} alt="date" />
									{format(new Date(date), "dd/MM/yyyy")}
								</div>
							)}
						</div>

						<div className={styles.description_wrapper}>
							{description}
						</div>

						{(contributes?.length > 0 || tagged?.length > 0) && (
							<div className={styles.people_wrapper}>
								{contributes?.length > 0 && (
									<div>
										<span>Contributors</span>
										<div className={styles.tags_container}>
											{contributorsList}
											{contributes?.length - tagsCount >
												0 && (
												<div
													className={
														styles.people_modal
													}
													onClick={() =>
														setShowContributorsModal(
															true
														)
													}
												>
													+
													{contributes?.length -
														tagsCount}
												</div>
											)}
										</div>
									</div>
								)}

								{tagged?.length > 0 && (
									<div>
										<span>Tagged in</span>
										<div className={styles.tags_container}>
											{taggedList}
											{tagged?.length - tagsCount > 0 && (
												<div
													className={
														styles.people_modal
													}
													onClick={() =>
														setShowTaggedModal(true)
													}
												>
													+
													{tagged?.length - tagsCount}
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						)}
					</>
				)}
			</div>

			<div className={styles.album_wrapper}>
				{!isFetching && albumItems.length === 0 && isOwner && (
					<div className={styles.info_wrapper}>
						Your album doesn’t have any items to display.
						<br />
						You can add images, videos and PDF files to your albums
						by tapping the “Add files” button below.
					</div>
				)}

				{(isOwner || isContributor) && !isSystemAlbum && (
					<div className={styles.button_container}>
						<CtaButton
							text={"+ ADD FILES"}
							onClick={() =>
								navigate(`/profile/album/${albumId}/uploader`)
							}
							className={styles.add_button}
						/>
					</div>
				)}
				{!isFetching && albumItems.length === 0 && (
					<div className={styles.emptyIcon_wrapper}>
						<img
							src={emptyFolderIcon}
							className={styles.emptyIcon}
							alt="empty folder"
						/>
					</div>
				)}

				<GalleryContainer>
					{albumItemsList}
					{isFetching && loadingItems}
				</GalleryContainer>

				<LoadMoreButton
					hasMore={hasNextPage} //&& !getApplicantsCountApi.loading
					loading={isFetching}
					onLoad={fetchNextPage}
					showSpinner={false}
				/>
			</div>

			{post && (
				<PostItem
					sm={true}
					post={post}
					visibleByDefault={true}
					onPostUpdate={handleUpdateAlbumPost}
					onShare={handleShare}
					showFooterOnly={true}
				/>
			)}

			<CreatePost
				ref={postRef}
				name={profileName}
				profileImg={profile.profileImage}
				type={profile.type}
				onCreate={() => toast.success("Post successfully shared!")}
			/>

			<PostViewer
				show={showSlider}
				onClose={handleCloseSlider}
				onShare={(post) => handleShare(post)}
				post={albumItems?.[activeSlideIndex]}
				files={filesArray}
				postName={album?.title}
				onLoadMore={() => hasNextPage && fetchNextPage()}
				initialSlideIndex={initialSlideIndex}
				onActiveSlide={(index) => {
					setActiveSlideIndex(index);
				}}
				isLoading={isFetching}
				onPostUpdate={(updatedPostReaction) =>
					updatePostReaction(
						galleryKeys.albumItems(albumId),
						updatedPostReaction
					)
				}
				isOwner={isOwner}
				onEdit={(post) => handleShowEditModal(post)}
				onDelete={(post) => handleDelete(post)}
				onDownload={handleDownload}
			/>

			{contributes?.length - tagsCount > 0 && (
				<TaggedModal
					show={showContributorsModal}
					title={"Contributors"}
					handleClose={() => setShowContributorsModal(false)}
					profiles={contributes}
				/>
			)}

			{tagged?.length - tagsCount > 0 && (
				<TaggedModal
					show={showTaggedModal}
					title={"Tagged in"}
					handleClose={() => setShowTaggedModal(false)}
					profiles={tagged}
				/>
			)}

			<WarningModal
				show={showWarning}
				modalOnTop={true}
				headerText="Are you sure you want to delete this item?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarning(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleSubmitDelete}
			/>

			<EditMediaModal
				show={showEditModal}
				onClose={() => setShowEditModal(false)}
				onReset={() => setEditItem("")}
				item={editItem}
				onConfirm={handleEdit}
				onToggle={(bool) => setShowEditModal(bool)}
				isEditing={true}
			/>
		</>
	);
};

export default Album;
