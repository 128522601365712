import styles from "./PeopleFilter.module.scss";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import { forwardRef, useImperativeHandle, useRef } from "react";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { jobsInitialFilters } from "../initialFilters";
import useSearchFilters from "../../../hooks/useSearchFilters";
import jobApi from "api/job";
import {
	frequencyTypes,
	jobTypeOptions,
	postedDateTypes,
	jobTypes,
	workLocation,
	genderTypes,
} from "components/Utils/General";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import useCurrencies from "hooks/useCurrencies";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import { defaultFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import LongTermFilters from "./SubFilters/LongTermFilters";
import FreelanceFilters from "./SubFilters/FreelanceFilters";
import CastingFilters from "./SubFilters/CastingFilters";
import usePaginatedSearchFilters from "hooks/usePaginatedSearchFilters";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useFilterAndSort from "./useFilterAndSort";

const JobsFilter = forwardRef(
	(
		{
			inputValue,
			onChange,
			onResetFilters,
			selectedFilters: selectedDynamicFilters,
			userCoordinates,
		},
		ref
	) => {
		const {
			initialFilters,
			data,
			isFetching,
			hasFilters,
			selectedFilters,
			setSelectedFilters,
		} = useSearchFilters(
			jobsInitialFilters,
			{ locationId: userCoordinates.id },
			"jobsFilters",
			inputValue,
			jobApi.jobsFilters,
			selectedDynamicFilters,
			onChange,
			true,
			true
		);

		const {
			hasNextPage,
			fetchNextPage,
			isFetched,
			refetch,
			data: dynamicSkills,
		} = usePaginatedSearchFilters(
			jobsInitialFilters,
			{ locationId: userCoordinates.id },
			"jobSkillsFilters",
			inputValue,
			jobApi.getJobSkills,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const {
			hasNextPage: hasNextPageLanguages,
			fetchNextPage: fetchNextPageLanguages,
			isFetched: isFetchedLanguages,
			refetch: refetchLanguages,
			data: dynamicLanguages,
		} = usePaginatedSearchFilters(
			jobsInitialFilters,
			{ locationId: userCoordinates.id },
			"jobLanguagesFilters",
			inputValue,
			jobApi.getJobLanguages,
			selectedDynamicFilters,
			onChange,
			false // disabling request first time
		);

		const skillRef = useRef();
		const languageRef = useRef();

		const { data: currencies = [], isLoading: isLoadingCurrencies } =
			useCurrencies();

		const { languages: languagesList = [], skills: skillsList = [] } =
			data || {};

		const {
			jobType = [{ label: "All", value: jobTypes.all }],
			time = [{ label: "Any time", value: "NONE" }],
			compensation = "",
			currencyId = "",
			frequency = "",
			jobRequest: { longTermJobTypeId, workingLocation, experience },
			freelanceRequest: {
				workingLocation: freelanceWorkingLocation,
				experience: freelanceExp,
				showBasedOnCalendar,
				startDate,
				endDate,
			},
			castingCallRequest: {
				gender,
				minAge,
				maxAge,
				ethnicityObject = null,
			},
			skillIds = [],
			languageIds = [],
		} = selectedFilters || {};

		const filteredSkills = useFilterAndSort(
			skillIds,
			dynamicSkills,
			skillsList,
			isFetched
		);

		const filteredLanguages = useFilterAndSort(
			languageIds,
			dynamicLanguages,
			languagesList,
			isFetchedLanguages,
			"code"
		);

		const handleClearFields = () => {
			if (hasFilters) {
				setSelectedFilters(initialFilters);
				onResetFilters(initialFilters);
			}
		};

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "skillIds":
						skillRef.current?.removeItem(tag);
						break;
					case "languageIds":
						languageRef.current?.removeItem(tag);
						break;
					default:
						break;
				}
			},
		}));

		if (isFetching)
			return <LoadingSpinner customStyle={styles.loading_spinner} />;

		return (
			<>
				<div className={styles.container}>
					<MultiCheckbox
						title={"Type"}
						type="radio"
						options={jobTypeOptions}
						onSelect={(type) => {
							if (jobType !== type[0].value) {
								setSelectedFilters((prevState) => ({
									...prevState,
									skillIds: [],
									languageIds: [],
								}));
							}
							setSelectedFilters((prevState) => ({
								...prevState,
								jobType: type[0].value,
							}));
						}}
						selected={[
							jobTypeOptions.find(
								(item) => item.value === jobType
							),
						]}
						perRow="2, 150px"
						customStyle={styles.multicheckbox_container}
					/>

					<MultiCheckbox
						title={"Date Posted"}
						type="radio"
						options={postedDateTypes}
						onSelect={(timeType) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								time: timeType[0].value,
							}));
						}}
						selected={[
							postedDateTypes.find((item) => item.value === time),
						]}
						perRow="2, 150px"
						customStyle={styles.multicheckbox_container}
					/>
				</div>

				{/* //~ =============== FILTERS BASED ON JOB TYPE ==================== */}

				{jobType === jobTypes.long_term && (
					<LongTermFilters
						onEmployementSelect={(employementType) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								jobRequest: {
									...prevState.jobRequest,
									longTermJobTypeId: employementType,
								},
							}));
						}}
						employement={longTermJobTypeId}
						onWorkLocation={(workLocation) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								jobRequest: {
									...prevState.jobRequest,
									workingLocation: workLocation[0].value,
								},
							}));
						}}
						workLocation={[
							workLocation.find(
								(item) => item.value === workingLocation
							),
						]}
						onExpLvl={({ target }) => {
							const isDigit = /\d/g.test(target.value);

							setSelectedFilters((prevState) => ({
								...prevState,
								jobRequest: {
									...prevState.jobRequest,
									experience: isDigit
										? parseInt(target.value)
										: "",
								},
							}));
						}}
						expLvl={experience}
					/>
				)}
				{jobType === jobTypes.freelance && (
					<FreelanceFilters
						onWorkLocation={(workLocation) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								freelanceRequest: {
									...prevState.freelanceRequest,
									workingLocation: workLocation[0].value,
								},
							}));
						}}
						workLocation={[
							workLocation.find(
								(item) =>
									item.value === freelanceWorkingLocation
							),
						]}
						onExpLvl={({ target }) => {
							const isDigit = /\d/g.test(target.value);

							setSelectedFilters((prevState) => ({
								...prevState,
								freelanceRequest: {
									...prevState.freelanceRequest,
									experience: isDigit
										? parseInt(target.value)
										: "",
								},
							}));
						}}
						expLvl={freelanceExp}
						onCalendarAvailability={() =>
							setSelectedFilters((prevState) => ({
								...prevState,
								freelanceRequest: {
									...prevState.freelanceRequest,
									showBasedOnCalendar:
										!prevState.freelanceRequest
											.showBasedOnCalendar,
								},
							}))
						}
						calendarAvailability={showBasedOnCalendar}
						onStartDate={(startDate) =>
							setSelectedFilters((prevState) => ({
								...prevState,
								freelanceRequest: {
									...prevState.freelanceRequest,
									startDate: startDate?.getTime(),
								},
							}))
						}
						onEndDate={(endDate) =>
							setSelectedFilters((prevState) => ({
								...prevState,
								freelanceRequest: {
									...prevState.freelanceRequest,
									endDate: endDate?.getTime(),
								},
							}))
						}
						startDate={startDate}
						endDate={endDate}
					/>
				)}
				{jobType === jobTypes.casting && (
					<CastingFilters
						ref={languageRef}
						onGenderChange={(gender) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								castingCallRequest: {
									...prevState.castingCallRequest,
									gender: gender[0].value,
								},
							}));
						}}
						gender={[
							genderTypes.find((item) => item.value === gender),
						]}
						onMinAge={({ target }) => {
							// const value = target.value.replace(/\D/g, "");
							const isDigit = /\d/g.test(target.value);

							setSelectedFilters((prevState) => ({
								...prevState,
								castingCallRequest: {
									...prevState.castingCallRequest,
									minAge: isDigit
										? parseInt(target.value)
										: "",
								},
							}));
						}}
						onMaxAge={({ target }) => {
							// const value = target.value.replace(/\D/g, "");
							const isDigit = /\d/g.test(target.value);

							setSelectedFilters((prevState) => ({
								...prevState,
								castingCallRequest: {
									...prevState.castingCallRequest,
									maxAge: isDigit
										? parseInt(target.value)
										: "",
								},
							}));
						}}
						minAge={minAge}
						maxAge={maxAge}
						onEthnicity={(ethnicity) =>
							setSelectedFilters((prevState) => ({
								...prevState,
								castingCallRequest: {
									...prevState.castingCallRequest,
									ethnicityId: ethnicity
										? ethnicity.value
										: "",
									ethnicityObject: ethnicity,
								},
							}))
						}
						ethnicity={ethnicityObject}
						languagesList={
							// languagesList
							filteredLanguages
						}
						onLanguage={(languages) => {
							setSelectedFilters((prevState) => ({
								...prevState,
								languageIds: languages,
							}));
						}}
						languages={languageIds}
						isFetchedLanguages={isFetchedLanguages}
						hasNextPageLanguage={hasNextPageLanguages}
						onRefetch={refetchLanguages}
						onFetchNextPage={fetchNextPageLanguages}
						baseLanguagesCount={languagesList.length}
					/>
				)}

				{/* //~ ============================================================= */}

				<div
					className={`${styles.container} ${
						jobType === jobTypes.all
							? styles.no_top_padding
							: undefined
					}`}
				>
					{skillsList?.length > 0 && (
						<MultiCheckbox
							ref={skillRef}
							title={"Skills"}
							options={filteredSkills.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(skills) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									skillIds: skills,
								}));
							}}
							selected={skillIds}
							perRow="2, 150px"
							// customStyle={styles.multicheckbox_container}
						/>
					)}

					{((!isFetched && skillsList.length === 4) ||
						(isFetched && hasNextPage)) && (
						<SeeMore
							onClick={!isFetched ? refetch : fetchNextPage}
							className={styles.margin_top}
						/>
					)}

					<InputWrapper
						label="Minimum compensation"
						component={
							<TextInput
								value={compensation}
								placeholder="Enter amount"
								onChange={({ target }) => {
									const isDigit = /\d/g.test(target.value);

									setSelectedFilters((prevState) => ({
										...prevState,
										compensation: isDigit
											? parseInt(target.value)
											: "",
									}));
								}}
							/>
						}
					/>

					<div className={styles.inline_input_wrapper}>
						<div className={styles.item}>
							<label>Currency</label>
							<CustomSelect
								isLoading={isLoadingCurrencies}
								options={currencies?.map((currency) =>
									defaultFormatter(currency)
								)}
								placeholder="Select"
								height="35px"
								isClearable={true}
								onChange={(currency) =>
									setSelectedFilters((prevState) => ({
										...prevState,
										currencyId: currency
											? currency.value
											: "",
									}))
								}
								value={currencies
									?.map((currency) =>
										defaultFormatter(currency)
									)
									.find((item) => item.value === currencyId)}
							/>
						</div>
						<div className={styles.item}>
							<label>Frequency</label>
							<CustomSelect
								options={frequencyTypes}
								placeholder="Select"
								height="35px"
								isClearable={true}
								onChange={(freq) =>
									setSelectedFilters((prevState) => ({
										...prevState,
										frequency: freq ? freq.value : "",
									}))
								}
								value={frequencyTypes.find(
									(item) => item.value === frequency
								)}
							/>
						</div>
					</div>

					<div className={styles.button_container}>
						<PrimaryButton
							type="button"
							text={"clear filters"}
							className={`${styles.btn} ${styles.clear}`}
							onClick={handleClearFields}
						/>
					</div>
				</div>
			</>
		);
	}
);

export default JobsFilter;
