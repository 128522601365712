import React from "react";
import GaawkModal from "./GaawkModal";
import styles from "./WarningModal.module.css";

const WarningModal = ({
    show,
    headerText,
    warningText,
    submitButtonText,
    onSubmitButtonClicked,
    cancelButtonText,
    onCancelButtonClicked,
    modalOnTop = false,
}) => {
    return (
        <GaawkModal
            show={show}
            showHeader={false}
            escKeyboard={false}
            size={"sm"}
            modalOnTop={modalOnTop}
            children={
                <div className={styles.delete_confirmation_modal}>
                    <p>{headerText}</p>
                    <p className={styles.warning}>{warningText}</p>

                    <div className={styles.buttons}>
                        <button
                            className={styles.danger}
                            onClick={onSubmitButtonClicked}
                        >
                            {submitButtonText}
                        </button>

                        <button onClick={onCancelButtonClicked}>
                            {cancelButtonText}
                        </button>
                    </div>
                </div>
            }
        />
    );
};

export default WarningModal;
