import { forwardRef } from "react";
import styles from "./ProfileTabContainer.module.css";

const ProfileTabContainer = forwardRef(
	({ children, marginTop = true, customStyle, disabled = false }, ref) => {
		return (
			<div
				disabled={disabled}
				ref={ref}
				className={`${styles.container} ${
					!marginTop ? styles.no_margin : undefined
				}
                 ${customStyle}`}
			>
				{children}
			</div>
		);
	}
);

export default ProfileTabContainer;
