import client from "./client";

const endpoint = "/location";

const getLocation = (lat, lng) =>
    client.get(endpoint + `/byCoords?latitude=${lat}&longitude=${lng}`);

const searchCities = (
    page,
    size,
    lat = "",
    lng = "",
    q = "",
    countryCode = ""
) =>
    client.get(
        endpoint +
            `/cities?page=${page}&size=${size}&lat=${lat}&lng=${lng}&q=${q}&countryCode=${countryCode}`
    );

const searchCountries = (page, size, lat = "", lng = "", q = "") =>
    client.get(
        endpoint +
            `/countries?page=${page}&size=${size}&lat=${lat}&lng=${lng}&q=${q}`
    );
const locationSearch = (page, size, lat = "", lng = "", q = "") =>
    client.get(
        endpoint +
            `/search?page=${page}&size=${size}&lat=${lat}&lng=${lng}&q=${q}`
    );

// eslint-disable-next-line import/no-anonymous-default-export
export default { getLocation, searchCities, searchCountries, locationSearch };
