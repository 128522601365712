import { useQuery } from "@tanstack/react-query";
import profileApi from "../api/profile";
import useApi from "./useApi";

const useProfileById = (userId, type) => {
	const getUserById = useApi(profileApi.getUserProfile, true, true);
	const getCompanyById = useApi(profileApi.getCompanyProfile, true, true);

	const fetchUser = async ({ queryKey }) => {
		const [_, id] = queryKey;

		const response =
			type === "USER"
				? await getUserById.request(id)
				: await getCompanyById.request(id);
		return response.data;
	};

	const user = useQuery({
		queryKey: ["user", userId],
		queryFn: fetchUser,
		enabled: false,
		cacheTime: 0,
	});

	return user;
};

export default useProfileById;
