import { forwardRef, useMemo } from "react";
import LoadingSpinner from "../SubComs/LoadingSpinner/LoadingSpinner";
import styles from "./NotificationsPopover.module.scss";
import NotificationItem from "./NotificationItem";
import GaawkLink from "../SubComs/GaawkLink/GaawkLink";
import NoResults from "../SubComs/NoResults/NoResults";
// import emptyIcon from "images/empty-box.svg";

const NotificationsPopover = forwardRef(
	({ isLoading, data, onDelete, onRead, onClose, onAllRead }, ref) => {
		const notificationsList = useMemo(
			() =>
				data?.pages?.map((page) =>
					page?.map((item, i) => (
						<div
							ref={page.length === i + 1 ? ref : null}
							key={item.uuid}
						>
							<NotificationItem
								item={item}
								onRead={() => onRead(item.uuid)}
								onDelete={() => onDelete(item.uuid)}
								onClose={onClose}
							/>
						</div>
					))
				),
			[data]
		);

		return (
			<div className={`popover_container ${styles.popover_container}`}>
				<div className={styles.header_wrapper}>
					<h3>Notifications</h3>
					{data?.pages[0].length > 0 && (
						<button type="button" onClick={() => onAllRead()}>
							Mark all as read
						</button>
					)}
				</div>

				<div className={styles.notifications_wrapper}>
					{data?.pages[0].length > 0 && notificationsList}
					<LoadingSpinner visible={isLoading} />
					<NoResults
						// image={emptyIcon}
						// imageStyle={styles.empty_icon}
						// customStyle={styles.no_results}
						text={"You don't have any notifications"}
						visible={data?.pages[0].length === 0}
					/>
				</div>

				{data?.pages[0].length > 0 && (
					<GaawkLink className={styles.see_all} to="/notifications">
						See all notifications
					</GaawkLink>
				)}
			</div>
		);
	}
);

export default NotificationsPopover;
