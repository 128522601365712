import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import useApi from "./useApi";
import { useInView } from "react-intersection-observer";
import jobApi from "api/job";
import useMutate from "./useMutate";
import { jobTypes } from "components/Utils/General";
import NewJobCard from "components/Jobs/JobsComponents/NewJobCard/NewJobCard";

const useSavedAppliedJobs = (endpoint, queryKey, itemsPerPage = 20) => {
	const queryClient = useQueryClient();

	const getJobsApi = useApi(endpoint, true, true);

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const [jobType, setJobType] = useState("all");

	const handleTabChange = (tab) => {
		setJobType(tab);
	};

	const fetchSavedJobs = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, ___, jobType] = queryKey;

		const response = await getJobsApi.request(
			pageParam,
			itemsPerPage,
			jobType
		);
		return response.data;
	};

	const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
		queryKey: queryKey(jobTypes[jobType]),
		queryFn: fetchSavedJobs,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, () =>
		queryClient.invalidateQueries(queryKey(jobTypes[jobType]))
	);

	const jobsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page?.map((job, i) => {
					return (
						<div
							ref={page.length === i + 1 ? viewRef : null}
							key={job.uuid}
						>
							<NewJobCard
								data={job}
								onToggleSave={() =>
									toggleSave({
										id: job.uuid,
										status: !job.savedJob,
									})
								}
							/>
						</div>
					);
				})
			),
		[data?.pages]
	);

	return { data, isFetching, jobType, handleTabChange, jobsList };
};

export default useSavedAppliedJobs;
