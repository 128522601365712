import styles from "./FAQItem.module.scss";

const FAQItem = ({item, selected = false, onSelect}) => {
    return <div onClick={() => onSelect(selected ? undefined : item.id)} className={styles.container}>
        <span className={styles.title}>{item.title}</span>
        <span onClick={() => onSelect(selected ? undefined : item.id)} className={styles.close}>
            <img className={selected ? styles.closeIcon : styles.openIcon}
                 src={selected ? require("images/close-icon-black-20-x-20.svg").default : require("images/plus-icon-black-15-x-15.svg").default}
                 alt={"close"}/>
        </span>
        {selected &&
            <div className={styles.descriptionWrapper}>
                <span className={styles.description}>
                    {item.description}
                </span>
            </div>
        }
    </div>;
};

export default FAQItem;
