import styles from "./JobInfoItem.module.scss";

const JobInfoItem = ({ title, content }) => {
	return (
		<div className={styles.container}>
			<h3>{title}</h3>
			<div className={styles.content_wrapper}>{content}</div>
		</div>
	);
};

export default JobInfoItem;
