import {
	forwardRef,
	// useEffect,
	// useImperativeHandle,
	// useRef,
	// useState,
} from "react";
import styles from "./GalleryItem.module.css";

// import {
// 	getFileIcon,
// 	getVideoFrame
// } from "../../Utils/General";

import LazyImage from "../../Utils/LazyImage/LazyImage";
// import playIcon from "images/play-icon-blue.svg";
import { ReactComponent as PlayIcon } from "images/play-button.svg";
// import optionIcon from "images/options-hor-dark-grey.svg";
// import whiteOptionIcon from "images/three-dots-white-5-x-20.svg";
// import Popover from "../../Utils/Popover/Popover";
import FileSaver from "file-saver";

// import editIcon from "images/edit-black.svg";
// import shareIcon from "images/share-option.svg";
// import downloadIcon from "images/download-black.svg";
// import deleteIcon from "images/delete-black.svg";
import PostActions from "components/Home/PostActions";
import crossIcon from "images/icon-exit.svg";
// import { videoToImage } from "components/Utils/videoToImage";

// import profileIcon from "images/contact-icon.svg";
// import coverIcon from "images/cover-icon.svg";
// import albumCoverIcon from "images/album-cover.svg";

const GalleryItem = forwardRef(
	(
		{
			onClick,
			onDelete,
			onEdit,
			onShare,
			onCopy,
			onReport,
			// onProfile,
			// onProfileCover,
			// onAlbumCover,
			isOwner,
			item,
			deletable = false,
			custom_style,
			showPopover = false,
			option_white = false,
			isPublic = false,
		},
		ref
	) => {
		const {
			file: { url, thumbnail, mediaType, originalName, fileTypeDTO },
		} = item;

		return (
			<div className={styles.outer_wrapper}>
				<button
					className={styles.container}
					ref={ref}
					onClick={onClick}
				>
					<div
						className={`${styles.relative} ${
							styles.base_container
						} ${
							custom_style ? custom_style : styles.item_container
						} `}
					>
						{mediaType === "VIDEO" && (
							<PlayIcon className={styles.play_icon} />
						)}

						<LazyImage
							image={
								<img
									src={
										mediaType === "IMAGE"
											? url
											: mediaType === "VIDEO"
											? thumbnail
											: fileTypeDTO.url
									}
									alt={originalName}
								/>
							}
							inlineStyle={
								mediaType !== "IMAGE" && mediaType !== "VIDEO"
									? styles.file_icon
									: undefined
							}
							height={
								mediaType !== "IMAGE" && mediaType !== "VIDEO"
									? "auto"
									: undefined
							}
						/>

						{mediaType === "PDF" && (
							<div className={styles.file_name}>
								{originalName}
							</div>
						)}
					</div>
				</button>

				{showPopover && (
					<div className={styles.option_wrapper}>
						<PostActions
							whiteButton={option_white}
							isOwner={isOwner}
							isPublic={isPublic}
							onEdit={onEdit}
							onDelete={onDelete}
							onShare={onShare}
							onCopy={onCopy}
							onReport={onReport}
							onDownload={() =>
								FileSaver.saveAs(`${url}`, `${originalName}`)
							}
						/>
					</div>
				)}

				{deletable && (
					<div className={styles.option_wrapper}>
						<button
							onClick={(e) => {
								e.stopPropagation();
								onDelete();
							}}
						>
							<img src={crossIcon} alt="Delete" />
						</button>
					</div>
				)}
			</div>
		);
	}
);

export default GalleryItem;
