export const projectKeys = {
	all: ["projects"],
	lists: (companyId, status) => [
		...projectKeys.all,
		"listProjects",
		companyId,
		status,
	],
	details: () => [...projectKeys.all, "details"],
	detail: (id) => [...projectKeys.details(), id],
	// medias: (uuid) => [...specialProfileKeys.all, "medias", uuid],
	// getTalents: (uuid, confirmed = true, type = "N_A") => [
	// 	...specialProfileKeys.all,
	// 	"talents",
	// 	uuid,
	// 	confirmed,
	// 	type,
	// ],
};
