import client from "./client";

const endpoint = "/frame";

const searchFrames = (page, size, q, type) =>
	client.get(
		endpoint + `/search?page=${page}&size=${size}&q=${q}&type=${type}`
	);

const getSuggestedFrames = (page, size, type) =>
	client.get(endpoint + `/suggested?page=${page}&size=${size}&type=${type}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	searchFrames,
	getSuggestedFrames,
};
