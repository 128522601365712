import { getFileIcon } from "../../Utils/General";
import LazyImage from "../../Utils/LazyImage/LazyImage";
import styles from "./GalleryFileItem.module.css";

// import removeIcon from "images/icon-exit-white.svg";
import { ReactComponent as CrossIcon } from "images/cross-icon.svg";
import EditButton from "components/Utils/Button/EditButton";
// import { useCallback, useEffect, useState } from "react";
import useGetFirstFrame from "hooks/useGetFirstFrame";
import { ReactComponent as PlayIcon } from "images/play-button.svg";

const GalleryFileItem = ({ file, onRemove, onEdit, className }) => {
	const { multipartFile } = file;
	const { icon, isPhoto } = getFileIcon(multipartFile, multipartFile.name);

	const videoThumb = useGetFirstFrame(
		file.multipartFile,
		file.multipartFile.name,
		file.type === "VIDEO"
	);

	return (
		<div className={`${styles.container} ${className}`}>
			<div
				className={`${styles.image_container} ${
					isPhoto || file.type === "VIDEO" ? styles.photo : undefined
				}`}
			>
				<button className={styles.remove_button} onClick={onRemove}>
					<CrossIcon width={12} />
				</button>

				{/* <PrimaryButton
					className={styles.edit_button}
					onClick={onEdit}
					text={
						<div className={styles.edit_button_container}>
							<img src={editIcon} alt="Edit" /> Edit
						</div>
					}
				/> */}
				{onEdit && (
					<EditButton
						onClick={onEdit}
						className={styles.edit_button}
					/>
				)}

				{isPhoto ? (
					<LazyImage
						image={<img src={icon} alt={multipartFile.name} />}
					/>
				) : file.type === "VIDEO" ? (
					<div className={styles.video_container}>
						<PlayIcon className={styles.icon} />
						<LazyImage
							image={
								<img
									src={videoThumb}
									alt={multipartFile.name}
								/>
							}
						/>
					</div>
				) : (
					<>
						<div className={styles.icon_container}>
							<img src={icon} alt={multipartFile.name} />
						</div>
						<div className={styles.filename_container}>
							{multipartFile.name}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default GalleryFileItem;
