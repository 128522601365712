import client from "./client";

const endpoint = "/voice";

const getVoiceCharacteristics = () => client.get(endpoint);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getVoiceCharacteristics,
};
