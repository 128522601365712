import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import WarningModal from "../../../Utils/GaawkModal/WarningModal";

import { deleteEducation } from "../../../../store/slices/user";
import EducationItem from "./EducationItem";

import styles from "./Education.module.css";
import CtaButton from "components/Utils/Button/CtaButton";

const Education = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const educations = useSelector(
		(state) => state.user.profileInfo.educations
	);

	const [educationId, setEducationId] = useState("");
	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const [formattedEducation, setFormattedEducation] = useState([]);

	const handleDelete = (educationId) => {
		setEducationId(educationId);
		setWarningDeleteModal(true);
	};

	const handleConfirmDelete = () => {
		dispatch(deleteEducation(educationId));
		setWarningDeleteModal(false);
	};

	useEffect(() => {
		setFormattedEducation(
			educations.map((item) => (
				<EducationItem
					key={item.uuid}
					item={item}
					onEditClicked={() =>
						navigate("/profile/education/edit", {
							state: item,
						})
					}
					onDelete={() => handleDelete(item.uuid)}
				/>
			))
		);
	}, [navigate, educations]);

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW EDUCATION"
					onClick={() => navigate("/profile/education/add")}
				/>
			</div>

			{educations.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Educations you have added</h4>

					<div className={styles.certificates_section}>
						{formattedEducation}
					</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Education;
