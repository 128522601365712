import Select from "react-select";
import { Controller } from "react-hook-form";
import { cssValue } from "components/Utils/General";

const CustomSelect = ({
	options,
	// onChange,
	// value,
	defaultValue,
	id,
	isSearchable = true,
	className,
	fontSize = "14px",
	placeholder,
	onInputChange,
	width = "100% !important",
	height = "35px !important",
	menuIsOpen,
	components,
	isClearable = false,
	cursor = "pointer",
	maxMenuHeight,
	isLoading = false,
	control,
	name,
	error,
	onChange,
	value,
	onBlur,
	disabled = false,
	onMenuScrollToBottom,
}) => {
	return control ? (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<Select
					options={options}
					components={components}
					isSearchable={isSearchable}
					// getOptionLabel={(option) => option}
					// getOptionValue={(option) => option}
					id={id}
					name={id}
					width={width}
					height={height}
					fontSize={fontSize}
					placeholder={placeholder}
					className={className}
					// onChange={onChange}
					// value={value}
					// onBlur={onBlur}
					{...field}
					onBlur={(e) => {
						onBlur && onBlur(e);
						field.onBlur(e);
					}}
					onChange={(e) => {
						onChange && onChange(e);
						field.onChange(e);
					}}
					defaultValue={defaultValue}
					styles={customStyles}
					menuIsOpen={menuIsOpen}
					cursor={cursor}
					onInputChange={onInputChange}
					isClearable={isClearable}
					maxMenuHeight={maxMenuHeight}
					isLoading={isLoading}
					error={error}
					isDisabled={disabled}
					onMenuScrollToBottom={onMenuScrollToBottom}
					noOptionsMessage={({ inputValue }) => "No results found"}
				/>
			)}
		/>
	) : (
		<Select
			options={options}
			components={components}
			isSearchable={isSearchable}
			// getOptionLabel={(option) => option}
			// getOptionValue={(option) => option}
			id={id}
			name={id}
			width={width}
			height={height}
			fontSize={fontSize}
			placeholder={placeholder}
			className={className}
			onChange={onChange}
			value={value}
			defaultValue={defaultValue}
			styles={customStyles}
			menuIsOpen={menuIsOpen}
			cursor={cursor}
			onInputChange={onInputChange}
			isClearable={isClearable}
			maxMenuHeight={maxMenuHeight}
			isLoading={isLoading}
			isDisabled={disabled}
			onBlur={onBlur}
			error={error}
			onMenuScrollToBottom={onMenuScrollToBottom}
			noOptionsMessage={({ inputValue }) => "No results found"}
		/>
	);
};

const customStyles = {
	container: (provided, state) => ({
		...provided,
		backgroundColor: "white",
		padding: "0 !important",
		borderRadius: "5px",
		height: state.selectProps.height,
	}),
	option: (_, { isFocused, isSelected, selectProps }) => ({
		padding: "7px",
		fontSize: selectProps.fontSize,
		backgroundColor: isSelected
			? "#6cc5d1"
			: isFocused
			? "#d4f3f7"
			: "white",
		cursor: "pointer",
		color: "#000000",
	}),
	control: (provided, { isFocused, isSelected, selectProps }) => ({
		...provided,
		borderRadius: "5px",
		outline:
			(isFocused || isSelected) && !selectProps.error
				? "2px solid #6cc5d1 !important"
				: selectProps.error
				? "2px solid red !important"
				: "1px solid #c4ccd3 !important",
		outlineOffset: "0px",
		borderWidth: "0px",
		boxShadow: "none",
		transition: "none !important",
		// cursor: "pointer !important",
		cursor: selectProps.cursor ? selectProps.cursor : "pointer",
		width: selectProps.width,
		height: selectProps.height,
		minHeight: selectProps.height,
		boxSizing: "border-box !important",

		// justifyContent: "center",
		// alignContent: "center",
		// alignItems: "center",
		// padding: isFocused || isSelected ? "0 !important" : "1 !important",
	}),
	singleValue: (provided, { selectProps }) => ({
		...provided,
		color: "black",
		fontSize: selectProps.fontSize,
	}),

	valueContainer: (provided, { selectProps }) => ({
		...provided,
		fontSize: selectProps.fontSize,
		height: "100%",
		// top: "70%",
		// justifyContent: "center",
		padding: "7px",
		alignContent: "center",
		alignItems: "center",
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		marginRight: "0 !important",
		height: "100%",
	}),
	menu: (provided) => ({
		...provided,
		zIndex: "3",
	}),
	placeholder: (provided) => ({
		...provided,
		color: cssValue("--gray70"),
	}),
};

export default CustomSelect;
