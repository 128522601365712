/* eslint-disable jsx-a11y/img-redundant-alt */
import ProfilePic from "components/Home/ProfilePic";
import routes from "components/Routing/routing-keys";
import { cssValue, pluralize } from "components/Utils/General";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { format } from "date-fns";
import { ReactComponent as ImageIcon } from "images/gallery.svg";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import ProjectActions from "../ProjectActions/ProjectActions";
import styles from "./ProjectCard.module.scss";

const ProjectCard = ({ project, showActions = true }) => {
	const navigate = useNavigate();

	const {
		uuid,
		image,
		active,
		title,
		activeCastingCallsCount,
		activeFreelanceCount,
		activeJobsCount,
		owner: { name: ownerName, profileImage },
		insertionTime,
	} = project;

	const activeJobs = activeJobsCount + activeFreelanceCount;

	// * ===============================

	return (
		<div
			className={styles.container}
			role={"button"}
			onClick={() => navigate(routes.project(uuid))}
		>
			<div className={styles.img_wrapper}>
				{image ? (
					<LazyImage
						image={
							<img
								src={image?.file?.customName}
								alt="project image"
							/>
						}
					/>
				) : (
					<ImageIcon
						width={35}
						height={35}
						fill={cssValue("--gray30")}
					/>
				)}
			</div>

			<div
				className={classNames(styles.project_details, {
					[styles.closed]: !active,
				})}
			>
				<div>
					<div className={styles.project_header}>
						<Tag itemName="Project" customStyle={styles.tag} />
						{showActions && <ProjectActions project={project} />}
					</div>

					<h3 className={styles.title}>{title}</h3>
				</div>

				<div>
					{activeJobs + activeCastingCallsCount > 0 && (
						<p className={styles.job_count_container}>
							{activeJobs > 0 &&
								`${activeJobs} Active ${pluralize(
									activeJobs,
									"Job"
								)}${activeCastingCallsCount > 0 ? ", " : ""}`}
							{activeCastingCallsCount > 0 &&
								`${activeCastingCallsCount} Active ${pluralize(
									activeCastingCallsCount,
									"Casting Call"
								)}`}
						</p>
					)}
					<div className={styles.project_footer}>
						<ProfilePic
							w={24}
							h={24}
							type={"COMPANY"}
							name={ownerName}
							letterSize={14}
							nameClassName={styles.companyName}
							thumbnail={profileImage?.image}
						/>

						<div className={styles.date_wrapper}>
							<p>
								<span>Posted on:</span>{" "}
								{format(new Date(insertionTime), "dd.MM.yyyy")}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectCard;
