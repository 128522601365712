import styles from "./BlockedContainer.module.scss";
import { ReactComponent as BlockIcon } from "images/connection-icons/block.svg";
import { ReactComponent as BlockingIcon } from "images/blocking.svg";

const BlockedContainer = ({ blocked, blocking }) => {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				{blocking && <BlockingIcon />}
				{blocked && <BlockIcon />}
				<h3>
					{blocking && "Blocked User"}
					{blocked && "You're Blocked"}
				</h3>
				<p>
					{blocking && "You need to unblock to see user's content"}
					{blocked && "By this user"}
				</p>
			</div>
		</div>
	);
};

export default BlockedContainer;
