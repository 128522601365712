import styles from "./Checkbox.module.css";

const Checkbox = ({
	id,
	name,
	checked,
	onChange,
	customBackgroundColor,
	className,
	w = "15px",
	h = "15px",
	marginRight = "10px",
}) => {
	return (
		<div
			className={`${styles.container} ${className}`}
			style={{ marginRight: marginRight }}
		>
			<input
				style={{ width: w, height: h }}
				id={id}
				name={name}
				type="checkbox"
				checked={checked || false}
				onChange={onChange}
			/>
			<span
				style={{ width: w, height: h }}
				className={`${styles.checkmark} ${
					customBackgroundColor ? styles.gaawk : styles.black
				}`}
			/>
		</div>
	);
};

export default Checkbox;
