import styles from "./ServicesPage.module.scss";
import InfiniteServices from "../../Profile/ServicesTab/ServicesComponent/InfiniteServices";
import useApi from "../../../hooks/useApi";
import servicesApi from "../../../api/services";

const itemsPerPage = 24;

const ServicesConnections = () => {
	const connectionServicesApi = useApi(
		servicesApi.getConnectionServices,
		true,
		true
	);

	const fetchConnectionServices = async ({ pageParam = 0, signal }) => {
		const response = await connectionServicesApi.request(
			pageParam,
			itemsPerPage
		);
		return response.data;
	};

	return (
		<div className={styles.container}>
			<InfiniteServices
				title={"Services by your connections"}
				queryKey={["infiniteConnectionServices"]}
				queryFn={fetchConnectionServices}
				// errorMessage={"Failed to get connection services"}
				itemsPerPage={itemsPerPage}
			/>
		</div>
	);
};

export default ServicesConnections;
