import { useQuery } from "@tanstack/react-query";
import useApi from "./useApi";
import currencyApi from "api/currency";
import { currencyKeys } from "queryKeys/currency-key-factory";

const useCurrencies = () => {
	const getCurrenciesApi = useApi(currencyApi.getCurrencies, true, true);

	const fetchCurrencies = async () => {
		const response = await getCurrenciesApi.request();
		return response.data;
	};

	const query = useQuery({
		queryKey: currencyKeys.all,
		queryFn: fetchCurrencies,
	});

	return query;
};

export default useCurrencies;
