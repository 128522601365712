import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./CompanyManage.module.css";
import CompanyItem from "../Companies/CompanyItem";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import ProfilePic from "../Home/ProfilePic";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";

import {
	getCompanyPeople,
	addCompanyAdmin,
	addCompanyRep,
} from "../../store/slices/company";
import profileApi from "../../api/profile";
import AdminRepEdit from "./AdminRepEdit";
import ManageSection from "./ManageSection";
import useMutate from "hooks/useMutate";
import useProfileSwitch from "hooks/useProfileSwitch";
import { classNames } from "primereact/utils";

const CompanyManage = ({ state }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// const deleteCompanyApi = useApi(profileApi.deleteCompany);

	const companyProfile = state;

	const companyOwner = companyProfile.owner;

	const {
		admins,
		reps,
		//  employees,
		//   owners
	} = useSelector((state) => state.company);

	useEffect(() => {
		dispatch(getCompanyPeople(companyProfile.uuid, "ADMIN"));
		dispatch(getCompanyPeople(companyProfile.uuid, "REPRESENTATIVE"));
		// dispatch(getCompanyPeople(companyProfile.uuid, "EMPLOYEE"));
		// dispatch(getCompanyPeople(companyProfile.uuid, "OWNER"));
	}, [companyProfile.uuid, dispatch]);

	const adminsIds = admins.map((admin) => admin.uuid);
	const repsIds = reps.map((rep) => rep.uuid);

	const [selectedUser, setSelectedUser] = useState("");
	const [alertModal, setAlertModal] = useState("");

	const handleSelected = (user, mode) => {
		const { label: name, value: uuid, ...userProps } = user;
		const formattedUserObject = { name, uuid, ...userProps };

		setSelectedUser(formattedUserObject);

		if (mode === "admin") {
			if (repsIds.includes(uuid)) {
				setAlertModal(mode);
			} else {
				dispatch(
					addCompanyAdmin(
						companyProfile.uuid,
						uuid,
						formattedUserObject
					)
				);
			}
		} else {
			if (adminsIds.includes(uuid)) {
				setAlertModal(mode);
			} else {
				dispatch(
					addCompanyRep(
						companyProfile.uuid,
						uuid,
						formattedUserObject
					)
				);
			}
		}
	};

	const handleConfirmSwitch = () => {
		dispatch(
			alertModal === "admin"
				? addCompanyAdmin(
						companyProfile.uuid,
						selectedUser.uuid,
						selectedUser
				  )
				: addCompanyRep(
						companyProfile.uuid,
						selectedUser.uuid,
						selectedUser
				  )
		);

		setAlertModal("");
	};

	// !======================== DELETE COMPANY =========================

	const user = useSelector((state) => state.user.profileInfo);

	const { switchToProfile, isProfileSwitchLoading } = useProfileSwitch(false);

	const {
		action: { mutate: deleteCompany },
	} = useMutate(profileApi.deleteCompany, () => {
		//* >> IF YOU ARE LOGGED AS THE COMPANY YOU'RE TRYING TO DELETE
		//* >> THEN SWITCH BACK TO YOUR MAIN PROFILE
		switchToProfile(user.uuid);
	});

	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			if (!isProfileSwitchLoading) navigate("/");
		}
		didMountRef.current = true;
	}, [isProfileSwitchLoading, navigate]);

	const [deleteModal, setDeleteModal] = useState(false);

	// !=================================================================

	return (
		<div className={styles.container}>
			<div className={styles.company_item}>
				<CompanyItem
					item={companyProfile}
					subdata={`${companyProfile.locationDTO.name}, ${companyProfile.locationDTO.countryDTO.name}`}
				/>
			</div>

			{companyProfile.companyUserType === "OWNER" &&
				!companyProfile.agency && (
					<ManageSection
						title="Become Agency"
						text="By becoming an agency you can represent and manage talents, and lorem ipsum valar morgulis, and valor dohaeris…"
						btnText="become agency"
						onClick={() =>
							navigate(
								`/profile/${companyProfile.url}/manage/agency`,
								{
									state: companyProfile,
								}
							)
						}
					/>
				)}

			<AdminRepEdit
				mode="admin"
				people={admins}
				onSelect={(user) => handleSelected(user, "admin")}
				company={companyProfile}
			/>

			<AdminRepEdit
				mode="representative"
				people={reps}
				onSelect={(user) => handleSelected(user, "representative")}
				company={companyProfile}
			/>

			<div
				className={classNames(styles.owner_details_wrapper, {
					[styles.bordered]:
						companyProfile.companyUserType === "OWNER",
				})}
			>
				<h4>Company Owner</h4>

				<ProfilePic
					thumbnail={companyOwner?.profileImage?.image}
					w={36}
					h={36}
					type={companyOwner.type}
					name={companyOwner.name}
					createMode={true}
					url={companyOwner.url}
					subdata={companyOwner.tagLine}
				/>
			</div>

			{companyProfile.companyUserType === "OWNER" && (
				<ManageSection
					title="Company Ownership"
					text="You are the owner of this company on Gaawk. You can transfer the ownership to someone else."
					btnText="transfer ownership"
					onClick={() =>
						navigate(
							`/profile/${companyProfile.url}/manage/transfer`
						)
					}
				/>
			)}

			{companyProfile.companyUserType === "OWNER" && (
				<div className={styles.button_container}>
					<PrimaryButton
						text={"DELETE COMPANY"}
						className={styles.delete_btn}
						onClick={() => setDeleteModal(true)}
					/>
				</div>
			)}

			<GaawkModal
				show={alertModal}
				handleClose={() => setAlertModal("")}
				defaultModal={false}
				showHeader={true}
				title={`Add new ${alertModal}`}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.warning_wrapper}>
							<div className={styles.image_wrapper}>
								<ProfilePic
									thumbnail={selectedUser.profileImage?.image}
									w={36}
									h={36}
									type={"USER"}
								/>
							</div>

							<div className={styles.warning_text}>
								{`${selectedUser.name} is ${
									alertModal === "admin"
										? "a representative"
										: "an admin"
								}.
                                If you add them to ${
									alertModal === "admin"
										? "admins"
										: "representatives"
								}, they will be removed from ${
									alertModal === "admin"
										? "representatives"
										: "admins"
								}.
                                Are you sure you want to do this?`}
							</div>
						</div>
						<div className={styles.modal_controls}>
							<SecondaryButton
								text={"CANCEL"}
								className={styles.cancel_btn}
								onClick={() => setAlertModal("")}
							/>
							<PrimaryButton
								text={`make ${alertModal}`}
								className={styles.confirm_btn}
								onClick={handleConfirmSwitch}
							/>
						</div>
					</div>
				}
			/>

			<GaawkModal
				show={deleteModal}
				handleClose={() => setDeleteModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Delete Company"}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.warning_wrapper}>
							<div className={styles.image_wrapper}>
								<ProfilePic
									thumbnail={
										companyProfile.profileImage
											? companyProfile.profileImage
													.originalImageURL
											: ""
									}
									w={36}
									h={36}
									type={"COMPANY"}
								/>
							</div>

							<div className={styles.warning_text}>
								Once you delete the company, all the data and
								people related to this company will be deleted.
								Are you sure you want to do this action?
							</div>
						</div>
						<div className={styles.modal_controls}>
							<SecondaryButton
								text={"CANCEL"}
								className={styles.cancel_btn}
								onClick={() => setDeleteModal(false)}
							/>
							<PrimaryButton
								text={"DELETE"}
								className={styles.confirm_btn}
								onClick={() =>
									deleteCompany(companyProfile.uuid)
								}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default CompanyManage;
