import useApi from "../../../../hooks/useApi";
import productApi from "../../../../api/product";
import { useQuery } from "@tanstack/react-query";
import { productsKeys } from "queryKeys/products-key-factory";

export const useProductsStats = (profileId) => {
    const getStatsApi = useApi(productApi.getProductStats, true, true);

    const fetchStats = async ({ queryKey }) => {
        const [, , profileId] = queryKey;
        const response = await getStatsApi.request(profileId);
        return response.data;
    };

    const { data: stats, isLoading: isLoadingStats } = useQuery({
        queryKey: productsKeys.stats(profileId),
        queryFn: fetchStats,
    });
    return { stats, isLoadingStats };
};
