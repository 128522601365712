import { useEffect, useMemo } from "react";
import styles from "./ServiceSection.module.scss";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import Masonry from "react-masonry-css";
import ServiceItemAlt from "./ServiceItemAlt";

const InfiniteServices = ({
	title,
	queryKey,
	queryFn,
	// errorMessage,
	itemsPerPage,
}) => {
	const { ref: viewRef, inView } = useInView();

	const {
		data,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		fetchStatus,
	} = useInfiniteQuery({
		queryKey,
		queryFn,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		// ...(errorMessage && {
		// 	meta: {
		// 		errorMessage,
		// 	},
		// }),
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) fetchNextPage();
	}, [inView, hasNextPage, fetchNextPage, isFetching]);

	const formattedServices = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((service, i) => (
					<Link
						key={service.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						className={styles.service_item}
						to={`/services/${service.uuid}`}
					>
						<ServiceItemAlt item={service} />
					</Link>
				))
			),
		[data]
	);

	return (
		<div className={styles.container}>
			<div className={styles.section_wrapper}>
				<h3>{title}</h3>

				{fetchStatus !== "idle" && isLoading ? (
					<LoadingSpinner />
				) : (
					<Masonry
						breakpointCols={{
							default: 2,
							1399: 1,
						}}
						className={styles.services_container}
						columnClassName={styles.services_container_grid_columns}
					>
						{formattedServices}
					</Masonry>
				)}
			</div>
		</div>
	);
};

export default InfiniteServices;
