import client from "./client";

const endpoint = "/project";

const getAllProjects = (page, size, status = true) =>
    client.get(endpoint + `?page=${page}&size=${size}&status=${status}`);

const getProject = (projectId) => client.get(endpoint + `/${projectId}`);

const updateProjectStatus = (projectId, status) =>
    client.put(endpoint + `/${projectId}/status?status=${status}`);

const getProjectTags = (query = "") => client.get(`/projectTag?tag=${query}`);

const addProject = (formData) => client.post(endpoint, formData);

const updateProject = (formData) => client.put(endpoint, formData);

const deleteProject = (projectId) => client.delete(endpoint + `/${projectId}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllProjects,
    getProject,
    updateProjectStatus,
    getProjectTags,
    addProject,
    updateProject,
    deleteProject,
};
