import {createSlice} from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";
import {comparer} from "../../components/Utils/General";

const slice = createSlice({
    name: "Comment",
    initialState: {
        data: [
            {
                postId: "",
                comments: [],
                hasMore: true,
            }
        ]
    },
    reducers: {
        "commentsUpdated": (state, action) => {
            const comments = action.payload;

            if (comments.length === 0) {

            } else {
                const postId = comments[0].postId;
                const index = state.data.findIndex(data => data.postId === postId);

                if (index >= 0) {
                    const storeComments = state.data[index].comments;

                    const commentsToAppend = comments.filter(comparer(storeComments));

                    state.data[index] = {
                        ...state.data[index],
                        comments: [...state.data[index].comments, ...commentsToAppend],
                        hasMore: commentsToAppend.length === 5,
                    };

                } else {

                    const dataItem = {
                        postId: postId,
                        comments: comments,
                        hasMore: comments.length === 5,
                    };

                    return {
                        ...state,
                        data: [...state.data, dataItem],
                    }
                }
            }
        },

        "commentAdded": (state, action) => {
            // console.log("comment added", action.payload);

            const postId = action.payload.postId;

            const index = state.data.findIndex(data => data.postId === postId);

            if (index >= 0) {

                state.data[index] = {
                    ...state.data[index],
                    comments: [...state.data[index].comments, action.payload],
                    hasMore: false,
                };

            } else {

                const arr = [];
                arr.unshift(action.payload);

                const dataItem = {
                    postId: postId,
                    comments: arr,
                    hasMore: false,
                }

                return {
                    ...state,
                    data: [...state.data, dataItem],
                }
            }
        },

        "reactionUpdated": (state, action) => {

            const index = state.data.findIndex(data => data.postId === action.payload.postId);
            const storeComments = state.data[index].comments;
            const commentIndex = storeComments.findIndex(item => item.uuid === action.payload.uuid);

            state.data[index].comments[commentIndex].stats = action.payload.stats;
            state.data[index].comments[commentIndex].profileReactionType = action.payload.profileReactionType;
        },

    },
});

export const {commentsUpdated, commentAdded, reactionUpdated} = slice.actions;
export default slice.reducer;

/**
 * get comments
 */

export const getComments = (postId, profileId, page, size) => apiActions.apiCallBegan({
    url: `posts/comment?page=${page}&postId=${postId}&profileId=${profileId}&size=${size}`,
    method: "GET",
    onSuccess: commentsUpdated.type,
});

/**
 * get comments
 */

export const addComment = (commentData) => apiActions.apiCallBegan({
    url: `posts/comment`,
    method: "POST",
    data: commentData,
    onSuccess: commentAdded.type,
    showSuccessAlert: true,
});

/**
 * add comment reaction
 */
export const addReaction = (commentId, profileId, reaction) => apiActions.apiCallBegan({
    url: `posts/comment/reaction?commentId=${commentId}&profileId=${profileId}&reactionType=${reaction}`,
    method: "PUT",
    onSuccess: reactionUpdated.type,
    // showSuccessAlert: true,
});

/**
 * remove reaction
 */
export const removeReaction = (commentId, profileId) => apiActions.apiCallBegan({
    url: `posts/comment/reaction?commentId=${commentId}&profileId=${profileId}`,
    method: "DELETE",
    onSuccess: reactionUpdated.type,
    // showSuccessAlert: true,
});
