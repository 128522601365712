import client from "./client";

const endpoint = "/recordingTypes";

const getRecordingTypes = () => client.get(endpoint);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getRecordingTypes,
};
