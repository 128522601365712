import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Skill",
    initialState: {
        list: [],
    },
    reducers: {
        skillListUpdated: (state, action) => {
            state.list = action.payload;
        },
    },
});

export const { skillListUpdated } = slice.actions;
export default slice.reducer;

/**
 * get skills list
 */

export const getSkillList = () =>
    apiActions.apiCallBegan({
        url: "/skills",
        method: "GET",
        onSuccess: skillListUpdated.type,
    });
