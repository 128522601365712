import useApi from "hooks/useApi";
import { useInView } from "react-intersection-observer";
import productApi from "api/product";
import { useInfiniteQuery } from "@tanstack/react-query";
import { productsKeys } from "queryKeys/products-key-factory";
import { useEffect } from "react";

const PAGE = 0;
const PAGE_SIZE = 50;
export const useGetSuggestedProducts = (productId, profileId) => {
	const { ref, inView } = useInView({ triggerOnce: true });
	const getProductsApi = useApi(productApi.getSuggestedProducts, true, true);

	const fetchProducts = async ({ pageParam = PAGE, queryKey }) => {
		const [, , , productId, profileId] = queryKey;
		const response = await getProductsApi.request(
			pageParam,
			PAGE_SIZE,
			productId,
			profileId
		);
		return response.data;
	};

	const {
		data: suggestedProducts,
		isLoading: isLoadingGetSuggestedProducts,
		hasNextPage,
		fetchNextPage,
		isFetching: isFetchingSuggestedProducts,
	} = useInfiniteQuery({
		queryKey: productsKeys.suggestedList(productId, profileId),
		queryFn: fetchProducts,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage?.list?.length === PAGE_SIZE ? pages.length : undefined;
			return nextPage;
		},
		refetchOnWindowFocus: false,
		enabled: !!productId,
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, inView]);
	return {
		suggestedProducts,
		isLoadingGetSuggestedProducts,
		isFetchingSuggestedProducts,
		ref,
	};
};
