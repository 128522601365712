import {createSlice} from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Chat",
    initialState: {
        converation: [],
        hasMore: true,
        tempConv: null,
        selected: null,
        searched: null,
        totalCount: 0,
    },
    reducers: {
        converationListUpdated: (state, action) => {
            const stateConversations = state.converation;

            if (stateConversations.length === 0) {
                // state.converation = action.payload;
                state.converation = action.payload;
                state.hasMore = action.payload.length === 10;

                // return {
                //     converation: action.payload,
                //     hasMore: action.payload.length === 10,
                // };
            } else {
                const totalCount = state.totalCount;
                return {
                    ...state,
                    converation: [...state.converation, ...action.payload],
                    hasMore: action.payload.length === 10,
                    totalCount: totalCount,
                };
            }
        },

        searchConversation: (state, action) => {
            state.searched = action.payload;
        },

        converationGroupAdded: (state, action) => {
            const stateConversations = state.converation;

            //? SHOULD GROUP BE "SELECTED" (state.selected = action.payload) in the returned state ?

            if (stateConversations.length === 0) {
                return {
                    ...state,
                    converation: action.payload,
                    hasMore: action.payload.length === 10,
                };
            } else {
                return {
                    ...state,
                    converation: [...state.converation, action.payload],
                    hasMore: action.payload.length === 10,
                };
            }
        },

        converationGroupUpdated: (state, action) => {
            const stateConversations = state.converation;

            const index = stateConversations.findIndex(
                (conv) => conv.uuid === action.payload.uuid
            );

            if (index > -1) {
                state.converation[index] = action.payload;
            }
        },

        converationTypingStatusUpdated: (state, action) => {
            const stateConversations = state.converation;

            const index = stateConversations.findIndex(
                (conv) => conv.uuid === action.payload.conversationId
            );

            if (index > -1) {
                state.converation[index].typing = action.payload.status;
            }
        },

        conversationLastMessageUpdated: (state, action) => {
            const conversationId = action.payload.conversationId;
            const index = state.converation.findIndex(
                (item) => item.uuid === conversationId
            );

            //does not exists
            if (index === -1) {
                // todo handle this
                // return {
                //     ...state,
                //     converation: [...state.converation, action.payload],
                //     hasMore: false,
                // }
            } else {
                state.converation[index].message = action.payload;
            }
        },

        converationTempAdded: (state, action) => {
            // check if conversation exists
            const conversationId = action.payload.uuid;
            const index = state.converation.findIndex(
                (item) => item.uuid === conversationId
            );

            if (index === -1) {
                state.tempConv = action.payload;
            }
            //  else {
            //     //todo: handle this
            // }
        },

        conversationUpdated: (state, action) => {

            const totalUnreadCount = action.payload.totalUnreadCount;

            // check if conversation exists
            const conversationId = action.payload.uuid;
            const index = state.converation.findIndex(
                (item) => item.uuid === conversationId
            );

            if (index === -1) {
                // to put the new conv on top of the list
                state.converation.unshift(action.payload);
            } else {

                // update the conversation last message

                state.converation[index].message =
                    action.payload.message;

                state.converation[index].unreadCount =
                    action.payload.unreadCount;

                // save the converdation
                const conv = state.converation[index];

                // delete it from the list
                state.converation = state.converation.filter(
                    (item) => item !== conv
                );

                // unshfit...
                state.converation.unshift(conv);
            }

            state.totalCount = totalUnreadCount;
        },

        totalUnreadCountUpdated: (state, action) => {

            state.totalCount = action.payload.totalUnreadCount;

        },

        converationRemoved: (state, action) => {
            state.tempConv = null;
        },

        conversationSelected: (state, action) => {
            state.selected = action.payload;
        },
        resetTempConv: (state) => {
            state.tempConv = null;
        },
    },
});

export const {
    converationListUpdated,
    searchConversation,
    converationGroupAdded,
    converationGroupUpdated,
    converationTypingStatusUpdated,
    conversationLastMessageUpdated,
    converationTempAdded,
    conversationUpdated,
    converationRemoved,
    conversationSelected,
    resetTempConv,
    totalUnreadCountUpdated,
} = slice.actions;
export default slice.reducer;

/**
 * get converstaions
 */

export const getConverations = (page, size) =>
    apiActions.apiCallBegan({
        url: `conversation/conversation?page=${page}&size=${size}`,
        method: "GET",
        onSuccess: converationListUpdated.type,
    });

export const getConversation = (conversationId) =>
    apiActions.apiCallBegan({
        url: `conversation/conversation/get?conversationId=${conversationId}`,
        method: "GET",
        onSuccess: searchConversation.type,
    });

/**
 * update converstaion typing status
 */

export const updateTypingStatus = (response) =>
    apiActions.socketBegan({
        response: response,
        onSuccess: converationTypingStatusUpdated.type,
    });

/**
 * update converstaion typing status
 */

export const updateLastMessage = (response) =>
    apiActions.socketBegan({
        response: response,
        onSuccess: conversationLastMessageUpdated.type,
    });

/**
 * converstation added
 */

export const addTempConversation = (response) =>
    apiActions.socketBegan({
        response: response,
        onSuccess: converationTempAdded.type,
    });

export const updateConversation = (response) =>
    apiActions.socketBegan({
        response: response,
        onSuccess: conversationUpdated.type,
    });

export const updateTotalUnread = (response) => apiActions.socketBegan({
    response: response,
    onSuccess: totalUnreadCountUpdated.type,
});

/**
 * converstation removed
 */

export const removeConversation = () =>
    apiActions.socketBegan({
        onSuccess: converationRemoved.type,
    });

/**
 * converstation selected
 */

export const selectConversation = (response) =>
    apiActions.socketBegan({
        response: response,
        onSuccess: conversationSelected.type,
    });

/**
 * Group related
 */

export const addGroupConversation = (formData) =>
    apiActions.apiCallBegan({
        url: `/conversation/group`,
        method: "POST",
        data: formData,
        onSuccess: converationGroupAdded.type,
        showSuccessAlert: true,
        contentType: "multipart/form-data",
    });

export const updateGroupConversation = (formData) =>
    apiActions.apiCallBegan({
        url: `/conversation/group`,
        method: "PUT",
        data: formData,
        onSuccess: converationGroupUpdated.type,
        showSuccessAlert: true,
        contentType: "multipart/form-data",
    });
