import {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
	useMemo,
} from "react";
import Close from "images/cross-white-14-x-14.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import closeIcon from "images/close-icon-black-20-x-20.svg";
import publicIcon from "images/public_icon.svg";
import { ReactComponent as AddIcon } from "images/add-icon.svg";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import VisibilitySelector from "../Utils/Visibility/VisibilitySelector";
import WarningModal from "../Utils/GaawkModal/WarningModal";
import styles from "./CreatePost.module.css";
import PostItem from "./PostItem";
import StaticMap from "../Utils/StaticMap/StaticMap";
import PostMedia from "./PostMedia";
// import LazyImage from "../Utils/LazyImage/LazyImage";
// import CustomTextarea from "../Utils/SubComs/CustomTextarea/CustomTextarea";
import LoadingBar from "../Utils/SubComs/LoadingBar/LoadingBar";
import LocationModal from "./LocationModal";
import MediaList from "./MediaList";

import useApi from "../../hooks/useApi";
import postApi from "../../api/post";

import EditMediaModal from "./EditMediaModal";
import PostButtons from "./PostButtons";
import ProfileTagModal from "./ProfileTagModal";
import EmotionModal from "./EmotionModal";
import ProfilePic from "./ProfilePic";
import PostHeader from "./PostHeader";
import CustomMentions from "../Utils/Mentions/CustomMentions";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { updatePostModal } from "store/slices/ui";
import useLocationChange from "hooks/useLocationChange";
import PostDocuments from "./Components/PostDocuments";
import { getLocationType } from "components/Utils/General";
import { toast } from "react-toastify";
import PrimaryButton from "components/Utils/Button/PrimaryButton";

const FILES_LIMIT = 10;

const CreatePost = forwardRef(
	(
		{ profileImg, name, type, onCreate, isFeed = false, board = undefined },
		ref
	) => {
		const addPostApi = useApi(postApi.addPost);
		const addMediaApi = useApi(postApi.addMedia);
		const updatePostStatusApi = useApi(postApi.updatePostStatus);

		const titleRef = useRef(null);

		// const isFeed = useLocation().pathname === "/";

		const thumbnail = profileImg?.framedImageURL
			? profileImg.framedImageURL
			: profileImg?.originalImageURL
			? profileImg.originalImageURL
			: undefined;

		const [showCreatePost, setShowCreatePost] = useState(false);
		const [showSharePost, setShowSharePost] = useState(false);
		const [showTagModal, setShowTagModal] = useState(false);
		const [showEmotionModal, setShowEmotionModal] = useState(false);
		const [showLocationModal, setShowLocationModal] = useState(false);
		const [showPhotosVideos, setShowPhotosVideos] = useState(false);
		const [showWarning, setShowWarning] = useState(false);

		const [postMedia, setPostMedia] = useState([]);
		const [pdfPosts, setPdfPosts] = useState([]);

		//* can be used if need to disable files buttons if more than ${FILES_LIMIT} files
		// const [filesError, setFilesError] = useState(false);

		// useEffect(() => {
		// 	if (postMedia.length + pdfPosts.length > FILES_LIMIT) {
		// 		alert("CAN'T UPLOAD MORE THAN 10 FILES");
		// 		setFilesError(true);
		// 	}
		// }, [postMedia, pdfPosts]);

		const [counter, setCounter] = useState(0);

		const [showProgression, setShowProgression] = useState(false);

		const [post, setPost] = useState({
			emotion: null,
			filesIncluded: false,
			location: null,
			postText: "",
			taggedProfiles: [],
			postVisibility: board ? "PUBLIC" : "WORK_CIRCLE_FRIENDS",
			boardId: board?.uuid,
			title: "",
		});

		const enablePosting = useMemo(() => {
			if (board) {
				if (post.title && post.postText) return true;
				else return false;
			} else {
				if (
					post.postText ||
					pdfPosts.length > 0 ||
					postMedia.length > 0
				)
					return true;
				else return false;
			}
		}, [board, pdfPosts, post.postText, post.title, postMedia]);

		const [sharedPost, setSharedPost] = useState("");

		const { postModal } = useSelector((state) => state.ui);

		useEffect(() => {
			setShowCreatePost(postModal);
		}, [postModal]);

		const dispatch = useDispatch();
		const didMountRef = useRef(false);

		useEffect(() => {
			if (didMountRef.current && !showCreatePost) {
				dispatch(updatePostModal(false));
			}
			didMountRef.current = true;
		}, [dispatch, showCreatePost]);

		useLocationChange(() => dispatch(updatePostModal(false)));

		useEffect(() => {
			if (showCreatePost) titleRef.current?.focus();
		}, [showCreatePost]);

		useImperativeHandle(ref, () => ({
			sharePost(post) {
				setSharedPost(post);
				setPost((prevState) => ({
					...prevState,
					sharedPostId: post.uuid,
				}));
				setShowSharePost(true);
			},
			showCreateModal() {
				setShowCreatePost(true);
			},
		}));

		useEffect(() => {
			setPost((prevState) => ({
				...prevState,
				filesIncluded: postMedia.length > 0 || pdfPosts.length > 0,
			}));
		}, [postMedia.length, pdfPosts.length]);

		const handlePostTextChange = (text) => {
			setPost((post) => ({
				...post,
				postText: text,
			}));
		};

		const onEmojiClick = (event, emojiObject) => {
			setPost((post) => ({
				...post,
				postText: post.postText + emojiObject.emoji,
			}));
		};

		/**
		 * Discard post creation....
		 */
		const handleWarningDiscard = () => {
			setShowWarning(false);
			setShowCreatePost(false);
			setShowSharePost(false);
			resetPost();
		};

		// !=== HANDLE SHARE POST =====
		const handleSharePost = () => {
			const { boardId, ...rest } = { ...post };
			// passing the post without the boardId (otherwise it's shared in the board)
			addPostApi.request(rest);
			setShowSharePost(false);
		};

		/**
		 * Handle post button
		 */
		const handlePostButton = () => {
			const postCopy = { ...post };
			delete postCopy.emotion;
			delete postCopy.taggedProfiles;
			if (post.emotion) postCopy["emotionId"] = post.emotion.uuid;
			if (post.taggedProfiles.length > 0) {
				postCopy["taggedProfiles"] = post.taggedProfiles.map(
					(profile) => profile.uuid
				);
			}

			//TODO add the servicesIds array
			addPostApi.request(postCopy);
			setShowCreatePost(false);
			if (postCopy.filesIncluded) setShowProgression(true);
		};

		const resetPost = () => {
			setPost({
				emotion: null,
				filesIncluded: false,
				location: null,
				postText: "",
				postVisibility: board ? "PUBLIC" : "WORK_CIRCLE_FRIENDS",
				taggedProfiles: [],
				boardId: board?.uuid,
			});

			if (postMedia.length > 0 || pdfPosts.length > 0) {
				setShowProgression(false);
				setCounter(0);
				setPostMedia([]);
				setPdfPosts([]);
			}
		};

		useEffect(() => {
			if (
				!addPostApi.loading &&
				addPostApi.data &&
				addPostApi.responseCode === 201
			) {
				if (!post.filesIncluded) {
					resetPost();
					onCreate(
						addPostApi.data
						// addPostApi.data.sharedPost ? true : false
					);
				} else {
					[...postMedia, ...pdfPosts].forEach((media) => {
						const formData = new FormData();
						if (board) {
							formData.append("boardId", board.uuid);
							formData.append("title", post.title);
						}
						formData.append("parentPostId", addPostApi.data.uuid);
						formData.append(
							"multipartFile",
							media.multipartFile,
							media.multipartFile instanceof Blob
								? media.multipartFile.fileName
								: undefined
						);
						formData.append("caption", media.caption);
						if (media.date) formData.append("date", media.date);
						if (media.location) {
							formData.append(
								"location.latitude",
								media.location.latitude
							);
							formData.append(
								"location.longitude",
								media.location.longitude
							);
							formData.append(
								"location.type",
								media.location.type
							);
							formData.append(
								"location.title",
								media.location.title
							);
						}
						if (media.taggedIds) {
							formData.append(
								"taggedIds",
								media.taggedIds.map((profile) => profile.uuid)
							);
						}
						if (media.services?.length > 0) {
							formData.append(
								"serviceIds",
								media.services.map((service) => service.uuid)
							);
						}

						addMediaApi.request(formData);
					});
				}
			}
		}, [addPostApi.data]);

		useEffect(() => {
			if (
				!addMediaApi.loading &&
				addMediaApi.data &&
				addMediaApi.responseCode === 200
			) {
				const mediaCount =
					addMediaApi.data.totalMediaCount +
					addMediaApi.data.totalPDFCount;

				setCounter(mediaCount);
				if (mediaCount === [...postMedia, ...pdfPosts].length) {
					updatePostStatusApi.request(addMediaApi.data.uuid);
				}
			}
		}, [addMediaApi.data]);

		useEffect(() => {
			if (
				!updatePostStatusApi.loading &&
				updatePostStatusApi.data &&
				updatePostStatusApi.responseCode === 200
			) {
				resetPost();
				onCreate(updatePostStatusApi.data);
			}
		}, [updatePostStatusApi.data]);

		/**
		 * Visibility
		 */
		const handlePostVisibilityChange = (value) => () => {
			setPost((prevState) => ({
				...prevState,
				postVisibility: value,
			}));
		};

		/**
		 * Tags
		 */

		const handleShowTagModal = () => {
			setShowCreatePost(false);
			setShowTagModal(true);
		};

		const handleHideTagModal = () => {
			setShowTagModal(false);
			setShowCreatePost(true);
		};

		const handleConfirmTags = (postTags) => {
			setPost((prevState) => ({
				...prevState,
				taggedProfiles: postTags.length === 0 ? [] : postTags,
			}));
		};

		/**
		 * Emotion handlers...
		 */

		const handleShowEmotionModal = () => {
			setShowCreatePost(false);
			setShowEmotionModal(true);
		};

		const handleEmotionClicked = (emotion) => {
			setPost((prevState) => ({
				...prevState,
				emotion,
			}));
		};

		const handleRemoveEmotion = (e) => {
			e.stopPropagation();
			setPost((prevState) => ({
				...prevState,
				emotion: null,
			}));
		};

		const handleHideEmotionModal = () => {
			setShowEmotionModal(false);
			setShowCreatePost(true);
		};

		/**
		 * Location handlers...
		 */
		const handleShowLocationModal = () => {
			setShowCreatePost(false);
			setShowLocationModal(true);
		};

		const handleClearLocation = () => {
			setPost((prevState) => ({ ...prevState, location: null }));
		};

		const handleLocationClicked = (place) => {
			setPost((prevState) => ({
				...prevState,
				location: {
					latitude: place.geometry.location.lat,
					longitude: place.geometry.location.lng,
					title: place.name,
					placeId: place.place_id,
					type: getLocationType(place.types),
				},
			}));

			handleCloseLocationModal();
		};

		const handleCloseLocationModal = () => {
			setShowLocationModal(false);
			setShowCreatePost(true);
		};

		/**
		 * File uploader
		 */

		const allowFiles = (filesAmount) => {
			if (filesAmount + pdfPosts.length + postMedia.length > FILES_LIMIT)
				return false;
			return true;
		};

		const triggerFilesErrorLimit = () =>
			toast.error(`You can only upload up to ${FILES_LIMIT} items`);

		const handleAppendFileChange = ({ target }) => {
			const files = target.files;
			const fileData = [];

			if (files.length > 0) {
				if (allowFiles(files.length)) {
					for (let i = 0; i < files.length; i++) {
						fileData.push({
							caption: "",
							multipartFile: files[i],
							postId: "",
							type: files[i].type.includes("video")
								? "VIDEO"
								: files[i].type.includes("image")
								? "IMAGE"
								: "",
							taggedIds: [],
							newFile: true,
						});
					}
					setPostMedia((prevState) => [...prevState, ...fileData]);
				} else {
					triggerFilesErrorLimit();
				}
			}

			//TODO >> run a FN that checks the files count and allows or not set in postMedias

			// if (!showCreatePost) {
			// 	setShowCreatePost(true);
			// }
		};

		const handlePdfChange = ({ target }) => {
			const files = target.files;
			const fileData = [];

			if (files.length > 0) {
				if (allowFiles(files.length)) {
					for (let i = 0; i < files.length; i++) {
						fileData.push({
							caption: "",
							multipartFile: files[i],
							postId: "",
							type: "PDF",
							taggedIds: [],
							newFile: true,
						});
					}

					setPdfPosts((prevState) => [...prevState, ...fileData]);
				} else {
					triggerFilesErrorLimit();
				}
			}
		};

		const handleShowPhotoVideoModal = () => {
			setShowCreatePost(false);
			setShowPhotosVideos(true);
		};

		const handleHidePhotoVideoModal = () => {
			setShowPhotosVideos(false);
			setShowCreatePost(true);
		};

		const handleRemovePDFItem = (item, index) => {
			const pdfPostsCopy = [...pdfPosts];
			pdfPostsCopy.splice(index, 1);
			setPdfPosts(pdfPostsCopy);
		};

		const handleRemoveMediaItem = (index) => () => {
			const postMediaCopy = [...postMedia];
			postMediaCopy.splice(index, 1);
			setPostMedia(postMediaCopy);

			if (postMediaCopy.length === 0) handleHidePhotoVideoModal();
		};

		const handleRemoveAllMedia = () => {
			setPostMedia([]);
		};

		/**
		 * mappers
		 */

		// media
		const [showEditMedia, setShowEditMedia] = useState(false);
		const [editItem, setEditItem] = useState("");
		const [editItemIndex, setEditItemIndex] = useState("");

		const handleShowEditModal = (media, index) => {
			setEditItem(media);
			setEditItemIndex(index);
			setShowPhotosVideos(false);
			setShowEditMedia(true);
		};

		const handleHideEditMedia = () => {
			setShowEditMedia(false);
			setShowPhotosVideos(true);
		};

		const handleConfirmEdit = (newItem) => {
			const postMediaCopy = [...postMedia];
			postMediaCopy[editItemIndex] = newItem;
			setPostMedia(postMediaCopy);
		};

		// TODO: USEMEMO
		const mediaList = postMedia.map((media, index) => {
			return (
				<MediaList
					key={index}
					mediaType={media.type}
					url={URL.createObjectURL(media.multipartFile)}
					fileName={media.multipartFile.name}
					text={media.caption}
					onClick={handleRemoveMediaItem(index)}
					onEdit={() => handleShowEditModal(media, index)}
				/>
			);
		});

		const NewPostLoading = () => {
			return (
				<div>
					<div>{`${counter} / ${
						[...postMedia, ...pdfPosts].length
					}`}</div>
					<LoadingBar
						progress={
							(100 * counter) / [...postMedia, ...pdfPosts].length
						}
					/>
				</div>
			);
		};

		return (
			<>
				{/* //TODO >> PROGRESSION BAR TO BE REDIFINED LATER (DESIGN-WISE) */}
				{showProgression && <NewPostLoading />}
				<div
					className={`${styles.padd_lr_10} ${
						!isFeed ? styles.hide_post_controls : undefined
					}`}
				>
					<div className={styles.create_post_section}>
						{/* <div className={styles.create_post_profile_img}>
							<button
								className={`${styles.profile_img} ${
									type === "COMPANY"
										? styles.company
										: undefined
								}`}
							>
								<ProfilePic
									w={"100%"}
									h={"100%"}
									type={type}
									thumbnail={thumbnail}
									noMarginRight={true}
									name={name}
									enableName={false}
								/>


							</button>
						</div> */}
						<button
							className={`${styles.profile_img} ${
								type === "COMPANY" ? styles.company : undefined
							}`}
						>
							<ProfilePic
								w={"100%"}
								h={"100%"}
								type={type}
								thumbnail={thumbnail}
								noMarginRight={true}
								name={name}
								enableName={false}
							/>
						</button>

						<div
							className={styles.create_post_text_reaction_section}
						>
							<div
								className={styles.create_post_input}
								onClick={() => setShowCreatePost(true)}
								role="button"
								tabIndex={0}
							>
								<label>What's on your mind?</label>
								<AddIcon fill="#000" width={18} height={18} />
							</div>

							{/* <PostButtons
								onChange={handleAppendFileChange}
								onEmotion={handleShowEmotionModal}
								onLocation={handleShowLocationModal}
								onTag={handleShowTagModal}
							/> */}
						</div>
					</div>
				</div>

				<GaawkModal
					show={showCreatePost}
					handleClose={() => setShowWarning(true)}
					defaultModal={false}
					showHeader={true}
					title={board ? "Post to Board" : "Create a Post"}
					closeAlign={"right"}
					children={
						<div className={styles.create_post_modal_container}>
							<div className={styles.user_wrapper}>
								<PostHeader
									name={name}
									img={
										board
											? board.feedImage.file.customName
											: thumbnail
									}
									emotion={post.emotion}
									location={post.location}
									tags={post.taggedProfiles}
									createMode={true}
									postTime={null}
									visibility={null}
									type={type}
									postType={board ? "BOARD" : undefined}
									boardName={board ? board.name : undefined}
									icon={
										board
											? board.owner?.profileImage?.image
											: undefined
									}
									// url={owner.url}
									onTagClicked={(e) => {
										e.stopPropagation();
										handleShowTagModal();
									}}
									onOtherTagsClicked={(e) => {
										e.stopPropagation();
										handleShowTagModal();
									}}
								/>
							</div>

							{board && (
								<div className={styles.title_input}>
									<label>
										Title
										<span className="required">*</span>
									</label>
									<TextInput
										ref={titleRef}
										onChange={({ target }) =>
											setPost((prevState) => ({
												...prevState,
												title: target.value,
											}))
										}
										value={post.title}
										placeholder="Enter title"
										cursor="text"
									/>
								</div>
							)}

							<div className={styles.create_body_wrapper}>
								<div className={styles.post_text_container}>
									<CustomMentions
										placeholder={"What's on your mind?"}
										value={post.postText}
										onChange={handlePostTextChange}
										onEmojiSelected={onEmojiClick}
										fixedHeight={true}
										h={177}
									/>
									{/* <CustomTextarea
                                        isExtendable={false}
                                        value={post.postText}
                                        onChange={handlePostTextChange}
                                        onEmojiSelected={onEmojiClick}
                                        hasBorder={true}
                                    /> */}
								</div>
								{post.location && postMedia.length <= 0 && (
									<div
										className={
											styles.post_location_map_container
										}
									>
										<StaticMap
											lat={post.location.latitude}
											lng={post.location.longitude}
											title={post.location.title}
										/>

										<button
											className={
												styles.location_clear_button
											}
											onClick={handleClearLocation}
										>
											<img src={Close} alt={"dismiss"} />
										</button>
									</div>
								)}
								{postMedia.length > 0 && (
									<div
										className={
											styles.create_post_media_container
										}
									>
										<PostMedia
											mediaList={postMedia}
											viewer={true}
											multipartViewer={true}
										/>
										<div
											className={
												styles.create_post_media_controllers
											}
										>
											<button
												className={
													styles.edit_post_media_button
												}
												onClick={
													handleShowPhotoVideoModal
												}
											>
												<img
													src={editIcon}
													alt={"edit"}
												/>
												<label>Edit</label>
											</button>

											<button
												className={
													styles.remove_post_media_button
												}
												onClick={handleRemoveAllMedia}
											>
												<img
													src={closeIcon}
													alt={"close"}
												/>
											</button>
										</div>
									</div>
								)}
								{pdfPosts.length > 0 && (
									<PostDocuments
										items={pdfPosts}
										onDelete={handleRemovePDFItem}
										canEdit={true}
									/>
								)}
								<div className={styles.info}>
									You can only upload up to 10 items{" "}
								</div>
								<PostButtons
									onChange={handleAppendFileChange}
									onChangePdf={handlePdfChange}
									onEmotion={handleShowEmotionModal}
									onLocation={handleShowLocationModal}
									onTag={handleShowTagModal}
								/>
								<div className={styles.post_visibility_row}>
									{board ? (
										<div
											className={styles.board_visibility}
										>
											<img src={publicIcon} alt="" />
											Posting to boards is always public.
										</div>
									) : (
										<>
											<label>Post to:</label>

											<div
												className={
													styles.visibility_wrapper
												}
											>
												<VisibilitySelector
													value={post.postVisibility}
													onValueChange={
														handlePostVisibilityChange
													}
												/>
											</div>
										</>
									)}
								</div>
								{/* <div className={styles.post_button_wrapper}>
									<button
										className={styles.post_button}
										onClick={handlePostButton}

									>
										post
									</button>
								</div> */}
								<div className={styles.post_button_wrapper}>
									<PrimaryButton
										className={styles.post_button}
										onClick={handlePostButton}
										disabled={!enablePosting}
										text={"post"}
									/>
								</div>
							</div>
						</div>
					}
				/>

				<GaawkModal
					show={showSharePost}
					handleClose={() => setShowWarning(true)}
					defaultModal={false}
					showHeader={true}
					title={"Share a Post"}
					closeAlign={"right"}
					children={
						<div className={styles.create_post_modal_container}>
							<div className={styles.user_wrapper}>
								<PostHeader
									name={name}
									img={thumbnail}
									emotion={post.emotion}
									location={post.location}
									tags={post.taggedProfiles}
									createMode={true}
									postTime={null}
									visibility={null}
									type={type}
									// url={owner.url}
								/>
							</div>

							<div className={styles.create_body_wrapper}>
								<div className={styles.post_text_container}>
									{/* <CustomTextarea
                                        isExtendable={false}
                                        value={post.postText}
                                        onChange={handlePostTextChange}
                                        onEmojiSelected={onEmojiClick}
                                        hasBorder={true}
                                    /> */}
									<CustomMentions
										placeholder={"What's on your mind?"}
										value={post && post.postText}
										onChange={handlePostTextChange}
										onEmojiSelected={onEmojiClick}
										fixedHeight={true}
										h={177}
									/>
								</div>

								<div className={styles.post_visibility_row}>
									<label>Post to:</label>

									<div className={styles.visibility_wrapper}>
										<VisibilitySelector
											value={post.postVisibility}
											onValueChange={
												handlePostVisibilityChange
											}
										/>
									</div>
								</div>

								<PostItem
									post={sharedPost}
									inShareModal={true}
									isSharePost={true}
								/>

								<div className={styles.post_button_wrapper}>
									<button
										className={styles.post_button}
										onClick={handleSharePost}
									>
										share
									</button>
								</div>
							</div>
						</div>
					}
				/>

				<ProfileTagModal
					show={showTagModal}
					tagsArray={post.taggedProfiles}
					onClose={handleHideTagModal}
					onConfirm={handleConfirmTags}
				/>

				<EmotionModal
					emotionId={post.emotion && post.emotion.uuid}
					show={showEmotionModal}
					onClose={handleHideEmotionModal}
					onSelect={handleEmotionClicked}
					onRemoveEmotion={handleRemoveEmotion}
				/>

				<LocationModal
					show={showLocationModal}
					onClose={handleCloseLocationModal}
					onLocationClicked={handleLocationClicked}
					activeLocation={post.location}
					onRemove={handleClearLocation}
				/>

				<GaawkModal
					show={showPhotosVideos}
					handleClose={handleHidePhotoVideoModal}
					defaultModal={false}
					showHeader={true}
					title={"Photos / Videos"}
					closeAlign={"left"}
					children={
						<div
							className={`${styles.create_post_modal_container} ${styles.scrollable_modal}`}
						>
							{mediaList}
						</div>
					}
				/>

				<EditMediaModal
					show={showEditMedia}
					onClose={handleHideEditMedia}
					item={editItem}
					onConfirm={handleConfirmEdit}
					onToggle={(bool) => setShowEditMedia(bool)}
					onReset={() => setEditItem("")}
				/>

				<WarningModal
					show={showWarning}
					modalOnTop={true}
					headerText="Are you sure you want to discard your changes?"
					warningText="This action cannot be undone."
					cancelButtonText={"Cancel"}
					onCancelButtonClicked={() => setShowWarning(false)}
					submitButtonText={"DISCARD"}
					onSubmitButtonClicked={handleWarningDiscard}
				/>
			</>
		);
	}
);

export default CreatePost;
