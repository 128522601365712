import client from "./client";

const endpoint = "/social";

const getSocialMedia = () => client.get(endpoint);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getSocialMedia,
};
