import useApi from "hooks/useApi";
import productApi from "api/product";
import { useQuery } from "@tanstack/react-query";
import { productsKeys } from "queryKeys/products-key-factory";

export const useGetProductById = (productId, enabled) => {
	const getProductByIdApi = useApi(productApi.getProductById, true, true);

	const fetchProduct = async ({ queryKey }) => {
		const [, , productId] = queryKey;
		const response = await getProductByIdApi.request(productId);
		return response.data;
	};

	const {
		data: product,
		isFetched: isFetchedGetProductById,
		isFetching: isFetchingGetProductById,
		isLoading: isLoadingGetProductById,
		isError: isErrorGetProductById,
	} = useQuery({
		queryKey: productsKeys.detail(productId),
		queryFn: fetchProduct,
		enabled: enabled,
	});

	return {
		product,
		isFetchedGetProductById,
		isFetchingGetProductById,
		isErrorGetProductById,
		isLoadingGetProductById,
	};
};
