import React from "react";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import {
    workLocation as workLocationTypes,
    employementTypes,
} from "../../../Utils/General";
import styles from "./Filters.module.css";

const JobsFilter = ({
    onJobTypeChange,
    jobType,
    onWorkLocationChange,
    workLocation,
    onExperienceChange,
    experienceLevel,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <MultiCheckbox
                    title={"Job Type"}
                    options={employementTypes.flatMap((job) =>
                        job.value === "FULL_TIME" ||
                        job.value === "PART_TIME" ||
                        job.value === "CONTRACT" ||
                        job.value === "VOLUNTEERING" ||
                        job.value === "INTERNSHIP"
                            ? job
                            : []
                    )}
                    onSelect={onJobTypeChange}
                    selected={jobType}
                />
            </div>

            <div className={styles.section}>
                <MultiCheckbox
                    title={"Working Location"}
                    options={workLocationTypes}
                    onSelect={onWorkLocationChange}
                    selected={workLocation}
                    type={"radio"}
                />
            </div>

            <div className={styles.section}>
                <div className={styles.form_input_wrapper}>
                    <label>Experience Level</label>
                    <TextInput
                        onChange={onExperienceChange}
                        value={experienceLevel}
                        type="number"
                        placeholder="Enter number of years"
                    />
                </div>
            </div>
        </div>
    );
};

export default JobsFilter;
