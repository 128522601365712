import React from "react";
import styles from "./LoadingBar.module.css";

const LoadingBar = ({ progress }) => {
    return (
        <div className={styles.progress_bar_wrapper}>
            <div
                className={styles.progress_label}
                style={{
                    width: `${progress}%`,
                }}
            >
                {`${progress}%`}
            </div>
            <div className={styles.progress_bar}>
                <div
                    className={styles.achieved}
                    style={{
                        width: `${progress}%`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default LoadingBar;
