import {createSlice} from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Emoji",
    initialState: {
        list: []
    },
    reducers: {
        "emojiListUpdate" : (state, action) => {
            state.list = action.payload;
        },
    },
});

export const {emojiListUpdate} = slice.actions;
export default slice.reducer;

/**
 * search emotions
 */

export const getEmotions = () => apiActions.apiCallBegan({
    url: `emotions`,
    method: "GET",
    onSuccess: emojiListUpdate.type,
});

export const searchEmotions = (name) => apiActions.apiCallBegan({
    url: `emotions/search?q=${name}`,
    method: "GET",
    onSuccess: emojiListUpdate.type,
});