import {createSlice} from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Software",
    initialState: {
        list: []
    },
    reducers: {
        "softwareListUpdated" : (state, action) => {
            state.list = action.payload;
        },
    },
});

export const {softwareListUpdated} = slice.actions;
export default slice.reducer;

/**
 * get software list
 * @returns {PayloadAction<undefined, "api/callBegan"> | PayloadAction<any, "api/callBegan", ReturnType<(...args: any[]) => {payload: any}> extends {meta: infer M} ? M : never, ReturnType<(...args: any[]) => {payload: any}> extends {error: infer E} ? E : never> | PayloadAction<any, "api/callBegan", ReturnType<(...args: any[]) => {payload: any, meta: any}> extends {meta: infer M} ? M : never, ReturnType<(...args: any[]) => {payload: any, meta: any}> extends {error: infer E} ? E : never> | PayloadAction<any, "api/callBegan", ReturnType<(...args: any[]) => {payload: any, error: any}> extends {meta: infer M} ? M : never, ReturnType<(...args: any[]) => {payload: any, error: any}> extends {error: infer E} ? E : never> | PayloadAction<any, "api/callBegan", ReturnType<(...args: any[]) => {payload: any, meta: any, error: any}> extends {meta: infer M} ? M : never, ReturnType<(...args: any[]) => {payload: any, meta: any, error: any}> extends {error: infer E} ? E : never>}
 */

export const getSoftwareList = () => apiActions.apiCallBegan({
    url: "software",
    method: "GET",
    onSuccess: softwareListUpdated.type,
});