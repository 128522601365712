import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import styles from "./LazyImage.module.css";

const LazyImage = ({
	image,
	width,
	height,
	inlineStyle,
	placeholder,
	onAfterLoad,
	customWrapper,
	onClick,
}) => {
	// const customStyle = {
	//     objectFit: "cover",
	//     borderRadius: borderRadius ?  borderRadius : 0,
	//     // display: flex ? "flex" : "block",
	//     // flexWrap: "wrap",
	//     // justifyContent: "center !important",
	//     // alignContent: "center",
	// }

	return (
		<LazyLoadImage
			afterLoad={onAfterLoad}
			className={inlineStyle}
			wrapperClassName={`${styles.wrapper} ${customWrapper}`}
			alt={image.props.alt}
			effect="blur"
			src={image.props.src}
			placeholder={placeholder}
			height={height ? height : "100%"}
			width={width ? width : "100%"}
			onClick={onClick}
			// visibleByDefault={true}
		/>
	);
};

export default LazyImage;
