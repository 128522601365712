import React, { forwardRef } from "react";
import styles from "./TalentItem.module.css";
import { Link } from "react-router-dom";
import ProfilePic from "../../Home/ProfilePic";
import { specialProfileTypes } from "../ProfileEdit/SpecialProfile/special-types";
import routes from "components/Routing/routing-keys";

const TalentItem = forwardRef(
	({ data: talent, rightAction, agencyId }, ref) => {
		return (
			<div className={styles.talent_item} ref={ref}>
				<Link
					to={routes.specialProfile(
						talent.url,
						agencyId && `?agency=${agencyId}`
					)}
					className={styles.talent_link}
				>
					<ProfilePic
						w={48}
						h={48}
						thumbnail={talent?.profilePic.file.customName}
						type="USER"
						fullWidth={true}
						name={talent.alias || talent.name}
						subdata={
							specialProfileTypes.find(
								(type) => type.value === talent.type
							).label
						}
					/>
				</Link>
				{rightAction}
			</div>
		);
	}
);

export default TalentItem;
