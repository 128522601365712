import React from "react";
import styles from "./EmployeeItem.module.css";

import acceptIcon from "images/blue-tick-35-x-35.svg";
import cancelIcon from "images/cancel-btn-red.svg";
import GroupMember from "../../Chat/GroupMember";
import { format } from "date-fns";

const EmployeeItem = ({ data, onUpdate }) => {
	return (
		<div className={styles.item_container}>
			<GroupMember data={data.profile} customStyle={styles.no_border} />
			<div className={styles.controls_wrapper}>
				<div className={styles.left_side}>
					<div className={styles.dates_wrapper}>
						{format(new Date(data.startDate), "MMM yyyy")} -{" "}
						{data.currentPosition
							? "PRESENT"
							: format(new Date(data.endDate), "MMM yyyy")}
					</div>
					<div className={styles.role_wrapper}>
						{data.role?.name || data?.title}
					</div>
				</div>
				<div className={styles.right_side}>
					<button onClick={() => onUpdate("DECLINE")}>
						<img src={cancelIcon} alt="" />
					</button>
					<button onClick={() => onUpdate("ACCEPT")}>
						<img src={acceptIcon} alt="" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default EmployeeItem;
