import styles from "./UserSection.module.scss";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import GroupMember from "../../../Chat/GroupMember";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

const UserSection = ({
	title,
	queryKey,
	queryFn,
	// errorMessage,
	onViewMore,
}) => {
	const { data, isLoading, fetchStatus } = useQuery({
		queryKey,
		queryFn,
		// ...(errorMessage && {
		// 	meta: {
		// 		errorMessage,
		// 	},
		// }),
		staleTime: 300000, // 5 minutes
	});

	const formattedUsers = useMemo(
		() =>
			data?.map((user) => (
				<div className={styles.contact_item} key={user.uuid}>
					<GroupMember data={user} redirect={true} />
				</div>
			)),
		[data]
	);

	if (data?.length === 0) return null;

	return (
		<div className={styles.container}>
			<div className={styles.section_wrapper}>
				<h3>{title}</h3>

				{fetchStatus !== "idle" && isLoading ? (
					<LoadingSpinner />
				) : (
					<div>{formattedUsers}</div>
				)}

				{!isLoading && (
					<div className={styles.button_wrapper}>
						<SeeMore text="See more" onClick={onViewMore} />
					</div>
				)}
			</div>
		</div>
	);
};

export default UserSection;
