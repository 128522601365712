import React from "react";
import GaawkLink from "../../Utils/SubComs/GaawkLink/GaawkLink";
import styles from "./GaawkTile.module.css";

const GaawkTile = ({
    label,
    img,
    path,
    tileStyle,
    labelStyle,
    inline = false,
    customStyle,
}) => {
    return (
        <GaawkLink
            to={path}
            customStyle={styles.tile_wrapper}
            disableDecoration={true}
        >
            <div
                className={`${styles.tile_item} ${
                    inline ? styles.inline : undefined
                } ${customStyle}`}
            >
                <div className={`${styles.icon} ${tileStyle}`}>
                    <img src={img} alt="" />
                </div>

                <div className={`${styles.label} ${labelStyle}`}>{label}</div>
            </div>
        </GaawkLink>
    );
};

export default GaawkTile;
