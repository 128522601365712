import { useEffect, useRef, useCallback } from "react";

function usePageLeave(onLeave) {
	const isLeaving = useRef(false);

	const handleVisibilityChange = useCallback(() => {
		if (document.visibilityState === "hidden") {
			isLeaving.current = true;
			setTimeout(() => {
				if (isLeaving.current) {
					onLeave();
				}
			}, 0);
		} else {
			isLeaving.current = false;
		}
	}, [onLeave]);

	const handleBeforeUnload = useCallback(() => {
		isLeaving.current = true;
	}, []);

	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			document.removeEventListener(
				"visibilitychange",
				handleVisibilityChange
			);
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [handleVisibilityChange, handleBeforeUnload]);
}

export default usePageLeave;
