import Marquee from "react-fast-marquee";

import DescriptionTile from "components/Landing/DescriptionTile";
import styles from "./LandingPageDescription.module.scss";
import PerfectFitView from "components/Landing/PerfectFitView";
import LandingPageFeatures from "components/Landing/LandingPageFeatures";

const LandingPageDescription = () => {

    return <div className={styles.container}>
        <div className={styles.smallTitleView}>
            <span className={styles.smallTitle}>gaawk features</span>
        </div>
        <div className={styles.titleView}>
            <span className={styles.title}>All You need, together.</span>
        </div>
        <DescriptionTile
            img={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/gaawk_feature_2.png"}
            subtitleIcon={require("images/cloud-data.svg").default}
            subtitleText={"Special Profile & Talent"}
            title={"Special Profile & Talent"}
            description={"Vault is your go-to digital storage solution, designed to keep your data safe and secure. Say goodbye to cluttered conversations and hello to organized storage with Vault!"}
        />
        <DescriptionTile
            direction={"right"}
            img={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/gaawk_feature_3.png"}
            subtitleIcon={require("images/suitcase.svg").default}
            subtitleText={"Jobs & work Status"}
            title={"Dream Career"}
            description={"Your professional playground for freelancers and job seekers alike! Unlock endless opportunities, connect with like-minded professionals, and pave your path to success."}

        />
        <DescriptionTile
            img={"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/gaawk_feature_4.png"}
            subtitleIcon={require("images/cloud-data.svg").default}
            subtitleText={"Vault"}
            title={"Say, Free Data"}
            description={"Vault is your go-to digital storage solution, designed to keep your data safe and secure. Say goodbye to cluttered conversations and hello to organized storage with Vault!"}
        />
        <div className={styles.extendedView}>
            <Marquee className={styles.marquee}>
                FIND JOBS • FRIENDS • FREELANCERS •
            </Marquee>
            <PerfectFitView/>
            <LandingPageFeatures />
        </div>
    </div>;
};

export default LandingPageDescription;