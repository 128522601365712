export const defaultFormatter = (item) => {
	return {
		label: item.name || item.label,
		value: item.uuid || item.value,
	};
};

export const specialtyFormatter = (item) => {
	return {
		label:
			(item.customIndustry ? item.customIndustry : item.industry?.name) ||
			item.label,
		value: item.id || item.value,
	};
};

export const languageFormatter = (item) => {
	return {
		label: item.name || item.label,
		value: item.code || item.value,
	};
};

export const countryFormatter = (item) => {
	return {
		label: item.countryDTO.name,
		value: item.id,
	};
};

export const shortCityFormatter = (item) => {
	return {
		label: item.name,
		value: item.id,
	};
};
export const cityFormatter = (item) => {
	return {
		label: `${item.name}, ${item.countryDTO.name}`,
		value: item.id,
		countryCode: item.countryDTO.code,
	};
};

export const addressFormatter = (item) => {
	return {
		label: `${item.name}, ${item.countryDTO.name}`,
		value: item.name,
		countryCode: item.countryDTO.code,
		id: item.id,
	};
};

export const dialCodeFormatter = (item) => {
	return {
		label: `${item.countryDTO.name} (${item.countryDTO.dialCode})`,
		value: item.countryDTO.dialCode,
	};
};

export const locationFormatter = (item) => {
	return {
		label: `${
			item.name
				? `${item.name}, ${item.countryDTO.name}`
				: `${item.countryDTO.name}`
		} `,
		value: item.id,
	};
};

export const peopleFormatter = (user) => {
	const { uuid, name, ...restUserProps } = user;
	return {
		label: name,
		value: uuid,
		...restUserProps,
	};
};

//TODO >> replace with unduplicateValue if possible
export const unduplicateIds = (idsList, data, identifier = undefined) => {
	return data?.filter(
		(item) =>
			!idsList?.includes(
				identifier ? item[identifier] : item.uuid || item.id
			)
	);
};

export const unduplicateValue = (list, data, identifier = "value") => {
	return data?.filter(
		(item) => !list?.map((i) => i[identifier])?.includes(item[identifier])
	);
};
