import styles from "./All.module.scss";
import searchApi from "../../../api/search";
import PeopleAndCompanies from "../SearchComponents/PeopleAndCompanies";
import { companiesInitialFilters } from "../initialFilters";
import useGlobalSearch from "../../../hooks/useGlobalSearch";

const Companies = ({
	searchInput,
	userCoordinates,
	isMobile,
	onShowModal,
	tagsList,
}) => {
	const { data, hasNextPage, fetchNextPage, isLoading, isFetching } =
		useGlobalSearch(
			companiesInitialFilters,
			// userCoordinates,
			{ locationIdForSort: userCoordinates.id },
			["searchCompanies"],
			searchInput,
			searchApi.searchCompanies
		);

	return (
		<div className={styles.section}>
			<div className={styles.sub_section}>
				<PeopleAndCompanies
					count={data?.pages[0].count}
					data={data}
					isCompany={true}
					isPaginated={true}
					hasNextPage={hasNextPage}
					isFetching={isFetching}
					onLoad={fetchNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
				/>
			</div>
		</div>
	);
};

export default Companies;
