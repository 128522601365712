import { useEffect, useMemo, useRef } from "react";
import PostItem from "../../Home/PostItem";
import { pluralize } from "../../Utils/General";
import styles from "./Posts.module.scss";
import { useInView } from "react-intersection-observer";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import filterIcon from "images/filter-icon-white.svg";
import EditPostModal from "../../Home/EditPostModal";
import FullScreenModal from "../../Utils/GaawkModal/FullScreenModal";
import PostFullScreen from "../../Home/PostFullScreen";
import postApi from "../../../api/post";
import boardsApi from "../../../api/boards";
import { useSelector } from "react-redux";
import useCompanyId from "../../../hooks/useCompanyId";
import CreatePost from "../../Home/CreatePost";
import usePostViewer from "../../../hooks/usePostViewer";
import useMutate from "../../../hooks/useMutate";
import usePostById from "../../../hooks/usePostById";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import PostLoader from "components/Utils/SubComs/CustomLoader/PostLoader";

const Posts = ({
	count,
	data,
	isPaginated = false,
	onLoad,
	hasNextPage,
	isFetching,
	isLoading,
	isMobile,
	onShowModal,
	searchInput,
	onRefresh,
	onClick,
	tagsList,
}) => {
	const { ref: viewRef, inView } = useInView();

	const companyId = useCompanyId();

	const profile = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const { type, url, profileImage, firstName, lastName, name } =
		profile || {};

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			onLoad();
		}
	}, [inView, hasNextPage, onLoad, isFetching]);

	// !========== POST CLICK HANDLER ==============

	const {
		postModal,
		postId,
		subPostId,
		handleItemClicked,
		handleCloseModal,
		subPostIndex,
	} = usePostViewer();

	// ! ============ DELETE MEDIA ============

	const {
		action: { mutate: deletePost },
	} = useMutate(postApi.deletePost, onRefresh);

	// ! ============ PIN / UNPIN HANDLER ============

	// const {
	// 	action: { mutate: pinPost },
	// } = useMutate(boardsApi.pinPost, onRefresh);

	// const {
	// 	action: { mutate: unpinPost },
	// } = useMutate(boardsApi.unpinPost, onRefresh);

	// const handleTogglePin = (post) => {
	// 	const {
	// 		pinned: isPostPinned,
	// 		board: { uuid: boardId },
	// 		uuid: postId,
	// 	} = post;

	// 	if (isPostPinned) {
	// 		unpinPost({ boardId, postId });
	// 	} else {
	// 		pinPost({ boardId, postId });
	// 	}
	// };

	// ! ============ EDIT HANDLER ============

	const isEditing = true;
	const { post, setPostId, showModal, setShowModal } = usePostById(isEditing);

	// ! ============ POST SHARE ============

	const postRef = useRef();

	const handleShare = (post) => {
		if (post.sharedPost) {
			postRef.current.sharePost(post.sharedPost);
		} else {
			postRef.current.sharePost(post);
		}
	};

	// !=======================================

	const itemsList = useMemo(
		() =>
			isPaginated
				? data?.pages?.map((page) =>
						page?.list?.map((post, i) => (
							<PostItem
								key={post.uuid}
								ref={
									page.list.length === i + 1 ? viewRef : null
								}
								post={post}
								canEditBoard={post.board?.moderator}
								isPinned={post.pinned}
								onItemClicked={handleItemClicked(post)}
								onDelete={() => deletePost(post.uuid)}
								onPostUpdate={onRefresh}
								onEdit={(post) => setPostId(post.uuid)}
								onShare={handleShare}
								// onTogglePin={() => {
								// 	handleTogglePin(post);
								// }}
							/>
						))
				  )
				: data?.map((post) => (
						<PostItem
							key={post.uuid}
							post={post}
							canEditBoard={post.board?.moderator}
							isPinned={post.pinned}
							onItemClicked={handleItemClicked(post)}
							onDelete={() => deletePost(post.uuid)}
							onPostUpdate={onRefresh}
							onEdit={(post) => setPostId(post.uuid)}
							onShare={handleShare}
							// onTogglePin={() => handleTogglePin(post)}
						/>
				  )),
		[data]
	);

	const handleReplaceUrl = () => {
		window.history.replaceState(null, "", `/search?q=${searchInput}`);
	};

	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			<div className={styles.title_wrapper}>
				<h4>{`${count} ${pluralize(count, "Post")} found`}</h4>
				{isMobile && isPaginated && (
					<button onClick={onShowModal}>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList?.length > 0 && (
				<div className={styles.active_filters}>{tagsList}</div>
			)}

			<div className={styles.container}>
				<div className={styles.center_section}>
					{itemsList}
					{isFetching && <PostLoader />}
				</div>
			</div>

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text="See more" />
				</div>
			)}

			<CreatePost
				ref={postRef}
				name={type === "USER" ? `${firstName} ${lastName}` : name}
				profileImg={profileImage}
				type={type}
				onCreate={onRefresh}
			/>

			<EditPostModal
				existingPost={post.data}
				name={type === "USER" ? `${firstName} ${lastName}` : name}
				profileImg={profileImage}
				type={type}
				url={url}
				show={showModal}
				toggleShow={(bool) => setShowModal(bool)}
				onInvalidate={onRefresh}
				// onSaveEdit={onRefresh}
				// onDeleteMedia={onRefresh}
				// onDeleteAllMedia={onRefresh}
				onClose={() => setPostId("")}
			/>

			<FullScreenModal
				show={postModal}
				onClose={() => {
					handleCloseModal();
					handleReplaceUrl();
				}}
				fullWidth={true}
				children={
					<PostFullScreen
						postId={postId}
						subPostId={subPostId}
						initialSlideIndex={subPostIndex}
						isModal={true}
						onClose={handleCloseModal}
						onRefresh={onRefresh}
						onUpdateUrl={handleReplaceUrl}
					/>
				}
			/>
		</>
	);
};

export default Posts;
