import client from "./client";

const endpoint = "/emotions";

const getEmotions = () => client.get(endpoint);
const searchEmotions = (query) => client.get(endpoint + `/search?q=${query}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getEmotions,
    searchEmotions,
};
