import { classNames } from "primereact/utils";
import styles from "./CtaButton.module.scss";

const CtaButton = ({ text, onClick, className, disabled = false }) => {
	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={classNames(styles.container, className, {
				[styles.disabled]: disabled,
			})}
		>
			{text}
		</button>
	);
};

export default CtaButton;
