import { forwardRef } from "react";
import styles from "./EditButton.module.scss";
import SecondaryButton from "./SecondaryButton";
import { ReactComponent as PencilIcon } from "images/pencil.svg";

const EditButton = forwardRef(({ onClick, className }, ref) => {
	return (
		<SecondaryButton
			ref={ref}
			text={
				<>
					<PencilIcon fill="#6cc5d1" />
					Edit
				</>
			}
			className={`${className} ${styles.btn}`}
			onClick={onClick}
		/>
	);
});

export default EditButton;
