import useApi from "hooks/useApi";
import productApi from "api/product";
import React, { useMemo } from "react";
import Tag from "components/Utils/SubComs/Tags/Tag";

export const useColors = (colorsPerPage, colorsWatcher, setValue) => {
	const searchColorsApi = useApi(productApi.searchProductColors, true);

	const searchColors = async ({ pageParam = 0, queryKey, signal }) => {
		const [, debouncedSearch] = queryKey;
		const response = await searchColorsApi.request(
			pageParam,
			colorsPerPage,
			debouncedSearch
		);
		return response.data;
	};

	const handleDeleteColor = (index) => {
		const updatedArray = colorsWatcher.filter((color, i) => i !== index);
		setValue("colors", updatedArray);
	};

	const colorsList = useMemo(
		() =>
			colorsWatcher?.map((color, index) => (
				<Tag
					key={color.value}
					itemName={color.label}
					onRemove={() => handleDeleteColor(index)}
				/>
			)),
		[colorsWatcher]
	);
	return { colorsList, searchColors };
};
