import styles from "./CollapsibleContainer.module.scss";
import { useCollapse } from "react-collapsed";
import { ReactComponent as ForwardIcon } from "images/arrow-icon.svg";
import { classNames } from "primereact/utils";

const CollapsibleContainer = ({ defaultExpanded = true, title, children }) => {
	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
		defaultExpanded,
	});

	return (
		<div className={styles.container}>
			<button {...getToggleProps()}>
				<h3>{title}</h3>
				<ForwardIcon
					className={classNames(styles.forward_icon, {
						[styles.rotate]: isExpanded,
					})}
				/>
			</button>
			<section {...getCollapseProps()}>{children}</section>
		</div>
	);
};

export default CollapsibleContainer;
