import React from "react";
import styles from "./MediaList.module.css";
import { getFileIcon } from "../Utils/General";
import ReactPlayer from "react-player/lazy";
import LazyImage from "../Utils/LazyImage/LazyImage";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import closeIcon from "images/close-icon-black-20-x-20.svg";

const MediaList = ({
	mediaType,
	url,
	fileName,
	onClick,
	onEdit,
	singleMedia = false,
}) => {
	return (
		<div className={styles.media_viewer_item}>
			{!singleMedia && (
				<div
					className={`${styles.create_post_media_controllers} ${
						mediaType === "VIDEO" && styles.video
					}`}
				>
					<button
						className={styles.edit_post_media_button}
						onClick={onEdit}
					>
						<img src={editIcon} alt={"edit"} />
						<label>Edit</label>
					</button>

					<button
						className={styles.remove_post_media_button}
						onClick={onClick}
					>
						<img src={closeIcon} alt={"close"} />
					</button>
				</div>
			)}

			{mediaType === "IMAGE" && (
				<LazyImage
					image={<img src={url} alt={fileName} />}
					inlineStyle={styles.lazy_img}
				/>
			)}
			{mediaType === "VIDEO" && (
				<ReactPlayer
					width={"100%"}
					height={"100%"}
					url={url}
					playing={false}
				/>
			)}

			{mediaType !== "VIDEO" && mediaType !== "IMAGE" && (
				<div className={styles.doc_icon_container}>
					<img
						src={getFileIcon("", fileName).icon}
						alt={fileName}
						className={styles.doc_icon}
					/>
					<span>{fileName}</span>
				</div>
			)}
		</div>
	);
};

export default MediaList;
