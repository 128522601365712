import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../../store/actionCreators/api";

const slice = createSlice({
    name: "certification",
    initialState: {
        // list: [],
        instituteList: [],
    },
    reducers: {
        // updateCertificates: (state, action) => {
        //     state.list = action.payload;
        // },
        searchInstitute: (state, action) => {
            state.instituteList = action.payload;
        },
    },
});

export const { searchInstitute } = slice.actions;
export default slice.reducer;

// action creators
// TODO A SUPPRIMER >> not needed anymore
// export const getCertificates = () =>
//     apiActions.apiCallBegan({
//         url: "/certificationType",
//         method: "GET",
//         onSuccess: updateCertificates.type,
//     });

// export const certificationSearch = (query) =>
//     apiActions.apiCallBegan({
//         url: `/certificationType/search?q=${query}`,
//         method: "GET",
//         onSuccess: searchCertification.type,
//     });

export const institutionSearch = (query) =>
    apiActions.apiCallBegan({
        url: `/institution/certificate/search?name=${query}`,
        method: "GET",
        onSuccess: searchInstitute.type,
    });
