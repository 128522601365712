import styles from "./ProgressIndicator.module.css";

const ProgressIndicator = ({ step }) => {
    return (
        <div className={styles.container}>
            <div className={styles.stroke}></div>
            <div
                className={`${styles.step_item} ${
                    step === 1 ? styles.active : undefined
                } ${step === 2 || step === 3 ? styles.validated : undefined}`}
            >
                <span></span>
            </div>
            <div
                className={`${styles.step_item} ${
                    step === 2 ? styles.active : undefined
                }
                ${step === 3 ? styles.validated : undefined}`}
            >
                <span></span>
            </div>
            <div
                className={`${styles.step_item} ${
                    step === 3 ? styles.active : undefined
                }`}
            >
                <span></span>
            </div>
        </div>
    );
};

export default ProgressIndicator;
