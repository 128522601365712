import { useEffect, useMemo } from "react";
import styles from "./PeopleAndCompanies.module.scss";
import GroupMember from "../../Chat/GroupMember";
import { useInView } from "react-intersection-observer";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import filterIcon from "images/filter-icon-white.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

const PeopleAndCompanies = ({
	count,
	data,
	isCompany = false,
	isPaginated = false,
	onLoad,
	hasNextPage,
	isFetching,
	isLoading,
	isMobile,
	onShowModal,
	onClick,
	tagsList,
}) => {
	const { ref: viewRef, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			onLoad();
		}
	}, [inView, hasNextPage, onLoad, isFetching]);

	const itemsList = useMemo(
		() =>
			isPaginated
				? data?.pages?.map((page) =>
						page?.list?.map((user, i) => (
							<div
								className={styles.contact_item}
								key={user.uuid}
								ref={
									page.list.length === i + 1 ? viewRef : null
								}
							>
								<GroupMember data={user} redirect={true} />
							</div>
						))
				  )
				: data?.map((user) => (
						<div className={styles.contact_item} key={user.uuid}>
							<GroupMember data={user} redirect={true} />
						</div>
				  )),
		[data, isPaginated, viewRef]
	);

	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			<div className={styles.title_wrapper}>
				{isCompany ? (
					<h4>{`${count} ${
						count > 1 ? "Companies" : "Company"
					} found`}</h4>
				) : (
					<h4>{`${count} ${
						count > 1 ? "People" : "Person"
					} found`}</h4>
				)}

				{isMobile && isPaginated && (
					<button
						className={styles.filter_button}
						onClick={onShowModal}
					>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList?.length > 0 && (
				<div className={styles.active_filters}>{tagsList}</div>
			)}

			<div>{itemsList}</div>
			{isFetching && <LoadingSpinner />}

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text="See more" />
				</div>
			)}
		</>
	);
};

export default PeopleAndCompanies;
