import Footer from "components/Utils/Footer/Footer";
import MainHeader from "components/Utils/SubComs/MainHeader/MainHeader";
import HeaderBar from "../../SubComs/HeaderBar/HeaderBar";
import LeftSide from "../../SubComs/Left-Side/LeftSide";
import RightSide from "../../SubComs/RightSide/RightSide";
import BottomNavBar from "../../SubComs/bottomNavBar/BottomNavBar";
import styles from "./DefaultPage.module.css";

const DefaultPage = ({
	leftSideChildren,
	rightSideChildren,
	onScroll,
	headerRightContent,
	rightSideBgColor,
	rightSideFullWidth = false,
	hasMobileHeader = true,
	defaultHeader = true,
	disableScrollbar = false,
	bannerComponent,
	showFooter = true,
	rightSideContainerColor = undefined,
	defaultBannerHeight = undefined,
}) => {
	return (
		<>
			{defaultHeader ? (
				<MainHeader />
			) : (
				<HeaderBar
					headerRightContent={headerRightContent}
					hasMobileHeader={hasMobileHeader}
				/>
			)}

			<div className={styles.container}>
				<LeftSide children={leftSideChildren} />

				<RightSide
					bannerComponent={bannerComponent}
					defaultBannerHeight={defaultBannerHeight}
					disableScrollbar={disableScrollbar}
					onScroll={onScroll}
					children={
						<div
							className={styles.right_side_container}
							style={{ backgroundColor: rightSideContainerColor }}
						>
							{rightSideChildren}
							{showFooter && <Footer />}
						</div>
					}
					rightSideBgColor={rightSideBgColor}
					rightSideFullWidth={rightSideFullWidth}
				/>
			</div>

			{/* Show only in mobile mode (below 748px) because bottomBar in left pannel will be hidden */}
			<div className={styles.bottomNavBar}>
				<BottomNavBar />
			</div>
		</>
	);
};

export default DefaultPage;
