import client from "./client";

const endpoint = "/skills";

const getSkills = () => client.get(endpoint);
const searchSkills = (page, size = 20, q = "") =>
	client.get(endpoint + `/search?page=${page}&size=${size}&q=${q}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getSkills,
	searchSkills,
};
