export const specialProfileKeys = {
	all: ["specialProfile"],
	lists: (userId) => [
		...specialProfileKeys.all,
		"listSpecialProfiles",
		userId,
	],
	details: () => [...specialProfileKeys.all, "details"],
	detail: (url, agencyId = "") => [
		...specialProfileKeys.details(),
		url,
		agencyId,
	],
	medias: (uuid) => [...specialProfileKeys.all, "medias", uuid],
	getTalents: (uuid, confirmed = true, type = "N_A") => [
		...specialProfileKeys.all,
		"talents",
		uuid,
		confirmed,
		type,
	],
};
