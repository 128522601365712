import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import calendarApi from "../api/calendar";
import useApi from "./useApi";

const useCalendarEntries = (
	profileId,
	existingData = undefined,
	disabled = false
) => {
	//TODO > use react-query

	const getEntriesApi = useApi(calendarApi.getEntries);
	const deleteEntryApi = useApi(calendarApi.deleteEntry, true);

	const [calendarEntries, setCalendarEntries] = useState([]);

	const handleMonthChange = (date) => {
		getEntriesApi.request(date.getMonth(), date.getFullYear(), profileId);
	};

	const handleDeleteEntry = async (event) => {
		const response = await deleteEntryApi.request(event.uuid);
		if (!response.ok) toast.error("Something wrong happened");
		if (response.status === 200)
			setCalendarEntries((prevState) =>
				prevState.filter((entry) => entry.uuid !== event.uuid)
			);
	};

	useEffect(() => {
		if (!disabled) {
			if (existingData) {
				setCalendarEntries(existingData);
			} else {
				getEntriesApi.request(
					new Date().getMonth(),
					new Date().getFullYear(),
					profileId
				);
			}
		}
	}, [profileId]);

	useEffect(() => {
		if (
			!getEntriesApi.loading &&
			getEntriesApi.data &&
			getEntriesApi.responseCode === 200
		) {
			setCalendarEntries(getEntriesApi.data);
		}
	}, [getEntriesApi.data]);

	return { calendarEntries, handleMonthChange, handleDeleteEntry };
};

export default useCalendarEntries;
