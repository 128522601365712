import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./ManageFollowers.module.scss";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useMutate from "hooks/useMutate";
import boardsApi from "api/boards";
import { useQueryClient } from "@tanstack/react-query";
import { boardsKeys } from "queryKeys/boards-key-factory";
import { useEffect, useMemo, useState } from "react";
import useDebounce from "hooks/useDebounce";
import { useInView } from "react-intersection-observer";
import useApi from "hooks/useApi";
import usePaginatedResource from "hooks/usePaginatedResource";
import GroupMember from "components/Chat/GroupMember";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import { ReactComponent as RemoveIcon } from "images/remove.svg";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import Tag from "components/Utils/SubComs/Tags/Tag";

const itemsPerPage = 20;

const ManageFollowers = ({
	show,
	boardId,
	appointModeratorEnabled = false,
	onAddModerator,
	onClose,
	onBack,
	canEdit = false,
}) => {
	const queryClient = useQueryClient();

	// !==================== ADD MODERATORS =========================

	const {
		action: { mutate: addModerator },
	} = useMutate(boardsApi.addModerator, () => {
		queryClient.invalidateQueries(boardsKeys.moderators(boardId));
		onAddModerator && onAddModerator();
	});

	// !==================== REMOVE FOLLOWER =========================
	const [showWarning, setShowWarning] = useState(false);
	const [activeFollower, setActiveFollower] = useState("");

	const handleOpenModal = (follower) => {
		setActiveFollower(follower);
		setShowWarning(true);
	};

	const {
		action: { mutate: removeFollower },
	} = useMutate(boardsApi.deleteFollower, () => {
		queryClient.invalidateQueries(
			boardsKeys.followers(boardId, debouncedSearch)
		);
		queryClient.invalidateQueries(boardsKeys.detail(boardId));
		setShowWarning(false);
	});

	// !==================== GET FOLLOWERS  =========================
	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const { ref: followersViewRef, inView } = useInView({
		triggerOnce: true,
	});

	const getBoardFollowersApi = useApi(
		boardsApi.getBoardFollowers,
		true,
		true
	);

	const getFollowers = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, boardId, query] = queryKey;

		const response = await getBoardFollowersApi.request(
			boardId,
			pageParam,
			itemsPerPage,
			query,
			appointModeratorEnabled ? true : false
		);

		return response.data;
	};

	const {
		data: followers,
		isFetching,
		hasNextPage,
		fetchNextPage,
	} = usePaginatedResource(
		boardsKeys.followers(boardId, debouncedSearch),
		getFollowers,
		itemsPerPage,
		show
	);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const followersList = useMemo(
		() =>
			followers?.pages?.map((page) =>
				page.map(({ profile, moderator }, i) => (
					<GroupMember
						// ref={followersViewRef}
						ref={page.length === i + 1 ? followersViewRef : null}
						key={profile.uuid}
						data={profile}
						redirect={!appointModeratorEnabled}
						forceShow={true}
						disabled={appointModeratorEnabled && moderator}
						onClick={() =>
							appointModeratorEnabled &&
							addModerator({ boardId, userId: profile.uuid })
						}
						customStyle={styles.contact_item}
						rightSideAction={
							canEdit && !appointModeratorEnabled ? (
								<button
									onClick={() => handleOpenModal(profile)}
								>
									<RemoveIcon />
								</button>
							) : canEdit &&
							  appointModeratorEnabled &&
							  moderator ? (
								<Tag itemName="Moderator" />
							) : undefined
						}
					/>
				))
			),
		[followers, appointModeratorEnabled, boardId, canEdit]
	);

	return (
		<GaawkModal
			show={show}
			title={"Board Followers"}
			closeAlign={appointModeratorEnabled ? "both" : "right"}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			backPress={onBack}
			children={
				<div className={styles.container}>
					{appointModeratorEnabled && (
						<div className={styles.info_text}>
							Only followers of the board can be appointed as
							moderators.
						</div>
					)}

					<SearchInput
						onChange={handleSearchInputChange}
						value={searchInput}
						showIcons={true}
						onClearClicked={() => setSearchInput("")}
						border={false}
					/>

					<div className={styles.items_wrapper}>{followersList}</div>

					<LoadingSpinner visible={isFetching} />

					<NoResults
						isSearch={debouncedSearch}
						text={
							debouncedSearch
								? "No results related to your search"
								: "No Followers"
						}
						visible={
							!isFetching && followers?.pages?.[0]?.length === 0
						}
					/>

					<WarningModal
						show={showWarning}
						modalOnTop={true}
						headerText={`Are you sure you want to remove ${activeFollower.name} as a follower in the board? They'll no longer be part of it.`}
						warningText="This action cannot be undone."
						cancelButtonText={"Cancel"}
						onCancelButtonClicked={() => setShowWarning(false)}
						submitButtonText={"remove"}
						onSubmitButtonClicked={() =>
							removeFollower({
								boardId,
								userId: activeFollower.uuid,
							})
						}
					/>
				</div>
			}
		/>
	);
};

export default ManageFollowers;
