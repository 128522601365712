import { useQuery } from "@tanstack/react-query";
import styles from "./RecentConnections.module.scss";
import useApi from "hooks/useApi";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import profileApi from "api/profile";
import ConnectionItem from "./ConnectionItem";
import useDirectMessage from "hooks/useDirectMessage";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";

const RecentConnections = () => {
	const recentConnectionsApi = useApi(
		profileApi.getRecentConnections,
		true,
		true
	);

	const fetchRecent = async () => {
		const response = await recentConnectionsApi.request(0, 5);
		return response.data;
	};

	const { data } = useQuery({
		queryKey: connectionsKeys.recent(),
		queryFn: fetchRecent,

		staleTime: 30000, // 5 minutes
	});

	const { handleDirectMessage } = useDirectMessage();

	const recentList = data?.map((profile) => (
		<ConnectionItem
			key={profile.uuid}
			data={profile}
			rightSideAction={
				<button
					className={styles.directMessageBtn}
					type="button"
					onClick={() => handleDirectMessage(profile)}
				>
					Send Message
				</button>
			}
		/>
	));

	if (!data || data?.length === 0) return null;

	return (
		<ProfileTabContainer customStyle={styles.section_container}>
			<h3>Recently Connected</h3>
			<div className={styles.items_container}>{recentList}</div>
		</ProfileTabContainer>
	);
};

export default RecentConnections;
