import styles from "./JobDates.module.scss";
import { format } from "date-fns";

const JobDates = ({ startDate, endDate }) => {
	return (
		<div className={styles.project_dates}>
			{startDate > 0 && (
				<p>
					Start:{" "}
					<span>{format(new Date(startDate), "dd/MM/yyyy")}</span>
				</p>
			)}
			{endDate > 0 && (
				<p>
					End: <span>{format(new Date(endDate), "dd/MM/yyyy")}</span>
				</p>
			)}
		</div>
	);
};

export default JobDates;
