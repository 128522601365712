export const jobsKeys = {
	all: ["jobs"],

	landing: () => [...jobsKeys.all, "landing"],

	lists: () => [...jobsKeys.all, "listJobs"],

	details: () => [...jobsKeys.all, "details"],

	detail: (jobId) => [...jobsKeys.details(), jobId],

	searchList: (query, locationId) => [
		...jobsKeys.lists(),
		"search",
		query,
		locationId,
	],

	recommended: (userCoordinates) => [
		...jobsKeys.lists(),
		"recommended",
		userCoordinates,
	],

	nearby: (userCoordinates) => [
		...jobsKeys.lists(),
		"nearby",
		userCoordinates,
	],

	saved: (jobType) => [...jobsKeys.lists(), "saved", jobType],
	applied: (jobType) => [...jobsKeys.lists(), "applied", jobType],

	jobsByCompany: (
		companyId,
		jobType,
		closed,
		excludeId = "",
		projectId = ""
	) => [
		...jobsKeys.lists(),
		companyId,
		jobType,
		closed,
		excludeId,
		projectId,
	],

	similarJobs: (jobId) => [...jobsKeys.lists(), "similar", jobId],
	jobApplicants: (jobId, statusId) => [
		...jobsKeys.all,
		"applicants",
		jobId,
		statusId,
	],
	applicationsCount: (jobId) => [...jobsKeys.all, "applicationsCount", jobId],
	applicant: (applicantId) => [...jobsKeys.all, "applicant", applicantId],

	// search: (userId, query) => [...servicesKeys.searchList(), userId, query],

	// serviceLvl: (level, userId, parentId) =>
	//     [...servicesKeys.lists(), { level }, userId, parentId].filter(
	//         (item) => item !== undefined
	//     ),

	// details: () => [...servicesKeys.all, "details"],

	// infinitMedias: (serviceId) => [
	//     ...servicesKeys.details(),
	//     "mediasInfinit",
	//     serviceId,
	// ],

	// countableMedias: (serviceId) => [
	//     ...servicesKeys.details(),
	//     "mediasCountable",
	//     serviceId,
	// ],

	// products: (serviceId) => [...servicesKeys.details(), "products", serviceId],

	// detail: (serviceId) => [...servicesKeys.details(), serviceId],

	// suggested: () => [...servicesKeys.lists(), "suggested"],
};
