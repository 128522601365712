import client from "./client";

const endpoint = "/industry";

const search = (page, size, q) =>
	client.get(endpoint + `/search?page=${page}&size=${size}&q=${q}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	search,
};
