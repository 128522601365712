import { useEffect, useState, useMemo, useRef } from "react";
import styles from "./MediaContainer.module.css";
import chatApi from "../../api/chat";
import useApi from "../../hooks/useApi";
import { viewerMapper } from "../Utils/General";
import FileItem from "../Vault/FileItem";
import FullScreenModal from "../Utils/GaawkModal/FullScreenModal";
import FileViewer from "../Utils/FileViewer/FileViewer";
import ViewerHeader from "../Utils/FileViewer/ViewerHeader";
import ShowChatProfile from "./ShowChatProfile";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import LoadingSpinner from "../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import fullscreenIcon from "images/fullscreen-icon-white.svg";
import { ReactComponent as GhostIcon } from "images/ghost.svg";

const itemsPerPage = 50;

const MediaContainer = ({ conversation, filesCount, p2p = false }) => {
	const getMediaApi = useApi(chatApi.getMedia, true, true);

	const fileviewerRef = useRef();

	const { ref: viewRef, inView } = useInView();

	const fetchSearch = async ({ pageParam = 0, signal }) => {
		const response = await getMediaApi.request(
			conversation.uuid,
			pageParam,
			itemsPerPage
		);
		return response.data;
	};

	const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
		queryKey: ["chatMedia", conversation.uuid],
		queryFn: fetchSearch,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	const [showViewer, setShowViewer] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	// console.log("THE MEDIA CONTAINER >>", data);

	const mediaList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((item, i) => {
					//* >> "if" condition to be removed when Waleed will fix the ability to see previous messages when being added to a group after that media files were sent
					if (item.media) {
						return (
							<div
								key={item.media.uuid}
								ref={
									// hasNextPage && page.length === i + 1
									// 	? viewRef
									// 	: null
									page.length === i + 1 ? viewRef : null
								}
								className={`${styles.media_files_item} ${
									p2p ? styles.p2p : undefined
								}`}
								onClick={() => handleFileClick(i)}
							>
								<FileItem
									// item={getFileIcon(
									// 	item.media.file,
									// 	item.media.name
									// )}
									p2p={p2p}
									item={item.media}
									isChat={true}
								/>
							</div>
						);
					} else
						return (
							<div
								className={`${styles.media_files_item} ${
									p2p ? styles.p2p : undefined
								} ${styles.no_media}`}
								key={i}
							>
								<GhostIcon />
								<p>Media not available</p>
							</div>
						);
				})
			),
		[data, p2p, hasNextPage, viewRef]
	);

	const mediaListViewer = useMemo(
		() => viewerMapper(data.pages.flat()),
		[data]
	);

	const handleFileClick = (index) => {
		setInitialSlideIndex(index);
		setShowViewer(true);
	};

	const handleLoadMore = () => {
		if (hasNextPage && mediaListViewer.length < conversation.filesCount) {
			fetchNextPage();
		}
	};

	return (
		<>
			{data?.pages[0].length > 0 && (
				<div
					className={`${styles.media_container}  ${
						p2p ? styles.p2p : undefined
					}`}
				>
					<div className={styles.media_container_title}>
						<h2>Media &amp; Files</h2>
						<span>{`${filesCount} files`}</span>
					</div>

					<div
						className={`${styles.grid_container} ${
							!p2p ? styles.groupPage : undefined
						}`}
					>
						{/* <div className={styles.media_files_container}> */}
						{mediaList}
					</div>
					<LoadingSpinner visible={isFetching} />
				</div>
			)}

			<FullScreenModal
				show={showViewer}
				fullWidth={true}
				onClose={() => setShowViewer(false)}
				header={
					<ViewerHeader
						onClose={() => setShowViewer(false)}
						leftSide={
							<ShowChatProfile
								data={conversation}
								fontColor={"#FFF"}
								// typing={selectedConveration?.typing}
								// isGroup={conversation.groupDTO}
								// totalparticipants={totalParticipants}
								handleProfileClick={() => {
									setShowViewer(false);
								}}
							/>
						}
						rightSide={
							<button>
								<img
									src={fullscreenIcon}
									className={styles.fullscreen_icon}
									alt="Fullscreen"
									onClick={() =>
										fileviewerRef.current.enterFullscreen()
									}
								/>
							</button>
						} //TODO ADD 3 DOTS 'MORE OPTIONS'
					/>
				}
				children={
					<div className={styles.fileviewer_container}>
						<FileViewer
							ref={fileviewerRef}
							files={mediaListViewer}
							disableScrollToEnd={true}
							initialSlide={initialSlideIndex}
							onLoadMore={handleLoadMore}
							isLoading={getMediaApi.loading}
							onActiveSlide={(index) =>
								setActiveSlideIndex(index)
							}
							text={mediaListViewer[activeSlideIndex]?.message}
						/>
					</div>
				}
			/>
		</>
	);
};

export default MediaContainer;
