import { useEffect, useState } from "react";
import usePaginatedData from "./usePaginatedData";

const useFilteredData = (
	searchApi,
	filterApi,
	itemsPerPage = 10,
	optionalId = false,
	agenciesOnly
) => {
	const [filters, setFilters] = useState({});

	const [dynamicFilters, setDynamicFilters] = useState({});

	const [selectedStaticFilters, setSelectedStaticFilters] = useState([]);

	const [selectedDynamicFilters, setSelectedDynamicFilters] = useState([]);

	const { data, loadMore, hasMore, loading, setData, reload } =
		usePaginatedData(searchApi, itemsPerPage, optionalId);

	const handleLoadData = (
		id = undefined,
		page = undefined,
		filtersObject = filters,
		isLoadMore = false
	) => {
		if (!isLoadMore) setData([]);
		loadMore(
			id,
			page,
			filtersObject,
			agenciesOnly
			// lat, //TODO if location field is available
			// lng, //TODO if location field is available
		);
	};

	const handleReloadData = (
		id = undefined,
		page = undefined,
		filtersObject = filters
	) => {
		reload(id, page, filtersObject, agenciesOnly);
	};

	const handleLoadFilters = (id = undefined, filtersObject = filters) => {
		filterApi.request(id, filtersObject, agenciesOnly);
		// if (id || optionalId) filterApi.request(id, filtersObject);
		// else filterApi.request(filtersObject);
	};

	useEffect(() => {
		if (
			!filterApi.loading &&
			filterApi.responseCode === 200 &&
			filterApi.data
		) {
			const response = filterApi.data;

			const values = Object.values(response);
			const responseHasValues = values.some((item) => item.length > 0);

			if (responseHasValues) setDynamicFilters(filterApi.data);
		}
	}, [filterApi.data]);

	const handleResetFilters = () => {
		setFilters({});
		setSelectedStaticFilters([]);
		setSelectedDynamicFilters([]);
	};

	const handleFilterChange = (selectedFilters) => {
		setSelectedStaticFilters(selectedFilters);

		const formattedFilters = {};

		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value)) {
				formattedFilters[key] = value.map((x) => x.value);
			} else {
				formattedFilters[key] = value;
			}
		}

		setFilters((prevState) => ({
			...prevState,
			...formattedFilters,
		}));
	};

	const handleDynamicFilters = (selectedFilters) => {
		setSelectedDynamicFilters(selectedFilters);

		let formattedFilters = {};

		for (const [key, values] of Object.entries(selectedFilters)) {
			if (Array.isArray(values) && values.length > 0) {
				formattedFilters[key] = values.map((item) => item.value);
			} else {
				formattedFilters[key] = [];
			}
		}

		setFilters((prevState) => ({
			...prevState,
			...formattedFilters,
		}));
	};

	return {
		filters,
		dynamicFilters,
		selectedStaticFilters,
		selectedDynamicFilters,
		data,
		setData,
		loading,
		hasMore,
		handleLoadData,
		handleLoadFilters,
		handleResetFilters,
		handleFilterChange,
		handleDynamicFilters,
		handleReloadData,
	};
};

export default useFilteredData;
