import { useNavigate } from "react-router-dom";

import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import GalleryFileUploader from "../../components/Profile/GalleryTab/GalleryFileUploader";

import BackButton from "../../components/Utils/Button/BackButton";

import styles from "../Header.module.css";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";

const AlbumUploaderView = () => {
	const navigate = useNavigate();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Add Files
				</div>
			}
			defaultHeader={false}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={<GalleryFileUploader />}
		/>
	);
};

export default AlbumUploaderView;
