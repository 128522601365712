import React, { useRef, useState } from "react";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "./BoardsView.module.css";
import BackButton from "../../components/Utils/Button/BackButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Boards from "../../components/More/BoardsPage/Boards";
import Popover from "../../components/Utils/Popover/Popover";
import optionIcon from "images/three-dots-white-5-x-20.svg";
import copyIcon from "images/copy-icon-gray.svg";
import postIcon from "images/edit-icon-20-x-20-black.svg";
import editIcon from "images/edit-black.svg";
import deleteIcon from "images/delete-icon-black-20-x-20.svg";

import {
	copyToClipboard,
	featuresSection,
} from "../../components/Utils/General";
import { toast } from "react-toastify";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import reportIcon from "images/report.svg";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import { useSelector } from "react-redux";
import useCompanyId from "hooks/useCompanyId";
import ManageBoards from "components/More/BoardsPage/ManageBoards";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import BoardDetails from "components/Boards/BoardDetails/BoardDetails";
import { ReactComponent as BoardIcon } from "images/board.svg";

const BoardsView = () => {
	const navigate = useNavigate();
	const reportRef = useRef();

	const companyId = useCompanyId();

	const { uuid: profileId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const { pathname } = useLocation();

	const { boardId } = useParams();
	const popoverRef = useRef(null);
	const boardsRef = useRef(null);
	const boardDetailsRef = useRef(null);

	const [countryFlag, setCountryFlag] = useState();
	const [board, setBoard] = useState();
	const isBoardOwner = profileId === board?.owner?.uuid;

	let component;
	let headerBtn;

	switch (pathname) {
		case "/boards":
			component = (
				<Boards ref={boardsRef} onFlagChange={setCountryFlag} />
			);
			headerBtn = (
				<button onClick={() => boardsRef.current.showModal()}>
					<img src={countryFlag} alt="" />
				</button>
			);
			break;
		case "/boards/manage":
			component = <ManageBoards />;

			break;
		case `/boards/${boardId}`:
			component = (
				<BoardDetails ref={boardDetailsRef} onLoad={setBoard} />
			);
			headerBtn = (
				<Popover
					ref={popoverRef}
					render={
						<div className="popover_container">
							{(board?.moderator || isBoardOwner) && (
								<button
									className={`popover_item ${styles.add_gap}`}
									onClick={() => {
										boardDetailsRef.current.editBoard();
										popoverRef.current.closePopover();
									}}
								>
									<div className={styles.icon_wrapper}>
										<img
											src={editIcon}
											className={styles.more_icon}
											alt=""
										/>
									</div>
									<span>Edit Board</span>
								</button>
							)}

							{(board?.moderator ||
								board?.follower ||
								isBoardOwner) && (
								<button
									className={`popover_item ${styles.add_gap}`}
									onClick={() => {
										boardDetailsRef.current.showPostModal();
										popoverRef.current.closePopover();
									}}
								>
									<div className={styles.icon_wrapper}>
										<img
											src={postIcon}
											className={styles.more_icon}
											alt=""
										/>
									</div>
									<span>Post To Board</span>
								</button>
							)}

							{isBoardOwner && (
								<button
									className={`popover_item ${styles.add_gap}`}
									onClick={() => {
										boardDetailsRef.current.manageModerators();
										popoverRef.current.closePopover();
									}}
								>
									<BoardIcon fill={"#000"} width={14} />

									<span>Manage moderators</span>
								</button>
							)}
							{board?.moderator && (
								<button
									className={`popover_item ${styles.add_gap}`}
									onClick={() => {
										boardDetailsRef.current.moderatorLeave();
										popoverRef.current.closePopover();
									}}
								>
									<BoardIcon fill={"#000"} width={14} />

									<span>Leave your role as moderator</span>
								</button>
							)}

							<button
								className={`popover_item ${styles.add_gap}`}
								onClick={() => {
									copyToClipboard(
										`${window.location.origin}/boards/${board.uuid}`
									);
									toast.success("Link copied !");
									popoverRef.current.closePopover();
								}}
							>
								<div className={styles.icon_wrapper}>
									<img
										src={copyIcon}
										className={styles.more_icon}
										alt=""
									/>
								</div>
								<span>Copy Board Link</span>
							</button>

							{isBoardOwner && (
								<button
									className={`popover_item ${styles.add_gap}`}
									onClick={() => {
										boardDetailsRef.current.deleteBoard();
										popoverRef.current.closePopover();
									}}
								>
									<div className={styles.icon_wrapper}>
										<img
											src={deleteIcon}
											className={styles.more_icon}
											alt=""
										/>
									</div>
									<span>Delete Board</span>
								</button>
							)}

							<button
								className="popover_item"
								tabIndex={0}
								onClick={(e) => {
									e.stopPropagation();
									reportRef.current.showReportModal(boardId);
									popoverRef.current.closePopover();
								}}
							>
								<span className="img-sm">
									<img src={reportIcon} alt={"report"} />
								</span>
								<span>Report board</span>
							</button>
						</div>
					}
				>
					<button>
						<img src={optionIcon} alt="options" />
					</button>
				</Popover>
			);
			break;

		default:
			break;
	}

	return (
		<>
			<DefaultPage
				headerRightContent={
					<>
						<div className={styles.header}>
							<BackButton onBack={() => navigate(-1)} />
							Boards
						</div>
						{headerBtn}
					</>
				}
				defaultHeader={false}
				leftSideChildren={
					pathname === "/boards/manage" ? (
						<HomeLeftSidebar />
					) : (
						<FeaturesSidebar active={featuresSection.boards} />
					)
				}
				rightSideChildren={component}
				rightSideBgColor={"#FFF"}
			/>

			<ReportModal ref={reportRef} category="board" />
		</>
	);
};

export default BoardsView;
