// import { splitName } from "../components/Utils/General";
import { router } from "AppRoutes";

const useDirectMessage = () => {
	const handleDirectMessage = (profile, message = "") => {
		const { name, firstName, lastName, ...otherProps } = profile;
		// const { firstName, lastName } = splitName(name);

		router.navigate("/chat", {
			state: {
				name: name ?? `${firstName} ${lastName}`,
				...otherProps,
				message,
			},
		});
	};

	return { handleDirectMessage };
};

export default useDirectMessage;
