import { useQueryClient } from "@tanstack/react-query";
import useMutate from "hooks/useMutate";
import productApi from "api/product";
import { productsKeys } from "queryKeys/products-key-factory";
import { servicesKeys } from "queryKeys/services-key-factory";
import { useNavigate } from "react-router-dom";

export const useDeleteProduct = (shouldNavigate = false) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	// ! ==== DELETE HANDLERS ======
	const handleInvalidateQuery = () => {
		queryClient.invalidateQueries(productsKeys.lists());
		queryClient.invalidateQueries(servicesKeys.lists());
		queryClient.removeQueries(productsKeys.detail(activeId));
		shouldNavigate && navigate("/profile", { replace: true });
	};
	const {
		warningModal,
		setWarningModal,
		handleRequestDelete,
		handleConfirmDelete,
		action,
		activeId,
	} = useMutate(productApi.deleteProduct, handleInvalidateQuery);
	const { isLoading } = action;

	return {
		isLoading,
		warningModal,
		setWarningModal,
		handleRequestDelete,
		handleConfirmDelete,
	};
};
