import React from "react";
import styles from "./Reactions.module.css";
import { ReactionBarSelector } from "@charkour/react-reactions";
import { getReactionImg } from "../General";
import useWindowSize from "../../../hooks/useWindowSize";

const Reactions = ({ onSelect, isFullscreenPost }) => {
    const reactionOptions = [
        {
            label: "gaawk",
            node: (
                <img
                    className={`${styles.reaction_icon} ${isFullscreenPost ? styles.full_screen_post : undefined}`}
                    src={getReactionImg("gaawk")}
                    alt="gaawk"
                />
            ),
            key: "gaawk",
        },
        {
            label: "Love",
            node: (
                <img
                    className={`${styles.reaction_icon} ${isFullscreenPost ? styles.full_screen_post : undefined}`}
                    src={getReactionImg("Love")}
                    alt="Love"
                />
            ),
            key: "Love",
        },
        {
            label: "Wow",
            node: (
                <img
                    className={`${styles.reaction_icon} ${isFullscreenPost ? styles.full_screen_post : undefined}`}
                    src={getReactionImg("Wow")}
                    alt="Wow"
                />
            ),
            key: "Wow",
        },
        {
            label: "Haha",
            node: (
                <img
                    className={`${styles.reaction_icon} ${isFullscreenPost ? styles.full_screen_post : undefined}`}
                    src={getReactionImg("Haha")}
                    alt="Haha"
                />
            ),
            key: "Haha",
        },
        {
            label: "Sad",
            node: (
                <img
                    className={`${styles.reaction_icon} ${isFullscreenPost ? styles.full_screen_post : undefined}`}
                    src={getReactionImg("Sad")}
                    alt="Sad"
                />
            ),
            key: "Sad",
        },
        {
            label: "Angry",
            node: (
                <img
                    className={`${styles.reaction_icon} ${isFullscreenPost ? styles.full_screen_post : undefined}`}
                    src={getReactionImg("Angry")}
                    alt="Angry"
                />
            ),
            key: "Angry",
        },
        {
            label: "Insightful",
            node: (
                <img
                    className={`${styles.reaction_icon} ${isFullscreenPost ? styles.full_screen_post : undefined}`}
                    src={getReactionImg("Insightful")}
                    alt="Insightful"
                />
            ),
            key: "Insightful",
        },
    ];

    const { width } = useWindowSize();

    return (
        <ReactionBarSelector
            style={{
                borderRadius: "15px",
                boxShadow: "2px 2px 2px 0 rgba(0, 0, 0, 0.16), inset -6px 6px 3px 0 #fff",
                border: "0.5px solid #a6b1bc",
                backgroundColor: "#f2f3f5",
                padding: "10px",
                gap: `${width < 640 || (width < 974 && width >= 748) || isFullscreenPost ? "10px" : "20px"}`,
            }}
            reactions={reactionOptions}
            onSelect={onSelect}
        />
    );
};

export default Reactions;
