import { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./VaultItem.module.css";

// import shareIcon from "images/icon-awesome-share.svg";
// import downloadIcon from "images/icon-ionic-md-download.svg";
import starFullIcon from "images/icon-metro-star-full-color.svg";
import starIcon from "images/icon-metro-star-full.svg";
// import folderIcon from "images/icon-awesome-folder-open.svg";
// import gaawkFolderLetter from "images/gaawkfolderletter.svg";
// import folderIconBlue from "images/folder-icon-blue.svg";
import optionIcon from "images/options-hor-dark-grey.svg";
// import playIcon from "images/play-icon-blue.svg";
import { ReactComponent as PlayIcon } from "images/play-button.svg";

import { ReactComponent as FolderIcon } from "images/folder.svg";
import { ReactComponent as GaawkFolder } from "images/gaawk-folder.svg";

import LazyImage from "../Utils/LazyImage/LazyImage";
// import FileSaver from "file-saver";
import VaultItemOptions from "./VaultItemOptions";
import RectangleCheckbox from "../Utils/SubComs/Inputs/Checkbox/RectangleCheckbox";
import Popover from "../Utils/Popover/Popover";
import useCompanyId from "../../hooks/useCompanyId";
import otherIcon from "images/other_icon.svg";

import useGetFirstFrame from "hooks/useGetFirstFrame";

const GridView = ({
	item,
	// isRecent,
	isSelected,
	showTickSelect,
	handleSelected,
	isActive,

	onMouseOver,
	onMouseLeave,

	onShare,
	onCopy,
	onMove,
	onDetails,
	onFavorite,
	onDelete,
	onRename,
	onClick,
}) => {
	const {
		uuid,
		gaawkFolder,
		favorite,
		type,
		file,
		subCount,
		owner,
		sharedItem,
		enableDelete,
	} = item;

	const isFolder = type === "FOLDER";

	const { customName: url, originalName } = file || {};

	const videoThumb = useGetFirstFrame(
		url,
		originalName,
		!isFolder && file.previewType === "VIDEO"
	);

	/**
	 * Selectors
	 */

	// const { uuid: profileId } = useSelector((state) => state.user.profileInfo);

	const companyId = useCompanyId();

	const { uuid: profileId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const isOwner = owner.uuid === profileId;

	const popoverRef = useRef();

	/**
	 * Hooks
	 */

	const navigate = useNavigate();

	/**
	 * States
	 */

	const handleClick = () => {
		if (isActive) {
			handleSelected();
		} else if (isFolder) {
			navigate(`/vault/${uuid}`);
		} else {
			onClick();
		}
	};

	/**
	 * JSX
	 */
	return (
		<div className={styles.item}>
			<div
				className={`${styles.item_icon} ${
					isSelected ? styles.active : undefined
				} ${gaawkFolder ? styles.gaawkFolder : undefined}`}
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
				onClick={handleClick}
			>
				{showTickSelect && (
					<button
						className={styles.tickbox}
						onClick={(e) => e.stopPropagation()}
					>
						<RectangleCheckbox
							checked={isSelected}
							onChange={handleSelected}
						/>
					</button>
				)}

				<Popover
					ref={popoverRef}
					render={
						<VaultItemOptions
							isFolder={isFolder}
							shared={sharedItem}
							file={file}
							isOwner={isOwner}
							gaawkFolder={gaawkFolder}
							favorite={favorite}
							onRename={onRename}
							onDetails={onDetails}
							onShare={onShare}
							onMove={onMove}
							onCopy={onCopy}
							onFavorite={onFavorite}
							onDelete={onDelete}
							onClose={() => popoverRef.current.closePopover()}
							enableDelete={enableDelete}
						/>
					}
				>
					<button
						className={styles.options}
						onClick={(e) => e.stopPropagation()}
					>
						<img src={optionIcon} alt="options" />
					</button>
				</Popover>

				{/* <button className={styles.share} onClick={onShare}>
                    <img src={shareIcon} alt={"share"} />
                </button> */}

				{/* {!isFolder && (
                    <button
                        className={styles.download}
                        onClick={(e) => {
                            e.stopPropagation();
                            FileSaver.saveAs(`${file.customName}`, `${item.name}`);
                        }}
                    >
                        <img src={downloadIcon} alt={"download"} />
                    </button>
                )} */}

				<button className={styles.star} onClick={onFavorite}>
					<img
						src={favorite ? starFullIcon : starIcon}
						alt={"star"}
					/>
				</button>

				{isFolder && !gaawkFolder && (
					<div className={styles.small_icon}>
						<FolderIcon fill="#a6b1bc" width={36} height={36} />
					</div>
				)}

				{isFolder && gaawkFolder && (
					<div className={styles.small_icon}>
						<GaawkFolder width={36} height={36} />
					</div>
				)}

				{!isFolder &&
					file.fileTypeDTO &&
					file.previewType !== "IMAGE" &&
					file.previewType !== "VIDEO" && (
						<LazyImage
							image={
								<img
									src={
										file.fileTypeDTO.url
											? file.fileTypeDTO.url
											: otherIcon
									}
									alt={file.fileTypeDTO.name}
									className={styles.small_icon}
								/>
							}
							inlineStyle={styles.small_icon}
						/>
					)}

				{!isFolder && file.previewType === "IMAGE" && (
					<LazyImage
						image={
							<img
								src={file.customName}
								alt={item.name}
								className={styles.file_img}
							/>
						}
						inlineStyle={styles.file_img}
					/>
				)}

				{!isFolder && file.previewType === "VIDEO" && (
					<div className={styles.media_wrapper}>
						<PlayIcon className={styles.play_icon} />

						<LazyImage
							image={
								<img
									src={videoThumb}
									alt={item.name}
									className={styles.file_img}
								/>
							}
							inlineStyle={styles.file_img}
						/>
					</div>
				)}
			</div>

			<div className={styles.item_name}>
				<p>{item.name}</p>
			</div>

			<div className={styles.item_type}>
				{isFolder
					? `${subCount} item${subCount > 1 ? "s" : ""}`
					: `${file.extension.toUpperCase()} File`}
			</div>
		</div>
	);
};

export default GridView;
