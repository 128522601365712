import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Post",
    initialState: {
        feeds: [],
        pending: null,
        hasMore: true,
    },
    reducers: {
        feedsUpdated: (state, action) => {
            const stateFeeds = state.feeds;
            const actionFeeds = action.payload;

            if (stateFeeds.length === 0) {
                state.feeds = actionFeeds;
            } else {
                return {
                    ...state,
                    feeds: [...state.feeds, ...action.payload],
                    hasMore: action.payload.length !== 0,
                };
            }
        },

        reactionUpdated: (state, action) => {
            const { uuid, reactionStats, profileReactionType } = action.payload;
            const index = state.feeds.findIndex((post) => post.uuid === uuid);
            state.feeds[index].reactionStats = reactionStats;
            state.feeds[index].profileReactionType = profileReactionType;
        },

        postAdded: (state, action) => {
            state.feeds.unshift(action.payload);
        },

        postAddedWithMedia: (state, action) => {
            state.feeds.unshift(action.payload);
            state.pending = null;
        },

        postDeleted: (state, action) => {
            const postId = action.payload.passedData;
            state.feeds = state.feeds.filter((post) => post.uuid !== postId);
        },

        postPendingAdded: (state, action) => {
            state.pending = action.payload;
        },
    },
});

export const {
    feedsUpdated,
    reactionUpdated,
    postAdded,
    postDeleted,
    postPendingAdded,
    postAddedWithMedia,
} = slice.actions;
export default slice.reducer;

/**
 * get feeds
 */

export const getFeeds = (page, size) =>
    apiActions.apiCallBegan({
        url: `posts/feeds?page=${page}&size=${size}`,
        method: "GET",
        onSuccess: feedsUpdated.type,
    });

/**
 * add post
 */
export const addPost = (postData) =>
    apiActions.apiCallBegan({
        url: `posts/add`,
        method: "POST",
        data: postData,
        onSuccess: postAdded.type,
        showSuccessAlert: true,
    });

export const addPostWithMedia = (postData) =>
    apiActions.apiCallBegan({
        url: `posts/add`,
        method: "POST",
        data: postData,
        onSuccess: postPendingAdded.type,
        showSuccessAlert: true,
    });

/**
 * add post media
 */
export const addPostMedia = (postMedia) =>
    apiActions.apiCallBegan({
        url: `posts/mediaList`,
        method: "POST",
        data: postMedia,
        contentType: "multipart/form-data",
        onSuccess: postAddedWithMedia.type,
        showSuccessAlert: true,
    });

/**
 * delete post
 */
export const deletePost = (postId) =>
    apiActions.apiCallBegan({
        url: `posts/delete?postId=${postId}`,
        method: "DELETE",
        passedData: postId,
        onSuccess: postDeleted.type,
        showSuccessAlert: true,
    });

/**
 * add post reaction
 */
export const addReaction = (postId, profileId, reaction) =>
    apiActions.apiCallBegan({
        url: `posts/reaction?postId=${postId}&profileId=${profileId}&reactionType=${reaction}`,
        method: "PUT",
        onSuccess: reactionUpdated.type,
        // showSuccessAlert: true,
    });

/**
 * remove reaction
 */
export const removeReaction = (postId, profileId) =>
    apiActions.apiCallBegan({
        url: `posts/reaction?postId=${postId}&profileId=${profileId}`,
        method: "DELETE",
        onSuccess: reactionUpdated.type,
        // showSuccessAlert: true,
    });
