import { useState, useRef, useEffect } from "react";
import styles from "./CreatePost.module.css";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import EditMediaButtons from "./EditMediaButtons";
import LocationModal from "./LocationModal";
import ProfileTagModal from "./ProfileTagModal";
import locationIcon from "images/location_icon.svg";

import calendarIcon from "images/calendar-icon-21-x-20@3x.png";
import greyClose from "images/cancel-btn-gray.svg";
import ProfilePic from "../Home/ProfilePic";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import WarningModal from "../Utils/GaawkModal/WarningModal";
// import CropModal from "./CropModal";
import CropModal from "../Utils/CropModal/CropModal";
import MediaItem from "./MediaList";
import { format } from "date-fns";
import ServicesTagModal from "./ServicesTagModal";
import Tag from "../Utils/SubComs/Tags/Tag";
import { getLocationType } from "components/Utils/General";

const EditMediaModal = ({
	show,
	onClose,
	item,
	isEditing = false,
	onToggle,
	onConfirm,
	onReset,
}) => {
	const [newItem, setNewItem] = useState("");

	useEffect(() => {
		if (
			item &&
			(item.file || item.subPosts?.length > 0 || item.multipartFile)
		) {
			if (!item.newFile) {
				setNewItem({
					...item,
					caption: item.postText,
					taggedIds: item.taggedProfiles,
				});
			} else {
				setNewItem(item);
			}
		}
	}, [item]);

	const ref = useRef();

	//! =========== caption Handler =============

	const handleCaptionChange = (e) => {
		setNewItem((prevState) => ({ ...prevState, caption: e.target.value }));
	};

	//! =========== Location Handler =============

	const [showLocationModal, setShowLocationModal] = useState(false);

	const handleShowLocationModal = () => {
		onToggle(false);
		setShowLocationModal(true);
	};

	const handleCloseLocationModal = () => {
		setShowLocationModal(false);
		onToggle(true);
	};

	const handleLocationClicked = (place) => {
		// onLocationChange(place);

		setNewItem((prevState) => ({
			...prevState,
			location: {
				latitude: place.geometry.location.lat,
				longitude: place.geometry.location.lng,
				title: place.name,
				type: getLocationType(place.types),
				placeId: place.place_id,
			},
		}));
		handleCloseLocationModal();
	};

	const handleRemoveLocation = () => {
		setNewItem((prevState) => ({ ...prevState, location: null }));
	};

	//! =========== Profile tag Handler =============
	const [showTagModal, setShowTagModal] = useState(false);

	const handleShowTagModal = () => {
		onToggle(false);
		setShowTagModal(true);
	};

	const handleCloseTagModal = () => {
		setShowTagModal(false);
		onToggle(true);
	};

	const handleTagChange = (mediaTags) => {
		setNewItem((prevState) => ({ ...prevState, taggedIds: mediaTags }));
	};

	const handleRemoveTaggedProfile = (profile) => {
		const newItemCopy = { ...newItem };
		const mediaTaggedProfiles = newItemCopy["taggedIds"];
		const updatedTaggedProfiles = mediaTaggedProfiles.filter(
			(x) => x.uuid !== profile.uuid
		);
		setNewItem((prevState) => ({
			...prevState,
			taggedIds: updatedTaggedProfiles,
		}));
	};

	const taggedProfiles =
		newItem.taggedIds &&
		newItem.taggedIds.map((profile) => {
			return (
				<div className={styles.taggedItem} key={profile.uuid}>
					<ProfilePic
						type={profile.type}
						thumbnail={
							profile.profileImage
								? profile.profileImage.image
								: undefined
						}
						w={40}
						h={40}
					/>
					<img
						src={greyClose}
						className={styles.removeTag}
						onClick={() => handleRemoveTaggedProfile(profile)}
						alt="remove"
					/>
				</div>
			);
		});

	//! =========== Datepicker Handler =============

	// const [showDatePicker, setShowDatePicker] = useState(false);

	const handleDatePicker = (date) => {
		setNewItem((prevState) => ({ ...prevState, date: date.getTime() }));

		// setShowDatePicker((prevState) => !prevState);
	};

	// const handleSelectDate = (dateInMs) => {
	//     setShowDatePicker(false);
	//     // onSelectDate(dateInMs);
	//     setNewItem((prevState) => ({ ...prevState, date: dateInMs }));
	// };

	const handleRemoveMediaDate = () => {
		// onRemoveDate();
		const newItemCopy = { ...newItem };
		delete newItemCopy.date;
		setNewItem(newItemCopy);
		ref.current.resetDate();
	};

	//! =========== CROP Handler ================

	const [showCropModal, setShowCropModal] = useState(false);

	const handleShowCropModal = () => {
		onToggle(false);
		setShowCropModal(true);
	};

	const handleHideCropModal = () => {
		setShowCropModal(false);
		onToggle(true);
	};

	const handleCrop = (blob) => {
		blob.fileName = item.multipartFile.name;
		setNewItem((prevState) => ({ ...prevState, multipartFile: blob }));
	};

	//! =========== Services Handler =============

	const [showServicesModal, setShowServicesModal] = useState(false);

	const handleShowServicesModal = () => {
		onToggle(false);
		setShowServicesModal(true);
	};

	const handleHideServicesModal = () => {
		setShowServicesModal(false);
		onToggle(true);
	};

	const handleServicesChange = (serviceTags) => {
		setNewItem((prevState) => ({
			...prevState,
			services: serviceTags,
		}));
	};

	const handleRemoveTaggedService = (service) => {
		const newItemCopy = { ...newItem };
		const taggedServices = newItemCopy["services"];
		const updatedTaggedServices = taggedServices.filter(
			(x) => x.uuid !== service.uuid
		);
		setNewItem((prevState) => ({
			...prevState,
			services: updatedTaggedServices,
		}));
	};

	const taggedServices = newItem.services?.map((service) => {
		return (
			<Tag
				key={service.uuid}
				itemName={service.name}
				onRemove={() => handleRemoveTaggedService(service)}
			/>
		);
	});

	//! =========== BUTTONS Handler =============

	const [showWarning, setShowWarning] = useState(false);

	const clearModal = () => {
		onClose();
		onReset && onReset();
	};

	const handleWarningDiscard = () => {
		setShowWarning(false);
		clearModal();
	};

	const handleConfirmEditMedia = () => {
		onConfirm(newItem);
		clearModal();
	};

	return (
		<>
			{show && (
				<GaawkModal
					show={show}
					handleClose={() => setShowWarning(true)} //TODO: enhancement >> only open warning if changes detected
					defaultModal={false}
					showHeader={true}
					title={"Edit details"}
					closeAlign={"left"}
					children={
						<div
							className={`${styles.create_post_modal_container} ${styles.scrollable_modal}`}
						>
							<MediaItem
								mediaType={
									newItem.newFile
										? newItem.type
										: newItem.file?.mediaType
								}
								url={
									newItem.newFile
										? URL.createObjectURL(
												newItem.multipartFile
										  )
										: newItem.file?.url
								}
								fileName={
									newItem.newFile
										? newItem.multipartFile.name
										: newItem.file?.originalName
								}
								text={
									newItem.newFile
										? newItem.caption
										: newItem.postText
								}
								singleMedia={true}
							/>

							<EditMediaButtons
								ref={ref}
								mediaType={newItem.type}
								isEditing={isEditing}
								onLocation={handleShowLocationModal}
								onTag={handleShowTagModal}
								onDatePicker={handleDatePicker}
								onCrop={handleShowCropModal}
								onServices={handleShowServicesModal}
							/>

							<div className={styles.form_input_wrapper}>
								<label htmlFor="group-description">
									Description
								</label>
								<textarea
									onChange={handleCaptionChange}
									value={newItem.caption}
								/>
							</div>

							{newItem.location && (
								<div
									className={`${styles.form_input_wrapper} ${styles.media_edit}`}
								>
									<label htmlFor="group-description">
										Location
									</label>
									<div className={styles.media_data}>
										<div>
											<img
												src={locationIcon}
												alt="location"
											/>
											{newItem.location.title}
										</div>
										<img
											src={greyClose}
											className={styles.delete_data}
											onClick={handleRemoveLocation}
											alt="close"
										/>
									</div>
								</div>
							)}

							{newItem.date !== undefined &&
								newItem.date !== 0 && (
									<div
										className={`${styles.form_input_wrapper} ${styles.media_edit}`}
									>
										<label htmlFor="group-description">
											Date
										</label>
										<div className={styles.media_data}>
											<div>
												<img
													src={calendarIcon}
													alt="Date"
												/>
												{format(
													new Date(newItem.date),
													"dd/MM/yyyy"
												)}
											</div>
											<img
												src={greyClose}
												className={styles.delete_data}
												onClick={handleRemoveMediaDate}
												alt="close"
											/>
										</div>
									</div>
								)}

							{taggedProfiles && taggedProfiles.length > 0 && (
								<div
									className={`${styles.form_input_wrapper} ${styles.media_edit}`}
								>
									<label htmlFor="group-description">
										Tagged profiles
									</label>
									<div
										className={
											styles.taggedProfiles_container
										}
									>
										{taggedProfiles}
									</div>
								</div>
							)}

							{taggedServices && taggedServices.length > 0 && (
								<div
									className={`${styles.form_input_wrapper} ${styles.media_edit}`}
								>
									<label htmlFor="group-description">
										Tagged services
									</label>
									<div
										className={
											styles.selected_tags_container
										}
									>
										{taggedServices}
									</div>
								</div>
							)}

							<div className={styles.button_container}>
								<PrimaryButton
									text={"SAVE"}
									className={`${styles.btn}`}
									onClick={handleConfirmEditMedia}
								/>
							</div>
						</div>
					}
				/>
			)}

			{!isEditing && (
				<CropModal
					image={
						newItem && URL.createObjectURL(newItem.multipartFile)
					}
					show={showCropModal}
					onClose={handleHideCropModal}
					onCrop={(cropped) => {
						handleCrop(cropped);
						setShowCropModal(false);
						handleHideCropModal();
					}}
					returnBlob={true}
				/>
			)}

			<LocationModal
				show={showLocationModal}
				onClose={handleCloseLocationModal}
				onLocationClicked={handleLocationClicked}
			/>

			<ProfileTagModal
				show={showTagModal}
				tagsArray={newItem.taggedIds}
				onClose={handleCloseTagModal}
				onConfirm={handleTagChange}
			/>

			<ServicesTagModal
				show={showServicesModal}
				handleClose={handleHideServicesModal}
				onConfirm={handleServicesChange}
				currentTags={newItem.services}
			/>

			<WarningModal
				show={showWarning}
				modalOnTop={true}
				headerText="Are you sure you want to discard your changes?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarning(false)}
				submitButtonText={"DISCARD"}
				onSubmitButtonClicked={handleWarningDiscard}
			/>
		</>
	);
};

export default EditMediaModal;
