import React, { useMemo, useRef, useState } from "react";
import styles from "./GroupInfo.module.css";
import GroupMember from "./GroupMember";
import { pluralize } from "../Utils/General";
// import SearchInput from "../Utils/SubComs/Inputs/SearchInput/SearchInput";
// import magnifiyingGlass from "images/magnifiyingGlass.svg";
// import GaawkModal from "../Utils/GaawkModal/GaawkModal";
// import ProfilePic from "../Home/ProfilePic";
// import imgUpload from "images/imgUpload.svg";
import msgIcon from "images/message-icon-black.svg";
import optionIcon from "images/three-dots-black-5-x-20.svg";
import chatApi from "../../api/chat";
import connectionApi from "../../api/connection";
import useWindowSize from "../../hooks/useWindowSize";
import MediaContainer from "./MediaContainer";
import ConvActions from "./ConvActions";
import AddMembersModal from "./AddMembersModal";
import ParticipantItem from "./ParticipantItem";
import ConnectionModal from "../Profile/ProfileHeader/ConnectionModal";
import { format } from "date-fns";
import LazyImage from "../Utils/LazyImage/LazyImage";
import GroupModalStep1 from "./ChatComponents/GroupModal/GroupModalStep1";
import useMutate from "../../hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";
import Popover from "../Utils/Popover/Popover";
import GroupMemberOptions from "./GroupMemberOptions";
import useSearchNewGroupMember from "./hooks/useSearchNewGroupMember";
import useGetGroupMembers from "./hooks/useGetGroupMembers";
import GroupParticipants from "./ChatComponents/GroupModal/GroupParticipants";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import routes from "components/Routing/routing-keys";

const GroupInfo = ({
	info,
	onReportGrp,
	onExit,
	onClear,
	onExport,
	onMute,
	onUnmute,
	onUpdateCount,
	onUpdateGroupInfo,
	onDirectMsg,
}) => {
	const queryClient = useQueryClient();
	const popoverRef = useRef();

	const {
		filesCount,
		groupDTO: {
			creationTime,
			image: { file: { customName: groupImage } = {} } = {},
			name,
			description,
			owner,
			uuid: groupId,
			admin: amIAdmin,
			totalAdmins,
			totalMembers,
		},
		muted,
	} = info;

	const { width } = useWindowSize();

	const [addPeopleModal, setAddPeopleModal] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const [editModal, setEditModal] = useState(false);

	const groupCreationDate = new Date(creationTime) || "";

	const [groupParticipants, setGroupParticipants] = useState([]);
	const [selectedUser, setSelectedUser] = useState("");

	// ! === FETCH PARTICIPANTS ===

	const {
		data: participants,
		hasNextPage: hasNextPageParticipants,
		fetchNextPage: fetchNextPageParticipants,
		isFetching: isFetchingParticipants,
	} = useGetGroupMembers("participants", groupId);

	const participantsList = participants?.pages?.map((page) =>
		page.map((member) => (
			<GroupMember
				key={member.uuid}
				data={member}
				redirect={true}
				rightSideAction={
					<>
						<button
							type="button"
							onClick={() => onDirectMsg(member)}
						>
							<img
								src={msgIcon}
								className={styles.direct_msg_icon}
								alt="message"
							/>
						</button>

						<Popover
							ref={popoverRef}
							render={
								<GroupMemberOptions
									url={member.url}
									friendStatus={
										member.connection.friendStatus
									}
									userIsAdmin={false}
									amIAdmin={amIAdmin}
									inMyWorkCircle={
										member.connection.inMyWorkCircle
									}
									onUpdateConnection={(action) =>
										handleConnection(action, member, false)
									}
									onDirectMsg={() => onDirectMsg(member)}
									onMakeAdmin={() =>
										handleMakeAdmin({
											...member,
											isAdmin: false,
										})
									}
									onDelete={() =>
										handleDeleteUser({
											...member,
											isAdmin: false,
										})
									}
									onClose={() =>
										popoverRef.current?.closePopover()
									}
								/>
							}
						>
							<button>
								<img src={optionIcon} alt="options" />
							</button>
						</Popover>
					</>
				}
			/>
		))
	);

	// ! === FETCH ADMINS ===

	const {
		data: admins,
		hasNextPage: hasNextPageAdmins,
		fetchNextPage: fetchNextPageAdmins,
		isFetching: isFetchingAdmins,
	} = useGetGroupMembers("admins", groupId);

	const adminsList = admins?.pages?.map((page) =>
		page.map((admin) => (
			<GroupMember
				key={admin.uuid}
				data={admin}
				redirect={true}
				isChatLeftSide={true}
				rightSideAction={
					<>
						<button
							type="button"
							onClick={() => onDirectMsg(admin)}
						>
							<img
								src={msgIcon}
								className={styles.direct_msg_icon}
								alt="message"
							/>
						</button>

						<Popover
							ref={popoverRef}
							render={
								<GroupMemberOptions
									url={admin.url}
									friendStatus={admin.connection.friendStatus}
									userIsAdmin={true}
									amIAdmin={amIAdmin}
									inMyWorkCircle={
										admin.connection.inMyWorkCircle
									}
									onUpdateConnection={(action) =>
										handleConnection(action, admin, true)
									}
									onDirectMsg={() => onDirectMsg(admin)}
									onRevokeAdmin={() =>
										handleRevokeAdmin({
											...admin,
											isAdmin: true,
										})
									}
									onDelete={() =>
										handleDeleteUser({
											...admin,
											isAdmin: true,
										})
									}
									onClose={() =>
										popoverRef.current?.closePopover()
									}
								/>
							}
						>
							<button>
								<img src={optionIcon} alt="options" />
							</button>
						</Popover>
					</>
				}
			/>
		))
	);

	//* ============HANDLERS==============

	const handleEditGroup = () => {
		setEditModal(true);
	};

	const handleAddSuccess = () => {
		queryClient.invalidateQueries(["participants", groupId]);
		onUpdateCount("ADD", groupParticipants.length);
		setGroupParticipants([]);
		setAddPeopleModal(false);
	};

	const {
		action: { mutate: addParticipants },
	} = useMutate(chatApi.addParticipants, handleAddSuccess);

	const handleAddToGroup = () => {
		addParticipants({
			groupId,
			profileIds: groupParticipants.map((user) => user.uuid),
		});
	};

	const handleContactSearch = ({ target }) => {
		const query = target.value;
		setSearchQuery(query);
	};

	const handleCloseAddModal = () => {
		setAddPeopleModal(false);
		setSearchQuery("");
	};

	const {
		ref: viewRef,
		infiniteQuery: { data, isFetching },
	} = useSearchNewGroupMember(searchQuery, addPeopleModal, groupId);

	const handleNewGroupParticipant = (participant) => {
		setGroupParticipants((prevState) => [participant, ...prevState]);
		setSearchQuery("");
	};

	const handleRemoveParticipant = (participant) => {
		const participants = [...groupParticipants];
		const updatedParticipants = participants.filter(
			(item) => item.uuid !== participant.uuid
		);
		setGroupParticipants(updatedParticipants);
	};

	const newParticipantsList = useMemo(
		() =>
			groupParticipants.map((participant) => (
				<ParticipantItem
					key={participant.uuid}
					name={participant.name}
					onRemove={() => handleRemoveParticipant(participant)}
				/>
			)),
		[groupParticipants]
	);

	const {
		action: { mutate: updateGroupInfo },
	} = useMutate(chatApi.editGroup, (response) => {
		queryClient.invalidateQueries(["conversations"]);
		onUpdateGroupInfo(response.data);
	});

	const handleUpdateInfo = (data) => {
		const { groupName, description, croppedImg, file } = data;

		updateGroupInfo({
			groupId,
			groupName,
			description,
			groupImg: croppedImg,
			imgName: file && file[0].name,
		});

		//TODO >> add a loading spinner and close the modal in the successFn for better UX
		setEditModal(false);
	};

	//TODO >> NEED NEW API (/profile/search/groups) TO REMOVE CURRENT GROUP MEMBERS FROM THE SEARCH RESULTS (won't have to manually filter)
	const profileGroupList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page
					// //* MANUALLY REMOVES EXISTING PARTICIPANTS
					// .filter(
					// 	(user) =>
					// 		!participants.pages
					// 			.flat()
					// 			.map((item) => item.uuid)
					// 			.includes(user.uuid)
					// )
					// //* MANUALLY REMOVES EXISTING ADMINS
					// .filter(
					// 	(user) =>
					// 		!admins.pages
					// 			.flat()
					// 			.map((item) => item.uuid)
					// 			.includes(user.uuid)
					// )
					// //* MANUALLY REMOVES NEWLY ADDED MEMBERS
					.filter(
						(user) =>
							!groupParticipants
								.map((item) => item.uuid)
								.includes(user.uuid)
					)
					.map((profile, i) => (
						<div
							key={profile.uuid}
							ref={page.length === i + 1 ? viewRef : null}
							className={styles.contact_item}
							onClick={() => handleNewGroupParticipant(profile)}
						>
							<GroupMember
								key={profile.uuid}
								data={profile}
								isChatLeftSide={true}
								disableDecoration={true}
							/>
						</div>
					))
			),
		[data, groupParticipants]
	);

	//! ==== REMOVE USER FROM GROUP ====

	const {
		action: { mutate: deleteParticipant },
	} = useMutate(chatApi.deleteParticipants, () => {
		if (selectedUser.isAdmin) {
			queryClient.invalidateQueries(["admins", groupId]);
			onUpdateCount("DELETE", 1, true);
		} else {
			queryClient.invalidateQueries(["participants", groupId]);
			onUpdateCount("DELETE", 1, false);
		}
	});

	const handleDeleteUser = (user) => {
		setSelectedUser(user);
		deleteParticipant({
			groupId,
			profileIds: [user.uuid],
		});
	};

	// ! ==== MAKE / REVOKE ADMIN ====

	const invalidateMembers = () => {
		queryClient.invalidateQueries(["admins", groupId]);
		queryClient.invalidateQueries(["participants", groupId]);
	};

	const {
		action: { mutate: assignAdmin },
	} = useMutate(chatApi.assignAdmin, () => {
		invalidateMembers();
		onUpdateCount("SWAP", 1, true);
	});

	const handleMakeAdmin = (user) => {
		assignAdmin({
			assigneeId: user.uuid,
			groupId,
		});
	};

	const {
		action: { mutate: revokeAdmin },
	} = useMutate(chatApi.revokeAdmin, () => {
		invalidateMembers();
		onUpdateCount("SWAP", 1, false);
	});

	const handleRevokeAdmin = (user) => {
		revokeAdmin({
			assigneeId: user.uuid,
			groupId,
		});
	};

	// !======= connection handlers =============

	const [showConnectionModal, setShowConnectionModal] = useState(false);
	const [modalAction, setModalAction] = useState("");
	const [profile, setProfile] = useState("");

	const handleConnectionUpdate = (updateType, status) => {
		queryClient.setQueryData(
			[profile.isAdmin ? "admins" : "participants", groupId],
			(prevData) => {
				const pagesCopy = [...prevData.pages];

				const pageIndex = pagesCopy.findIndex((page) =>
					page.find(
						({ uuid: profileId }) => profileId === profile.uuid
					)
				);

				const userIndex = pagesCopy[pageIndex].findIndex(
					({ uuid: profileId }) => profileId === profile.uuid
				);

				const updatedUser = { ...pagesCopy[pageIndex][userIndex] };

				updatedUser.connection[updateType] = status;

				pagesCopy[pageIndex][userIndex] = updatedUser;

				return {
					...prevData,
					pages: pagesCopy,
				};
			}
		);
	};

	const {
		action: { mutate: addWorkCircle },
	} = useMutate(connectionApi.addWorkCircle, () =>
		handleConnectionUpdate("inMyWorkCircle", true)
	);

	const {
		action: { mutate: removeWorkCircle },
	} = useMutate(connectionApi.removeWorkCircle, () =>
		handleConnectionUpdate("inMyWorkCircle", false)
	);

	const handleConnection = (action, profile, isAdmin) => {
		setProfile({ isAdmin, ...profile });
		if (action === "ADD_WORK") {
			addWorkCircle(profile.uuid);
		} else if (action === "REMOVE_WORK") {
			removeWorkCircle(profile.uuid);
		} else {
			setModalAction(action);
			setShowConnectionModal(true);
		}
	};

	const {
		action: { mutate: sendFriendRequest },
	} = useMutate(connectionApi.sendFriendRequest, () =>
		handleConnectionUpdate("friendStatus", "SENT")
	);

	const {
		action: { mutate: acceptFriendRequest },
	} = useMutate(connectionApi.acceptFriend, () =>
		handleConnectionUpdate("friendStatus", "APPROVED")
	);

	const {
		action: { mutate: revokeFriendRequest },
	} = useMutate(connectionApi.revokeFriendRequest, () =>
		handleConnectionUpdate("friendStatus", "N_A")
	);

	const {
		action: { mutate: deleteFriend },
	} = useMutate(connectionApi.deleteFriend, () =>
		handleConnectionUpdate("friendStatus", "N_A")
	);

	const {
		action: { mutate: declineFriendRequest },
	} = useMutate(connectionApi.declineFriend, () =>
		handleConnectionUpdate("friendStatus", "N_A")
	);

	const handleModalConfirm = (action) => {
		switch (action) {
			case "ADD_FRIEND":
				sendFriendRequest(profile.uuid);
				break;
			case "REMOVE_FRIEND":
				deleteFriend(profile.uuid);
				break;
			case "REVOKE_FRIEND":
				revokeFriendRequest(profile.uuid);
				break;
			case "ACCEPT_FRIEND":
				acceptFriendRequest(profile.uuid);
				break;
			case "DECLINE_FRIEND":
				declineFriendRequest(profile.uuid);
				break;
			default:
				break;
		}
		setShowConnectionModal(false);
	};

	// * =================== SEARCH IN CONVERSATION HANDLER ====================

	// const [searchInput, setSearchInput] = useState("");

	// const confirmSearch = () => {
	//     alert(
	//         `CALL API FOR SEARCH AND OPEN CONV WITH SEARCH ENABLED: ${searchInput}`
	//     );
	//     setSearchInput("");
	// };

	// const handleChangeSearchInput = (e) => {
	//     const query = e.target.value;
	//     if (query) {
	//         setSearchInput(query);
	//     }
	// };

	// const handleKeyUp = (e) => {
	//     if (e.key === "Enter" && searchInput) {
	//         confirmSearch();
	//     }
	// };

	const groupActions = (
		<div className={styles.group_controls}>
			<ConvActions
				amIAdmin={amIAdmin}
				muted={muted}
				onExport={onExport}
				onClear={onClear}
				isGroup={true}
				onMute={onMute}
				onUnmute={onUnmute}
				onReport={onReportGrp}
				onAdd={() => setAddPeopleModal(true)}
				onEditGroup={handleEditGroup}
				onExit={onExit}
			/>
		</div>
	);

	return (
		<>
			<div className={styles.groupInfo_container}>
				<div className={styles.upper_block}>
					<div className={styles.group_header}>
						<div className={styles.img_wrapper}>
							<LazyImage
								image={<img src={groupImage} alt="group" />}
							/>
						</div>

						{(width >= 974 || (width >= 640 && width < 748)) &&
							groupActions}
					</div>

					<div className={styles.group_description}>
						<h1>{name}</h1>
						<p>{description}</p>
						{/* <p className={styles.group_creation}>
							{`Created on ${format(
								new Date(groupCreationDate),
								"MMMM do yyyy"
							)} by `}

							<GaawkLink to={routes.profile(owner.url)}>
								{owner.name}
							</GaawkLink>
						</p> */}

						{/* <div className={styles.search_wrapper}>
                            <SearchInput
                                onKeyUp={handleKeyUp}
                                onChange={handleChangeSearchInput}
                                showIcons={true}
                                placeholder={"Search Conversation"}
                                value={searchInput}
                                onClearClicked={() => setSearchInput("")}
                                onSubmitSearch={() => confirmSearch()}
                            />
                        </div> */}
					</div>

					{/* GROUP CONTROLS MOVED HERE for 748px and 360px */}
					{((width < 974 && width >= 748) || width < 640) &&
						groupActions}
				</div>

				<div className={styles.lower_block}>
					<div
						className={`${styles.group_members} ${
							filesCount === 0 ? styles.full_width : undefined
						}`}
					>
						<div className={styles.group_members_header}>
							<h2>Group members</h2>

							<span>{`${totalMembers} ${pluralize(
								totalMembers,
								"member"
							)}, ${totalAdmins} ${pluralize(
								totalAdmins,
								"admin"
							)}`}</span>
						</div>

						{admins?.pages[0].length > 0 && (
							<GroupParticipants
								title="Admins"
								data={adminsList}
								isLoading={isFetchingAdmins}
								hasNextPage={hasNextPageAdmins}
								onSeeMore={fetchNextPageAdmins}
							/>
						)}

						{participants?.pages[0].length > 0 && (
							<GroupParticipants
								title="Members"
								data={participantsList}
								isLoading={isFetchingParticipants}
								hasNextPage={hasNextPageParticipants}
								onSeeMore={fetchNextPageParticipants}
							/>
						)}
					</div>

					{filesCount !== 0 && (
						<MediaContainer
							conversation={info}
							filesCount={filesCount}
						/>
					)}
				</div>
			</div>

			{/* //TODO >> replace component with <MultiItemsModal /> */}

			<AddMembersModal
				show={addPeopleModal}
				onClose={handleCloseAddModal}
				onCancel={handleCloseAddModal}
				closeAlign={"right"}
				searchInput={searchQuery}
				onInputChange={handleContactSearch}
				onClear={() => {
					setSearchQuery("");
				}}
				participantsList={newParticipantsList}
				contactList={profileGroupList?.flat() || []}
				onConfirm={handleAddToGroup}
				buttonText="ADD"
				isLoading={isFetching}
			/>

			{/* EDIT GROUP MODAL */}
			<GroupModalStep1
				show={editModal}
				onClose={() => setEditModal(false)}
				onNext={handleUpdateInfo}
				editMode={true}
				groupInfo={info}
			/>

			<ConnectionModal
				show={showConnectionModal}
				profileImage={
					profile.profileImage
						? profile.profileImage.image
						: undefined
				}
				name={profile.name}
				action={modalAction}
				onConfirm={handleModalConfirm}
				onClose={() => setShowConnectionModal(false)}
			/>
		</>
	);
};

export default GroupInfo;
