import { useForm } from "react-hook-form";
import styles from "./ForgotPasswordForm.module.scss";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import useApi from "hooks/useApi";
import profileApi from "api/profile";
import { useEffect, useState } from "react";

const ForgotPasswordForm = ({ onBack }) => {
	const schema = Joi.object({
		email: Joi.string()
			.email({ tlds: { allow: false } })
			.required()
			.label("Email")
			.messages({
				"string.empty": "Email should not be empty",
				"string.email": "Email should be valid",
			}),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
		setValue,
	} = useForm({ resolver: joiResolver(schema), mode: "onBlur" });

	const watchEmail = watch("email");

	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const passwordLinkApi = useApi(profileApi.passwordLink);

	const url = `${window.location.origin}/password/reset`;

	const handleSendLink = ({ email }) => {
		passwordLinkApi.request(email, url);
	};

	useEffect(() => {
		if (!passwordLinkApi.loading && passwordLinkApi.responseCode === 200) {
			setErrorMsg(null);
			setIsSuccess(true);
		} else if (
			!passwordLinkApi.loading &&
			passwordLinkApi.data &&
			Object.keys(passwordLinkApi.data).length > 0
		) {
			// setErrorMsg(`${passwordLinkApi.data.message}`);
			setIsSuccess(false);
			setErrorMsg("Profile not found");
		}
	}, [
		passwordLinkApi.loading,
		passwordLinkApi.responseCode,
		passwordLinkApi.data,
	]);

	return (
		<form
			className={styles.form_wrapper}
			onSubmit={handleSubmit((data) => handleSendLink(data))}
		>
			<button className={styles.arrow_btn} type="button" onClick={onBack}>
				<img
					src={require("images/back-arrow-black.svg").default}
					alt="back"
				/>
			</button>

			<h3>Forgot Password</h3>

			<p>
				Enter your registered email address below, and we’ll send you a
				link to reset your password. Please follow the instructions in
				the email to proceed.
			</p>

			<InputWrapper
				label="Email"
				required={true}
				error={errors?.email || errorMsg}
				customError={errorMsg}
				component={
					<TextInput
						{...register("email")}
						error={errors?.email || errorMsg}
						placeholder="Enter email"
						autoComplete="on"
						onBlur={() => setValue("email", watchEmail.trim())}
					/>
				}
			/>

			<PrimaryButton
				text={"send link"}
				className={styles.submit_btn}
				isLoading={passwordLinkApi.loading}
			/>

			{isSuccess && (
				<p className={styles.success_info}>
					An email was sent to your address. Please check your inbox
					and follow instructions to reset your password.
				</p>
			)}
		</form>
	);
};

export default ForgotPasswordForm;
