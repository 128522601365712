import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CertificateEntry.module.css";

import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import CustomRadioButton from "../../../Utils/SubComs/CustomRadioButton/CustomRadioButton";
import Switch from "react-switch";

import {
	addCertificate,
	updateCertificate,
	addCertificateFiles,
	// deleteCertificateFiles,
	certificateFilesUpdated,
	certificateUpdated,
} from "../../../../store/slices/user";

import {
	addCompanyCertificate,
	updateCompanyCertificate,
	addCompanyCertificateFiles,
	// deleteCompanyCertificateFiles,
	companyCertificateFilesUpdated,
	certificateUpdated as companyCertificateUpdated,
} from "../../../../store/slices/company";

// import pdfIcon from "images/pdf_icon.svg";

import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import DayPicker from "../../../Utils/SubComs/DayPicker/DayPicker";
import useApi from "../../../../hooks/useApi";
import profileApi from "../../../../api/profile";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
	defaultFormatter,
	locationFormatter,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
// import CreatableInput from "../../../Utils/SubComs/Inputs/CreatableInput/CreatableInput";
import institutionApi from "../../../../api/institution";
import certificateSchema from "./certificate-schema";
import InfiniteSearchInput from "../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useFetchLocation from "../../../../hooks/useFetchLocation";
import PromptModal from "components/Utils/Prompt/PromptModal";
import SimpleDropZone from "components/Utils/SubComs/CustomDropZone/SimpleDropZone";
import FileThumbnail from "components/Utils/SubComs/FileThumbnail/FileThumbnail";
import { getFileIcon } from "components/Utils/General";
import useMutate from "hooks/useMutate";
import ThumbnailContainer from "components/Utils/SubComs/ThumbnailContainer/ThumbnailContainer";

const itemsPerPage = 20;

const CertificateEntry = ({ editCompany }) => {
	const addCertificateFilesApi = useApi(
		editCompany
			? profileApi.addCompanyCertificateFiles
			: profileApi.addCertificateFiles
	);

	const dispatch = useDispatch();
	const { pathname, state } = useLocation();

	const editMode = pathname === "/profile/certificate/edit";

	const {
		uuid: certificationId,
		locationDTO: {
			name: cityName,
			countryDTO: { name: countryName } = {},
			id: locationId,
		} = {},
		completionDate,
		certificateType,
		files: currentFiles,
		comments,
		instituteName,
		instituteDTO,
		score,
		scoreOutOf,
		startDate,
		title,
		validity,
		website,
		currentlyWorking: currentStatus,
	} = state || {};

	const { certificates: userCertificates, uuid } = useSelector((state) => {
		if (editCompany) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const prevUserCertificates = useRef(userCertificates).current;
	const [newUserCertificateId, setNewUserCertificateId] = useState("");
	const [saveNewEntry, setSaveNewEntry] = useState(false);
	const [saveEditedEntry, setSaveEditedEntry] = useState(false);

	const newUserCertificate = userCertificates.find(
		(certificate) => certificate.uuid === newUserCertificateId
	);

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
		watch,
		setValue,
	} = useForm({
		resolver: joiResolver(certificateSchema),
		mode: "onChange",
		defaultValues: editMode
			? {
					title,
					certificateType,
					institution: instituteDTO
						? { label: instituteDTO.name, value: instituteDTO.uuid }
						: {
								label: instituteName,
								value: instituteName,
								__isNew__: true,
						  },
					city: {
						value: locationId,
						label: `${cityName}, ${countryName}`,
					},
					startDate: startDate !== 0 ? new Date(startDate) : null,
					endDate:
						!currentStatus && completionDate !== 0
							? new Date(completionDate)
							: null,
					score: score ?? "",
					outOf: scoreOutOf ?? "",
					validity: validity !== 0 ? new Date(validity) : null,
					comments: comments ?? "",
					website: website ?? "",
					files: currentFiles,
					switch: currentStatus,
			  }
			: {
					title: "",
					certificateType: "",
					institution: null,
					city: null,
					startDate: null,
					endDate: null,
					comments: "",
					files: [],
					score: "",
					validity: null,
					outOf: "",
					switch: false,
			  },
	});

	const files = watch("files");
	const switchWatcher = watch("switch");

	//! FILES HANDLER -------------------------------------------------------

	const [fileCounter, setFileCounter] = useState(0);

	// const handleRemoveFile = (file, index) => {
	// 	if (file instanceof File && files.includes(file)) {
	// 		const updatedFiles = [...files];
	// 		updatedFiles.splice(index, 1);
	// 		setValue("files", updatedFiles, {
	// 			shouldDirty:
	// 				updatedFiles.length === 1 && !editMode ? false : true,
	// 		});
	// 	} else {
	// 		dispatch(
	// 			editCompany
	// 				? deleteCompanyCertificateFiles(
	// 						certificationId,
	// 						file.uuid,
	// 						uuid
	// 				  )
	// 				: deleteCertificateFiles(certificationId, file.uuid)
	// 		);
	// 	}
	// };

	const {
		action: { mutate: deleteFile },
	} = useMutate(
		editCompany
			? profileApi.deleteCompanyCertificateFile
			: profileApi.deleteCertificateFile,
		(response) => {
			const updatedResponse = response.data;

			dispatch(
				editCompany
					? companyCertificateUpdated(updatedResponse)
					: certificateUpdated(updatedResponse)
			);

			const updatedFiles = [...files];
			const filteredList = updatedFiles.filter(
				(file) => file.uuid !== fileIdToDelete
			);
			setValue("files", filteredList);
			setFileIdToDelete("");
		}
	);

	const [fileIdToDelete, setFileIdToDelete] = useState("");

	const handleFileToRemove = (file, index) => {
		if (file instanceof File && files.includes(file)) {
			const updatedFiles = [...files];
			updatedFiles.splice(index, 1);
			setValue("files", updatedFiles, {
				shouldDirty:
					updatedFiles.length === 1 && !editMode ? false : true,
			});
		} else {
			setFileIdToDelete(file.uuid);
			deleteFile({
				certificationId,
				fileId: file.uuid,
				...(editCompany && { uuid }),
			});
		}
	};

	const handleDroppedFiles = (acceptedFiles) => {
		const updatedFiles = [...files];

		acceptedFiles.forEach((file) => {
			updatedFiles.push(file);
		});

		setValue("files", updatedFiles);
	};

	// ! STATUS HANDLER -----------------------------------------------------

	const [currentlyWorking, setCurrentlyWorking] = useState(false);
	const handleWorkStatus = () => {
		setCurrentlyWorking((prevState) => !prevState);
		if (!currentlyWorking) setValue("endDate", null);
	};

	//! SAVE HANDLER --------------------------------------------------------

	const [isLoading, setIsLoading] = useState(false);

	const handleSave = (data) => {
		setIsLoading(true);

		const {
			city: { value: locationId },
			certificateType,
			comments,
			score,
			outOf: scoreOutOf,
			startDate,
			endDate,
			title,
			validity,
			institution,
			website,
			switch: switchStatus,
		} = data;

		const body = {
			...(editMode && { certificationId }),
			title,
			certificateType,
			...(institution.__isNew__
				? { instituteName: institution.value }
				: { instituteId: institution.value }),
			locationId,
			website,
			currentlyWorking: switchStatus,
			...(startDate && { startDate: startDate.getTime() }),
			...(!switchStatus &&
				endDate && { completionDate: endDate.getTime() }),
			...(score && { score }),
			...(scoreOutOf && { scoreOutOf }),
			...(validity !== null && { validity: validity.getTime() }),
			...(comments && { comments }),
		};

		if (editMode) {
			dispatch(
				editCompany
					? updateCompanyCertificate(body, uuid)
					: updateCertificate(body)
			);
			setSaveEditedEntry(true);
		} else {
			dispatch(
				editCompany
					? addCompanyCertificate(body, uuid)
					: addCertificate(body)
			);
			setSaveNewEntry(true);
		}
	};

	//! UNSAVED CHANGES HANDLER ---------------------------------------------

	const navigate = useNavigate();
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	useEffect(() => {
		if (!shouldBlockSave) navigate("/profile/certificate");
	}, [navigate, shouldBlockSave]);

	//* TO UPLOAD FILES (IF ANY AFTER CLICKING "SAVE") ==============

	//! ADDING FILES FOR NEWELY CREATED CERTIFICATE--------------------------
	useEffect(() => {
		if (saveNewEntry && !newUserCertificateId) {
			const newCertificate = userCertificates.filter(
				(i) =>
					prevUserCertificates
						.map((item) => item.uuid)
						.indexOf(i.uuid) === -1
			);

			if (newCertificate[0] && newCertificate[0].uuid)
				setNewUserCertificateId(newCertificate[0].uuid);
		}
	}, [
		newUserCertificateId,
		prevUserCertificates,
		saveNewEntry,
		userCertificates,
	]);

	useEffect(() => {
		if (newUserCertificate && files.length > 0) {
			if (newUserCertificate.files.length < files.length) {
				const formData = new FormData();
				formData.append("certificationId", newUserCertificateId);
				formData.append("multipartFile", files[fileCounter]);
				if (editCompany) formData.append("companyId", uuid);
				dispatch(
					editCompany
						? addCompanyCertificateFiles(formData)
						: addCertificateFiles(formData)
				);
				setFileCounter((prevState) => prevState + 1);
			} else if (newUserCertificate.files.length === files.length) {
				setShouldBlockSave(false);
			}
		} else if (newUserCertificate && files.length === 0) {
			setShouldBlockSave(false);
		}
	}, [newUserCertificate]);

	//! ADDING FILES FOR EXISTING CERTIFICATE-------------------------------
	useEffect(() => {
		if (saveEditedEntry) {
			const fileArray = files.filter((file) => file instanceof File);

			if (fileArray.length > 0) {
				if (fileCounter < fileArray.length) {
					const formData = new FormData();
					formData.append("certificationId", certificationId);
					formData.append("multipartFile", fileArray[fileCounter]);
					if (editCompany) formData.append("companyId", uuid);
					addCertificateFilesApi.request(formData);
				} else if (fileCounter === fileArray.length) {
					setShouldBlockSave(false);
				}
			} else {
				setShouldBlockSave(false);
			}
		}
	}, [saveEditedEntry, fileCounter]);

	useEffect(() => {
		if (
			!addCertificateFilesApi.loading &&
			addCertificateFilesApi.responseCode === 201 &&
			Object.keys(addCertificateFilesApi.data).length > 0
		) {
			const updatedCertificates =
				addCertificateFilesApi.data.certificates.find(
					(certificate) => certificate.uuid === certificationId
				);

			dispatch(
				editCompany
					? companyCertificateFilesUpdated(updatedCertificates)
					: certificateFilesUpdated(updatedCertificates)
			);
			setFileCounter((prevState) => prevState + 1);
		}
	}, [
		addCertificateFilesApi.loading,
		addCertificateFilesApi.responseCode,
		addCertificateFilesApi.data,
	]);

	//* =============================================================

	const searchInstituteApi = useApi(
		institutionApi.searchCertificateInstitute,
		true,
		true
	);

	const fetchInstitutions = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await searchInstituteApi.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	if (editMode && !state) {
		return <Navigate to={"/profile/certificate"} />;
	}

	return (
		<>
			<div className={styles.container}>
				<form
					className={styles.form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<div className={styles.form_input_wrapper}>
						<label>
							Title
							<span className="required">*</span>
						</label>

						<TextInput
							error={!!errors.title}
							{...register("title")}
							placeholder="Enter your certificate's title"
						/>

						{errors?.title?.message && (
							<p className={styles.error_message}>
								{errors?.title?.message}
							</p>
						)}
					</div>
					<div className={styles.react_select_wrapper}>
						<label>
							Type
							<span className="required">*</span>
						</label>
						<div className={styles.radio_buttons_container}>
							<div className={styles.radio_button}>
								<button
									type="button"
									className={styles.radio_btn}
									onClick={() =>
										setValue(
											"certificateType",
											"CERTIFICATE"
										)
									}
								>
									<CustomRadioButton
										{...register("certificateType")}
										value="CERTIFICATE"
										name="certificateType"
										id="typeCertificate"
									/>
								</button>
								<label
									htmlFor="typeCertificate"
									className={styles.radioLabel}
								>
									Certificate
								</label>
							</div>
							<div className={styles.radio_button}>
								<button
									type="button"
									className={styles.radio_btn}
									onClick={() =>
										setValue("certificateType", "AWARD")
									}
								>
									<CustomRadioButton
										{...register("certificateType")}
										value="AWARD"
										name="certificateType"
										id="typeAward"
									/>
								</button>
								<label
									htmlFor="typeAward"
									className={styles.radioLabel}
								>
									Award
								</label>
							</div>
						</div>
						{errors?.certificateType?.message && (
							<p
								className={`${styles.error_message} ${styles.certificate}`}
							>
								{errors?.certificateType?.message}
							</p>
						)}
					</div>

					<InfiniteSearchInput
						label={"Training Institution"}
						required={true}
						queryName={"certificateInstitutions"}
						queryFn={fetchInstitutions}
						itemsPerPage={itemsPerPage}
						formatter={defaultFormatter}
						error={errors?.institution}
						control={control}
						name="institution"
						creatable={true}
					/>

					<InfiniteSearchInput
						queryName={"cities"}
						queryFn={fetchLocation}
						itemsPerPage={itemsPerPage}
						formatter={locationFormatter}
						label={"Location"}
						placeholder={"Select a city"}
						required={true}
						error={errors?.city}
						control={control}
						name="city"
					/>

					<div className={styles.form_input_wrapper}>
						<label>Website</label>
						<TextInput
							{...register("website")}
							placeholder="http://www."
						/>
					</div>

					<div className={styles.react_switch}>
						<Controller
							name="switch"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Switch
									checked={value}
									onColor="#6cc5d1"
									offColor="#a6b1bc"
									handleDiameter={18}
									width={39}
									height={24}
									checkedIcon={false}
									uncheckedIcon={false}
									activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
									onChange={(val) => {
										handleWorkStatus();
										onChange(val);
									}}
								/>
							)}
						/>

						<span>Currently pursuing</span>
					</div>

					<div className={styles.datePickerWrapper}>
						<div className={styles.datePickerItem}>
							<label>Start Date</label>
							<DayPicker
								className={`form-start-date ${
									!!errors.startDate ? "error" : undefined
								}`}
								control={control}
								name="startDate"
								isClearable={true}
							/>
							{errors?.startDate?.message && (
								<p className={styles.error_message}>
									{errors?.startDate?.message}
								</p>
							)}
						</div>
						<div className={styles.datePickerItem}>
							<label>End Date</label>
							<DayPicker
								disabled={switchWatcher}
								className={`form-end-date ${
									!!errors.endDate && !switchWatcher
										? "error"
										: undefined
								}`}
								control={control}
								name="endDate"
								isClearable={true}
							/>
							{errors?.endDate?.message && !switchWatcher && (
								<p className={styles.error_message}>
									{errors?.endDate?.message}
								</p>
							)}
						</div>
					</div>

					<div className={styles.add_files_section}>
						<label>Files</label>
						<label className={styles.subLabel}>
							You can upload images and PDF files only.
						</label>

						{/* {files.length > 0 && (
							<div className={styles.thumbs_container}>
								{thumbs}
							</div>
						)} */}

						{files.length > 0 && (
							<ThumbnailContainer
								items={files}
								onDelete={handleFileToRemove}
							/>
						)}

						<div className={styles.file_uploader_wrapper}>
							<SimpleDropZone
								name="files"
								onDrop={handleDroppedFiles}
								acceptedFiles={[
									"image/png, image/jpg, image/jpeg, application/pdf",
								]}
							/>
						</div>
					</div>

					<div
						className={
							styles.form_input_wrapper +
							" " +
							styles.grade_section
						}
					>
						<div className={styles.score_item}>
							<label>Score / Grade</label>
							<TextInput
								{...register("score")}
								placeholder="Your score"
							/>
						</div>
						<div className={styles.score_item}>
							<label>Out of</label>
							<TextInput
								{...register("outOf")}
								placeholder="Max score"
							/>
						</div>
					</div>

					<div className={styles.datePickerWrapper}>
						<div className={styles.datePickerItem}>
							<label>Valid until</label>

							<DayPicker
								className={`form-start-date ${
									!!errors.validity ? "error" : undefined
								}`}
								control={control}
								name="validity"
							/>
							{errors?.validity?.message && (
								<p className={styles.error_message}>
									{errors?.validity?.message}
								</p>
							)}
						</div>
					</div>

					<div className={styles.comments_section}>
						<label>Comments</label>

						<textarea
							{...register("comments")}
							placeholder="Enter your headline"
						/>
					</div>

					<div className={styles.button_container}>
						<PrimaryButton
							className={styles.save_btn}
							text={"save"}
							isLoading={isLoading}
						/>
					</div>
				</form>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default CertificateEntry;
