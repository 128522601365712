import Joi from "joi";

const softwareSchema = Joi.object({
	software: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.required()
		.messages({
			"any.required": "Software can't be empty",
			"object.base": "Software can't be empty",
		})
		.label("Software"),

	expAmount: Joi.object({
		years: Joi.object({
			value: Joi.number(),
			label: Joi.number(),
		}).allow(null),

		months: Joi.object({
			value: Joi.number(),
			label: Joi.number(),
		}).allow(null),
	})
		.or("years", "months", {
			isPresent: (resolved) =>
				resolved !== undefined && resolved !== null,
		})
		.messages({
			"object.missing": "Enter at least one: years or months",
		}),

	proficiency: Joi.number().required().messages({
		"any.required": "Proficiency is required",
		"number.base": "Proficiency is required",
	}),

	activelyGaining: Joi.optional(),
});

export default softwareSchema;
