import websiteIcon from "images/website.svg";
import emailIcon from "images/email.svg";

import styles from "./Email.module.css";
import { getVisibility, getClickableLink } from "../../../../Utils/General";
import EditButton from "components/Utils/Button/EditButton";
import DeleteButton from "components/Utils/Button/DeleteButton";

const EmailItem = ({ item, onEditClicked, onDelete, showControls = true }) => {
	return (
		<div className={styles.item_container}>
			<div className={styles.description}>
				<div className={styles.contact_icon}>
					{item.type === "EMAIL" ? (
						<img src={emailIcon} alt="Email" />
					) : (
						<img src={websiteIcon} alt="Website" />
					)}
				</div>
				<div className={styles.site_description}>
					<div className={styles.site_header}>
						<p>{item.description}</p>
						<div className={styles.visibility}>
							{getVisibility(item.visibility)}
						</div>
					</div>
					<div className={styles.site_wrapper}>
						{item.type === "EMAIL" ? (
							<a
								href={`mailto:${item.site}`}
								target="_blank"
								rel="noreferrer"
							>
								{item.site}
							</a>
						) : (
							<a
								href={getClickableLink(item.site)}
								target="_blank"
								rel="noreferrer"
							>
								{item.site}
							</a>
						)}
					</div>
				</div>
			</div>

			{showControls && (
				<div className={styles.controls}>
					<EditButton onClick={onEditClicked} />
					<DeleteButton onClick={onDelete} />
				</div>
			)}
		</div>
	);
};

export default EmailItem;
