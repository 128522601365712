import locationIcon from "images/location_icon.svg";
import styles from "./Address.module.css";
import { getVisibility } from "../../../../Utils/General";
import StaticMap from "components/Utils/StaticMap/StaticMap";
import EditButton from "components/Utils/Button/EditButton";
import DeleteButton from "components/Utils/Button/DeleteButton";

const AddressItem = ({
	item,
	onEditClicked,
	onDelete,
	showControls = true,
}) => {
	const {
		building,
		label,
		visibility,
		latitude,
		longitude,
		locationDTO: {
			latitude: cityLat,
			longitude: cityLng,
			name: cityName,
			countryDTO: { name: countryName } = {},
		} = {},
	} = item;

	return (
		<div style={{ overflow: "hidden" }}>
			<div className={styles.leftside_header}>
				<span className={styles.item_icon}>
					<img src={locationIcon} alt="Address" />
				</span>
				<div className={styles.right_side}>
					<div className={styles.item_description}>
						<label>{label}</label>
						<div className={styles.visibility_icon}>
							{getVisibility(visibility)}
						</div>
					</div>
					<p>{`${
						building ? `${building},` : ""
					} ${cityName}, ${countryName}`}</p>
				</div>
			</div>

			{showControls && (
				<div className={styles.controls}>
					<EditButton onClick={onEditClicked} />
					<DeleteButton onClick={onDelete} />
				</div>
			)}

			<div>
				<StaticMap
					lat={latitude !== 0 && longitude !== 0 ? latitude : cityLat}
					lng={
						latitude !== 0 && longitude !== 0 ? longitude : cityLng
					}
					zoom="15"
					customStyle={styles.map}
				/>
			</div>
		</div>
	);
};

export default AddressItem;
