import React from "react";
// import { useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";
import BottomNavBar from "../bottomNavBar/BottomNavBar";

import styles from "./LeftSide.module.css";

const LeftSide = ({ children, chat = false, rightProiority, inConvo }) => {
	const { width } = useWindowSize();
	const isMobile = width < 748;

	return (
		<div
			className={`${styles.container}
             ${chat && styles.chat} ${
				chat && rightProiority && styles.right_proiority
			} ${isMobile && inConvo && styles.hide}`}
			// ${isMobile && !showConvoList && styles.hide}
		>
			<div className={styles.left_panel_main_wrapper}>
				<div
					className={`${styles.middle_container} ${
						chat && styles.chat
					}`}
				>
					{children}
				</div>
			</div>

			{/* le bottom bar doit s'expand en full width si pas dans le chat  */}
			<BottomNavBar inConvo={inConvo} />
		</div>
	);
};

export default LeftSide;
