import React from "react";
import styles from "./ProductPage.module.scss";
import { useLocation } from "react-router-dom";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import PrimaryButton from "components/Utils/Button/PrimaryButton";

import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useWindowSize from "hooks/useWindowSize";
import {
	useDispatch,
	//  useSelector
} from "react-redux";
import { setModalProduct } from "store/slices/products";
import { useProductsSearch } from "components/Profile/ProductsTab/hooks";
import ProductsEmpty from "images/box@3x.png";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { AllProductsComponent } from "components/Profile/ProductsTab/ProductsComponents/AllProducts";
// import useCompanyId from "hooks/useCompanyId";
import CtaButton from "components/Utils/Button/CtaButton";
import ProductEntry from "../ProductEntry/ProductEntry";

export const ProductPage = ({ profile, canEdit, productAccordion }) => {
	const dispatch = useDispatch();
	const { width } = useWindowSize();

	const isMobile = width < 748;
	const { state } = useLocation();
	const { filters, q } = state || {};

	const isUncategorizedProducts = filters?.onlyUncategorizedProducts === true;

	const {
		_handleClearSearch,
		handleSearchInputChange,
		isFetching,
		products,
		ref,
		searchInput,
	} = useProductsSearch(
		{
			...filters,
			ownerIds: [profile?.uuid],
		},
		false,
		q
	);
	return (
		<div className={styles.container}>
			<div className={`${styles.search_container}`}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					classNameInput={styles.search_input}
					onClearClicked={_handleClearSearch}
					border={false}
					placeholder={"Search in Products"}
				/>
				<div className={styles.row}>
					<span className={styles.service_name}>
						{isUncategorizedProducts
							? "Uncategorised Products"
							: "All Products"}
					</span>
					{canEdit && (
						<div className={`${styles.btn_wrapper}`}>
							<PrimaryButton
								text={"+ add product"}
								onClick={() => dispatch(setModalProduct(true))}
							/>
						</div>
					)}
				</div>
			</div>

			{isMobile && productAccordion}

			<AllProductsComponent
				visible={true}
				products={products}
				ref={ref}
				Customitems_wrapper={styles.items_wrapper}
				isViewAll
				userUrl={profile?.url}
				product_img_style={styles.product_img}
				canEdit={canEdit}
			/>
			<LoadingSpinner visible={isFetching} />
			<NoResults
				visible={products?.pages[0]?.list?.length === 0}
				text={
					canEdit
						? "Feel free to drop your product right here!"
						: "No products related to your search"
				}
				isSearch={!canEdit}
				image={canEdit && ProductsEmpty}
				button={
					canEdit && (
						<CtaButton
							text={"+ Add Product"}
							onClick={() => dispatch(setModalProduct(true))}
						/>
					)
				}
				customStyle={styles.no_result_conatiner}
				imageStyle={styles.empty_image}
			/>

			<ProductEntry />
		</div>
	);
};
