import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import workStatusApi from "../../../api/workStatus";
import useApi from "../../../hooks/useApi";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import MyCalendar from "./MyCalendar/MyCalendar";
import styles from "./WorkStatus.module.css";
import WorkStatusItem from "./WorkStatusItem/WorkStatusItem";
import openCalendar from "images/open-calendar.svg";
import { employementTypes } from "../../Utils/General";
import useCalendarEntries from "../../../hooks/useCalendarEntries";
import routes from "components/Routing/routing-keys";

const WorkStatus = ({ canEdit, userInfo }) => {
	const getWorkStatusApi = useApi(workStatusApi.getWorkStatus);

	const navigate = useNavigate();

	const { uuid: profileId, calendarEnabled } = userInfo;

	const [workStatus, setWorkStatus] = useState({});

	const {
		available,
		roles = [],
		types = [],
		locations = [],
		legalCountries = [],
		workplace_office,
		workplace_remote,
		workplace_hybrid,
	} = workStatus;

	useEffect(() => {
		getWorkStatusApi.request(profileId);

		return () => {
			setWorkStatus({});
		};
	}, [profileId]);

	useEffect(() => {
		if (
			!getWorkStatusApi.loading &&
			Object.keys(getWorkStatusApi.data).length > 0 &&
			getWorkStatusApi.responseCode === 200
		) {
			setWorkStatus(getWorkStatusApi.data);
		}
	}, [getWorkStatusApi.data]);

	// ! =========== LISTS =============

	const roleItems = useMemo(
		() =>
			roles.map((role) =>
				role.roleDTO ? role.roleDTO.name : role.roleName
			),
		[roles]
	);

	const workplaceItems = [];
	useMemo(() => {
		if (workplace_office) workplaceItems.push("On-site");
		if (workplace_remote) workplaceItems.push("Remote");
		if (workplace_hybrid) workplaceItems.push("Hybrid");
	}, [workplace_hybrid, workplace_office, workplace_remote]);

	const jobItems = useMemo(
		() =>
			types.map(
				(type) =>
					employementTypes.find((jobType) => jobType.value === type)
						.label
			),
		[types]
	);

	const locationsItems = useMemo(
		() =>
			locations.map(
				(location) => `${location.name}, ${location.countryDTO.name}`
			),
		[locations]
	);

	const countriesItems = useMemo(
		() => legalCountries.map((country) => country.countryDTO.name),
		[legalCountries]
	);

	// !=== calendar entries ====

	const { calendarEntries, handleMonthChange, handleDeleteEntry } =
		useCalendarEntries(profileId, undefined, !calendarEnabled && !canEdit);

	const noData =
		roleItems.length === 0 &&
		workplaceItems.length === 0 &&
		locationsItems.length === 0 &&
		jobItems.length === 0 &&
		countriesItems.length === 0;

	return (
		<>
			{available && (
				<div className={styles.work_availability_container}>
					<img src={openCalendar} alt="Open to work" />
					Open to work
				</div>
			)}

			<ProfileTabContainer
				customStyle={styles.calendar_container}
				disabled={!calendarEnabled && !canEdit}
				marginTop={!available}
				minHeight={false}
			>
				<MyCalendar
					userInfo={userInfo}
					calendarEntries={calendarEntries}
					onMonthChange={handleMonthChange}
					canEdit={canEdit}
					onDeleteEntry={handleDeleteEntry}
				/>
			</ProfileTabContainer>

			{(!noData || canEdit) && (
				<ProfileTabContainer
					minHeight={false}
					customStyle={styles.work_status_container}
				>
					{canEdit && (
						<div className={styles.btn_wrapper}>
							<PrimaryButton
								text={"update work status"}
								onClick={() =>
									navigate(routes.updateWorkStatus)
								}
							/>
						</div>
					)}
					{roleItems.length > 0 && (
						<WorkStatusItem title={"Roles"} items={roleItems} />
					)}
					{workplaceItems.length > 0 && (
						<WorkStatusItem
							title={"Workplace"}
							items={workplaceItems}
						/>
					)}
					{locationsItems.length > 0 && (
						<WorkStatusItem
							title={"Locations"}
							items={locationsItems}
						/>
					)}
					{jobItems.length > 0 && (
						<WorkStatusItem title={"Job type"} items={jobItems} />
					)}
					{countriesItems.length > 0 && (
						<WorkStatusItem
							title={"Legal to work in"}
							items={countriesItems}
						/>
					)}
				</ProfileTabContainer>
			)}
		</>
	);
};

export default WorkStatus;
