import React, {
	useState,
	useEffect,
	useImperativeHandle,
	forwardRef,
	useRef,
} from "react";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import styles from "./Filters.module.css";

import {
	defaultFormatter,
	countryFormatter,
	languageFormatter,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";

const TalentDynamicFilters = forwardRef(
	(
		{
			filters: {
				accents = [],
				languages = [],
				ethnicities = [],
				nationalities = [],
				recordingTypes = [],
				voiceCharacteristics = [],
			},
			selectedDynamicFilters,
			onChange,
			category,
		},
		ref
	) => {
		const didMountRef = useRef(false);

		const voiceRef = useRef();
		const accentRef = useRef();
		const languageRef = useRef();
		const ethnicityRef = useRef();
		const nationalityRef = useRef();
		const recordingTypeRef = useRef();

		const [selectedFilters, setSelectedFilters] = useState(
			selectedDynamicFilters
		);

		const {
			accentIds = [],
			languageIds = [],
			voiceCharIds = [],
			ethnicityIds = [],
			nationalityIds = [],
			recordingTypeIds = [],
		} = selectedFilters || {};

		useEffect(() => {
			let timer;
			if (
				didMountRef.current &&
				Object.keys(selectedFilters).length > 0
			) {
				timer = setTimeout(() => {
					onChange(selectedFilters);
				}, 500);
			}
			didMountRef.current = true;
			return () => clearTimeout(timer);
		}, [selectedFilters]);

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "accentIds":
						accentRef.current.removeItem(tag);
						break;
					case "languageIds":
						languageRef.current.removeItem(tag);
						break;
					case "voiceCharIds":
						voiceRef.current.removeItem(tag);
						break;
					case "ethnicityIds":
						ethnicityRef.current.removeItem(tag);
						break;
					case "nationalityIds":
						nationalityRef.current.removeItem(tag);
						break;
					case "recordingTypeIds":
						recordingTypeRef.current.removeItem(tag);
						break;
					default:
						break;
				}
			},
			clear() {
				setSelectedFilters({});
				voiceRef.current?.clear();
				accentRef.current?.clear();
				languageRef.current?.clear();
				ethnicityRef.current?.clear();
				nationalityRef.current?.clear();
				recordingTypeRef.current?.clear();
			},
		}));

		return (
			<div className={styles.container}>
				<div className={styles.padding_container}>
					{ethnicities.length > 0 && (
						<MultiCheckbox
							ref={ethnicityRef}
							title={"Ethnicities"}
							options={ethnicities.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(ethnicities) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									ethnicityIds: ethnicities,
								}));
							}}
							selected={ethnicityIds}
							perRow="2, 150px"
							customStyle={styles.multicheckbox_container}
						/>
					)}

					{nationalities.length > 0 && (
						<MultiCheckbox
							ref={nationalityRef}
							title={"Nationalities"}
							options={nationalities.map((item) =>
								countryFormatter(item)
							)}
							onSelect={(nationalities) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									nationalityIds: nationalities,
								}));
							}}
							selected={nationalityIds}
							perRow="2, 150px"
							customStyle={styles.multicheckbox_container}
						/>
					)}

					{languages.length > 0 && (
						<MultiCheckbox
							ref={languageRef}
							title={"Languages Spoken"}
							options={languages.map((item) =>
								languageFormatter(item)
							)}
							onSelect={(languages) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									languageIds: languages,
								}));
							}}
							selected={languageIds}
							perRow="2, 150px"
							customStyle={styles.multicheckbox_container}
						/>
					)}

					{accents.length > 0 && (
						<MultiCheckbox
							ref={accentRef}
							title={"Dialects Spoken / Accents"}
							options={accents.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(accents) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									accentIds: accents,
								}));
							}}
							selected={accentIds}
							perRow="2, 150px"
							customStyle={styles.multicheckbox_container}
						/>
					)}

					{recordingTypes.length > 0 &&
						(category.value === "VOICE_OVER_ARTIST" ||
							category.value === "SINGER_SONGWRITER") && (
							<MultiCheckbox
								ref={recordingTypeRef}
								title={"Recording Types"}
								options={recordingTypes.map((item) =>
									defaultFormatter(item)
								)}
								onSelect={(recordingTypes) => {
									setSelectedFilters((prevState) => ({
										...prevState,
										recordingTypeIds: recordingTypes,
									}));
								}}
								selected={recordingTypeIds}
								perRow="2, 150px"
								customStyle={styles.multicheckbox_container}
							/>
						)}

					{voiceCharacteristics.length > 0 &&
						(category.value === "VOICE_OVER_ARTIST" ||
							category.value === "SINGER_SONGWRITER") && (
							<MultiCheckbox
								ref={voiceRef}
								title={"Voice Characteristics"}
								options={voiceCharacteristics.map((item) =>
									defaultFormatter(item)
								)}
								onSelect={(voiceChars) => {
									setSelectedFilters((prevState) => ({
										...prevState,
										voiceCharIds: voiceChars,
									}));
								}}
								selected={voiceCharIds}
								perRow="2, 150px"
								customStyle={styles.multicheckbox_container}
							/>
						)}
				</div>
			</div>
		);
	}
);

export default TalentDynamicFilters;
