import { useInfiniteQuery } from "@tanstack/react-query";

const useGetBoards = (
	queryFn,
	queryKey,
	isSearch = false,
	itemsPerPage = 20,
	enabled = true,
	staleTime = undefined
) => {
	const data = useInfiniteQuery({
		queryKey,
		queryFn,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},

		enabled: !!enabled,
		...(isSearch && { cacheTime: 0 }), // won't store search queries in cache
		...(staleTime && { staleTime }), // won't store search queries in cache
		// staleTime: 300000, // 5 minutes
	});

	return data;
};

export default useGetBoards;
