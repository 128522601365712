import client from "./client";

const endpoint = "/posts";

//! Post
const getFeed = (page, size) =>
	client.get(endpoint + `/feeds?page=${page}&size=${size}`);

const getFeedByHashtag = (page, size, hashtag = "") =>
	client.get(
		endpoint +
			`/feeds/hashtag?page=${page}&size=${size}&hashtag=%23${hashtag}`
	);

const getUserFeed = (requestedProfileId, page, size) =>
	client.get(
		endpoint +
			`/getByUser?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}`
	);

const getPostById = (postId) =>
	client.get(endpoint + `/byUuid?postId=${postId}`);

const getServiceMedia = (serviceId, page, size, q = "") =>
	client.get(
		endpoint +
			`/service/media?serviceId=${serviceId}&page=${page}&size=${size}&q=${q}`
	);

const addPost = (data) => client.post(endpoint, data);

const updatePost = (data) => client.put(endpoint, data);

const addMedia = (media) => client.post(endpoint + "/media", media);

const updateMedia = (media) => client.put(endpoint + "/media", media);

const updatePostStatus = (postId) =>
	client.patch(endpoint + `/update/completeStatus?postId=${postId}`);

const deletePost = (postId) =>
	client.delete(endpoint + `?parentPostId=${postId}`);

const deleteMedia = (postId, subPostId) =>
	client.delete(
		endpoint + `/media?parentPostId=${postId}&subPostId=${subPostId}`
	);

const deleteAllMedia = (postId) =>
	client.delete(endpoint + `/media/all?parentPostId=${postId}`);

const removeFromFeed = (postId) =>
	client.delete(endpoint + `/remove?postId=${postId}`);

//! Post Reactions
const addPostReaction = (postId, reaction) =>
	client.put(
		endpoint + `/reaction?postId=${postId}&reactionType=${reaction}`
	);

const removePostReaction = (postId) =>
	client.delete(endpoint + `/reaction?postId=${postId}`);

//! Comments
const getComments = (postId, page, size) =>
	client.get(
		endpoint + `/comment?postId=${postId}&page=${page}&size=${size}`
	);

const addComment = (comment) => client.post(endpoint + "/comment", comment);

const updateComment = (comment) => client.put(endpoint + "/comment", comment);

const deleteComment = (postId, commentId) =>
	client.delete(
		endpoint + `/comment?postId=${postId}&commentId=${commentId}`
	);

//! Comments Reactions

const addCommentReaction = (commentId, reaction) =>
	client.put(
		endpoint +
			`/comment/reaction?commentId=${commentId}&reactionType=${reaction}`
	);

const removeCommentReaction = (commentId) =>
	client.delete(endpoint + `/comment/reaction?commentId=${commentId}`);

//! Replies

const getCommentReplies = (commentId, page, size) =>
	client.get(
		endpoint + `/reply?commentId=${commentId}&page=${page}&size=${size}`
	);

const addReply = (reply) => client.post(endpoint + "/reply", reply);

const updateReply = (reply) => client.put(endpoint + "/reply", reply);

const deleteReply = (replyId) =>
	client.delete(endpoint + `/reply?replyId=${replyId}`);

//! Replies Reactions

const addReplyReaction = (replyId, reaction) =>
	client.put(
		endpoint + `/reply/reaction?replyId=${replyId}&reactionType=${reaction}`
	);

const removeReplyReaction = (replyId) =>
	client.delete(endpoint + `/reply/reaction?replyId=${replyId}`);

const getReaction = (reactionFrom, id, page, size, reactionType = "") => {
	//* reactionFrom = "post" | "comment" | "reply"

	return client.get(
		endpoint +
			`${
				reactionFrom === "comment" || reactionFrom === "reply"
					? `/${reactionFrom}`
					: ""
			}/reaction?${reactionFrom}Id=${id}&page=${page}&size=${size}${
				reactionType && `&type=${reactionType}`
			}`
	);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getFeed,
	getFeedByHashtag,
	getUserFeed,
	getPostById,
	addPost,
	updatePost,
	addMedia,
	getServiceMedia,
	updateMedia,
	updatePostStatus,
	deletePost,
	deleteMedia,
	deleteAllMedia,
	getComments,
	addPostReaction,
	removePostReaction,
	addComment,
	updateComment,
	deleteComment,
	addCommentReaction,
	removeCommentReaction,
	getCommentReplies,
	addReply,
	updateReply,
	deleteReply,
	addReplyReaction,
	removeReplyReaction,
	getReaction,
	removeFromFeed,
};
