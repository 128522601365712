export const boardsKeys = {
	all: ["boards"],

	lists: () => [...boardsKeys.all, "list"],

	list: (category, coordinates) =>
		[...boardsKeys.lists(), { category }, coordinates].filter(
			(item) => item !== undefined
		),

	searchList: (type) =>
		[...boardsKeys.all, "search", type].filter(
			(item) => item !== undefined
		),

	search: (query, coordinates) => [
		...boardsKeys.searchList(),
		query,
		coordinates,
	],

	details: () => [...boardsKeys.all, "details"],

	detail: (boardId) => [...boardsKeys.details(), boardId],

	posts: (boardId) => [...boardsKeys.detail(boardId), "posts"],

	searchPosts: (boardId, searchQuery) => [
		...boardsKeys.searchList("posts"),
		boardId,
		searchQuery,
	],
	moderators: (boardId) => [...boardsKeys.all, "moderators", boardId],
	followers: (boardId, q) => [...boardsKeys.all, "followers", boardId, q],
	pendingFollowers: (boardId) => [
		...boardsKeys.all,
		"pendingFollowers",
		boardId,
	],
};
