import React from "react";

import SliderBar from "../../Utils/SliderBar/SliderBar";

import styles from "./ProfileLeftPanel.module.css";
import ProfileEditCollapsableMenu from "./ProfileEditCollapsableMenu";

const ProfileLeftPanel = ({ activeKey, activeItem, editCompany }) => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {/* //TODO >> profile strength to be adapted based on filled sections */}

                {/* <div className={styles.profile_strength_section}>
                    <p>
                        Profile Strength: <span>Basic</span>
                    </p>

                    <div className={styles.slider_bar_container}>
                        <SliderBar value={[3]} />
                    </div>
                </div> */}

                <div className={styles.collapsable_menu_container}>
                    <ProfileEditCollapsableMenu
                        activeKeyProp={activeKey}
                        activeItemProp={activeItem}
                        editCompany={editCompany}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProfileLeftPanel;
