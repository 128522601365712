import { useState, useEffect } from "react";

export default function useBreadcrumb(folder) {
	const [fullBreadCrumb, setFullBreadCrumb] = useState("");

	useEffect(() => {
		if (folder && folder?.dirs?.length > 0) {
			setFullBreadCrumb(
				`Vault >
                ${folder.dirs
					.reverse()
					.map((parentFolder) => parentFolder.name)
					.join(" > ")}
                > ${folder.name}`
			);
		} else if (folder) {
			setFullBreadCrumb(`Vault > ${folder.name}`);
		} else {
			setFullBreadCrumb("");
		}
	}, [folder]);

	return fullBreadCrumb;
}
