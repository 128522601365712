import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import styles from "./CallbackModal.module.scss";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { callbackSchema } from "./callback-schema";
import { useState } from "react";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { classNames } from "primereact/utils";
import useMutate from "hooks/useMutate";
import paymentApi from "api/payment";
import { toast } from "react-toastify";

const CallbackModal = ({ show, onClose }) => {
	const [unsavedModal, setUnsavedModal] = useState(false);

	const close = () => {
		onClose();
		reset();
	};

	const closeForm = () => {
		if (isDirty) {
			setUnsavedModal(true);
			return;
		}
		close();
	};

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		watch,
		reset,
		// control,
		// setValue,
		// clearErrors,
	} = useForm({
		resolver: joiResolver(callbackSchema),
		mode: "onChange",
		defaultValues: {
			fullName: "",
			position: "",
			contact: "",
			email: "",
			description: "",
		},
	});
	const descriptionWatcher = watch("description");

	const {
		action: { mutate: requestCallback },
	} = useMutate(paymentApi.requestCallback, () => {
		toast.success("Callback request submitted!");
		close();
	});

	return (
		<>
			<GaawkModal
				showHeader={true}
				show={show && !unsavedModal}
				fullWidth={true}
				handleClose={closeForm}
				defaultModal={false}
				title={"Request a Callback"}
				closeAlign={"right"}
			>
				<form
					onSubmit={handleSubmit((data) => requestCallback({ data }))}
					className={styles.container}
					noValidate
				>
					<InputWrapper
						label="Full Name"
						className={styles.no_margin}
						required={true}
						error={errors?.fullName}
						component={
							<TextInput
								{...register("fullName")}
								error={errors.fullName}
								placeholder="Sam Altman"
							/>
						}
					/>
					{/* <span className={styles.character_max}>
						50 characters max
					</span> */}

					<InputWrapper
						label="Position"
						required={true}
						error={errors?.position}
						component={
							<TextInput
								{...register("position")}
								error={errors.position}
								placeholder="CTO, Freelancer, etc."
							/>
						}
					/>

					<InputWrapper
						label="Mobile Number"
						required={true}
						error={errors?.contact}
						component={
							<TextInput
								{...register("contact")}
								error={errors.contact}
								placeholder="ex: 971 XX XXX XXXX"
							/>
						}
					/>

					<InputWrapper
						label="Email"
						required={true}
						error={errors?.email}
						component={
							<TextInput
								{...register("email")}
								error={errors.email}
								placeholder="sam@mail.com"
							/>
						}
					/>

					<InputWrapper
						label="Description"
						// error={errors?.description}
						component={
							<div
								className={classNames(styles.textarea_wrapper, {
									[styles.error]: !!errors.description,
								})}
							>
								<textarea
									{...register("description")}
									placeholder="Write here..."
								/>

								<div className={styles.description_counter}>
									<span
										className={classNames(
											styles.description_watcher,
											{
												[styles.error]:
													descriptionWatcher?.length >
													5000,
											}
										)}
									>
										{descriptionWatcher?.length}/5000
									</span>
								</div>
							</div>
						}
					/>

					<div className={styles.button_container}>
						<PrimaryButton
							text={"Send Request"}
							// isLoading={isLoading}
						/>
					</div>
				</form>
			</GaawkModal>

			<WarningModal
				show={unsavedModal}
				headerText="Are you sure you want to discard unsaved changes?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setUnsavedModal(false)}
				submitButtonText={"DISCARD"}
				onSubmitButtonClicked={() => {
					setUnsavedModal(false);
					close();
				}}
			/>
		</>
	);
};

export default CallbackModal;
