import React from "react";
import SliderBar from "../../../Utils/SliderBar/SliderBar";

import styles from "./Language.module.css";
import blackTickIcon from "images/black-tick-circle-empty-20-x-20.svg";
import { getLanguageProficiency } from "./LanguageProficiency";
import EditButton from "components/Utils/Button/EditButton";
import DeleteButton from "components/Utils/Button/DeleteButton";

const LanguageItem = ({
	item,
	onEditClicked,
	onDelete,
	showControls = true,
}) => {
	const spokenProficiency = getLanguageProficiency(item.spoken);
	const writtenProficiency = getLanguageProficiency(item.written);

	const spokenArr = [];
	spokenArr.push(spokenProficiency.id);

	const writtenArr = [];
	writtenArr.push(writtenProficiency.id);

	return (
		<div key={item.uuid} className={styles.language_item}>
			<div className={styles.language_header}>
				<label className={styles.language_name}>
					{item.language.name}
				</label>
			</div>

			<div className={styles.language_slider_wrapper}>
				<div>
					<label className={styles.proficiency_label}>Spoken</label>

					<SliderBar
						width={"97%"}
						trackColor="#dbdfe4"
						barColor="#6cc5d1"
						maxDomain={5}
						value={[getLanguageProficiency(item.spoken).id]}
						tickImg={blackTickIcon}
						tickWidth={23}
						tickHeight={25}
						margin={-6}
						tickPadding={"1px 0 0 0"}
						labelOnTick={true}
					/>
					<label className={styles.proficiency_grade}>
						{getLanguageProficiency(item.spoken).label}
					</label>
				</div>

				<div>
					<label className={styles.proficiency_label}>Written</label>

					<SliderBar
						width={"97%"}
						trackColor="#dbdfe4"
						barColor="#6cc5d1"
						maxDomain={5}
						value={[getLanguageProficiency(item.written).id]}
						tickImg={blackTickIcon}
						tickWidth={23}
						tickHeight={25}
						margin={-6}
						tickPadding={"1px 0 0 0"}
						labelOnTick={true}
					/>
					<label className={styles.proficiency_grade}>
						{getLanguageProficiency(item.written).label}
					</label>
				</div>
			</div>

			{showControls && (
				<div className={styles.controls}>
					<EditButton onClick={onEditClicked} />
					<DeleteButton onClick={onDelete} />
				</div>
			)}
		</div>
	);
};

export default LanguageItem;
