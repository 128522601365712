import {createSlice} from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
    name: "Places",
    initialState: {
        list: []
    },
    reducers: {
        "placeListUpdated" : (state, action) => {
            state.list = action.payload;
        },
    },
});

export const {placeListUpdated} = slice.actions;
export default slice.reducer;

/**
 * get places list from google api
 */

export const getPlaces = (place) => apiActions.apiCallBegan({
    url: `places/search?place=${place}`,
    method: "GET",
    onSuccess: placeListUpdated.type,
});