import styles from "./FileContainer.module.scss";

const FileContainer = ({ children, isList, marginTop = false }) => {
	return (
		<div
			className={`${styles.container} ${
				isList ? styles.list : undefined
			} ${marginTop ? styles.m0 : undefined}`}
		>
			{children}
		</div>
	);
};

export default FileContainer;
