import { Navigate, useNavigate } from "react-router-dom";
import styles from "./Results.module.scss";
import { useInView } from "react-intersection-observer";
import {
	forwardRef,
	useEffect,
	// useImperativeHandle,
	useMemo,
	// useState,
} from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";
import useApi from "hooks/useApi";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { pluralize } from "components/Utils/General";
import filterIcon from "images/filter-icon-white.svg";
import useMutate from "hooks/useMutate";
import useUserLocation from "hooks/useUserLocation";
import NewJobCard from "../JobsComponents/NewJobCard/NewJobCard";
// import CityModal from "components/Boards/BoardsComponents/CityModal";

const itemsPerPage = 20;

const Results = forwardRef(({ body, searchProps, onFlagChange }, ref) => {
	const queryClient = useQueryClient();

	const { location } = searchProps;
	const { q } = body;

	const searchJobsApi = useApi(jobApi.search, true, true);

	const navigate = useNavigate();

	//! ========= GETTING USER COORDINATES ============

	// const [showModal, setShowModal] = useState(false);

	// useImperativeHandle(ref, () => ({
	// 	showModal() {
	// 		setShowModal(true);
	// 	},
	// }));

	const { userCoordinates } = useUserLocation(onFlagChange);

	// ! ==============================================

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const fetchJobs = async ({ pageParam = 0, signal }) => {
		const response = await searchJobsApi.request(
			pageParam,
			itemsPerPage,
			body
		);
		return response.data;
	};

	const { data, hasNextPage, fetchNextPage, isFetching, isLoading } =
		useInfiniteQuery({
			queryKey: jobsKeys.searchList(body, userCoordinates.id),
			queryFn: fetchJobs,
			getNextPageParam: (lastPage, pages) => {
				const nextPage =
					lastPage.list.length === itemsPerPage
						? pages.length
						: undefined;

				return nextPage;
			},
		});

	const hasResults = useMemo(() => data?.pages[0]?.count > 0, [data]);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	const jobsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.list?.map((job, i) => {
					return (
						<div
							ref={page.list.length === i + 1 ? viewRef : null}
							key={job.uuid}
						>
							<NewJobCard
								data={job}
								onToggleSave={() =>
									handleToggleSave(job.uuid, job.savedJob)
								}
							/>
						</div>
					);
				})
			),
		[data?.pages, viewRef]
	);

	// !===== get recommanded jobs ======
	const getRecommendedJobsApi = useApi(jobApi.getRecommendedJobs, true, true);

	const { ref: recoViewRef, inView: inViewReco } = useInView({
		triggerOnce: true,
	});

	const fetchReco = async ({ pageParam = 0, signal }) => {
		const response = await getRecommendedJobsApi.request(
			pageParam,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const {
		data: recoJobs,
		hasNextPageReco,
		fetchNextPageReco,
		// isFetchingReco,
		// isLoadingReco,
		isInitialLoading,
	} = useInfiniteQuery({
		queryKey: jobsKeys.recommended(userCoordinates),
		queryFn: fetchReco,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},

		enabled: !hasResults && !isFetching,
	});

	const hasReco = useMemo(() => recoJobs?.pages[0]?.length > 0, [recoJobs]);

	useEffect(() => {
		if (inViewReco && hasNextPageReco) {
			fetchNextPageReco();
		}
	}, [inViewReco, hasNextPageReco, fetchNextPageReco]);

	const recoList = useMemo(
		() =>
			recoJobs?.pages?.map((page) =>
				page?.map((job, i) => {
					return (
						<div
							ref={page.length === i + 1 ? recoViewRef : null}
							key={job.uuid}
						>
							<NewJobCard
								data={job}
								onToggleSave={() =>
									handleToggleSave(job.uuid, job.savedJob)
								}
							/>
						</div>
					);
				})
			),
		[recoJobs?.pages, recoViewRef]
	);

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, () =>
		showJobs
			? queryClient.invalidateQueries(
					jobsKeys.searchList(body, userCoordinates.id)
			  )
			: queryClient.invalidateQueries(
					jobsKeys.recommended(userCoordinates)
			  )
	);

	const handleToggleSave = (id, status) => {
		toggleSave({ id, status: !status });
	};

	const showJobs = hasResults && !isLoading;
	const showReco = !hasResults && !isLoading && hasReco && !isInitialLoading;

	if (!body) return <Navigate to="/jobs" />;

	return (
		<>
			{!hasResults && !isLoading && (
				<div className={styles.no_results_container}>
					Sorry! Your search <b>{q ? `for ${q}` : ""}</b>{" "}
					<b>{location ? `in ${location.label}` : ""}</b> didn't
					return any results. Please try to search for a different
					role or in a different location, clear filters...or come
					back and check later. Meanwhile, you can try some of our
					recommandations below.
				</div>
			)}
			{showJobs && (
				<>
					<div className={styles.title_wrapper}>
						<h3>{`${data?.pages[0]?.count} ${pluralize(
							data?.pages[0]?.count,
							"job"
						)} found`}</h3>

						<button
							onClick={() =>
								navigate("/jobs/filter", {
									state: searchProps,
								})
							}
						>
							<img src={filterIcon} alt="" />
						</button>
					</div>
					<div className={styles.container}>{jobsList}</div>
				</>
			)}

			{showReco && (
				<>
					<div className={styles.title_wrapper}>
						<h3>Recommended Jobs</h3>
					</div>

					<div className={styles.container}>{recoList}</div>
				</>
			)}
			<LoadingSpinner visible={isFetching || isInitialLoading} />

			{/* {showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)} */}
		</>
	);
});

export default Results;
