import React from "react";
import Switch from "react-switch";

import styles from "./Filters.module.css";

import DayPicker from "../../../Utils/SubComs/DayPicker/DayPicker";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import { workLocation as workLocationTypes } from "../../../Utils/General";

const FreelanceFilter = ({
    onCalendarChange,
    calendarAvailability,
    onStartDateChange,
    onEndDateChange,
    startDate,
    endDate,
    onWorkLocationChange,
    workLocation,
    onExperienceChange,
    experienceLevel,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.switch_wrapper}>
                    <Switch
                        checked={calendarAvailability}
                        onColor="#6cc5d1"
                        offColor="#a6b1bc"
                        handleDiameter={18}
                        width={39}
                        height={24}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
                        onChange={onCalendarChange}
                    />
                    <span>Show jobs as per Calendar availability</span>
                </div>
            </div>

            {!calendarAvailability && (
                <div className={`${styles.section} ${styles.inline_input}`}>
                    <div className={styles.item}>
                        <label>Start Date</label>
                        <DayPicker
                            className={"form-start-date"}
                            onChange={onStartDateChange}
                            value={startDate}
                            isClearable={true}
                        />
                    </div>
                    <div className={styles.item}>
                        <label>End Date</label>
                        <DayPicker
                            className={"form-end-date"}
                            onChange={onEndDateChange}
                            value={endDate}
                            isClearable={true}
                        />
                    </div>
                </div>
            )}

            <div className={styles.section}>
                <MultiCheckbox
                    title={"Working Location"}
                    options={workLocationTypes}
                    onSelect={onWorkLocationChange}
                    selected={workLocation}
                    type={"radio"}
                />
            </div>

            <div className={styles.section}>
                <div className={styles.form_input_wrapper}>
                    <label>Experience Level</label>
                    <TextInput
                        onChange={onExperienceChange}
                        value={experienceLevel}
                        type="number"
                        placeholder="Enter number of years"
                    />
                </div>
            </div>
        </div>
    );
};

export default FreelanceFilter;
