import { useQuery } from "@tanstack/react-query";
import styles from "./ServiceSection.module.scss";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";
import ServiceItemAlt from "./ServiceItemAlt";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

const ServiceSection = ({
	title,
	queryKey,
	queryFn,
	// errorMessage,
	onViewMore,
}) => {
	const { data, isLoading, fetchStatus } = useQuery({
		queryKey,
		queryFn,
		// ...(errorMessage && {
		// 	meta: {
		// 		errorMessage,
		// 	},
		// }),
		// enabled:
		//     !queryKey[1] || (queryKey[1] && Object.keys(queryKey[1]).length > 0)
		//         ? true
		//         : false,
		staleTime: 300000, // 5 minutes
	});

	const formattedServices = useMemo(
		() =>
			data?.map((service) => (
				<Link
					key={service.uuid}
					className={styles.service_item}
					to={`/services/${service.uuid}`}
				>
					<ServiceItemAlt item={service} />
				</Link>
			)),
		[data]
	);

	return (
		<div className={styles.container}>
			<div className={styles.section_wrapper}>
				<h3>{title}</h3>

				{fetchStatus !== "idle" && isLoading ? (
					<LoadingSpinner />
				) : (
					<Masonry
						breakpointCols={{
							default: 2,
							1399: 1,
						}}
						className={styles.services_container}
						columnClassName={styles.services_container_grid_columns}
					>
						{formattedServices}
					</Masonry>
				)}

				{!isLoading && (
					<div className={styles.button_wrapper}>
						<SeeMore text="See more" onClick={onViewMore} />
					</div>
				)}
			</div>
		</div>
	);
};

export default ServiceSection;
