import {createAction} from "@reduxjs/toolkit";

export const apiCallBegan = createAction("api/callBegan");
export const apiCallSuccess = createAction("api/callSuccess");
export const apiCallFailed = createAction("api/callFailed");

export const socketBegan = createAction("socket/callBegan");

export const storeBegan = createAction("store/callBegan");


