import { useEffect } from "react";
import styles from "./CalendarEntry.module.css";

import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import CustomSelect from "../../Utils/SubComs/CustomSelect/CustomSelect";
import { components } from "react-select";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import useApi from "../../../hooks/useApi";
import calendarApi from "../../../api/calendar";
import { toast } from "react-toastify";
import { entryTypes } from "../../Utils/General";
import { useLocation, useNavigate } from "react-router-dom";

const CalendarEntry = ({ state }) => {
	const navigate = useNavigate();
	const addEntryApi = useApi(calendarApi.addEntry);
	const updateEntryApi = useApi(calendarApi.updateEntry);

	const { pathname } = useLocation();

	const editMode = pathname === "/profile/calendar/edit";
	if (editMode && !state) {
		window.location = "/profile/calendar";
	}

	const {
		label,
		notes,
		startTime,
		endTime,
		type: entryType,
		uuid: entryId,
	} = state || {};

	const schema = Joi.object({
		startDate: Joi.date().required().label("Start Date").messages({
			"any.required": "Start Date should not be empty",
			"date.base": "Start Date should be a valid date",
		}),
		endDate: Joi.date()
			.required()
			.label("End Date")
			.min(Joi.ref("startDate"))
			.messages({
				"any.required": "End Date should not be empty",
				"date.base": "End Date should be a valid date",
				"date.min": "End date should be greater or equal to Start date",
			}),
		entryType: Joi.object({
			value: Joi.string(),
			label: Joi.string(),
		})
			.required()
			.messages({
				"any.required": "Entry type can't be empty",
				"object.base": "Entry type can't be empty",
			})
			.label("Entry type"),
		label: Joi.string().allow("").label("Label"),
		notes: Joi.string().allow("").label("Notes"),
	});

	const {
		formState: { errors },
		register,
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					startDate: new Date(startTime),
					endDate: new Date(endTime),
					entryType: entryTypes.find(
						(entry) => entry.value === entryType
					),
					label,
					notes,
			  }
			: {
					startDate: null,
					endDate: null,
					entryType: null,
					label: "",
					notes: "",
			  },
	});

	// ! SAVE ========================================================

	const handleSave = (data) => {
		const {
			label,
			notes,
			startDate,
			endDate,
			entryType: { value: type },
		} = data;

		const body = {
			...(editMode && { entryId }),
			startDate: startDate.getTime(),
			endDate: endDate.getTime(),
			label,
			notes,
			type,
		};

		if (editMode) updateEntryApi.request(body);
		else addEntryApi.request(body);
	};

	useEffect(() => {
		if (!addEntryApi.loading && addEntryApi.responseCode === 200)
			navigate("/profile/calendar");
		else if (!addEntryApi.loading && addEntryApi.responseCode === 406) {
			toast.error("You already have a calendar entry during these dates");
		}
	}, [addEntryApi.loading, addEntryApi.responseCode]);

	useEffect(() => {
		if (!updateEntryApi.loading && updateEntryApi.responseCode === 200)
			navigate("/profile/calendar");
		else if (
			!updateEntryApi.loading &&
			updateEntryApi.responseCode === 406
		) {
			toast.error("You already have a calendar entry during these dates");
		}
	}, [updateEntryApi.loading, updateEntryApi.responseCode]);

	return (
		<form
			className={styles.calendar_form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<div className={styles.form_input_wrapper}>
				<label className={styles.input_label}>
					Entry Type<span className="required">*</span>
				</label>
				<CustomSelect
					options={entryTypes}
					placeholder="Select an entry"
					isSearchable={false}
					components={{
						Option: (props) => {
							const label = props.data.label;
							return (
								<components.Option {...props}>
									<div className={styles.option_item}>
										<div
											className={styles.color_pin}
											style={{
												backgroundColor:
													label === "Booked"
														? "#ffad5c"
														: label === "Penciled"
														? "#83d1ad"
														: "#a6b1bc",
											}}
										/>
										{label}
									</div>
								</components.Option>
							);
						},
						Control: ({ children, ...props }) => {
							const label = props.getValue()[0]?.label;
							return (
								<components.Control {...props}>
									{label !== undefined && (
										<div
											className={`${styles.color_pin} ${styles.control}`}
											style={{
												backgroundColor:
													label === "Booked"
														? "#ffad5c"
														: label === "Penciled"
														? "#83d1ad"
														: "#a6b1bc",
											}}
										/>
									)}
									{children}
								</components.Control>
							);
						},
					}}
					height={35}
					control={control}
					name="entryType"
					error={!!errors.entryType}
				/>
				{errors?.entryType?.message && (
					<p className={styles.error_message}>
						{errors?.entryType?.message}
					</p>
				)}
			</div>

			<div className={styles.datePickerWrapper}>
				<div className={styles.datePickerItem}>
					<label>
						Start Date
						<span className="required">*</span>
					</label>
					<DayPicker
						className={`form-start-date ${
							!!errors.startDate ? "error" : undefined
						}`}
						control={control}
						name="startDate"
					/>
					{errors?.startDate?.message && (
						<p className={styles.error_message}>
							{errors?.startDate?.message}
						</p>
					)}
				</div>
				<div className={styles.datePickerItem}>
					<label>
						End Date
						<span className="required">*</span>
					</label>
					<DayPicker
						className={`form-end-date ${
							!!errors.endDate ? "error" : undefined
						}`}
						control={control}
						name="endDate"
					/>
					{errors?.endDate?.message && (
						<p className={styles.error_message}>
							{errors?.endDate?.message}
						</p>
					)}
				</div>
			</div>

			<div className={styles.form_input_wrapper}>
				<label className={styles.input_label}>Label</label>
				<TextInput {...register("label")} placeholder={"Add label"} />
			</div>

			<div className={styles.textarea_container}>
				<label>Notes</label>
				<textarea {...register("notes")} placeholder="Enter notes" />
			</div>

			<div className={styles.button_wrapper}>
				<PrimaryButton
					type="submit"
					// disabled={xxxApi.loading}
					text={"save"}
					className={`${styles.button}`}
				/>
			</div>
		</form>
	);
};

export default CalendarEntry;
