import actorImg from "images/actor.jpg";
import singerImg from "images/singer.jpg";
import voiceoverImg from "images/voiceover.jpg";
import dancerImg from "images/dancer.jpg";
import modelImg from "images/model.jpg";
import entertainerImg from "images/entertainer.jpg";

export const talentCategories = [
	{
		uuid: "ACTOR_ACTRESS",
		name: "Actor / Actress",
		image: actorImg,
	},
	{
		uuid: "SINGER_SONGWRITER",
		name: "Singer / Songwriter",
		image: singerImg,
	},
	{
		uuid: "VOICE_OVER_ARTIST",
		name: "Voice Over",
		image: voiceoverImg,
	},
	{
		uuid: "DANCER",
		name: "Dancer",
		image: dancerImg,
	},
	{
		uuid: "MODEL",
		name: "Model",
		image: modelImg,
	},
	{
		uuid: "ENTERTAINER",
		name: "Entertainer",
		image: entertainerImg,
	},
];
