import { useQuery, useQueryClient } from "@tanstack/react-query";
import GroupMember from "components/Chat/GroupMember";
import routes from "components/Routing/routing-keys";
import { pluralize } from "components/Utils/General";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { format } from "date-fns";
import useJob from "hooks/useJob";
import useMutate from "hooks/useMutate";
import usePaginatedResource from "hooks/usePaginatedResource";
import moreIcon from "images/three-dots-black-5-x-20.svg";
import thumbIcon from "images/thumb-icon.svg";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import jobApi from "../../../api/job";
import useApi from "../../../hooks/useApi";
import Popover from "../../Utils/Popover/Popover";
import NoResults from "../../Utils/SubComs/NoResults/NoResults";
import TabMenu from "../../Utils/SubComs/TabMenu/TabMenu";
import Tag from "../../Utils/SubComs/Tags/Tag";
import styles from "./JobApplicants.module.css";

const itemsPerPage = 20;

const statusType = {
	new: 1,
	shortlisted: 2,
	turnedDown: 3,
};

const JobApplicants = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { jobId } = useParams();
	const { data: job, isFetching: isFetchingJob } = useJob(jobId);

	const popoverRef = useRef(null);

	const { name: jobName, project, insertionTime } = job || {};

	const validateTab = (tab) => {
		const validTabs = ["new", "shortlisted", "turnedDown"];
		return validTabs.includes(tab) ? tab : "new";
	};

	const [activeTab, setActiveTab] = useState("new");

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const currentTab = searchParams.get("tab");
		const validTab = validateTab(currentTab);

		if (currentTab !== validTab) {
			navigate(`${location.pathname}?tab=${validTab}`, { replace: true });
		}
		setActiveTab(validTab);
	}, [location, navigate]);

	const handleTabChange = (tab) => {
		const validTab = validateTab(tab);
		navigate(`${location.pathname}?tab=${validTab}`, { replace: true });
		setActiveTab(validTab);
	};

	//! applicants paginated ===========

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getApplicantsApi = useApi(jobApi.getApplicants, true, true);

	const fetchApplicants = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, jobId, statusId] = queryKey;

		const response = await getApplicantsApi.request(
			jobId,
			pageParam,
			itemsPerPage,
			statusId
		);
		return response.data;
	};
	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			jobsKeys.jobApplicants(jobId, statusType[activeTab]),
			fetchApplicants,
			itemsPerPage
		);

	console.log("🚀 ~ data >>", data);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	//! ==========APPLICATIONS COUNTS=============

	const getApplicantsCountApi = useApi(jobApi.getApplicantsCount, true, true);

	const fetchApplicationsCount = async ({ queryKey }) => {
		const [_, __, jobId] = queryKey;
		const response = await getApplicantsCountApi.request(jobId);
		return response.data;
	};

	const {
		data: applicationsCount,
		// isLoading,
		// isFetching,
		// isError,
	} = useQuery({
		queryKey: jobsKeys.applicationsCount(jobId),
		queryFn: fetchApplicationsCount,
		enabled: !!job,
	});

	//! =======================

	const queryClient = useQueryClient();

	const {
		action: { mutate: updateApplicationStatus },
	} = useMutate(jobApi.updateApplicantStatus, () => {
		queryClient.invalidateQueries(jobsKeys.applicationsCount(jobId));
		queryClient.invalidateQueries(
			jobsKeys.jobApplicants(jobId, statusType[activeTab])
		);
	});

	const handleApplicationStatus = useCallback((applicant, status) => {
		updateApplicationStatus({
			applicationId: applicant.uuid,
			status,
		});
	}, []);

	const applicantsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((applicant, i) => (
					<GroupMember
						key={applicant.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						redirect={false}
						data={applicant.minifiedProfile}
						onClick={() =>
							navigate(routes.applicant(job.uuid, applicant.uuid))
						}
						rightSideAction={
							<Popover
								ref={popoverRef}
								render={
									<div className="popover_container">
										{(applicant.status === "NEW" ||
											applicant.status ===
												"TURNED_DOWN") && (
											<div
												className="popover_item"
												onClick={(e) => {
													e.stopPropagation();
													handleApplicationStatus(
														applicant,
														"SHORTLISTED"
													);
													popoverRef.current.closePopover();
												}}
											>
												<img src={thumbIcon} alt="" />
												<span>Shortlist Applicant</span>
											</div>
										)}

										{(applicant.status === "NEW" ||
											applicant.status ===
												"SHORTLISTED") && (
											<div
												className="popover_item"
												onClick={(e) => {
													e.stopPropagation();
													handleApplicationStatus(
														applicant,
														"TURNED_DOWN"
													);
													popoverRef.current.closePopover();
												}}
											>
												<img
													src={thumbIcon}
													className={
														styles.turndown_icon
													}
													alt=""
												/>
												<span>Turn Down Applicant</span>
											</div>
										)}
									</div>
								}
							>
								<button
									onClick={(e) => e.stopPropagation()}
									className={styles.option_icon}
								>
									<img src={moreIcon} alt="options" />
								</button>
							</Popover>
						}
					/>
				))
			),
		[data, handleApplicationStatus, job, navigate, viewRef]
	);

	if (isFetchingJob) return <LoadingPage />;

	return (
		<>
			<div className={styles.tab_menu}>
				<div className={styles.job_container}>
					<h3>{jobName}</h3>
					{project && (
						<div className={styles.project_wrapper}>
							<Tag
								itemName={"Project"}
								customStyle={styles.project_tag}
							/>
							<h4>{project.title}</h4>
						</div>
					)}

					<div className={styles.job_footer}>
						Posted on:{" "}
						{format(new Date(insertionTime), "dd/MM/yyyy")}
						{applicationsCount &&
							` | ${applicationsCount.total} ${pluralize(
								applicationsCount.total,
								"Applicant"
							)}, ${applicationsCount.newCount} New`}
					</div>
				</div>

				<TabMenu
					menuItems={{
						new: "NEW APPLICANTS",
						shortlisted: "SHORTLISTED",
						turnedDown: "TURNED DOWN",
					}}
					selectedTab={activeTab}
					onSelectedTabChanged={handleTabChange}
					isLoading={isFetching}
				/>
			</div>
			{data?.pages?.[0]?.length > 0 && (
				<div className={styles.applicants_container}>
					{applicantsList}
					<LoadingSpinner visible={isFetching} />
				</div>
			)}
			<NoResults
				visible={data?.pages?.[0]?.length === 0 && !isFetching}
				text={"Nothing to show here yet!"}
				customStyle={styles.info_text}
			/>
		</>
	);
};

export default JobApplicants;
