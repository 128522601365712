import routes from "components/Routing/routing-keys";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useVaultStorage from "hooks/useVaultStorage";
import { ReactComponent as FolderIcon } from "images/vault-storage-folder.svg";
import { useNavigate } from "react-router-dom";
import {
	formatBytes,
	getStorageWarningText,
	showStorageWarning,
} from "../Utils/General";
import styles from "./VaultHeader.module.css";
import StorageCard from "components/Storage/StorageComponents/StorageCard";
import { classNames } from "primereact/utils";

const VaultHeader = ({ onResizeWindow }) => {
	const navigate = useNavigate();

	const { data, isFetching, isError, usedSpaceInPercentage } =
		useVaultStorage();

	const {
		galleryImage,
		galleryPDF,
		galleryVideo,
		vaultAudio,
		vaultFiles,
		vaultImage,
		vaultPDF,
		vaultVideo,
		totalStorage,
	} = data;

	const imageSize = galleryImage + vaultImage;
	const videoSize = galleryVideo + vaultVideo;
	const otherSize = galleryPDF + vaultPDF + vaultAudio + vaultFiles;
	const usedSize = imageSize + videoSize + otherSize;

	if (isError) return null;

	if (isFetching)
		return (
			<div className={styles.container}>
				<LoadingSpinner customStyle={styles.blackBg} />
			</div>
		);

	return (
		<div
			ref={(node) => onResizeWindow(node?.clientHeight)}
			className={styles.container}
		>
			<div className={styles.inner_wrapper}>
				<div className={styles.vault_details}>
					<p>
						<FolderIcon fill="#FFF" />
						{`${formatBytes(usedSize)} / ${formatBytes(
							totalStorage
						)}`}
					</p>
					<SeeMore
						onClick={() => navigate(routes.storage)}
						color="#dbdfe4"
						text="Manage Storage"
					/>
				</div>

				<div className={styles.storage_indicator}>
					<div
						className={classNames(styles.used_container, {
							[styles.critical]: showStorageWarning(
								usedSpaceInPercentage
							),
						})}
						style={{
							width:
								usedSize >= totalStorage
									? "100%"
									: `${usedSpaceInPercentage}%`,
						}}
					/>
				</div>

				<div className={styles.detailed_size}>
					<p>Image {formatBytes(imageSize)}</p>
					<p>Video {formatBytes(videoSize)}</p>
					<p>Other {formatBytes(otherSize)}</p>
				</div>
			</div>

			{showStorageWarning(usedSpaceInPercentage, data) && (
				<StorageCard
					className={styles.storage_warning}
					text={getStorageWarningText(
						"default",
						usedSpaceInPercentage,
						data
					)}
				/>
			)}
		</div>
	);
};

export default VaultHeader;
