import { useState } from "react";
import styles from "./Help.module.scss";
import TopBar from "components/Static/TopBar";
import LandingPageFooter from "components/Landing/LandingPageFooter";
import HelpInput from "components/Static/Help/HelpInput";
import HelpTextArea from "components/Static/Help/HelpTextArea";
import HelpUploadFile from "components/Static/Help/HelpUploadFile";
import helpApi from "api/help";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import helpSchema from "./help-schema";
import useMutate from "hooks/useMutate";
import PrimaryButton from "components/Utils/Button/PrimaryButton";

const Help = () => {
	const [success, setSuccess] = useState(false);

	const {
		action: { mutate: submitHelpRequest, isLoading },
	} = useMutate(helpApi.addHelpRequest, () => setSuccess(true));

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
		control,
		setValue,
		clearErrors,
	} = useForm({
		resolver: joiResolver(helpSchema),
		mode: "all",
	});

	const descriptionWatcher = watch("description");
	const fileWatcher = watch("file");

	const handleSave = (data) => {
		const { name, phone, email, description, file } = data;

		const formData = new FormData();
		formData.append("contact", phone);
		formData.append("description", description);
		formData.append("email", email);
		formData.append("name", name);
		if (file) formData.append("file", file, file.name);

		submitHelpRequest(formData);
	};

	return (
		<div className={styles.container}>
			<TopBar />

			<div className={styles.titleContainer}>
				<div className={styles.title}>
					<span className={styles.titleText1}>Help</span>
					<span className={styles.titleText2}>And</span>
					<span className={styles.titleText1}>Support</span>
				</div>
				<span className={styles.subTitle}>
					Our help and support services are here to assist you
					promptly with any questions or issues you may encounter,
					ensuring a smooth and satisfying experience. Your
					satisfaction and success are our top priorities.
				</span>
			</div>

			{success ? (
				<div className={styles.successContainer}>
					<img
						src={require("images/check_colored.svg").default}
						alt={"success"}
					/>
					<span>
						Your message has been successfully submitted, and we
						will get back to you asap!
					</span>
				</div>
			) : (
				<form
					onSubmit={handleSubmit((data) => handleSave(data))}
					className={styles.contentWrapper}
					noValidate
				>
					<HelpInput
						{...register("name")}
						placeholder={"Your Name"}
						error={errors.name}
					/>

					<HelpInput
						{...register("email")}
						placeholder={"Email"}
						error={errors.email}
					/>

					<HelpInput
						{...register("phone")}
						placeholder={"Phone"}
						error={errors.phone}
					/>

					<HelpTextArea
						{...register("description")}
						value={descriptionWatcher}
						placeholder={"Let’s talk about your problem"}
						error={errors.description}
					/>

					{fileWatcher && (
						<div className={styles.fileUploadedView}>
							<img
								alt={"check"}
								className={styles.icon1}
								src={
									require("images/check_colored.svg").default
								}
							/>
							<span
								className={styles.text}
							>{`${fileWatcher?.name}`}</span>
							<button
								type="button"
								onClick={() => {
									setValue("file", undefined);
									clearErrors("file");
								}}
							>
								<img
									alt={"delete"}
									className={styles.icon2}
									src={
										require("images/delete-black.svg")
											.default
									}
								/>
							</button>
						</div>
					)}

					<Controller
						name="file"
						control={control}
						render={({
							field: { onChange, value },
							fieldState,
						}) => (
							<HelpUploadFile
								file={value}
								text={"Upload Additional file"}
								onFileUploaded={onChange}
								error={fieldState.error}
							/>
						)}
					/>

					<PrimaryButton
						text={"submit"}
						isLoading={isLoading}
						className={styles.button}
					/>
				</form>
			)}

			<LandingPageFooter />
		</div>
	);
};

export default Help;
