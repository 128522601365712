import React, { forwardRef } from "react";
import styles from "./button.module.css";

const SecondaryButton = forwardRef(
	({ text, onClick, className, type = "button" }, ref) => {
		return (
			<button
				ref={ref}
				className={`${className} ${styles.secondary}`}
				onClick={onClick}
				type={type}
			>
				{text}
			</button>
		);
	}
);

export default SecondaryButton;
