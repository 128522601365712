import MainHeader from "components/Utils/SubComs/MainHeader/MainHeader";
import BottomNavBar from "../../SubComs/bottomNavBar/BottomNavBar";
import styles from "./DefaultFullPage.module.scss";
import Footer from "components/Utils/Footer/Footer";

const DefaultFullPage = ({
	content,
	padding = true,
	showBackButton = false,
	backLabel = "",
}) => {
	return (
		<>
			<MainHeader showBackButton={showBackButton} backLabel={backLabel} />

			<div className={styles.background}>
				<div
					className={`${styles.container} ${
						padding ? styles.with_padding : undefined
					}`}
				>
					{content}
				</div>
				<Footer />
			</div>

			<BottomNavBar fluid={true} />
		</>
	);
};

export default DefaultFullPage;
