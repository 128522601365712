import { useEffect, useState } from "react";

const useCompanyId = () => {
	const [companyId, setCompanyId] = useState(() =>
		localStorage.getItem("companyId")
	);

	useEffect(() => {
		const handleStorageChange = (e) => {
			setCompanyId(e.target.localStorage.companyId);
		};

		window.addEventListener("storage", handleStorageChange);

		return () => {
			window.removeEventListener("storage", handleStorageChange);
		};
	}, []);

	return companyId;
};

export default useCompanyId;
