import client from "./client";

const endpoint = "/workStatus";

const getWorkStatus = (requestedProfileId) => client.get(endpoint + `?requestedProfileId=${requestedProfileId}`);
const updateWorkStatus = (body) => client.put(endpoint, body);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getWorkStatus,
    updateWorkStatus,
};
