export const getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (
            addressArray[i].types[0] &&
            "administrative_area_level_2" === addressArray[i].types[0]
        ) {
            area = addressArray[i].long_name;
            return area;
        }
    }
};

export const getBuilding = (addressArray) => {
    let building = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (
            addressArray[i].types[0] &&
            "premise" === addressArray[i].types[0]
        ) {
            building = addressArray[i].long_name;
            return building;
        }
    }
};

export const getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
            street = addressArray[i].long_name;
            return street;
        }
    }
};

export const getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if (
                    // "sublocality_level_1" === addressArray[i].types[j] ||
                    "locality" === addressArray[i].types[j]
                ) {
                    city = addressArray[i].long_name;
                    return city;
                }
            }
        }
    }
};

export const getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if (
                    // "sublocality_level_1" === addressArray[i].types[j] ||
                    "country" === addressArray[i].types[j]
                ) {
                    country = addressArray[i].long_name;
                    return country;
                }
            }
        }
    }
};

// export const getCountryCode = (addressArray) => {
//     let countryCode = "";
//     for (let i = 0; i < addressArray.length; i++) {
//         if (addressArray[i].types[0]) {
//             for (let j = 0; j < addressArray[i].types.length; j++) {
//                 if (
//                     // "sublocality_level_1" === addressArray[i].types[j] ||
//                     "country" === addressArray[i].types[j]
//                 ) {
//                     countryCode = addressArray[i].short_name;
//                     return countryCode;
//                 }
//             }
//         }
//     }
// };

export const getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (
            addressArray[i].types[0] &&
            "administrative_area_level_1" === addressArray[i].types[0]
        ) {
            state = addressArray[i].long_name;
            return state;
        }
    }
};

export const getZipCode = (addressArray) => {
    let zipCode = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (
            addressArray[i].types[0] &&
            "postal_code" === addressArray[i].types[0]
        ) {
            zipCode = addressArray[i].long_name;
            return zipCode;
        }
    }
};
