import Joi from "joi";

const reportSchema = Joi.object({
	reason: Joi.string()
		.required()
		.label("reason")
		.messages({ "string.empty": "Reason can't be empty" }),
	reasonText: Joi.when("reason", {
		is: Joi.string().valid("other"),
		then: Joi.string()
			.required()
			.label("reason text")
			.messages({ "string.empty": "Please provide a reason" }),
		otherwise: Joi.optional(),
	}),
	message: Joi.string().allow("").label("message"),
});

export default reportSchema;
