import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tag from "../../../Utils/SubComs/Tags/Tag";
import styles from "./SpecialProfile.module.scss";
import SpecialProfileItem from "./SpecialProfileItem";
import AdditionalDetails from "./SpecialProfileSections/AdditionalDetails";
import GeneralDetails from "./SpecialProfileSections/GeneralDetails";
import Media from "./SpecialProfileSections/Media";
import PhysicalDetails from "./SpecialProfileSections/PhysicalDetails";
import VocalDetails from "./SpecialProfileSections/VocalDetails";
import SpecialSection from "./SpecialSection";
import { differenceInYears } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

import copyIcon from "images/copy-icon.svg";
import { toast } from "react-toastify";
import AgencyEntry from "./SpecialProfileForms/AgencyEntry/AgencyEntry";
import LinkToAgencyModal from "./LinkToAgencyModal";
import useApi from "hooks/useApi";
import profileApi from "api/profile";
import specialProfileApi from "api/special-profile";
import { copyToClipboard } from "components/Utils/General";
import useMutate from "hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";
import { updateSpecialProfileCounts } from "store/slices/user";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { ReactComponent as ReportIcon } from "images/report.svg";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import routes from "components/Routing/routing-keys";
import useGetQueryParam from "hooks/useGetQueryParam";

const SpecialProfile = ({ profile: specialProfile, canEdit }) => {
	const { userUrl } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const unlinkAgencyApi = useApi(profileApi.unlinkAgency, true);
	const reportRef = useRef();

	const { paramValue: agencyId } = useGetQueryParam("agency");

	const { uuid, specialProfileCounts } = useSelector(
		(state) => state.user.profileInfo
	);

	const [profile, setProfile] = useState();

	useEffect(() => {
		setProfile(specialProfile);
	}, [specialProfile]);

	const {
		uuid: specialProfileId,
		bio,
		accents = [],
		ethnicity,
		languages = [],
		nationalities = [],
		physicalDetails,
		imagesCount,
		videosCount,
		audiosCount,
		info,
		skills = [],
		vocalDetails,
		agencies = [],
		gender,
		customGender,
		age,
	} = profile || {};

	const [agencyModal, setAgencyModal] = useState(false);

	const handleCopyLink = () => {
		copyToClipboard(
			`${window.location.origin}${routes.specialProfile(userUrl)}`
		);
		toast.success("Link copied !");
	};

	const handleDeleteAgency = async (agency) => {
		const response = await unlinkAgencyApi.request(
			agency.uuid,
			specialProfileId
		);

		if (response.status === 200) {
			setProfile((prevState) => ({
				...prevState,
				agencies: prevState.agencies.filter(
					(item) => item.uuid !== agency.uuid
				),
			}));
		} else {
			toast.error("Something Wrong Happened");
		}
	};

	const {
		action: { mutate: deleteSpecialProfile },
	} = useMutate(specialProfileApi.deleteSpecialProfile, () => {
		queryClient.invalidateQueries(specialProfileKeys.lists(uuid));
		dispatch(updateSpecialProfileCounts(specialProfileCounts - 1));
		navigate(routes.specialProfilesList());
	});

	return (
		<>
			{profile && (
				<div className={styles.image_container}>
					<SpecialProfileItem
						item={profile}
						showUrl={true}
						canEdit={canEdit}
						onDelete={() => deleteSpecialProfile(profile.uuid)}
						redirect={false}
						agencyId={agencyId}
					/>
				</div>
			)}

			<div className={styles.base_info}>
				<div className={styles.base_info_wrapper}>
					<Tag
						itemName={
							customGender
								? customGender?.[0]?.toUpperCase() +
								  customGender?.slice(1)?.toLowerCase()
								: gender?.[0]?.toUpperCase() +
								  gender?.slice(1)?.toLowerCase()
						}
					/>
					<Tag
						itemName={`${differenceInYears(
							new Date(),
							new Date(age)
						)} Years`}
					/>
				</div>
			</div>

			<SpecialSection
				label={bio ? "Summary" : "General Details"}
				canEdit={canEdit}
				content={<GeneralDetails data={profile} />}
				hasContent={
					bio ||
					accents.length > 0 ||
					ethnicity ||
					languages.length > 0 ||
					nationalities.length > 0
						? true
						: false
				}
				noBorder={true}
				btnText="+ Add General Details"
				onEdit={() =>
					navigate(routes.specialProfile(userUrl, "/edit/general"), {
						state: { agencyId },
					})
				}
			/>

			<SpecialSection
				label={"Physical Details"}
				canEdit={canEdit}
				content={<PhysicalDetails data={physicalDetails} />}
				hasContent={
					physicalDetails &&
					(physicalDetails.chest > 0 ||
						physicalDetails.height > 0 ||
						physicalDetails.shoe > 0 ||
						physicalDetails.waist > 0 ||
						physicalDetails.weight > 0 ||
						physicalDetails.buildType ||
						physicalDetails.eyeColor ||
						physicalDetails.hairColor ||
						physicalDetails.hairLength ||
						physicalDetails.hairStyle)
				}
				onEdit={() =>
					navigate(routes.specialProfile(userUrl, "/edit/physical"), {
						state: { agencyId },
					})
				}
			/>

			<SpecialSection
				label={"Media"}
				canEdit={canEdit}
				content={<Media specialProfileId={specialProfileId} />}
				hasContent={
					imagesCount > 0 || videosCount > 0 || audiosCount > 0
				}
				onEdit={() =>
					navigate(routes.specialProfile(userUrl, "/edit/media"), {
						state: { agencyId },
					})
				}
			/>

			<SpecialSection
				label={"Vocal Details"}
				canEdit={canEdit}
				content={<VocalDetails data={vocalDetails} />}
				hasContent={
					vocalDetails &&
					(vocalDetails.pitch !== "N_A" ||
						vocalDetails.vocalAge !== "N_A" ||
						vocalDetails.vocalRange !== "N_A" ||
						vocalDetails.recordingTypes.length > 0 ||
						vocalDetails.voiceCharacteristics.length > 0)
				}
				onEdit={() =>
					navigate(routes.specialProfile(userUrl, "/edit/vocal"), {
						state: { agencyId },
					})
				}
			/>

			<SpecialSection
				label={"Additional Details"}
				canEdit={canEdit}
				content={<AdditionalDetails skills={skills} info={info} />}
				hasContent={skills.length > 0 || info}
				onEdit={() =>
					navigate(routes.specialProfile(userUrl, "/edit/more"), {
						state: { agencyId },
					})
				}
			/>

			<SpecialSection
				label={"List with an Agency"}
				canEdit={canEdit}
				content={
					<AgencyEntry
						data={agencies}
						canEdit={canEdit}
						onClick={() => setAgencyModal(true)}
						onDelete={handleDeleteAgency}
						disabled={profile?.privacy === "PRIVATE"}
					/>
				}
				hasContent={agencies.length > 0}
				btnText="link with an agency"
				onEdit={() => setAgencyModal(true)}
				disableEdit={true}
				disabled={profile?.privacy === "PRIVATE"}
				warningContent={
					profile?.privacy === "PRIVATE" && (
						<div className={styles.warning_info}>
							<img
								src={require("images/caution.svg").default}
								alt=""
							/>
							<p>
								With a private profile, you can’t link with an
								agency. Please come back later to this option
								once you change privacy of your profile.
							</p>
						</div>
					)
				}
			/>

			<div className={styles.share_section}>
				<button className={styles.share_item} onClick={handleCopyLink}>
					<img className={styles.icon} src={copyIcon} alt="copy" />
					<span>Copy link</span>
				</button>

				<button
					className={styles.share_item}
					onClick={() =>
						reportRef.current.showReportModal(specialProfileId)
					}
				>
					<ReportIcon className={styles.icon} fill={"#6cc5d1"} />
					<span>Report</span>
				</button>
			</div>

			{agencyModal && (
				<LinkToAgencyModal
					show={agencyModal}
					onClose={() => setAgencyModal(false)}
					specialProfileId={specialProfileId}
					onSuccess={() => toast.success("Request Sent Successfully")}
					onError={(error) =>
						toast.error(error ?? "Something Wrong Happened")
					}
				/>
			)}

			<ReportModal ref={reportRef} category="specialProfile" />
		</>
	);
};

export default SpecialProfile;
