export const specialProfileTypes = [
    {
        label: "Actor / Actress",
        value: "ACTOR_ACTRESS",
    },
    {
        label: "Singer / Songwriter",
        value: "SINGER_SONGWRITER",
    },
    {
        label: "Model",
        value: "MODEL",
    },
    {
        label: "Voice Over Artist",
        value: "VOICE_OVER_ARTIST",
    },
    {
        label: "Dancer",
        value: "DANCER",
    },
    {
        label: "Entertainer",
        value: "ENTERTAINER",
    },
];

export const buildTypes = [
    {
        label: "Slim",
        value: "SLIM",
    },
    {
        label: "Average",
        value: "AVERAGE",
    },
    {
        label: "Athletic",
        value: "ATHLETIC",
    },
    {
        label: "Few Extra Pounds",
        value: "EXTRA",
    },
];

export const eyeHairColor = [
    {
        label: "Black",
        value: "BLACK",
    },
    {
        label: "Grey",
        value: "GREY",
    },
    {
        label: "Brown",
        value: "BROWN",
    },
    {
        label: "Blond",
        value: "BLOND",
    },
    {
        label: "Other",
        value: "OTHER",
    },
];

export const hairLength = [
    {
        label: "Long",
        value: "LONG",
    },
    {
        label: "Very Long",
        value: "VERY_LONG",
    },
    {
        label: "Fair",
        value: "FAIR",
    },
    {
        label: "Short",
        value: "SHORT",
    },
    {
        label: "Very Short",
        value: "VERY_SHORT",
    },
    {
        label: "Bald",
        value: "BALD",
    },
];

export const hairStyle = [
    {
        label: "Straight",
        value: "STRAIGHT",
    },
    {
        label: "Wavy",
        value: "WAVY",
    },
    {
        label: "Curly",
        value: "CURLY",
    },
];

export const vocalAge = [
    {
        label: "Young Adult",
        value: "YOUNG_ADULT",
    },
    {
        label: "Old",
        value: "OLD",
    },
    {
        label: "Adult",
        value: "ADULT",
    },
    {
        label: "Child",
        value: "CHILD",
    },
    {
        label: "Baby",
        value: "BABY",
    },
];

export const vocalRange = [
    {
        label: "Soprano",
        value: "SOPRANO",
    },
    {
        label: "Mezzo-Soprano",
        value: "MEZZO_SOPRANO",
    },
    {
        label: "Alto",
        value: "ALTO",
    },
    {
        label: "Tenor",
        value: "TENOR",
    },
    {
        label: "Baritone",
        value: "BARITONE",
    },
    {
        label: "Bass",
        value: "BASS",
    },
];

export const pitch = [
    {
        label: "Low Pitch",
        value: "LOW",
    },
    {
        label: "Normal Pitch",
        value: "NORMAL",
    },
    {
        label: "High Pitch",
        value: "HIGH",
    },
];
