import { useMemo } from "react";
import styles from "./PostDocuments.module.scss";
import PdfItem from "components/Utils/SubComs/PdfItem/PdfItem";

const PostDocuments = ({
	items,
	canEdit = false,
	onClick,
	onDelete,
	isShared = false,
}) => {
	const pdfList = useMemo(
		() =>
			items.map((item, index) => {
				return (
					<PdfItem
						key={index}
						item={item.file || item.multipartFile}
						canEdit={canEdit}
						onClick={() => onClick && onClick(index)}
						onDelete={() => onDelete(item, index)}
						isShared={isShared}
					/>
				);
			}),
		[items]
	);

	return <div className={styles.container}>{pdfList}</div>;
};

export default PostDocuments;
