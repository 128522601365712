import React from "react";
// import Tag from "../../Utils/SubComs/Tags/Tag";
import styles from "./FilterRightSide.module.css";
import LoadMoreButton from "../../Utils/Button/LoadMoreButton";
import filterIcon from "images/filter-icon-white.svg";
import NoResults from "../../Utils/SubComs/NoResults/NoResults";

const FilterRightSide = ({
	data = [],
	hasMore,
	loading,
	onLoad,
	onShowModal,
	isMobile,
	title,
	tagsList = [],
}) => {
	return (
		<>
			<div
				className={`${styles.title_wrapper} ${
					tagsList.length > 0 ? undefined : styles.no_bottom_padding
				}`}
			>
				{title && <h3>{title}</h3>}

				{isMobile && (
					<button onClick={onShowModal}>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList.length > 0 && (
				<div className={styles.active_filters}>{tagsList}</div>
			)}

			<NoResults
				visible={data?.length === 0 && !loading}
				text="Sorry, looks like there are no result here."
			/>

			{data.length > 0 && (
				<div className={styles.talent_container}>{data}</div>
			)}

			<LoadMoreButton
				hasMore={hasMore}
				loading={loading}
				onLoad={onLoad}
			/>
		</>
	);
};

export default FilterRightSide;
