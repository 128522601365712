import phoneLandline from "images/phone_landline.svg";
import phoneMobile from "images/smartphone.svg";

import styles from "./Phone.module.css";
import { getVisibility } from "../../../../Utils/General";
import EditButton from "components/Utils/Button/EditButton";
import DeleteButton from "components/Utils/Button/DeleteButton";

const PhoneItem = ({ item, onEditClicked, onDelete, showControls = true }) => {
	return (
		<>
			<div className={styles.item_container}>
				<div className={styles.description}>
					<div className={styles.contact_icon}>
						{item.type === "LANDLINE" ? (
							<img src={phoneLandline} alt="Landline" />
						) : (
							<img src={phoneMobile} alt="Mobile" />
						)}
					</div>
					<div style={{ overflow: "hidden" }}>
						<div className={styles.phone_header}>
							<p>{item.description}</p>
							<div className={styles.visibility}>
								{getVisibility(item.visibility)}
							</div>
						</div>
						<div className={styles.phonenumber_wrapper}>
							<a
								href={`tel:${item.code}${item.mobile}`}
							>{`${item.code} ${item.mobile}`}</a>
						</div>
					</div>
				</div>

				{showControls && (
					<div className={styles.controls}>
						<EditButton onClick={onEditClicked} />
						<DeleteButton onClick={onDelete} />
					</div>
				)}
			</div>
		</>
	);
};

export default PhoneItem;
