import styles from "./ProductsItems.module.scss";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import { Link, useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

export const UncategorisedProductsComponent = ({
	UncategorizedProducts,
	visible,
	userUrl,
}) => {
	const navigate = useNavigate();

	if (!visible) return null;
	return (
		<div className={styles.service_products_container}>
			<div className={styles.name_image_container}>
				<span className={styles.service_name}>
					Un-categorised Products
				</span>

				<SeeMore
					text="See All"
					onClick={() => {
						navigate(`/profile/${userUrl}/products`, {
							state: {
								filters: {
									onlyUncategorizedProducts: true,
								},
							},
						});
					}}
				/>
			</div>
			<ScrollContainer className={styles.scroll_container}>
				{UncategorizedProducts?.list?.map((product) => {
					const { mainImage, name, uuid: productId } = product;

					return (
						<Link
							key={productId}
							className={styles.container_product_image}
							to={`/profile/${product?.owner?.url}/product/${productId}`}
						>
							<div>
								<LazyImage
									image={
										<img
											src={mainImage?.file.customName}
											alt={name}
										/>
									}
									inlineStyle={styles.product_img}
								/>
							</div>
							<span className={styles.product_name}>{name}</span>
						</Link>
					);
				})}
			</ScrollContainer>
		</div>
	);
};
