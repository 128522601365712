import { useDropzone } from "react-dropzone";
// import galleryIcon from "images/gallery-v2.svg";
import blockIcon from "images/block-icon.svg";
import acceptedIcon from "images/blue-tick-35-x-35.svg";
import newFile from "images/upload-file-icon.svg";

import styles from "./SimpleDropZone.module.css";
import { useForm } from "react-hook-form";

const SimpleDropZone = ({
	onDrop,
	acceptedFiles = undefined,
	name,
	multiple = true,
	className,
}) => {
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		fileRejections,
	} = useDropzone({
		onDrop,
		onError: (err) => console.log("error >>", err),
		...(acceptedFiles && { accept: { acceptedFiles } }),
		multiple: multiple,
	});

	const { register } = useForm();

	return (
		<>
			<div
				{...getRootProps({
					className: `${styles.dropzone} ${className}`,
				})}
			>
				<input
					{...(name
						? getInputProps({ ...register(name) })
						: getInputProps())}
				/>
				<img src={newFile} alt="File icon" />

				{isDragActive ? (
					<>
						{isDragAccept && (
							<p>
								<img
									src={acceptedIcon}
									className={styles.status_icon}
									alt="file accepted"
								/>
								All files will be accepted
							</p>
						)}
						{isDragReject && (
							<p>
								<img
									src={blockIcon}
									className={styles.status_icon}
									alt="file not accepted"
								/>
								Some files will be rejected
							</p>
						)}
					</>
				) : (
					<p>
						Drag & drop your files or Click here to select your
						files from your computer
					</p>
				)}
			</div>

			{fileRejections.length > 0 && (
				<div className={styles.rejected_container}>
					<h4>
						{fileRejections.length > 1
							? "These files are "
							: "This file is "}{" "}
						not accepted:
					</h4>
					<ul>
						{fileRejections.map((item) => (
							<li>{item.file.name}</li>
						))}
					</ul>
				</div>
			)}
		</>
	);
};

export default SimpleDropZone;
