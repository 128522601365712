import React, { useEffect, useMemo, useRef, useState } from "react";
import specialProfileApi from "../../api/special-profile";
import profileApi from "../../api/profile";
import TalentItem from "../../components/Profile/TalentTab/TalentItem";
import Filter from "../../components/Utils/Filter/Filter";
import {
	removeItemIdFromArray,
	replaceItemInArray,
	specialProfileTypes,
} from "../../components/Utils/General";
import Popover from "../../components/Utils/Popover/Popover";
import useApi from "../../hooks/useApi";
import useCompanyId from "../../hooks/useCompanyId";
import useFetchProfile from "../../hooks/useFetchProfile";

import chatIcon from "images/comment-black.svg";
import deleteIcon from "images/delete-black.svg";
import moreIcon from "images/3dot-grey.svg";
import editIcon from "images/edit-black.svg";
import useDirectMessage from "../../hooks/useDirectMessage";
import AliasModal from "../../components/Profile/TalentTab/AliasModal";
import MultiCheckbox from "../../components/Utils/MultiCheckbox/MultiCheckbox";
import PhysicalFilters from "../../components/Profile/TalentTab/Filters/PhysicalFilters";
import VocalFilters from "../../components/Profile/TalentTab/Filters/VocalFilters";
import TalentDynamicFilters from "../../components/Profile/TalentTab/Filters/TalentDynamicFilters";
import Tag from "../../components/Utils/SubComs/Tags/Tag";
import styles from "../../components/Utils/Filter/Filter.module.css";
import useFilteredData from "../../hooks/useFilteredData";
import DeleteItemModal from "../../components/Utils/DeleteItemModal/DeleteItemModal";
import { toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindowSize";
import FilterRightSide from "../../components/Profile/TalentTab/FilterRightSide";
import FilterLeftSide from "../../components/Profile/TalentTab/FilterLeftSide";
import { useLocation, useNavigate } from "react-router-dom";
import useCurrentUser from "hooks/useCurrentUser";

const TalentFilterView = ({ isGeneralSearch = false }) => {
	// const { state: { category: cat = "ACTOR_ACTRESS" } = {} } = useLocation();

	const cat = useLocation().state?.category || "ACTOR_ACTRESS";

	const leftSideRef = useRef();
	const didMountRef = useRef(false);
	const didMount2Ref = useRef(false);

	const navigate = useNavigate();
	const companyId = useCompanyId();
	const currentUser = useCurrentUser();

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const [filterModal, setFilterModal] = useState();

	useEffect(() => {
		// closes the filter modal if opened in mobile,
		// and then switching to a desktop breakpoint
		if (filterModal && !isMobile) setFilterModal(false);
	}, [isMobile, filterModal]);

	const { profile, canEdit } = useFetchProfile(currentUser, companyId);

	if (profile && !profile.agency && !isGeneralSearch)
		navigate(`/profile/${profile.url}`);

	const [category, setCategory] = useState(() =>
		specialProfileTypes.find((type) => type.value === cat)
	);

	const unlinkAgencyApi = useApi(profileApi.unlinkAgency, true);
	const searchTalentsApi = useApi(specialProfileApi.search, true);
	const searchFiltersApi = useApi(specialProfileApi.searchFilters);

	const {
		filters,
		handleReloadData,
		handleLoadData,
		handleLoadFilters,
		data: talents,
		setData,
		hasMore,
		loading,
		selectedStaticFilters,
		selectedDynamicFilters,
		dynamicFilters,
		handleResetFilters,
		handleFilterChange,
		handleDynamicFilters,
	} = useFilteredData(
		searchTalentsApi,
		searchFiltersApi,
		10,
		true,
		!isGeneralSearch
	);

	useEffect(() => {
		if (didMountRef.current) {
			handleLoadData(
				!isGeneralSearch && profile ? profile.uuid : undefined,
				0,
				{
					typeIds: [category.value],
					...filters,
				}
			);
			handleLoadFilters(
				!isGeneralSearch && profile ? profile.uuid : undefined,
				{
					typeIds: [category.value],
					...filters,
				}
			);
		}
		didMountRef.current = true;
	}, [filters, profile]);

	useEffect(() => {
		if (didMount2Ref.current) {
			leftSideRef.current.handleClearFields();
			handleResetFilters();
		}
		didMount2Ref.current = true;
	}, [category.value]);

	const popoverRef = useRef(null);

	const { handleDirectMessage } = useDirectMessage();

	const [selectedTalent, setSelectedTalent] = useState();
	const [aliasModal, setAliasModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);

	const talentsList = useMemo(
		() =>
			talents.map((talent) => (
				<TalentItem
					key={talent.uuid}
					data={talent}
					rightAction={
						<Popover
							ref={popoverRef}
							render={
								<div className="popover_container">
									<button
										className="popover_item"
										onClick={() => {
											handleDirectMessage(talent.owner);
										}}
									>
										<img src={chatIcon} alt="" />
										<span>Message Talent</span>
									</button>
									{canEdit && !isGeneralSearch && (
										<>
											<button
												className="popover_item"
												onClick={() => {
													popoverRef.current.closePopover();
													setSelectedTalent(talent);
													setAliasModal(true);
												}}
											>
												<img src={editIcon} alt="" />
												<span>Rename Alias</span>
											</button>
											<button
												className="popover_item"
												onClick={() => {
													popoverRef.current.closePopover();
													setSelectedTalent(talent);
													setDeleteModal(true);
												}}
											>
												<img src={deleteIcon} alt="" />
												<span>Remove Talent</span>
											</button>
										</>
									)}
								</div>
							}
						>
							<button onClick={(e) => e.stopPropagation()}>
								<img src={moreIcon} alt="options" />
							</button>
						</Popover>
					}
				/>
			)),
		[talents]
	);

	const handleUpdateTalent = (talent) => {
		setData((prevState) => replaceItemInArray(talent, prevState, "uuid"));
	};

	const handleDeleteReload = async () => {
		setDeleteModal(false);

		const response = await unlinkAgencyApi.request(
			profile.uuid,
			selectedTalent.uuid
		);

		if (response.status === 200) {
			setData((prevState) =>
				removeItemIdFromArray([selectedTalent], prevState)
			);
			if (hasMore)
				handleReloadData(
					!isGeneralSearch && profile ? profile.uuid : undefined,
					undefined,
					{
						typeIds: [category.value],
						...filters,
					}
				);
		} else {
			toast.error("Something Wrong Happaned");
		}
	};

	const handleDeleteTag = (key, tag) => {
		leftSideRef.current.updateDynamicFilters(key, tag);
	};

	const tagsList = useMemo(() => {
		const valuesList = [];
		for (const [k, v] of Object.entries(selectedDynamicFilters)) {
			if (v.length > 0) {
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.label}
							itemName={`${item.label}`}
							onRemove={() => handleDeleteTag(k, item)}
							customStyle={styles.filter_tag}
						/>
					)
				);
			}
		}
		return valuesList;
	}, [selectedDynamicFilters]);

	const activeFilter = useMemo(() => {
		switch (true) {
			case category.value === "ACTOR_ACTRESS" ||
				category.value === "MODEL" ||
				category.value === "ENTERTAINER" ||
				category.value === "DANCER":
				return (
					<PhysicalFilters
						id="staticFilter" //required for the child component
						selectedStaticFilters={selectedStaticFilters}
						onChange={handleFilterChange}
					/>
				);

			case category.value === "VOICE_OVER_ARTIST" ||
				category.value === "SINGER_SONGWRITER":
				return (
					<VocalFilters
						id="staticFilter" //required for the child component
						selectedStaticFilters={selectedStaticFilters}
						onChange={handleFilterChange}
					/>
				);

			default:
				break;
		}
	}, [category, selectedStaticFilters]);

	return (
		<>
			<Filter
				title="Talents"
				isMobile={isMobile}
				onCloseModal={() => setFilterModal(false)}
				hiddenMode={!filterModal}
				leftSideChildren={
					<FilterLeftSide
						ref={leftSideRef}
						onResetFilter={handleResetFilters}
						leftSideChildren={[
							<MultiCheckbox
								id="otherFilter" //required for the child component
								title={"Profile Type"}
								options={specialProfileTypes}
								onSelect={(type) => {
									setCategory(type[0]);
								}}
								selected={[category]}
								perRow="2, 150px"
								type="radio"
							/>,
							activeFilter,
							<TalentDynamicFilters
								id="dynamicFilter" //required for the child component
								category={category}
								filters={dynamicFilters || {}}
								onChange={handleDynamicFilters}
								selectedDynamicFilters={selectedDynamicFilters}
							/>,
						]}
					/>
				}
				rightSideChildren={
					<FilterRightSide
						data={talentsList}
						title="Talents"
						tagsList={tagsList}
						hasMore={hasMore}
						loading={loading}
						isMobile={isMobile}
						onShowModal={() => setFilterModal(true)}
						onLoad={() =>
							handleLoadData(
								!isGeneralSearch && profile
									? profile.uuid
									: undefined,
								undefined,
								{
									typeIds: [category.value],
									...filters,
								},
								true
							)
						}
					/>
				}
			/>

			{aliasModal && (
				<AliasModal
					show={aliasModal}
					onClose={() => setAliasModal(false)}
					data={selectedTalent}
					onUpdate={handleUpdateTalent}
				/>
			)}

			<DeleteItemModal
				show={deleteModal}
				onClose={() => setDeleteModal(false)}
				title="Remove Talent"
				text={`You are about to remove the talent ${
					selectedTalent?.alias || selectedTalent?.name
				}. This action can NOT be undone.`}
				onConfirm={handleDeleteReload}
			/>
		</>
	);
};

export default TalentFilterView;
