import { useState } from "react";
import TopBar from "components/Static/TopBar";
import LandingPageFooter from "components/Landing/LandingPageFooter";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import FAQItem from "components/Static/Faq/FAQItem";
import styles from "./Faq.module.scss";

const Faq = () => {
	// states
	const [selected, setSelected] = useState(undefined);
	const [list, setList] = useState([
		{
			id: 1,
			title: "Where can I watch?",
			description:
				"Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla. Elit adipiscing proin quis est consectetur. Felis ultricies nisi, quis malesuada sem odio. Potentiмnibh natoque amet amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam tincidunt condimentum nullam.",
			selected: false,
		},
		{
			id: 2,
			title: "WTempus magna risus interdum ultricies sed urna?",
			description:
				"Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla. Elit adipiscing proin quis est consectetur. Felis ultricies nisi, quis malesuada sem odio. Potentiмnibh natoque amet amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam tincidunt condimentum nullam.",
			selected: false,
		},
		{
			id: 3,
			title: "Augue in nibh urna volutpat mattis?",
			description:
				"Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla. Elit adipiscing proin quis est consectetur. Felis ultricies nisi, quis malesuada sem odio. Potentiмnibh natoque amet amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam tincidunt condimentum nullam.",
			selected: false,
		},
		{
			id: 4,
			title: "Eu egestas sed sed posuere ultrices ?",
			description:
				"Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla. Elit adipiscing proin quis est consectetur. Felis ultricies nisi, quis malesuada sem odio. Potentiмnibh natoque amet amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam tincidunt condimentum nullam.",
			selected: false,
		},
		{
			id: 5,
			title: " Elementum facilisi aliquam, nisi, orci vulputate?",
			description:
				"Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla. Elit adipiscing proin quis est consectetur. Felis ultricies nisi, quis malesuada sem odio. Potentiмnibh natoque amet amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam tincidunt condimentum nullam.",
			selected: false,
		},
		{
			id: 6,
			title: "Nibh at odio dolor etiam neque in vel id orci?",
			description:
				"Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla. Elit adipiscing proin quis est consectetur. Felis ultricies nisi, quis malesuada sem odio. Potentiмnibh natoque amet amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam tincidunt condimentum nullam.",
			selected: false,
		},
		{
			id: 7,
			title: "Non dolor at velit lorem erat maecenas?",
			description:
				"Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla. Elit adipiscing proin quis est consectetur. Felis ultricies nisi, quis malesuada sem odio. Potentiмnibh natoque amet amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam tincidunt condimentum nullam.",
			selected: false,
		},
	]);

	// JSX
	return (
		<div className={styles.container}>
			<TopBar />

			<div className={styles.titleWrapper}>
				<div className={styles.imgContainer}>
					<LazyImage
						inlineStyle={styles.img}
						image={
							<img
								alt={"faq_img"}
								src={
									"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/cutted_faq.png"
								}
							/>
						}
					/>
				</div>
				<div className={styles.titleContainer}>
					<div className={styles.title}>
						<span className={styles.titleText1}>Frequently</span>
						<span className={styles.titleText2}>Asked</span>
						<span className={styles.titleText3}>Questions</span>
					</div>
					<span className={styles.subTitle}>
						Please review all user inquiries, and if you cannot find
						the information you're looking for, kindly contact our
						support center for assistance.
					</span>
				</div>
			</div>

			<div className={styles.contentWrapper}>
				<span className={styles.contentTitle}>FAQ</span>
				<div className={styles.faqWrapper}>
					{list.map((item) => (
						<FAQItem
							key={item.id}
							item={item}
							selected={item.id === selected}
							onSelect={(itemId) => setSelected(itemId)}
						/>
					))}
				</div>
			</div>

			<LandingPageFooter />
		</div>
	);
};

export default Faq;
