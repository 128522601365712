import client from "./client";

const endpoint = "/accent";

const getAccents = () => client.get(endpoint);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAccents,
};
