import rolesApi from "api/roles";
import useApi from "./useApi";

const useFetchRoles = (itemsPerPage = 20) => {
	const searchRolesApi = useApi(rolesApi.getRoles, true, true);

	const fetchRoles = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await searchRolesApi.request(
			pageParam,
			itemsPerPage,
			searchInput
		);

		return response.data;
	};

	return fetchRoles;
};

export default useFetchRoles;
