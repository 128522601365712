import styles from "./TipsSlider.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import arrowIcon from "images/icon-navigate-forward@2x.png";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";

const TipsSlider = ({ data, isLoading }) => {
	const tipsList = data?.map((tip, i) => (
		<SwiperSlide key={i} className={styles.tip_wrapper}>
			<div className={styles.tip_item}>
				<LazyImage
					width={80}
					image={<img src={tip.image} alt="" />}
					inlineStyle={styles.tip_image}
				/>

				<div className={styles.right_side}>
					<div className={styles.header}>
						<h3>{tip.title}</h3>
						<GaawkLink>
							<span>Find out</span> <img src={arrowIcon} alt="" />
						</GaawkLink>
					</div>
					<div className={styles.description}>{tip.description}</div>
				</div>
			</div>
		</SwiperSlide>
	));

	if (isLoading) return <LoadingSpinner />;

	return (
		<Swiper
			slidesPerView={"auto"}
			spaceBetween={5}
			centeredSlides={true}
			className={styles.tipsSwiper}
		>
			{tipsList}
		</Swiper>
	);
};

export default TipsSlider;
