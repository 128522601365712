import { useMemo } from "react";
import styles from "./ServicesTab.module.css";
import WarningModal from "../../Utils/GaawkModal/WarningModal";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import { useDispatch } from "react-redux";
import { setModal } from "../../../store/slices/service";
import useWindowSize from "../../../hooks/useWindowSize";
import { useServiceDelete, useGetServices } from "./hooks";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import ServiceItem from "./ServicesComponent/ServiceItem";
import NoResults from "../../Utils/SubComs/NoResults/NoResults";
import CtaButton from "components/Utils/Button/CtaButton";
import ServiceEntry from "./ServiceAdd/ServiceEntry";
import ServicesTree from "./ServicesComponent/ServicesTree";
import useVaultStorage from "hooks/useVaultStorage";
import {
	getStorageWarningText,
	showStorageWarning,
} from "components/Utils/General";
import StorageCard from "components/Storage/StorageComponents/StorageCard";

const ServicesTab = ({ canEdit, userInfo }) => {
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const isMobile = width < 748;

	const { ref, inView } = useInView({ triggerOnce: true });

	const { data, usedSpaceInPercentage } = useVaultStorage();

	const {
		usedServicesCount,
		currentPlan: { services: servicesAdCount } = {},
	} = data;

	const {
		uuid: userId,
		hasServices, //! this can't be used because user profile is not invalidated and getting fresh data !
		type,
		firstName,
		lastName,
		name: companyName,
	} = userInfo;
	const name = type === "USER" ? `${firstName} ${lastName}` : companyName;

	const {
		handleConfirmServiceDelete,
		handleRequestServiceDelete,
		serviceWarningModal,
		setServiceWarningModal,
	} = useServiceDelete();

	const {
		handleSearchInputChange,
		isLoadingLevel1,
		isStale,
		searchInput,
		_handleClearSearch,
		services,
	} = useGetServices(userId, inView);

	const servicesList = useMemo(() => {
		return services?.pages?.map((page) =>
			page.map((service, index) => {
				if (searchInput.length === 0 ? service.level === 1 : true) {
					return (
						<Link
							key={service.uuid}
							ref={ref}
							className={styles.service_full_view_item}
							to={`/services/${service.uuid}`}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<ServiceItem
								item={service}
								index={index}
								isLastItem={index === page.length - 1}
								onDelete={() =>
									handleRequestServiceDelete(service.uuid)
								}
								canEdit={canEdit}
							/>
						</Link>
					);
				}
				return null;
			})
		);
	}, [services, searchInput, canEdit]);

	return (
		<>
			<div className={styles.service_tab_container}>
				{canEdit && hasServices && (
					<div className={styles.btn_wrapper}>
						<CtaButton
							text={"+ add service"}
							onClick={() => dispatch(setModal(true))}
							className={styles.custom_btn}
							disabled={usedServicesCount >= servicesAdCount}
						/>
					</div>
				)}

				{canEdit && showStorageWarning(usedSpaceInPercentage, data) && (
					<div className={styles.warning_wrapper}>
						<StorageCard
							text={getStorageWarningText(
								"productsAndServices",
								usedSpaceInPercentage,
								data
							)}
						/>
					</div>
				)}

				{/* //TODO >> should not rely on 'hasServices' because we're not invalidating the userProfile when deleting or creating a service */}
				{hasServices && (
					<div className={styles.search_container}>
						<SearchInput
							onChange={handleSearchInputChange}
							value={searchInput}
							showIcons={true}
							classNameInput={styles.search_input}
							onClearClicked={_handleClearSearch}
							border={false}
							placeholder={`Search Services by ${name}`}
						/>
					</div>
				)}
				{isMobile && (
					<div style={{ padding: "20px" }}>
						<ServicesTree userId={userId} enabled={true} />
					</div>
				)}
				<LoadingSpinner visible={isLoadingLevel1} />

				{hasServices && (
					<div
						className={`${styles.services_container} ${
							isStale ? styles.is_stale : undefined
						}`}
					>
						{servicesList}

						<NoResults
							isSearch={true}
							visible={
								servicesList &&
								servicesList[0]?.length === 0 &&
								searchInput
							}
							text={"No services related to your search"}
						/>
					</div>
				)}

				<NoResults
					visible={!hasServices && !isLoadingLevel1}
					text="Feel free to drop your service right here!"
					button={
						canEdit && (
							<CtaButton
								text={"+ Add Service"}
								onClick={() => dispatch(setModal(true))}
							/>
						)
					}
				/>
			</div>

			<WarningModal
				show={serviceWarningModal}
				modalOnTop={false}
				headerText="Are you sure you want to delete this Service? All associated sub-services will be deleted as well."
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setServiceWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmServiceDelete}
			/>

			<ServiceEntry />
		</>
	);
};

export default ServicesTab;
