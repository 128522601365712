import Joi from "joi";

const experienceSchema = Joi.object({
    role: Joi.object({
        value: Joi.string(),
        label: Joi.string(),
        __isNew__: Joi.boolean(),
    })
        .required()
        .messages({
            "any.required": "Role can't be empty",
            "object.base": "Role can't be empty",
        })
        .label("Role"),

    employementType: Joi.object({
        value: Joi.string(),
        label: Joi.string(),
    })
        .required()
        .messages({
            "any.required": "Employement type can't be empty",
            "object.base": "Employement type can't be empty",
        })
        .label("Employement Type"),

    companyName: Joi.object({
        value: Joi.string(),
        label: Joi.string(),
        __isNew__: Joi.boolean(),
    })
        .required()
        .messages({
            "any.required": "Company name can't be empty",
            "object.base": "Company name can't be empty",
        })
        .label("Company Name"),

    city: Joi.object({
        value: Joi.number(),
        label: Joi.string(),
        // countryCode: Joi.string(),
    })
        .required()
        .messages({
            "any.required": "Location can't be empty",
            "object.base": "Location can't be empty",
        })
        .label("Location"),
    workSwitch: Joi.boolean(),
    startDate: Joi.date()
        .required()
        .messages({
            "any.required": "Start date can't be empty",
            "date.base": "Start date can't be empty",
        })
        .label("Start date"),
    endDate: Joi.date()
        .min(Joi.ref("startDate"))
        .when("workSwitch", {
            is: false,
            then: Joi.required()
                .messages({
                    "any.required": "End date can't be empty",
                    "date.base": "End date can't be empty",
                    "date.min":
                        "End date should be greater or equal to Start date",
                })
                .label("End date"),
        })
        .when("workSwitch", {
            is: true,
            then: Joi.allow(null).label("End date"),
        }),
    headline: Joi.string().allow("").label("Headline"),
    details: Joi.string().allow("").label("Details"),
});

export default experienceSchema;
