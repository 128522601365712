import styles from "./HomeRightSidebar.module.scss";
import SuggestedProfiles from "./SuggestedProfiles";
import PendingRequests from "./PendingRequests";
import RecentConnections from "./RecentConnections";
import SuggestedServices from "./SuggestedServices";

const HomeRightSidebar = () => {
	return (
		<div className={styles.container}>
			<PendingRequests />

			<SuggestedProfiles />

			<RecentConnections />

			<SuggestedServices />
		</div>
	);
};

export default HomeRightSidebar;
