import { useEffect, useState } from "react";
import styles from "./ForwardModal.module.css";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import MessageInfoUser from "./MessageInfoUser";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SearchInput from "../Utils/SubComs/Inputs/SearchInput/SearchInput";
import useApi from "../../hooks/useApi";
import chatApi from "../../api/chat";
import InfiniteScroll from "react-infinite-scroll-component";
import { List } from "react-content-loader";

const conversationsPerPage = 5;
const convResultsPerPage = 10;

const ForwardModal = ({
	isModalOpened,
	onClose,
	onSelect,
	selectedConvs,
	onForward,
}) => {
	const getConversationsApi = useApi(chatApi.getConversations);
	const getFreqConversationsApi = useApi(chatApi.getFreqConversations);
	const searchConversationsApi = useApi(chatApi.searchConversations);

	//! ================ LOAD ALL CONVERSATIONS ================

	const [conversations, setConversations] = useState([]);
	const [convPage, setConvPage] = useState(0);

	const loadNextConvs = () => {
		getConversationsApi.request(convPage, conversationsPerPage);
	};

	useEffect(() => {
		loadNextConvs();
	}, []);

	useEffect(() => {
		if (
			!getConversationsApi.loading &&
			getConversationsApi.data &&
			getConversationsApi.data.length > 0
		) {
			if (conversations.length === 0)
				setConversations(getConversationsApi.data);
			else
				setConversations((prevState) => [
					...prevState,
					...getConversationsApi.data,
				]);

			setConvPage((prevState) => prevState + 1);
		}
	}, [getConversationsApi.data]);

	//! ================ LOAD FRQ CONVERSATIONS ================

	const [freqConversations, setFreqConversations] = useState([]);

	useEffect(() => {
		getFreqConversationsApi.request();
	}, []);

	useEffect(() => {
		if (
			!getFreqConversationsApi.loading &&
			getFreqConversationsApi.data &&
			getFreqConversationsApi.data.length > 0
		) {
			setFreqConversations(getFreqConversationsApi.data);
		}
	}, [getFreqConversationsApi.data]);

	//! ================ SEARCH CONVERSATIONS ==================

	const [searchQuery, setSearchQuery] = useState("");
	const [searchConvs, setSearchConvs] = useState([]);

	const handleClearInput = () => {
		setSearchQuery("");
		setSearchConvs([]);
	};

	const handleInputChange = (e) => {
		const query = e.target.value;
		setSearchQuery(query);
		if (query) {
			searchConversationsApi.request(query, 0, convResultsPerPage);
		}
	};

	useEffect(() => {
		if (
			!searchConversationsApi.loading &&
			searchConversationsApi.data &&
			searchConversationsApi.responseCode === 200
		) {
			setSearchConvs(searchConversationsApi.data);
		}
	}, [searchConversationsApi.data]);

	//! ========================================================

	// console.log("%c ALL CONVS >>", "color: orange; font-weight: bolder;", conversations);
	// console.log("%c FREQ CONVS >>", "color: cyan; font-weight: bolder;", freqConversations);
	// console.log("%c SELECTED CONVS >>", "color: fuchsia; font-weight: bolder;", selectedConvs);
	// console.log("%c RESULTS CONVS >>", "color: #79FE0C; font-weight: bolder;", searchConvs);

	const LoadingView = () => (
		<div>
			<List />
		</div>
	);
	const EmptyView = () => (
		<span className={styles.info_text}>
			No conversations matches your search
		</span>
	);

	return (
		<div>
			<GaawkModal
				show={isModalOpened}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={"Forward Message"}
				closeAlign={"left"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.search_wrapper}>
							{/* <label>Add members</label> */}
							<SearchInput
								borderRadius={"5px"}
								showIcons={true}
								onChange={handleInputChange}
								value={searchQuery}
								onClearClicked={handleClearInput}
								// check component for more actions
							/>
						</div>

						{searchQuery ? (
							<div className={styles.section_wrapper}>
								<span className={styles.title}>
									Search Conversations
								</span>
								<div className={styles.contacts_wrapper}>
									{!searchConversationsApi.loading &&
										searchConvs.length === 0 && (
											<EmptyView />
										)}
									{searchConversationsApi.loading &&
										searchConvs.length === 0 && (
											<LoadingView />
										)}

									{searchConvs.length > 0 &&
										searchConvs.map((conv) => (
											<MessageInfoUser
												key={conv.uuid}
												id={conv.uuid}
												thumbnail={
													conv.conversationType ===
													"Group"
														? conv.groupDTO?.image
																?.file
																.customName
														: conv.minifiedProfile
																?.profileImage
																?.image
												}
												name={
													conv.conversationType ===
													"Group"
														? conv.groupDTO.name
														: conv.minifiedProfile
																.name
												}
												type={
													conv.conversationType ===
													"Default"
														? conv.minifiedProfile
																.type
														: "COMPANY"
												}
												canSelect={true}
												onSelect={onSelect}
												isSelected={selectedConvs.includes(
													conv.uuid
												)}
											/>
										))}
								</div>
							</div>
						) : (
							<>
								<div
									className={`${styles.section_wrapper} ${styles.graybg}`}
								>
									<span className={styles.title}>
										Frequently Contacted
									</span>
									<div className={styles.contacts_wrapper}>
										{freqConversations.map((conv) => (
											<MessageInfoUser
												key={conv.uuid}
												id={conv.uuid}
												thumbnail={
													conv.conversationType ===
													"Group"
														? conv.groupDTO?.image
																?.file
																.customName
														: conv.minifiedProfile
																?.profileImage
																?.image

													// conv.conversationType ===
													// "Group"
													//     ? conv.groupDTO.image
													//     : conv.minifiedProfile
													//           .profileImage
													//     ? conv.minifiedProfile
													//           .profileImage
													//           .thumbnail
													//     : ""
												}
												name={
													conv.conversationType ===
													"Group"
														? conv.groupDTO.name
														: conv.minifiedProfile
																.name
												}
												type={
													conv.conversationType ===
													"Default"
														? conv.minifiedProfile
																.type
														: "COMPANY"
												}
												canSelect={true}
												onSelect={onSelect}
												isSelected={selectedConvs.includes(
													conv.uuid
												)}
											/>
										))}
									</div>
								</div>

								<div className={styles.section_wrapper}>
									<span className={styles.title}>
										All Conversations
									</span>
									<div className={styles.contacts_wrapper}>
										<InfiniteScroll
											dataLength={conversations.length}
											next={loadNextConvs}
											hasMore={
												conversations.length > 0 &&
												conversations.length % 5 === 0
											}
											loader={<h4>Loading...</h4>}
											height={220}
										>
											{conversations.length > 0 &&
												conversations.map((conv) => (
													<MessageInfoUser
														key={conv.uuid}
														id={conv.uuid}
														thumbnail={
															conv.conversationType ===
															"Group"
																? conv.groupDTO
																		?.image
																		?.file
																		.customName
																: conv
																		.minifiedProfile
																		?.profileImage
																		?.image

															// conv.conversationType ===
															// "Group"
															//     ? conv.groupDTO
															//           .image
															//     : conv
															//           .minifiedProfile
															//           .profileImage
															//     ? conv
															//           .minifiedProfile
															//           .profileImage
															//           .thumbnail
															//     : ""
														}
														name={
															conv.conversationType ===
															"Group"
																? conv.groupDTO
																		.name
																: conv
																		.minifiedProfile
																		.name
														}
														type={
															conv.conversationType ===
															"Default"
																? conv
																		.minifiedProfile
																		.type
																: "COMPANY"
														}
														canSelect={true}
														onSelect={onSelect}
														isSelected={selectedConvs.includes(
															conv.uuid
														)}
													/>
												))}
										</InfiniteScroll>
									</div>
								</div>
							</>
						)}
						{/* add button here */}
						<div className={styles.btn_wrapper}>
							<PrimaryButton
								className={styles.forward_btn}
								text={"forward"}
								onClick={onForward}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default ForwardModal;
