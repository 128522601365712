import useApi from "hooks/useApi";
import searchApi from "api/search";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import useDebounce from "hooks/useDebounce";
import { useInView } from "react-intersection-observer";
import { productsKeys } from "queryKeys/products-key-factory";

const itemsPerPage = 5;

export const useProductsSearch = (
	filters,
	isProductTab = false,
	initialQuery = ""
) => {
	const { ref, inView } = useInView({ triggerOnce: true });
	const [searchInput, setSearchInput] = useState(initialQuery);
	const query = useDebounce(searchInput);
	const getProductsApi = useApi(searchApi.searchProducts, true, true);

	const fetchProducts = async ({ pageParam = 0, queryKey }) => {
		const [, , , filters] = queryKey;

		const response = await getProductsApi.request(
			pageParam,
			itemsPerPage,
			filters,
			"NEWEST"
		);
		return response.data;
	};

	const {
		data: products,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
		isFetching: isFetchingProducts,
	} = useInfiniteQuery({
		queryKey: productsKeys.search(query, filters),
		queryFn: fetchProducts,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage?.list?.length === itemsPerPage
					? pages.length
					: undefined;
			return nextPage;
		},
		refetchOnWindowFocus: false,
		enabled: isProductTab ? query.trim() !== "" : true,
	});

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};
	const _handleClearSearch = () => {
		setSearchInput("");
	};
	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, inView]);

	return {
		products,
		isFetching,
		handleSearchInputChange,
		isStale: searchInput !== query,
		_handleClearSearch,
		searchInput,
		ref,
		isFetchingProducts,
		isLoading,
	};
};
