import ProfileCarousel from "../ProfileCarousel/ProfileCarousel";
import styles from "./ConvoLeftHeader.module.scss";

import { ReactComponent as NewMessage } from "images/new-message-icon.svg";
import { ReactComponent as NewGroup } from "images/new-group-icon.svg";

const ConvoLeftHeader = ({ onMessage, onGroup }) => {
	return (
		<div className={styles.container}>
			<ProfileCarousel />
			<div className={styles.conversation_controls}>
				<button onClick={onMessage}>
					<NewMessage className={styles.icon} />
				</button>

				<button onClick={onGroup}>
					<NewGroup className={styles.icon} />
				</button>
			</div>
		</div>
	);
};

export default ConvoLeftHeader;
