import styles from "../../Home/Feeds.module.css";
import Feeds from "../../Home/Feeds";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";

const PostsTab = ({ userInfo }) => {
	return (
		<div className={styles.container}>
			<FeedContainer>
				<Feeds profileId={userInfo.uuid} />
			</FeedContainer>
		</div>
	);
};

export default PostsTab;
