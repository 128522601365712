import profileApi from "api/profile";
import GroupMember from "components/Chat/GroupMember";
import CompanyItem from "components/Companies/CompanyItem";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import { pluralize } from "components/Utils/General";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import usePaginatedResource from "hooks/usePaginatedResource";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./CommonConnections.module.css";

const itemsPerPage = 20;

const CommonConnections = ({ count, data, company }) => {
	const { uuid: companyId, name: companyName } = company || {};

	const [showModal, setShowModal] = useState(false);

	//!--------------

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getCommonConnectionsApi = useApi(
		profileApi.getCommonConnections,
		true,
		true
	);

	const fetchCommonConnections = async ({
		pageParam = 0,
		signal,
		queryKey,
	}) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, requestedProfileId] = queryKey;

		const response = await getCommonConnectionsApi.request(
			pageParam,
			itemsPerPage,
			requestedProfileId
		);

		return response.data;
	};

	const {
		data: commonConnectionsPaginated,
		isFetching,
		hasNextPage,
		fetchNextPage,
	} = usePaginatedResource(
		profileKeys.commonConnections(companyId),
		fetchCommonConnections,
		itemsPerPage,
		showModal
	);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	//!--------------

	const connectionsList = useMemo(
		() =>
			data?.map((profile) => {
				return (
					<div
						key={profile.uuid}
						className={styles.contact_item_wrapper}
					>
						<GroupMember
							key={profile.uuid}
							redirect={true}
							data={profile}
						/>
					</div>
				);
			}),
		[data]
	);

	const LoadingView = () => (
		<div>
			<ContactLoader />
			<ContactLoader />
			<ContactLoader />
		</div>
	);

	const commonConnectionsList = useMemo(
		() =>
			commonConnectionsPaginated?.pages?.map((page) =>
				page.map((profile, i) => (
					<GroupMember
						key={profile.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						redirect={true}
						data={profile}
					/>
				))
			),
		[commonConnectionsPaginated, viewRef]
	);

	return (
		<>
			<div className={styles.connections}>
				<h3 className={styles.title}>{`${count} of your ${pluralize(
					count,
					"connection"
				)} work here.`}</h3>

				<div>{connectionsList}</div>

				{/* //* 6 is being the max items of connections return by backend as a preview */}
				{count > 6 && (
					<SeeMore
						className={styles.see_all_btn}
						onClick={() => setShowModal(true)}
						text={"See All"}
					/>
				)}
			</div>

			<GaawkModal
				show={showModal}
				handleClose={() => {
					setShowModal(false);
				}}
				defaultModal={false}
				showHeader={true}
				title={`Connections at ${companyName}`}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.company_container}>
							<p>{`${count} of your connections work at:`}</p>

							<CompanyItem
								item={company}
								subdata={`${company?.locationDTO?.name}, ${company?.locationDTO?.countryDTO?.name}`}
							/>
						</div>

						<div className={styles.connections_container}>
							<div className={styles.mr_10}>
								{commonConnectionsList}
							</div>
							{isFetching && <LoadingView />}
						</div>
					</div>
				}
			/>
		</>
	);
};

export default CommonConnections;
