import React from "react";

import { dateToFromNowDaily } from "../Utils/General";

import styles from "./VaultFolder.module.css";
import vaultIcon from "images/vault_icon.svg";

import useBreadcrumb from "../../hooks/useBreadcrumb";
import GaawkLink from "../Utils/SubComs/GaawkLink/GaawkLink";

const VaultFolderHeader = ({ folder }) => {
	const breadcrumb = useBreadcrumb(folder);

	return (
		<>
			<div className={styles.header}>
				<GaawkLink customStyle={styles.icon_container} to={"/vault"}>
					<img
						src={vaultIcon}
						alt={"vault"}
						className={styles.header_icon}
					/>
				</GaawkLink>

				{folder && (
					<div className={styles.breadcrumb}>
						{folder?.dirs?.length === 0 ? (
							<GaawkLink
								customStyle={styles.parent_folder}
								to={"/vault"}
							>
								VAULT
							</GaawkLink>
						) : (
							<GaawkLink
								customStyle={styles.parent_folder}
								to={`/vault/${folder?.dirs?.[0].uuid}`}
							>
								{folder?.dirs?.[0].name}
							</GaawkLink>
						)}
						<span>{` > ${folder.name}`}</span>

						<p className={styles.folder_info}>
							{`${folder.subCount} ${
								folder.subCount > 1 ? "items" : "item"
							}`}
							, Created:{" "}
							{dateToFromNowDaily(folder.insertionTime)}{" "}
							{folder.updateTime > 0 &&
								` , Last modified:
                                    ${dateToFromNowDaily(folder.updateTime)}`}
						</p>
						<p className={styles.folder_info}>{breadcrumb}</p>
					</div>
				)}
			</div>
		</>
	);
};

export default VaultFolderHeader;
