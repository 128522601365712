import { useCallback, useRef } from "react";

import styles from "./SortingButtons.module.css";

import { ReactComponent as GridIcon } from "images/grid.svg";
import { ReactComponent as ListIcon } from "images/list.svg";
import { ReactComponent as SortAlphaIcon } from "images/sort-alpha.svg";
import { ReactComponent as SortNewestIcon } from "images/sort-newest.svg";
import { ReactComponent as SortOldestIcon } from "images/sort-oldest.svg";

import Popover from "../../Popover/Popover";

const SortingButtons = ({
	order,
	stateLayout,
	onChangeLayout,
	onChangeOrder,
}) => {
	const popoverRef = useRef(null);

	const changeOrder = (sort) => {
		onChangeOrder(sort);
		popoverRef.current.closePopover();
	};

	const meSortIcon = useCallback(() => {
		switch (order) {
			case "OLDEST":
				return <SortOldestIcon />;

			case "ALPHA":
				return <SortAlphaIcon />;

			default:
				return <SortNewestIcon />;
		}
	}, [order]);

	return (
		<div className={styles.tabs_buttons}>
			<Popover
				ref={popoverRef}
				render={
					<div className="popover_container">
						<button
							className="popover_item"
							onClick={() => changeOrder("NEWEST")}
						>
							<SortNewestIcon />
							<span>Newest</span>
						</button>

						<button
							className="popover_item"
							onClick={() => changeOrder("OLDEST")}
						>
							<SortOldestIcon />
							<span>Oldest</span>
						</button>

						<button
							className="popover_item"
							onClick={() => changeOrder("ALPHA")}
						>
							<SortAlphaIcon />
							<span>Alphabetical</span>
						</button>
					</div>
				}
			>
				<button className={styles.sort_button}>{meSortIcon()}</button>
			</Popover>

			{stateLayout === "list" && (
				<button onClick={() => onChangeLayout("grid")}>
					<GridIcon />
				</button>
			)}

			{stateLayout === "grid" && (
				<button onClick={() => onChangeLayout("list")}>
					<ListIcon />
				</button>
			)}
		</div>
	);
};

export default SortingButtons;
