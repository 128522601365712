import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from "./VaultItemViewer.module.css";
import { useParams } from "react-router-dom";
import vaultApi from "../../api/vault";
import useApi from "../../hooks/useApi";
import FileViewer from "../Utils/FileViewer/FileViewer";
import ViewerHeader from "../Utils/FileViewer/ViewerHeader";
import { featuresSection, viewerMapper } from "../Utils/General";
import VaultActions from "./VaultActions";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import DefaultPage from "../Utils/PageArchs/DefaultPage/DefaultPage";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import { useNavigate } from "react-router-dom";
import useMutate from "hooks/useMutate";
import VaultShareModal from "./VaultShareModal";

const VaultItemViewer = () => {
	const navigate = useNavigate();
	const updateFavoriteApi = useApi(vaultApi.updateFavorite, true);
	const shareItemsApi = useApi(vaultApi.shareItems, true);

	const fileviewerRef = useRef();

	const { id } = useParams();

	const [item, setItem] = useState();
	const [shareModal, setShareModal] = useState(false);

	const {
		action: { mutate: getVaultItem, isLoading },
	} = useMutate(vaultApi.getItemById, ({ data }) => setItem(data), false);

	useEffect(() => {
		getVaultItem(id);
	}, [id]);

	const formattedFile = useMemo(() => item && viewerMapper([item]), [item]);

	const doShareItem = async (profiles) => {
		const response = await shareItemsApi.request([item], profiles);
		if (response.status === 201) return;
		else toast.error(response.data.message);
	};

	const handleDownload = () => {
		FileSaver.saveAs(item.file.customName, item.file.originalName);
	};

	const handleFavoriteItem = async () => {
		const response = await updateFavoriteApi.request(
			item.uuid,
			!item.favorite
		);

		if (response.status === 200) {
			setItem((prevState) => ({
				...prevState,
				favorite: !prevState.favorite,
			}));
		} else toast.error(response.data.message);
	};

	return (
		<>
			<DefaultPage
				leftSideChildren={
					<FeaturesSidebar active={featuresSection.vault} />
				}
				defaultHeader={false}
				headerRightContent={
					<ViewerHeader
						onClose={() => navigate("/vault")}
						leftSide={
							<div className={styles.parentFolderName}>
								Your Vault
							</div>
						}
						rightSide={
							<VaultActions
								isFavorite={item?.favorite}
								onFavorite={handleFavoriteItem}
								onDownload={handleDownload}
								onShare={() => {
									setShareModal(true);
								}}
								onFullscreen={() =>
									fileviewerRef.current.enterFullscreen()
								}
							/>
						}
					/>
				}
				rightSideChildren={
					<FileViewer
						ref={fileviewerRef}
						files={formattedFile}
						isLoading={isLoading}
						text={item?.file?.originalName}
					/>
				}
				rightSideFullWidth={true}
			/>

			<VaultShareModal
				show={shareModal}
				onClose={() => {
					setShareModal(false);
				}}
				items={item}
			/>
		</>
	);
};

export default VaultItemViewer;
