import { useInfiniteQuery } from "@tanstack/react-query";
import useApi from "../../../hooks/useApi";
import chatApi from "../../../api/chat";

const useGetGroupMembers = (userType, groupId, itemsPerPage = 10) => {
	const getGroupMembersApi = useApi(chatApi.getParticipants, true, true);

	const fetchMembers = async ({ pageParam = 0, signal, queryKey }) => {
		const [type, groupId] = queryKey;

		const response = await getGroupMembersApi.request(
			groupId,
			pageParam,
			itemsPerPage,
			type === "participants" ? "MEMBER" : "ADMIN"
		);
		return response.data;
	};

	const infiniteQuery = useInfiniteQuery({
		queryKey: [userType, groupId],
		queryFn: fetchMembers,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
	});

	return infiniteQuery;
};

export default useGetGroupMembers;
