import { useSelector } from "react-redux";
import useCompanyId from "./useCompanyId";

const useCurrentUser = () => {
	const companyId = useCompanyId();

	const currentUser = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	return currentUser;
};

export default useCurrentUser;
