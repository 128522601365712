import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ConnectionTab.module.css";
import useApi from "../../../hooks/useApi";
import connectionApi from "../../../api/connection";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import blockIcon from "images/connection-icons/block.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import { pluralize } from "components/Utils/General";

const Blocked = ({ profile, canEdit, connectionCount }) => {
	const getBlockedApi = useApi(connectionApi.getBlocked);
	const [blocked, setBlocked] = useState([]);
	const { connection } = profile;
	const navigate = useNavigate();
	const { userUrl } = useParams();

	const fetchBlocked = () => {
		getBlockedApi.request(0, 6);
	};

	useEffect(() => {
		// if (profile.uuid) {
		fetchBlocked();
		// }
	}, [profile.uuid, connection.blocking, connection.blocked]);

	useEffect(() => {
		if (
			!getBlockedApi.loading &&
			getBlockedApi.responseCode === 200 &&
			getBlockedApi.data &&
			getBlockedApi.data.length > 0
		) {
			setBlocked(getBlockedApi.data);
		}
	}, [getBlockedApi.data]);

	const handleViewAll = (action) => () => {
		sessionStorage.setItem("activeConnectionTab", action);
		navigate(
			userUrl
				? `/profile/${userUrl}/connections`
				: "/profile/connections",
			{
				state: {
					profile,
					canEdit,
				},
			}
		);
	};

	const blockedList = blocked.map((block) => (
		<div className={styles.contact_item} key={block.uuid}>
			<GroupMember data={block} redirect={true} />
		</div>
	));

	if (connectionCount.blocking === 0 || !canEdit) return null;

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img src={blockIcon} alt="Blocked" />
					Blocked
				</div>

				<SeeMore text="See All" onClick={handleViewAll("Blocked")} />
			</div>

			{getBlockedApi.loading && <ContactLoader />}

			{connectionCount.blocking > 0 && !getBlockedApi.loading && (
				<>
					<div className={styles.subtitle}>
						You have blocked{" "}
						<span
							onClick={handleViewAll("Blocked")}
						>{`${connectionCount.blocking}`}</span>{" "}
						{pluralize(connectionCount.blocking, "Connection")}.
					</div>

					<div>{blockedList}</div>
				</>
			)}

			{/* {connectionCount.blocking === 0 && !getBlockedApi.loading && (
                        <div className={`${styles.more_section} ${styles.counter}`}>
                            <div className={`${styles.more_info} ${styles.counter}`}>
                                You don't have any blocked connection yet
                            </div>
                        </div>
                    )} */}
		</ProfileTabContainer>
	);
};

export default Blocked;
