import FullPage from "../Utils/PageArchs/FullPage/FullPage";
import icon from "images/gaawk-logo-79-x-25.svg";
import styles from "./More.module.scss";
import GaawkSection from "components/Utils/GaawkSection/GaawkSection";
import MainHeader from "components/Utils/SubComs/MainHeader/MainHeader";

const Index = () => {
	return (
		<FullPage
			content={
				<div className={styles.container}>
					<MainHeader />

					<div className={styles.top_header}>
						<div className={styles.top_header_wrapper}>
							<div className={styles.logo}>
								<img src={icon} height="auto" alt="logo" />
							</div>
						</div>
					</div>

					<div className={styles.middle_container}>
						<div className={styles.middle_wrapper}>
							<div className={styles.services_section}>
								<div className={styles.service_panel}>
									<GaawkSection />
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default Index;
