import styles from "./ProductItem.module.scss";
import { useRef } from "react";
import LazyImage from "../../../Utils/LazyImage/LazyImage";
import Popover from "../../../Utils/Popover/Popover";
import Options from "images/three-dots-black-5-x-20.svg";
import { Link } from "react-router-dom";
import ProductPopoverActions from "./ProductPopoverActions";

const ProductItem = ({
	item,
	isOwner,
	onCopy,
	onMessage,
	onEdit,
	// onShare,
	optionsIcon = Options,
	onDelete,
	product_img_style,
}) => {
	const popoverRef = useRef(null);

	const { mainImage, name, uuid: productId } = item;

	return (
		<Link
			to={`/profile/${item?.owner?.url}/product/${productId}`}
			className={styles.container}
		>
			<div className={styles.image_wrapper}>
				<LazyImage
					image={<img src={mainImage?.file?.customName} alt="" />}
					inlineStyle={
						product_img_style
							? product_img_style
							: styles.product_img
					}
				/>

				{optionsIcon && (
					<Popover
						ref={popoverRef}
						render={
							<ProductPopoverActions
								isOwner={isOwner}
								onCopy={onCopy}
								onMessage={onMessage}
								onEdit={onEdit}
								// onShare={onShare}
								onDelete={onDelete}
								onClose={() =>
									popoverRef.current.closePopover()
								}
							/>
						}
					>
						<button
							className={styles.option_icon}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<img src={optionsIcon} alt="options" />
						</button>
					</Popover>
				)}
			</div>
			<div className={styles.product_name}>{name}</div>
		</Link>
	);
};

export default ProductItem;
