import routes from "components/Routing/routing-keys";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import { useNavigate } from "react-router-dom";
import useJobsLanding from "../EmployerLanding/hooks/useJobsLanding";
import CollapsibleProjectSection from "./CollapisbleSection/CollapsibleProjectSection";
import styles from "./ProjectAll.module.scss";
import useCurrentUser from "hooks/useCurrentUser";

const ProjectAll = () => {
	const navigate = useNavigate();

	const { type } = useCurrentUser();
	const { data: jobsLanding, isLoading } = useJobsLanding();
	const { activeProjects, pastProjects } = jobsLanding || {};

	if (type === "USER") navigate(-1);

	if (isLoading) return <LoadingPage />;

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<PrimaryButton
					text={"+ Add New Project"}
					className={styles.addProjectBtn}
					onClick={() => navigate(routes.jobCreate)}
				/>
			</div>

			<div>
				<CollapsibleProjectSection open count={activeProjects} />
				<CollapsibleProjectSection open={false} count={pastProjects} />
			</div>
		</div>
	);
};

export default ProjectAll;
