import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import useApi from "./useApi";
import { useSelector } from "react-redux";

const itemsPerPage = 100;

const usePaginatedSearchFilters = (
	filters,
	locationId,
	queryName,
	inputValue,
	filterApi,
	selectedDynamicFilters,
	onChange,
	enabled = true
) => {
	const queryClient = useQueryClient();

	const initialFilters = useMemo(
		() => ({
			...filters,
			...locationId,
		}),
		[locationId, filters]
	);

	const [selectedFilters, setSelectedFilters] = useState(
		Object.keys(selectedDynamicFilters).length > 0
			? selectedDynamicFilters
			: initialFilters
	);

	const filtersList = useSelector((state) => state.search.filters);

	const hasFilters = Object.keys(filtersList).length > 0;

	const searchFilterApi = useApi(filterApi, true, true);

	const fetchFilters = async ({ pageParam = 0, queryKey, signal }) => {
		const [_, searchInput, filtersList, locationId] = queryKey;

		const response = await searchFilterApi.request(
			pageParam,
			itemsPerPage,
			hasFilters
				? {
						...filtersList,
						...locationId,
						q: searchInput,
				  }
				: { ...initialFilters, q: searchInput }
		);

		return response.data;
	};

	const queryKey = [queryName, inputValue, filtersList, locationId];

	const { data, hasNextPage, fetchNextPage, isFetching, isFetched, refetch } =
		useInfiniteQuery({
			queryKey: queryKey,
			queryFn: fetchFilters,
			getNextPageParam: (lastPage, pages) => {
				const nextPage =
					lastPage.length === itemsPerPage ? pages.length : undefined;
				return nextPage;
			},
			staleTime: 0,
			enabled,
		});

	const didMountRef = useRef(false);

	useEffect(() => {
		let timer;
		if (didMountRef.current && Object.keys(selectedFilters).length > 0) {
			timer = setTimeout(() => {
				onChange({
					...selectedFilters,
					...locationId,
				});
			}, 500);
		}
		didMountRef.current = true;
		return () => clearTimeout(timer);
	}, [selectedFilters]);

	const handleRefetch = () => {
		queryClient.invalidateQueries(queryKey);
	};

	const handleRemoveQuery = () => {
		queryClient.removeQueries(queryKey);
	};

	return {
		initialFilters,
		data,
		isFetching,
		hasFilters,
		selectedFilters,
		setSelectedFilters,
		handleRefetch,
		fetchNextPage,
		hasNextPage,
		isFetched,
		refetch,
		handleRemoveQuery,
	};
};

export default usePaginatedSearchFilters;
