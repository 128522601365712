import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import profileApi from "../../api/profile";
import useApi from "../../hooks/useApi";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import BasicCheckbox from "../Utils/SubComs/Inputs/BasicCheckbox/BasicCheckbox";
import styles from "./BecomeAgency.module.css";
import CompanyItem from "./CompanyItem";
import routes from "components/Routing/routing-keys";

const BecomeAgency = ({ state }) => {
	const navigate = useNavigate();
	const companyProfile = state;
	const becomeAgencyApi = useApi(profileApi.becomeAgency, true);

	const [isChecked, setIsChecked] = useState(false);
	const [showPayment, setShowPayment] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);

	const handlePayment = async () => {
		const response = await becomeAgencyApi.request();
		if (response.status === 200) {
			setConfirmationModal(true);
		}
	};

	return (
		<>
			<div className={styles.company_item}>
				<CompanyItem
					item={companyProfile}
					subdata={`${companyProfile.locationDTO.name}, ${companyProfile.locationDTO.countryDTO.name}`}
				/>
			</div>
			<div className={styles.container}>
				<p>
					Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
					diam nonumy eirmod tempor invidunt ut labore et dolore magna
					aliquyam erat, sed diam voluptua. At vero eos et accusam et
					justo duo dolores et ea rebum. Stet clita kasd gubergren, no
					sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
					ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
					nonumy eirmod tempor invidunt ut labore et dolore magna
					aliquyam erat, sed diam voluptua. At vero eos et accusam et
					justo duo dolores et ea rebum. Stet clita kasd gubergren, no
					sea takimata sanctus est Lorem ipsum dolor sit amet lorem
					ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
					nonumy eirmod tempor invidunt ut labore et dolore magna.
				</p>
				{!showPayment && (
					<>
						<div className={styles.terms_wrapper}>
							<BasicCheckbox
								isChecked={isChecked}
								onChange={() =>
									setIsChecked((prevState) => !prevState)
								}
								clickableLabel={false}
								text={
									<p>
										Accept{" "}
										<span
											onClick={() => {
												alert(
													"Display terms & conditions"
												);
											}}
											className={styles.terms}
										>
											terms & conditions.
										</span>
									</p>
								}
							/>
						</div>
						<div className={styles.button_container}>
							<PrimaryButton
								text={"proceed to payment"}
								className={styles.btn}
								onClick={() => setShowPayment(true)}
								disabled={!isChecked}
							/>
						</div>
					</>
				)}

				{showPayment && (
					<>
						<div className={styles.payment_container}>
							ADD PAYMENT METHODS HERE
						</div>
						<div className={styles.button_container}>
							<PrimaryButton
								text={"pay"}
								className={styles.btn}
								onClick={handlePayment}
								disabled={becomeAgencyApi.loading}
							/>
						</div>
					</>
				)}
			</div>

			<GaawkModal
				show={confirmationModal}
				handleClose={() => setConfirmationModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Become an Agency"}
				closeAlign={"right"}
				children={
					<div className={`${styles.container} ${styles.modal}`}>
						<div className={styles.congrats_message}>
							<h2>CONGRATULATIONS!</h2>
							<h4>Your company is now an agency.</h4>
						</div>
						<div className={styles.company_wrapper}>
							<CompanyItem
								item={companyProfile}
								subdata={`${companyProfile.locationDTO.name}, ${companyProfile.locationDTO.countryDTO.name}`}
							/>
						</div>
						<div className={styles.info}>
							Lorem ipsum dolor sit amet, consetetur sadipscing
							elitr, sed diam nonumy eirmod tempor invidunt ut
							labore et dolore magna aliquyam erat, sed diam
							voluptua. At vero eos et accusam et justo duo
							dolores et ea rebum.
						</div>
						<div className={styles.button_container}>
							<PrimaryButton
								text={"go to your agency"}
								className={styles.btn}
								onClick={() => navigate(routes.profile())}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default BecomeAgency;
