import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
	name: "Replies",
	initialState: {
		data: [
			{
				commentId: "",
				replies: [],
				hasMore: true,
			},
		],
	},
	reducers: {
		repliesUpdated: (state, action) => {
			const replies = action.payload;

			if (replies.length === 0) {
			} else {
				const commentId = replies[0].commentId;
				const index = state.data.findIndex(
					(data) => data.commentId === commentId
				);

				if (index >= 0) {
					const storeReplies = state.data[index].replies;

					const repliesToAppend = replies.filter(
						comparer(storeReplies)
					);

					state.data[index] = {
						...state.data[index],
						replies: [
							...state.data[index].replies,
							...repliesToAppend,
						],
						hasMore: repliesToAppend.length === 5,
					};
				} else {
					const dataItem = {
						commentId: commentId,
						replies: replies,
						hasMore: replies.length === 5,
					};

					return {
						...state,
						data: [...state.data, dataItem],
					};
				}
			}
		},

		replyAdded: (state, action) => {
			const commentId = action.payload.commentId;

			const index = state.data.findIndex(
				(data) => data.commentId === commentId
			);

			if (index >= 0) {
				state.data[index] = {
					...state.data[index],
					replies: [...state.data[index].replies, action.payload],
					hasMore: false,
				};
			} else {
				const arr = [];
				arr.unshift(action.payload);

				const dataItem = {
					commentId: commentId,
					replies: arr,
					hasMore: false,
				};

				return {
					...state,
					data: [...state.data, dataItem],
				};
			}
		},

		reactionUpdated: (state, action) => {
			const index = state.data.findIndex(
				(data) => data.commentId === action.payload.commentId
			);
			const storeComments = state.data[index].replies;
			const commentIndex = storeComments.findIndex(
				(item) => item.uuid === action.payload.uuid
			);

			state.data[index].replies[commentIndex].stats =
				action.payload.stats;
			state.data[index].replies[commentIndex].profileReactionType =
				action.payload.profileReactionType;
		},
	},
});

function comparer(otherArray) {
	return function (current) {
		return (
			otherArray.filter(function (other) {
				return other.uuid === current.uuid;
			}).length === 0
		);
	};
}

export const { repliesUpdated, replyAdded, reactionUpdated } = slice.actions;
export default slice.reducer;

/**
 * get replies
 */

export const getReplies = (commentId, profileId, page, size) =>
	apiActions.apiCallBegan({
		url: `posts/reply?commentId=${commentId}&page=${page}&profileId=${profileId}&size=${size}`,
		method: "GET",
		onSuccess: repliesUpdated.type,
	});

/**
 * add reply
 */

export const addReply = (replyData) =>
	apiActions.apiCallBegan({
		url: `posts/reply`,
		method: "POST",
		data: replyData,
		onSuccess: replyAdded.type,
		showSuccessAlert: true,
	});

/**
 * add comment reaction
 */
export const addReaction = (replyId, profileId, reaction) =>
	apiActions.apiCallBegan({
		url: `posts/reply/reaction?replyId=${replyId}&profileId=${profileId}&reactionType=${reaction}`,
		method: "PUT",
		onSuccess: reactionUpdated.type,
		// showSuccessAlert: true,
	});

/**
 * remove reaction
 */
export const removeReaction = (replyId, profileId) =>
	apiActions.apiCallBegan({
		url: `posts/reply/reaction?replyId=${replyId}&profileId=${profileId}`,
		method: "DELETE",
		onSuccess: reactionUpdated.type,
		// showSuccessAlert: true,
	});
