import client from "./client";

const endpoint = "/payment";

//! USER ENDPOINTS ===========================

const getPackages = () => client.get(endpoint + `/packages`);

const requestCallback = (body) =>
	client.post(endpoint + `/requestCallback`, body);

const subscribe = (paymentPackage) =>
	client.post(endpoint + `/subscribe?paymentPackage=${paymentPackage}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getPackages,
	requestCallback,
	subscribe,
};
