import React, {
    useState,
    useEffect,
    useMemo,
    forwardRef,
    useImperativeHandle,
} from "react";
import useApi from "../../../../../hooks/useApi";
import profileApi from "../../../../../api/profile";
import styles from "./TagInput.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import { components } from "react-select";
import { removeItemIdFromArray, removeItemsFromArray } from "../../../General";
import ProfileTag from "../../Tags/ProfileTag";
import ProfilePic from "../../../../Home/ProfilePic";

const TagInput = forwardRef(
    ({ title, placeholder = "Select", profiles = [] }, ref) => {
        const searchProfileApi = useApi(profileApi.search);
        const [searchProfile, setSearchProfile] = useState([]);
        const [menu, setMenu] = useState(false);
        const [selectedProfiles, setSelectedProfiles] = useState(profiles);
        const [query, setQuery] = useState("");

        useImperativeHandle(ref, () => ({
            getIds() {
                return selectedProfiles.map((profile) => profile.uuid);
            },
        }));

        const cleanSearch = () => {
            setQuery("");
            setSearchProfile([]);
        };

        const handleKeyUp = (e) => {
            if (
                e.key !== "ArrowUp" &&
                e.key !== "ArrowDown" &&
                e.target.value
            ) {
                setQuery(e.target.value);
            } else if (!e.target.value) {
                cleanSearch();
            }
        };

        useEffect(() => {
            let timer;
            if (query) {
                timer = setTimeout(() => {
                    searchProfileApi.request(undefined, undefined, query);
                }, 500);
            }

            return () => {
                clearTimeout(timer);
            };
        }, [query]);

        useEffect(() => {
            if (
                !searchProfileApi.loading &&
                searchProfileApi.data &&
                searchProfileApi.responseCode === 200
            ) {
                const response = [...searchProfileApi.data];
                const filteredResponse = removeItemIdFromArray(
                    selectedProfiles,
                    response
                );
                setSearchProfile(filteredResponse);
            }
        }, [searchProfileApi.data]);

        const inputHandler = (value) => {
            let menuIsOpened = false;
            if (value) {
                menuIsOpened = true;
            }
            setMenu(menuIsOpened);
        };

        const handleSelected = (profile) => {
            setSelectedProfiles((prevState) => [...prevState, profile.user]);
            cleanSearch();
        };

        const handleRemoveProfile = (profile) => () => {
            setSelectedProfiles((prevState) =>
                removeItemsFromArray([profile], prevState)
            );
        };

        const profileSearchList = useMemo(
            () =>
                searchProfile.map((user) => ({
                    value: user.uuid,
                    label: user.name,
                    user,
                })),
            [searchProfile]
        );

        const selectedProfilesList = useMemo(
            () =>
                selectedProfiles.map((profile) => (
                    <ProfileTag
                        key={profile.uuid}
                        name={profile.name}
                        type={profile.type}
                        image={profile.profileImage?.image}
                        onRemove={handleRemoveProfile(profile)}
                        size={40}
                    />
                )),
            [selectedProfiles]
        );

        return (
            <div className={styles.form_input_wrapper}>
                <label className={styles.input_label}>{title}</label>
                <div
                    onKeyUp={handleKeyUp}
                    className={styles.custom_select_wrapper}
                >
                    <CustomSelect
                        options={profileSearchList}
                        placeholder={placeholder}
                        height="35px"
                        menuIsOpen={menu}
                        value={""}
                        onBlur={cleanSearch}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            Option: (props) => {
                                const { user } = props.data;
                                return (
                                    <components.Option {...props}>
                                        <div className={styles.profile_item}>
                                            <ProfilePic
                                                thumbnail={
                                                    user.profileImage?.image
                                                }
                                                w={36}
                                                h={36}
                                                type={user.type}
                                                name={user.name}
                                                createMode={true}
                                                subdata={user.tagLine}
                                            />
                                        </div>
                                    </components.Option>
                                );
                            },
                        }}
                        isLoading={searchProfileApi.loading}
                        onInputChange={inputHandler}
                        onChange={handleSelected}
                    />
                </div>
                {selectedProfilesList.length > 0 && (
                    <div className={styles.contributors_container}>
                        {selectedProfilesList}
                    </div>
                )}
            </div>
        );
    }
);

export default TagInput;
