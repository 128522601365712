import client from "./client";

const endpoint = "/currency";

const getCurrencies = () => client.get(endpoint);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getCurrencies,
};
