import { classNames } from "primereact/utils";
import styles from "./CompanyJobItem.module.scss";
import { format } from "date-fns";
import Options from "images/three-dots-black-5-x-20.svg";
import { useRef, useState } from "react";
import Popover from "components/Utils/Popover/Popover";
import deleteIcon from "images/delete.svg";
import editIcon from "images/edit.svg";
import archiveIcon from "images/archive.svg";
import { useNavigate, useParams } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import useMutate from "hooks/useMutate";
import jobApi from "api/job";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import useCurrentUser from "hooks/useCurrentUser";
import useGetQueryParam from "hooks/useGetQueryParam";
import { useQueryClient } from "@tanstack/react-query";
import { projectKeys } from "queryKeys/projects-key-factory";

const CompanyJobItem = ({ job }) => {
	const { uuid: profileId } = useCurrentUser();

	const [deleteModal, setDeleteModal] = useState(false);

	const popoverRef = useRef(null);

	const {
		name,
		insertionTime,
		newApplicants,
		closed,
		uuid,
		minifiedCompany,
		jobType,
	} = job;

	const navigate = useNavigate();

	const { paramValue: projectId } = useGetQueryParam("projectId");
	const { projectId: projectIdParam } = useParams();
	const queryClient = useQueryClient();

	const {
		action: { mutate: deleteJob },
	} = useMutate(jobApi.deleteJob, () => {
		queryClient.invalidateQueries(
			jobsKeys.jobsByCompany(
				minifiedCompany.uuid,
				jobType,
				closed,
				undefined,
				projectId || projectIdParam
			)
		);

		//* > invalidate the count
		if (projectId || projectIdParam) {
			queryClient.invalidateQueries(
				projectKeys.detail(projectId || projectIdParam)
			);
		} else {
			queryClient.invalidateQueries(jobsKeys.landing());
		}

		setDeleteModal(false);
	});

	const {
		action: { mutate: updateJobStatus },
	} = useMutate(jobApi.updateJobStatus, () => {
		queryClient.invalidateQueries(
			jobsKeys.jobsByCompany(
				minifiedCompany.uuid,
				jobType,
				closed,
				undefined,
				projectId || projectIdParam
			)
		);

		queryClient.invalidateQueries(
			jobsKeys.jobsByCompany(
				minifiedCompany.uuid,
				jobType,
				!closed,
				undefined,
				projectId || projectIdParam
			)
		);

		//* > invalidate the count
		if (projectId || projectIdParam) {
			queryClient.invalidateQueries(
				projectKeys.detail(projectId || projectIdParam)
			);
		} else {
			queryClient.invalidateQueries(jobsKeys.landing());
		}
	});

	return (
		<>
			<div
				role={"button"}
				tabIndex={0}
				className={classNames(styles.container, {
					[styles.closed]: closed,
				})}
				onClick={() => navigate(routes.job(uuid))}
			>
				<div className={styles.job_details}>
					<h3>{name}</h3>
					<p>
						<span>Posted on:</span>{" "}
						{format(new Date(insertionTime), "dd/MM/yyyy")}
					</p>
				</div>
				<div className={styles.job_controls}>
					{newApplicants > 0 && (
						<div className={styles.new_applicants}>
							{newApplicants}
						</div>
					)}

					{profileId === minifiedCompany.uuid && (
						<Popover
							allow={["left"]}
							// crossAxis={15}
							ref={popoverRef}
							render={
								<div className="popover_container">
									<button
										tabIndex={0}
										className="popover_item"
										onClick={(e) => {
											e.stopPropagation();
											navigate(routes.jobEdit(uuid));
											popoverRef.current.closePopover();
										}}
									>
										<span>
											<img src={editIcon} alt={"edit"} />
										</span>
										<span>Edit job</span>
									</button>
									<button
										tabIndex={0}
										className="popover_item"
										onClick={(e) => {
											e.stopPropagation();
											updateJobStatus({
												jobId: uuid,
												status: closed,
											});
											popoverRef.current.closePopover();
										}}
									>
										<span>
											<img
												src={archiveIcon}
												alt={`mark as ${
													closed ? "open" : "closed"
												}`}
											/>
										</span>
										<span>
											Mark job as{" "}
											{closed ? "open" : "closed"}
										</span>
									</button>
									<button
										tabIndex={0}
										className="popover_item"
										onClick={(e) => {
											e.stopPropagation();
											setDeleteModal(true);
											popoverRef.current.closePopover();
										}}
									>
										<span>
											<img
												src={deleteIcon}
												alt={"delete"}
											/>
										</span>
										<span>Delete job</span>
									</button>
								</div>
							}
						>
							<button
								className={styles.option_btn}
								onClick={(e) => e.stopPropagation()}
							>
								<img src={Options} alt="options" />
							</button>
						</Popover>
					)}
				</div>
			</div>

			<WarningModal
				show={deleteModal}
				headerText="Are you sure you want to delete this job?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => deleteJob(uuid)}
			/>
		</>
	);
};

export default CompanyJobItem;
