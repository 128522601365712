import React, { useState, useEffect } from "react";
import styles from "./Tabs.module.css";
import useApi from "../../../../hooks/useApi";
import useConnection from "../hooks/useConnection";
import connectionApi from "../../../../api/connection";
// import profileApi from "../../../../api/profile";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupMember from "../../../Chat/GroupMember";
// import ConnectionModal from "../../ProfileHeader/ConnectionModal";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";

const Blocked = ({ profile, query, count, onUpdateCounter }) => {
	const getBlockedApi = useApi(connectionApi.getBlocked);
	// const blockApi = useApi(profileApi.block);
	// const unblockApi = useApi(profileApi.unblock);

	const {
		data: blocked,
		fetchData,
		// updateConnection,
		// unBlockConnection,
	} = useConnection(getBlockedApi, query);

	// const [showConnectionModal, setShowConnectionModal] = useState(false);
	// const [user, setUser] = useState("");
	// const [modalAction, setModalAction] = useState("");

	// const handleBlock = (user, action) => {
	//     setUser(user);
	//     if (action === "BLOCK") {
	//         setModalAction("BLOCK");
	//     } else {
	//         setModalAction("UNBLOCK");
	//     }
	//     setShowConnectionModal(true);
	// };

	// const handleModalConfirm = (action) => {
	//     if (action === "UNBLOCK") {
	//         unblockApi.request(user.uuid);
	//     } else {
	//         blockApi.request(user.uuid);
	//     }
	//     setShowConnectionModal(false);
	// };

	const blockedList = blocked.map((item) => (
		// <div className={styles.contact_item} key={item.uuid}>
		<GroupMember
			key={item.uuid}
			data={item}
			blocked={true}
			redirect={true}
			// rightSideAction={
			//     item.connection && !item.connection.blocking ? (
			//         <span
			//             className={styles.rightaction_text}
			//             onClick={() => handleBlock(item, "BLOCK")}
			//         >
			//             Block
			//         </span>
			//     ) : (
			//         <span
			//             className={styles.rightaction_text}
			//             onClick={() => handleBlock(item, "UNBLOCK")}
			//         >
			//             Unblock
			//         </span>
			//     )
			// }
		/>
		// </div>
	));

	// useEffect(() => {
	//     if (!blockApi.loading && blockApi.responseCode === 200) {
	//         updateConnection(user.uuid, "blocking");
	//         onUpdateCounter("blocking", "INCREMENT");
	//     }
	// }, [blockApi.loading, blockApi.responseCode]);

	// useEffect(() => {
	//     if (
	//         !unblockApi.loading &&
	//         unblockApi.responseCode === 200 &&
	//         unblockApi.data
	//     ) {
	//         unBlockConnection(unblockApi.data);
	//         onUpdateCounter("blocking", "DECREMENT");
	//     }
	// }, [unblockApi.data]);

	return (
		<>
			<div id="scrollDiv" className={styles.container}>
				<InfiniteScroll
					dataLength={blocked.length}
					next={fetchData}
					hasMore={
						!query && blocked.length > 0 && blocked.length < count
					}
					loader={<ContactLoader />}
					scrollableTarget="scrollDiv"
				>
					{getBlockedApi.loading && <ContactLoader />}
					{blockedList.length > 0 &&
						!getBlockedApi.loading &&
						blockedList}
				</InfiniteScroll>
			</div>

			{/* <ConnectionModal
                show={showConnectionModal}
                name={user.name}
                profileImage={
                    user.profileImage ? user.profileImage.image : undefined
                }
                action={modalAction}
                onConfirm={handleModalConfirm}
                onClose={() => setShowConnectionModal(false)}
            /> */}
		</>
	);
};

export default Blocked;
