import {
	useEffect,
	useMemo,
	useRef,
	useCallback,
	forwardRef,
	useImperativeHandle,
	useState,
} from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useInView } from "react-intersection-observer";
import PlanItem from "../PlanItem/PlanItem";
import styles from "./PlansCarousel.module.scss";
import { packageTypes } from "components/Utils/General";
import CallbackModal from "../CallbackModal/CallbackModal";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";

const SCROLL_POSITION_KEY = "plansCarouselScrollPosition";

const PlansCarousel = forwardRef(
	({ currentPlan, packages, shouldAutoScroll, onScrollFinish }, ref) => {
		const navigate = useNavigate();
		const { id: currentPlanId } = currentPlan;

		const scrollContainerRef = useRef(null);

		const planRefs = useRef({});

		const { ref: inViewRef, inView } = useInView();
		const [highlightedPlan, setHighlightedPlan] = useState(null);

		const setRefs = useCallback(
			(planType) => (el) => {
				planRefs.current[planType] = el;
				if (currentPlan.paymentPackageType === planType) {
					inViewRef(el);
				}
			},
			[inViewRef, currentPlan]
		);

		const [showCallbackModal, setShowCallbackModal] = useState(false);

		const handlePlanClick = (plan) => {
			const { id: planId, paymentPackageType: planType } = plan;

			if (planType === packageTypes.custom) {
				setShowCallbackModal(true);
			} else {
				if (planId > currentPlanId) {
					navigate(routes.payment, { state: plan });
				} else {
					navigate(routes.subscribe(`?plan=${planType}`));
				}
			}
		};

		const packagesList = useMemo(
			() =>
				packages?.map((item) => (
					<PlanItem
						key={item.id}
						currentPlan={currentPlan}
						item={item}
						ref={setRefs(item.paymentPackageType)}
						isHighlighted={
							highlightedPlan === item.paymentPackageType
						}
						onPlanClick={() => handlePlanClick(item)}
					/>
				)),
			[packages, currentPlan, setRefs, highlightedPlan]
		);

		// Save scroll position
		const handleScroll = useCallback(() => {
			if (scrollContainerRef.current) {
				const position = scrollContainerRef.current.scrollLeft;
				sessionStorage.setItem(
					SCROLL_POSITION_KEY,
					position.toString()
				);
			}
		}, []);

		// Restore scroll position on mount
		useEffect(() => {
			const savedPosition = sessionStorage.getItem(SCROLL_POSITION_KEY);
			if (savedPosition && scrollContainerRef.current) {
				scrollContainerRef.current.scrollLeft = parseInt(
					savedPosition,
					10
				);
			}
		}, []);

		// Handle scrolling to current plan
		useEffect(() => {
			if (
				shouldAutoScroll &&
				!inView &&
				scrollContainerRef.current &&
				planRefs.current[currentPlan.paymentPackageType]
			) {
				planRefs.current[currentPlan.paymentPackageType].scrollIntoView(
					{
						behavior: "smooth",
						block: "center",
						inline: "center",
					}
				);
			}
		}, [inView, packages, currentPlan, shouldAutoScroll]);

		useImperativeHandle(ref, () => ({
			scrollToPlan: (planType) => {
				if (planRefs.current[planType]) {
					planRefs.current[planType].scrollIntoView({
						behavior: "smooth",
						block: "center",
						inline: "center",
					});

					setHighlightedPlan(planType);
					setTimeout(() => {
						setHighlightedPlan(null);
						onScrollFinish(true);
					}, 1000);
				}
			},
		}));

		return (
			<>
				<ScrollContainer
					hideScrollbars={true}
					className={styles.scroll_container}
					innerRef={scrollContainerRef}
					onScroll={handleScroll}
				>
					{packagesList}
				</ScrollContainer>

				<CallbackModal
					show={showCallbackModal}
					onClose={() => setShowCallbackModal(false)}
				/>
			</>
		);
	}
);

export default PlansCarousel;
