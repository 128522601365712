import React from "react";
import styles from "./LoadingSpinner.module.css";
import Loader from "react-loader-spinner";

const LoadingSpinner = ({
    visible = true,
    size = 40,
    customStyle,
    color = "#6cc5d1",
}) => {
    if (!visible) return null;
    return (
        <div className={`${styles.container} ${customStyle}`}>
            <Loader type="Oval" color={color} height={size} width={size} />
        </div>
    );
};

export default LoadingSpinner;
