import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import TextInput from "../TextInput/TextInput";
import styles from "./UrlCheckInput.module.css";
import blueTick from "images/blue-tick-35-x-35.svg";
import redCross from "images/cancel-btn-red.svg";
import useApi from "../../../../../hooks/useApi";
import profileApi from "../../../../../api/profile";
import { toast } from "react-toastify";
import { urlRegex } from "components/Utils/General";
import useDebounce from "hooks/useDebounce";

const UrlCheckInput = ({
	label,
	placeholder,
	control,
	name,
	errors,
	urlValidityWatcher,
	urlWatcher,
	url,
	onSetValue,
	onSetError,
	onClearErrors,
	urlPrefix,
	max = 20,
}) => {
	const urlCheckApi = useApi(profileApi.urlCheck, true);

	// const [timer, setTimer] = useState(null);

	const urlCheck = async (value) => {
		if (value) {
			const response = await urlCheckApi.request(value);

			if (!response.ok) toast.error("Something wrong happened");
			if (response.status === 200) {
				onSetValue("urlValidity", !response.data);
				if (!!response.data) {
					onSetError("urlValidity", {
						message: "URL is already taken",
					});
				} else {
					onClearErrors("urlValidity");
				}
			}
		}
	};

	const handleUrlChange = (value) => {
		// onSetValue("urlValidity", undefined);
		// onClearErrors("urlValidity");

		// if (value) {
		// 	clearTimeout(timer);

		// 	const newTimer = setTimeout(() => {
		// 		if (value === url) {
		// 			onSetValue("urlValidity", true);
		// 			onClearErrors("urlValidity");
		// 		} else if (value) {
		// 			urlCheck(value);
		// 		}
		// 	}, 500);

		// 	setTimer(newTimer);
		// }
		if (value) {
			if (value === url) {
				onSetValue("urlValidity", true);
				onClearErrors("urlValidity");
			} else if (value) {
				urlCheck(value);
			}
		}
	};

	const debouncedInput = useDebounce(urlWatcher);

	useEffect(() => {
		if (urlRegex.test(debouncedInput) && debouncedInput.length <= max) {
			handleUrlChange(debouncedInput);
		}
	}, [debouncedInput]);

	const prefixRef = useRef(null);

	return (
		<div className={styles.form_input_wrapper}>
			<label>
				{label}
				<span className="required">*</span>
			</label>

			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, value } }) => {
					return (
						<TextInput
							leftSideItem={
								<div
									ref={prefixRef}
									className={styles.left_side_item}
								>{`www.gaawk.com/${urlPrefix}`}</div>
							}
							inputStyle={{
								paddingLeft: `${
									prefixRef?.current?.clientWidth + 7
								}px`,
							}}
							value={value}
							error={!!errors.url || !!errors.urlValidity}
							// {...urlField}
							onChange={(val) => {
								onChange(val.target.value);
								onSetValue("urlValidity", undefined);
								onClearErrors("urlValidity");

								// if (urlRegex.test(val.target.value)) {
								// 	handleUrlChange(val.target.value);
								// }
							}}
							placeholder={placeholder}
						/>
					);
				}}
			/>

			{errors?.url?.message && (
				<p className={styles.error_message}>{errors?.url?.message}</p>
			)}
			{errors?.urlValidity?.message && !errors.url && (
				<p className={`${styles.error_message} ${styles.taken}`}>
					<img
						src={redCross}
						className={styles.warning_icon}
						alt=""
					/>
					{errors?.urlValidity?.message}
				</p>
			)}

			{!errors?.url && urlValidityWatcher && urlWatcher !== url && (
				<p className={`${styles.error_message} ${styles.available}`}>
					<img
						src={blueTick}
						className={styles.warning_icon}
						alt=""
					/>
					This URL is available
				</p>
			)}
		</div>
	);
};

export default UrlCheckInput;
