import styles from "./LandingPageFeatures.module.scss";
import LazyImage from "components/Utils/LazyImage/LazyImage";

const LandingPageFeatures = () => {
	return (
		<div className={styles.container}>
			<div className={styles.iconsView}>
				<img
					className={styles.headerIcon}
					src={require("images/bookmark_black.svg").default}
					alt={"bookmark"}
				/>
				<img
					className={styles.headerIcon}
					src={require("images/group_black.svg").default}
					alt={"group"}
				/>
				<img
					className={styles.headerIcon}
					src={require("images/chat_black.svg").default}
					alt={"chat"}
				/>
				<img
					className={styles.headerIcon}
					src={require("images/discussion_black.svg").default}
					alt={"discussion"}
				/>
				<img
					className={styles.headerIcon}
					src={require("images/note_black.svg").default}
					alt={"note"}
				/>
				<img
					className={styles.headerIcon}
					src={require("images/user_black.svg").default}
					alt={"user"}
				/>
			</div>
			<span className={styles.title}>
				Jobs. Connections. Data. All in one Place.
			</span>
			<span className={styles.description}>
				Discover, Connect, and Thrive - Your One-Stop Marketplace for
				All Services & Products. Share, Book, and Connect with Service
				Providers and Creators, All in One Place!
			</span>
			<LazyImage
				inlineStyle={styles.image}
				image={
					<img
						src={
							"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/gaawk_feature_6.jpg"
						}
						alt={"headerImage"}
					/>
				}
			/>

			<div className={styles.features}>
				<div className={styles.feature}>
					<img
						className={styles.featureImg}
						src={require("images/discussion_color.svg").default}
						alt={"group"}
					/>
					<span className={styles.featureSubtitle}>Boards</span>
					<span className={styles.featureTitle}>Endless Talk</span>
					<span className={styles.featureDescription}>
						Vault is your go-to digital storage solution, designed
						to keep your data safe and secure.
					</span>
				</div>
				<div className={styles.feature}>
					<img
						className={styles.featureImg}
						src={require("images/connection_color.svg").default}
						alt={"connection"}
					/>
					<span className={styles.featureSubtitle}>
						Work Connections
					</span>
					<span className={styles.featureTitle}>
						All your Colleagues
					</span>
					<span className={styles.featureDescription}>
						Vault is your go-to digital storage solution, designed
						to keep your data safe and secure.
					</span>
				</div>
				<div className={styles.feature}>
					<img
						className={styles.featureImg}
						src={require("images/user_color.svg").default}
						alt={"user"}
					/>
					<span className={styles.featureSubtitle}>Profile</span>
					<span className={styles.featureTitle}>Who are you ?</span>
					<span className={styles.featureDescription}>
						Vault is your go-to digital storage solution, designed
						to keep your data safe and secure.
					</span>
				</div>
				<div className={styles.feature}>
					<img
						className={styles.featureImg}
						src={require("images/note_color.svg").default}
						alt={"notes"}
					/>
					<span className={styles.featureSubtitle}>Posts</span>
					<span className={styles.featureTitle}>
						Post your next event
					</span>
					<span className={styles.featureDescription}>
						Vault is your go-to digital storage solution, designed
						to keep your data safe and secure.
					</span>
				</div>
				<div className={styles.feature}>
					<img
						className={styles.featureImg}
						src={require("images/chat_color.svg").default}
						alt={"chat"}
					/>
					<span className={styles.featureSubtitle}>Messaging</span>
					<span className={styles.featureTitle}>Text me asap!</span>
					<span className={styles.featureDescription}>
						Vault is your go-to digital storage solution, designed
						to keep your data safe and secure.
					</span>
				</div>
				<div className={styles.feature}>
					<img
						className={styles.featureImg}
						src={require("images/group_color.svg").default}
						alt={"chat"}
					/>
					<span className={styles.featureSubtitle}>Connections</span>
					<span className={styles.featureTitle}>
						All your friends
					</span>
					<span className={styles.featureDescription}>
						Vault is your go-to digital storage solution, designed
						to keep your data safe and secure.
					</span>
				</div>
			</div>
			<div className={styles.downloadView}>
				<div className={styles.download_img_wrapper}>
					<LazyImage
						inlineStyle={styles.downloadViewImg}
						image={
							<img
								src={
									"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/gaawk_feature_7.jpg"
								}
								alt={"downloadImage"}
							/>
						}
					/>
				</div>
				<div className={styles.downloadViewContainer}>
					<span className={styles.downloadViewTitle}>
						Let’s gaawk now!
					</span>
					<span className={styles.downloadViewSubtitle}>
						Feel free to register on our web platform or download
						our iOS or Android app today!
					</span>
					<div className={styles.downloadButtons}>
						<div className={styles.downloadButton}>
							<img
								className={styles.downloadButtonImg}
								src={require("images/google_play.svg").default}
								alt={"google play"}
							/>
						</div>
						<div className={styles.downloadButton}>
							<img
								className={styles.downloadButtonImg}
								src={require("images/app_store.svg").default}
								alt={"app store"}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingPageFeatures;
