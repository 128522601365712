import { useQueryClient } from "@tanstack/react-query";
import useCurrentUser from "hooks/useCurrentUser";
import usePaginatedResource from "hooks/usePaginatedResource";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import profileApi from "../../../api/profile";
import useApi from "../../../hooks/useApi";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import EmployeeItem from "./EmployeeItem";
import styles from "./PendingEmployeeModal.module.css";

const itemsPerPage = 20;

const PendingEmployeeModal = ({ show, onClose, onUpdateCount, onUpdate }) => {
	const queryClient = useQueryClient();

	const getPendingEmployeesApi = useApi(
		profileApi.getPendingEmployees,
		true,
		true
	);
	const verifyRoleApi = useApi(profileApi.verifyRole, true);
	const rejectRoleApi = useApi(profileApi.rejectRole, true);

	//* using react-query =======

	const { uuid } = useCurrentUser();

	const fetchPendingEmployees = async ({ pageParam = 0, signal }) => {
		const response = await getPendingEmployeesApi.request(
			pageParam,
			itemsPerPage
		);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			profileKeys.pendingEmployees(uuid),
			fetchPendingEmployees,
			itemsPerPage,
			show
		);

	//TODO >> add a logic to close the modal if no pending employees ?

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	// * =========

	const LoadingView = () => (
		<div>
			<ContactLoader />
			<ContactLoader />
			<ContactLoader />
		</div>
	);

	const handleUpdateStatus = async (status, employee) => {
		let response;
		if (status === "ACCEPT") {
			response = await verifyRoleApi.request(
				employee.profile.uuid,
				employee.uuid
			);
		} else {
			response = await rejectRoleApi.request(
				employee.profile.uuid,
				employee.uuid
			);
		}

		if (response.status === 200) {
			removeFromList(status);
			if (status === "ACCEPT" && employee.currentPosition) onUpdate();
		} else {
			toast.error("Something Wrong Happened");
		}
	};

	const removeFromList = (status) => {
		queryClient.invalidateQueries(profileKeys.pendingEmployees(uuid));
		onUpdateCount(status);
	};

	const employeesList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((employee, i) => (
					<div
						key={employee.uuid}
						ref={page.length === i + 1 ? viewRef : null}
					>
						<EmployeeItem
							data={employee}
							onUpdate={(status) =>
								handleUpdateStatus(status, employee)
							}
						/>
					</div>
				))
			),
		[data]
	);

	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={"Confirm Employees"}
			closeAlign={"right"}
			children={
				<div className={styles.container}>
					<div className={styles.info_text}>
						The people in the list below added experiences to their
						profile claiming that they work or have previously
						worked in your company. You can approve their claim or
						decline it.
					</div>

					{/* <div className={styles.employees_container} id="scrollable">
						<InfiniteScroll
							scrollableTarget="scrollable"
							dataLength={employees.length}
							next={loadMore}
							hasMore={hasMore}
							loader={loading && <LoadingView />}
						>
							{loading && employees.length === 0 && (
								<LoadingView />
							)}

							{!loading && employees.length === 0 && (
								<div
									className={`${styles.info_text} ${styles.margin_top}`}
								>
									You don't have any request for now.
								</div>
							)}

							{employeesList}
						</InfiniteScroll>
					</div> */}

					<div className={styles.employees_container}>
						<div className={styles.mr_10}>{employeesList}</div>
						{isFetching && <LoadingView />}
					</div>
				</div>
			}
		/>
	);
};

export default PendingEmployeeModal;
