import { useQueryClient } from "@tanstack/react-query";
import usePaginatedResource from "hooks/usePaginatedResource";
import acceptIcon from "images/blue-tick-35-x-35.svg";
import cancelIcon from "images/cancel-btn-red.svg";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import profileApi from "../../../api/profile";
import specialProfileApi from "../../../api/special-profile";
import useApi from "../../../hooks/useApi";
import ProfilePic from "../../Home/ProfilePic";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import SecondaryButton from "../../Utils/Button/SecondaryButton";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import { specialProfileTypes } from "../ProfileEdit/SpecialProfile/special-types";
import styles from "./PendingTalentModal.module.css";
import TalentItem from "./TalentItem";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const itemsPerPage = 20;

const PendingTalentModal = ({
	show,
	onClose,
	agencyId,
	onUpdateCount,
	onUpdate,
}) => {
	const queryClient = useQueryClient();

	const getTalentsApi = useApi(specialProfileApi.getTalents, true, true);
	const acceptTalentRequestApi = useApi(profileApi.acceptTalentRequest, true);
	const declineTalentRequestApi = useApi(
		profileApi.declineTalentRequest,
		true
	);

	//* using react-query =======

	const fetchPendingTalents = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, uuid, status, type] = queryKey;

		const response = await getTalentsApi.request(
			uuid,
			pageParam,
			itemsPerPage,
			status,
			type
		);

		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			specialProfileKeys.getTalents(agencyId, false),
			fetchPendingTalents,
			itemsPerPage,
			show
		);

	//TODO >> add a logic to close the modal if no pending talent ?

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const talentsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((talent, i) => (
					<TalentItem
						key={talent.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						data={talent}
						rightAction={
							<div className={styles.controls}>
								<button
									onClick={() => {
										handleTalentRequest(talent, "decline");
									}}
								>
									<img src={cancelIcon} alt="" />
								</button>
								<button
									onClick={() => {
										handleTalentRequest(talent, "accept");
									}}
								>
									<img src={acceptIcon} alt="" />
								</button>
							</div>
						}
					/>
				))
			),
		[data]
	);

	// * =========

	const [warningModal, setWarningModal] = useState(false);
	const [selectedTalent, setSelectedTalent] = useState();

	const LoadingView = () => (
		<div>
			<ContactLoader />
			<ContactLoader />
			<ContactLoader />
		</div>
	);

	const handleTalentRequest = (talent, requestStatus) => {
		if (requestStatus === "accept") {
			talent["formattedType"] = `${
				/[aeiou]/i.test(talent.type[0]) ? "an" : "a"
			} ${
				specialProfileTypes.find((type) => type.value === talent.type)
					.label
			}`;
			talent["requestStatus"] = requestStatus;
		}
		setSelectedTalent(talent);
		setWarningModal(true);
	};

	const handleAlias = ({ target }) => {
		setSelectedTalent((prevState) => ({
			...prevState,
			alias: target.value,
		}));
	};

	const removeTalentFromList = () => {
		queryClient.invalidateQueries(
			specialProfileKeys.getTalents(agencyId, false)
		);
		setWarningModal(false);
		onUpdateCount();
	};

	const handleConfirm = async () => {
		let response;
		if (selectedTalent.requestStatus === "accept") {
			//* ACCEPTING THE TALENT
			response = await acceptTalentRequestApi.request(
				agencyId,
				selectedTalent.uuid,
				selectedTalent.alias || ""
			);
		} else {
			//* DECLINING THE TALENT
			response = await declineTalentRequestApi.request(
				agencyId,
				selectedTalent.uuid
			);
		}

		if (response.status === 200) {
			removeTalentFromList();
			onUpdate();
		} else {
			toast.error("Something Wrong Happened");
		}
	};

	return (
		<>
			<GaawkModal
				show={show && !warningModal}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={"Pending Talents"}
				closeAlign={"right"}
				children={
					<div className={styles.container}>
						<div className={styles.info_text}>
							The people in the list below want to be presented by
							your agency. You can either approve and list them to
							your talents, or you can decline their request. You
							can also choose an alias name to be displayed.
						</div>

						<div className={styles.talent_container}>
							<div className={styles.mr_10}>{talentsList}</div>
							{isFetching && <LoadingView />}

							<NoResults
								visible={
									data?.pages[0].length === 0 && !isFetching
								}
								text={"You don't have any request for now."}
							/>
						</div>
					</div>
				}
			/>
			<GaawkModal
				show={warningModal}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={
					selectedTalent?.requestStatus === "accept"
						? "List a talent"
						: "Decline Request"
				}
				closeAlign={"right"}
				children={
					<div className={styles.container}>
						<div className={styles.warning_container}>
							<ProfilePic
								w={48}
								h={48}
								thumbnail={
									selectedTalent?.profilePic.file.customName
								}
								type="USER"
							/>
							{selectedTalent?.requestStatus === "accept"
								? `You are about to list the talent ${selectedTalent?.name} as ${selectedTalent?.formattedType} in your Agency’s talent’s list.`
								: `Are you sure you want to decline talent request from ${selectedTalent?.name}. This action can NOT be undone.`}
						</div>

						{selectedTalent?.requestStatus === "accept" && (
							<div className={styles.input_wrapper}>
								<label>Alias name</label>
								<TextInput
									onChange={handleAlias}
									placeholder="Enter Alias Name"
								/>
								<p>
									If you choose to enter an alias name for
									this talent, your chosen alias name will be
									displayed instead of the talent’s stage
									name.
								</p>
							</div>
						)}
						<div className={styles.button_container}>
							<SecondaryButton
								text={"Cancel"}
								className={`${styles.btn} ${styles.cancel}`}
								onClick={() => {
									setSelectedTalent(undefined);
									setWarningModal(false);
								}}
							/>

							<PrimaryButton
								text={
									selectedTalent?.requestStatus === "accept"
										? "confirm"
										: "remove"
								}
								className={`${styles.btn} ${
									selectedTalent?.requestStatus === "accept"
										? undefined
										: styles.decline
								}`}
								type="button"
								onClick={handleConfirm}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default PendingTalentModal;
