import { useEffect, useMemo, useState } from "react";
import SearchInput from "../../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./InfiniteUsersPage.module.scss";
import useDebounce from "../../../../hooks/useDebounce";
import SearchUserSection from "./SearchUserSection";
import { useInView } from "react-intersection-observer";
import useApi from "../../../../hooks/useApi";
import { useInfiniteQuery } from "@tanstack/react-query";
import GroupMember from "../../../Chat/GroupMember";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "../../../Utils/SubComs/NoResults/NoResults";

const itemsPerPage = 20;

const InfiniteUsersPage = ({
	onInputChange,
	userCoordinates,
	isMobile,
	onShowModal,
	tagsList = [],
	endpoint,
	queryKey,
	initialFilters,
	searchQueryKey,
	searchEndpoint,
	isCompany = false,
}) => {
	//! =========== SEARCH INPUT HANDLER =============

	const [searchInput, setSearchInput] = useState("");

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const debouncedSearch = useDebounce(searchInput);

	useEffect(() => {
		onInputChange(debouncedSearch);
	}, [debouncedSearch]);

	//! ==============================================

	const { ref: viewRef, inView } = useInView();

	const getDataApi = useApi(endpoint, true, true);

	const fetchData = async ({ pageParam = 0, queryKey }) => {
		const [queryName, userCoordinates] = queryKey;

		const response = await getDataApi.request(
			pageParam,
			itemsPerPage,
			...(userCoordinates
				? [userCoordinates.lat, userCoordinates.lng]
				: [])
		);
		return response.data;
	};

	const {
		data,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		fetchStatus,
	} = useInfiniteQuery({
		queryKey,
		queryFn: fetchData,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},

		// staleTime: 300000, // 5 minutes
	});

	const formattedCompanies = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((user, i) => (
					<div
						key={user.uuid}
						ref={page.length === i + 1 ? viewRef : null}
						className={styles.contact_item}
					>
						<GroupMember data={user} redirect={true} />
					</div>
				))
			),
		[data]
	);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) fetchNextPage();
	}, [inView, hasNextPage, fetchNextPage, isFetching]);

	return (
		<div className={styles.container}>
			<div className={styles.search_input_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={() => setSearchInput("")}
					border={false}
					placeholder={`Search ${isCompany ? "Companies" : "People"}`}
					classNameInput={styles.search_input}
				/>
			</div>

			{searchInput ? (
				<SearchUserSection
					inputValue={searchInput}
					userCoordinates={userCoordinates}
					initialFilters={initialFilters}
					searchQueryKey={searchQueryKey}
					searchEndpoint={searchEndpoint}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
				/>
			) : fetchStatus !== "idle" && isLoading ? (
				<div className={styles.section_wrapper}>
					<LoadingSpinner />
				</div>
			) : (
				<>
					<NoResults
						visible={
							!isLoading &&
							!formattedCompanies?.some(
								(pageArray) => pageArray.length > 0
							)
						}
						text="No results"
					/>
					<div className={styles.section_wrapper}>
						{formattedCompanies}
					</div>
				</>
			)}
		</div>
	);
};

export default InfiniteUsersPage;
