import frameApi from "api/frame";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useApi from "hooks/useApi";
import useDebounce from "hooks/useDebounce";
import usePaginatedResource from "hooks/usePaginatedResource";
import { framesKeys } from "queryKeys/frames-key-factory";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import FrameItem from "./FrameItem";
import styles from "./FrameModal.module.scss";
import useCurrentUser from "hooks/useCurrentUser";

const itemsPerPage = 20;

const FrameModal = ({ show, onClose, imagePreview, onSelect }) => {
	//! =========== SEARCH INPUT HANDLER =============
	const [searchInput, setSearchInput] = useState("");

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const debouncedSearch = useDebounce(searchInput);

	// !====================SEARCH QUERY=========================

	const { type } = useCurrentUser();

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const searchFramesApi = useApi(frameApi.searchFrames, true, true);

	const searchFrames = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, searchInput, type] = queryKey;

		const response = await searchFramesApi.request(
			pageParam,
			itemsPerPage,
			searchInput,
			type
		);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			framesKeys.search(debouncedSearch, type),
			searchFrames,
			itemsPerPage,
			!!debouncedSearch
		);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	// const handleSelectedFrame = useCallback(
	// 	(frameUrl, frameId) => {
	// 		dispatch(selectedFrame(frameUrl));
	// 		dispatch(selectedFrameId(frameId));
	// 		navigate("/profile/profilePic");
	// 	},
	// 	[dispatch]
	// );

	const searchedFramesList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page?.map((item, i) => (
					<div
						ref={page.length === i + 1 ? viewRef : null}
						key={item.uuid}
					>
						<FrameItem
							item={item}
							userImg={imagePreview}
							onSelect={onSelect}
						/>
					</div>
				))
			),
		[data, imagePreview]
	);

	// !====================SUGGESTED FRAMES=========================

	const { ref: viewFramesRef, inViewFrames } = useInView({
		triggerOnce: true,
	});

	const suggestedFramesApi = useApi(frameApi.getSuggestedFrames, true, true);

	const fetchFrames = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, type] = queryKey;

		const response = await suggestedFramesApi.request(
			pageParam,
			itemsPerPage,
			type
		);
		return response.data;
	};

	const {
		data: frames,
		isFetching: isFetchingFrames,
		hasNextPage: hasNextPageFrames,
		fetchNextPage: fetchNextPageFrame,
	} = usePaginatedResource(
		framesKeys.suggested(type),
		fetchFrames,
		itemsPerPage,
		show
	);

	useEffect(() => {
		if (inViewFrames && hasNextPageFrames && !isFetchingFrames) {
			fetchNextPageFrame();
		}
	}, [hasNextPageFrames, inViewFrames, isFetchingFrames]);

	const framesList = useMemo(
		() =>
			frames?.pages?.map((page) =>
				page?.map((item, i) => (
					<div
						ref={page.length === i + 1 ? viewFramesRef : null}
						key={item.uuid}
					>
						<FrameItem
							item={item}
							userImg={imagePreview}
							onSelect={onSelect}
						/>
					</div>
				))
			),
		[frames, imagePreview]
	);

	const noResults = searchInput
		? data?.pages?.[0]?.length === 0
		: frames?.pages?.[0]?.length === 0;

	return (
		<GaawkModal
			show={show}
			title={"Add Frame"}
			closeAlign={"right"}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			children={
				<div className={styles.container}>
					<div className={styles.search_input_wrapper}>
						<SearchInput
							onChange={handleSearchInputChange}
							value={searchInput}
							showIcons={true}
							onClearClicked={() => setSearchInput("")}
							border={false}
						/>
					</div>

					<div className={styles.frames_wrapper}>
						{searchInput ? searchedFramesList : framesList}
					</div>

					<NoResults
						visible={noResults}
						text={
							searchInput
								? "No results related to your search"
								: "No results"
						}
						isSearch={searchInput}
					/>

					<LoadingSpinner visible={isFetching || isFetchingFrames} />
				</div>
			}
		/>
	);
};

export default FrameModal;
