import GaawkSection from "components/Utils/GaawkSection/GaawkSection";
import styles from "./FeaturesSidebar.module.scss";

const FeaturesSidebar = ({ active = "" }) => {
    return (
        <div className={styles.container}>
            <GaawkSection
                inline={true}
                tileStyle={styles.tile}
                labelStyle={styles.label}
                customStyle={styles.tile_item}
                active={active}
            />
        </div>
    );
};

export default FeaturesSidebar;
