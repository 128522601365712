import { classNames } from "primereact/utils";
import styles from "./FeedContainer.module.scss";

const FeedContainer = ({ children, className }) => {
	return (
		<div className={classNames(styles.container, className)}>
			{children}
		</div>
	);
};

export default FeedContainer;
