import Invoice from "components/Plans/Invoice";
import Payment from "components/Plans/Payment";
import Plans from "components/Plans/Plans";
import Subscribe from "components/Plans/Subscribe";
import routes from "components/Routing/routing-keys";
import { packageTypes } from "components/Utils/General";
import DefaultFullPage from "components/Utils/PageArchs/DefaultFullPage/DefaultFullPage";
import useGetQueryParam from "hooks/useGetQueryParam";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PlansView = () => {
	const { pathname, state } = useLocation();

	const navigate = useNavigate();

	const { paramValue: unsub } = useGetQueryParam("unsub");
	const { paramValue: plan } = useGetQueryParam("plan");

	const isUnsub = unsub === "true";

	const redirectingToPlans = () => {
		navigate(routes.plans, { replace: true });
		return null;
	};

	//* REDIRECTION LOGIC
	useEffect(() => {
		if (pathname === routes.subscribe()) {
			if (!unsub && !plan) {
				redirectingToPlans();
			} else if (unsub && unsub !== "true") {
				redirectingToPlans();
			} else if (plan && !Object.values(packageTypes).includes(plan)) {
				redirectingToPlans();
			}
		}

		if (pathname === routes.payment) {
			if (!state) navigate(routes.plans);
		}

		if (pathname === routes.invoice) {
			if (!state) navigate(routes.plans);
		}
	}, [pathname]);

	let component;
	let showBackButton = true;
	let backLabel = "";

	switch (pathname) {
		case "/plans":
			component = <Plans />;
			backLabel = "Payments & Plans";
			break;

		case "/plans/subscribe":
			component = <Subscribe />;
			backLabel = `${isUnsub ? "Cancel" : "Downgrade"} Subscription`;
			break;

		case "/plans/payment":
			component = <Payment plan={state} />;
			backLabel = "Payment Details";
			break;

		case "/plans/invoice":
			component = <Invoice plan={state} />;
			backLabel = "Invoice";

			break;

		default:
			break;
	}

	return (
		<DefaultFullPage
			content={component}
			showBackButton={showBackButton}
			backLabel={backLabel}
		/>
	);
};

export default PlansView;
