import React, { useState } from "react";
import styles from "./ExperienceItem.module.css";
import verifiedIcon from "images/verified.svg";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";

import { deleteExperience } from "../../../../store/slices/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import EditButton from "components/Utils/Button/EditButton";
import DeleteButton from "components/Utils/Button/DeleteButton";
import ProfilePic from "components/Home/ProfilePic";

const ExperienceItem = ({ item, showControls = true }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [experienceId, setExperienceId] = useState("");
	const [roleId, setRoleId] = useState("");
	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const handleEdit = (experienceItem, role) => () => {
		navigate("/profile/experience/edit", {
			state: {
				experienceItem: experienceItem,
				role: role,
			},
		});
	};

	const handleDelete = (experienceId, role) => () => {
		setWarningDeleteModal(true);
		setExperienceId(experienceId);
		setRoleId(role.uuid);
	};

	const handleConfirmDelete = () => {
		dispatch(deleteExperience(experienceId, roleId));
		setWarningDeleteModal(false);
	};

	const experienceRoles = item.roleList.map((role) => (
		<div key={role.uuid} className={styles.outer}>
			<div className={styles.experience_card}>
				<div className={styles.position_wrapper}>
					<div className={styles.position_info}>
						<span className={styles.position_title}>
							{role.title ? role.title : role.role.name}
						</span>

						<span className={styles.subData}>
							{`${role.locationDTO?.name}, ${role.locationDTO?.countryDTO.name}`}
						</span>

						<span className={styles.subData}>
							{`${format(
								new Date(role.startDate),
								"MMMM yyyy"
							)} - ${
								role.endDate === 0 || role.currentPosition
									? "Present"
									: format(
											new Date(role.endDate),
											"MMMM yyyy"
									  )
							}`}
						</span>
					</div>

					{role.verified && (
						<img
							src={verifiedIcon}
							className={styles.verified_icon}
							alt="verified"
						/>
					)}
				</div>
				{role.description && <p>{role.description}</p>}
				{showControls && (
					<div className={styles.controls}>
						<EditButton onClick={handleEdit(item, role)} />
						<DeleteButton onClick={handleDelete(item.uuid, role)} />
					</div>
				)}
			</div>
		</div>
	));

	const companyName = item.companyName
		? item.companyName
		: item.company
		? item.company.name
		: "";

	return (
		<>
			<div className={styles.experience_item}>
				<div className={styles.experience_header}>
					{/* {item.company && item.company.profileImage ? (
						<img
							src={item.company.profileImage.image}
							className={styles.company_logo}
							alt=""
						/>
					) : (
						<div className={styles.experience_icon}></div>
					)} */}
					<ProfilePic
						type="COMPANY"
						thumbnail={item.company?.profileImage?.image}
						name={companyName}
						enableName={false}
					/>

					<div className={styles.company_details}>
						<span className={styles.company_position}>
							{companyName}
						</span>
						{/* <span className={styles.company_location}>
                            {item.company && item.company.city.name}
                        </span> */}
					</div>
				</div>
				<div className={styles.position_details}>{experienceRoles}</div>
			</div>

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default ExperienceItem;
