import React, { useState, useEffect, useRef } from "react";
import styles from "./CommentItem.module.css";
import postApi from "../../api/post";
import useApi from "../../hooks/useApi";

import { useNavigate } from "react-router-dom";
import { getDiffIn } from "../Utils/General";
import ProfilePic from "./ProfilePic";
import moreIcon from "images/three-dots-black-5-x-20.svg";
import replyIcon from "images/reply-icon.svg";

import binIcon from "images/bin-icon.svg";
import editIcon from "images/rename-icon.svg";
import FormatSpecialText from "../Utils/SubComs/FormatSpecialText/FormatSpecialText";

import CommentInput from "./CommentInput";
import Popover from "../Utils/Popover/Popover";

import CommentReactionsCounter from "../Utils/Reactions/CommentReactionsCounter";
import ReadMore from "../Utils/SubComs/ReadMore/ReadMore";

const ReplyItem = ({
	commentId,
	reply,
	onReplyUpdate,
	onTextChange,
	editedText,
	onDelete,
	profileImg,
	type,
	url,
	onReply,
	isFullscreenPost,
}) => {
	const addReplyReactionApi = useApi(postApi.addReplyReaction);
	const removeReplyReactionApi = useApi(postApi.removeReplyReaction);
	const updateReplyApi = useApi(postApi.updateReply);

	const navigate = useNavigate();

	const popoverRef = useRef(null);

	// !===== REACTION HANDLERS =======

	const handleAddReaction = (reactionType) => {
		if (reply.profileReactionType === reactionType)
			removeReplyReactionApi.request(reply.uuid);
		else addReplyReactionApi.request(reply.uuid, reactionType);
	};

	const handleReactionBtnClicked = () => {
		if (reply.profileReactionType)
			removeReplyReactionApi.request(reply.uuid);
		else addReplyReactionApi.request(reply.uuid, "gaawk");
	};

	useEffect(() => {
		if (
			!addReplyReactionApi.loading &&
			addReplyReactionApi.responseCode === 200 &&
			addReplyReactionApi.data
		) {
			onReplyUpdate(addReplyReactionApi.data);
		}
	}, [addReplyReactionApi.data]);

	useEffect(() => {
		if (
			!removeReplyReactionApi.loading &&
			removeReplyReactionApi.responseCode === 200 &&
			removeReplyReactionApi.data
		) {
			onReplyUpdate(removeReplyReactionApi.data);
		}
	}, [removeReplyReactionApi.data]);

	// !===== REPLY EDIT =======

	const [isEditing, setIsEditing] = useState(false);

	const handleEdit = () => {
		setIsEditing((prevState) => !prevState);
		onTextChange(reply.reply);
	};

	const handleConfirmReply = () => {
		updateReplyApi.request({
			reply: editedText,
			replyId: reply.uuid,
			commentId,
		});
	};

	useEffect(() => {
		if (
			!updateReplyApi.loading &&
			updateReplyApi.responseCode === 200 &&
			updateReplyApi.data
		) {
			onReplyUpdate(updateReplyApi.data);
			setIsEditing(false);
		}
	}, [updateReplyApi.data]);

	const handleEmojiClick = (_, emojiObject) => {
		onTextChange(editedText + emojiObject.emoji);
	};

	const handleCancelEdit = () => {
		setIsEditing(false);
		onTextChange("");
	};

	return (
		<>
			{!isEditing ? (
				<div className={styles.comment_item} key={reply.uuid}>
					<ProfilePic
						thumbnail={
							reply.profile.profileImage
								? reply.profile.profileImage.image
								: undefined
						}
						type={reply.profile.type}
						url={reply.profile.url}
					/>

					<div className={styles.comment_flex}>
						<div className={styles.comment}>
							<div className={styles.comment_header}>
								<div>
									<label
										className={styles.owner}
										onClick={() =>
											navigate(
												`/profile/${reply.profile.url}`
											)
										}
									>
										{reply.profile.name}
									</label>
									<span className={styles.time}>
										{getDiffIn(reply.replyTime)}
									</span>
								</div>

								<Popover
									ref={popoverRef}
									render={
										<div className="popover_container">
											<>
												<div
													className="popover_item"
													onClick={() => {
														onReply(
															reply.profile.url
														);
														popoverRef.current.closePopover();
													}}
												>
													<img
														src={replyIcon}
														alt="reply"
													/>
													<span>Reply</span>
												</div>

												<div
													className="popover_item"
													onClick={handleEdit}
												>
													<img
														src={editIcon}
														alt="forward"
													/>
													<span>Edit</span>
												</div>

												<div
													className="popover_item"
													onClick={() =>
														onDelete(reply.uuid)
													}
												>
													<img
														src={binIcon}
														alt="delete"
													/>
													<span>Delete</span>
												</div>
											</>
										</div>
									}
								>
									<button className={styles.option_icon}>
										<img src={moreIcon} alt="options" />
									</button>
								</Popover>
							</div>

							<ReadMore>
								<FormatSpecialText
									text={reply.reply}
									customStyle={styles.reply_text}
								/>
							</ReadMore>
						</div>

						<CommentReactionsCounter
							item={reply}
							onReply={() => onReply(reply.profile.url)}
							onClick={handleReactionBtnClicked}
							onReact={handleAddReaction}
							isFullscreenPost={isFullscreenPost}
						/>
					</div>
				</div>
			) : (
				<>
					<CommentInput
						profileImg={profileImg}
						type={type}
						url={url}
						onTextChange={onTextChange}
						text={editedText}
						// onQuery={handleQuery}
						// onKeyPress={handleKeyPress}
						onEmojiClick={handleEmojiClick}
						onCancel={handleCancelEdit}
						isEditing={isEditing}
						onConfirm={handleConfirmReply}
						//TODO: ADD isReply prop for styling purposes
					/>
				</>
			)}
		</>
	);
};

export default ReplyItem;
