import React, { useEffect, useMemo, useState } from "react";
import styles from "../JobEntry/JobEntry.module.css";

import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";

import Switch from "react-switch";
import CreatableInput from "../../Utils/SubComs/Inputs/CreatableInput/CreatableInput";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import useApi from "../../../hooks/useApi";
import projectApi from "../../../api/project";
import {
	defaultFormatter,
	locationFormatter,
	unduplicateIds,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TagContainer from "../../Utils/SubComs/Tags/TagContainer";
import Tag from "../../Utils/SubComs/Tags/Tag";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import MapItem from "../../Profile/ProfileEdit/Contacts/Address/MapItem";
import {
	useNavigate,
	useLocation,
	useParams,
	Navigate,
} from "react-router-dom";
import useCompanyId from "../../../hooks/useCompanyId";
import LoadingPage from "../../Utils/SubComs/CustomLoader/LoadingPage";
import CropModal from "../../Utils/CropModal/CropModal";
import projectSchema from "./project-schema";
import InfiniteSearchInput from "../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useFetchLocation from "../../../hooks/useFetchLocation";
import MapSelect from "../../Utils/MapSelect/MapSelect";
import useMutate from "hooks/useMutate";
import PromptModal from "components/Utils/Prompt/PromptModal";
import routes from "components/Routing/routing-keys";

const itemsPerPage = 20;

const ProjectEntry = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { projectId } = useParams();

	const editMode = useMemo(
		() => pathname === `/jobs/project/${projectId}/edit`,
		[pathname, projectId]
	);

	const [isLoading, setIsLoading] = useState(false);

	useMemo(() => {
		if (editMode) {
			setIsLoading(true);
		}
	}, [editMode]);

	const companyId = useCompanyId();

	const getProjectApi = useApi(projectApi.getProject, true);
	const getProjectTagApi = useApi(projectApi.getProjectTags);

	const [imageName, setImageName] = useState("");

	useEffect(() => {
		const fetchProjectData = async () => {
			const response = await getProjectApi.request(projectId);

			if (companyId === response.data.owner.uuid) {
				const {
					title,
					active,
					descriptionText,
					startDate,
					endDate,
					image,
					tags,
					location,
				} = response.data;

				if (location)
					setSelectedLocation({
						address: location?.title,
						city: location.locationDTO?.name,
						country: location.locationDTO?.countryDTO?.name,
						countryCode: location.locationDTO?.countryDTO?.code,
						lat: location.latitude,
						lng: location.longitude,
					});

				reset({
					title,
					status: active,
					description: descriptionText,
					...(startDate > 0 && {
						startDate: new Date(startDate),
					}),
					...(endDate > 0 && {
						endDate: new Date(endDate),
					}),
					image: image ? [image.file] : [],
					tags: tags.map((tag) => ({
						label: tag.name,
						value: tag.uuid,
					})),
					...(location?.title && {
						address: location.title,
					}),
					...(location?.locationDTO && {
						city: {
							label: `${location.locationDTO.name}, ${location.locationDTO.countryDTO.name}`,
							value: location.locationDTO.id,
						},
					}),
				});

				setIsLoading(false);
			} else {
				navigate(`/jobs/project/${projectId}`);
			}
		};

		if (editMode) fetchProjectData();
	}, [editMode, projectId]);

	const {
		register,
		setValue,
		formState: { errors, isDirty },
		watch,
		handleSubmit,
		control,
		reset,
	} = useForm({
		resolver: joiResolver(projectSchema),
		mode: "all",
		defaultValues: {
			title: "",
			startDate: null,
			endDate: null,
			image: [],
			tags: [],
			status: true,
			description: "",
			city: null,
			address: "",
		},
	});

	const tagsWatcher = watch("tags");
	const statusWatcher = watch("status");
	const imageWatcher = watch("image");
	const cityWatcher = watch("city");

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const [cropModal, setCropModal] = useState(false);

	// ! ====== PROJECT TAGS ======

	const handleRemoveTag = (tagIndex) => {
		const updatedTags = tagsWatcher.filter(
			(_, index) => index !== tagIndex
		);
		setValue("tags", updatedTags);
	};

	const tagsList = useMemo(
		() =>
			tagsWatcher.map((tag, index) => (
				<Tag
					key={tag.value}
					itemName={tag.label}
					onRemove={() => handleRemoveTag(index)}
				/>
			)),
		[tagsWatcher]
	);
	// ! ====== LOCATION ======

	const [mapLocation, setMapLocation] = useState("");
	const [locationModal, setLocationModal] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState("");

	const handleMapLocation = (location) => {
		setMapLocation(location);
	};

	const handleSelectedLocation = () => {
		setValue("address", mapLocation.address);
		setSelectedLocation(mapLocation);
		setLocationModal(false);
	};

	const handleRemoveLocation = () => {
		setSelectedLocation("");
	};

	// ! ====================

	const {
		action: { mutate: addEditProject, isLoading: isLoadingAddEdit },
	} = useMutate(
		editMode ? projectApi.updateProject : projectApi.addProject,
		(response) => {
			navigate(`/jobs/project/${response.data.uuid}`);
		},
		undefined,
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		const {
			title,
			tags,
			status,
			startDate,
			endDate,
			description,
			image,
			city,
			address,
		} = data;

		const formData = new FormData();
		formData.append("active", status);
		formData.append("title", title);
		formData.append("description", description);
		if (tags.length > 0)
			formData.append(
				"tags",
				tags.map((tag) => tag.value)
			);
		if (address) formData.append("address", address);
		if (city) formData.append("locationId", city.value);
		// TODO >> CURRENTLY, WHEN TAG IS ADDED BY USER, IT SHOULD HIT THE API TO CREATE A NEW PROJECT TAG FIRST
		// TODO >> ASK WALEED IF WE CAN DO THE SAME AS SKILLS (ADD STRING WHEN TAG DOESN'T EXIST)
		if (startDate)
			formData.append("startDate", new Date(startDate).getTime());
		if (endDate) formData.append("endDate", new Date(endDate).getTime());

		// if (image[0] instanceof File) formData.append("image", image[0]);
		if (image[0] instanceof Blob)
			formData.append("image", image[0], imageName);

		if (
			selectedLocation &&
			cityWatcher &&
			selectedLocation.lat !== 0 &&
			selectedLocation.lng !== 0
		) {
			formData.append("latitude", selectedLocation.lat);
			formData.append("longitude", selectedLocation.lng);
		}

		if (editMode) formData.append("projectId", projectId);

		setShouldBlockSave(false);
		addEditProject(formData);
	};

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	if (!companyId) {
		//* 1 - redirecting if you're not a company (should not be able to edit job as a user)
		//* 2 - Knowing if you're the owner of the job and allow editing >> check performed in the useEffect
		return (
			<Navigate
				to={projectId ? routes.jobProject(projectId) : routes.jobs}
			/>
		);
	}

	return (
		<>
			{isLoading ? (
				<LoadingPage />
			) : (
				<form
					className={styles.job_form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<div className={styles.form_input_wrapper}>
						<label>
							Project Title
							<span className="required">*</span>
						</label>

						<TextInput
							{...register("title")}
							error={!!errors.title}
							placeholder="Enter Project Title"
						/>
						{errors?.title?.message && (
							<p className={styles.error_message}>
								{errors?.title?.message}
							</p>
						)}
					</div>

					<div className={styles.inline_input}>
						<div className={styles.item}>
							<label>Start Date</label>

							<DayPicker
								className={"form-start-date"}
								control={control}
								name="startDate"
								isClearable={true}
							/>
						</div>
						<div className={styles.item}>
							<label>End Date</label>
							<DayPicker
								className={`form-end-date ${
									!!errors.endDate ? "error" : undefined
								}`}
								control={control}
								name="endDate"
								isClearable={true}
							/>
							{errors?.endDate?.message && (
								<p className={styles.error_message}>
									{errors?.endDate?.message}
								</p>
							)}
						</div>
					</div>

					<div className={styles.form_input_wrapper}>
						<label>Project Image</label>

						{imageWatcher.length > 0 && (
							<div className={styles.thumbs_container}>
								<img
									src={
										imageWatcher[0] instanceof File ||
										imageWatcher[0] instanceof Blob
											? URL.createObjectURL(
													imageWatcher[0]
											  )
											: imageWatcher[0].customName
									}
									alt=""
								/>
							</div>
						)}

						<div className={styles.img_option}>
							<button type="button">
								<Controller
									name="image"
									control={control}
									render={() => (
										<input
											type="file"
											accept="image/png, image/jpg, image/jpeg"
											onChange={(val) => {
												val.target.files[0] &&
													setValue(
														"image",
														[val.target.files[0]],
														{ shouldDirty: true }
													);
												setImageName(
													val.target.files[0].name
												);
												val.target.value = "";
												setCropModal(true);
											}}
											tabIndex="-1"
										/>
									)}
								/>
								{imageWatcher.length > 0
									? "REPLACE IMAGE"
									: "+ ADD IMAGE"}
							</button>
						</div>
					</div>

					<CreatableInput
						multiSelect={true}
						label={"Project Tags"}
						onChange={(tag) =>
							setValue("tags", [...tagsWatcher, tag])
						}
						api={getProjectTagApi}
						unDuplicateFn={(passedData) =>
							unduplicateIds(
								tagsWatcher.map((tag) => tag.value),
								passedData
							)
						}
						formatter={defaultFormatter}
						cursor={"pointer"}
					/>

					<div className={styles.form_input_wrapper}>
						{tagsWatcher.length > 0 && (
							<TagContainer
								label="Selected Tags"
								items={tagsList}
							/>
						)}
					</div>

					<div className={styles.react_switch}>
						<Controller
							name="status"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Switch
									checked={value}
									onColor="#6cc5d1"
									offColor="#a6b1bc"
									handleDiameter={18}
									width={39}
									height={24}
									checkedIcon={false}
									uncheckedIcon={false}
									activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
									onChange={(val) => {
										onChange(val);
									}}
								/>
							)}
						/>
						<span>
							Project Status:{" "}
							<b>{statusWatcher ? "Open" : "Closed"}</b>
						</span>
					</div>

					<div className={styles.text_input}>
						<label>Project Description</label>

						<textarea
							{...register("description")}
							placeholder={"Enter Project Description"}
						/>
					</div>

					{/* <div className={styles.form_input_wrapper}>
                        <label>Address</label>
                        {selectedLocation && (
                            <div className={styles.address}>
                                {selectedLocation.address}
                            </div>
                        )}
                    </div> */}

					<div className={styles.form_input_wrapper}>
						<label>Address</label>

						<TextInput
							{...register("address")}
							placeholder="Enter address"
						/>
					</div>

					<InfiniteSearchInput
						queryName={"cities"}
						queryFn={fetchLocation}
						itemsPerPage={itemsPerPage}
						formatter={locationFormatter}
						label={"City & Country"}
						name="city"
						control={control}
						isClearable={true}
						customStyle={styles.margin_20}
					/>

					{cityWatcher && (
						<MapSelect
							location={selectedLocation}
							onClick={() => setLocationModal(true)}
							onRemove={handleRemoveLocation}
							customStyle={styles.map}
						/>
					)}

					<div className={styles.button_container}>
						<PrimaryButton
							className={styles.save_btn}
							text={editMode ? "save" : "create"}
							isLoading={isLoadingAddEdit}
						/>
					</div>

					<PromptModal when={isDirty && shouldBlockSave} />

					<GaawkModal
						show={locationModal}
						handleClose={() => setLocationModal(false)}
						defaultModal={false}
						showHeader={true}
						title={"Select Job Location"}
						closeAlign={"right"}
						children={
							<div className={styles.map_container}>
								<MapItem
									onLocation={handleMapLocation}
									locationData={
										selectedLocation || mapLocation
									}
								/>

								{mapLocation && (
									<div className={styles.button_container}>
										<PrimaryButton
											className={styles.save_btn}
											text="select"
											onClick={handleSelectedLocation}
										/>
									</div>
								)}
							</div>
						}
					/>

					<CropModal
						image={
							imageWatcher[0] instanceof File &&
							URL.createObjectURL(imageWatcher[0])
						}
						show={cropModal}
						onClose={() => setCropModal(false)}
						aspectMode={2 / 1}
						onCrop={(cropped) => {
							setValue("image", [cropped]);
							setCropModal(false);
						}}
						returnBlob={true}
					/>
				</form>
			)}
		</>
	);
};

export default ProjectEntry;
