import React from "react";
import styles from "./AlbumItemCounter.module.css";

import { ReactComponent as PdfIcon } from "images/pdf.svg";
import { ReactComponent as ImageIcon } from "images/image.svg";
import { ReactComponent as VideoIcon } from "images/video.svg";

const AlbumItemCounter = ({
	// audiosCount,
	imagesCount,
	videosCount,
	pdfsCount,
}) => {
	return (
		<div className={styles.counter_container}>
			{imagesCount > 0 && (
				<div className={styles.counter_item}>
					<ImageIcon className={styles.icon} />
					<p>{imagesCount}</p>
				</div>
			)}

			{videosCount > 0 && (
				<div className={styles.counter_item}>
					<VideoIcon className={styles.icon} />
					<p>{videosCount}</p>
				</div>
			)}

			{pdfsCount > 0 && (
				<div className={styles.counter_item}>
					<PdfIcon className={styles.icon} />
					<p>{pdfsCount}</p>
				</div>
			)}

			{/* //TODO >> include audio files ?  */}
		</div>
	);
};

export default AlbumItemCounter;
