import styles from "./button.module.css";
import LoadingSpinner from "../SubComs/LoadingSpinner/LoadingSpinner";

const PrimaryButton = ({
	text,
	onClick,
	className,
	disabled = false,
	type = "submit",
	isLoading = false,
	width = undefined,
}) => {
	return (
		<button
			disabled={disabled || isLoading}
			className={`${className} ${styles.primary} ${
				disabled || isLoading ? styles.disabled : undefined
			}`}
			onClick={onClick}
			type={type}
			style={{ width: width }}
		>
			{isLoading ? (
				<LoadingSpinner
					visible={isLoading}
					color={"#FFFFFF"}
					size={20}
					customStyle={styles.custom_loader}
				/>
			) : (
				text
			)}
		</button>
	);
};

export default PrimaryButton;
