import Joi from "joi";

const keyPeopleSchema = Joi.object({
	title: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
		__isNew__: Joi.boolean(),
	})
		.required()
		.messages({
			"any.required": "Title / Designation can't be empty",
			"object.base": "Title / Designation can't be empty",
		})
		.label("Title / Designation"),

	person: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.options({ stripUnknown: true })
		.required()
		.messages({
			"any.required": "Person's name can't be empty",
			"object.base": "Person's name can't be empty",
		})
		.label("Person's name"),
});

export default keyPeopleSchema;
