import React, { useState, useEffect } from "react";
import styles from "./Tabs.module.css";
import useApi from "../../../../hooks/useApi";
import useConnection from "../hooks/useConnection";
import connectionApi from "../../../../api/connection";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupMember from "../../../Chat/GroupMember";
// import PrimaryButton from "../../../Utils/Button/PrimaryButton";
// import SecondaryButton from "../../../Utils/Button/SecondaryButton";
// import ConnectionModal from "../../ProfileHeader/ConnectionModal";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";

const Pending = ({ query, count, onUpdateCounter }) => {
	const getReceivedApi = useApi(connectionApi.getReceived);
	// const acceptApi = useApi(connectionApi.acceptFriend);
	// const declineApi = useApi(connectionApi.declineFriend);

	const {
		data: received,
		fetchData,
		// deleteItem,
	} = useConnection(getReceivedApi, query);

	// const [pendingId, setPendingId] = useState("");
	// const [showConnectionModal, setShowConnectionModal] = useState(false);
	// const [profile, setProfile] = useState("");
	// const [modalAction, setModalAction] = useState("");

	// const handlePending = (profile) => {
	//     setProfile(profile);
	//     setShowConnectionModal(true);
	//     setModalAction("ACCEPT_FRIEND");
	// };

	const receivedList = received.map((item) => (
		<GroupMember key={item.uuid} data={item} redirect={true} />
	));

	// useEffect(() => {
	//     if (
	//         (!acceptApi.loading && acceptApi.responseCode === 200) ||
	//         (!declineApi.loading && declineApi.responseCode === 200)
	//     ) {
	//         deleteItem(profile.uuid);
	//         onUpdateCounter("friendPendingReceived");
	//     }
	// }, [
	//     acceptApi.loading,
	//     acceptApi.responseCode,
	//     declineApi.loading,
	//     declineApi.responseCode,
	// ]);

	// const handleModalConfirm = (action) => {
	//     if (action === "ACCEPT_FRIEND") {
	//         acceptApi.request(profile.uuid);
	//     } else {
	//         declineApi.request(profile.uuid);
	//     }
	//     setShowConnectionModal(false);
	// };

	return (
		<>
			<div id="scrollDiv" className={styles.container}>
				<InfiniteScroll
					dataLength={received.length}
					next={fetchData}
					hasMore={
						!query && received.length > 0 && received.length < count
					}
					loader={<ContactLoader />}
					scrollableTarget="scrollDiv"
				>
					{getReceivedApi.loading && <ContactLoader />}
					{receivedList.length > 0 &&
						!getReceivedApi.loading &&
						receivedList}
				</InfiniteScroll>
			</div>
			{/* <ConnectionModal
                show={showConnectionModal}
                name={profile.name}
                profileImage={
                    profile.profileImage
                        ? profile.profileImage.image
                        : undefined
                }
                action={modalAction}
                onConfirm={handleModalConfirm}
                onClose={() => setShowConnectionModal(false)}
            /> */}
		</>
	);
};

export default Pending;
