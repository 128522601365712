import { toast } from "react-toastify";

const useEditSubPost = (
	onSuccess,
	updateApi,
	returnResponseData = false,
	args = []
) => {
	const handleEdit = async (newItem) => {
		const body = {
			postId: newItem.uuid,
			...(newItem.caption && { caption: newItem.caption }),
			...(newItem.date > 0 && { date: newItem.date }),
			...(newItem.location && {
				location: {
					latitude: newItem.location.latitude,
					longitude: newItem.location.longitude,
					title: newItem.location.type,
					type: newItem.location.type,
				},
			}),
			...(newItem.taggedIds.length > 0 && {
				taggedIds: newItem.taggedIds.map((tag) => tag.uuid),
			}),
			...(newItem.services?.length > 0 && {
				serviceIds: newItem.services.map((service) => service.uuid),
			}),
		};

		const response = await updateApi.request(...args, body);
		if (response.status === 200) {
			//! The returned data is the parent post, not the subPost!

			const subPosts = [];

			if (response.data.totalMediaCount > 0) {
				subPosts.push(...response.data.mediaList);
			}

			if (response.data.totalPDFCount > 0) {
				subPosts.push(...response.data.pdfList);
			}

			const updatedSubPost = subPosts.find(
				(sub) => sub.uuid === newItem.uuid
			);

			//* if returnResponseData is true, returning the parent post,
			//* otherwise return the udpated subpost
			onSuccess(returnResponseData ? response.data : updatedSubPost);
		} else {
			toast.error("Something Wrong Happened!");
		}
	};

	return { handleEdit };
};

export default useEditSubPost;
