import { useStompClient, useSubscription } from "react-stomp-hooks";
import useSound from "use-sound";
import pingSound from "../../sounds/notification.mp3";
import { useDispatch, useSelector } from "react-redux";
import { updateResponse } from "store/slices/socket";
import useCompanyId from "hooks/useCompanyId";
import { useEffect } from "react";
import FileSaver from "file-saver";
import { reset } from "store/slices/chat";
import { toast } from "react-toastify";
import useMutate from "hooks/useMutate";
import chatApi from "api/chat";
import { updateChatTotalCount } from "store/slices/ui";

export const deliveryStatus = {
	pending: 0,
	sent: 1,
	received: 2,
	read: 3,
};

const SocketSubscription = () => {
	const companyId = useCompanyId();

	const { uuid: userId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const { conversationIdToExport } = useSelector((state) => state.chat);

	const dispatch = useDispatch();

	const stompClient = useStompClient();

	useEffect(() => {
		if (stompClient) {
			stompClient.publish({
				destination: "/app/connect",
				body: JSON.stringify({
					user: userId,
				}),
			});
		}
	}, [stompClient, userId]);

	// const disconnectIfNotLogged = () => {
	//     if (!userId) {
	//         stompClient.deactivate();
	//     }
	// };

	// stompClient?.beforeConnect(disconnectIfNotLogged());

	const [messageArrivedSound] = useSound(pingSound, { volume: 0.17 });

	const acknowledgeReceived = (response) => {
		// console.log("🚀 ~ acknowledge response >>", response);
		const message = {
			from: response.response.message.sendBy
				? response.response.message.sendBy.uuid
				: null,
			participantId: userId,
			messageId: response.response.message.messageId,
			messageStatus: deliveryStatus.received,
		};

		if (stompClient) {
			stompClient.publish({
				destination: "/app/mark.public",
				body: JSON.stringify(message),
			});
		}
	};

	const {
		action: { mutate: deleteFile },
	} = useMutate(chatApi.deleteExportedConversation);

	useSubscription(`/topic/messages.${userId}`, (message) => {
		const response = JSON.parse(message.body);

		const isMuted =
			response?.type === "MESSAGE_OBJ" && response.response?.muted;

		dispatch(updateResponse(response));

		switch (response.type) {
			case "MESSAGE_OBJ":
				// play the sound
				if (!isMuted) messageArrivedSound();

				// set the badge
				dispatch(
					updateChatTotalCount(response.response.totalUnreadCount)
				);

				if (response.response.message.messageStatus === "SENT") {
					acknowledgeReceived(response);
				}

				break;

			case "PDF_GENERATE_REQUEST":
				if (response.response.status && conversationIdToExport) {
					FileSaver.saveAs(response.response.response.fileUrl);
					deleteFile(response.response.response.fileId);
				} else if (!response.response.status) {
					toast.error("Couldn't export your conversation");
				}
				dispatch(reset());
				break;

			default:
				break;
		}

		//     const {conversationId, messageText, messageTime, receiver} =
		//         response.response;
		//
		//     const conversation = response.response;
		//
		//     // eslint-disable-next-line default-case
		//     switch (response.type) {
		//         case "HEARTBEAT":
		//             // console.log("Messages activated !!!!");
		//             break;
		//
		//         case "ACK":
		//             if (response.response.ack) {
		//                 dispatch(updateTypingStatus(response.response));
		//             } else {
		//
		//                 // update the message
		//                 dispatch(updateAck(conversation.message));
		//
		//                 dispatch(updateConversation(conversation)); // ==> add unexisting conversation and unshift to top of the list
		//             }
		//             break;
		//
		//         case "MEDIA_PENDING":
		//             break;
		//
		//         case "MEDIA_UPLOADED":
		//             // update the message
		//             dispatch(updateMessage(response.response));
		//
		//             const tempConversation = {
		//                 uuid: conversationId,
		//                 messageText,
		//                 messageTime,
		//                 minifiedProfile: receiver,
		//                 message: response.response,
		//             };
		//
		//             dispatch(updateLastMessage(conversation));
		//             dispatch(updateConversation(tempConversation));
		//
		//             break;
		//
		//         case "MESSAGE_OBJ":
		//             messageArrivedSound();
		//             const message = conversation.message;
		//             if (message) {
		//                 dispatch(addMessage(message));
		//
		//                 if (message.messageStatus !== "RECEIVED") {
		//
		//                     const feedback = {
		//                         messageId: message.messageId,
		//                         participantId: userId,
		//                         messageStatus: "RECEIVED",
		//                         from: message.sendBy.uuid,
		//                     };
		//                     sendMessage("/app/mark.public", feedback);
		//                 }
		//             }
		//             dispatch(updateConversation(conversation)); // ==> add unexisting conversation and unshit to top of the list
		//
		//             break;
		//
		//         default:
		//     }
		//
	});

	// const sendMessage = (dest, message) => {
	//
	//     const jsonMessage = JSON.stringify(message);
	//
	//     //Send Message
	//     stompClient.publish({
	//         destination: dest,
	//         body: jsonMessage
	//     });
	// };

	return null;
};

export default SocketSubscription;
