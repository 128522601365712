import styles from "./All.module.scss";
import JobsComponent from "../SearchComponents/Jobs";
import { jobsInitialFilters } from "../initialFilters";
import jobApi from "api/job";
import { useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import useApi from "hooks/useApi";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import useMutate from "hooks/useMutate";

const itemsPerPage = 20;

const Jobs = ({
	searchInput,
	isMobile,
	onShowModal,
	tagsList,
	userCoordinates,
}) => {
	const queryClient = useQueryClient();
	const filtersList = useSelector((state) => state.search.filters);
	const debouncedSearch = useDebounce(searchInput);

	const jobSearchApi = useApi(jobApi.search, true, true);

	const searchFn = async ({ pageParam = 0, queryKey, signal }) => {
		const [searchInput, filtersList, location] = queryKey;

		const hasFilters = Object.keys(filtersList).length > 0;

		const response = await jobSearchApi.request(
			pageParam,
			itemsPerPage,
			hasFilters
				? {
						...filtersList,
						...location,
						title: searchInput,
				  }
				: {
						...jobsInitialFilters,
						...location,
						title: searchInput,
				  }
		);
		return response.data;
	};

	const { data, hasNextPage, fetchNextPage, isLoading, isFetching } =
		useInfiniteQuery({
			queryKey: [
				debouncedSearch,
				filtersList,
				{ locationId: userCoordinates.id },
				"searchJobs",
			],
			queryFn: searchFn,
			getNextPageParam: (lastPage, pages) => {
				const nextPage =
					lastPage.list?.length === itemsPerPage
						? pages.length
						: undefined;
				return nextPage;
			},
		});

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, () =>
		queryClient.invalidateQueries([
			debouncedSearch,
			filtersList,
			{ locationId: userCoordinates.id },
			"searchJobs",
		])
	);

	return (
		<div className={styles.section}>
			<div className={styles.sub_section}>
				<JobsComponent
					count={data?.pages[0].count}
					data={data}
					isPaginated={true}
					hasNextPage={hasNextPage}
					isFetching={isFetching}
					onLoad={fetchNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
					onToggleSave={toggleSave}
				/>
			</div>
		</div>
	);
};

export default Jobs;
