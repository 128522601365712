import { useEffect, useState } from "react";
import styles from "./Companies.module.scss";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import useApi from "../../../hooks/useApi";
import UserSection from "../MoreComponents/UsersComponents/UserSection";
import profileApi from "../../../api/profile";
import SearchUserSection from "../MoreComponents/UsersComponents/SearchUserSection";
import useDebounce from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";

const itemsPerPage = 5;

const Companies = ({
	onInputChange,
	userCoordinates,
	isMobile,
	onShowModal,
	tagsList = [],
	initialFilters,
	searchQueryKey,
	searchEndpoint,
}) => {
	const navigate = useNavigate();

	//! =========== SEARCH INPUT HANDLER =============

	const [searchInput, setSearchInput] = useState("");

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const debouncedSearch = useDebounce(searchInput);

	useEffect(() => {
		onInputChange(debouncedSearch);
	}, [debouncedSearch]);

	//! =============== NEARBY COMPANIES ===============

	const nearbyCompaniesApi = useApi(
		profileApi.getNearbyCompanies,
		true,
		true
	);

	const fetchNearbyCompanies = async () => {
		const response = await nearbyCompaniesApi.request(
			0,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	//! =============== HIRING COMPANIES ===============

	const hiringCompaniesApi = useApi(
		profileApi.getHiringCompanies,
		true,
		true
	);

	const fetchHiringCompanies = async () => {
		const response = await hiringCompaniesApi.request(
			0,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	//! =============== NETWORK COMPANIES ===============

	const networkCompaniesApi = useApi(
		profileApi.getNetworkCompanies,
		true,
		true
	);

	const fetchNetworkCompanies = async () => {
		const response = await networkCompaniesApi.request(0, itemsPerPage);
		return response.data;
	};

	return (
		<div className={styles.container}>
			<div className={styles.search_input_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={() => setSearchInput("")}
					border={false}
					placeholder={"Search Companies"}
					classNameInput={styles.search_input}
				/>
			</div>

			{searchInput ? (
				<SearchUserSection
					inputValue={searchInput}
					userCoordinates={userCoordinates}
					initialFilters={initialFilters}
					searchQueryKey={searchQueryKey}
					searchEndpoint={searchEndpoint}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
				/>
			) : (
				<>
					<UserSection
						title="Companies you may know"
						queryKey={["networkCompanies"]}
						queryFn={fetchNetworkCompanies}
						// errorMessage={"Failed to get network companies"}
						onViewMore={() => navigate("/companies/network")}
					/>

					<UserSection
						title="Companies hiring now"
						queryKey={["hiringCompanies", userCoordinates]}
						queryFn={fetchHiringCompanies}
						// errorMessage={"Failed to get hiring companies"}
						onViewMore={() => navigate("/companies/hiring")}
					/>

					<UserSection
						title="Companies near you"
						queryKey={["nearbyCompanies", userCoordinates]}
						queryFn={fetchNearbyCompanies}
						// errorMessage={"Failed to get nearby companies"}
						onViewMore={() => navigate("/companies/nearby")}
					/>
				</>
			)}
		</div>
	);
};

export default Companies;
