import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "./useApi";
import connectionApi from "api/connection";
import { connectionsKeys } from "queryKeys/connections-key-factory";

const usePendingConnections = (
	pendingType = "received",
	searchQuery = undefined,
	itemsPerPage = 5,
	staleTime = 30000
) => {
	const queryClient = useQueryClient();

	const invalidate = () => {
		queryClient.invalidateQueries(
			connectionsKeys.list(pendingType, searchQuery)
		);
	};

	const getPendingRequests = useApi(
		pendingType === "received"
			? connectionApi.getReceived
			: connectionApi.getSent,
		true,
		true
	);

	const fetchPending = async ({ pageParam = 0, signal, queryKey }) => {
		const [, , , query] = queryKey;

		const response = await getPendingRequests.request(
			pageParam,
			itemsPerPage,
			query
		);
		return response.data;
	};

	const infiniteQuery = useInfiniteQuery({
		queryKey: connectionsKeys.list(pendingType, searchQuery),
		queryFn: fetchPending,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},

		staleTime,
	});

	return { infiniteQuery, invalidate };
};

export default usePendingConnections;
