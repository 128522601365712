import React, { useEffect, useState } from "react";
import styles from "./Comments.module.css";
import { useSelector } from "react-redux";
import postApi from "../../api/post";
import useApi from "../../hooks/useApi";
import CommentItem from "./CommentItem";
import { replaceItemInArray } from "../Utils/General";
import CommentInput from "./CommentInput";
import useCompanyId from "../../hooks/useCompanyId";

const commentsSize = 5;

const Comments = ({
	visible,
	postId,
	commentsCount,
	onUpdateCommentCount,
	onUpdateTotalCommentCount,
	isFullscreenPost,
	onIncrementComment,
	commentUniqueCount,
	onDecrementComment,
}) => {
	const getCommentsApi = useApi(postApi.getComments);
	const addCommentApi = useApi(postApi.addComment);
	const deleteCommentApi = useApi(postApi.deleteComment);

	// const { profileImage, type, url } = useSelector(
	//     (state) => state.user.profileInfo
	// );

	const companyId = useCompanyId();

	const { profileImage, type, url } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const [comments, setComments] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(0);
	const [commentText, setCommentText] = useState("");

	useEffect(() => {
		loadMore();
	}, []);

	const loadMore = () => {
		getCommentsApi.request(postId, page, commentsSize);
		setPage(page + 1);
	};

	useEffect(() => {
		if (
			!getCommentsApi.loading &&
			getCommentsApi.responseCode === 200 &&
			getCommentsApi.data
		) {
			if (comments.length + getCommentsApi.data.length < commentsCount)
				setHasMore(true);
			else setHasMore(false);

			if (comments.length === 0) setComments(getCommentsApi.data);
			else
				setComments((prevState) => [
					...prevState,
					...getCommentsApi.data,
				]);
		}
	}, [getCommentsApi.data]);

	const handleTextChange = (comment) => {
		setCommentText(comment);
	};

	const handleConfirmComment = () => {
		addCommentApi.request({ comment: commentText, postId });
		setCommentText("");
	};

	useEffect(() => {
		if (
			!addCommentApi.loading &&
			addCommentApi.responseCode === 201 &&
			addCommentApi.data
		) {
			setComments((prevState) => [addCommentApi.data, ...prevState]);
			onUpdateCommentCount("INCREMENT");
			// onIncrementComment();
		}
	}, [addCommentApi.data]);

	const handleEmojiClick = (_, emojiObject) => {
		setCommentText((prevState) => prevState + emojiObject.emoji);
	};

	const handleCommentUpdate = (updatedComment) => {
		setComments((prevState) =>
			replaceItemInArray(updatedComment, prevState, "uuid")
		);
	};

	// !--- REPLIES ----

	const handleUpdateRepliesCount = (commentId, operation) => {
		const updatedComment = comments.find(
			(comment) => comment.uuid === commentId
		);
		if (operation === "INCREMENT") {
			updatedComment.repliesCount += 1;
			onUpdateTotalCommentCount("INCREMENT");
		} else {
			updatedComment.repliesCount -= 1;
			onUpdateTotalCommentCount("DECREMENT");
		}

		setComments((prevState) =>
			replaceItemInArray(updatedComment, prevState, "uuid")
		);
	};

	// ! ========= COMMENT EDIT =========

	const [newText, setNewText] = useState("");

	const handleCommentEdit = (text) => {
		setNewText(text);
	};

	// ! ========= COMMENT DELETE =========
	const [commentIdToDelete, setCommentIdToDelete] = useState("");

	const handleDeleteComment = (commentId) => {
		setCommentIdToDelete(commentId);
		deleteCommentApi.request(postId, commentId);
	};

	useEffect(() => {
		if (
			!deleteCommentApi.loading &&
			deleteCommentApi.responseCode === 200
		) {
			setComments((prevState) =>
				prevState.filter(
					(comment) => comment.uuid !== commentIdToDelete
				)
			);
			onUpdateCommentCount("DECREMENT");
			// onDecrementComment();
		}
	}, [deleteCommentApi.loading, deleteCommentApi.responseCode]);

	// todo: add useMemo
	const commentList =
		comments.length > 0 &&
		comments.map((comment) => {
			return (
				<CommentItem
					key={comment.uuid}
					postId={postId}
					comment={comment}
					onCommentUpdate={handleCommentUpdate}
					onUpdateRepliesCount={handleUpdateRepliesCount}
					profileImg={profileImage?.originalImageURL}
					type={type}
					url={url}
					onTextChange={handleCommentEdit}
					editedText={newText}
					onDelete={handleDeleteComment}
					isFullscreenPost={isFullscreenPost}
				/>
			);
		});

	return (
		<div
			className={`${styles.post_comments_section} ${
				visible && styles.post_comments_section_visible
			}`}
		>
			<CommentInput
				profileImg={profileImage?.originalImageURL}
				type={type}
				url={url}
				onTextChange={handleTextChange}
				text={commentText}
				onEmojiClick={handleEmojiClick}
				isEditing={false}
				onConfirm={handleConfirmComment}
			/>

			<div className={styles.comments_wrapper}>{commentList}</div>

			{comments.length > 0 && hasMore && (
				<div className={styles.load_more_button}>
					<button onClick={loadMore}>
						View {commentsCount - comments.length} more comments
					</button>
				</div>
			)}
		</div>
	);
};

export default Comments;
