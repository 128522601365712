import React from "react";
import styles from "./ProfileEdit.module.css";
import worldIcon from "images/public_icon.svg";

function PublicInfo({ subtext, fixedMargin }) {
	return (
		<div
			className={
				fixedMargin
					? styles.public_info + " " + styles.fixed_mt
					: styles.public_info
			}
		>
			<img src={worldIcon} alt={"public"} />
			<label>{`Your ${subtext} is always public.`}</label>
		</div>
	);
}

export default PublicInfo;
