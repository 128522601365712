import { cloneElement, useState } from "react";
import {
    offset,
    flip,
    shift,
    autoUpdate,
    useFloating,
    useInteractions,
    useHover,
    useFocus,
    useRole,
    useDismiss,
} from "@floating-ui/react-dom-interactions";
import styles from "./Tooltip.module.css";

const Tooltip = ({ children, label, placement = "top" }) => {
    const [open, setOpen] = useState(false);

    const { x, y, reference, floating, strategy, context } = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        middleware: [offset(5), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context),
        useFocus(context),
        useRole(context, { role: "tooltip" }),
        useDismiss(context),
    ]);

    return (
        <>
            {cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}

            {open && (
                <div
                    ref={floating}
                    className={styles.tooltip}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                    }}
                    {...getFloatingProps()}
                >
                    {label}
                </div>
            )}
        </>
    );
};

export default Tooltip;
