import {
	useEffect,
	useRef,
	useImperativeHandle,
	forwardRef,
	useMemo,
	useState,
} from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import useApi from "../../hooks/useApi";
import postApi from "../../api/post";
import PostItem from "./PostItem";
import PostLoader from "../Utils/SubComs/CustomLoader/PostLoader";
import CreatePost from "./CreatePost";
import styles from "./Feeds.module.css";
import { useLocation } from "react-router-dom";
import FullScreenModal from "../Utils/GaawkModal/FullScreenModal";
import PostFullScreen from "./PostFullScreen";

import EditPostModal from "./EditPostModal";
import boardsApi from "../../api/boards";
import useDebounce from "../../hooks/useDebounce";
import NoResults from "../Utils/SubComs/NoResults/NoResults";
import usePostViewer from "../../hooks/usePostViewer";
import useMutate from "../../hooks/useMutate";
import usePostById from "../../hooks/usePostById";
import { boardsKeys } from "queryKeys/boards-key-factory";
import useUpdateReaction from "hooks/useUpdateReaction";
import { feedKeys } from "queryKeys/feed-key-factory";

import useCurrentUser from "hooks/useCurrentUser";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import SecondaryButton from "components/Utils/Button/SecondaryButton";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";

const feedSize = 20;

const Feeds = forwardRef(
	(
		{
			profileId = undefined,
			board = undefined,
			hashtagValue,
			hasSearch = false,
		},
		ref
	) => {
		const { ref: viewRef, inView } = useInView({ triggerOnce: true });
		const getFeedApi = useApi(postApi.getFeed, true, true);
		const getFeedByHashtagApi = useApi(
			postApi.getFeedByHashtag,
			true,
			true
		);
		const userFeedApi = useApi(postApi.getUserFeed, true, true);
		const getBoardPostsApi = useApi(boardsApi.getBoardPosts, true, true);
		const searchPostsBoardApi = useApi(
			boardsApi.searchPostsBoard,
			true,
			true
		);
		// const getBoardPinnedPostsApi = useApi(
		// 	boardsApi.getBoardPinnedPosts,
		// 	true,
		// 	true
		// );

		// const companyId = useCompanyId();

		// const { type, url, profileImage, firstName, lastName, name } =
		// 	useSelector((state) => {
		// 		if (companyId) {
		// 			return state.company.companyInfo;
		// 		} else {
		// 			return state.user.profileInfo;
		// 		}
		// 	});

		// ! ====== search board's posts ======

		const [isSearching, setIsSearching] = useState(false);
		const [searchInput, setSearchInput] = useState("");

		const handleSearchInputChange = ({ target }) => {
			const query = target.value;
			setIsSearching(true);

			setSearchInput(query);
		};

		useEffect(() => {
			const timeout = setTimeout(() => {
				setIsSearching(false);
			}, 500);

			return () => clearTimeout(timeout);
		}, [searchInput]);

		// !=================================

		const { type, url, profileImage, firstName, lastName, name, uuid } =
			useCurrentUser();

		const postRef = useRef();
		const { pathname } = useLocation();

		useImperativeHandle(ref, () => ({
			showCreateModal() {
				postRef.current.showCreateModal();
			},
		}));

		const queryClient = useQueryClient();

		const fetchFeed = async ({ pageParam = 0 }) => {
			let response;
			if (searchInput) {
				response = await searchPostsBoardApi.request(
					board.uuid,
					pageParam,
					feedSize,
					searchInput
				);
			}
			// else if (pinned) {
			// 	response = await getBoardPinnedPostsApi.request(
			// 		board.uuid,
			// 		pageParam,
			// 		feedSize
			// 	);
			// }
			else if (board) {
				response = await getBoardPostsApi.request(
					board.uuid,
					pageParam,
					feedSize
				);
			} else if (profileId) {
				response = await userFeedApi.request(
					profileId,
					pageParam,
					feedSize
				);
			} else if (hashtagValue) {
				response = await getFeedByHashtagApi.request(
					pageParam,
					feedSize,
					hashtagValue
				);
			} else {
				response = await getFeedApi.request(pageParam, feedSize);
			}

			if (profileId) return response.data.posts;
			return response.data;
		};

		const debouncedSearch = useDebounce(searchInput);

		const queryKey = useMemo(
			() =>
				debouncedSearch
					? boardsKeys.searchPosts(board.uuid, debouncedSearch)
					: board
					? boardsKeys.posts(board.uuid)
					: profileId
					? feedKeys.userFeed(profileId)
					: feedKeys.all,
			[board, debouncedSearch, profileId]
		);

		const invalidateQuery = (options = { queryKey }) => {
			queryClient.invalidateQueries(options);
		};

		const { data, hasNextPage, fetchNextPage, isLoading, isFetching } =
			useInfiniteQuery({
				queryKey: queryKey,
				queryFn: fetchFeed,
				// structuralSharing: false,
				getNextPageParam: (lastPage, pages) => {
					const nextPage =
						lastPage.length === feedSize ? pages.length : undefined;
					return nextPage;
				},

				// staleTime: 300000, // 5 minutes
			});

		const {
			action: { mutate: deletePost },
		} = useMutate(postApi.deletePost, invalidateQuery);

		const {
			action: { mutate: removeFromFeed },
		} = useMutate(postApi.removeFromFeed, invalidateQuery);

		const didMountRef = useRef(false);
		useEffect(() => {
			if (didMountRef.current) {
				if (!debouncedSearch) {
					queryClient.removeQueries(boardsKeys.searchList("posts"));
				}
			}
			didMountRef.current = true;
		}, [debouncedSearch]);

		useEffect(() => {
			if (inView && hasNextPage && !isFetching) fetchNextPage();
		}, [inView, hasNextPage, fetchNextPage, isFetching]);

		// !========== POST CLICK HANDLER ==============

		const {
			postModal,
			postId,
			subPostId,
			handleItemClicked,
			handleCloseModal,
			subPostIndex,
		} = usePostViewer();

		// ! ========== EDIT HANDLER ==========

		const isEditing = true;
		const { post, setPostId, showModal, setShowModal } =
			usePostById(isEditing);

		// ! ===================================

		//todo >> invalidate instead of doing it manually

		// const handleSaveEdit = (editedPost) => {
		// 	const firstFiveMedia = editedPost["subPosts"].slice(0, 5);
		// 	editedPost["subPosts"] = firstFiveMedia;
		// 	queryClient.setQueryData(queryKey, (prevData) => {
		// 		const newPagesArray = prevData.pages.map((page) =>
		// 			replaceItemInArray(editedPost, page, "uuid")
		// 		);
		// 		return {
		// 			pages: newPagesArray,
		// 			pageParams: prevData.pageParams,
		// 		};
		// 	});
		// };

		// const handleDeleteMedia = (parentPostId, subPostId, prevSubPosts) => {
		// 	queryClient.setQueryData(queryKey, (prevData) => {
		// 		let updatedPost;
		// 		prevData.pages.forEach((page) => {
		// 			const index = page.findIndex(
		// 				(post) => post.uuid === parentPostId
		// 			);
		// 			if (index > -1) {
		// 				updatedPost = page[index];
		// 			}
		// 		});
		// 		//* updating the count
		// 		updatedPost["totalMediaCount"] -= 1;
		// 		//* updating the subposts of the post
		// 		const updatedSubPosts = updatedPost.subPosts.filter(
		// 			(sub) => sub.uuid !== subPostId
		// 		);
		// 		if (
		// 			updatedSubPosts.length < updatedPost["childPostsCount"] &&
		// 			updatedPost["childPostsCount"] >= 5 &&
		// 			updatedSubPosts.length < 5
		// 		) {
		// 			const postToAdd = prevSubPosts.find(
		// 				(sub) =>
		// 					!updatedSubPosts
		// 						.map((x) => x.uuid)
		// 						.includes(sub.uuid)
		// 			);
		// 			updatedSubPosts.push(postToAdd);
		// 		}
		// 		updatedPost["subPosts"] = updatedSubPosts;
		// 		//* updating the post in the feed
		// 		const newPagesArray = prevData.pages.map((page) =>
		// 			replaceItemInArray(updatedPost, page, "uuid")
		// 		);
		// 		return {
		// 			pages: newPagesArray,
		// 			pageParams: prevData.pageParams,
		// 		};
		// 	});
		// };

		// const handleDeleteAllMedia = (parentPostId) => {
		// 	queryClient.setQueryData(queryKey, (prevData) => {
		// 		let updatedPost;
		// 		prevData.pages.forEach((page) => {
		// 			const index = page.findIndex(
		// 				(post) => post.uuid === parentPostId
		// 			);
		// 			if (index > -1) {
		// 				updatedPost = page[index];
		// 			}
		// 		});
		// 		//* updating the count
		// 		updatedPost["childPostsCount"] = 0;
		// 		//* updating the subposts of the post
		// 		updatedPost["subPosts"] = [];
		// 		//* updating the post in the feed
		// 		const newPagesArray = prevData.pages.map((page) =>
		// 			replaceItemInArray(updatedPost, page, "uuid")
		// 		);
		// 		return {
		// 			pages: newPagesArray,
		// 			pageParams: prevData.pageParams,
		// 		};
		// 	});
		// };

		// ! ========== POST UPDATE (add / remove reaction) ==========
		const updatePostReaction = useUpdateReaction();

		const handlePostUpdate = (reaction) => {
			updatePostReaction(queryKey, reaction);
		};

		// ! ========== POST SHARE ==========
		const handleShare = (post) => {
			if (post.sharedPost) {
				postRef.current.sharePost(post.sharedPost);
			} else {
				postRef.current.sharePost(post);
			}
		};

		// ! ============ PIN / UNPIN HANDLER =============

		const [showPinModal, setShowPinModal] = useState(false);
		const [activePost, setActivePost] = useState(undefined);

		const onToggleSuccess = () => {
			invalidateQuery();
			setShowPinModal(false);
		};

		const {
			action: { mutate: pinPost },
		} = useMutate(boardsApi.pinPost, onToggleSuccess);

		const {
			action: { mutate: unpinPost },
		} = useMutate(boardsApi.unpinPost, onToggleSuccess);

		const handleTogglePin = (post) => {
			setActivePost(post);
			setShowPinModal(true);
		};

		// !=====================================

		//* possible fix for laggy scroll >> https://www.youtube.com/watch?v=KBLrJ-5WVTE
		//* fixes to implement suggested by Claude.ai : https://claude.ai/chat/78f74ee4-2fa2-418c-a14b-da6293a51074

		const feedList = data?.pages.map((page) =>
			page.map((post, i) => {
				return (
					<PostItem
						key={post.uuid}
						ref={page.length === i + 1 ? viewRef : null} // assigning a ref to the third last post
						// ref={
						// 	page.length >= 5 && page.length - 5 === i
						// 		? viewRef
						// 		: null
						// } // assigning a ref to the fifth last post
						post={post}
						canEditBoard={
							post?.board?.moderator ||
							post?.board?.owner.uuid === uuid
						}
						isPinned={post.pinned}
						onItemClicked={handleItemClicked(post)}
						onDelete={() => {
							if (
								post.postType === "PROFILE_PIC" ||
								post.postType === "COVER_PIC"
							) {
								removeFromFeed(post.uuid);
							} else {
								deletePost(post.uuid);
							}
						}}
						onPostUpdate={handlePostUpdate}
						onEdit={(post) => setPostId(post.uuid)}
						onShare={handleShare}
						onTogglePin={() => handleTogglePin(post)}
					/>
				);
			})
		);

		return (
			<>
				<CreatePost
					ref={postRef}
					name={type === "USER" ? `${firstName} ${lastName}` : name}
					profileImg={profileImage}
					type={type}
					onCreate={invalidateQuery}
					isFeed={pathname === "/"}
					board={board}
				/>

				<div
					className={`${styles.feed_posts_section} ${
						board ? styles.board_feed : undefined
					}`}
				>
					{((hasSearch && data?.pages?.[0]?.length > 0) ||
						(hasSearch && queryKey[1] === "search")) && (
						<div className={styles.search_input_wrapper}>
							<SearchInput
								onChange={handleSearchInputChange}
								value={searchInput}
								showIcons={true}
								onClearClicked={() => setSearchInput("")}
								border={false}
								placeholder={"Search this board"}
							/>
						</div>
					)}

					{feedList}

					<NoResults
						visible={
							!isSearching &&
							!isFetching &&
							!feedList?.some((pageArray) => pageArray.length > 0)
						}
						noPadding={!board}
						isSearch={debouncedSearch}
						text={
							debouncedSearch
								? "No results related to your search"
								: "No results"
						}
						customStyle={
							board ? styles.board_no_results : undefined
						}
					/>

					{(isLoading || isFetching) && <PostLoader />}
				</div>

				<EditPostModal
					existingPost={post.data}
					name={type === "USER" ? `${firstName} ${lastName}` : name}
					profileImg={profileImage}
					type={type}
					url={url}
					show={showModal}
					toggleShow={(bool) => setShowModal(bool)}
					onInvalidate={() => invalidateQuery()}
					// onSaveEdit={handleSaveEdit}
					// onDeleteMedia={handleDeleteMedia}
					// onDeleteAllMedia={handleDeleteAllMedia}
					onClose={() => setPostId("")}
				/>

				<FullScreenModal
					show={postModal}
					onClose={() => {
						//ESC KEY WHEN IN THE MODAL TRIGGERS THIS
						handleCloseModal();
						window.history.pushState(null, "", pathname);
					}}
					fullWidth={true}
					children={
						<PostFullScreen
							postId={postId}
							initialSlideIndex={subPostIndex}
							subPostId={subPostId}
							isModal={true}
							onClose={handleCloseModal}
							onRefresh={invalidateQuery}
							onUpdateUrl={() =>
								window.history.pushState(null, "", pathname)
							}
						/>
					}
				/>

				<GaawkModal
					show={showPinModal}
					title={`${
						activePost?.pinned ? "Unpin" : "Pin"
					} post to board`}
					closeAlign={"right"}
					handleClose={() => setShowPinModal(false)}
					defaultModal={false}
					showHeader={true}
					children={
						<div className={styles.modal_container}>
							{activePost?.pinned
								? "Are you sure you want to remove pin from this post? It will no longer appear on top of post list."
								: "You can only pin one post at the time. So if you already have a pinned post, previous post will be unpinned. Are you sure you want to pin this post?"}
							<div className={styles.modal_button_wrapper}>
								<SecondaryButton
									className={styles.cancel_btn}
									text={"cancel"}
									onClick={() => setShowPinModal(false)}
								/>
								<PrimaryButton
									text={"yes, please"}
									onClick={() => {
										const postInfo = {
											boardId: activePost.board.uuid,
											postId: activePost.uuid,
										};

										activePost?.pinned
											? unpinPost(postInfo)
											: pinPost(postInfo);
									}}
								/>
							</div>
						</div>
					}
				/>
			</>
		);
	}
);

export default Feeds;
