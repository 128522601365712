import styles from "./LandingPageIntro.module.scss";

import packageIcon from "images/package.svg";

const LandingPageIntro = () => {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.line1}>
					<span className={styles.line1Text}>Services</span>
					<img
						className={styles.line1Img}
						src={packageIcon}
						alt={"services & products"}
					/>
					<span className={styles.line1Text}>Products</span>
				</div>
				<div className={styles.line2}>
					<span className={styles.line2Text}>
						All Services & Products in one place
					</span>
				</div>
				<div className={styles.line3}>
					<span className={styles.line3Text}>
						Discover, Connect, and Thrive - Your One-Stop
						Marketplace for All Services & Products. Share, Book,
						and Connect with Service Providers and Creators, All in
						One Place!
					</span>
				</div>
			</div>
		</div>
	);
};

export default LandingPageIntro;
