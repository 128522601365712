import { useMemo } from "react";
import Tag from "../../../../Utils/SubComs/Tags/Tag";
import styles from "./AdditionalDetails.module.css";

const AdditionalDetails = ({ skills, info }) => {
	const skillsList = useMemo(
		() =>
			skills.map((skill) => (
				<Tag key={skill.uuid} itemName={skill.name} />
			)),
		[skills]
	);

	return (
		<>
			<div className={styles.container}>
				{skills.length > 0 && (
					<div className={styles.subcontainer}>
						<h3>Skills</h3>
						<div className={styles.skills_container}>
							{skillsList}
						</div>
					</div>
				)}

				{info && (
					<div className={styles.subcontainer}>
						<h3>Additional Info</h3>
						<div>{info}</div>
					</div>
				)}
			</div>
		</>
	);
};

export default AdditionalDetails;
