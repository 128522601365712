import client from "./client";

const endpoint = "/product";

const getProductsByServiceId = (
	requestedProfileId,
	serviceId,
	page,
	size,
	q = "",
	sort = "NEWEST",
	filter
) =>
	client.post(
		endpoint +
			`/byService?requestedProfileId=${requestedProfileId}&serviceId=${serviceId}&page=${page}&size=${size}&q=${q}&sort=${sort}`,
		filter
	);

const getProductById = (productId) => client.get(endpoint + `/${productId}`);

const searchProductColors = (page, size, q = "") =>
	client.get(endpoint + `/color/search?page=${page}&size=${size}&q=${q}`);

const searchProductMaterials = (page, size, q = "") =>
	client.get(endpoint + `/material/search?page=${page}&size=${size}&q=${q}`);

const getProductStats = (requestedProfileId) =>
	client.get(endpoint + `/stats?requestedProfileId=${requestedProfileId}`);

const addProduct = (formData) => client.post(endpoint, formData);

const editProduct = (formData) => client.put(endpoint, formData);

const addProductMedia = (formData) =>
	client.post(endpoint + `/media`, formData);

const getProductMedia = (page, size, productId) =>
	client.get(
		endpoint + `/media?page=${page}&size=${size}&productId=${productId}`
	);

const deleteProductMedia = (productId, vaultItemId) =>
	client.delete(
		endpoint + `/media?productId=${productId}&vaultItemId=${vaultItemId}`
	);

const deleteProduct = (productId) =>
	client.delete(endpoint + `?productId=${productId}`);

const getSuggestedProducts = (page, size, productId, requestedProfileId) =>
	client.get(
		endpoint +
			`/suggested?page=${page}&size=${size}&productId=${productId}&profileId=${requestedProfileId}`
	);

const getProductNearby = (page, size, lat = 0, lng = 0) =>
	client.get(
		endpoint + `/nearby?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getProductsByServiceId,
	getSuggestedProducts,
	searchProductColors,
	searchProductMaterials,
	addProduct,
	editProduct,
	addProductMedia,
	getProductStats,
	getProductById,
	getProductMedia,
	deleteProductMedia,
	deleteProduct,
	getProductNearby,
};
