import client from "./client";

const endpoint = "/educationType";

const search = (q) => client.get(endpoint + `?name=${q}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    search,
};
