import { useEffect, useState } from "react";

const useWindowFocus = () => {
	const [isWindowFocus, setIsWindowFocus] = useState(false);

	const onFocus = () => {
		setIsWindowFocus(true);
	};

	const onBlur = () => {
		setIsWindowFocus(false);
	};

	useEffect(() => {
		window.addEventListener("focus", onFocus);
		window.addEventListener("blur", onBlur);
		// Calls onFocus when the window first loads
		onFocus();
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener("focus", onFocus);
			window.removeEventListener("blur", onBlur);
		};
	}, []);

	return isWindowFocus;
};

export default useWindowFocus;
