import Joi from "joi";

const genderSchema = Joi.object({
    gender: Joi.string().valid("male", "female", "custom").required(),
    customGender: Joi.when("gender", {
        is: Joi.string().valid("custom"),
        then: Joi.object({
            value: Joi.string(),
            label: Joi.string(),
        }).required(),
        otherwise: Joi.optional(),
    }),
    optionalGender: Joi.string().allow("").optional(),
});

export default genderSchema;
