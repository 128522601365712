import client from "./client";

const endpoint = "/tips";

const getTips = () => client.get(endpoint);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getTips,
};
