import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./CityModal.module.css";
import { useInfiniteQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "../../Utils/SubComs/NoResults/NoResults";
import useFetchLocation from "../../../hooks/useFetchLocation";
import { useInView } from "react-intersection-observer";

const itemsPerPage = 20;

const CityModal = ({ show, onClose, onSelectCity }) => {
	const fetchCities = useFetchLocation(true, itemsPerPage);

	const [selectedCityId, _] = useState(
		() => JSON.parse(localStorage.getItem("selectedCity")).id
	);

	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
		queryKey: ["cities", debouncedSearch],
		queryFn: fetchCities,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		staleTime: Infinity,
		enabled: show,
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	const citiesList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((city, i) => (
					<button
						key={city.id}
						ref={page.length === i + 1 ? viewRef : null}
						className={`${styles.city_item} ${
							selectedCityId === city.id
								? styles.active
								: undefined
						}`}
						onClick={() => {
							onSelectCity(city);
							onClose();
						}}
					>
						{`${city.name}, ${city.countryDTO.name}`}
					</button>
				))
			),
		[data]
	);

	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={"Location"}
			closeAlign={"right"}
			children={
				<div>
					<div className={styles.search_input_wrapper}>
						<SearchInput
							onChange={handleSearchInputChange}
							value={searchInput}
							showIcons={true}
							onClearClicked={() => setSearchInput("")}
							border={false}
							placeholder={"Search cities"}
						/>
					</div>

					<div className={styles.cities_container}>
						{citiesList}
						<NoResults
							visible={!isFetching && data?.pages[0].length === 0}
							text="No Results"
							noPadding={true}
						/>

						<LoadingSpinner
							visible={isFetching}
							customStyle={styles.loader}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default CityModal;
