import React, { useState, useEffect } from "react";
import styles from "./LocationModal.module.css";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import useApi from "../../hooks/useApi";
import placesApi from "../../api/googlePlaces";
import { List } from "react-content-loader";
import Tag from "../Chat/ParticipantItem";

const LocationModal = ({
	show,
	onClose,
	onLocationClicked,
	activeLocation,
	onRemove,
}) => {
	const getPlacesApi = useApi(placesApi.getPlaces);
	const [searchQuery, setSearchQuery] = useState("");
	const [searchLocation, setSearchLocation] = useState([]);

	const handleInputChange = (e) => {
		const query = e.target.value;
		setSearchQuery(query);
		if (query) {
			getPlacesApi.request(query);
		}
	};

	useEffect(() => {
		if (
			!getPlacesApi.loading &&
			getPlacesApi.responseCode === 200 &&
			getPlacesApi.data
		) {
			setSearchLocation(getPlacesApi.data);
		}
	}, [getPlacesApi.data]);

	const handleClose = () => {
		setSearchQuery("");
		setSearchLocation([]);
		onClose();
	};

	const handleSelectedLocation = (place) => {
		setSearchQuery("");
		setSearchLocation([]);
		onLocationClicked(place);
	};

	let locationList;

	if (getPlacesApi.loading) {
		locationList = <List />;
	} else {
		locationList =
			searchLocation.length > 0 &&
			searchLocation.map((place) => {
				return (
					<div
						className={styles.location_item}
						key={place.place_id}
						onClick={() => handleSelectedLocation(place)}
					>
						<img src={place.icon} alt={place.name} />
						<div>
							<label className={styles.name}>{place.name}</label>
							<label className={styles.location}>
								{place.formatted_address}
							</label>
						</div>
					</div>
				);
			});
	}

	return (
		<GaawkModal
			show={show}
			handleClose={handleClose}
			defaultModal={false}
			showHeader={true}
			title={"Search location"}
			closeAlign={"left"}
			children={
				<div className={styles.location_modal_container}>
					<div className={styles.search_input}>
						<TextInput
							placeholder={"Search..."}
							value={searchQuery}
							onChange={handleInputChange}
						/>
					</div>

					{activeLocation && (
						<div className={styles.active_location}>
							<Tag
								name={activeLocation.title}
								onRemove={onRemove}
							/>
						</div>
					)}

					<div className={styles.location_list_wrapper}>
						{locationList}
					</div>
				</div>
			}
		/>
	);
};

export default LocationModal;
