import styles from "./PrivacyPolicy.module.scss";
import TopBar from "components/Static/TopBar";
import LandingPageFooter from "components/Landing/LandingPageFooter";

const PrivacyPolicy = () => {
    return <div className={styles.container}>
        <TopBar/>
        <div className={styles.titleContainer}>
            <div className={styles.title}>
                <span className={styles.titleText1}>Privacy</span>
                <span className={styles.titleText2}>Policy</span>
            </div>
            <span className={styles.subTitle}>
				Our privacy policy outlines how we handle your personal information, ensuring its security and confidentiality. We are committed to safeguarding your privacy and providing transparency in our data practices.
			</span>
        </div>
        <div className={styles.contentWrapper}>
            <span className={styles.contentTitle}>Welcome to Gaawk!</span>
            <div className={styles.content}>
                <p>Last Updated: March 27, 2024</p>
                <p>At Gaawk, we are committed to protecting your privacy. This Privacy Policy explains how we collect,
                    use, disclose, and safeguard your information when you use our mobile application Gaawk (the
                    "Application"). Please read this Privacy Policy carefully. If you do not agree with the terms of
                    this Privacy Policy, please do not access the Application.</p>
                <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will
                    alert you about any changes by updating the "Last Updated" date of this Privacy Policy. You are
                    encouraged to periodically review this Privacy Policy to stay informed of updates. You will be
                    deemed to have been made aware of, will be subject to, and will be deemed to have accepted the
                    changes in any revised Privacy Policy by your continued use of the Application after the date such
                    revised Privacy Policy is posted.</p>
                <p>Collection of Your Information</p>
                <p>We may collect information about you in a variety of ways. The information we may collect via the
                    Application depends on the content and materials you use and how you interact with our services.</p>
                <p>Personal Data: We may collect personally identifiable information, such as your name, email address,
                    phone number, and any other information you provide to us.
                    Financial Data: We may collect financial information, such as payment card details, when you engage
                    in transactions with us.
                    Files and Data Storage: Our Application may include a vault feature for storage of files. Any files
                    or data you upload or store within the Application are subject to our privacy policy.
                    Usage Data: We may automatically collect information on how you interact with the Application, such
                    as the pages or features you access, and how much time you spend on each page.
                    Device Information: We may collect information about your mobile device, including the hardware
                    model, operating system, and unique device identifiers.</p>
                <p>Use of Your Information</p>
                <p>We may use information collected about you to:

                    Provide and maintain our Application;
                    To improve, personalize, and expand our Application;
                    To understand and analyze how you use our Application;
                    Develop new products, services, features, and functionality;
                    Communicate with you, either directly or through one of our partners;
                    To send you updates, newsletters, marketing materials, and other information that may be of interest
                    to you;
                    For compliance purposes, including enforcing our Terms of Service or other legal rights, or as may
                    be required by applicable laws and regulations.</p>
                <p>Disclosure of Your Information</p>
                <p>We may share your information with third parties only in the ways that are described in this Privacy
                    Policy. We may disclose your personal information to:

                    Service providers who need access to such information to carry out work on our behalf;
                    Business partners who help provide our services or with whom we partner to provide certain features;
                    Other users of the Application, to the extent you interact with them through the features of the
                    Application;
                    In response to a request for information if we believe disclosure is in accordance with, or required
                    by, any applicable law, regulation, or legal process;
                    If we believe your actions are inconsistent with our user agreements or policies, or to protect the
                    rights, property, and safety of ourselves and others.</p>
                <p>Security of Your Information</p>
                <p>We take reasonable measures to protect your information from unauthorized access or disclosure.
                    However, no method of transmission over the Internet or method of electronic storage is 100% secure.
                    Therefore, we cannot guarantee absolute security.</p>
                <p>Children’s Privacy</p>
                <p>Our Application does not address anyone under the age of 13. We do not knowingly collect personally
                    identifiable information from children under 13. If you are a parent or guardian and you are aware
                    that your child has provided us with personal information, please contact us. If we become aware
                    that we have collected personal information from children without verification of parental consent,
                    we take steps to remove that information from our servers.</p>
                <p>Contact Us</p>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at
                    hi@gaawk.com.</p>
                <p>By using the Application, you are consenting to the terms of this Privacy Policy.</p>
            </div>
        </div>
        <LandingPageFooter/>
    </div>;
};

export default PrivacyPolicy;
