export const productsKeys = {
	all: ["products"],

	lists: () => [...productsKeys.all, "listProducts"],

	searchList: () => [...productsKeys.lists(), "search"],
	suggestedList: (productId, profileId) => [
		...productsKeys.lists(),
		"suggested",
		productId,
		profileId,
	],
	search: (query, filters = {}) => [
		...productsKeys.searchList(),
		{ ...filters, q: query },
	],
	unCategorized: (userId) => [
		...productsKeys.lists(),
		"unCategorized",
		userId,
	],
	stats: (userId) => [...productsKeys.all, "stats", userId],

	details: () => [...productsKeys.all, "details"],
	detail: (productId) => [...productsKeys.details(), productId],
	productMedia: (productId) => [
		...productsKeys.details(),
		"Pmedia",
		productId,
	],
	nearby: () => [...productsKeys.lists(), "nearby"],
};
