import { useState, useRef, useEffect } from "react";
import styles from "./MessageBubble.module.css";

import { getMessageState } from "./Conversation";
import PostMedia from "../Home/PostMedia";

import { ReactComponent as DeleteIcon } from "images/deleteInMenu.svg";
import { ReactComponent as ForwardIcon } from "images/forward.svg";
import moreIcon from "images/chat-arrow-icon.svg";
import { copyToClipboard, getMapsLink } from "components/Utils/General";

import { isEmojisOnly } from "../Utils/General";
import ContactCard from "./ContactCard";
import Loader from "react-loader-spinner";
import StaticMap from "../Utils/StaticMap/StaticMap";
import ReplyWidget from "./ReplyWidget";
import Popover from "../Utils/Popover/Popover";
import MessageBubbleOptions from "./MessageBubbleOptions";
import { format } from "date-fns";
import FormatSpecialText from "../Utils/SubComs/FormatSpecialText/FormatSpecialText";
import ReadMore from "../Utils/SubComs/ReadMore/ReadMore";
import VoiceNoteItem from "./ChatComponents/VoiceNote/VoiceNoteItem";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import { updateReplyId } from "store/slices/chat";
import boxIcon from "images/box.svg";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";

const MessageBubble = ({
	type,
	item,
	isGroup,
	onShowInfo,
	onReply,
	// replyItem,
	onForward,
	onDelete,
	onShowSlider,
	isInfoModal,
	// isVoiceNote = false,
	isReply = false,
}) => {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const replyIdToFocus = useSelector((state) => state.chat.replyId);

	const {
		message,
		messageId,
		sendBy,
		messageTime,
		media,
		messageStatus: status,
		forwardedMsg,
		deleted,
		location,
		parent: replyItem,
		messageType,
		fullStorage,
	} = item;

	const msgRef = useRef(null);
	const popoverRef = useRef(null);

	if (replyItem && replyItem.parent) {
		delete replyItem.parent;
	}

	const messageMediaList = [];

	const isVoiceNote = messageType === "VOICE";

	media &&
		!isVoiceNote &&
		messageMediaList.push(
			//     {
			//     // media: media.url,
			//     // type: media.type.toLowerCase().includes("video")
			//     media: media.file.customName,
			//     // type: media.file.extension.toLowerCase().includes("video") ? "VIDEO" : "IMAGE",
			//     type: media.file.previewType === "IMAGE" ? "IMAGE" : media.file.previewType === "VIDEO" ? "VIDEO" : "OTHER",
			//     extension: media.file.extension,
			// }
			media.file
		);

	// * ================== Voice note handler ==================

	// const [isPlaying, setIsPlaying] = useState(false);

	// const handlePlayPause = () => {
	// 	setIsPlaying((prevState) => !prevState);
	// };

	// * ========================================================

	const [popoverOpened, setPopoverOpened] = useState(false);
	const [style, setStyle] = useState({ display: "none" });

	useEffect(() => {
		let timer;
		if (replyIdToFocus === messageId) {
			msgRef.current?.scrollIntoView({ behavior: "smooth" });

			timer = setTimeout(() => {
				dispatch(updateReplyId(""));
			}, 1300);
		}

		return () => clearTimeout(timer);
	}, [dispatch, messageId, replyIdToFocus]);

	return (
		<div
			ref={msgRef}
			onClick={() => isReply && dispatch(updateReplyId(messageId))}
			className={classNames(styles.card, {
				[styles.sender]: type === "SENDER",
				[styles.receiver]: type === "RECEIVER",
				[styles.isInfoModal]: isInfoModal,
				[styles.isReply]: isReply,
			})}
			onMouseEnter={() => {
				setStyle({ display: !isReply ? "block" : "none" });
			}}
			onMouseLeave={() => {
				!popoverOpened && setStyle({ display: "none" });
			}}
		>
			{!isInfoModal && (
				<Popover
					ref={popoverRef}
					onOpen={(bool) => {
						!bool && setStyle({ display: "none" });
						setPopoverOpened(bool);
					}}
					render={
						<MessageBubbleOptions
							type={type}
							deleted={deleted}
							copyEnabled={
								messageType === "TEXT" && message && !deleted
							}
							onCopy={() => {
								copyToClipboard(message);
							}}
							onReply={() => {
								onReply(item);
							}}
							onForward={() => {
								onForward(item);
							}}
							onMessageInfo={() => {
								onShowInfo({
									...item,
									isGroup,
									type,
								});
							}}
							onDelete={() => {
								onDelete(item);
							}}
							onClose={() => popoverRef.current.closePopover()}
						/>
					}
				>
					<button
						className={`${styles.option_icon} ${
							type === "SENDER" ? styles.align_left : undefined
						}`}
						style={style}
					>
						<img src={moreIcon} alt="options" />
					</button>
				</Popover>
			)}

			{((isGroup && type === "RECEIVER") ||
				(type === "SENDER" && isReply) ||
				(forwardedMsg && !isReply)) && (
				<div
					className={`${styles.bubble_header} ${
						(isGroup && type === "RECEIVER") ||
						(type === "SENDER" && isReply)
							? styles.with_name
							: undefined
					}`}
				>
					{isGroup && type === "RECEIVER" && (
						<div className={styles.participant_name}>
							{sendBy &&
							sendBy.uuid === localStorage.getItem("uuid")
								? "You"
								: sendBy.name}
						</div>
					)}

					{type === "SENDER" && isReply && (
						<div className={styles.participant_name}>
							{sendBy &&
							sendBy.uuid === localStorage.getItem("uuid")
								? "You"
								: sendBy.name}
						</div>
					)}
					{forwardedMsg && !isReply && (
						<div className={styles.forwarded}>
							{/* <img src={forwardIconGray} alt="Forward" /> */}
							<ForwardIcon className={styles.forwardIcon} />
							Forwarded
						</div>
					)}
				</div>
			)}

			{/* {!isGroup && type === "SENDER" && isReply && (
                <div className={styles.participant_name}>
                    {sendBy && sendBy.uuid === localStorage.getItem("uuid")
                        ? "You"
                        : sendBy.name}
                </div>
            )} */}

			{/* //* ADDING REPLY BUBBLE  */}
			{replyItem && (
				<MessageBubble
					item={replyItem}
					isGroup={isGroup}
					type={type}
					isReply={true}
				/>
			)}

			{media === "LOADING" && (
				<div className={styles.loading_container}>
					<Loader
						type="Oval"
						color="#6cc5d1"
						height={50}
						width={50}
					/>
				</div>
			)}

			{media &&
				!isVoiceNote &&
				media.file &&
				!deleted &&
				!isReply &&
				!fullStorage && (
					<div className={styles.media_container}>
						<PostMedia
							mediaList={messageMediaList}
							isReply={isReply}
							chat={true}
							viewer={true}
							onItemClicked={onShowSlider}
						/>
					</div>
				)}

			{media && fullStorage && !isReply && !deleted && (
				<div
					className={styles.warning_storage}
					onClick={() => navigate(routes.storage)}
				>
					{/* <PostMedia
							mediaList={messageMediaList}
							isReply={isReply}
							chat={true}
							viewer={true}
							onItemClicked={onShowSlider}
						/> */}
					<img src={boxIcon} alt="" />
					<p>
						Your storage is full! Upgrade your package or clear your
						data in vault to keep receiving new files.
					</p>
				</div>
			)}

			{location && !deleted && !isReply && (
				<div
					className={styles.map_container}
					onClick={() => window.open(getMapsLink(location), "_blank")}
				>
					<StaticMap
						lat={location.latitude}
						lng={location.longitude}
						title={location.title}
					/>
					<p className={styles.location_title}>{location.title}</p>
				</div>
			)}

			{!deleted && isReply && <ReplyWidget item={item} />}

			<div className={styles.message_text_container}>
				{deleted ? (
					<span className={styles.deleted_msg}>
						<DeleteIcon />
						This message has been deleted
					</span>
				) : isVoiceNote && !isReply ? (
					<VoiceNoteItem item={item} isReply={isReply} />
				) : (
					<>
						{item.attachedProfile && !isReply ? (
							item.attachedProfile === "LOADING" ? (
								<div className={styles.loading_container}>
									<Loader
										type="Oval"
										color="#6cc5d1"
										height={50}
										width={50}
									/>
								</div>
							) : (
								<ContactCard profile={item.attachedProfile} />
							)
						) : (
							""
						)}

						<ReadMore>
							<FormatSpecialText
								text={message}
								customStyle={`${styles.message_text} ${
									isEmojisOnly(message)
										? styles.emoji_only
										: undefined
								}`}
							/>
						</ReadMore>
					</>
				)}

				{/* //* UI FIX WHEN ADDED TO A GROUP CONVO AND MEDIA MESSAGES WERE SENT BEFORE YOU JOIN */}
				{messageType === "MEDIA" && !media && (
					<i>message not available</i>
				)}

				{!isReply && (
					<div className={styles.bottom_info}>
						{type === "SENDER" && !deleted && (
							<img src={getMessageState(status)} alt="status" />
						)}

						<label>
							{messageTime &&
								format(new Date(messageTime), "h:mm aaa")}
						</label>
					</div>
				)}
			</div>
		</div>
	);
};

export default MessageBubble;
