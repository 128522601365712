import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import styles from "./SuggestBoardModal.module.scss";
import { ReactComponent as BoardIcon } from "images/board.svg";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import useMutate from "hooks/useMutate";
import boardsApi from "api/boards";
import { suggestedBoardSchema } from "./board-schema";

const SuggestBoardModal = ({ show, onClose }) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({
		resolver: joiResolver(suggestedBoardSchema),
		mode: "all",
	});

	const handleClose = () => {
		reset();
		onClose();
	};

	const {
		action: { mutate: suggestBoard, isLoading },
	} = useMutate(boardsApi.suggestBoard, handleClose);

	const handleSave = (data) => {
		const { boardName, description } = data;
		suggestBoard({ boardName, description });
	};

	return (
		<GaawkModal
			show={show}
			handleClose={handleClose}
			defaultModal={false}
			showHeader={true}
			title={"Suggest a board"}
			closeAlign={"right"}
			children={
				<div className={styles.container}>
					<div className={styles.info}>
						<BoardIcon fill="#000" className={styles.board_icon} />
						<p>
							Didn't find the board you were looking for? No
							problem! Please fill the fields below and we'll
							evaluate your suggestion and get back to you.
						</p>
					</div>
					<form
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<InputWrapper
							label="Board Name"
							required={true}
							error={errors?.boardName}
							component={
								<TextInput
									{...register("boardName")}
									error={errors.boardName}
									placeholder="Enter Board Name"
								/>
							}
						/>

						<InputWrapper
							label="Description"
							required={true}
							error={errors?.description}
							component={
								<div
									className={`${styles.textarea_wrapper} ${
										!!errors.description
											? styles.error
											: undefined
									}`}
								>
									<textarea
										{...register("description")}
										placeholder="Enter board description"
									/>
								</div>
							}
						/>

						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								text={"save"}
								isLoading={isLoading}
							/>
						</div>
					</form>
				</div>
			}
		/>
	);
};

export default SuggestBoardModal;
