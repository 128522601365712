import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
    name: "JWTToken",
    initialState: {
        token: "",
    },
    reducers: {
        "JWTUpdated": (state, action) => {
            state.token = action.payload.jwttoken;
        },
    }
});

export const {JWTUpdated} = slice.actions;
export default slice.reducer;