import { useInfiniteQuery } from "@tanstack/react-query";
import useApi from "./useApi";
import profileApi from "api/profile";
import { profileKeys } from "queryKeys/profile-key-factory";

export default function useProfileSearch(query, enabled, itemsPerPage = 20) {
	const searchProfileApi = useApi(profileApi.search, true, true);

	const fetchSearch = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, searchQuery] = queryKey;

		const response = await searchProfileApi.request(
			pageParam,
			itemsPerPage,
			searchQuery
		);
		return response.data;
	};

	const infiniteQuery = useInfiniteQuery({
		queryKey: profileKeys.search(query),
		queryFn: fetchSearch,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		enabled,
	});

	return infiniteQuery;
}
