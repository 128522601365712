import React from "react";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import styles from "./NoData.module.css";

const NoData = ({ text, onClick }) => {
	return (
		<div className={styles.no_data}>
			<p>{text}</p>
			<PrimaryButton
				className={styles.message_btn}
				text={"send a message"}
				onClick={onClick}
			/>
		</div>
	);
};

export default NoData;
