import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../../Utils/SubComs/CustomSelect/CustomSelect";

import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";

import { addSocial, updateSocial } from "../../../../../store/slices/user";
import {
	addCompanySocial,
	updateCompanySocial,
} from "../../../../../store/slices/company";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import styles from "./Social.module.css";
import PrimaryButton from "../../../../Utils/Button/PrimaryButton";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import useApi from "../../../../../hooks/useApi";
import socialApi from "../../../../../api/social";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import PromptModal from "components/Utils/Prompt/PromptModal";

const SocialEntry = ({ editCompany }) => {
	const navigate = useNavigate();

	const getSocialMediaApi = useApi(socialApi.getSocialMedia);
	const dispatch = useDispatch();
	const { pathname, state } = useLocation();

	const editMode = pathname === `/profile/social/edit`;

	const companyId = useSelector((state) => state.company.companyInfo.uuid);

	const {
		uuid: profileSocialId,
		link,
		socialDTO,
		visibility: currentVisibility,
	} = state || {};

	const schema = Joi.object({
		platform: Joi.object({
			label: Joi.string(),
			value: Joi.string(),
		})
			.required()
			.messages({
				"any.required": "Platform can't be empty",
				"object.base": "Platform can't be empty",
			})
			.label("Platform"),

		link: Joi.string()
			.required()
			.messages({ "string.empty": "Link can't be empty" })
			.label("Link"),
	});

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					platform: {
						label: socialDTO?.name,
						value: socialDTO?.uuid,
					},
					link,
			  }
			: {
					platform: null,
					link: "",
			  },
	});

	const [socialList, setSocialList] = useState([]);

	useEffect(() => {
		getSocialMediaApi.request();
	}, []);

	useEffect(() => {
		if (
			!getSocialMediaApi.loading &&
			getSocialMediaApi.responseCode === 200 &&
			getSocialMediaApi.data.length > 0
		) {
			const response = getSocialMediaApi.data;
			setSocialList(
				response.map((social) => ({
					label: social.name,
					value: social.uuid,
				}))
			);
		}
	}, [getSocialMediaApi.data]);

	const [visibility, setVisibility] = useState(
		currentVisibility ?? "WORK_CIRCLE_FRIENDS"
	);

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const handleSave = (data) => {
		const {
			link,
			platform: { value: socialId },
		} = data;

		const body = {
			...(editMode && { profileSocialId }),
			socialId,
			link,
			visibility,
		};

		if (editCompany) {
			dispatch(
				editMode
					? updateCompanySocial(body, companyId)
					: addCompanySocial(body, companyId)
			);
		} else {
			dispatch(editMode ? updateSocial(body) : addSocial(body));
		}
		setShouldBlockSave(false);
	};

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	useEffect(() => {
		if (!shouldBlockSave) navigate("/profile/social");
	}, [navigate, shouldBlockSave]);

	if (editMode && !state) {
		return <Navigate to={"/profile/social"} />;
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.contact_section}>
					<form
						className={styles.add_form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<InputWrapper
							label="Platform"
							required={true}
							error={errors?.platform}
							className={styles.no_margin}
							component={
								<CustomSelect
									fontSize="14px"
									height="35px"
									options={socialList}
									// onChange={handleSocial}
									error={!!errors.platform}
									control={control}
									name="platform"
								/>
							}
						/>

						{/* <div className={styles.react_select_wrapper}>
								<label>
									Platform
									<span className="required">*</span>
								</label>

								<CustomSelect
									fontSize="14px"
									height="35px"
									options={socialList}
									// onChange={handleSocial}
									error={!!errors.platform}
									control={control}
									name="platform"
								/>
								{errors?.platform?.message && (
									<p className={styles.error_message}>
										{errors?.platform?.message}
									</p>
								)}
							</div> */}

						<InputWrapper
							label="Link"
							required={true}
							error={errors?.link}
							component={
								<TextInput
									{...register("link")}
									error={errors.link}
									placeholder="Enter Link"
								/>
							}
						/>

						<div className={styles.visibility_wrapper}>
							<label className={styles.visibility_label}>
								Visibility
								<span className="required">*</span>
							</label>

							<VisibilitySelector
								value={visibility}
								onValueChange={handleVisibilityChange}
							/>
						</div>

						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								text={"save"}
							/>
						</div>
					</form>
				</div>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default SocialEntry;
