import { useRef, useState } from "react";
import styles from "./FileInput.module.scss";
import { Controller } from "react-hook-form";
import CropModal from "../../../CropModal/CropModal";
import ThumbnailContainer from "../../ThumbnailContainer/ThumbnailContainer";

const FileInput = ({
	name,
	control,
	onChange,
	loadedFile,
	hasCrop = true,
	cropAspect = 1,
	onCrop,
	error,
	title,
	required = false,
	acceptedFiles = "image/png, image/jpg, image/jpeg",
	onDelete,
	disabled = false,
	infoText = "",
	fullWidthModal = false,
}) => {
	const inputRef = useRef(null);

	const [showCropModal, setShowCropModal] = useState(false);

	return (
		<div
			className={`${styles.img_option} ${
				title ? styles.has_title : undefined
			}`}
		>
			{title && (
				<label>
					{title}
					{required && <span className="required">*</span>}
				</label>
			)}
			{infoText && <i>{infoText}</i>}

			{loadedFile?.length > 0 && (
				<ThumbnailContainer
					items={loadedFile}
					onDelete={onDelete}
					className={styles.thumb_container}
				/>
			)}

			<button
				className={disabled ? styles.disabled : undefined}
				type="button"
				onClick={() => {
					inputRef.current.click();
				}}
			>
				<Controller
					name={name}
					control={control}
					render={() => (
						<input
							onClick={(e) => e.stopPropagation()}
							ref={inputRef}
							type="file"
							accept={acceptedFiles}
							onChange={(val) => {
								onChange(val);
								setShowCropModal(true);
								val.target.value = "";
							}}
							tabIndex="-1"
							disabled={disabled}
						/>
					)}
				/>
				UPLOAD IMAGE
			</button>

			{error?.message && (
				<p className={styles.error_message}>{error?.message}</p>
			)}

			{loadedFile?.length > 0 && hasCrop && (
				<CropModal
					canEscape={false}
					image={
						loadedFile[loadedFile.length - 1] instanceof File
							? URL.createObjectURL(
									loadedFile[loadedFile.length - 1]
							  )
							: loadedFile[loadedFile.length - 1]
					}
					show={showCropModal}
					aspectMode={cropAspect}
					fullWidth={fullWidthModal}
					onClose={() => setShowCropModal(false)}
					onCrop={(cropped) => {
						cropped.fileName =
							loadedFile[loadedFile.length - 1].name;
						onCrop(cropped);
						setShowCropModal(false);
					}}
					returnBlob={true}
				/>
			)}
		</div>
	);
};

export default FileInput;
