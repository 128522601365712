import styles from "./ConnectionFilter.module.scss";
import Switch from "react-switch";

const ConnectionFilter = ({
	friends,
	friendLabel = "In my Friend list",
	inMyCircle,
	myCircleLabel = "In my Work Circle",
	inTheirCircle,
	theirCircleLabel = "Added me to their Work Circle",
	onFriendChange,
	onMyWorkChange,
	onTheirWorkChange,
}) => {
	return (
		<div className={styles.container}>
			<h4>Connections</h4>

			{onFriendChange && (
				<div className={styles.switch_wrapper}>
					<Switch
						checked={friends}
						onColor="#6cc5d1"
						offColor="#a6b1bc"
						handleDiameter={18}
						width={39}
						height={24}
						checkedIcon={false}
						uncheckedIcon={false}
						activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
						onChange={onFriendChange}
					/>
					<span>
						{/* <img src={friendBlackIcon} alt="" /> */}
						{friendLabel}
					</span>
				</div>
			)}
			{onMyWorkChange && (
				<div className={styles.switch_wrapper}>
					<Switch
						checked={inMyCircle}
						onColor="#6cc5d1"
						offColor="#a6b1bc"
						handleDiameter={18}
						width={39}
						height={24}
						checkedIcon={false}
						uncheckedIcon={false}
						activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
						onChange={onMyWorkChange}
					/>
					<span>
						{/* <img src={workBlackIcon} alt="" /> */}
						{myCircleLabel}
					</span>
				</div>
			)}
			{onTheirWorkChange && (
				<div className={styles.switch_wrapper}>
					<Switch
						checked={inTheirCircle}
						onColor="#6cc5d1"
						offColor="#a6b1bc"
						handleDiameter={18}
						width={39}
						height={24}
						checkedIcon={false}
						uncheckedIcon={false}
						activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
						onChange={onTheirWorkChange}
					/>
					<span>
						{/* <img src={workBlackIcon} alt="" /> */}
						{theirCircleLabel}
					</span>
				</div>
			)}
		</div>
	);
};

export default ConnectionFilter;
