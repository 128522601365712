import React, { useState, useEffect } from "react";
import styles from "./Tabs.module.css";
import useApi from "../../../../hooks/useApi";
import useConnection from "../hooks/useConnection";
import connectionApi from "../../../../api/connection";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupMember from "../../../Chat/GroupMember";
// import ConnectionModal from "../../ProfileHeader/ConnectionModal";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";

const Sent = ({ query, count, onUpdateCounter }) => {
	const getSentApi = useApi(connectionApi.getSent);
	// const revokeApi = useApi(connectionApi.revokeFriendRequest);

	const {
		data: sent,
		fetchData,
		// deleteItem,
	} = useConnection(getSentApi, query);

	// const [showConnectionModal, setShowConnectionModal] = useState(false);
	// const [profile, setProfile] = useState("");
	// const [modalAction, setModalAction] = useState("");

	// const handleRevoke = (profile) => {
	//     setProfile(profile);
	//     setShowConnectionModal(true);
	//     setModalAction("REVOKE_FRIEND");
	// };

	// const handleModalConfirm = () => {
	//     revokeApi.request(profile.uuid);
	//     setShowConnectionModal(false);
	// };

	const sentList = sent.map((item) => (
		// <div className={styles.contact_item} key={item.uuid}>
		<GroupMember
			key={item.uuid}
			data={item}
			redirect={true}
			// rightSideAction={
			//     <span
			//         className={styles.rightaction_text}
			//         onClick={() => handleRevoke(item)}
			//     >
			//         Revoke
			//     </span>
			// }
		/>
		// </div>
	));

	// useEffect(() => {
	//     if (!revokeApi.loading && revokeApi.responseCode === 200) {
	//         deleteItem(profile.uuid);
	//         onUpdateCounter("friendPendingSent");
	//     }
	// }, [revokeApi.loading, revokeApi.responseCode]);

	return (
		<>
			<div id="scrollDiv" className={styles.container}>
				<InfiniteScroll
					dataLength={sent.length}
					next={fetchData}
					hasMore={!query && sent.length > 0 && sent.length < count}
					loader={<ContactLoader />}
					scrollableTarget="scrollDiv"
				>
					{getSentApi.loading && <ContactLoader />}
					{sentList.length > 0 && !getSentApi.loading && sentList}
				</InfiniteScroll>
			</div>

			{/* <ConnectionModal
                show={showConnectionModal}
                name={profile.name}
                profileImage={
                    profile.profileImage
                        ? profile.profileImage.image
                        : undefined
                }
                action={modalAction}
                onConfirm={handleModalConfirm}
                onClose={() => setShowConnectionModal(false)}
            /> */}
		</>
	);
};

export default Sent;
