import React from "react";
import DatePicker from "react-date-picker";
import "./DayPicker.css";
import "./Calendar.css";
import { Controller } from "react-hook-form";

const DayPicker = ({
	disabled = false,
	onChange,
	value,
	className,
	control,
	name,
	minDate = null,
	maxDate = null,
	isClearable = null,
}) => {
	return control ? (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field: { ref, ...field } }) => (
					<DatePicker
						inputRef={ref}
						showLeadingZeros={true}
						disabled={disabled}
						maxDate={maxDate}
						minDate={minDate}
						locale="en"
						format="dd/MM/yyyy"
						className={className}
						calendarIcon={null}
						clearIcon={isClearable ? undefined : null}
						{...field}
					/>
				)}
			/>
		</>
	) : (
		<div>
			<DatePicker
				showLeadingZeros={true}
				disabled={disabled}
				maxDate={maxDate}
				minDate={minDate}
				locale="en"
				format="dd/MM/yyyy"
				onChange={onChange}
				value={value}
				className={className}
				calendarIcon={null}
				clearIcon={isClearable ? undefined : null}
			/>
		</div>
	);
};

export default DayPicker;
