import React, { useMemo } from "react";

import { getReactionImg } from "../General";
import styles from "./Reactions.module.css";
import { pluralize } from "../General";

const ReactionsCounter = ({
	reactionStats,
	commentsCount,
	sharedCount,
	onShowComment,
	onShowReactions,
	counterOnly = false,
	onClick,
}) => {
	// console.log("THE REACTIONS STATS >>", reactionStats);
	const { stats, totalCount } = reactionStats;

	const reactionOptions = useMemo(
		() =>
			stats.map((item) => (
				<img
					key={item.reaction}
					className={styles.emoji_icon}
					src={getReactionImg(item.reaction)}
					alt={item.reaction}
					onClick={() => {
						onClick(item.reaction);
						onShowReactions();
					}}
				/>
			)),
		[reactionStats]
	);

	// return <ReactionCounter className={styles.reaction_counter_container} reactions={reactionOptions} />;
	return (
		<div className={styles.reaction_counter_wrapper}>
			<div className={styles.emoji_container}>{reactionOptions}</div>
			<div className={styles.reaction_counter}>
				{totalCount > 0 && (
					<span onClick={onShowReactions}>
						{totalCount}{" "}
						{!counterOnly && pluralize(totalCount, "Reaction")}
					</span>
				)}
				{commentsCount > 0 && (
					<span onClick={onShowComment}>
						{commentsCount} {pluralize(commentsCount, "Comment")}
					</span>
				)}
				{sharedCount > 0 && (
					<span className={styles.share_counter}>
						{sharedCount} {pluralize(sharedCount, "Repost")}
					</span>
				)}
			</div>
		</div>
	);
};

export default ReactionsCounter;
