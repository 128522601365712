import React, { useEffect, useRef, useState } from "react";
import styles from "./LinkToAgencyModal.module.css";
import GaawkModal from "../../../Utils/GaawkModal/GaawkModal";
import ContactLoader from "../../../Utils/SubComs/CustomLoader/ContactLoader";
import ContactSearch from "../../../Chat/ContactSearch";
import useApi from "../../../../hooks/useApi";
import profileApi from "../../../../api/profile";
import InfiniteScroll from "react-infinite-scroll-component";

import { toast } from "react-toastify";
import CompanyItem from "../../../Companies/CompanyItem";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";

const itemsPerPage = 10;

// TODO IMPROVEMENT >> usePaginatedData custom hook

const LinkToAgencyModal = ({
	show,
	onClose,
	specialProfileId,
	onSuccess,
	onError,
}) => {
	const searchAgenciesApi = useApi(profileApi.searchCompany, true);
	const agencyRequestApi = useApi(profileApi.sendAgencyRequest, true);
	const [searchInput, setSearchInput] = useState("");
	const [agencies, setAgencies] = useState([]);

	const didMountRef = useRef(false);

	const [confirmModal, setConfirmModal] = useState(false);
	const [selectedAgency, setSelectedAgency] = useState();

	const [page, setPage] = useState(0);

	const handleSearchInputChange = (e) => {
		const query = e.target.value;
		setSearchInput(query);
	};

	const loadMore = async (search = searchInput, pageNumber = page) => {
		const response = await searchAgenciesApi.request(
			search,
			pageNumber,
			itemsPerPage,
			true
		);

		if (response.status === 200) {
			setAgencies((prevState) => [...prevState, ...response.data]);
			setPage(pageNumber + 1);
		} else {
			toast.error("Something Wrong Happened");
		}
	};

	const handleSendRequest = async () => {
		const response = await agencyRequestApi.request(
			selectedAgency.uuid,
			specialProfileId
		);

		if (response.status === 200) {
			onSuccess();
		} else {
			onError(response.data.message);
		}

		onClose();
	};

	// ================= EFFECTS ===================

	useEffect(() => {
		if (didMountRef.current) {
			let timer;
			if (searchInput) {
				timer = setTimeout(() => {
					setAgencies([]);
					loadMore(searchInput, 0);
				}, 500);
			} else {
				setAgencies([]);
				loadMore("", 0);
			}

			return () => {
				clearTimeout(timer);
			};
		}
		didMountRef.current = true;
	}, [searchInput]);

	useEffect(() => {
		loadMore();
	}, []);

	useEffect(() => {
		if (
			!searchAgenciesApi.loading &&
			searchAgenciesApi.responseCode === 200 &&
			searchAgenciesApi.data
		) {
			const data = searchAgenciesApi.data;
			setAgencies((prevState) => [...prevState, ...data]);
			setPage((prevState) => prevState + 1);
		}
	}, [searchAgenciesApi.data]);

	// ====================================================

	const LoadingView = () => (
		<div className={styles.bulletList_container}>
			<ContactLoader />
			<ContactLoader />
			<ContactLoader />
		</div>
	);
	const EmptyView = () => (
		<div className={styles.info_text}>No agencies found</div>
	);

	return (
		<>
			<GaawkModal
				show={show && !confirmModal}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={"Link to Agency"}
				closeAlign={"right"}
				children={
					<>
						<div className={styles.search_wrapper}>
							<ContactSearch
								searchInput={searchInput}
								onSearchInputChange={handleSearchInputChange}
								onClear={() => setSearchInput("")}
							/>
						</div>

						<div className={styles.container}>
							<div
								className={styles.agencies_container}
								id="scrollable"
							>
								<InfiniteScroll
									scrollableTarget="scrollable"
									dataLength={agencies.length}
									next={loadMore}
									hasMore={
										agencies.length > 0 &&
										agencies.length % itemsPerPage === 0
									}
									loader={
										searchAgenciesApi.loading && (
											<LoadingView />
										)
									}
								>
									{!searchAgenciesApi.loading &&
										agencies.length === 0 && <EmptyView />}
									{searchAgenciesApi.loading &&
										agencies.length === 0 && (
											<LoadingView />
										)}

									{agencies.length > 0 &&
										agencies.map((agency) => (
											<CompanyItem
												key={agency.uuid}
												item={agency}
												subdiv={
													<>
														<div
															className={
																styles.agency_tagLine
															}
														>
															{agency.tagLine}
														</div>
														<div
															className={
																styles.agency_location
															}
														>
															{`${agency.locationDTO.name}, ${agency.locationDTO.countryDTO.name}`}
														</div>
													</>
												}
												customStyle={styles.agency_item}
												onClick={() => {
													setSelectedAgency(agency);
													setConfirmModal(true);
												}}
											/>
										))}
								</InfiniteScroll>
							</div>
						</div>
					</>
				}
			/>
			<GaawkModal
				show={confirmModal}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={"Send Request"}
				closeAlign={"right"}
				children={
					<div className={styles.container}>
						<div className={styles.info_text}>
							Are you sure you want to send request to{" "}
							<b>{selectedAgency?.name}</b> for listing you on
							their talent profile? You will not be listed until
							they confirm.
						</div>

						<div className={styles.btn_container}>
							<PrimaryButton
								text="send"
								type="button"
								className={styles.btn}
								onClick={handleSendRequest}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default LinkToAgencyModal;
