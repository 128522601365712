import styles from "./Social.module.css";
import { getVisibility, getClickableLink } from "../../../../Utils/General";
import EditButton from "components/Utils/Button/EditButton";
import DeleteButton from "components/Utils/Button/DeleteButton";

const SocialItem = ({ item, onEditClicked, onDelete, showControls = true }) => {
	return (
		<div className={styles.item_container}>
			<div className={styles.description}>
				{item.socialDTO.icon ? (
					<span className={styles.item_icon}>
						<img
							src={item.socialDTO.icon}
							alt={item.socialDTO.name}
						/>
					</span>
				) : (
					<span className={`${styles.item_icon} ${styles.empty}`} />
				)}

				<div style={{ overflow: "hidden" }}>
					<div className={styles.site_header}>
						<p>{item.socialDTO.name}</p>
						<div className={styles.visibility}>
							{getVisibility(item.visibility)}
						</div>
					</div>
					<div className={styles.site_wrapper}>
						<a
							href={getClickableLink(item.link)}
							target="_blank"
							rel="noreferrer"
						>
							{item.link}
						</a>
					</div>
				</div>
			</div>

			{showControls && (
				<div className={styles.controls}>
					<EditButton onClick={onEditClicked} />
					<DeleteButton onClick={onDelete} />
				</div>
			)}
		</div>
	);
};

export default SocialItem;
