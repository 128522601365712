import styles from "./CropModal.module.css";

import GaawkModal from "../GaawkModal/GaawkModal";
import CropImage from "../SubComs/CropImage/CropImage";

const CropModal = ({
	show,
	onClose,
	image,
	onCrop,
	// mode = "default",
	aspectMode,
	isCircle,
	returnBlob,
	canEscape = true,
	fullWidth = false,
}) => {
	return (
		<>
			<GaawkModal
				show={show}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={"Crop image"}
				closeAlign={canEscape ? "left" : "none"}
				escKeyboard={canEscape}
				modalOnTop={true}
				fullWidth={fullWidth}
				children={
					<div className={styles.container}>
						<div className={styles.crop_container}>
							<CropImage
								src={image}
								// mode={mode}
								aspectMode={aspectMode}
								isCircle={isCircle}
								onCrop={onCrop}
								returnBlob={returnBlob}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default CropModal;
