import "../Utils/Popover/Popover.css";

import copyIcon from "images/copy-icon.svg";
import forwardIcon from "images/forward-icon.svg";
import replyIcon from "images/reply-icon.svg";
import binIcon from "images/bin-icon.svg";
import infoIcon from "images/info-icon.svg";

const MessageBubbleOptions = ({
	deleted,
	isInfoModal,
	type,
	onReply,
	onForward,
	onMessageInfo,
	onDelete,
	onCopy,
	onClose,
	copyEnabled,
}) => {
	return (
		<div className="popover_container">
			{!deleted && (
				<>
					<button
						className="popover_item"
						onClick={() => {
							onReply();
							onClose && onClose();
						}}
					>
						<img src={replyIcon} alt="reply" />
						<span>Reply</span>
					</button>

					<button
						className="popover_item"
						onClick={() => {
							onForward();
							onClose && onClose();
						}}
					>
						<img src={forwardIcon} alt="forward" />
						<span>Forward</span>
					</button>

					{/* <button
                                    className="popover_item"
                                    onClick={() => alert("ASSIGN AS A TASK")}
                                >
                                    <img src={taskIcon} alt="assign as task" />
                                    <span className="gaawk-color">Assign as task</span>
                                </button> */}

					{!isInfoModal && type === "SENDER" && (
						<button
							className="popover_item"
							onClick={() => {
								onMessageInfo();
								onClose && onClose();
							}}
						>
							<img src={infoIcon} alt="Message info" />
							<span>Message info</span>
						</button>
					)}

					{copyEnabled && (
						<button
							className="popover_item"
							onClick={() => {
								onCopy();
								onClose && onClose();
							}}
						>
							<img src={copyIcon} alt="copy" />
							<span>Copy</span>
						</button>
					)}
				</>
			)}

			<button
				className="popover_item"
				onClick={() => {
					onDelete();
					onClose && onClose();
				}}
			>
				<img src={binIcon} alt="delete" />
				<span>Delete</span>
			</button>
		</div>
	);
};

export default MessageBubbleOptions;
