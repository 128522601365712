import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import styles from "./TempModal.module.scss";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import CustomRadioButton from "components/Utils/SubComs/CustomRadioButton/CustomRadioButton";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import scheduleIcon from "images/schedule-icon-25-x-25.svg";
import { useState } from "react";
import { monthDropdown } from "components/Utils/General";

const TempModal = ({ show, onClose, imagePreview, onSelect }) => {
	const currentDate = new Date();

	const [tempChecked, setTempChecked] = useState("");
	const [selectedMonth, setSelectedMonth] = useState(0);
	const [selectedDay, setSelectedDay] = useState(0);
	const [daysArray, setDaysArray] = useState([]);
	const [tempDate, setTempDate] = useState(0);

	const handleDay = () => {
		setTempChecked("day");
		const dateCopy = new Date(currentDate.valueOf());
		const nextDay = dateCopy.getDate() + 1;
		const adjustedDate = dateCopy.setDate(nextDay);
		setTempDate(adjustedDate);
	};
	const handleWeek = () => {
		setTempChecked("week");
		const dateCopy = new Date(currentDate.valueOf());
		const nextWeek = dateCopy.getDate() + 7;
		const adjustedDate = dateCopy.setDate(nextWeek);
		setTempDate(adjustedDate);
	};
	const handleCustom = () => {
		setTempChecked("custom");
	};

	const handleSelectedMonth = (e) => {
		setSelectedMonth(e.value);
		const year = currentDate.getFullYear();
		const daysInMonth = new Date(year, e.value, 0).getDate();

		const numberOfDays = [];
		for (let i = 1; i <= daysInMonth; i++) {
			numberOfDays.push({
				value: i,
				label: i.toString(),
			});
		}
		setDaysArray(numberOfDays);
	};

	const handleSelectedDay = (e) => {
		setSelectedDay(e.value);
	};

	const handleSave = () => {
		if (tempChecked === "custom") {
			if (
				selectedMonth < currentDate.getMonth() + 1 ||
				(selectedMonth === currentDate.getMonth() + 1 &&
					selectedDay < currentDate.getDate())
			) {
				//THIS IS NEXT YEAR
				const adjustedDate = new Date(
					currentDate.getFullYear() + 1,
					selectedMonth - 1,
					selectedDay
				).getTime();
				// dispatch(selectedTempTime(adjustedDate));
				onSelect(adjustedDate);
			} else {
				// THIS IS THE CURRENT YEAR
				const adjustedDate = new Date(
					currentDate.getFullYear(),
					selectedMonth - 1,
					selectedDay
				).getTime();
				// dispatch(selectedTempTime(adjustedDate));
				onSelect(adjustedDate);
			}
		} else {
			// dispatch(selectedTempTime(tempDate));
			onSelect(tempDate);
		}
		// history.goBack();
		// setShouldBlockSave(false);
	};

	return (
		<GaawkModal
			show={show}
			title={"Make temporary"}
			closeAlign={"right"}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			children={
				<div className={styles.container}>
					{/* <div className={styles.img_canvas}>
					{imgToDisplay && <img src={imgToDisplay} alt="profile" />}
				</div> */}

					<LazyImage
						image={<img src={imagePreview} alt={"profile"} />}
						inlineStyle={styles.img_canvas}
					/>

					{/* <div className={styles.until_section}>
						<label>Make temporary until...</label>
					</div> */}

					<div className={styles.temp_options_section}>
						<div className={styles.temp_option} onClick={handleDay}>
							<div>
								<img src={scheduleIcon} alt="schedule" />
								<label>1 day</label>
							</div>

							<CustomRadioButton
								id="h1"
								name="temp"
								checked={tempChecked === "day"}
								onChange={handleDay}
							/>
						</div>

						<div
							className={styles.temp_option}
							onClick={handleWeek}
						>
							<div>
								<img src={scheduleIcon} alt="schedule" />
								<label>1 week</label>
							</div>

							<CustomRadioButton
								id="w1"
								name="temp"
								checked={tempChecked === "week"}
								onChange={handleWeek}
							/>
						</div>

						<div
							className={styles.temp_option}
							onClick={handleCustom}
						>
							<div>
								<img src={scheduleIcon} alt="schedule" />
								<label>Custom</label>
							</div>

							<CustomRadioButton
								id="custom"
								name="temp"
								checked={tempChecked === "custom"}
								onChange={handleCustom}
							/>
						</div>
					</div>

					{tempChecked === "custom" && (
						<div className={styles.custom_date_section}>
							<div className={styles.custom_date_wrapper}>
								{/* //TODO redo using Joi for validation */}
								<label>
									Select a date{" "}
									<span className="required-star">*</span>
								</label>

								<div className={styles.select_section}>
									<div>
										<CustomSelect
											id="month"
											options={monthDropdown}
											placeholder="Select Month"
											width="140px"
											onChange={handleSelectedMonth}
										/>
									</div>
									<div>
										<CustomSelect
											id="day"
											options={daysArray}
											placeholder="Select Day"
											width="140px"
											onChange={handleSelectedDay}
										/>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className={styles.button_wrapper}>
						<button
							className={styles.save_button}
							onClick={handleSave}
						>
							CONFIRM
						</button>
					</div>
				</div>
			}
		/>
	);
};

export default TempModal;
