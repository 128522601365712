import React, { useEffect, useState, useMemo } from "react";
import ethnicityApi from "../../../../api/ethnicity";
import useApi from "../../../../hooks/useApi";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import {
	defaultFormatter,
	languageFormatter,
	unduplicateValue,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import Tag from "../../../Utils/SubComs/Tags/Tag";
import TagContainer from "../../../Utils/SubComs/Tags/TagContainer";
import DayPicker from "../../../Utils/SubComs/DayPicker/DayPicker";

import styles from "./Filters.module.css";
import InfiniteSearchInput from "../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { languagesKeys } from "queryKeys/languages-key-factory";
import useFetchLanguages from "hooks/useFetchLanguages";

const itemsPerPage = 20;

const CastingFilter = ({
	onGenderChange,
	gender,
	onMinAgeChange,
	onMaxAgeChange,
	onCastingDateChange,
	castingDate,
	onEthnicityChange,
	languages,
	onAddLanguage,
	onRemoveLanguage,
	minAge,
	maxAge,
	ethnicity,
}) => {
	const getEthnicitiesApi = useApi(ethnicityApi.getEthnicities);
	const [ethnicities, setEthnicities] = useState([]);

	useEffect(() => {
		getEthnicitiesApi.request();
	}, []);

	useEffect(() => {
		if (
			!getEthnicitiesApi.loading &&
			getEthnicitiesApi.responseCode === 200 &&
			getEthnicitiesApi.data.length > 0
		) {
			const response = getEthnicitiesApi.data;
			setEthnicities(response.map((item) => defaultFormatter(item)));
		}
	}, [getEthnicitiesApi.data]);

	// !------------ languages

	const fetchLanguages = useFetchLanguages();

	const languagesList = useMemo(
		() =>
			languages.map((language, index) => (
				<Tag
					key={language.value}
					itemName={language.label}
					onRemove={() => onRemoveLanguage(index)}
				/>
			)),
		[languages]
	);

	return (
		<div className={styles.container}>
			<div className={styles.section}>
				<MultiCheckbox
					title={"Gender"}
					options={[
						{ label: "Male", value: 0 },
						{ label: "Female", value: 1 },
					]}
					onSelect={onGenderChange}
					selected={gender}
				/>
			</div>

			<div className={styles.section}>
				<label>Age Range</label>
				<div className={styles.inline_input}>
					<div className={styles.form_input_wrapper}>
						<TextInput
							onChange={onMinAgeChange}
							value={minAge}
							type="number"
							placeholder="Minimum"
						/>
					</div>
					<div className={styles.form_input_wrapper}>
						<TextInput
							onChange={onMaxAgeChange}
							value={maxAge}
							type="number"
							placeholder="Maximum"
						/>
					</div>
				</div>
			</div>

			<div className={styles.section}>
				<label>Ethnicity</label>
				<CustomSelect
					options={ethnicities}
					onChange={onEthnicityChange}
					value={ethnicity}
					height="35px"
					isClearable={true}
				/>
			</div>

			<div className={styles.section}>
				<InfiniteSearchInput
					queryName={languagesKeys.all}
					queryFn={fetchLanguages}
					itemsPerPage={itemsPerPage}
					label={"Languages"}
					onChange={onAddLanguage}
					selected={languages}
					formatter={languageFormatter}
					unDuplicateFn={(passedData) =>
						unduplicateValue(languages, passedData)
					}
				/>

				{languages.length > 0 && (
					<TagContainer
						label="Selected Languages"
						items={languagesList}
					/>
				)}
			</div>

			<div className={styles.section}>
				<label>Start Date</label>
				<DayPicker
					onChange={onCastingDateChange}
					value={castingDate}
					isClearable={true}
				/>
			</div>
		</div>
	);
};

export default CastingFilter;
