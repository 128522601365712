import useApi from "./useApi";
import profileApi from "../api/profile";
import { useQuery } from "@tanstack/react-query";
import { profileKeys } from "queryKeys/profile-key-factory";

export const useGetUserLocationsList = (enabled = true) => {
	const getLocationList = useApi(profileApi.getProfileLocations, true, true);

	const fetchProfileLocations = async () => {
		const response = await getLocationList.request();
		return response.data;
	};
	const {
		data: profileLocationLists,
		isLoading: isLoadingProfileLocationsList,
	} = useQuery({
		queryKey: profileKeys.myLocations(),
		queryFn: fetchProfileLocations,
		refetchOnWindowFocus: false,
		enabled: enabled,
	});
	return { profileLocationLists, isLoadingProfileLocationsList };
};
