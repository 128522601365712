import styles from "./NoResults.module.css";
// import CtaButton from "components/Utils/Button/CtaButton";
import desertIcon from "images/desert.svg";
import emptyBox from "images/empty-box.svg";

const NoResults = ({
	visible,
	text,
	customStyle,
	style,
	image = emptyBox,
	imageStyle,
	button,
	noPadding = false,
	isSearch = false,
}) => {
	if (!visible) return null;
	return (
		<div
			className={`${styles.container} ${
				image || isSearch ? styles.is_search : undefined
			} ${customStyle} ${noPadding ? styles.no_padding : undefined}`}
			style={style}
		>
			{image && !isSearch && (
				<img
					src={image}
					alt="Empty"
					className={`${
						imageStyle ? imageStyle : styles.empty_image
					}`}
				/>
			)}

			{isSearch && (
				<div className={styles.no_results_container}>
					<img
						src={desertIcon}
						alt="Empty"
						className={styles.empty_image}
					/>
					<span>OOPS!</span>
				</div>
			)}

			<p className={image || isSearch ? styles.text : undefined}>
				{text}
			</p>

			{button && <div className={styles.button_wrapper}>{button}</div>}
		</div>
	);
};

export default NoResults;
