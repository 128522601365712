import { useState } from "react";
import useWindowSize from "./useWindowSize";

const useVaultThumbCount = () => {
	const { width } = useWindowSize();

	const [count] = useState(() => {
		switch (true) {
			case width >= 1608:
				return 18;

			case width >= 1400 && width < 1608:
				return 16;

			case width >= 1166 && width < 1400:
				return 12;

			case width >= 974 && width < 1166:
				return 8;

			case width >= 748 && width < 974:
				return 6;

			case width >= 640 && width < 748:
				return 10;

			case width < 640:
				return 6;

			default:
				break;
		}
	});

	return count;
};

export default useVaultThumbCount;
