import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProductsItems.module.scss";
import ProductItem from "components/Profile/ServicesTab/ServicesComponent/ProductItem";
import WhiteOptions from "images/three-dots-white-5-x-20.svg";
import useDirectMessage from "hooks/useDirectMessage";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import { toast } from "react-toastify";
import { copyToClipboard } from "components/Utils/General";
import { BackdropLoading } from "./BackDropLoading";
import { useDeleteProduct } from "../hooks/useDeleteProduct";
import { useDispatch } from "react-redux";
import { setModalProduct, setProduct } from "store/slices/products";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

export const AllProductsComponent = forwardRef(
	(
		{
			visible = false,
			products,
			canEdit,
			isViewAll = false,
			Customitems_wrapper,
			product_img_style,
			isSugg,
			userUrl,
			profileId,
			searchInput,
			isLoading = false,
			isStale = false,
		},
		ref
	) => {
		const { handleDirectMessage } = useDirectMessage();
		const dispatch = useDispatch();
		const navigate = useNavigate();

		const handleCopyLink = (productId, url) => {
			copyToClipboard(
				`${window.location.origin}/profile/${url}/product/${productId}`
			);
			toast.success("Link copied !");
		};
		const _handleEdit = (product) => {
			dispatch(setProduct(product));
			dispatch(setModalProduct(true));
		};

		const {
			handleConfirmDelete,
			handleRequestDelete,
			warningModal,
			setWarningModal,
			isLoading: isLoadingDelete,
		} = useDeleteProduct(false);

		if (!visible) return null;

		if (isStale || isLoading) return <LoadingSpinner />;

		if (
			(products?.pages[0]?.count === 0 ||
				products?.pages[0]?.count === undefined) &&
			searchInput
		)
			return (
				<NoResults
					isSearch={true}
					visible={true}
					text={"No products related to your search"}
					// customStyle={styles.no_results}
				/>
			);

		return (
			<div className={styles.section}>
				{isSugg ? <h3>Similar Products</h3> : <h3>Products</h3>}

				<div
					className={
						Customitems_wrapper
							? Customitems_wrapper
							: styles.items_wrapper
					}
				>
					{products?.pages?.map((page) =>
						(Array.isArray(page.list) ? page.list : page)?.map(
							(product) => {
								return (
									<div ref={ref} key={product.uuid}>
										<ProductItem
											optionsIcon={WhiteOptions}
											isOwner={
												isSugg
													? profileId ===
													  product?.owner?.uuid
													: canEdit
											}
											product_img_style={
												product_img_style
											}
											item={product}
											onCopy={() =>
												handleCopyLink(
													product.uuid,
													product?.owner?.url
												)
											}
											onDelete={() =>
												handleRequestDelete(
													product.uuid
												)
											}
											onEdit={() => _handleEdit(product)}
											onMessage={() =>
												handleDirectMessage(
													product.owner
												)
											}
										/>
									</div>
								);
							}
						)
					)}
				</div>
				{!isViewAll && (
					<div className={styles.view_all_container}>
						<SeeMore
							text="View All"
							onClick={() => {
								navigate(`/profile/${userUrl}/products`, {
									state: {
										filters: {
											onlyUncategorizedProducts: false,
										},
										q: searchInput,
									},
								});
							}}
						/>
					</div>
				)}

				<WarningModal
					show={warningModal}
					modalOnTop={true}
					headerText="Are you sure you want to delete this product?"
					warningText="This action cannot be undone."
					cancelButtonText={"Cancel"}
					onCancelButtonClicked={() => setWarningModal(false)}
					submitButtonText={"DELETE"}
					onSubmitButtonClicked={handleConfirmDelete}
				/>
				<BackdropLoading isVisible={isLoadingDelete} />
			</div>
		);
	}
);
