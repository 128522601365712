import useMutate from "hooks/useMutate";
import productApi from "api/product";
import { productsKeys } from "queryKeys/products-key-factory";

export const useDeleteMedia = (
	queryClient,
	productId,
	otherImagesWatcher,
	setValue
) => {
	const {
		warningModal,
		setWarningModal,
		handleRequestDelete,
		handleConfirmDelete,
	} = useMutate(productApi.deleteProductMedia, () =>
		// queryClient.invalidateQueries(["productMedia", product?.uuid])
		queryClient.invalidateQueries(productsKeys.productMedia(productId))
	);

	const handleDeleteMulti = (item, index) => {
		if (item instanceof Blob) {
			const updatedArray = otherImagesWatcher.filter(
				(_, i) => i !== index
			);
			setValue("otherImages", updatedArray);
		} else {
			handleRequestDelete({ productId, vaultItemId: item.uuid });
		}
	};
	return {
		warningModal,
		setWarningModal,
		handleConfirmDelete,
		handleDeleteMulti,
	};
};
