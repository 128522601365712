import styles from "./PerfectFitView.module.scss";
import ForUsers from "components/Landing/ForUsers";

const PerfectFitView = () => {

    return <div className={styles.container}>
        <span className={styles.subtitle}>Does it work for me?"</span>
        <span className={styles.title}>It Perfectly Fits!</span>
        <span className={styles.description}>is an excellent choice for freelancers, talented individuals, and anyone seeking part-time opportunities while cherishing the freedom in their lives.</span>

        <ForUsers />
    </div>;
};

export default PerfectFitView;