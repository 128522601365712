import client from "./client";

const endpoint = "/calendar";

const getEntries = (month, year, requestedProfileId) =>
    client.get(endpoint + `?month=${month}&requestedProfileId=${requestedProfileId}&year=${year}`);

const updateCalendarVisibility = (bool) => client.put(endpoint + `/state?state=${bool}`);

const addEntry = (body) => client.post(endpoint, body);
const updateEntry = (body) => client.put(endpoint, body);
const deleteEntry = (entryId) => client.delete(endpoint + `?entryId=${entryId}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getEntries,
    updateCalendarVisibility,
    addEntry,
    updateEntry,
    deleteEntry,
};
