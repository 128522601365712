import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Social.module.css";
import SocialItem from "./SocialItem";
import { deleteSocial } from "../../../../../store/slices/user";
import { deleteCompanySocial } from "../../../../../store/slices/company";
import CtaButton from "components/Utils/Button/CtaButton";

const Social = ({ editCompany }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { socials, uuid: companyId } = useSelector((state) => {
		if (editCompany) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const [warningDeleteModal, setWarningDeleteModal] = useState(false);
	const [socialId, setSocialId] = useState("");

	const handleSocialDelete = (socialId) => {
		setWarningDeleteModal(true);
		setSocialId(socialId);
	};

	const handleConfirmDelete = () => {
		dispatch(
			editCompany
				? deleteCompanySocial(socialId, companyId)
				: deleteSocial(socialId)
		);
		setWarningDeleteModal(false);
	};

	const socialList = socials.map((social) => (
		<SocialItem
			key={social.uuid}
			item={social}
			onEditClicked={() =>
				navigate("/profile/social/edit", {
					state: social,
				})
			}
			onDelete={() => handleSocialDelete(social.uuid)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD NEW SOCIAL MEDIA"
					onClick={() => navigate("/profile/social/add")}
				/>
			</div>

			{socials.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Social Medias</h4>
					<div className={styles.contact_section}>{socialList}</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Social;
