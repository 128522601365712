// import HeaderBar from "../../SubComs/HeaderBar/HeaderBar";
// import BottomNavBar from "../../SubComs/bottomNavBar/BottomNavBar";
import styles from "../DefaultPage/DefaultPage.module.css";
import LeftSide from "../../SubComs/Left-Side/LeftSide";
import RightSide from "../../SubComs/RightSide/RightSide";
import MainHeader from "components/Utils/SubComs/MainHeader/MainHeader";

const ChatPage = ({
	leftSideChildren,
	rightSideChildren,
	footerContent,
	rightProiority,
	headerLeftContent,
	headerRightContent,
	showConvoList,
	inConvo,
	isInfoOpened,
}) => {
	return (
		<>
			{/* <HeaderBar
                headerLeftContent={headerLeftContent}
                headerRightContent={headerRightContent}
                hasMobileHeader={!showConvoList}
            /> */}
			<MainHeader />

			<div className={styles.container}>
				<LeftSide
					inConvo={inConvo}
					rightProiority={rightProiority}
					chat={true}
					children={leftSideChildren}
				/>

				<RightSide
					isInfoOpened={isInfoOpened}
					showConvoList={showConvoList}
					rightProiority={rightProiority}
					chat={true}
					footerContent={footerContent}
					children={
						<div className={styles.right_side_container}>
							{rightSideChildren}
						</div>
					}
				/>
			</div>

			{/* <BottomNavBar /> */}
		</>
	);
};

export default ChatPage;
