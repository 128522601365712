import { useInfiniteQuery } from "@tanstack/react-query";
import gaawkServiceApi from "api/gaawk-service";
import useApi from "hooks/useApi";
import useDebounce from "hooks/useDebounce";
import { gaawkServicesKeys } from "queryKeys/gaawk-service-key-factory";
import { useState } from "react";

const size = 30;

const useGaawkServices = (
	parentIdLvl1 = undefined,
	parentIdLvl2 = undefined
) => {
	const [query, setQuery] = useState("");
	const debouncedQuery = useDebounce(query);

	const getGaawkServiceApi = useApi(
		gaawkServiceApi.getGaawkService,
		true,
		true
	);

	const fetchGaawkServices = async ({ pageParam = 0, queryKey }) => {
		const [_, query, parentIdLvl1, parentIdLvl2] = queryKey;
		const response = await getGaawkServiceApi.request(
			pageParam,
			size,
			query,
			parentIdLvl1,
			parentIdLvl2
		);
		return response.data;
	};

	const handleKeyUp = (e) => {
		if (e.key !== "ArrowUp" && e.key !== "ArrowDown") {
			setQuery(e.target.value);
		}
	};

	const {
		data,
		hasNextPage,
		fetchNextPage,
		isFetching,
		// isLoading,
	} = useInfiniteQuery({
		// queryKey: ["gaawkServiceLvl1", debouncedQueryLvl1],
		queryKey: gaawkServicesKeys.list(
			debouncedQuery,
			parentIdLvl1,
			parentIdLvl2
		),
		queryFn: fetchGaawkServices,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === size ? pages.length : undefined;
			return nextPage;
		},
		// refetchOnWindowFocus: false,

		// staleTime: 300000, // 5 minutes
	});

	return {
		handleKeyUp,
		hasNextPage,
		fetchNextPage,
		isFetching,
		data,
	};
};

export default useGaawkServices;
