import { useSelector } from "react-redux";
import styles from "./Conversation.module.css";
import sentIcon from "images/tick.svg";
import waitingIcon from "images/danger-sign.svg";
import readIcon from "images/read-indicator.svg";
import receivedIcon from "images/received-indicator.svg";
import muteIcon from "images/mute-icon-lightgray.svg";
import ProfilePic from "../Home/ProfilePic";
import { dateToFromNowDaily } from "../Utils/General";
import LeftSideMsg from "./LeftSideMsg";
import useCompanyId from "../../hooks/useCompanyId";
import useWindowSize from "../../hooks/useWindowSize";

const Conversation = ({ conversation, onclick, active = false }) => {
	const {
		conversationType,
		lastUpdateTime,
		minifiedProfile,
		groupDTO,
		message,
		muted,
		unreadCount,
	} = conversation;

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const companyId = useCompanyId();

	const { uuid } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const convoName =
		conversationType === "Default"
			? minifiedProfile.deleted
				? "Deleted profile"
				: minifiedProfile.name
			: groupDTO.name;

	return (
		<div
			className={`${styles.conversation_item} ${
				active && !isMobile ? styles.active : undefined
			}`}
			onClick={onclick}
		>
			<div className={styles.conversation_item_wrapper}>
				<ProfilePic
					type={
						conversationType === "Default" &&
						minifiedProfile.type === "USER"
							? "USER"
							: "COMPANY"
					}
					thumbnail={
						conversationType === "Group"
							? groupDTO?.image?.file.customName
							: minifiedProfile?.profileImage?.image
					}
					enableName={false}
					name={convoName}
				/>

				<div className={styles.conversion_info}>
					<div className={styles.name_container}>
						<label className={styles.profile_name}>
							{convoName}
						</label>
						<label className={styles.time}>
							{message && dateToFromNowDaily(lastUpdateTime)}
						</label>
					</div>
					<div className={styles.message_container}>
						<div className={styles.message_wrapper}>
							{message &&
								message.sendBy &&
								message.sendBy.uuid === uuid && (
									<img
										src={getMessageState(
											message.messageStatus
										)}
										alt={"sent"}
									/>
								)}
							{/* <label className={styles.message_text}>{message ? message.message : ""}</label> */}

							<LeftSideMsg message={message} />
						</div>

						<div className={styles.message_indicators}>
							{muted && (
								<img
									src={muteIcon}
									className={styles.muteIcon}
									alt="Mute"
								/>
							)}
							{unreadCount > 0 && (
								<div className={styles.unread_count}>
									<label>{unreadCount}</label>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const getMessageState = (status) => {
	switch (status) {
		case "READ":
			return readIcon;
		case "RECEIVED":
			return receivedIcon;
		case "SENT":
			return sentIcon;
		default:
			return waitingIcon;
	}
};

export default Conversation;
