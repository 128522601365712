import useApi from "./useApi";
import languageApi from "api/language";

const useFetchLanguages = (itemsPerPage = 20) => {
	const searchLanguagesApi = useApi(languageApi.searchLanguage, true, true);

	const fetchLanguages = async ({ pageParam = 0, queryKey, signal }) => {
		const [_, debouncedSearch] = queryKey;
		const response = await searchLanguagesApi.request(
			debouncedSearch,
			pageParam,
			itemsPerPage
		);
		return response.data;
	};

	return fetchLanguages;
};

export default useFetchLanguages;
