import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../../Utils/SubComs/CustomSelect/CustomSelect";

import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";
import { addSite, updateSite } from "../../../../../store/slices/user";
import {
	addCompanySite,
	updateCompanySite,
} from "../../../../../store/slices/company";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import styles from "./Email.module.css";
import PrimaryButton from "../../../../Utils/Button/PrimaryButton";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import PromptModal from "components/Utils/Prompt/PromptModal";
import emailSchema from "./email-schema";
import { capitalize } from "components/Utils/General";

const EmailEntry = ({ editCompany }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname, state } = useLocation();

	const editMode = pathname === `/profile/email/edit`;

	const companyId = useSelector((state) => state.company.companyInfo.uuid);

	const {
		uuid,
		description,
		site,
		type,
		visibility: currentVisibility,
	} = state || {};

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(emailSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					description,
					type: {
						label: capitalize(type), //type?.charAt(0) + type?.slice(1).toLowerCase(),
						value: type,
					},
					site,
			  }
			: {
					description: "",
					type: null,
					site: "",
			  },
	});

	const [visibility, setVisibility] = useState(
		currentVisibility ?? "WORK_CIRCLE_FRIENDS"
	);
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const handleSave = (data) => {
		const {
			description,
			site,
			type: { value: type },
		} = data;

		const body = {
			...(editMode && { uuid }),
			description,
			type,
			site,
			visibility,
		};

		if (editCompany) {
			dispatch(
				editMode
					? updateCompanySite(body, companyId)
					: addCompanySite(body, companyId)
			);
		} else {
			dispatch(editMode ? updateSite(body) : addSite(body));
		}
		setShouldBlockSave(false);
	};

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	useEffect(() => {
		if (!shouldBlockSave) navigate("/profile/email");
	}, [navigate, shouldBlockSave]);

	if (editMode && !state) {
		return <Navigate to={`/profile/email`} />;
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.contact_section}>
					<form
						className={styles.add_form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<div className={styles.form_input_wrapper}>
							<label>
								Description
								<span className="required">*</span>
							</label>
							<TextInput
								{...register("description")}
								error={!!errors.description}
								placeholder="Description"
							/>
							{errors?.description?.message && (
								<p className={styles.error_message}>
									{errors?.description?.message}
								</p>
							)}
						</div>

						<div className={styles.react_select_wrapper}>
							<label>
								Type
								<span className="required">*</span>
							</label>

							<CustomSelect
								fontSize="14px"
								height="35px"
								options={[
									{
										value: "WEBSITE",
										label: "Website",
									},
									{
										value: "EMAIL",
										label: "Email",
									},
								]}
								isSearchable={false}
								error={!!errors.type}
								control={control}
								name="type"
							/>
							{errors?.type?.message && (
								<p className={styles.error_message}>
									{errors?.type?.message}
								</p>
							)}
						</div>

						<div className={styles.form_input_wrapper}>
							<label>
								Email / Website
								<span className="required">*</span>
							</label>
							<TextInput
								{...register("site")}
								error={!!errors.site}
								placeholder="Email / Website"
							/>
							{errors?.site?.message && (
								<p className={styles.error_message}>
									{errors?.site?.message}
								</p>
							)}
						</div>

						<div className={styles.visibility_wrapper}>
							<label className={styles.visibility_label}>
								Visibility
								<span className="required">*</span>
							</label>

							<VisibilitySelector
								value={visibility}
								onValueChange={handleVisibilityChange}
							/>
						</div>

						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								text={"save"}
							/>
						</div>
					</form>
				</div>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default EmailEntry;
