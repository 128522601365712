import { useRef } from "react";
import styles from "./Chat.module.css";

import { ReactComponent as OptionIcon } from "images/three-dots-white-5-x-20.svg";
import { ReactComponent as CloseIcon } from "images/close-icon-black-20-x-20.svg";

import accountIcon from "images/account-icon.svg";
import binIcon from "images/bin-icon.svg";
import clearIcon from "images/clear-icon.svg";
import exportIcon from "images/export-icon.svg";
import muteIcon from "images/notification-icon.svg";
import unmuteIcon from "images/unmute-icon.svg";
import selectIcon from "images/select-message-icon.svg";

import ShowChatProfile from "./ShowChatProfile";

import Popover from "../Utils/Popover/Popover";
import BackButton from "components/Utils/Button/BackButton";

const RightSideHeader = ({
	onProfileClick,
	handleBack,
	conversation,
	onClear,
	onExport,
	onSelect,
	onDelete,
	onMute,
	onUnmute,
	isInfoOpened,
}) => {
	const popoverRef = useRef(null);

	return (
		<>
			{conversation && (
				<div className={styles.header_container}>
					{/* {showChatProfile(
                        conversation.minifiedProfile,
                        conversation.typing
                    )} */}
					<div className={styles.header_leftside}>
						<div className={styles.back_btn}>
							<BackButton color={"#000"} onBack={handleBack} />
						</div>
						<ShowChatProfile
							data={conversation}
							typing={conversation.typing}
							handleProfileClick={onProfileClick}
							// isGroup={conversation.groupDTO}
							// totalparticipants={totalParticipants}
						/>
					</div>

					<div className={styles.header_options}>
						{isInfoOpened ? (
							<button
								className={styles.option_icon}
								onClick={handleBack}
							>
								<CloseIcon />
							</button>
						) : (
							<Popover
								ref={popoverRef}
								render={
									<div className="popover_container">
										<div
											className="popover_item"
											onClick={() => {
												onProfileClick();
												popoverRef.current.closePopover();
											}}
										>
											<img
												src={accountIcon}
												alt="view account"
											/>
											<span>View Info</span>
										</div>

										{!conversation.isTemp && (
											<>
												<div
													className="popover_item"
													onClick={() => {
														onSelect();
														popoverRef.current.closePopover();
													}}
												>
													<img
														src={selectIcon}
														alt="select messages"
													/>
													<span>Select Messages</span>
												</div>

												{conversation.muted ? (
													<div
														className="popover_item"
														onClick={() => {
															onUnmute();
															popoverRef.current.closePopover();
														}}
													>
														<img
															src={unmuteIcon}
															alt="unmute conversation"
														/>
														<span>
															Unmute Notifications
														</span>
													</div>
												) : (
													<div
														className="popover_item"
														onClick={() => {
															onMute();
															popoverRef.current.closePopover();
														}}
													>
														<img
															src={muteIcon}
															alt="mute conversation"
														/>
														<span>
															Mute Notifications
														</span>
													</div>
												)}

												<div
													className="popover_item"
													onClick={() => {
														onExport();
														popoverRef.current.closePopover();
													}}
												>
													<img
														src={exportIcon}
														alt="export conversation"
													/>
													<span>
														Export Conversation
													</span>
												</div>

												<div
													className="popover_item"
													onClick={() => {
														onClear();
														popoverRef.current.closePopover();
													}}
												>
													<img
														src={clearIcon}
														alt="clear conversation"
													/>
													<span>
														Clear Conversation
													</span>
												</div>

												<div
													className="popover_item"
													onClick={() => {
														onDelete();
														popoverRef.current.closePopover();
													}}
												>
													{conversation.conversationType ===
													"Group" ? (
														<>
															<img
																src={binIcon}
																alt="delete conversation"
															/>
															<span>
																Leave Group
															</span>
														</>
													) : (
														<>
															<img
																src={binIcon}
																alt="delete conversation"
															/>
															<span>
																Delete
																Conversation
															</span>
														</>
													)}
												</div>
											</>
										)}
									</div>
								}
							>
								<button className={styles.option_icon}>
									{/* <img src={optionIcon} alt="options" /> */}
									<OptionIcon
									// className={styles.option_icon}
									/>
								</button>
							</Popover>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default RightSideHeader;
