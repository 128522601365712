import styles from "./DescriptionTile.module.scss";
import LazyImage from "components/Utils/LazyImage/LazyImage";

const DescriptionTile = ({direction = "left", img, subtitleIcon, subtitleText, title, description}) => {

    return <div className={`${styles.container} ${direction === "right" && styles.rightTile}`}>
        <div className={styles.side1}>
            <LazyImage image={<img className={styles.img} src={img} alt={"demo"}/>}/>
        </div>
        <div className={styles.side2}>
            <div className={styles.subTitle}>
                <img className={styles.subTitleIcon} src={subtitleIcon} alt={subtitleText}/>
                <span className={styles.subTitleText}>{subtitleText}</span>
            </div>
            <div className={styles.titleView}>
                <span className={styles.title}>{title}</span>
            </div>
            <span className={styles.description}>{description}</span>
        </div>
    </div>;
};

export default DescriptionTile;