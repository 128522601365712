import { Outlet } from "react-router-dom";
import { StompSessionProvider } from "react-stomp-hooks";

const SocketProvider = () => {
	return (
		<StompSessionProvider
			url={process.env.REACT_APP_SOCKET_BASE_URL}
			debug={(str) => {
				console.debug(
					"%c WEBSOCKET DEBUGGER ",
					"color: orange; font-weight: bolder;",
					str
				);
			}}
			splitLargeFrames={true}
			discardWebsocketOnCommFailure={true}
			maxWebSocketChunkSize={8 * 1024}
			onStompError={(error) => console.error(error)}
			onWebSocketError={(error) => console.log(error)}
			onDisconnect={(frame) => console.log(frame)}
			onConnect={() => {
				console.warn(
					"%c WEBSOCKET CONNECTED ",
					"font-weight:bolder; color: lime; border: 2px solid lime"
				);
			}}
		>
			<Outlet />
		</StompSessionProvider>
	);
};

export default SocketProvider;
