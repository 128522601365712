import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
	name: "Company",
	initialState: {
		companyInfo: {},
		admins: [],
		reps: [],
		employees: [],
		owners: [],
	},
	reducers: {
		companyInfoUpdated: (state, action) => {
			state.companyInfo = action.payload;
		},
		companyAdminsUpdated: (state, action) => {
			state.admins = action.payload;
		},
		addingCompanyAdmin: (state, action) => {
			const newAdmin = action.payload.passedData;
			state.admins = [...state.admins, newAdmin];
			//* IF USER IS REPRESENTATIVE: REMOVE HIM FROM REP LIST
			const index = state.reps.findIndex(
				(rep) => rep.uuid === newAdmin.uuid
			);
			if (index >= 0) {
				state.reps = state.reps.filter(
					(rep) => rep.uuid !== newAdmin.uuid
				);
			}
		},
		removingCompanyAdmin: (state, action) => {
			const adminId = action.payload.passedData;
			state.admins = state.admins.filter(
				(admin) => admin.uuid !== adminId
			);
		},
		addingCompanyRep: (state, action) => {
			const newRep = action.payload.passedData;
			state.reps = [...state.reps, newRep];
			//* IF USER IS ADMIN: REMOVE HIM FROM ADMIN LIST
			const index = state.admins.findIndex(
				(admin) => admin.uuid === newRep.uuid
			);
			if (index >= 0) {
				state.admins = state.admins.filter(
					(admin) => admin.uuid !== newRep.uuid
				);
			}
		},
		removingCompanyRep: (state, action) => {
			const repId = action.payload.passedData;
			state.reps = state.reps.filter((rep) => rep.uuid !== repId);
		},
		companyRepsUpdated: (state, action) => {
			state.reps = action.payload;
		},
		companyEmployeesUpdated: (state, action) => {
			state.employees = action.payload;
		},
		companyOwnersUpdated: (state, action) => {
			state.owners = action.payload;
		},

		bioUpdate: (state, action) => {
			state.companyInfo.bio = action.payload.bio;
		},

		bioDelete: (state) => {
			state.companyInfo.bio = "";
		},
		certificateUpdated: (state, action) => {
			state.companyInfo.certificates = action.payload.certificates;
		},
		certificateDeleted: (state, action) => {
			const certificateId = action.payload.passedData;
			state.companyInfo.certificates =
				state.companyInfo.certificates.filter(
					(certificate) => certificate.uuid !== certificateId
				);
		},

		companyCertificateFilesUpdated: (state, action) => {
			const { uuid: certificationId } = action.payload;
			const index = state.companyInfo.certificates.findIndex(
				(certificate) => certificate.uuid === certificationId
			);
			if (index > -1) {
				state.companyInfo.certificates[index].files =
					action.payload.files;
			}
		},

		addKeyPerson: (state, action) => {
			state.companyInfo.keyPeople = action.payload;
		},
		removeKeyPerson: (state, action) => {
			const profileId = action.payload.passedData;
			state.companyInfo.keyPeople = state.companyInfo.keyPeople.filter(
				(item) => item.profile.uuid !== profileId
			);
		},
		otherUpdated: (state, action) => {
			state.companyInfo.others = action.payload.others;
		},
		otherDeleted: (state, action) => {
			const otherId = action.payload.passedData;
			state.companyInfo.others = state.companyInfo.others.filter(
				(other) => other.uuid !== otherId
			);
		},
		socialUpdated: (state, action) => {
			state.companyInfo.socials = action.payload.socials;
		},
		socialDeleted: (state, action) => {
			const socialId = action.payload.passedData;
			state.companyInfo.socials = state.companyInfo.socials.filter(
				(social) => social.uuid !== socialId
			);
		},
		addressUpdated: (state, action) => {
			state.companyInfo.addresses = action.payload.addresses;
		},
		addressDeleted: (state, action) => {
			const addressId = action.payload.passedData;
			state.companyInfo.addresses = state.companyInfo.addresses.filter(
				(address) => address.uuid !== addressId
			);
		},
		siteUpdated: (state, action) => {
			state.companyInfo.sites = action.payload.sites;
		},
		siteDeleted: (state, action) => {
			const siteId = action.payload.passedData;
			state.companyInfo.sites = state.companyInfo.sites.filter(
				(site) => site.uuid !== siteId
			);
		},
		phoneUpdated: (state, action) => {
			state.companyInfo.contacts = action.payload.contacts;
		},
		phoneDeleted: (state, action) => {
			const contactId = action.payload.passedData;
			state.companyInfo.contacts = state.companyInfo.contacts.filter(
				(contact) => contact.uuid !== contactId
			);
		},
		companyProfileImageUpdated: (state, action) => {
			state.companyInfo.profileImage = action.payload.profileImage;
		},
		coverUpdated: (state, action) => {
			state.companyInfo.profileCover = action.payload.profileCover;
		},
	},
});

export const {
	companyInfoUpdated,
	companyAdminsUpdated,
	companyRepsUpdated,
	companyEmployeesUpdated,
	companyOwnersUpdated,
	addingCompanyAdmin,
	removingCompanyAdmin,
	addingCompanyRep,
	removingCompanyRep,
	bioUpdate,
	bioDelete,
	certificateUpdated,
	certificateDeleted,
	companyCertificateFilesUpdated,
	addKeyPerson,
	removeKeyPerson,
	otherUpdated,
	otherDeleted,
	socialUpdated,
	socialDeleted,
	addressUpdated,
	addressDeleted,
	siteUpdated,
	siteDeleted,
	phoneUpdated,
	phoneDeleted,
	companyProfileImageUpdated,
	coverUpdated,
} = slice.actions;
export default slice.reducer;

export const getCompanyInfo = (companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/get?companyId=${companyId}`,
		method: "GET",
		onSuccess: companyInfoUpdated.type,
	});

/**
 * GET COMPANY USERS
 */

export const getCompanyPeople = (companyId, type) => {
	let onSuccess;

	switch (type) {
		case "ADMIN":
			onSuccess = companyAdminsUpdated.type;
			break;

		case "REPRESENTATIVE":
			onSuccess = companyRepsUpdated.type;
			break;

		case "EMPLOYEE":
			onSuccess = companyEmployeesUpdated.type;
			break;

		case "OWNER":
			onSuccess = companyOwnersUpdated.type;
			break;

		default:
			break;
	}

	return apiActions.apiCallBegan({
		url: `/profile/company/users?companyId=${companyId}&type=${type}`,
		method: "GET",
		onSuccess,
	});
};

/**
 * ADD COMPANY USERS
 */

export const addCompanyAdmin = (companyId, profileId, passedData) =>
	apiActions.apiCallBegan({
		url: `/profile/company/users?companyId=${companyId}&profileId=${profileId}&type=ADMIN`,
		method: "POST",
		passedData,
		onSuccess: addingCompanyAdmin.type,
	});

export const removeCompanyAdmin = (companyId, profileId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/users?companyId=${companyId}&profileId=${profileId}&type=ADMIN`,
		method: "DELETE",
		passedData: profileId,
		onSuccess: removingCompanyAdmin.type,
	});

export const addCompanyRep = (companyId, profileId, passedData) =>
	apiActions.apiCallBegan({
		url: `/profile/company/users?companyId=${companyId}&profileId=${profileId}&type=REPRESENTATIVE`,
		method: "POST",
		passedData,
		onSuccess: addingCompanyRep.type,
	});

export const removeCompanyRep = (companyId, profileId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/users?companyId=${companyId}&profileId=${profileId}&type=REPRESENTATIVE`,
		method: "DELETE",
		passedData: profileId,
		onSuccess: removingCompanyRep.type,
	});

/**
 * Courses & Certifications
 */
export const addCompanyCertificate = (body, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/certificate?companyId=${companyId}`,
		method: "POST",
		data: body,
		onSuccess: certificateUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanyCertificate = (certificationId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/certificate?certificationId=${certificationId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: certificationId,
		onSuccess: certificateDeleted.type,
		showSuccessAlert: true,
	});

export const updateCompanyCertificate = (body, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/certificate?companyId=${companyId}`,
		method: "PUT",
		data: body,
		onSuccess: certificateUpdated.type,
		showSuccessAlert: true,
	});

export const addCompanyCertificateFiles = (formData) =>
	apiActions.apiCallBegan({
		url: "/profile/company/certificate/file",
		method: "POST",
		data: formData,
		onSuccess: certificateUpdated.type,
		contentType: "multipart/form-data",
		// showSuccessAlert: true,
	});

// export const deleteCompanyCertificateFiles = (certificationId, fileId, companyId) =>
//     apiActions.apiCallBegan({
//         url: `/profile/company/certificate/file?certificationId=${certificationId}&fileId=${fileId}&companyId=${companyId}`,
//         method: "DELETE",
//         onSuccess: certificateUpdated.type,
//         // showSuccessAlert: true,
//     });

/**
 * Key People
 */

export const deleteKeyPeople = (companyId, profileId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/users/key?companyId=${companyId}&profileId=${profileId}`,
		method: "DELETE",
		onSuccess: removeKeyPerson.type,
		passedData: profileId,
		// showSuccessAlert: true,
	});

// OTHER

export const addCompanyOther = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/other?companyId=${companyId}`,
		method: "POST",
		data,
		onSuccess: otherUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanyOther = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/other?companyId=${companyId}`,
		method: "PUT",
		data,
		onSuccess: otherUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanyOther = (otherId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/other?otherUuid=${otherId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: otherId,
		onSuccess: otherDeleted.type,
		showSuccessAlert: true,
	});

//SOCIAL

export const addCompanySocial = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/social?companyId=${companyId}`,
		method: "POST",
		data: data,
		onSuccess: socialUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanySocial = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/social?companyId=${companyId}`,
		method: "PUT",
		data: data,
		onSuccess: socialUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanySocial = (userSocialId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/social?userSocialId=${userSocialId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: userSocialId,
		onSuccess: socialDeleted.type,
		showSuccessAlert: true,
	});

/**
 * Addresses
 */

export const addCompanyAddress = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/address?companyId=${companyId}`,
		method: "POST",
		data: data,
		onSuccess: addressUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanyAddress = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/address?companyId=${companyId}`,
		method: "PUT",
		data: data,
		onSuccess: addressUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanyAddress = (addressId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/address?addressId=${addressId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: addressId,
		onSuccess: addressDeleted.type,
		showSuccessAlert: true,
	});

//SITE / EMAIL

export const addCompanySite = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/site?companyId=${companyId}`,
		method: "POST",
		data: data,
		onSuccess: siteUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanySite = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/site?companyId=${companyId}`,
		method: "PUT",
		data: data,
		onSuccess: siteUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanySite = (siteId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/site?siteId=${siteId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: siteId,
		onSuccess: siteDeleted.type,
		showSuccessAlert: true,
	});

/**
 * Phone
 */

export const addCompanyPhone = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/contact?companyId=${companyId}`,
		method: "POST",
		data: data,
		onSuccess: phoneUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanyPhone = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/contact?companyId=${companyId}`,
		method: "PUT",
		data: data,
		onSuccess: phoneUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanyPhone = (contactId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/contact?contactId=${contactId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: contactId,
		onSuccess: phoneDeleted.type,
		showSuccessAlert: true,
	});

//PROFILE & COVER IMAGE

// export const updateCompanyProfileImage = (formData, imgData, companyId) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		url: `/profile/company/profileImage?${array.join(
// 			"&"
// 		)}&companyId=${companyId}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: companyProfileImageUpdated.type,
// 		showSuccessAlert: true,
// 		contentType: "multipart/form-data",
// 	});
// };

// export const deleteCompanyProfileImage = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/profileImage?companyId=${companyId}`,
// 		method: "DELETE",
// 		showSuccessAlert: true,
// 		onSuccess: companyProfileImageUpdated.type,
// 	});

export const makeTempPermanentCompany = (companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/profileImage/permTemp?companyId=${companyId}`,
		method: "PATCH",
		showSuccessAlert: true,
		onSuccess: companyProfileImageUpdated.type,
	});

export const removeTempCompany = (companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/profileImage/removeTemp?companyId=${companyId}`,
		method: "DELETE",
		showSuccessAlert: true,
		onSuccess: companyProfileImageUpdated.type,
	});

// export const updateCompanyCoverImage = (formData, imgData, companyId) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		url: `/profile/company/coverImage?${array.join(
// 			"&"
// 		)}&companyId=${companyId}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: coverUpdated.type,
// 		contentType: "multipart/form-data",
// 		showSuccessAlert: true,
// 	});
// };
// export const deleteCompanyCoverImage = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/coverImage?companyId=${companyId}`,
// 		method: "DELETE",
// 		onSuccess: coverUpdated.type,
// 		showSuccessAlert: true,
// 	});
