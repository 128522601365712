import styles from "./PdfItem.module.scss";
import pdfIcon from "images/pdf_icon.svg";
import cancelIcon from "images/cancel-btn-gray.svg";

const PdfItem = ({
	item,
	canEdit = false,
	onDelete,
	onClick,
	isShared = false,
}) => {
	return (
		<button
			onClick={onClick}
			className={`${styles.container} ${
				isShared ? styles.shared : undefined
			}`}
		>
			<div className={styles.left_side}>
				<img src={pdfIcon} className={styles.icon} alt="pdf" />
				<div className={styles.details}>
					<h4>{item.originalName || item.name}</h4>
					<span>PDF File</span>
				</div>
			</div>

			{canEdit && (
				<div
					role="button"
					tabIndex={0}
					onClick={(e) => {
						e.stopPropagation();
						onDelete();
					}}
					className={styles.delete_btn}
				>
					<img src={cancelIcon} alt="" />
				</div>
			)}
		</button>
	);
};

export default PdfItem;
