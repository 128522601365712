// import websiteIcon from "images/website.svg";
// import emailIcon from "images/email.svg";
import phoneLandline from "images/phone_landline.svg";
import phoneMobile from "images/smartphone.svg";

import AddressItem from "../../../components/Profile/ProfileEdit/Contacts/Address/AddressItem";
import OtherItem from "../../../components/Profile/ProfileEdit/Contacts/Other/OtherItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	getVisibility,
	// getClickableLink
} from "../../Utils/General";
import styles from "./ContactTab.module.css";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import NoData from "../ProfilePage/NoData";
import useDirectMessage from "../../../hooks/useDirectMessage";
import ProfileSection from "../ProfileTab/ProfileSection";
import { useDispatch } from "react-redux";
import { updateIsInfoOpened } from "store/slices/ui";
import SocialItem from "../ProfileEdit/Contacts/Social/SocialItem";
import EmailItem from "../ProfileEdit/Contacts/Email/EmailItem";
import PhoneItem from "../ProfileEdit/Contacts/Phone/PhoneItem";

const ContactTab = (props) => {
	const navigate = useNavigate();

	const { userUrl } = useParams();

	const {
		contacts,
		sites,
		socials,
		addresses,
		others,
		firstName,
		lastName,
		type,
		name: companyName,
	} = props.userInfo;

	const contactList = contacts.map((item) => (
		<PhoneItem key={item.uuid} item={item} showControls={false} />
	));

	const siteList = sites.map((item) => (
		<EmailItem key={item.uuid} item={item} showControls={false} />
	));

	const socialList = socials.map((item) => (
		<SocialItem key={item.uuid} item={item} showControls={false} />
	));

	const addressList = addresses.map((address) => (
		<AddressItem key={address.uuid} item={address} showControls={false} />
	));

	const otherList = others.map((other) => (
		<OtherItem key={other.uuid} item={other} showControls={false} />
	));

	const { handleDirectMessage } = useDirectMessage();
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const handleSendMessage = () => {
		if (pathname === "/chat") {
			dispatch(updateIsInfoOpened(false));
		} else {
			handleDirectMessage(props.userInfo);
		}
	};

	const handleRedirect = (url) => {
		if (userUrl) navigate(`/profile/${userUrl}${url}`);
		else navigate(`/profile${url}`);
	};

	return (
		<div className={styles.container}>
			{!props.canEdit &&
				contacts.length === 0 &&
				sites.length === 0 &&
				socials.length === 0 &&
				addresses.length === 0 &&
				others.length === 0 && (
					<ProfileTabContainer>
						<NoData
							text={`${
								type === "USER"
									? `${firstName} ${lastName}`
									: companyName
							}  did not add any information to his contact details, or he has visibility limits for friends / work circles.`}
							onClick={handleSendMessage}
						/>
					</ProfileTabContainer>
				)}

			<ProfileSection
				canEdit={props.canEdit}
				show={contacts.length > 0}
				label={"Phone"}
				btnText="PHONE NUMBERS"
				data={
					<div className={styles.items_wrapper_grid}>
						{contactList}
					</div>
				}
				onEdit={() => handleRedirect("/contact")}
				onAdd={() => handleRedirect("/contact/add")}
			/>

			<ProfileSection
				canEdit={props.canEdit}
				show={sites.length > 0}
				label={"Web"}
				btnText="WEB ADDRESS"
				data={
					<div className={styles.items_wrapper_grid}>{siteList}</div>
				}
				onEdit={() => handleRedirect("/email")}
				onAdd={() => handleRedirect("/email/add")}
			/>

			<ProfileSection
				canEdit={props.canEdit}
				show={socials.length > 0}
				label={"Social Media"}
				data={
					<div className={styles.items_wrapper_grid}>
						{socialList}
					</div>
				}
				onEdit={() => handleRedirect("/social")}
				onAdd={() => handleRedirect("/social/add")}
			/>

			<ProfileSection
				canEdit={props.canEdit}
				show={addresses.length > 0}
				label={"Address"}
				data={
					<div className={styles.items_wrapper_grid}>
						{addressList}
					</div>
				}
				onEdit={() => handleRedirect("/address")}
				onAdd={() => handleRedirect("/address/add")}
			/>

			<ProfileSection
				canEdit={props.canEdit}
				show={others.length > 0}
				label={"Other info"}
				data={
					<div className={styles.items_wrapper_grid}>{otherList}</div>
				}
				onEdit={() => handleRedirect("/other")}
				onAdd={() => handleRedirect("/other/add")}
			/>
		</div>
	);
};

export default ContactTab;
