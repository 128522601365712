import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
import styles from "./MyCalendar.module.css";
import addIcon from "images/add-icon.svg";
import calendarIcon from "images/calendar-icon-blue.svg";
import SecondaryButton from "../../../Utils/Button/SecondaryButton";
import RangesCalendar from "../../../Utils/RangesCalendar/RangesCalendar";
import useApi from "../../../../hooks/useApi";
import calendarApi from "../../../../api/calendar";
import { updateCalendarState } from "../../../../store/slices/user";
import GaawkModal from "../../../Utils/GaawkModal/GaawkModal";
import { format } from "date-fns";
import { entryTypes } from "../../../Utils/General";
import EditButton from "../../../Utils/Button/EditButton";
import DeleteButton from "../../../Utils/Button/DeleteButton";

const MyCalendar = ({
	canEdit,
	calendarEntries,
	onMonthChange,
	onDeleteEntry,
	userInfo,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const updateCalendarVisibilityApi = useApi(
		calendarApi.updateCalendarVisibility
	);

	const { calendarEnabled, uuid } = useSelector(
		(state) => state.user.profileInfo
	);

	const handleCalendarVisibility = () => {
		updateCalendarVisibilityApi.request(!calendarEnabled);
	};

	useEffect(() => {
		if (
			!updateCalendarVisibilityApi.loading &&
			updateCalendarVisibilityApi.responseCode === 200
		) {
			dispatch(updateCalendarState());
		}
	}, [
		dispatch,
		updateCalendarVisibilityApi.loading,
		updateCalendarVisibilityApi.responseCode,
	]);

	// !====== GET CALENDAR ENTRIES ======

	const [showEntryModal, setShowEntryModal] = useState(false);
	const [calendarEntry, setCalendarEntry] = useState("");

	const handleEntryClick = (entry) => {
		const { from, to, ...otherProps } = entry;
		setCalendarEntry({
			startTime: from.getTime(),
			endTime: to.getTime(),
			...otherProps,
		});
		setShowEntryModal(true);
	};

	// !===== DELETE ENTRY =====

	const handleDeleteEntry = (calendarEntry) => {
		onDeleteEntry(calendarEntry);
		setShowEntryModal(false);
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h3>
					{userInfo.uuid === uuid ? "My" : `${userInfo.firstName}'s`}{" "}
					Calendar
				</h3>
				{canEdit && (
					<div className={styles.btn_wrapper}>
						<SecondaryButton
							text={
								<>
									<img
										src={addIcon}
										alt="add"
										className={styles.add_icon}
									/>
									New
								</>
							}
							className={styles.btn}
							type="submit"
							onClick={() => navigate("/profile/calendar/add")}
						/>

						{pathname !== "/profile/calendar" && (
							<EditButton
								onClick={() =>
									navigate("/profile/calendar", {
										state: calendarEntries,
									})
								}
							/>
						)}
					</div>
				)}
			</div>

			<div className={styles.body}>
				<RangesCalendar
					entries={calendarEntries}
					onMonthChange={onMonthChange}
					onClick={handleEntryClick}
					canEdit={canEdit}
				/>
				<div className={styles.entry_caption_container}>
					<div className={styles.entry_caption_item}>
						<div
							className={`${styles.color_pin} ${styles.booked}`}
						/>
						<p>Booked</p>
					</div>
					<div className={styles.entry_caption_item}>
						<div
							className={`${styles.color_pin} ${styles.penciled}`}
						/>
						<p>Penciled</p>
					</div>
					<div className={styles.entry_caption_item}>
						<div
							className={`${styles.color_pin} ${styles.blacked_out}`}
						/>
						<p>Blacked out</p>
					</div>
				</div>

				{calendarEntry && (
					<GaawkModal
						show={showEntryModal}
						handleClose={() => setShowEntryModal(false)}
						defaultModal={false}
						showHeader={true}
						title={"Calendar Entry Details"}
						closeAlign={"right"}
						children={
							<div className={styles.entry_details_container}>
								<div className={styles.entry_details}>
									<img
										src={calendarIcon}
										alt="calendar icon"
									/>
									<div className={styles.entry_status}>
										<div className={styles.entry_type}>
											<div
												className={styles.color_pin}
												style={{
													backgroundColor:
														calendarEntry.type ===
														"Booked"
															? "#ffad5c"
															: calendarEntry.type ===
															  "Penciled"
															? "#83d1ad"
															: "#a6b1bc",
												}}
											></div>
											{
												entryTypes.find(
													(entry) =>
														entry.value ===
														calendarEntry.type
												).label
											}
										</div>
										<div className={styles.entry_dates}>
											{format(
												new Date(
													calendarEntry.startTime
												),
												"dd MMM. yyyy"
											)}{" "}
											-{" "}
											{format(
												new Date(calendarEntry.endTime),
												"dd MMM. yyyy"
											)}
										</div>
									</div>
								</div>
								<div className={styles.label}>
									{calendarEntry.label}
								</div>
								<div className={styles.notes}>
									{calendarEntry.notes}
								</div>
								{canEdit && (
									<>
										<div className={styles.btn_wrapper}>
											<EditButton
												onClick={() =>
													navigate(
														"/profile/calendar/edit",
														{
															state: calendarEntry,
														}
													)
												}
											/>
											<DeleteButton
												onClick={() =>
													handleDeleteEntry(
														calendarEntry
													)
												}
											/>
										</div>
									</>
								)}
							</div>
						}
					/>
				)}
			</div>

			{canEdit && (
				<>
					<div className={styles.switch_container}>
						<Switch
							onChange={handleCalendarVisibility}
							checked={calendarEnabled}
							onColor="#6cc5d1"
							offColor="#a6b1bc"
							handleDiameter={18}
							width={39}
							height={24}
							checkedIcon={false}
							uncheckedIcon={false}
							activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
						/>
						Make my Calendar visibile to other people
					</div>
					<div className={styles.info_message}>
						If your work status is set to “Not looking for a job”
						your Calendar will not be visible to other people.
					</div>
				</>
			)}
		</div>
	);
};

export default MyCalendar;
