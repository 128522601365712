import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useClickOutside = (ref, callback) => {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback(event);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
};

export default useClickOutside;
