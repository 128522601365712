import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import styles from "./LandingPageFooter.module.scss";
import routes from "components/Routing/routing-keys";
import { getClickableLink } from "components/Utils/General";

const LandingPageFooter = () => {
	return (
		<div className={styles.container}>
			<div className={styles.links}>
				<img
					className={styles.linkItem}
					src={
						require("images/gaawk-logo-79-x-25-black-text.svg")
							.default
					}
					alt={"icon"}
				/>
				<GaawkLink
					to={routes.root}
					customStyle={styles.linkItem}
					disableDecoration={true}
					tabIndex={3}
				>
					Appstore
				</GaawkLink>
				<GaawkLink
					to={routes.root}
					customStyle={styles.linkItem}
					disableDecoration={true}
					tabIndex={3}
				>
					Google Play
				</GaawkLink>
				<GaawkLink
					to={routes.help}
					customStyle={styles.linkItem}
					disableDecoration={true}
					tabIndex={3}
				>
					Help & Support
				</GaawkLink>
				<GaawkLink
					to={routes.privacy}
					customStyle={styles.linkItem}
					disableDecoration={true}
					tabIndex={3}
				>
					Privacy Policy
				</GaawkLink>
				<GaawkLink
					to={routes.faq}
					customStyle={styles.linkItem}
					disableDecoration={true}
					tabIndex={3}
				>
					FAQ
				</GaawkLink>
			</div>
			<div className={styles.copyrightView}>
				<span className={styles.copyrightText}>
					Copyright all rights reserved | 2024 | Powered by Evolution
					Live Events{" "}
				</span>
				<GaawkLink
					to={getClickableLink(routes.evoServices)}
					target="_blank"
				>
					<img
						className={styles.evoIcon}
						src={require("images/evolution-logo-black.svg").default}
						alt={"evo"}
					/>
				</GaawkLink>
			</div>
		</div>
	);
};

export default LandingPageFooter;
