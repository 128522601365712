import { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./VaultItem.module.css";
import otherIcon from "images/other_icon.svg";

// import shareIcon from "images/icon-awesome-share.svg";
// import downloadIcon from "images/icon-ionic-md-download.svg";
import { ReactComponent as ShareIcon } from "images/share.svg";
import { ReactComponent as DownloadIcon } from "images/download.svg";
// import starFullIcon from "images/icon-metro-star-full-color.svg";
// import starIcon from "images/icon-metro-star-full.svg";
import { ReactComponent as FolderIcon } from "images/folder.svg";
import { ReactComponent as StarIcon } from "images/star.svg";
import { ReactComponent as GaawkFolder } from "images/gaawk-folder.svg";

// import folderIcon from "images/icon-awesome-folder-open.svg";
// import gaawkFolderLetter from "images/gaawkfolderletter.svg";
// import folderIconBlue from "images/folder-icon-blue.svg";
import optionIcon from "images/options-hor-dark-grey.svg";
// import playIcon from "images/play-icon-blue.svg";
import { ReactComponent as PlayIcon } from "images/play-button.svg";

import LazyImage from "../Utils/LazyImage/LazyImage";
import FileSaver from "file-saver";
import VaultItemOptions from "./VaultItemOptions";
import RectangleCheckbox from "../Utils/SubComs/Inputs/Checkbox/RectangleCheckbox";
import Popover from "../Utils/Popover/Popover";
import useCompanyId from "../../hooks/useCompanyId";

import useGetFirstFrame from "hooks/useGetFirstFrame";

const ListView = ({
	item,
	// isRecent,
	isSelected,
	showTickSelect,
	handleSelected,
	isActive,

	onMouseOver,
	onMouseLeave,

	onShare,
	onCopy,
	onMove,
	onDetails,
	onFavorite,
	onDelete,
	onRename,
	onClick,
}) => {
	const {
		uuid,
		gaawkFolder,
		favorite,
		type,
		file,
		subCount,
		owner,
		sharedItem,
		enableDelete,
	} = item;

	const isFolder = type === "FOLDER";
	const { customName: url, originalName } = file || {};

	const videoThumb = useGetFirstFrame(
		url,
		originalName,
		!isFolder && file.previewType === "VIDEO"
	);

	/**
	 * Selectors
	 */

	// const { uuid: profileId } = useSelector((state) => state.user.profileInfo);
	const companyId = useCompanyId();

	const { uuid: profileId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const isOwner = owner.uuid === profileId;

	const popoverRef = useRef();

	/**
	 * Hooks
	 */

	const navigate = useNavigate();

	/**
	 * States
	 */

	const handleClick = () => {
		if (isActive) {
			handleSelected();
		} else if (isFolder) {
			navigate(`/vault/${uuid}`);
		} else {
			onClick();
		}
	};

	/**
	 * JSX
	 */
	return (
		<div
			className={`${styles.item_list} ${
				isSelected ? styles.active : undefined
			}`}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
			onClick={handleClick}
		>
			<div className={styles.left_side}>
				<div className={styles.icon_wrapper}>
					{isFolder && !gaawkFolder && (
						<FolderIcon fill="#a6b1bc" width={24} height={24} />
					)}

					{isFolder && gaawkFolder && (
						<GaawkFolder width={24} height={24} />
					)}

					{!isFolder &&
						file.fileTypeDTO &&
						file.previewType !== "IMAGE" &&
						file.previewType !== "VIDEO" && (
							<div className={styles.document_wrapper}>
								<LazyImage
									image={
										<img
											src={
												file.fileTypeDTO.url
													? file.fileTypeDTO.url
													: otherIcon
											}
											alt={file.fileTypeDTO.name}
										/>
									}
								/>
							</div>
						)}
					{!isFolder && file.previewType === "IMAGE" && (
						<div className={styles.media_wrapper}>
							<LazyImage
								image={
									<img
										src={file.customName}
										alt={file.originalName}
									/>
								}
							/>
						</div>
					)}

					{!isFolder && file.previewType === "VIDEO" && (
						<div className={styles.media_wrapper}>
							<PlayIcon className={styles.play_icon} />
							<LazyImage
								image={
									// <img
									// 	src={playIcon}
									// 	alt={file.originalName}
									// />
									<img src={videoThumb} alt={originalName} />
								}
							/>
						</div>
					)}
				</div>

				<div className={styles.tickbox_container}>
					{showTickSelect && (
						<button onClick={(e) => e.stopPropagation()}>
							<RectangleCheckbox
								checked={isSelected}
								onChange={handleSelected}
							/>
						</button>
					)}
				</div>

				<div className={styles.item_details}>
					<div className={styles.item_name_list}>
						<p>
							{isFolder ? `${item.name}` : `${file.originalName}`}
						</p>
					</div>
					<div className={styles.item_type_list}>
						{isFolder
							? `${subCount} item${subCount > 1 ? "s" : ""}`
							: `${file.extension.toUpperCase()} File`}
					</div>
				</div>
			</div>

			<div className={styles.item_buttons}>
				{!isFolder && (
					<button
						// className={styles.download_list}
						className={styles.quick_action}
						onClick={(e) => {
							e.stopPropagation();
							FileSaver.saveAs(
								`${file.customName}`,
								`${item.name}`
							);
						}}
					>
						{/* <img src={downloadIcon} alt={"download"} /> */}
						<DownloadIcon />
					</button>
				)}

				<button
					// className={styles.star_list}
					className={styles.quick_action}
					onClick={onFavorite}
				>
					{/* <img
						src={favorite ? starFullIcon : starIcon}
						alt={"star"}
					/> */}

					<StarIcon
						fill={favorite ? "#6cc5d1" : "none"}
						stroke={favorite ? "#6cc5d1" : "#a6b1bc"}
					/>
				</button>

				<button
					// className={styles.share_list}
					className={styles.quick_action}
					onClick={onShare}
				>
					{/* <img src={shareIcon} alt={"share"} /> */}
					<ShareIcon />
				</button>

				<Popover
					ref={popoverRef}
					render={
						<VaultItemOptions
							isFolder={isFolder}
							shared={sharedItem}
							file={file}
							isOwner={isOwner}
							gaawkFolder={gaawkFolder}
							favorite={favorite}
							onRename={onRename}
							onDetails={onDetails}
							onShare={onShare}
							onMove={onMove}
							onCopy={onCopy}
							onFavorite={onFavorite}
							onDelete={onDelete}
							enableDelete={enableDelete}
							onClose={() => popoverRef.current.closePopover()}
						/>
					}
				>
					<button onClick={(e) => e.stopPropagation()}>
						<img src={optionIcon} alt="options" />
					</button>
				</Popover>
			</div>
		</div>
	);
};

export default ListView;
