import React, { useMemo } from "react";
import styles from "./TabMenu.module.css";

const TabMenu = ({
	menuItems,
	selectedTab,
	onSelectedTabChanged,
	customStyle,
	isLoading = false,
}) => {
	const menu = useMemo(
		() =>
			Object.entries(menuItems).map(([key, value]) => {
				return (
					<button
						key={key}
						className={`${styles.menuBtn} ${
							selectedTab === `${key}`
								? styles.selected
								: undefined
						} ${customStyle}`}
						onClick={() => onSelectedTabChanged(key)}
						disabled={isLoading}
					>
						{value}
					</button>
				);
			}),
		[menuItems, selectedTab]
	);

	return <div className={styles.tabs_wrapper}>{menu}</div>;
};

export default TabMenu;
