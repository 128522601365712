import { useEffect, useState } from "react";
import styles from "./People.module.scss";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import useApi from "../../../hooks/useApi";
import UserSection from "../MoreComponents/UsersComponents/UserSection";
import profileApi from "../../../api/profile";
import SearchUserSection from "../MoreComponents/UsersComponents/SearchUserSection";
import useDebounce from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import useCompanyId from "hooks/useCompanyId";

const itemsPerPage = 5;

const People = ({
	onInputChange,
	userCoordinates,
	isMobile,
	onShowModal,
	tagsList = [],
	initialFilters,
	searchQueryKey,
	searchEndpoint,
}) => {
	const navigate = useNavigate();
	const companyId = useCompanyId();

	//! =========== SEARCH INPUT HANDLER =============

	const [searchInput, setSearchInput] = useState("");

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const debouncedSearch = useDebounce(searchInput);

	useEffect(() => {
		onInputChange(debouncedSearch);
	}, [debouncedSearch]);

	//! =============== NEARBY PEOPLE ===============

	const nearbyPeopleApi = useApi(profileApi.getPeopleNearby, true, true);

	const fetchNearbyPeople = async () => {
		const response = await nearbyPeopleApi.request(
			0,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	//! =============== SAME INTEREST PEOPLE ===============

	const interestPeopleApi = useApi(profileApi.getPeopleInterests, true, true);

	const fetchInterestPeople = async () => {
		const response = await interestPeopleApi.request(
			0,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	//! =============== NETWORK PEOPLE ===============

	const networkPeopleApi = useApi(profileApi.getNetworkPeople, true, true);

	const fetchNetworkPeople = async () => {
		const response = await networkPeopleApi.request(0, itemsPerPage);
		return response.data;
	};

	//! =============== SAME COMPANY PEOPLE ===============

	const myCompanyPeopleApi = useApi(
		profileApi.getPeopleFromMyCompany,
		true,
		true
	);

	const fetchMyCompanyPeople = async () => {
		const response = await myCompanyPeopleApi.request(0, itemsPerPage);
		return response.data;
	};

	return (
		<div className={styles.container}>
			<div className={styles.search_input_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={() => setSearchInput("")}
					border={false}
					placeholder={"Search People"}
					classNameInput={styles.search_input}
				/>
			</div>

			{searchInput ? (
				<SearchUserSection
					inputValue={searchInput}
					userCoordinates={userCoordinates}
					initialFilters={initialFilters}
					searchQueryKey={searchQueryKey}
					searchEndpoint={searchEndpoint}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
				/>
			) : (
				<>
					<UserSection
						title="People you may know"
						queryKey={["networkPeople"]}
						queryFn={fetchNetworkPeople}
						// errorMessage={"Failed to get people"}
						onViewMore={() => navigate("/people/network")}
					/>

					<UserSection
						title="People in your area"
						queryKey={["nearbyPeople", userCoordinates]}
						queryFn={fetchNearbyPeople}
						// errorMessage={"Failed to get people"}
						onViewMore={() => navigate("/people/nearby")}
					/>

					<UserSection
						title="People who work in your company"
						queryKey={["myCompanyPeople"]}
						queryFn={fetchMyCompanyPeople}
						// errorMessage={"Failed to get people"}
						onViewMore={() => navigate("/people/my-company")}
					/>

					{!companyId && (
						<UserSection
							title="People with similar interests"
							queryKey={["interestPeople", userCoordinates]}
							queryFn={fetchInterestPeople}
							// errorMessage={"Failed to get people"}
							onViewMore={() => navigate("/people/similar")}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default People;
